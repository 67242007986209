const Categories = [
  {
    name: "Page",
    link: "Page",
  },
  {
    name: "Seafood",
    link: "seafood",
    img: "https://realdog-s3.s3-us-west-1.amazonaws.com/iconscard/seafoodsticker.png",
    bgColor: "#3c6eb0",
    darkBgColor: "#2f5a94",
  },
  {
    name: "Super Chew",
    link: "super-chew",
    img: "https://realdog-s3.s3-us-west-1.amazonaws.com/iconscard/superchewsticker.png",
    bgColor: "#fec100",
    darkBgColor: "#e4ae00",
  },
  {
    name: "Heavy Chew",
    link: "heavy-chew",
    img: "https://realdog-s3.s3-us-west-1.amazonaws.com/iconscard/heavychewsticker.png",
    bgColor: "#222223",
    darkBgColor: "#1c1c1d",
  },
  {
    name: "Medium Chew",
    link: "medium-chew",
    img: "https://realdog-s3.s3-us-west-1.amazonaws.com/iconscard/mediumchewsticker.png",
    bgColor: "#4c4b4b",
    darkBgColor: "#3e3d3d",
  },
  {
    name: "Light Chew",
    link: "light-chew",
    img: "https://realdog-s3.s3-us-west-1.amazonaws.com/iconscard/lightchewsticker.png",
    bgColor: "#777678",
    darkBgColor: "#666467",
  },
  {
    name: "Organ Meat",
    link: "organ-meat",
    img: "https://realdog-s3.s3-us-west-1.amazonaws.com/iconscard/organchewsticker.png",
    bgColor: "#804b97",
    darkBgColor: "#643977",
  },
  {
    name: "Muscle Meat",
    link: "muscle-meat",
    img: "https://realdog-s3.s3-us-west-1.amazonaws.com/iconscard/musclemeatsticker.png",
    bgColor: "#e25166",
    darkBgColor: "#bf4053",
  },
  {
    name: "Meatballs",
    link: "meatballs",
    img: "https://realdog-s3.s3-us-west-1.amazonaws.com/iconscard/meatball-orange.png",
    bgColor: "#ed5f13",
    darkBgColor: "#bb4b0f",
  },
];
export default Categories;
