import { IconButton, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import PreviewIcon from "@mui/icons-material/Preview";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactPlayer from "react-player/youtube";
import { Edit } from "@mui/icons-material";
import TutorialTitle from "./TutorialTitle";

const TutorialVideoContentEditor = ({
  onChangeNodeContent,
  rawContent,
  setDisabled = () => {},
}) => {
  const [youtubeLink, setYoutubeLink] = useState("");

  useEffect(() => {
    if (rawContent) {
      setYoutubeLink(JSON.parse(rawContent).youtubeLink);
    }
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      onChangeNodeContent(JSON.stringify({ youtubeLink }));
      setDisabled(false);
    }, 200);
    setDisabled(true);

    return () => clearTimeout(timer);
  }, [youtubeLink]);

  return (
    <div>
      <br></br>
      <TextField
        fullWidth
        label="Enter youtube url"
        value={youtubeLink}
        onChange={(e) => setYoutubeLink(e.target.value)}
      />
    </div>
  );
};
export default TutorialVideoContentEditor;

export const TutorialVideoContentEditorPreview = ({ tutorial }) => {
  const { youtubeLink } = JSON.parse(tutorial.text);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button startIcon={<PreviewIcon />} onClick={handleClickOpen}>
        Preview
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{tutorial.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ReactPlayer
              style={{ paddingLeft: 16, marginBottom: 20 }}
              width="100%"
              url={youtubeLink}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const EditVideoContentEditor = ({ tutorial, updateVideoTutorial }) => {
  const [open, setOpen] = React.useState(false);

  const [title, setTitle] = useState(tutorial.title);
  let temp = tutorial.text;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitSave = () => {
    updateVideoTutorial(temp, title);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Edit />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{tutorial.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TutorialTitle
              title={title}
              setTitle={setTitle}
              placeHolder={"Title"}
            />
            <TutorialVideoContentEditor
              onChangeNodeContent={(data) => {
                temp = data;
              }}
              rawContent={tutorial.text}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={onSubmitSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
