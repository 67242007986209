import { sub } from "date-fns";

const REAL_DOG = "https://real.dog/";
const CANINECARE = "https://canine.care/";
const FEEDREAL = "https://feedreal.com/articles/";

export const FEEDREAL_LOGO =
  "https://realdog-s3.s3.us-west-1.amazonaws.com/FR-logo-transparent.png";
export const BEAST_VILLAGE_LOGO =
  "https://caninewellness-s3.s3.amazonaws.com/bv-logo-white-small.png";

export const FEEDREAL_CONTENT = "feedrealContent";
export const TEXT_EDITOR_CONTENT = "textEditorContent";
export const VIDEO_CONTENT = "videoContent";
export const QUIZ_CONTENT = "quizContent";

export const MULTIPLE_CHOICE = "MULTIPLE_CHOICE";
export const QUESTION_ANSWER = "QUESTION_ANSWER";
export const FINAL_EXAM = "FINAL_EXAM";

export const CUSTOMER_TYPES = [
  {
    value: "REGULAR_MEMBER",
    label: "Regular Member",
  },
  {
    value: "AFFILIATE",
    label: "Affiliate",
  },
  {
    value: "RETAILER",
    label: "Retailer",
  },
  {
    value: "VET",
    label: "Vet",
  },
  {
    value: "PARTNER",
    label: "Partner",
  },
  {
    value: "EMPLOYEE",
    label: "Employee",
  },
  {
    value: "BANNED",
    label: "BANNED",
  },
];

export const precisionRoundMod = (number, float) => {
  if (float < 0) {
    float = 0;
  }
  let n = parseFloat(number).toFixed(float);
  return parseFloat(n);
};
export const createRandKey = (length = 6) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function getSubscription(subs, type = "rdb_membership") {
  const sub = subs.find((s) => s.type === type);
  if (!sub) return false;
  return sub;
}
export const pageLinkGenerator = (page, sefurl, category) => {
  if (page === "real.dog") {
    return REAL_DOG + sefurl;
  }
  if (page === "feedreal.com") {
    return FEEDREAL + sefurl;
  }
  if (page === "canine.care") {
    return CANINECARE + category + "/" + sefurl;
  }
};
export const sefUrlGenerator = (text) => {
  return text
    .trim()
    .toLowerCase()
    .replace(/[^\w-_ ]+/g, "")
    .replace(/ +/g, "-");
};

export const permissionState = {
  god: {
    admin: false,
  },
  inventory: {
    admin: false,
    process: false,
    read: false,
    display: false,
  },
  author: {
    admin: false,
    write: false,
    read: false,
  },
  underhood: {
    admin: false,
    write: false,
    read: false,
  },
  secretshop: {
    admin: false,
    write: false,
    read: false,
  },
  affiliate: {
    admin: false,
    write: false,
    read: false,
  },
  shipping: {
    admin: false,
    write: false,
    read: false,
  },
  customer: {
    admin: false,
    write: false,
    read: false,
  },
  accountManager: {
    admin: false,
    write: false,
    read: false,
  },
};

export const MENU_LIST = [
  {
    title: "Beastvillage",
    link: "https://www.beastvillage.org",
  },
  {
    title: "Underhood",
    link: "https://underhood.beastvillage.org/",
  },
];

export const formatFloat = (value) => {
  const epsilonN = (N) => (num) => Math.round(num * N + Number.EPSILON) / N;
  const epsilon2 = epsilonN(1e2);
  return epsilon2(value);
};

export const MESSAGE_TYPES = {
  secretshop_drop: "secretshop_drop",
  gold_members: "gold_members",
  active_subs: "active_subs",
  affiliates: "affiliates",
};
