import { useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { CREATE_GIFT_GROUP } from "../../../../queries";

const AddGiftGroup = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [onlyOne, setOnlyOne] = useState(true);

  const [createGiftGroup, { loading }] = useMutation(CREATE_GIFT_GROUP);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
        Create Gift Group
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ minWidth: "200px" }}
      >
        <DialogTitle id="alert-dialog-title">
          {"    Create Gift Group"}
        </DialogTitle>
        <DialogContent>
          <FormGroup
            sx={{
              "& .MuiFormControl-root": {
                marginBottom: "5px",
                marginTop: "5px",
              },
            }}
          >
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  value={onlyOne}
                  onChange={() => {
                    setOnlyOne(!onlyOne);
                  }}
                />
              }
              label={onlyOne ? "One Claim Only" : "No Limit"}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              if (name.length < 1) {
                enqueueSnackbar("Name cannot be empty", { variant: "error" });
                return;
              }
              createGiftGroup({
                variables: {
                  input: {
                    name: name,
                    onlyOne: onlyOne,
                  },
                },
              })
                .then((res) => {
                  enqueueSnackbar("Gift Group Created", { variant: "success" });
                  props.refetch();
                  handleClose();
                })
                .catch((e) => {
                  enqueueSnackbar(e.message, { variant: "error" });
                });
            }}
            autoFocus
            color="secondary"
          >
            {loading ? <CircularProgress /> : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddGiftGroup;
