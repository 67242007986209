import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import NumberFormat from "react-number-format";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import EventNoteIcon from "@mui/icons-material/EventNote";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { GET_REF_LINKS } from "../../../../../queries";
import { useLazyQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment-timezone";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginTop: 20,
  },
}));

function renderRow(props) {
  const { index, style } = props;

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton>
        <ListItemText primary={`Item ${index + 1}`} />
      </ListItemButton>
    </ListItem>
  );
}

const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

const RefLinks = ({ id, username }) => {
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    getLinks({ variables: { id } });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateField = (e) => {
    setNote(e.target.value);
  };

  const [getLinks, { loading, error, data }] = useLazyQuery(GET_REF_LINKS, {
    fetchPolicy: "no-cache",
  });

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );

  if (error) return <div>Error.</div>;

  return (
    <div>
      <Button
        style={{ zIndex: 2, marginTop: "10px" }}
        fullWidth
        variant="outlined"
        startIcon={<SupervisedUserCircleIcon />}
        color="primary"
        onClick={handleClickOpen}
        className=" btn-text-left btn-text-margin-left"
      >
        Linked Accounts
      </Button>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {loading ? (
          <div>
            <div>
              <LinearProgress color="secondary" />
            </div>
          </div>
        ) : (
          <div>
            <DialogTitle id="alert-dialog-title">
              Reffered/Affiliated <small>by {username}</small>{" "}
              <span className="totalref">
                {data && `Total: ${data.getAssociatedLinks.length}`}
              </span>
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    maxHeight: 300,
                  }}
                >
                  <nav aria-label="main mailbox folders">
                    <List>
                      {data
                        ? data.getAssociatedLinks.map((customer, index) => (
                            <ListItem disablePadding index={index}>
                              <ListItemButton
                                button
                                component={Link}
                                target="_blank"
                                to={`/dashboard/customer/${customer.id}`}
                              >
                                <ListItemText>
                                  <div className="refdetailtext">
                                    <span>
                                      {`${index + 1}. ${
                                        customer.email
                                          ? customer.email
                                          : customer.username
                                          ? customer.username
                                          : customer.name
                                          ? customer.name
                                          : "Member"
                                      }`}
                                    </span>
                                    <span style={{ textAlign: "right" }}>
                                      {customer.phone_number
                                        ? `(${customer.phone_number})`
                                        : ""}
                                    </span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        textAlign: "right",
                                      }}
                                    >
                                      {customer.created_at
                                        ? moment
                                            .unix(customer.created_at)
                                            .format("YYYY/MM/DD")
                                        : ""}
                                    </span>
                                  </div>
                                </ListItemText>
                              </ListItemButton>
                            </ListItem>
                          ))
                        : ""}
                    </List>
                  </nav>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default RefLinks;
