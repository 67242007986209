import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, TextField } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import { useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
export default function FilterByDate({ onFilterDate, onDateFilterUnset }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  const onFilter = () => {
    onFilterDate({
      start: parseInt(start.getTime() / 1000),
      end: parseInt(end.getTime() / 1000),
    });
    handleClose();
  };

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <FilterAltIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {"select the start and end dates"}
        </DialogTitle>
        <DialogContent style={{ paddingTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <DatePicker
              views={["day"]}
              inputFormat="MM/dd/yyyy"
              label="Start Date"
              value={start}
              onChange={(newValue) => {
                setStart(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} fullWidth />
              )}
            />
          </DialogContentText>
          <br />
          <DialogContentText id="alert-dialog-description">
            <DatePicker
              views={["day"]}
              inputFormat="MM/dd/yyyy"
              label="End Date"
              value={end}
              minDate={start}
              onChange={(newValue) => {
                setEnd(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} fullWidth />
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
          <Button
            onClick={() => {
              handleClose();
              onDateFilterUnset();
            }}
          >
            unset
          </Button>
          <Button onClick={onFilter}>filter</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
