import React, { Component, useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client";
import { useHistory } from "react-router-dom";

import { ARTICLE_FIND_SHORTLEASH } from "../../../../../queries";
import moment from "moment";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "16px",
    marginBottom: "8px",
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function AutoSearch(props) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [dataset, setDataset] = useState([]);
  const [website, setWebsite] = useState("");
  const isInitialMount = useRef(true);
  const [open, setOpen] = useState(false);
  const [getArticles, { loading: loadingSearch, data: searchData }] =
    useLazyQuery(ARTICLE_FIND_SHORTLEASH, {
      fetchPolicy: "no-cache",
      variables: {
        key: search,
        website,
      },
      onCompleted: (data) => {
        if (data) {
          setDataset(data.shortleashSearchAdmin);
        }
      },
    });

  useEffect(() => {
    if (!isInitialMount.current) {
      getArticles();
      isInitialMount.current = true;
    }
  }, [search]);

  const searchInput = (event) => {
    const { value } = event.target;
    if (value.length > 2) {
      isInitialMount.current = false;
      setSearch(value);
    }
  };

  const selectWebsite = (event) => {
    const { value } = event.target;
    if (value) {
      setWebsite(value);
      setOpen(true);
      isInitialMount.current = false;
    }
  };

  const onMouseLeave = (event) => {
    setOpen(false);
  };

  const onMouseOver = (event) => {
    setOpen(true);
  };

  return (
    <AutoSearcUI
      history={props.history}
      edit={props.edit}
      searchInput={searchInput}
      classes={classes}
      dataset={dataset}
      search={search}
      loadingSearch={loadingSearch}
      open={open}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      website={website}
      selectWebsite={selectWebsite}
    />
  );
}

export class AutoSearcUI extends Component {
  render() {
    const {
      searchInput,
      dataset,
      search,
      history,
      loadingSearch,
      edit,
      classes,
      open,
      onMouseLeave,
      onMouseOver,
      website,
      selectWebsite,
    } = this.props;
    return (
      <Grid
        container
        spacing={3}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <Grid item xs={9}>
          <Autocomplete
            id="REALDOG - REAL BLOG SEARCH"
            noOptionsText={"Autocomplete Search Feature"}
            loading={loadingSearch}
            options={dataset}
            valueInput={search}
            getOptionLabel={(option) => option.title}
            open={open}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <div className="searchresult">
                  <b>{option.title} </b>
                  <span>{option.status ? "Active" : "Draft"}</span>
                </div>
              </li>
            )}
            onChange={(e, value) => {
              let newValue = value || "";
              return history.push(`/dashboard/newsletter/edit/${newValue.id}`);
            }}
            renderInput={(articles) => (
              <TextField
                {...articles}
                label="Search Shortleash"
                margin="normal"
                onChange={searchInput}
                variant="outlined"
                InputProps={{
                  ...articles.InputProps,
                  value: search,
                  type: "search",
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Filter Website
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={website}
              onChange={selectWebsite}
            >
              <MenuItem value={"shortleash.co"}>Shortleash.co</MenuItem>
              <MenuItem value={"canine.care"}>Canine.care</MenuItem>
              <MenuItem value={"feedreal.com"}>Feedreal.com</MenuItem>
              <MenuItem value={"real.dog"}>Real.dog</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

export default AutoSearch;
