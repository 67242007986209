import React from "react";
import { withRouter } from "react-router-dom";
import { ApolloConsumer } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

const onClick = (history, client) => {
  localStorage.removeItem("token");
  history.push("/");
  client.resetStore();
};

const Logout = ({ history }) => (
  <ApolloConsumer>
    {(client) => {
      return (
        <ListItem
          onClick={() => {
            onClick(history, client);
          }}
          sx={{
            cursor: "pointer",
            paddingBottom: "0px",
            paddingTop: "0px",
            ["&:hover"]: {
              backgroundColor: "#ffffff15",
            },
          }}
        >
          <ListItemIcon>
            <LogoutIcon color="error" />
          </ListItemIcon>
          <ListItemText
            primary={
              <p
                style={{
                  fontSize: 14,
                }}
              >
                Logout
              </p>
            }
          />
        </ListItem>
      );
    }}
  </ApolloConsumer>
);

export default withRouter(Logout);
