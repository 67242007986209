import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation, useQuery } from "@apollo/client";

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import {
  CHANGE_ACCOUNT_MANAGER,
  GET_ACCOUNT_MANAGER,
  GET_SUPPORT_MEMBERS,
} from "../../../../../queries";

const useStyles = makeStyles((theme) => ({
  parent: {
    position: "relative",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000085",
    position: "absolute",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
}));

const STMOfCustomer = ({ customer, refetch }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [accountManager, setAccountManager] = useState("");

  const { loading, data, error } = useQuery(GET_ACCOUNT_MANAGER);
  const [
    changeAccountManager,
    {
      loading: accountChangeLoading,
      data: accountChangeData,
      error: accountChangeError,
    },
  ] = useMutation(CHANGE_ACCOUNT_MANAGER);

  useEffect(() => {
    if (data) {
      let stm = data.accountManagers.find(
        (item) => item.id === customer.accountManager?.id
      );
      if (stm) {
        setAccountManager(stm);
      }
    }
  }, [customer, loading, data, error]);
  const onChangeAccountManager = (data) => {
    changeAccountManager({
      variables: {
        id: customer.id,
        managerId: data.id,
      },
    })
      .then((res) => {
        enqueueSnackbar("Account Manager Updated Successfully", {
          variant: "success",
        });
        refetch();
      })
      .catch((error) => {
        enqueueSnackbar("Account Manager Could not Updated ", {
          variant: "error",
        });
      });
  };
  return (
    <Grid
      className={classes.parent}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {/* <Grid item xs={12}>
        <Typography variant="caption">Account Manager</Typography>
      </Grid> */}
      <Backdrop
        className={classes.backdrop}
        open={loading || accountChangeLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>{accountManager?.name || "No account manager"}</Typography>
        {!loading && data?.accountManagers && (
          <SetAccountManager
            stmList={data.accountManagers}
            accountManager={accountManager}
            setAccountManager={(res) => {
              onChangeAccountManager(res);
            }}
            refetch={refetch}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default STMOfCustomer;

const ITEM_HEIGHT = 48;

const SetAccountManager = ({ stmList, accountManager, setAccountManager }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ height: "22px !important" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {stmList.map((option) => (
          <ConfirmButton
            accountManager={option}
            selected={option === accountManager}
            setAccountManager={(data) => {
              setAccountManager(data);
              handleClose();
            }}
          />
          //   <MenuItem
          //     key={option}
          //     selected={option === accountManager}
          //     onClick={() => {
          //       setAccountManager(option);
          //       handleClose();
          //     }}
          //   >
          //     {option}
          //   </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const ConfirmButton = ({ accountManager, selected, setAccountManager }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickConfirm = () => {
    setAccountManager(accountManager);
    handleClose();
  };

  return (
    <div>
      {/* <Button fullWidth onClick={handleClickOpen}>
        {accountManager}
      </Button> */}
      <MenuItem
        key={accountManager}
        selected={selected}
        onClick={() => {
          handleClickOpen();
        }}
      >
        {accountManager.name}
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to update account manager?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Account manager will be updated as {accountManager.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              onClickConfirm();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
