import { gql } from "@apollo/client";

const GET_DIRECTIRY_STATES = gql`
  query GetDirectoryStates {
    getDirectoryStates {
      id
      name
      coordinates {
        lat
        lng
      }
      companies {
        id
        name
        website
        email
        phone
        city
        socialMediaAdress
        information
        category
        zipCode
        latitude
        longitude
      }
    }
  }
`;

const ADD_COMPANY_TO_DIRECTORY_STATE = gql`
  mutation AddCompanyToDirectoryState($id: ID, $input: inputCompany) {
    addCompanyToDirectoryState(id: $id, input: $input) {
      id
    }
  }
`;
const UPDATE_COMPANY_OF_DIRECTORY_STATE = gql`
  mutation UpdateCompanyOfDirectoryState(
    $id: ID
    $companyId: ID
    $input: inputCompany
  ) {
    updateCompanyOfDirectoryState(
      id: $id
      companyId: $companyId
      input: $input
    ) {
      id
    }
  }
`;
const GET_DIRECTIRY_STATE = gql`
  query GetDirectoryState($id: ID) {
    getDirectoryState(id: $id) {
      id
      name
      coordinates {
        lat
        lng
      }
      companies {
        id
        name
        website
        email
        phone
        city
        socialMediaAdress
        information
        category
        zipCode
        latitude
        longitude
      }
    }
  }
`;
export {
  GET_DIRECTIRY_STATES,
  GET_DIRECTIRY_STATE,
  ADD_COMPANY_TO_DIRECTORY_STATE,
  UPDATE_COMPANY_OF_DIRECTORY_STATE,
};
