import { gql } from "@apollo/client";

const GET_UNDERHOOD_LIST = gql`
  query {
    underhoods {
      id
      title
      text
      sefurl
      status
      main
      createdAt
      updatedAt
      site
      order
      locked
      admin {
        id
        name
        surname
      }
    }
  }
`;

const GET_CANINE_BLOGS = gql`
  query ($start: Int!, $end: Int!) {
    caninecareLimit(start: $start, end: $end) {
      id
      title
      text
      sefurl
      status
      category
      website
      createdAt
      updatedAt
      locked
      articleLock
      admin {
        id
        name
        surname
      }
    }
    caninecareAllFields(start: $start, end: $end) {
      length
      hasNextPage
    }
  }
`;

const ADD_CANINE_BLOG = gql`
  mutation (
    $title: String!
    $text: String!
    $category: String!
    $website: String!
    $admin_id: ID!
    $status: Boolean!
    $locked: Boolean!
    $featured_img: String
    $createdDate: Int
    $articleLock: Boolean
  ) {
    createCaninecare(
      data: {
        title: $title
        text: $text
        category: $category
        website: $website
        admin_id: $admin_id
        status: $status
        locked: $locked
        featured_img: $featured_img
        createdDate: $createdDate
        articleLock: $articleLock
      }
    ) {
      id
    }
  }
`;

const ADD_UNDERHOOD_PAGE = gql`
  mutation (
    $title: String!
    $text: String!
    $admin_id: ID!
    $status: Boolean!
    $locked: Boolean!
    $site: String
    $order: Int
    $main: Boolean
  ) {
    createUnderhood(
      data: {
        title: $title
        text: $text
        admin_id: $admin_id
        status: $status
        locked: $locked
        site: $site
        order: $order
        main: $main
      }
    ) {
      id
    }
  }
`;

const GET_BLOG = gql`
  query ($id: ID!) {
    caninecare(id: $id) {
      id
      title
      text
      status
      sefurl
      featured_img
      category
      website
      locked
      updatedAt
      articleLock
      admin {
        id
      }
    }
  }
`;

const GET_UNDERHOOD = gql`
  query ($id: ID!) {
    underhood(id: $id) {
      id
      title
      text
      status
      sefurl
      locked
      main
      site
      order
      updatedAt
      admin {
        id
      }
    }
  }
`;

const UPDATE_CANINE_BLOG = gql`
  mutation (
    $id: ID!
    $title: String!
    $text: String!
    $category: String!
    $website: String!
    $admin_id: ID!
    $status: Boolean!
    $featured_img: String
    $articleLock: Boolean
  ) {
    editCaninecare(
      id: $id
      data: {
        title: $title
        text: $text
        category: $category
        website: $website
        admin_id: $admin_id
        status: $status
        featured_img: $featured_img
        articleLock: $articleLock
      }
    ) {
      id
      title
      createdAt
      updatedAt
      text
      featured_img
      category
      website
      status
      locked
      articleLock
      admin {
        id
        name
        surname
      }
    }
  }
`;

const UPDATE_UNDERHOOD_PAGE = gql`
  mutation (
    $id: ID!
    $title: String!
    $text: String!
    $admin_id: ID!
    $status: Boolean!
    $site: String
    $order: Int
    $main: Boolean
  ) {
    editUnderhood(
      id: $id
      data: {
        title: $title
        text: $text
        admin_id: $admin_id
        status: $status
        site: $site
        order: $order
        main: $main
      }
    ) {
      id
      title
      createdAt
      updatedAt
      text
      status
      main
      locked
      admin {
        id
        name
        surname
      }
    }
  }
`;

const CANINE_CREATE = gql`
  subscription {
    caninecare {
      id
      title
      text
      sefurl
      status
      createdAt
      updatedAt
      category
      locked
      articleLock
      admin {
        id
        name
        surname
      }
    }
  }
`;

const UNDERHOOD_CREATE = gql`
  subscription {
    underhood {
      id
      title
      text
      sefurl
      status
      createdAt
      updatedAt
      locked
      admin {
        id
        name
        surname
      }
    }
  }
`;

const LOCKED_BLOG = gql`
  mutation ($id: ID!, $locked: Boolean!) {
    lockedCanine(id: $id, data: { locked: $locked }) {
      locked
    }
  }
`;

const LOCKED_UNDERHOOD = gql`
  mutation ($id: ID!, $locked: Boolean!) {
    lockedUnderhood(id: $id, data: { locked: $locked }) {
      locked
    }
  }
`;

const TITLE_FIND = gql`
  query ($filter: String, $website: String) {
    titleFind(filter: $filter, website: $website) {
      title
      sefurl
      id
      website
    }
  }
`;

const ARTICLE_FIND = gql`
  query ($key: String, $website: String) {
    articleFind(key: $key, website: $website) {
      id
      title
      sefurl
      status
      website
    }
  }
`;

const PAGES_FIND = gql`
  query ($key: String, $website: String) {
    pagesFind(key: $key, website: $website) {
      id
      title
      sefurl
      status
      website
    }
  }
`;

const GET_FEEDREALS = gql`
  query ($offset: Int, $limit: Int) {
    feedreals(filter: $offset, limit: $limit) {
      id
      title
      sefurl
      category
      status
      linkedKeywords {
        word
        sefurl
      }
    }
    feedrealsCount
  }
`;
const GET_FEEDREALS_TITLE = gql`
  query FeedrealTitles {
    feedrealTitles
  }
`;

const ADD_FEEDREAL = gql`
  mutation (
    $title: String!
    $shortText: String!
    $keywords: [String]!
    $category: String!
    $admin: ID!
    $status: Boolean!
    $node: [Nodes]
    $relatedArticles: [RelatedArticles]
    $website: String
    $createdAt: Int
    $linkedKeywords: [KeywordUrl]
  ) {
    createFeedreal(
      data: {
        title: $title
        shortText: $shortText
        category: $category
        website: $website
        admin_id: $admin
        status: $status
        keywords: $keywords
        nodes: $node
        relatedArticles: $relatedArticles
        createdAt: $createdAt
        linkedKeywords: $linkedKeywords
      }
    ) {
      id
    }
  }
`;

const UPDATE_FEEDREAL = gql`
  mutation (
    $title: String!
    $shortText: String!
    $keywords: [String]!
    $category: String!
    $admin: ID!
    $status: Boolean!
    $node: [Nodes]
    $relatedArticles: [RelatedArticles]
    $website: String!
    $updatedAt: Int
    $id: ID!
    $linkedKeywords: [KeywordUrl]
  ) {
    editFeedreal(
      id: $id
      data: {
        title: $title
        shortText: $shortText
        category: $category
        website: $website
        admin_id: $admin
        status: $status
        keywords: $keywords
        nodes: $node
        relatedArticles: $relatedArticles
        updatedAt: $updatedAt
        linkedKeywords: $linkedKeywords
      }
    ) {
      id
      title
      shortText
      keywords
      category
      status
      nodes {
        title
        html
        thumbnail
      }
      relatedArticles {
        link
        title
      }
      admin {
        id
      }
    }
  }
`;

const MANUAL_KEYWORD_LINKING = gql`
  mutation GlossaryKeywordLinking(
    $id: ID!
    $data: GlossaryKeywordLinkingInput
  ) {
    glossaryKeywordLinking(id: $id, data: $data) {
      id
    }
  }
`;

const GET_FEEDREAL = gql`
  query ($id: ID!) {
    feedrealGet(id: $id) {
      id
      title
      sefurl
      shortText
      keywords
      category
      status
      nodes {
        title
        html
        thumbnail
      }
      relatedArticles {
        link
        title
      }
      admin {
        id
      }
      linkedKeywords {
        word
        sefurl
      }
    }
  }
`;

const GET_SHORTLEASH_BLOGS = gql`
  query ($start: Int!, $end: Int!) {
    shortleashLimit(start: $start, end: $end) {
      id
      title
      sefurl
      status
      createdAt
    }
    shortleashAllField(start: $start, end: $end) {
      length
      hasNextPage
    }
  }
`;

const ADD_SHORTLEASH_BLOG = gql`
  mutation (
    $title: String!
    $shortText: String!
    $keywords: [String]!
    $admin: ID!
    $status: Boolean!
    $node: [Nodes]
    $relatedArticles: [RelatedArticles]
    $createdAt: Int
  ) {
    createShortleash(
      data: {
        title: $title
        shortText: $shortText
        admin_id: $admin
        status: $status
        keywords: $keywords
        nodes: $node
        relatedArticles: $relatedArticles
        createdAt: $createdAt
      }
    ) {
      id
    }
  }
`;

const CREATE_SHORTLEASH = gql`
  mutation CreateShortleash($input: inputShortleash) {
    createShortleash(input: $input) {
      id
      title
      admin_id
      sefurl
      status
      leashes {
        title
        sefurl
        html
        thumbnail
      }
    }
  }
`;

const UPDATE_SHORTLEASH = gql`
  mutation EditShortleash($id: ID!, $input: inputShortleash) {
    editShortleash(id: $id, input: $input) {
      title
      admin_id
      sefurl
      status
      website
      leashes {
        title
        sefurl
        html
        thumbnail
      }
    }
  }
`;

const GET_SHORTLEASH = gql`
  query Shortleash($id: ID!) {
    getShortleashByIdAdmin(id: $id) {
      id
      title
      sefurl
      admin_id
      status
      website
      leashes {
        title
        sefurl
        html
        thumbnail
      }
    }
  }
`;

const GET_SHORTLEASHES = gql`
  query ($start: Int!, $end: Int!) {
    shortleashLimitAdmin(start: $start, end: $end) {
      id
      title
      sefurl
      status
      website
      admin {
        name
      }
      createdAt
    }
    shortleashAllFieldsAdmin(start: $start, end: $end) {
      length
      hasNextPage
    }
  }
`;

const GET_LANDINGLOGS = gql`
  query ($offset: Int, $limit: Int, $from: String) {
    landingLogsAdmin(offset: $offset, limit: $limit, from: $from) {
      id
      data
      createdAt
    }
    landingLogsCount(from: $from)
  }
`;

const FROM_FIND = gql`
  query ($from: String) {
    fromFind(from: $from)
  }
`;

const ARTICLE_FIND_SHORTLEASH = gql`
  query ($website: String, $key: String) {
    shortleashSearchAdmin(website: $website, key: $key) {
      id
      title
      sefurl
      status
    }
  }
`;

const CREATE_PAGE = gql`
  mutation CreatePage($input: CreatePageInput) {
    createPage(input: $input) {
      id
      title
      sefurl
      sefurlLock
      website
      category
      createdAt
      updatedAt
      featured_img
      showFeaturedImg
      nodes {
        type
        text
      }
      status
      review
      reviewLock
      articleLock
      admin {
        email
      }
      seo
    }
  }
`;

const UPDATE_PAGE = gql`
  mutation UpdatePage($id: ID!, $input: CreatePageInput) {
    updatePage(id: $id, input: $input) {
      id
      title
      sefurl
      sefurlLock
      website
      category
      createdAt
      updatedAt
      featured_img
      showFeaturedImg
      nodes {
        type
        text
      }
      status
      review
      reviewLock
      articleLock
      courseLock
      admin {
        email
      }
      seo
    }
  }
`;

const GET_SINGLE_PAGE = gql`
  query GetPage($id: ID!) {
    getPageByIdAdmin(id: $id) {
      id
      title
      sefurl
      sefurlLock
      website
      category
      createdAt
      updatedAt
      featured_img
      showFeaturedImg
      authors {
        id
        name
        surname
        title
        email
        status
        permission
      }
      nodes {
        type
        text
      }
      status
      review
      reviewLock
      articleLock
      courseLock
      admin {
        id
        email
      }
      redirect_sefurls
      seo
    }
  }
`;

const GET_PAGES = gql`
  query GetPages(
    $limit: Int
    $offset: Int
    $website: String
    $category: String
  ) {
    getPagesAdmin(
      limit: $limit
      offset: $offset
      website: $website
      category: $category
    ) {
      id
      title
      sefurl
      website
      category
      createdAt
      updatedAt
      featured_img
      nodes {
        type
        text
      }
      status
      review
      reviewLock
      articleLock
      admin {
        email
        name
      }
    }
    pagesCount(website: $website)
  }
`;

const PAGES_COUNT = gql`
  query PagesCount($website: String) {
    pagesCount(website: $website)
  }
`;

const GET_KEYWORDS = gql`
  query GetKeywords {
    getKeywords {
      word
      sefurl
    }
  }
`;

export {
  GET_UNDERHOOD_LIST,
  GET_CANINE_BLOGS,
  ADD_CANINE_BLOG,
  ADD_UNDERHOOD_PAGE,
  GET_BLOG,
  GET_UNDERHOOD,
  UPDATE_CANINE_BLOG,
  UPDATE_UNDERHOOD_PAGE,
  CANINE_CREATE,
  UNDERHOOD_CREATE,
  LOCKED_BLOG,
  LOCKED_UNDERHOOD,
  TITLE_FIND,
  ARTICLE_FIND,
  PAGES_FIND,
  GET_FEEDREALS,
  GET_FEEDREALS_TITLE,
  ADD_FEEDREAL,
  UPDATE_FEEDREAL,
  MANUAL_KEYWORD_LINKING,
  GET_FEEDREAL,
  GET_SHORTLEASH_BLOGS,
  ADD_SHORTLEASH_BLOG,
  CREATE_SHORTLEASH,
  UPDATE_SHORTLEASH,
  GET_SHORTLEASH,
  GET_SHORTLEASHES,
  ARTICLE_FIND_SHORTLEASH,
  CREATE_PAGE,
  UPDATE_PAGE,
  GET_SINGLE_PAGE,
  GET_PAGES,
  GET_KEYWORDS,
  PAGES_COUNT,
  GET_LANDINGLOGS,
  FROM_FIND,
};
