import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button, CircularProgress, Grid } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_SAVED_SCHEDULE,
  FETCH_SAVED_SCHEDULE,
} from "../../../../../queries";
import { useSnackbar } from "notistack";

import Menu from "@mui/material/Menu";
import SavedScheduleDetail from "./scheduleDetail";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000095",
    position: "absolute",
  },
}));

export default function SelectTimes({
  id,
  scheduleId,
  setScheduleId,
  disabled,
}) {
  const [menuList, setMenuList] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const handleChange = (event) => {
    setScheduleId(event.target.value);
  };

  const { loading, data, error, refetch } = useQuery(FETCH_SAVED_SCHEDULE, {
    variables: {
      supportMembeId: id,
    },
  });
  useEffect(() => {
    if (data) {
      setMenuList(data.getSchedulePresets);
    }
  }, [data]);

  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [schedule, setSchedule] = useState(Array(20).fill(false));
  const [name, setName] = useState("");

  const [createSaved, { loading: createScheduleLoading }] = useMutation(
    CREATE_SAVED_SCHEDULE
  );

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSetValue = (index) => {
    let copy = JSON.parse(JSON.stringify(schedule));
    copy[index] = !copy[index];
    setSchedule(copy);
  };

  const onCreateSavedSchedule = () => {
    let data = {
      name: name,
      admin: id,
      schedules: schedule.map((item, index) => {
        return {
          name: labels[index],
          value: item,
        };
      }),
    };
    console.log(data);
    createSaved({
      variables: {
        input: data,
      },
    })
      .then((res) => {
        refetch();
        // need success page
        console.log(res);
        handleClose();
        enqueueSnackbar(`Your consult created successfully`, {
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(`Your consult is not created `, {
          variant: "success",
        });
      });
  };
  useEffect(() => {
    if (disabled) {
      setExpanded(false);
    }
  }, [disabled]);
  if (loading) {
    return <CircularProgress></CircularProgress>;
  }
  return (
    // <BasicMenu title={"Select"} menuList={menuList} />
    <div>
      <Accordion
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
        disabled={disabled}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {" "}
            {scheduleId === ""
              ? "Select Schedule"
              : data.getSchedulePresets.find((item) => item.id === scheduleId)
                  .name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            padding: 0,
          }}
        >
          {data &&
            data.getSchedulePresets.map((option) => (
              <MenuItem
                key={option.id}
                value={option.id}
                onClick={(e) => {
                  e.stopPropagation();
                  setScheduleId(option.id);
                  setExpanded(false);
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {option.name}
                  <SavedScheduleDetail
                    schedule={option}
                    refetch={refetch}
                    id={id}
                    savedScheduleId={scheduleId}
                  />
                </Grid>
              </MenuItem>
            ))}
          <MenuItem key={"MenuItemTimesDialog"} value={""}>
            <Button variant="outlined" fullWidth onClick={handleClickOpen}>
              <AddCircleIcon />
            </Button>
          </MenuItem>
        </AccordionDetails>
      </Accordion>
      <Dialog open={open} onClose={handleClose} disableEnforceFocus>
        <Backdrop className={classes.backdrop} open={createScheduleLoading}>
          <Typography align="center">
            Please wait
            <br />
            <CircularProgress color="primary" />
          </Typography>
        </Backdrop>
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginTop: 20 }}
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => {
              e.stopPropagation();
              setName(e.target.value);
            }}
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {schedule.map((item, index) => {
              return (
                <Grid item sm={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          color="secondary"
                          checked={item}
                          onClick={() => {
                            onSetValue(index);
                          }}
                        />
                      }
                      label={labels[index]}
                    />
                  </FormGroup>
                </Grid>
              );
            })}
            <Grid item sm={4}>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={schedule.every((item) => item)}
                    onClick={() => {
                      setSchedule(Array(20).fill(true));
                    }}
                  />
                }
                label={"All"}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button onClick={onCreateSavedSchedule} autoFocus>
            create new
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const labels = [
  "09:00-09:20",
  "09:30-09:50",
  "10:00-10:20",
  "10:30-10:50",
  "11:00-11:20",
  "11:30-11:50",
  "12:00-12:20",
  "12:30-12:50",
  "13:00-13:20",
  "13:30-13:50",
  "14:00-14:20",
  "14:30-14:50",
  "15:00-15:20",
  "15:30-15:50",
  "16:00-16:20",
  "16:30-16:50",
  "17:00-17:20",
  "17:30-17:50",
  "18:00-18:20",
  "18:30-18:50",
];
