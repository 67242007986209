import { useQuery } from "@apollo/client";
import Edit from "@mui/icons-material/Edit";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET_PAGES, GET_GIFT_COUPONS } from "../../../queries";
import GenerateButton from "./Components/generateButton";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "coupon", headerName: "Coupon", flex: 1 },
  { field: "amount", headerName: "Amount", flex: 1 },
  { field: "expiryDate", headerName: "Expiry Date", flex: 1 },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
  },
  {
    field: "printedBy",
    headerName: "Printed By",
    flex: 1,
  },
  {
    field: "redeemedBy",
    headerName: "Redeemed By",
    flex: 1,
  },
];

function GiftCodeGeneratorRedeemed(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // query get data
  const { subscribeToMore, loading, error, data, refetch, fetchMore } =
    useQuery(GET_GIFT_COUPONS, {
      variables: {
        limit: rowsPerPage,
        offset: 0,
        type: "redeemed",
      },
    });
  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.getGiftCoupons.length,
        limit: rowsPerPage,
        type: "redeemed",
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;
  const { permission } = props.session.activeAdmin;
  console.log("data", data);

  return (
    <div>
      {data.getGiftCoupons.length > 0 ? (
        <PageUI
          {...data}
          permission={permission}
          loading={loading}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refetch={refetch}
          history={props.history}
        />
      ) : (
        ""
      )}
    </div>
  );
}
const PageUI = (props) => {
  const [god, setGod] = useState(false);
  const [secretshop, setSecretshop] = useState(false);

  const getUserEmail = (user) => {
    if (!user) {
      return "";
    }
    return user.email;
  };
  useEffect(() => {
    setGod(props.permission.god ? props.permission.god.admin : false);
    setSecretshop(
      props.permission.secretshop ? props.permission.secretshop : false
    );
  }, [props.permission]);

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <a color="inherit" href="/gift_code_generate">
              Gift Codes
            </a>
            <Typography color="textPrimary">Redeemed</Typography>
          </Breadcrumbs>
        </div>
        {god || secretshop.admin || secretshop.write ? (
          <div className="right" style={{ marginLeft: "10px" }}>
            <GenerateButton refetch={props.refetch} />
          </div>
        ) : (
          ""
        )}
        {god || secretshop.admin || secretshop.write ? (
          <div className="right">
            <Button
              component={Link}
              variant="outlined"
              color="primary"
              startIcon={<FormatListBulletedIcon />}
              to={"/dashboard/gift_code_generate"}
              sx={{ marginLeft: "10px" }}
            >
              Gift Codes
            </Button>
          </div>
        ) : (
          ""
        )}
        {god || secretshop.admin || secretshop.write ? (
          <div className="right">
            <Button
              component={Link}
              variant="outlined"
              color="primary"
              startIcon={<LocalPrintshopIcon />}
              to={"/dashboard/gift_code_generate/printed"}
              sx={{ marginLeft: "10px" }}
            >
              Printed
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div>
            <div style={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={
                  props.getGiftCoupons && props.getGiftCoupons.length > 0
                    ? props.getGiftCoupons.map((data) => {
                        return {
                          id: data.id,
                          coupon: `${data.coupon.slice(
                            0,
                            4
                          )}-${data.coupon.slice(4, 8)}-${data.coupon.slice(
                            8,
                            12
                          )}-${data.coupon.slice(12, 16)}`,
                          amount: `$${data.amount}`,
                          expiryDate: data.expiryDate
                            ? moment.unix(data.expiryDate).format("MM/DD/YYYY")
                            : "Unlimited",
                          createdBy: data.admin
                            ? getUserEmail(data.admin)
                            : getUserEmail(data.customer),
                          printedBy: data.printedAdmin?.email,
                          redeemedBy: data.customer?.email,
                        };
                      })
                    : ""
                }
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 100]}
              />
            </div>
          </div>
          {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.giftCouponCount ? props.giftCouponCount : 0}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          /> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default GiftCodeGeneratorRedeemed;
