import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import NumberFormat from "react-number-format";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import EventNoteIcon from "@mui/icons-material/EventNote";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { CUSTOMER_NOTES, CUSTOMER_NOTES_ADD } from "../../../../../queries";
import { useMutation, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment-timezone";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginTop: 20,
  },
}));

const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

const CustomerNotes = ({ customerId, me }) => {
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateField = (e) => {
    setNote(e.target.value);
  };

  const { loading, error, data, refetch } = useQuery(CUSTOMER_NOTES, {
    variables: { id: customerId },
    fetchPolicy: "no-cache",
  });

  const [addCustomerNote, { error: errorAddNote, loading: loadingAddNote }] =
    useMutation(CUSTOMER_NOTES_ADD);

  const onSubmit = async () => {
    addCustomerNote({
      variables: {
        admin_id: me,
        customer_id: customerId,
        note: note,
        createdAt: moment().unix(),
      },
    })
      .then(async ({ data }) => {
        setNote("");
        enqueueSnackbar(`Customer notes added!`, {
          variant: "success",
        });
        refetch();
      })
      .catch((e) => {});
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );

  if (error) return <div>Error.</div>;

  return (
    <div>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">
          Add Customer Note
        </InputLabel>
        <OutlinedInput
          style={{ height: 150, paddingBottom: 40 }}
          id="filled-multiline-static"
          multiline
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                type="submit"
                onClick={onSubmit}
                color="secondary"
                size="large"
                disabled={loadingAddNote || !note}
                aria-label="delete"
                size="large"
              >
                <AddCircleIcon fontSize="inherit" />{" "}
                {loadingAddNote && <SpinnerAdornment />}
              </IconButton>
            </InputAdornment>
          }
          rows={4}
          value={note}
          onChange={updateField}
          variant="filled"
          label="Add Customer Note"
        />
      </FormControl>

      <Button
        style={{ border: "none", marginTop: "-70px", zIndex: 2 }}
        fullWidth
        variant="outlined"
        startIcon={<EventNoteIcon />}
        color="secondary"
        onClick={handleClickOpen}
        className=" btn-text-left btn-text-margin-left"
      >
        Customer Notes <b className="notesbtn">{data.customerNotes.length}</b>
      </Button>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {loading ? (
          <div>
            <div>
              <LinearProgress color="secondary" />
            </div>
          </div>
        ) : (
          <div>
            <DialogTitle id="alert-dialog-title">
              {"Customer Notes"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Timeline position="alternate">
                  {data &&
                    data.customerNotes.map((data) => (
                      <TimelineItem>
                        <TimelineOppositeContent color="text.secondary">
                          {moment.unix(data.createdAt).format("DD/MM/YYYY")}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ px: 2 }}>
                          <Typography
                            className="customernote_author"
                            style={{
                              background: "#111214",
                              padding: 10,
                              borderRadius: 5,
                            }}
                          >
                            <b>{data.admin.name}</b>
                            {data.note}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                </Timeline>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default CustomerNotes;
