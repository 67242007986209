import { Breadcrumbs, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TransactionList from "../Course/components/TransactionList";

const CourseTransactionList = (props) => {
  const { permission } = props.session.activeAdmin;
  console.log(permission);

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={"/"}>Dashboard</Link>
            <Link to={"/dashboard/course"}>Course</Link>
            <Typography color="textPrimary">Orders</Typography>
          </Breadcrumbs>
        </div>
      </div>
      {permission.god?.admin ||
      (permission.customer?.admin && permission.author?.admin) ? (
        <TransactionList {...props} />
      ) : (
        <div>Not Authorized</div>
      )}
    </div>
  );
};

export default CourseTransactionList;
