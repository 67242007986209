import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { SIGNIN_USER } from "../../queries";
import { withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";
import { useSnackbar } from "notistack";

import {
  Button,
  CssBaseline,
  TextField,
  Container,
  CircularProgress,
} from "@mui/material";
import { BEAST_VILLAGE_LOGO } from "../../globals";

const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

function Login(props) {
  // state
  const initialState = {
    email: "",
    password: "",
  };
  //
  // useState with initalState
  const [form, setState] = useState(initialState);
  const { enqueueSnackbar } = useSnackbar();

  let { email, password } = form;

  // mutation
  const [signinUser, { loading }] = useMutation(SIGNIN_USER);

  // update onChange form
  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // validate
  const formValidate = () => {
    const { email, password } = form;
    return !email || !password;
  };

  // clear state
  const resetState = () => {
    setState({ ...initialState });
  };

  // onSubmit function
  const onSubmit = (e) => {
    e.preventDefault();
    signinUser({ variables: { email, password } })
      .then(async ({ data }) => {
        localStorage.setItem("token", data.signIn.token);
        await props.refetch();
        resetState();
        props.history.push("/dashboard");
      })
      .catch((e) => {
        enqueueSnackbar(`${e.message}`, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      let { history } = props;
      jwt.verify(token, process.env.REACT_APP_SECRET, (err, decoded) => {
        if (decoded) {
          props.history.push("/dashboard");
        }
      });
    }
  }, [props]);

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className="loginbox">
          <img
            alt="Logo Real Dog"
            src={BEAST_VILLAGE_LOGO}
            style={{
              fill: "white",
              filter: " brightness(0) invert(1)",
              marginBottom: 40,
            }}
          />
          <form onSubmit={onSubmit}>
            <TextField
              onChange={updateField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              value={form.email}
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              onChange={updateField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={form.password}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <br /> <br />
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
              size="large"
              disabled={loading || formValidate()}
            >
              Login Dashboard
              {loading && <SpinnerAdornment />}
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
}

export default withRouter(Login);
