import { gql } from "@apollo/client";

const ROOMS = gql`
  query Rooms {
    getRooms {
      id
      status
      name
      notesCount
    }
  }
`;

const GET_ROOM_NOTES = gql`
  query RoomNotes($id: ID!) {
    getRoomNotes(id: $id) {
      id
      note
      type
      createdAt
      admin {
        email
      }
    }
  }
`;

const CREATE_ROOM = gql`
  mutation Room {
    createRoom(name: "Room 11") {
      id
      name
      status
    }
  }
`;

const CREATE_ROOM_NOTES = gql`
  mutation createNote($input: inputRoomNote) {
    createRoomNote(input: $input) {
      id
      roomId
      note
      type
      createdAt
    }
  }
`;


export {
    ROOMS,
    GET_ROOM_NOTES,
    CREATE_ROOM,
    CREATE_ROOM_NOTES
  };
  