import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";

//Library
import {
  Button,
  CssBaseline,
  TextField,
  Container,
  CircularProgress,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  FormControlLabel,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import moment from "moment-timezone";
import Chip from "@mui/material/Chip";

//utils
import { UploadFile } from "../../Tools/Upload";
import { startSingleUpload } from "../../Funcs/Upload";

//queries
import {
  GET_ADMINS,
  GET_FEEDREAL,
  GET_FEEDREALS,
  GET_FEEDREALS_TITLE,
  MANUAL_KEYWORD_LINKING,
  UPDATE_FEEDREAL,
} from "../../../queries";
import { useSnackbar } from "notistack";
import SimilarKeywordAlert from "./SimilarKeywordAlert";
import { createRandKey } from "../../../globals";
import { Box } from "@mui/system";

const filter = createFilterOptions();

const defaultNodeTitles = [
  {
    title: "Why we feed",
  },
  {
    title: "How to feed",
  },
  {
    title: "Additional info",
  },
];

const GlossaryAdd = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = props.match.params;
  const [form, setForm] = useState({
    title: "",
    category: "",
    admin_id: "",
    shortText: "",
    keywords: [],
  });
  const [status, setStatus] = useState(false);
  const isInitialMount = useRef(true);
  const onAuto = useRef(false);
  const [adminList, setAdminList] = useState([]);
  const [me, setMe] = useState([]);
  const [node, setNode] = useState([]);
  const [relatedArticles, setRelatedArticles] = useState([]);

  const [additionalKeywords, setAdditionalKeywords] = useState([]);
  const [additionalKeyword, setAdditionalKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [warningText, setWarningText] = useState("");
  const [existingAdditionalKeyords, setExistingAdditionalKeywords] = useState(
    []
  );
  const [keywordSearch, setKeywordSearch] = useState("");
  const [glossaryTitles, setGlossaryTitle] = useState([]);

  //UseQuery & Mutation
  const { data: data_admin, loading: loading_admin } = useQuery(GET_ADMINS);
  const { loading: loadingFeedrealTitle } = useQuery(GET_FEEDREALS_TITLE, {
    onCompleted: (res) => {
      setGlossaryTitle(res.feedrealTitles);
    },
  });
  const [edit_feedreal, { error: errorUpdate, loading: loadingAddFeed }] =
    useMutation(UPDATE_FEEDREAL);
  const [
    manualKeywordLınkingMutation,
    { loading: linkingLoading, data: linkingData },
  ] = useMutation(MANUAL_KEYWORD_LINKING);
  const { loading, error, data } = useQuery(GET_FEEDREAL, {
    variables: { id },
  });

  const {
    loading: allFeedrealLoading,
    error: allFeedrealError,
    data: allFeedrealData,
  } = useQuery(GET_FEEDREALS);

  useEffect(() => {
    if (allFeedrealData) {
      setExistingAdditionalKeywords(
        keywordDictionary(
          allFeedrealData.feedreals.map((item) =>
            item.linkedKeywords.map((element) => ({
              word: element.word.toLowerCase(),
              sefurl: element.sefurl,
              glossary: item.title,
            }))
          )
        )
      );
    }
  }, [allFeedrealLoading, allFeedrealError, allFeedrealData]);

  const keywordDictionary = (rawArray) => {
    let localKeywords = [];
    rawArray.forEach((item) => {
      item.forEach((element) => {
        if (!localKeywords.map((item) => item.word).includes(element.word)) {
          localKeywords.push(element);
        }
      });
    });
    return localKeywords;
  };

  //useEffect
  useEffect(() => {
    if (!loading_admin && data_admin) {
      const me = data_admin.admins.filter(
        (x) => x.id === props.session.activeAdmin.id
      );
      setAdminList(data_admin);
      setMe(me[0]);
      setForm({
        ...form,
        admin_id: me[0].id,
      });
    }
  }, [data_admin, loading_admin]);

  useEffect(() => {
    const onCompleted = (data) => {
      setAdditionalKeywords(
        data.feedrealGet.linkedKeywords.map((item) => ({
          word: item.word,
          sefurl: item.sefurl,
        }))
      );

      setForm({
        title: data.feedrealGet.title,
        category: data.feedrealGet.category,
        admin_id: data.feedrealGet.admin.id,
        shortText: data.feedrealGet.shortText,
        keywords: data.feedrealGet.keywords,
      });

      let newNode = [];
      data.feedrealGet.nodes.map((n) =>
        newNode.push({ title: n.title, html: n.html, thumbnail: n.thumbnail })
      );
      setNode(newNode);

      let newRelate = [];
      data.feedrealGet.relatedArticles.map((n) =>
        newRelate.push({ link: n.link, title: n.title })
      );
      setRelatedArticles(newRelate);

      setStatus(data.feedrealGet.status);
      isInitialMount.current = false;
    };
    const onError = (error) => {
      /* magic */
    };
    if (isInitialMount.current) {
      if (onCompleted || onError) {
        if (onCompleted && !loading && !error) {
          onCompleted(data);
        } else if (onError && !loading && error) {
          onError(error);
        }
      }
    }
  }, [loading, data, error]);

  //Default Form Action
  const updateField = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    onAuto.current = true;
  };

  const handleChange = (event) => {
    setStatus(event.target.checked);
  };

  const onSubmit = async () => {
    let keywords = [];

    edit_feedreal({
      variables: {
        id,
        title: form.title,
        shortText: form.shortText,
        keywords: form.keywords,
        category: form.category,
        admin: form.admin_id,
        status,
        node,
        relatedArticles,
        website: "feedreal",
        updatedAt: moment().unix(),
        linkedKeywords: additionalKeywords,
      },
    })
      .then(async ({ data }) => {
        setForm({
          title: data.editFeedreal.title,
          category: data.editFeedreal.category,
          admin_id: data.editFeedreal.admin.id,
          shortText: data.editFeedreal.shortText,
          keywords: data.editFeedreal.keywords,
        });
        enqueueSnackbar("The glossary has been updated successfully.", {
          variant: "success",
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: "error",
        });
      });
  };

  //Node Action
  const handleNodeMore = (e) => {
    setNode((node) => [
      ...node,
      {
        title: "",
        html: "",
        thumbnail: "",
      },
    ]);
  };

  const handleNodeLess = (index) => {
    let copy = JSON.parse(JSON.stringify(node));
    copy.splice(index, 1);
    setNode(copy);
  };

  const changeNodeTitle = (event, newValue, num) => {
    if (typeof newValue === "string") {
      node[num]["title"] = newValue;
    } else if (newValue && newValue.inputValue) {
      node[num]["title"] = newValue.inputValue;
    } else {
      node[num]["title"] = newValue["title"];
    }
    setNode((node) => [...node]);
  };

  const changeNodeText = (event, num) => {
    node[num]["html"] = event.target.value;
    setNode((node) => [...node]);
  };

  let uploadNodePhoto = ({ uri }, num) => {
    node[num]["thumbnail"] = uri;
    setNode((node) => [...node]);
  };

  const resetNodePhoto = (num) => {
    node[num]["thumbnail"] = "";
    setNode((node) => [...node]);
    setNode([...node]);
  };

  //Related Action
  const handleRelatedMore = (e) => {
    setRelatedArticles((relatedArticles) => [
      ...relatedArticles,
      {
        link: "",
        title: "",
      },
    ]);
  };

  const handleRelatedLess = (index) => {
    let copy = JSON.parse(JSON.stringify(relatedArticles));
    copy.splice(index, 1);
    setRelatedArticles(copy);
  };

  const changeRelate = (e, num) => {
    relatedArticles[num][e.target.name] = e.target.value;
    setRelatedArticles((relatedArticles) => [...relatedArticles]);
  };

  const addSimilarKeywords = () => {
    let copy = JSON.parse(JSON.stringify(additionalKeywords));
    copy.push({
      word: additionalKeyword.trim(),
      sefurl: additionalKeyword
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-"),
    });
    setAdditionalKeywords(copy);
    setAdditionalKeyword("");
    setOpen(false);
  };
  const checkIfExistatDicrionary = () => {
    let isKeywordEsist = existingAdditionalKeyords.find(
      (item) =>
        item.word.trim().toLowerCase() ===
        additionalKeyword.trim().toLowerCase()
    );
    if (isKeywordEsist) {
      setWarningText(
        `${additionalKeyword} already exist at glossary => ${isKeywordEsist.glossary}`
      );
      setOpen(true);
      return true;
    }
    return false;
  };
  const checkIfExist = () => {
    let isKeywordEsist = additionalKeywords.find(
      (item) =>
        item.word.trim().toLowerCase() ===
        additionalKeyword.trim().toLowerCase()
    );
    if (isKeywordEsist) {
      enqueueSnackbar("This similar keyword is already esist", {
        variant: "error",
      });
      return true;
    }
    return false;
  };

  const onAddKeyword = () => {
    const copyKeywords = JSON.parse(JSON.stringify(form.keywords));
    copyKeywords.push(keywordSearch);
    setForm((prev) => {
      return { ...prev, keywords: copyKeywords };
    });
    setKeywordSearch("");
  };

  const deleteKeyword = (index) => {
    const copyKeywords = JSON.parse(JSON.stringify(form.keywords));
    copyKeywords.splice(index, 1);
    setForm((prev) => {
      return { ...prev, keywords: copyKeywords };
    });
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <CssBaseline />
        <Grid item xs={9}>
          <div className="">
            <TextField
              variant="outlined"
              inputProps={{ style: { fontSize: 22 } }}
              InputLabelProps={{ style: { fontSize: 22 } }}
              margin="normal"
              required
              fullWidth
              value={form.title}
              name="title"
              label="Title"
              type="text"
              id="title"
              onChange={updateField}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={form.shortText}
              name="shortText"
              label="Short Text"
              type="text"
              id="shortText"
              onChange={updateField}
            />
            {/* <FormControl variant="outlined" className="width100">
              <InputLabel id="category">Select Category</InputLabel>
              <Select
                id="category"
                name="category"
                value={form.category}
                onChange={updateField}
                label="category"
              >
                <MenuItem value="">
                  <em>Select Category</em>
                </MenuItem>
                <MenuItem value="Cate">
                  <em>Cate</em>
                </MenuItem>
                <MenuItem value="Shiba">
                  <em>Shiba</em>
                </MenuItem>
              </Select>
            </FormControl> */}

            {node.map((field, i) => (
              <NodeArea
                num={i}
                lastNode={node.length - 1}
                field={field}
                nodeLess={handleNodeLess}
                moreNode={handleNodeMore}
                changeTitle={changeNodeTitle}
                changeNodeText={changeNodeText}
                uploadNodePhoto={uploadNodePhoto}
                resetNodePhoto={resetNodePhoto}
                length={node.length}
              />
            ))}

            {relatedArticles.map((article, i) => (
              <ArticleArea
                num={i}
                field={article}
                moreRelate={handleRelatedMore}
                relateLess={handleRelatedLess}
                lastRelate={relatedArticles.length - 1}
                changeRelate={changeRelate}
                length={relatedArticles.length}
              />
            ))}
            <br></br>
            <Typography variant="h5">Related Terms</Typography>
            <Typography
              variant="caption"
              style={{
                opacity: 0.7,
                marginTop: -10,
              }}
            >
              words that may be associated with this definition; for example,
              words associated with bacteria would be pathogen, virus, sanitize.
            </Typography>
            <Typography>
              {form.keywords.map((singleKeyword, index) => {
                return (
                  <Chip
                    key={singleKeyword + index}
                    style={{ marginRight: 10, marginTop: 10, marginBottom: 10 }}
                    label={singleKeyword}
                    variant="outlined"
                    onDelete={() => {
                      deleteKeyword(index);
                    }}
                  />
                );
              })}
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={10}>
                <Autocomplete
                  fullWidth
                  size="small"
                  onSubmit={onAddKeyword}
                  id="country-select-demo"
                  sx={{ width: 300 }}
                  value={keywordSearch}
                  onChange={(e, newValue) => {
                    setKeywordSearch(newValue);
                  }}
                  options={glossaryTitles}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      {...params}
                      label="Enter keyword and press add button"
                      // value={keywordSearch}
                      // onChange={(e) => {
                      //   setKeywordSearch(e.target.value);
                      // }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  disabled={keywordSearch === ""}
                  onClick={() => {
                    onAddKeyword();
                  }}
                  fullWidth
                >
                  Add
                </Button>
              </Grid>
            </Grid>

            {/* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={form.keywords}
              name="keywords"
              label="Keywords"
              type="text"
              id="keywords"
              onChange={updateField}
            /> */}
            <br></br>
            <Typography variant="h5">Similar words</Typography>
            <Typography
              variant="caption"
              style={{
                opacity: 0.7,
                marginTop: -10,
              }}
            >
              words that can fall under the same definition; for example, words
              that are similar to bacteria would be bacterium, bacterial.
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <SimilarKeywordAlert
                open={open}
                setOpen={setOpen}
                addSimilarKeywords={addSimilarKeywords}
                text={warningText}
              />
              <Grid xs={8}>
                {additionalKeywords.map((item, index) => {
                  return (
                    <Chip
                      style={{ marginRight: 10, marginTop: 10 }}
                      label={item.word}
                      variant="outlined"
                      onDelete={() => {
                        let copy = JSON.parse(
                          JSON.stringify(additionalKeywords)
                        );
                        copy.splice(index, 1);
                        setAdditionalKeywords(copy);
                      }}
                    />
                  );
                })}
              </Grid>
              <Grid xs={3}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (
                      additionalKeyword.length === 0 ||
                      checkIfExist() ||
                      checkIfExistatDicrionary()
                    ) {
                      return;
                    }

                    addSimilarKeywords();
                  }}
                >
                  <TextField
                    size="small"
                    label="Add Keyword"
                    value={additionalKeyword}
                    onChange={(e) => setAdditionalKeyword(e.target.value)}
                  ></TextField>
                </form>
              </Grid>
              <Grid xs={1}>
                <Button
                  onClick={() => {
                    if (
                      additionalKeyword.length === 0 ||
                      checkIfExist() ||
                      checkIfExistatDicrionary()
                    ) {
                      return;
                    }

                    addSimilarKeywords();
                  }}
                  disabled={additionalKeyword.length === 0}
                >
                  add
                </Button>
              </Grid>
              {/* <Grid xs={4}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    manualKeywordsLinking();
                  }}
                  disabled={additionalKeywords.length === 0 || linkingLoading}
                >
                  Link Manual Keywords
                </Button>
              </Grid> */}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={3}>
          <Paper className="padding10 margintop15">
            <div>
              <FormControl variant="outlined" className="width100">
                <InputLabel id="category">Select Author</InputLabel>
                <Select
                  name="admin_id"
                  value={form.admin_id}
                  onChange={updateField}
                  label="category"
                >
                  <MenuItem value={props.session.activeAdmin.id}>
                    {me.name} (Me)
                  </MenuItem>

                  {adminList.admins &&
                    adminList.admins.map((admin) => {
                      if (admin.id !== props.session.activeAdmin.id) {
                        return (
                          <MenuItem key={admin.id} value={admin.id}>
                            {admin.name}
                          </MenuItem>
                        );
                      }
                    })}
                </Select>
              </FormControl>
            </div>
            <br />
            <FormControlLabel
              control={
                <Switch
                  className="padding10"
                  checked={status}
                  onChange={handleChange}
                  name="status"
                  color="secondary"
                  inputProps={{
                    "aria-label": "secondary checkbox",
                  }}
                />
              }
              label={status ? "Published" : "Archive"}
            />
            <br /> <br />
            <Button
              type="submit"
              fullWidth
              onClick={onSubmit}
              variant="outlined"
              color={status ? "secondary" : "primary"}
              size="large"
              disabled={loadingAddFeed}
            >
              {status ? "PUBLISH" : "SAVE DRAFT"}
              {/* {loadingAddFeed && <SpinnerAdornment />} */}
            </Button>
            {errorUpdate && <div>{errorUpdate.message}</div>}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const ArticleArea = (props) => {
  return (
    <div key={props.num} className="nodes_box">
      <div className="titlebox">Add Related Article</div>
      <Grid container>
        <Grid item xs={12} spacing={1}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="link"
            label="Link"
            type="text"
            id="link"
            defaultValue={props.field.link}
            onChange={(e) => props.changeRelate(e, props.num)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="title"
            label="Title"
            type="text"
            id="titleRelate"
            defaultValue={props.field.title}
            onChange={(e) => props.changeRelate(e, props.num)}
          />
        </Grid>
      </Grid>
      <div>
        {props.lastRelate === props.num && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={props.moreRelate}
            style={{ marginRight: 10 }}
          >
            {" "}
            + Add Related Article{" "}
          </Button>
        )}
        {props.length !== 1 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              props.relateLess(props.num);
            }}
          >
            DELETE
          </Button>
        )}
      </div>
    </div>
  );
};

const NodeArea = (props) => {
  return (
    <div key={props.num} className="nodes_box">
      <Grid container>
        <Grid item xs={9} spacing={1}>
          <div className="titlebox">Add Nodes</div>
          {/* <Autocomplete
            value={props.field.title}
            onChange={(event, newValue) =>
              props.changeTitle(event, newValue, props.num)
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={defaultNodeTitles}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.title;
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            style={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label="Title"
                variant="outlined"
                fullWidth
              />
            )}
          /> */}
          <TextField
            value={props.field.title}
            onChange={(event) =>
              props.changeTitle(event, event.target.value, props.num)
            }
            label="Title"
            variant="outlined"
            fullWidth
            style={{
              marginBottom: 10,
            }}
          />
          <br />
          <TextField
            id="outlined-multiline-static"
            label="Text"
            multiline
            fullWidth
            rows={4}
            placeholder="Change my mind"
            variant="outlined"
            defaultValue={props.field.html}
            onChange={(event) => props.changeNodeText(event, props.num)}
          />
        </Grid>
        <Grid item xs={3} spacing={1}>
          {props.field.thumbnail ? (
            <div className="featuredImgBlock">
              <div className="deletephotobtn">
                <IconButton
                  onClick={() => props.resetNodePhoto(props.num)}
                  color="secondary"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <img width={"100%"} src={props.field.thumbnail} />
            </div>
          ) : (
            <UploadFile
              idKey={"uplad-image-key-" + createRandKey(6)}
              uploadPhoto={(file) => props.uploadNodePhoto(file, props.num)}
              buttonName={"Add Featured Image"}
            />
          )}
        </Grid>
      </Grid>
      <div>
        <br />
        {props.lastNode === props.num ? (
          <Button
            variant="outlined"
            color="secondary"
            onClick={props.moreNode}
            style={{ marginRight: 10 }}
          >
            {" "}
            + Add Node{" "}
          </Button>
        ) : (
          ""
        )}
        {props.length !== 1 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              props.nodeLess(props.num);
            }}
          >
            DELETE
          </Button>
        )}
      </div>
    </div>
  );
};
const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

export default withApollo(GlossaryAdd);
