import React, { Component, useEffect, useState, useRef } from "react";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import { GET_SHIPPING } from "../../../queries";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import moment from "moment-timezone";
import Button from "@mui/material/Button";

export function ShippingDetails(props) {
  const { data, loading, error } = useQuery(GET_SHIPPING, {
    fetchPolicy: "no-cache",
    variables: { id: props.id },
  });

  return (
    <div>
      {loading ? (
        <div>
          <div>
            <LinearProgress color="secondary" />
          </div>
        </div>
      ) : (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              <DialogTitle id="form-dialog-title">
                Shipping Details - {data.getOrder.shipTo.name}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {data.getOrder.customerEmail}
                </DialogContentText>
                <DialogContentText>
                  {data.getOrder.customerNotes}
                </DialogContentText>
                <DialogContentText>
                  {data.getOrder.shipTo.addressVerified}
                </DialogContentText>
                <DialogContentText>
                  {data.getOrder.shipTo.street1} {data.getOrder.shipTo.street2}
                </DialogContentText>
                <DialogContentText>
                  {data.getOrder.shipTo.city} - {data.getOrder.shipTo.state}
                </DialogContentText>
                <DialogContentText>
                  {data.getOrder.shipTo.postalCode}
                </DialogContentText>
              </DialogContent>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={props.close} color="primary">
              Close
            </Button>
          </DialogActions>
        </div>
      )}
    </div>
  );
}
