import React from "react";
import { Query } from '@apollo/client/react/components';

import { GET_ACTIVE_ADMIN } from "../queries";
import { Redirect } from "react-router-dom";

const auth = condition => Component => props => (

  <Query query={GET_ACTIVE_ADMIN}>
    {({ data, loading }) => {
      // console.log(data);
      if (loading) return <div>Loading</div>;
      return condition(data) ? <Component {...props} /> : <Redirect to="/" />;
    }}
  </Query>

);

export default auth;
