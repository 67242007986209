import React, { forwardRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_COURSES,
  PURCHASE_COURSE,
  FETCH_CUSTOMER_COURSES,
} from "../../../../../queries/Tutorial";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useSnackbar } from "notistack";
import SchoolIcon from "@mui/icons-material/School";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GiveCourseAway({ customer }) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [prePurchasedCourses, setPrePurchasedCourses] = useState([]);

  const [fetchPrePurchasedCourses, { loading: loadingRev }] = useLazyQuery(
    FETCH_CUSTOMER_COURSES,

    {
      variables: {
        customerId: customer.id,
      },
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        console.log(res);
        const idMap = res.purchasedCoursesOfCustomer.map(
          (item) => item.courseId
        );

        setPrePurchasedCourses(idMap);
      },
      onError: (err) => {
        const { message } = JSON.parse(JSON.stringify(err));
        enqueueSnackbar(`Error, ${message}`, {
          variant: "error",
        });
      },
    }
  );

  const [getCourses, { loading }] = useLazyQuery(GET_COURSES, {
    onCompleted: (res) => {
      setCourses(res.getCourses);
    },
    onError: (err) => {
      console.log("error", err);
    },
    fetchPolicy: "no-cache",
  });
  const [courseGiveaway, { loading: loadingMutation }] =
    useMutation(PURCHASE_COURSE);
  useEffect(() => {
    if (open) {
      fetchPrePurchasedCourses();
      getCourses();
      setSelectedCourseId("");
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (loading || loadingRev || loadingMutation) {
      return;
    }
    setOpen(false);
  };

  const handleChange = (event) => {
    setSelectedCourseId(event.target.value);
  };

  const onConfirm = () => {
    courseGiveaway({
      variables: {
        courseId: selectedCourseId,
        customerId: customer.id,
        input: {
          charge: false,
        },
      },
    })
      .then((res) => {
        enqueueSnackbar(`Success, course added`, {
          variant: "success",
        });
        handleClose();
      })
      .catch((err) => {
        const { message } = JSON.parse(JSON.stringify(err));
        enqueueSnackbar(`Error, ${message}`, {
          variant: "error",
        });
      });
  };
  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        onClick={handleClickOpen}
        style={{
          color: "#e25065",
          borderColor: "#e25065",
        }}
        startIcon={<SchoolIcon />}
      >
        Courses
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Give the course for free"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {(loading || loadingRev || loadingMutation) && <LinearProgress />}

            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Please select the course that you want to give away
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                {courses.map((item) => {
                  return (
                    <FormControlLabel
                      onChange={handleChange}
                      value={item.id}
                      control={
                        <Checkbox
                          checked={
                            prePurchasedCourses.includes(item.id) ||
                            selectedCourseId === item.id
                          }
                          disabled={prePurchasedCourses.includes(item.id)}
                        />
                      }
                      label={item.title}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            disabled={loading || loadingRev || loadingMutation}
          >
            cancel
          </Button>
          <Confirm
            onConfirm={() => {
              onConfirm();
            }}
            customer={customer}
            selectedCourseName={
              courses.find((item) => item.id === selectedCourseId)?.title || ""
            }
            disabled={
              selectedCourseId === "" ||
              loading ||
              loadingRev ||
              loadingMutation
            }
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Confirm = ({ onConfirm, customer, selectedCourseName, disabled }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} disabled={disabled}>
        Confirm
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to give {selectedCourseName} to{" "}
            {customer.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button
            onClick={() => {
              confirm();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
