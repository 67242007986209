import React, { Component } from "react";
import {
  GET_ACTIVE_USERS,
  GET_ACTIVE_SUBSCRIPTIONS,
  GET_SECRETSHOP_ACTIVE,
  GET_ALL_CUSTOMERS_COUNT,
  MEMBERSHIP_LIST_FILTER_LENGTH,
  PAGES_COUNT,
  GET_NUMBER_OF_COURSE_USER,
} from "../../../queries/";
import { useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CancelationStats from "./Components/cancelatonInfoDialog";
import Statistics from "./Components/statistics";
import TopAffiliates from "./Components/topAffiliates";
import CoursePurchaseDetail from "./CoursePurchaseDetail";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const INTERVALS = {
  12: "Yearly",
  1: "Monthly",
};

function DashboardMain(props) {
  const { permission } = props.session.activeAdmin;
  const classes = useStyles();
  const { loading, error, data, refetch } = useQuery(GET_ACTIVE_USERS);
  const {
    loading: loadingSubscription,
    error: errorSubscription,
    data: dataSubscription,
  } = useQuery(GET_ACTIVE_SUBSCRIPTIONS);
  const {
    loading: loadingSecretshop,
    error: errorSecretshop,
    data: dataSecretshop,
  } = useQuery(GET_SECRETSHOP_ACTIVE);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    data: dataCustomer,
  } = useQuery(GET_ALL_CUSTOMERS_COUNT);
  const { loading: loadingMembershipReal, data: realMembership } = useQuery(
    MEMBERSHIP_LIST_FILTER_LENGTH,
    { variables: { category: "Real.Dog" } }
  );
  const { loading: loadingMembershipFeedreal, data: feedrealMembership } =
    useQuery(MEMBERSHIP_LIST_FILTER_LENGTH, {
      variables: { category: "Feedreal" },
    });

  const {
    loading: loadingFeedrealArticles,
    error: errorFeedrealArticles,
    data: dataFeedrealArticles,
  } = useQuery(PAGES_COUNT, {
    variables: {
      website: "feedreal.com",
    },
  });

  if (
    loading ||
    loadingSubscription ||
    loadingSecretshop ||
    loadingCustomer ||
    loadingMembershipReal ||
    loadingMembershipFeedreal ||
    loadingFeedrealArticles
  )
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  return (
    <DashboardMainUI
      classes={classes}
      activeUserCount={data.activeCustomers}
      activeSubscription={dataSubscription.activeSubscriptions}
      secretshopCount={dataSecretshop.secretShopActiveProducts}
      allCustomer={dataCustomer.customersCount}
      membershipReal={realMembership.membershipListCount}
      membershipFeed={feedrealMembership.membershipListCount}
      feedrealArticles={dataFeedrealArticles.pagesCount}
      permission={permission}
    />
  );
}

class DashboardMainUI extends Component {
  render() {
    return (
      <div className={this.props.classes.root}>
        <Grid container spacing={3} className={"analyticsSpan"}>
          <Grid item xs={6} sm={3}>
            <Paper className={this.props.classes.paper}>
              {" "}
              <p>
                {" "}
                Active Users <span>{this.props.activeUserCount}</span>
              </p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={this.props.classes.paper}>
              {" "}
              <p>
                {" "}
                Active Subscriptions{" "}
                <span>{this.props.activeSubscription}</span>
              </p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={this.props.classes.paper}>
              {" "}
              <p>
                {" "}
                All Customers <span>{this.props.allCustomer}</span>
              </p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={this.props.classes.paper}>
              {" "}
              <p>
                {" "}
                Published Secretshop Products{" "}
                <span>{this.props.secretshopCount}</span>
              </p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={this.props.classes.paper}>
              {" "}
              <p>
                {" "}
                Real.dog Membership <span></span>
                {this.props.membershipReal.map((c) => {
                  return (
                    <div>
                      <span>{INTERVALS[c._id]}</span>
                      <span>{c.count}</span>
                    </div>
                  );
                })}
              </p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={this.props.classes.paper}>
              {" "}
              <p>
                Feedreal Membership <span></span>
                {this.props.membershipFeed.map((c) => {
                  return (
                    <div>
                      <span>{INTERVALS[c._id]}</span>
                      <span>{c.count}</span>
                    </div>
                  );
                })}
              </p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={this.props.classes.paper}>
              {" "}
              <p>
                {" "}
                Feedreal Articles <span>{this.props.feedrealArticles}</span>
              </p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <CoursePurchaseDetail />
          </Grid>

          {/* GOD RESTRICTION SHOUL DOME HERE  */}
          {this.props.permission.god && this.props.permission.god.admin && (
            <Grid item xs={6} sm={3}>
              <CancelationStats paper={this.props.classes.paper} />
            </Grid>
          )}
          {true &&
            this.props.permission.god &&
            this.props.permission.god.admin && (
              <Grid item xs={6} sm={3}>
                <Paper className={this.props.classes.paper}>
                  <Statistics />
                  <TopAffiliates />
                </Paper>
              </Grid>
            )}
        </Grid>
      </div>
    );
  }
}

export default DashboardMain;
