import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_MUTATION_LOGS_ADMIN } from "../../../queries";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import TablePagination from "@mui/material/TablePagination";
import { Backdrop, Button, FormControl, Grid, TextField } from "@mui/material";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import { Box } from "@mui/system";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Link } from "react-router-dom";

function BalanceHistory(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rangeDate, setRangeDate] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const { loading, error, data, refetch, fetchMore } = useQuery(
    GET_MUTATION_LOGS_ADMIN,
    {
      fetchPolicy: "network-only",
      variables: {
        limit: rowsPerPage,
        offset: 0,
        sort: "desc",
        query: "createCustomerBalance",
      },
    }
  );

  const handleSearch = async () => {
    let parsedDates = rangeDate.map((d) => moment(d).unix());
    setSearchLoading(true);
    await refetch({
      sort: "desc",
      limit: rowsPerPage,
      query: "createCustomerBalance",
      from: parsedDates[0],
      to: parsedDates[1],
    });
    setSearchLoading(false);
  };
  const handleChangePage = async (event, newPage) => {
    let parsedDates = rangeDate.map((d) => moment(d).unix());
    setPage(newPage);
    fetchMore({
      fetchPolicy: "network-only",
      variables: {
        sort: "desc",
        offset: data.logs.length,
        limit: rowsPerPage,
        query: "createCustomerBalance",
        from: parsedDates[0],
        to: parsedDates[1],
      },
    });
  };

  const handleRangeDate = (value) => {
    setRangeDate(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  return (
    <LogList
      {...data}
      history={props.history}
      rowsPerPage={rowsPerPage}
      page={page}
      loading={searchLoading}
      handleRangeDate={handleRangeDate}
      handleSearch={handleSearch}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}

const LogList = (props) => {
  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Balance History</Typography>
          </Breadcrumbs>
        </div>
        <div className="right"></div>
      </div>
      <div>
        <LogHeader {...props} />
      </div>
      <ul>
        {
          <TableContainer component={Paper}>
            <Backdrop open={props.loading}>
              <CircularProgress />
            </Backdrop>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  {/* <TableCell align="left">Type</TableCell> */}
                  <TableCell align="left">Admin</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="left">Amount($)</TableCell>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.logs
                  .slice(
                    props.page * props.rowsPerPage,
                    props.page * props.rowsPerPage + props.rowsPerPage
                  )
                  .filter(
                    (log) =>
                      !log.response.hasOwnProperty("errors") &&
                      Math.sign(log.variables.input.amount) === -1
                  )
                  .map((log) => {
                    return (
                      <TableRow key={log.id}>
                        <TableCell component="th" scope="row">
                          {moment(parseInt(log.created_at * 1000)).format(
                            "MMMM, DD YYYY HH:mm"
                          )}
                        </TableCell>

                        <TableCell component="th" align="left" scope="row">
                          {log.admin?.name}
                        </TableCell>
                        <TableCell component="th" align="left" scope="row">
                          {log.customer?.name}
                        </TableCell>
                        <TableCell component="th" align="left" scope="row">
                          <Link
                            variant="text"
                            className="linkcolorwhite"
                            size="small"
                            to={`/dashboard/customer/${log.customer?.id}`}
                          >
                            {log.customer?.email}
                          </Link>
                        </TableCell>

                        <TableCell component="th" align="left" scope="row">
                          {parseFloat(
                            Math.abs(log.variables.input.amount) / 100
                          ).toFixed(2)}
                        </TableCell>
                        <TableCell component="th" align="left" scope="row">
                          {log.variables.input.description}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 300]}
          component="div"
          count={props.totalLogs}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      </ul>
    </div>
  );
};

const LogHeader = (props) => {
  const [value, setValue] = React.useState([null, null]);

  const handleChange = (newValue) => {
    setValue(newValue);
    props.handleRangeDate(newValue);
  };
  return (
    <div>
      <Grid container spacing={3}>
        {true && (
          <Grid item xs={12}>
            <FormControl>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                localeText={{ start: "Start Date", end: "End Date" }}
              >
                <DateRangePicker
                  value={value}
                  onChange={(newValue) => handleChange(newValue)}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </FormControl>
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginLeft: "20px", padding: "15px", width: "30%" }}
              onClick={props.handleSearch}
            >
              Go
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default BalanceHistory;
