const oldReasons = [
  "financial reasons",
  "too many treats, already has small box",
  `too many treats, doesn't want to switch boxes or pause`,
  `didn't like the treats/chews`,
  `a lot of allergies`,
  `travelling/moving`,
  "no reply",
  "dog passed",
  "price increase",
  "vet reasoning",
  "family separated (ie divorce, gone to college)",
  "had a baby",
  "making their own treats",
  "dog got sick",
  "gift sub/OT box",
  "smell",
  `didn't give a reason`,
  "other - explain",
];

const cancelReasons = [
  "financial",
  "products don't work for my dog",
  "dog got sick/vet suggested",
  "no longer have a dog",
  "other",
];

export default cancelReasons;
