import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UploadFile } from "../../../Tools/Upload";
import DetailComponent, {
  DetailComponentInfoDialog,
} from "../Components/DetailComponent";

const ImageColumnEditor = ({ onChangeNodeContent, rawContent, title }) => {
  const [keys, setKeys] = useState([]);
  const [color, setColor] = useState("ed5f13");
  const addNew = () => {
    if (keys.length >= 4) {
      return;
    }
    let copy = JSON.parse(JSON.stringify(keys));
    copy.push({
      img: "",
      text: "",
    });
    setKeys(copy);
  };
  const uploadPhoto = (index, file) => {
    let copy = JSON.parse(JSON.stringify(keys));
    copy[index].img = file.uri;
    setKeys(copy);
  };
  const onSetText = (index, data) => {
    let copy = JSON.parse(JSON.stringify(keys));
    copy[index].text = data;
    setKeys(copy);
  };
  const resetFeaturedPhoto = (index) => {
    let copy = JSON.parse(JSON.stringify(keys));
    copy[index].img = "";
    setKeys(copy);
  };
  useEffect(() => {
    if (rawContent) {
      let obj = JSON.parse(rawContent);
      setKeys(obj.keys);
      setColor(obj.color);
    }
  }, []);
  useEffect(() => {
    let timer = setTimeout(() => {
      onChangeNodeContent(JSON.stringify({ keys, color }));
    }, 700);
    return () => clearTimeout(timer);
  }, [keys, color]);
  return (
    <div
      style={{
        position: "relative",
        border: "1px solid #ffffff30",
        padding: 10,
        borderRadius: 5,
        paddingBottom: 40,
      }}
    >
      {/* <br></br>
      <br></br>
      <DetailComponent keys={keys} color={color} title={title} />
      <br></br> */}
      <Typography>Image Column Editor</Typography>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <DetailComponentInfoDialog />
      </div>
      <br></br>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {keys.map((key, index) => {
          return (
            <SingleKey
              uploadPhoto={(file) => uploadPhoto(index, file)}
              onChangeText={(data) => {
                onSetText(index, data);
              }}
              selectedPhoto={key.img}
              rawText={key.text}
              resetFeaturedPhoto={() => resetFeaturedPhoto(index)}
              disabled={true}
            />
          );
        })}
      </Grid>
      {/* <TextField
        InputProps={{
          startAdornment: <InputAdornment position="start">#</InputAdornment>,
        }}
        disabled
        size="small"
        value={color}
        label={"Color(ffffff)"}
        onChange={(e) => setColor(e.target.value)}
      /> */}

      <Button
        disabled={keys.length >= 4}
        style={{ float: "right" }}
        variant="outlined"
        color="secondary"
        onClick={() => {
          addNew();
        }}
      >
        Add Image Column
      </Button>
    </div>
  );
};
export default ImageColumnEditor;

const SingleKey = ({
  uploadPhoto,
  onChangeText,
  selectedPhoto = "",
  resetFeaturedPhoto,
  disabled,
  rawText,
}) => {
  const [text, setText] = useState("");
  useEffect(() => {
    if (rawText) {
      setText(rawText);
    }
  }, []);
  useEffect(() => {
    let timer = setTimeout(() => {
      onChangeText(text);
    }, 700);
    return () => clearTimeout(timer);
  }, [text]);
  return (
    <Grid item xs={4} className="every-child-margin ">
      <TextField
        fullWidth
        size="small"
        value={text}
        label={"Detail"}
        onChange={(e) => setText(e.target.value)}
      />
      {selectedPhoto === "" ? (
        <UploadFile
          uploadPhoto={uploadPhoto}
          buttonName={"Add Featured Image"}
        />
      ) : (
        <div className="featuredImgBlock">
          <div className="deletephotobtn">
            <IconButton
              onClick={resetFeaturedPhoto}
              color="secondary"
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </div>
          <img
            style={{
              width: 70,
              height: 70,
              objectFit: "contain",
            }}
            src={selectedPhoto}
          />
        </div>
      )}
    </Grid>
  );
};
