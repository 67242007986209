import { useMutation, useQuery } from "@apollo/client";
import {
  Breadcrumbs,
  Container,
  Grid,
  LinearProgress,
  Typography,
  Button,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import React, { useEffect, useState } from "react";
import { createRandKey } from "../../../globals";
import { GET_WORKSHOPS, UPDATE_WORKSHOP } from "../../../queries";
import WorkshopDialog from "./components/edit";
import { Link } from "react-router-dom";
const Workshops = (props) => {
  const [workshop, setWorkshop] = useState();

  const [open, setOpen] = useState([]);

  const [author, setAuthor] = useState(false);

  useEffect(() => {
    const { permission } = props.session.activeAdmin;
    setAuthor(permission.author ? permission.author : false);
  }, [props.session]);

  const handleOnEdit = () => {
    setOpen(!open);
  };
  const updateWorkShop = (data) => {
    data.price = parseFloat(data.price);
    console.log(data);
    updateWorkshop({
      variables: {
        id: data.id,
        input: { ...data },
      },
    }).then((res) => {
      setOpen(false);
    });
  };

  const [updateWorkshop] = useMutation(UPDATE_WORKSHOP);
  const { loading, data, error, refetch } = useQuery(GET_WORKSHOPS, {
    fetchPolicy: "no-cache",
  });
  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <Typography>Error</Typography>;
  }
  return (
    <Container>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Workshops</Typography>
          </Breadcrumbs>
        </div>
        <div className="right">
          <Button
            component={Link}
            variant="outlined"
            color="secondary"
            to="/dashboard/workshops/list"
          >
            Workshop List
          </Button>
        </div>
      </div>
      <div>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {data?.getWorkshops?.map((workshop) => {
            return (
              <Grid
                item
                xs={3}
                onClick={() => {
                  setWorkshop(workshop);
                  setOpen(true);
                }}
                style={{
                  position: "relative",
                }}
              >
                <Workshop
                  key={"single-course-key-" + createRandKey(6)}
                  item={workshop}
                />
              </Grid>
            );
          })}
          {workshop && (
            <WorkshopDialog
              workshop={workshop}
              open={open}
              handleOpen={handleOnEdit}
              onEdit={(d) => updateWorkShop(d)}
            />
          )}

          {data?.getWorkshops?.length === 0 && (
            <Typography
              align="center"
              variant="h4"
              style={{ width: "100%", marginTop: 100 }}
            >
              There is no course yet
            </Typography>
          )}
        </Grid>
      </div>
    </Container>
  );
};

const Workshop = ({ item }) => {
  return (
    <Card sx={{ width: "100%" }}>
      <CardMedia
        component="img"
        height="140"
        image={
          "https://serverless-s3-images.s3.amazonaws.com/6ed8ba07-d40b-495c-8fe6-23b5f94cd0fd298331821_118682540915246_4439495809634335476_n.jpeg"
        }
        alt={item.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {item.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.description}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Times:</strong>
        </Typography>
      </CardContent>
      <CardActions sx={{ paddingTop: "0px" }}>
        <div
          style={{
            width: "100%",
          }}
        ></div>
      </CardActions>
      <CardActions sx={{ paddingTop: "0px" }}>
        <div
          style={{
            width: "100%",
          }}
        ></div>
      </CardActions>
    </Card>
  );
};

export default Workshops;
