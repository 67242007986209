import { gql } from "graphql-tag";

const GET_GIFT_GROUPS = gql`
  query GetGiftGroups {
    getGiftGroups {
      id
      name
      onlyOne
    }
  }
`;

const CREATE_GIFT_GROUP = gql`
  mutation CreateGiftGroup($input: GiftGroupInput) {
    createGiftGroup(input: $input) {
      id
      name
      onlyOne
    }
  }
`;

const GENERATE_GIFT = gql`
  mutation CreateGiftCoupons($input: CreateGiftCouponInput) {
    createGiftCoupon(input: $input) {
      id
      coupon
      amount
      expiryDate
      recipient_email
      redeemed
      printed
      note
    }
  }
`;

const GET_GIFT_COUPONS = gql`
  query GetCoupons($limit: Int, $offset: Int, $type: String) {
    getGiftCoupons(limit: $limit, offset: $offset, type: $type) {
      id
      coupon
      amount
      createdAt
      expiryDate
      redeemed
      printed
      note
      recipient_email
      printedAdmin {
        email
        name
      }
      admin {
        email
        name
      }
      customer {
        email
      }
      group {
        name
        onlyOne
      }
    }
    giftCouponCount(type: $type)
  }
`;

const UPDATE_GIFT_COUPON = gql`
  mutation UpdateGiftCoupon($id: ID!, $input: UpdateGiftCouponInput) {
    updateGiftCoupon(id: $id, input: $input) {
      redeemed
      printed
    }
  }
`;

const DELETE_GIFT_COUPON = gql`
  mutation DeleteGiftCoupon($id: ID!) {
    deleteGiftCoupon(id: $id) {
      id
    }
  }
`;

const GET_GIFT_COUPON_COMMENT = gql`
  query GetGiftCouponComments($id: ID) {
    getGiftCouponComments(id: $id) {
      id
      comment
      createdAt
      admin {
        email
      }
    }
  }
`;
const CREATE_GIFT_COUPON_COMMENT = gql`
  mutation CreateGiftCouponComment($input: inputGiftCouponComment) {
    createGiftCouponComment(input: $input) {
      id
      comment
      giftCodeID
    }
  }
`;

export {
  GET_GIFT_GROUPS,
  CREATE_GIFT_GROUP,
  GENERATE_GIFT,
  GET_GIFT_COUPONS,
  UPDATE_GIFT_COUPON,
  DELETE_GIFT_COUPON,
  CREATE_GIFT_COUPON_COMMENT,
  GET_GIFT_COUPON_COMMENT,
};
