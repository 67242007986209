import React from 'react';

import { Query } from '@apollo/client/react/components';

import {GET_ACTIVE_ADMIN} from '../queries';
import {
LinearProgress
} from "@mui/material";
const sessionWrapper = Component => props => (
  <Query query={GET_ACTIVE_ADMIN}>
  {
    ({data, loading, refetch}) => {
      if (loading) return <div><LinearProgress /></div>

      return <Component {...props} refetch={refetch} session={data}/>
    }
  }
  </Query>
);

export default sessionWrapper;
