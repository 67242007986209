import { gql } from "@apollo/client";

const PROCESS_SUBSCRIPTION = gql`
  subscription {
    inv_process {
      id
      status
    }
  }
`;

const GET_INVENTORY_PRODUCTS = gql`
  query {
    inv_products {
      id
      name
      category
      product_id
      keywords
      weight
      quantity
    }
  }
`;

const ADD_INV_PRODUCT = gql`
  mutation (
    $name: String!
    $category: String!
    $admin_id: ID!
    $product_id: String!
    $keywords: String!
    $weight: String
    $yield_number: String
    $quantity: String
    $hours: String!
  ) {
    createInvProduct(
      data: {
        name: $name
        category: $category
        admin_id: $admin_id
        product_id: $product_id
        keywords: $keywords
        weight: $weight
        yield_number: $yield_number
        quantity: $quantity
        hours: $hours
      }
    ) {
      name
    }
  }
`;

const GET_INV_PRODUCT = gql`
  query ($id: ID!) {
    inv_product(id: $id) {
      id
      name
      category
      product_id
      keywords
      weight
      quantity
      yield_number
      hours
      admin {
        id
      }
    }
  }
`;

const UPDATE_INV_PRODUCT = gql`
  mutation (
    $id: ID!
    $name: String!
    $category: String!
    $admin_id: ID!
    $product_id: String!
    $keywords: String!
    $weight: String
    $yield_number: String
    $quantity: String
    $hours: String!
  ) {
    updateInvProduct(
      id: $id
      data: {
        name: $name
        category: $category
        admin_id: $admin_id
        product_id: $product_id
        keywords: $keywords
        weight: $weight
        yield_number: $yield_number
        quantity: $quantity
        hours: $hours
      }
    ) {
      name
      category
      admin {
        id
      }
      product_id
      keywords
      weight
      quantity
      hours
      yield_number
    }
  }
`;

const GET_SUPPLIERS = gql`
  query {
    inv_suppliers {
      id
      name
      company
      location
      type
      phone_number
      cell_number
      email
      products
      note
      admin {
        id
      }
    }
  }
`;

const ADD_INV_SUPPLIER = gql`
  mutation (
    $name: String!
    $company: String!
    $location: String!
    $type: String!
    $phone_number: String!
    $cell_number: String!
    $email: String!
    $products: String!
    $note: String!
    $admin_id: ID!
  ) {
    createSuppliers(
      data: {
        name: $name
        company: $company
        location: $location
        type: $type
        phone_number: $phone_number
        cell_number: $cell_number
        email: $email
        products: $products
        note: $note
        admin_id: $admin_id
      }
    ) {
      name
      company
    }
  }
`;

const UPDATE_INV_SUPPLIER = gql`
  mutation (
    $id: ID!
    $name: String!
    $company: String!
    $location: String!
    $type: String!
    $phone_number: String!
    $cell_number: String!
    $email: String!
    $products: String!
    $note: String!
    $admin_id: ID!
  ) {
    updateSuppliers(
      id: $id
      data: {
        name: $name
        company: $company
        location: $location
        type: $type
        phone_number: $phone_number
        cell_number: $cell_number
        email: $email
        products: $products
        note: $note
        admin_id: $admin_id
      }
    ) {
      name
      company
      location
      type
      phone_number
      cell_number
      email
      products
      note
      admin {
        id
      }
    }
  }
`;

const GET_INV_SUPPLIER = gql`
  query ($id: ID!) {
    inv_supplier(id: $id) {
      id
      name
      company
      location
      type
      phone_number
      cell_number
      email
      products
      note
      admin {
        id
      }
    }
  }
`;

const GET_PROCESSS = gql`
  query ($rec_date: Int!) {
    inv_processs(rec_date: $rec_date) {
      id
      stock_un {
        id
        product {
          id
          name
          category
          hours
          quantity
          weight
        }
        supplier {
          id
          company
        }
        brand
        price
        order_number
      }
      admin {
        name
      }
      quantity
      rec_date
      createdAt
      comments
      units_made
      projected_units
      batch_number
      status
      units_made
      split
      lastsplit
      process_ts
      process_dp
      process_tf
      dryer_ds
      dryer_df
      bins_date
      bins_empitied_who {
        name
      }
      bins
      expected_bags
      bins_total
      packed
      total_bags
      waste
    }
    processCount
  }
`;

const GET_PROCESS = gql`
  query ($id: ID!) {
    inv_process(id: $id) {
      id
      stock_un {
        id
        product {
          id
          name
          category
          hours
          quantity
          weight
        }
        supplier {
          id
          company
        }
        brand
        price
        order_number
      }
      admin {
        name
      }
      quantity
      rec_date
      createdAt
      comments
      quantity_scrapped
      total_processed
      dryer_hum
      dryer_room
      dryer_df
      dryer_end_comment
      dryer_hours
      dryer_end_by {
        name
      }
      dryer_temp
      entered_by {
        name
      }
      units_made
      pieces_grate
      grates_used
      carts_used
      comments_process
      slice_operator {
        name
      }
      process_yield
      projected_units
      batch_number
      status
      units_made
      split
      lastsplit
      process_ts
      process_dp
      who_ts {
        name
      }
      who_te {
        name
      }
      process_tf
      dryer_ds
      dryer_df
      bins_date
      bins_empitied_who {
        name
      }
      bins
      expected_bags
      bins_total
      packed
      total_bags
      waste
    }
  }
`;

const UPDATE_STATUS = gql`
  mutation ($id: ID!, $admin_id: ID, $status: Int!) {
    statusUpdate(id: $id, admin_id: $admin_id, status: $status) {
      batch_number
      status
    }
  }
`;

const ADD_PROCESS = gql`
  mutation (
    $stock_un: ID!
    $batch_number: String!
    $quantity: String!
    $admin_id: ID!
    $status: Int!
    $createdAt: Int!
    $rec_date: Int!
    $projected_units: String
    $comments: String!
    $split: Boolean
    $lastsplit: Int
    $product: ID
  ) {
    createInvProcess(
      data: {
        stock_un: $stock_un
        batch_number: $batch_number
        quantity: $quantity
        admin_id: $admin_id
        rec_date: $rec_date
        projected_units: $projected_units
        createdAt: $createdAt
        status: $status
        comments: $comments
        split: $split
        lastsplit: $lastsplit
        product: $product
      }
    ) {
      batch_number
    }
  }
`;

const START_PROCESS = gql`
  mutation (
    $id: ID!
    $process_dp: Int!
    $process_ts: Int!
    $who_ts: ID!
    $status: Int!
  ) {
    processStart(
      id: $id
      data: {
        process_dp: $process_dp
        process_ts: $process_ts
        who_ts: $who_ts
        status: $status
      }
    ) {
      process_ts
      status
    }
  }
`;

const END_PROCESS = gql`
  mutation (
    $id: ID!
    $quantity_scrapped: String
    $total_processed: Float
    $units_made: Int
    $pieces_grate: String
    $grates_used: String
    $carts_used: String
    $comments_process: String
    $slice_operator: ID
    $process_yield: String
    $process_tf: Int
    $batch_record: String
    $who_te: ID
    $status: Int!
  ) {
    processEnd(
      id: $id
      data: {
        quantity_scrapped: $quantity_scrapped
        total_processed: $total_processed
        units_made: $units_made
        pieces_grate: $pieces_grate
        grates_used: $grates_used
        carts_used: $carts_used
        who_te: $who_te
        comments_process: $comments_process
        slice_operator: $slice_operator
        process_yield: $process_yield
        process_tf: $process_tf
        batch_record: $batch_record
        status: $status
      }
    ) {
      status
    }
  }
`;

const START_DRYER = gql`
  mutation (
    $id: ID!
    $dryer_ds: Int!
    $dryer_room: String!
    $entered_by: ID!
    $status: Int!
    $dryer_temp: String!
    $dryer_hum: String
  ) {
    dryerStart(
      id: $id
      data: {
        status: $status
        dryer_ds: $dryer_ds
        dryer_room: $dryer_room
        entered_by: $entered_by
        dryer_temp: $dryer_temp
        dryer_hum: $dryer_hum
      }
    ) {
      process_ts
      status
    }
  }
`;

const STOP_DRYER = gql`
  mutation (
    $id: ID!
    $dryer_df: Int!
    $dryer_hours: Int!
    $dryer_end_by: ID!
    $status: Int!
    $dryer_end_comment: String!
  ) {
    dryerStop(
      id: $id
      data: {
        status: $status
        dryer_df: $dryer_df
        dryer_hours: $dryer_hours
        dryer_end_by: $dryer_end_by
        dryer_end_comment: $dryer_end_comment
      }
    ) {
      dryer_df
      status
    }
  }
`;

const ADD_BINS = gql`
  mutation (
    $id: ID!
    $status: Int!
    $bins_empitied_who: ID
    $bins_date: Int
    $bins: String
    $bins_total: Int
    $expected_bags: Int
  ) {
    addBins(
      id: $id
      data: {
        status: $status
        bins_empitied_who: $bins_empitied_who
        bins_date: $bins_date
        bins: $bins
        bins_total: $bins_total
        expected_bags: $expected_bags
      }
    ) {
      bins
      status
    }
  }
`;

const ADD_PACKED = gql`
  mutation (
    $id: ID!
    $status: Int!
    $bins: String
    $packed: String
    $total_bags: Int
    $waste: Int
  ) {
    addPacked(
      id: $id
      data: {
        status: $status
        bins: $bins
        packed: $packed
        total_bags: $total_bags
        waste: $waste
      }
    ) {
      bins
      packed
      status
      waste
    }
  }
`;

const SPLIT_PROCESS = gql`
  mutation ($id: ID!, $lastsplit: String, $quantity: String) {
    splitMaterial(
      id: $id
      data: { quantity: $quantity, lastsplit: $lastsplit }
    ) {
      status
    }
  }
`;

const ADD_PERMISSION = gql`
  mutation ($inventory: Boolean, $inventory_admin: Boolean, $admin_id: ID!) {
    createPermission(
      data: {
        admin_id: $admin_id
        inventory: $inventory
        inventory_admin: $inventory_admin
      }
    ) {
      id
    }
  }
`;

const ADD_STOCK_UN = gql`
  mutation (
    $product: ID!
    $stock_number: String!
    $supplier: ID!
    $brand: String!
    $price: String!
    $comments: String
    $quantity: String!
    $order_number: String!
    $status: Int!
    $left: String
    $rec_date: Int
    $admin_id: ID!
    $secretshop: Boolean
  ) {
    createStockUn(
      data: {
        product: $product
        stock_number: $stock_number
        supplier: $supplier
        brand: $brand
        price: $price
        comments: $comments
        quantity: $quantity
        order_number: $order_number
        status: $status
        left: $left
        rec_date: $rec_date
        admin_id: $admin_id
        secretshop: $secretshop
      }
    ) {
      stock_number
    }
  }
`;

const GET_STOCK_UNS = gql`
  query {
    stock_uns {
      id
      admin {
        name
      }
      product {
        id
        name
        category
        hours
        quantity
        weight
      }
      stock_number
      supplier {
        id
        company
      }
      brand
      price
      comments
      quantity
      order_number
      status
      left
      rec_date
      secretshop
    }
    stockUnCount
  }
`;

const UPDATE_STOCK_UN = gql`
  mutation ($id: ID!, $left: String) {
    updateStockUn(id: $id, data: { left: $left }) {
      left
    }
  }
`;

const UPDATE_STOCK_UN_MANUAL = gql`
  mutation (
    $id: ID!
    $left: String
    $oldleft: String
    $product_name: String
    $rec_date: Int
    $admin_id: ID
    $comment: String
  ) {
    updateStockUnManual(
      id: $id
      data: {
        left: $left
        oldleft: $oldleft
        product_name: $product_name
        rec_date: $rec_date
        admin_id: $admin_id
        comment: $comment
      }
    ) {
      left
    }
  }
`;

const UPDATE_STOCK_MANUAL = gql`
  mutation (
    $id: ID!
    $left: Int
    $oldleft: Int
    $product_name: String
    $rec_date: Int
    $admin_id: ID
    $comment: String
  ) {
    updateStockManual(
      id: $id
      data: {
        left: $left
        oldleft: $oldleft
        product_name: $product_name
        rec_date: $rec_date
        admin_id: $admin_id
        comment: $comment
      }
    ) {
      left
    }
  }
`;

const GET_STOCK_UN = gql`
  query ($id: ID!) {
    stock_un(id: $id) {
      id
      left
      quantity
      product {
        name
      }
    }
  }
`;

const GET_STOCK = gql`
  query ($id: ID!) {
    stock(id: $id) {
      id
      left
      stock
      product {
        name
      }
    }
  }
`;

const ADD_STOCK = gql`
  mutation (
    $product: ID!
    $process: ID!
    $stock: Int
    $left: Int
    $rec_date: Int
    $admin_id: ID!
  ) {
    createAddInventory(
      data: {
        product: $product
        process: $process
        stock: $stock
        left: $left
        rec_date: $rec_date
        admin_id: $admin_id
      }
    ) {
      stock
    }
  }
`;

const GET_STOCKS = gql`
  query {
    stocks {
      id
      product {
        name
      }
      process {
        stock_un {
          quantity
        }
        batch_number
      }
      stock
      left
      rec_date
      admin {
        name
      }
    }
    stockCount
  }
`;

const FILTER_PROCESS_LIST = gql`
  query ($status: Int, $batch_number: String) {
    inv_process_filter(status: $status, batch_number: $batch_number) {
      id
      status
      batch_number
      stock_un {
        product {
          name
        }
      }
    }
  }
`;

const GET_ALL_PROCESS = gql`
  query {
    inv_processs_all {
      id
      createdAt
      rec_date
      batch_number
      quantity
      process_ts
      total_bags
      bins_total
      expected_bags
      stock_un {
        price
        product {
          name
          id
        }
      }
    }
    processCount
  }
`;

const CREATE_MONTHLY_BOX = gql`
  mutation CreateMonthlyBox($input: CreateMonthlyBoxInput) {
    createMonthlyBox(input: $input) {
      id
    }
  }
`;

const GET_MONTHLY_BOX = gql`
  query GetMonthlyBoxes {
    monthlyBoxes {
      id
      title
      createdAt
      updatedAt
      start
      end
      menu {
        product {
          id
          name
        }
        name
        category
      }
    }
  }
`;

export {
  PROCESS_SUBSCRIPTION,
  GET_INVENTORY_PRODUCTS,
  ADD_INV_PRODUCT,
  GET_INV_PRODUCT,
  UPDATE_INV_PRODUCT,
  GET_SUPPLIERS,
  ADD_INV_SUPPLIER,
  UPDATE_INV_SUPPLIER,
  GET_INV_SUPPLIER,
  GET_PROCESSS,
  GET_PROCESS,
  UPDATE_STATUS,
  ADD_PROCESS,
  START_PROCESS,
  END_PROCESS,
  START_DRYER,
  STOP_DRYER,
  ADD_BINS,
  ADD_PACKED,
  SPLIT_PROCESS,
  ADD_PERMISSION,
  ADD_STOCK_UN,
  GET_STOCK_UNS,
  UPDATE_STOCK_UN,
  UPDATE_STOCK_UN_MANUAL,
  UPDATE_STOCK_MANUAL,
  GET_STOCK_UN,
  GET_STOCK,
  ADD_STOCK,
  GET_STOCKS,
  FILTER_PROCESS_LIST,
  GET_ALL_PROCESS,
  CREATE_MONTHLY_BOX,
  GET_MONTHLY_BOX,
};
