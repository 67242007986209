import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FolderIcon from "@mui/icons-material/Folder";
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { createRandKey } from "../../../globals";
import {
  CREATE_COURSE,
  GET_COURSES,
  GET_SINGLE_COURSE,
  UPDATE_COURSE,
} from "../../../queries";
import { UploadFile } from "../../Tools/Upload";
import QuizCategories from "../Quiz/components/QuizCategories";
import QuizStatus from "../Quiz/components/QuizStatus";
import QuizTags from "../Quiz/components/QuizTags";
import AddIcon from "@mui/icons-material/Add";

const COURSE_TITLE_MIN_LENGTH = 10;
const COURSE_DETAIL_MIN_LENGTH = 10;

const Course = (props) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [dense, setDense] = useState(false);
  const [courseList, setCourseList] = useState([]);

  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(true);
  const [featuredImg, setFeaturedImg] = useState("");
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [courseDetail, setCourseDetail] = useState("");
  const [prerequisites, setPrerequisites] = useState([]);
  const [units, setUnits] = useState([]);

  const [getSingleCourse, { loading: getSingleCourseLoading }] = useLazyQuery(
    GET_SINGLE_COURSE,
    {
      onCompleted: (response) => {
        const {
          title,
          status,
          featured_img,
          tags,
          categories,
          courseDetail,
          prerequisites,
          units,
        } = response.getSingleCourse;
        setTitle(title);
        setStatus(status);
        setFeaturedImg(featured_img);
        setTags(tags);
        setCategories(categories);
        setCourseDetail(courseDetail);
        setPrerequisites(prerequisites);
        setUnits(units);
      },
      fetchPolicy: "no-cache",
    }
  );

  const [createCourseMutation, { loading: createCourseLoading }] = useMutation(
    CREATE_COURSE,
    {
      onCompleted: (response) => {
        enqueueSnackbar("Course Created Successfully", {
          variant: "success",
        });
        history.push("/dashboard/course/edit/" + response.createCourse.id);
      },
    }
  );
  const [updateCourseMutation, { loading: updateCourseLoading }] = useMutation(
    UPDATE_COURSE,
    {
      onCompleted: (response) => {
        enqueueSnackbar("Course updated Successfully", {
          variant: "success",
        });
        history.push("/dashboard/course");
      },
    }
  );

  const {
    loading: coursesLoading,
    data: coursesData,
    error: coursesError,
  } = useQuery(GET_COURSES);

  useEffect(() => {
    if (coursesData?.getCourses) {
      setCourseList(coursesData.getCourses);
    }
  }, [coursesData]);

  useEffect(() => {
    if (id) {
      getSingleCourse({
        variables: {
          input: { id },
        },
      });
    }
  }, []);

  const onUpdate = () => {
    const valid = isValid();
    if (valid) {
      const input = createInput();
      updateCourseMutation({
        variables: {
          id: id,
          input: input,
        },
      });
    }
  };
  const onCreate = () => {
    const valid = isValid();
    if (valid) {
      const input = createInput();
      createCourseMutation({
        variables: {
          input: input,
        },
      });
    }
  };

  const createInput = () => {
    const input = {
      title: title,
      status: status,
      featured_img: featuredImg,
      tags: tags,
      categories: categories,
      courseDetail: courseDetail,
      prerequisites: prerequisites,
    };
    return input;
  };

  const isValid = () => {
    if (title.length < COURSE_TITLE_MIN_LENGTH) {
      enqueueSnackbar(`Course title min length ${COURSE_TITLE_MIN_LENGTH}`, {
        variant: "error",
      });
      return false;
    }
    if (courseDetail.length < COURSE_DETAIL_MIN_LENGTH) {
      enqueueSnackbar(`Course detail min length ${COURSE_DETAIL_MIN_LENGTH}`, {
        variant: "error",
      });
      return false;
    }
    if (featuredImg.length === 0) {
      enqueueSnackbar(`Please upload an image first`, {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const uploadPhoto = (file) => {
    setFeaturedImg(file.uri);
  };
  const resetFeaturedPhoto = () => {
    setFeaturedImg("");
  };
  const onClickPrerequisites = (courseId) => {
    const copy = JSON.parse(JSON.stringify(prerequisites));
    const index = copy.indexOf(courseId);
    if (index !== -1) {
      copy.splice(index, 1);
    } else {
      copy.push(courseId);
    }
    setPrerequisites(copy);
  };
  if (createCourseLoading || getSingleCourseLoading) {
    return <LinearProgress />;
  }

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={9}>
          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            style={{
              marginBottom: 20,
            }}
          />
          <TextField
            fullWidth
            label="Course Detail"
            value={courseDetail}
            onChange={(e) => {
              setCourseDetail(e.target.value);
            }}
            style={{
              marginBottom: 20,
            }}
          />

          {id && (
            <List dense={dense}>
              {units.map((unit) => {
                return (
                  <ListItem
                    style={{
                      backgrounColor: "black",
                    }}
                    key={"unit-list-key-" + createRandKey(6)}
                    secondaryAction={
                      <Link
                        to={`/dashboard/course/edit/${id}/unit/edit/${unit.id}`}
                      >
                        <IconButton edge="end" aria-label="delete">
                          <EditIcon />
                        </IconButton>
                      </Link>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <FolderIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={unit.title}
                      // secondary={true ? "Secondary text" : null}
                    />
                  </ListItem>
                );
              })}
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={() => {
                    history.push(`/dashboard/course/edit/${id}/unit/add`);
                  }}
                  startIcon={<AddIcon />}
                >
                  add a new unit
                </Button>
              </div>
            </List>
          )}
        </Grid>
        <Grid item xs={3} className="every-child-margin ">
          <div style={{ position: "fixed", maxWidth: 250 }}>
            <FeaturedImage
              featuredImg={featuredImg}
              uploadPhoto={uploadPhoto}
              resetFeaturedPhoto={resetFeaturedPhoto}
            />

            <Paper style={{ marginBottom: 10 }}>
              <Accordion sx={{ backgroundColor: "#000 !important" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    Please select prerequisite if it's needed!
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {courseList
                    .filter((item) => item.id !== id)
                    .map((course) => {
                      return (
                        <ListItem key={course.id} disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={() => {
                              onClickPrerequisites(course.id);
                            }}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={prerequisites.includes(course.id)}
                                tabIndex={-1}
                                disableRipple
                              />
                            </ListItemIcon>
                            <ListItemText primary={course.title} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper
              style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}
            >
              <QuizStatus status={status} setStatus={setStatus} />
            </Paper>

            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() => {
                if (id) {
                  onUpdate();
                } else {
                  onCreate();
                }
              }}
            >
              {" "}
              {id ? "Update" : "Create"}{" "}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Course;

export const FeaturedImage = ({
  featuredImg,
  resetFeaturedPhoto,
  uploadPhoto,
}) => {
  return (
    <>
      {featuredImg === "" ? (
        <UploadFile
          idKey={"create-new-uplad-image-key-" + createRandKey(6)}
          uploadPhoto={uploadPhoto}
          buttonName={"Add Featured Image"}
        />
      ) : (
        <div className="featuredImgBlock">
          <div className="deletephotobtn">
            <IconButton
              onClick={resetFeaturedPhoto}
              color="secondary"
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </div>
          <img width={"100%"} src={featuredImg} />
        </div>
      )}
    </>
  );
};
