import { DashboardRounded, ExpandLess, ExpandMore } from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FeedIcon from "@mui/icons-material/Feed";
import LinkIcon from "@mui/icons-material/Link";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MenuIcon from "@mui/icons-material/Menu";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link as MenuLink } from "react-router-dom";
import { BEAST_VILLAGE_LOGO, MENU_LIST } from "../../globals";
import Logout from "./Logout";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#212529",
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  listItemText: {
    fontSize: "14px", //Insert your required size
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.down("sm")]: {
      marginRight: 5,
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    width: drawerWidth,
    flexShrink: 0,
    color: "#fff",
    whiteSpace: "nowrap",
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(0) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    color: "#fff",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
}));

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { permission } = props.session.activeAdmin;
  const classes = useStyles();
  const god = permission.god ? permission.god.admin : false;
  const inventory = permission.inventory ? permission.inventory : false;
  const author = permission.author ? permission.author : false;
  const underhood = permission.underhood ? permission.underhood : false;
  const customer = permission.customer ? permission.customer : false;
  const secretshop = permission.secretshop ? permission.secretshop : false;
  const [openCollapse, setOpenCollapse] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openSelect = Boolean(anchorEl);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  function handleOpenSettings(step) {
    let collapse = openCollapse === step ? false : step;
    setOpenCollapse(collapse);
  }
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Toolbar>
        <div className="sidelogo">
          <img
            width={100}
            src="https://realdog-s3.s3-us-west-1.amazonaws.com/brands/Logos/200px/BV/bv-circle-no-text-200px.png"
          />
          <img
            src={BEAST_VILLAGE_LOGO}
            style={{
              fill: "white",
              filter: " brightness(0) invert(1)",
              marginBottom: -7,
            }}
          />
        </div>
      </Toolbar>
      <Divider />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <List
          style={{
            height: "100%",
          }}
        >
          <ListItem button component={MenuLink} to="/dashboard">
            <ListItemIcon>
              <DashboardRounded />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Dashboard"
            />
          </ListItem>
          {god || customer.admin || customer.read || customer.write ? (
            <ListItem button onClick={() => handleOpenSettings("customer")}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Customers (CRM)"
              />
              {openCollapse === "customer" ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          ) : (
            ""
          )}
          {god || customer.admin || customer.read || customer.write ? (
            <Collapse
              in={openCollapse === "customer" ? true : false}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to={"/dashboard/customerlist"}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Customer List"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to={"/dashboard/customerlist"}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Referral Analytics"
                  />
                </ListItem>
                {/* <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to={"/dashboard/reports/cancel"}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Cancellations"
                  />
                </ListItem> */}
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to={"/dashboard/consultation"}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Consultation"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to={`/dashboard/asigned-consultation/${props.session.activeAdmin.id}`}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Manage Consultations"
                  />
                </ListItem>
                {god || customer.admin || customer.read || customer.write ? (
                  <ListItem
                    button
                    className={classes.nested}
                    component={MenuLink}
                    to={"/dashboard/affiliate"}
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      inset
                      primary="Affiliate"
                    />
                  </ListItem>
                ) : (
                  ""
                )}
                {god || customer.admin || customer.read || customer.write ? (
                  <ListItem
                    button
                    className={classes.nested}
                    component={MenuLink}
                    to={"/dashboard/retailer-requests"}
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      inset
                      primary="Retailer Requests"
                    />
                  </ListItem>
                ) : (
                  ""
                )}
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to={"/dashboard/balance-history"}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Balance History"
                  />
                </ListItem>
              </List>
            </Collapse>
          ) : (
            ""
          )}
          {god ||
          author.admin ||
          author.read ||
          author.write ||
          underhood.admin ||
          underhood.read ||
          underhood.write ? (
            <ListItem button onClick={() => handleOpenSettings("content")}>
              <ListItemIcon>
                <FeedIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Content (CMS)"
              />
              {openCollapse === "content" ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          ) : (
            ""
          )}
          {god ||
          author.admin ||
          author.read ||
          author.write ||
          underhood.admin ||
          underhood.read ||
          underhood.write ? (
            <Collapse
              in={openCollapse === "content" ? true : false}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {/*               <ListItem
                button
                className={classes.nested}
                component={MenuLink}
                to="/dashboard/caninecare"
              >
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  inset
                  primary="Old Pages"
                />
              </ListItem> */}
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to="/dashboard/page"
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Pages"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to="/dashboard/glossary"
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Glossary"
                  />
                </ListItem>
                {god || underhood.admin || underhood.read || underhood.write ? (
                  <ListItem
                    button
                    className={classes.nested}
                    component={MenuLink}
                    to="/dashboard/underhood"
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      inset
                      primary="Underhood"
                    />
                  </ListItem>
                ) : (
                  ""
                )}
                {god || author.qr ? (
                  <ListItem
                    button
                    className={classes.nested}
                    component={MenuLink}
                    to="/dashboard/qr-codes"
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      inset
                      primary="QR Codes"
                    />
                  </ListItem>
                ) : (
                  ""
                )}

                {god ||
                author.admin ||
                author.read ||
                author.write ||
                underhood.admin ||
                underhood.read ||
                underhood.write ? (
                  <ListItem
                    button
                    className={classes.nested}
                    component={MenuLink}
                    to="/dashboard/newsletter"
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      inset
                      primary="Newsletter"
                    />
                  </ListItem>
                ) : (
                  ""
                )}

                {god ||
                author.admin ||
                author.read ||
                author.write ||
                underhood.admin ||
                underhood.read ||
                underhood.write ? (
                  <ListItem
                    button
                    className={classes.nested}
                    component={MenuLink}
                    to="/dashboard/newsletter_email"
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      inset
                      primary="Newsletter List"
                    />
                  </ListItem>
                ) : (
                  ""
                )}
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to="/dashboard/course"
                  // disabled
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Course"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to="/dashboard/workshops"
                  // disabled
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Workshops"
                  />
                </ListItem>
                {god || author.admin || author.read || author.write ? (
                  <ListItem
                    button
                    className={classes.nested}
                    component={MenuLink}
                    to="/dashboard/website_settings"
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      inset
                      primary="Website Settings"
                    />
                  </ListItem>
                ) : (
                  ""
                )}
              </List>
            </Collapse>
          ) : (
            ""
          )}
          {god || inventory.admin || inventory.read ? (
            <ListItem button onClick={() => handleOpenSettings("inventory")}>
              <ListItemIcon>
                <PrecisionManufacturingIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Manufacturing"
              />
              {openCollapse === "inventory" ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          ) : (
            ""
          )}
          <Collapse
            in={openCollapse === "inventory" ? true : false}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                component={MenuLink}
                to="/dashboard/rooms"
              >
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  inset
                  primary="Rooms"
                />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={MenuLink}
                to="/dashboard/processing"
              >
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  inset
                  primary="Processing"
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                component={MenuLink}
                to="/dashboard/stock_un"
              >
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  inset
                  primary="Raw Stock"
                />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={MenuLink}
                to="/dashboard/stock"
              >
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  inset
                  primary="Inventory"
                />
              </ListItem>
              <ListItem button disabled className={classes.nested}>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  inset
                  primary="Performance"
                />
              </ListItem>
              {god || inventory.admin ? (
                <div>
                  <ListItem
                    button
                    className={classes.nested}
                    component={MenuLink}
                    to="/dashboard/inventorysettings"
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      inset
                      primary="Manage Inventory"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    component={MenuLink}
                    to="/dashboard/monthlyboxes"
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      inset
                      primary="Monthly Boxes"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    component={MenuLink}
                    to="/dashboard/barcode-generator"
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      inset
                      primary="Barcode Generator"
                    />
                  </ListItem>
                </div>
              ) : (
                ""
              )}
              {god ? (
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to="/dashboard/shippinglist"
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Shipping"
                  />
                </ListItem>
              ) : (
                ""
              )}
            </List>
          </Collapse>
          {god || secretshop.admin || secretshop.read || secretshop.write ? (
            <ListItem button onClick={() => handleOpenSettings("ecommerce")}>
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Commerce"
              />
              {openCollapse === "ecommerce" ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          ) : (
            ""
          )}
          {god || secretshop.admin || secretshop.read || secretshop.write ? (
            <Collapse
              in={openCollapse === "ecommerce" ? true : false}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to={"/dashboard/membership"}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Membership"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to={"/dashboard/secretshop"}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Secret Shop"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to={"/dashboard/sms-notification"}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="SMS Notification"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to="/dashboard/rewards"
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Rewards"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to="/dashboard/gift_code_generate"
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Gift Code Generate"
                  />
                </ListItem>
              </List>
            </Collapse>
          ) : (
            ""
          )}
          {god ? (
            <ListItem button onClick={() => handleOpenSettings("reports")}>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Admin"
              />
              {openCollapse === "customer" ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          ) : (
            ""
          )}
          {god ? (
            <Collapse
              in={openCollapse === "reports" ? true : false}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  component={MenuLink}
                  to="/dashboard/adminsettings"
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    inset
                    primary="Manage Admins"
                  />
                </ListItem>
                {god ? (
                  <ListItem
                    button
                    className={classes.nested}
                    component={MenuLink}
                    to={"/dashboard/reports/webhooks"}
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      inset
                      primary="Webhooks"
                    />
                  </ListItem>
                ) : (
                  ""
                )}
              </List>
            </Collapse>
          ) : (
            ""
          )}
        </List>
        <Divider />
        <div style={{ width: "100%" }}>
          {MENU_LIST.map((data) => {
            return <ListMenu title={data.title} link={data.link} />;
          })}
          <Logout />
        </div>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{
          // background: "none",
          boxShadow: "none",
          borderBottom: "1px solid #383a3e",
          overflow: "hidden",
        }}
        id="displayMobile"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}></div>
          <div className="soonbox"></div>
          <div>
            {/* <Button className="profiles" onClick={handleMenu} color="inherit">
              <div className="profiletop">
                <Avatar
                  style={{ width: 30, height: 30 }}
                  className="avatars"
                  src=""
                />
              </div>
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openSelect}
              onClose={handleClose}
            >
              <MenuItem disabled onClick={handleClose}>
                Profile
              </MenuItem>
              <MenuItem disabled onClick={handleClose}>
                My account
              </MenuItem>
              <Logout />
            </Menu> */}
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <main>
          <div />
          {props.children}
        </main>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;

const ListMenu = ({ title, link }) => {
  return (
    <ListItem
      sx={{
        color: "#fff",
        width: "100%",
        cursor: "pointer",
        paddingBottom: "0px",
        paddingTop: "0px",
        "&:hover": {
          backgroundColor: "#ffffff15",
        },
      }}
      component={Link}
      to={{ pathname: link }}
      target="_blank"
    >
      <ListItemIcon>
        <LinkIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <p
            style={{
              fontSize: 14,
              marginTop: "8px",
              marginBottom: "8px",
            }}
          >
            {title}
          </p>
        }
      />
    </ListItem>
  );
};
