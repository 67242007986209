import { Button, Chip, Grid, Tooltip } from "@mui/material";
import React from "react";
import "./style.css";
export const AddonItem = ({
  item,
  selectedTime,
  setSelectedTime,
  disabled = false,
}) => {
  const date = new Date(item.startTime * 1000);
  const passed = new Date() > date;
  const disableCondition = passed || !item.status || item.quota === 0;
  return (
    <Grid
      item
      sm={6}
      key={item.startTime}
      xs={12}
      style={{
        position: "relative",
      }}
    >
      {item.quota === 0 ? (
        <div className="price-tag-vertical-sold-out">
          <span>
            <b>SOLD</b>
          </span>
          <br></br>
          <span>OUT</span>
        </div>
      ) : (
        <div className="price-tag-vertical-active">
          <span>
            <b> {item.quota}</b>
          </span>
          <br></br>
          <span>left</span>
        </div>
      )}

      <Button
        disabled={disabled || disableCondition}
        fullWidth
        onClick={() => {
          setSelectedTime(item.startTime);
        }}
        variant={item.startTime === selectedTime ? "contained" : "outlined"}
        style={{
          color: "white",
          backgroundColor: item.startTime === selectedTime && "#e25065",
        }}
      >
        {passed ? (
          <Chip
            style={{ position: "absolute", top: 0, left: 0 }}
            size="small"
            label={"Passed"}
          />
        ) : (
          item.status == false && (
            <Chip
              style={{ position: "absolute", top: 0, left: 0 }}
              size="small"
              label={"inactive"}
            />
          )
        )}
        {date.toLocaleDateString("en-US", {
          timeZone: "America/Los_Angeles",
        })}
        <br></br>
        {date.toLocaleTimeString("en-US", {
          timeZone: "America/Los_Angeles",
          hourCycle: "h24",
          timeStyle: "short",
        })}{" "}
        PT /
        {date.toLocaleTimeString("en-US", {
          timeZone: "America/New_York",
          hourCycle: "h24",
          timeStyle: "short",
        })}{" "}
        ET{" "}
      </Button>
    </Grid>
  );
};
