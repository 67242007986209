import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import {
  GET_GIFT_COUPON_COMMENT,
  CREATE_GIFT_COUPON_COMMENT,
} from "../../../../queries";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import AddCommentIcon from "@mui/icons-material/AddComment";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import moment from "moment-timezone";

const CommentButton = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [value, setValue] = useState("");
  const [data, setData] = useState();

  const [createComment, { loading: createLoading }] = useMutation(
    CREATE_GIFT_COUPON_COMMENT
  );
  const [getComments, { loading: getLoading, refetch }] = useLazyQuery(
    GET_GIFT_COUPON_COMMENT,
    {
      onCompleted: (response) => {
        setData(response.getGiftCouponComments);
      },
      fetchPolicy: "no-cache",
    }
  );

  const handleComment = () => {
    createComment({
      variables: {
        input: {
          giftCodeID: props.id,
          comment: value,
          createdAt: moment().unix(),
        },
      },
    })
      .then(() => {
        enqueueSnackbar(`Saved.`, {
          variant: "success",
        });
        setValue("");
        setOpen2(false);
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: "error",
        });
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
    getComments({
      variables: {
        id: props.id,
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleClickOpen}
        sx={{ marginLeft: "20px" }}
      >
        <AddCommentIcon />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ minWidth: "200px" }}
      >
        <DialogTitle id="alert-dialog-title">{"Comments"}</DialogTitle>
        <DialogContent>
          <Timeline position="alternate">
            {data &&
              data.length > 0 &&
              data.map((n, index) => {
                if (index % 2 === 1) {
                  return (
                    <TimelineItem>
                      <TimelineOppositeContent color="text.secondary">
                        <Typography component="span" sx={{ fontSize: ".9em" }}>
                          {n.admin.email}
                        </Typography>
                        <Typography sx={{ fontSize: ".8em" }}>
                          {moment
                            .unix(n.createdAt)
                            .format("DD/MM/YYYY HH:mm:ss")}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography sx={{ fontWeight: "400", fontSize: "1em" }}>
                          {n.comment}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  );
                } else {
                  return (
                    <TimelineItem>
                      <TimelineOppositeContent color="text.secondary">
                        <Typography component="span" sx={{ fontSize: ".9em" }}>
                          {n.admin.email}
                        </Typography>
                        <Typography sx={{ fontSize: ".8em" }}>
                          {moment
                            .unix(n.createdAt)
                            .format("DD/MM/YYYY HH:mm:ss")}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography sx={{ fontWeight: "400", fontSize: "1em" }}>
                          {n.comment}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  );
                }
              })}
          </Timeline>
          <TextField
            id="filled-multiline-flexible"
            label="Comment"
            placeholder="Type more than 2 character to save the comment!"
            multiline
            rows={4}
            value={value}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button
            disabled={getLoading || createLoading || value.length < 3}
            onClick={() => {
              setOpen2(true);
            }}
            autoFocus
            color="secondary"
            variant="outlined"
          >
            {getLoading || createLoading ? <CircularProgress /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={() => {
          setOpen2(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to save your comment?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography sx={{ fontWeight: "600" }}>Your comment:</Typography>
            {value}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen2(false);
            }}
          >
            Close
          </Button>
          <Button onClick={handleComment} autoFocus>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CommentButton;
