import React, { useState, useCallback, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import { RDC_TRANSFER } from "../../../../../queries";
import { getSubscription } from "../../../../../globals";
import CachedIcon from "@mui/icons-material/Cached";

const INTERVAL = 2000;

const RDCTransfer = ({ mongoCustomer, refetch, customerTotalDogs }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [
    transferRDC,
    { loading: loadingMutation, data: dataMutation, error: errorMutation },
  ] = useMutation(RDC_TRANSFER);

  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [transferCoins, setTransferCoins] = useState(0);
  const [loading, setLoading] = useState(false);
  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    if (open) {
      initialState();
    }
  }, [open]);

  const initialState = () => {
    setOpenDialog(false);
    setTransferCoins(0);
    setLoading(false);
    setClicks(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onChangeCoinInput = (e) => {
    const value = e.target.value.replace(/[^\d]+/g, "");

    if (Number.isNaN(value)) {
      setTransferCoins(0);
      return;
    }
    if (value < 1) {
      setTransferCoins(0);
    } else {
      setTransferCoins(parseInt(value));
    }
  };
  const clickOnce = (click) => {
    setClicks(click + 1);
  };

  const debouncedClick = useCallback(
    _.debounce(
      (clicks) => {
        clickOnce(clicks);
      },
      INTERVAL,
      { leading: true, trailing: false, maxWait: INTERVAL }
    ),
    []
  );
  useEffect(() => {
    if (clicks > 0) {
      handleCoinTransfer();
    }
    // eslint-disable-next-line
  }, [clicks]);

  const handleCoinTransfer = async () => {
    setLoading(true);
    transferRDC({
      variables: {
        customerId: mongoCustomer.id,
        amount: transferCoins,
      },
    })
      .then((res) => {
        console.log(res);
        refetch();
        setOpen(false);
        initialState();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen} style={{ marginTop: -10 }}>
        <CachedIcon color="success" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Transfer RDC Operations"}
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: 20,
          }}
        >
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Transfer Coins"
                  variant="outlined"
                  // type="number"
                  value={transferCoins}
                  onChange={(e) => onChangeCoinInput(e)}
                  inputProps={{ style: { textAlign: "end" } }}
                  helperText=""
                  disabled={loading || loadingMutation}
                />
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  variant={"contained"}
                  color={"secondary"}
                  style={{ textTransform: "capitalize" }}
                  disabled={
                    transferCoins <= 0 ||
                    parseInt(transferCoins) > mongoCustomer.wallet.coins ||
                    (parseInt(transferCoins) <= 0 &&
                      parseInt(transferCoins) % 10 === 0) ||
                    loading
                  }
                  onClick={() => {
                    if (parseInt(transferCoins) > mongoCustomer.wallet.coins) {
                      enqueueSnackbar(
                        "Cannot greater than the available real dog coins",
                        {
                          variant: "error",
                        }
                      );
                      return;
                    }
                    setOpenDialog(true);
                  }}
                >
                  {loading || loadingMutation ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Transfer to balance"
                  )}
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
          <Confirm
            clicks={clicks}
            debouncedClick={() => {
              setOpenDialog(false);
              debouncedClick(clicks);
            }}
            open={openDialog}
            setOpen={setOpenDialog}
            amount={transferCoins}
            mongoCustomer={mongoCustomer}
            dogs={customerTotalDogs}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default RDCTransfer;

const Confirm = ({
  debouncedClick,
  clicks,
  open,
  setOpen,
  amount,
  mongoCustomer,
  dogs,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  console.log(mongoCustomer);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "20px", backgroundColor: "#1c1d1f" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent sx={{ padding: "0px 24px 0px 24px !important" }}>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to transfer ${amount} coins to RD Balance?{" "}
              <span style={{ color: "red" }}>
                It cannot be transferred back.
              </span>{" "}
              Your balance will be applied to your recurring payments and
              membership.
            </DialogContentText>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ marginTop: "20px" }}
            >
              {mongoCustomer.stripe.subscription.some(
                (s) => s.status === "active"
              ) &&
                "You do not have any active subscription right now! Please be aware that this balance will be applied on your next recurring payments (subscriptions). "}
            </DialogContentText>
          </DialogContent>
        </div>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            clicks={clicks}
            onClick={debouncedClick}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
