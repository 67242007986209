import { Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { VideoComponentInfoDialog } from "../Components/VideoComponent";

const VideoContentEditor = ({
  onChangeNodeContent,
  rawContent,
  autoSave = true,
}) => {
  const [title, setTitle] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");

  useEffect(() => {
    if (rawContent) {
      setYoutubeLink(JSON.parse(rawContent).youtubeLink);
      setTitle(JSON.parse(rawContent).title);
    }
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (autoSave) {
        onChangeNodeContent(JSON.stringify({ youtubeLink, title }));
      }
    }, 700);

    return () => clearTimeout(timer);
  }, [youtubeLink]);

  const onSaveManual = () => {
    onChangeNodeContent(JSON.stringify({ youtubeLink, title }));
  };

  return (
    <div
      style={{
        position: "relative",
        border: "1px solid #ffffff30",
        padding: 10,
        borderRadius: 5,
      }}
    >
      <Typography>Video Content Editor</Typography>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <VideoComponentInfoDialog />
      </div>
      <br></br>
      <TextField
        fullWidth
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <br></br>
      <br></br>
      <TextField
        fullWidth
        label="Enter youtube url"
        value={youtubeLink}
        onChange={(e) => setYoutubeLink(e.target.value)}
      />
      {/*       <Button
        style={{ float: "right", marginTop: -10 }}
        variant={"contained"}
        color={"secondary"}
        onClick={() => {
          onSaveManual();
        }}
      >
        Save Changes
      </Button> */}
    </div>
  );
};
export default VideoContentEditor;
