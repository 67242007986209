import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import { EditorState, convertFromRaw, EditorBlock } from "draft-js";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Button } from "@mui/material";

const styles = {
  darkBgMain: {
    backgroundColor: "#202124",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  episodeTable: {
    width: "100%",
    maxWidth: "600px",
    borderCollapse: "seperate !important",
    WebkitBorderVerticalSpacing: "15px !important",
    borderSpacing: "15px !important",
    WebkitBorderHorizontalSpacing: "0px",
    margin: "auto",
  },
  episodeTr: {
    maxWidth: "600px",
  },
  leashContainer: {
    width: "600px",
    padding: "20px",
    borderRadius: "6px",
    backgroundColor: "#1a1b1d",
    margin: "auto",
  },
  leashContainerImgA: {
    width: "100%",
  },
  leashContainerImg: {
    width: "100%",
    borderRadius: "6px",
    maxWidth: "600px",
  },
  imageContainer500: {
    maxHeight: "500px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    marginBottom: "15px",
  },
  headerTd: {
    position: "relative !important",
    textAlign: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    zIndex: "0",
    backgroundImage: `url(http://s3-us-west-1.amazonaws.com/realdog-s3/static/walk.jpg)`,
  },

  headerLogo: {
    width: "160px",
    marginTop: "70px",
    position: "relative",
  },
  episodeRd: {
    marginTop: "25px",
    marginBottom: "35px",
    fontSize: "14px",
    lineHeight: "1.81em",
    position: "relative !important",
  },
  episodeRdA: {
    color: "#fff !important",
    fontSize: "13px",
    lineHeight: "1.81em",
    opacity: "0.88",
    textDecoration: "none",
  },
};

const CreateHeader = ({ data }) => {
  return (
    <tr style={styles.episodeTr} id="bgImage">
      <td style={styles.headerTd}>
        <div style={styles.headerBG}></div>
        <a href="shortleash.co">
          <img
            src={"https://s3.amazonaws.com/shortleash-s3/shortleash-logo.png"}
            style={styles.headerLogo}
          />
        </a>
        <p style={styles.episodeRd}>
          <b style={{ position: "relative", color: "#fff" }}>
            {data?.getShortleashByIdAdmin.title}
          </b>{" "}
          <a href="https://real.dog/" target="_blank" style={styles.episodeRdA}>
            by Real.dog/box
          </a>
        </p>
      </td>
    </tr>
  );
};

const CreateBody = ({ leash, data }) => {
  const [markup, setMarkup] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(convertFromRaw(JSON.parse(leash.html)))
  );

  return (
    <tr style={styles.episodeTr} key={data.getShortleashByIdAdmin.sefurl}>
      <td style={styles.leashContainer}>
        <a
          href={
            "https://newsletter.co/" +
            data.getShortleashByIdAdmin.sefurl +
            "/" +
            leash.sefurl
          }
          style={styles.leashContainerImgA}
        >
          <img
            src={leash.thumbnail}
            style={styles.leashContainerImg}
            width="100%"
          />
        </a>
        <div
          id="mainTable"
          style={{
            overflow: "hidden",
            textDecoration: "none",
          }}
          dangerouslySetInnerHTML={{
            __html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          }}
        ></div>
      </td>
    </tr>
  );
};

const CreateFooter = () => {
  return (
    <tbody>
      <tr style={{ maxWidth: "600px" }} key={"a"}>
        <td style={{ textAlign: "center" }}>
          <div
            style={{
              maxWidth: "600px",
              padding: "20px",
              borderRadius: "6px",
              backgroundColor: "#1a1b1d",
              margin: "auto",
              color: "#fff",
            }}
          >
            <h2 style={{ fontSize: "30px" }}>
              Want to know more about The Short Leash!?
            </h2>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "1.81em",
              }}
            >
              At Real, we look beyond our walls to make sure we are staying up
              to date with anything that can nourish the connection between
              humans and dogs. There is no other example of unconditional love
              and companionship between two species.
            </p>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "1.81em",
              }}
            >
              We’re passionate about learning and sharing every bit of info we
              can to improve this connection. Feel free to pass this along to
              dog lovers like yourself who share this passion. We’d love to hear
              from you. Hit reply to give us feedback, your ideas, and
              suggestions to help improve our bi-weekly emails.
            </p>
          </div>
        </td>
      </tr>
      <div
        key={"b"}
        dangerouslySetInnerHTML={{
          __html: `<tr>
					<td style="padding-top:9px;padding-right:9px;padding-left:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" >
					<table style="margin:auto;border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
					<tbody>
					<tr>
					<td style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" >
					  <!--[if mso]>
					  <table align="center" border="0" cellspacing="0" cellpadding="0">
						<tr>
						  <![endif]-->
					  <!--[if mso]>
					  <td >
						<![endif]-->
					  <table style="display:inline;border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
						<tbody>
						  <tr>
							<td class="mcnFollowContentItemContainer" style="padding-right:10px;padding-bottom:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" >
							  <table class="mcnFollowContentItem" style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
								<tbody>
								  <tr>
									<td style="padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle">
									  <table style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
										<tbody>
										  <tr>
											<td class="mcnFollowIconContent" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
											  <a href="http://instagram.com/realdogbox" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;border-bottom:0 transparent !important;color:#63b274;text-decoration:none;" target="_blank"><img alt="dark-instagram-48.png" src="https://cdn-images.mailchimp.com/icons/social-block-v2/dark-instagram-48.png" style="width:24px;height:24px;display: block;                                                                     height: auto;                                                                     outline: none;                                                                     text-decoration: none;                                                                     -ms-interpolation-mode: bicubic;                                                                     border-bottom: 0                                                                       transparent !important;                                                                     border: 0 !important;" ></a>
											</td>
										  </tr>
										</tbody>
									  </table>
									</td>
								  </tr>
								</tbody>
							  </table>
							</td>
						  </tr>
						</tbody>
					  </table>
					  <!--[if mso]>
					</td>
					<![endif]-->
					  <!--[if mso]>
					  <td >
						<![endif]-->
					  <table  style="display:inline;border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
						<tbody>
						  <tr>
							<td class="mcnFollowContentItemContainer" style="padding-right:10px;padding-bottom:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
							  <table  class="mcnFollowContentItem" style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" >
								<tbody>
								  <tr>
									<td style="padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle">
									  <table  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
										<tbody>
										  <tr>
											<td class="mcnFollowIconContent" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle" >
											  <a href="http://www.facebook.com/realdogbox" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;border-bottom:0 transparent !important;color:#63b274;text-decoration:none;" target="_blank"><img alt="dark-facebook-48.png"src="https://cdn-images.mailchimp.com/icons/social-block-v2/dark-facebook-48.png" style="width:24px;height:24px;display: block;                                                                     height: auto;                                                                     outline: none;                                                                     text-decoration: none;                                                                     -ms-interpolation-mode: bicubic;                                                                     border-bottom: 0                                                                       transparent !important;                                                                     border: 0 !important;"></a>
											</td>
										  </tr>
										</tbody>
									  </table>
									</td>
								  </tr>
								</tbody>
							  </table>
							</td>
						  </tr>
						</tbody>
					  </table>
					  <!--[if mso]>
					</td>
					<![endif]-->
					  <!--[if mso]>
					  <td >
						<![endif]-->
					  <table  style="display:inline;border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
						<tbody>
						  <tr>
							<td class="mcnFollowContentItemContainer" style="padding-right:10px;padding-bottom:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" >
							  <table class="mcnFollowContentItem" style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" >
								<tbody>
								  <tr>
									<td style="padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" >
									  <table  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
										<tbody>
										  <tr>
											<td class="mcnFollowIconContent" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;"  >
											  <a href="https://real.dog/box/" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;border-bottom:0 transparent !important;color:#63b274;text-decoration:none;" target="_blank"><img alt="dark-link-48.png"  src="https://cdn-images.mailchimp.com/icons/social-block-v2/dark-link-48.png" style="width:24px;height:24px;display: block;                                                                     height: auto;                                                                     outline: none;                                                                     text-decoration: none;                                                                     -ms-interpolation-mode: bicubic;                                                                     border-bottom: 0                                                                       transparent !important;                                                                     border: 0 !important;"></a>
											</td>
										  </tr>
										</tbody>
									  </table>
									</td>
								  </tr>
								</tbody>
							  </table>
							</td>
						  </tr>
						</tbody>
					  </table>
					  <!--[if mso]>
					</td>
					<![endif]-->
					  <!--[if mso]>
					  <td >
						<![endif]-->
					  <table style="display:inline;border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
						<tbody>
						  <tr>
							<td class="mcnFollowContentItemContainer" style="padding-right:0;padding-bottom:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
							  <table class="mcnFollowContentItem" style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" >
								<tbody>
								  <tr>
									<td style="padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle">
									  <table style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
										<tbody>
										  <tr>
											<td class="mcnFollowIconContent" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle" width="24">
											  <a href="https://insights.realpetfood.com/" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;border-bottom:0 transparent !important;color:#63b274;text-decoration:none;" target="_blank"><img alt="dark-medium-48.png"src="https://cdn-images.mailchimp.com/icons/social-block-v2/dark-medium-48.png" style="width:24px; display: block;                                                                     height: auto;                                                                     outline: none;                                                                     text-decoration: none;                                                                     -ms-interpolation-mode: bicubic;                                                                     border-bottom: 0                                                                       transparent !important;                                                                     border: 0 !important;" ></a>
											</td>
										  </tr>
										</tbody>
									  </table>
									</td>
								  </tr>
								</tbody>
							  </table>
							</td>
						  </tr>
						</tbody>
					  </table>
					  <!--[if mso]>
					</td>
					<![endif]-->
					  <!--[if mso]>
					</tr>
					</table>
					<![endif]-->
					</td>
					</tr>
					</tbody>
					</table>
					</td>
					</tr>
					
					<tr>
					<td class="mcnTextBlockInner" style="padding-top:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
					<!--[if mso]>
					<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
					  <tr>
						<![endif]-->
					<!--[if mso]>
					<td valign="top">
					  <![endif]-->
					<tableclass="mcnTextContentContainer" style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
					<tbody>
					<tr>
					<td class="mcnTextContent" style="padding-top:0;padding-left:0px;padding-bottom:9px;padding-right:0px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;color:#656565;font-family:Helvetica;font-size:12px;line-height:150%;text-align:center;" valign="top">
					Copyright © 2018
					<a href="https://real.dog/box/" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;color:#656565;font-weight:normal;border-bottom:0 transparent !important;text-decoration:none !important;" target="_blank">Real.Dog/Box</a>, All rights reserved.<br>
					Text us anytime
					<a style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;color:#656565;font-weight:normal;border-bottom:0 transparent !important;text-decoration:none !important;">858-348-5954</a>.
					</td>
					</tr>
					</tbody>
					</table>
					<!--[if mso]>
					</td>
					<![endif]-->
					<!--[if mso]>
					</tr>
					</table>
					<![endif]-->
					</td>
					</tr>`,
        }}
      ></div>
    </tbody>
  );
};

const CreateHTML = (data) => {
  return (
    <div style={styles.darkBgMain}>
      <div style={styles.container}>
        <table style={styles.episodeTable}>
          <tbody>
            <CreateHeader data={data} />
            {data.getShortleashByIdAdmin.leashes.map((leash) => (
              <CreateBody leash={leash} data={data} />
            ))}
            <CreateFooter />
          </tbody>
        </table>
      </div>
    </div>
  );
};

const CreateFullHtml = (data) => {
  return (
    <html lang="en">
      <head>
        <meta charSet="UTF-8" />
        <meta content="IE=edge" httpEquiv="x-ua-compatible" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link id="dark-mode-general-link" rel="stylesheet" type="text/css" />
        <link id="dark-mode-custom-link" rel="stylesheet" type="text/css" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
          rel="stylesheet"
        />
        <style type="text/css">
          {`body{font-family: Source Sans Pro, sans-serif;text-decoration:none;}
                    strong{font-weight:600;text-decoration:none !important;color:#fff;}
                    a{color:#398545 !important;text-decoration:none !important}
					a strong{color:#398545 !important;}
					a strong:hover{color:#68b31d !important;}
                    a:hover{color:#68b31d !important;}
                    p{color:#fff;text-decoration:none;font-size:14px;line-height:1.81em;}
					#mainTable p a strong{color:#398545;text-decoration:none !important;font-size:14px;line-height:1.81em;}
					#mainTable p a strong:hover{color:#68b31d;text-decoration:none !important;font-size:14px;line-height:1.81em;}
					#mainTable p a {color:#398545;text-decoration:none !important;font-size:14px;line-height:1.81em;}
					#mainTable a:hover{color:#68b31d;text-decoration:none !important;font-size:14px;line-height:1.81em;}
					#bgImage td p a{color: #fff !important;font-size: 14px !important;;opacity:0.88;text-decoration:none !important;line-height:1.81em;}
					#bgImage td p a:hover{opacity:1;}
                    `}
        </style>
      </head>
      <body>
        <div
          id="root"
          dangerouslySetInnerHTML={{
            __html: ReactDOMServer.renderToString(CreateHTML(data)),
          }}
        />
      </body>
    </html>
  );
};

const MailTemplate = ({ data }) => {
  return (
    <Button
      type="submit"
      fullWidth
      variant="outlined"
      color={"primary"}
      size="large"
      sx={{ marginBottom: "10px", marginTop: "10px" }}
      onClick={() => {
        navigator.clipboard.writeText(
          `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">` +
            ReactDOMServer.renderToString(CreateFullHtml(data))
        );
      }}
    >
      Copy For Mail
    </Button>
  );
};

export default MailTemplate;
