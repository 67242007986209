import { Button, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import { convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import IngredientsComponentHintEditor from "../../../../assets/editorHints/IngredientsEditor.jpeg";

const IngredientsComponent = ({
  title = "",
  img = "",
  smallDetetail = "",
  analysisTitle = "",
  analysisArray = [],
  detailNodes = [],
}) => {
  return (
    <Container
      className="card-preview-container"
      style={{
        border: "1px solid rgba(255, 255, 255, 0.19)",
        borderRadius: "5px",
        padding: 10,
        "::after": {
          content: "Preview",
          position: "absolute",
        },
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
          padding: 10,
        }}
      >
        <Grid item sm={6}>
          <Typography
            variant="h6"
            style={{
              color: "#63b475",
              fontWeight: "bolder",
            }}
          >
            100% REAL {title.toUpperCase()}
          </Typography>
          <img
            src={img}
            alt="Product"
            style={{
              width: "60%",
            }}
          />
          <Typography>{smallDetetail}</Typography>
          <Typography
            variant="h6"
            style={{
              color: "#63b475",
              fontWeight: "bolder",
            }}
          >
            {analysisTitle}
          </Typography>
          {analysisArray.map((singleAnalysis) => {
            return <Typography>{singleAnalysis}</Typography>;
          })}
        </Grid>
        <Grid item sm={6}>
          {detailNodes.map((singleDetailNode) => {
            const content = singleDetailNode.text
              ? JSON.parse(singleDetailNode.text)
              : {
                  entityMap: {},
                  blocks: [],
                };
            const editorState = draftToHtml(content);
            return (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <div className="rightbox">
                    <div className="iconright">
                      <img src={singleDetailNode.icon} />
                    </div>
                    <div
                      id={"article"}
                      dangerouslySetInnerHTML={{ __html: editorState }}
                    ></div>
                  </div>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <img
          src="https://s3-us-west-1.amazonaws.com/realdog-s3/static/real-dog-food-logo.png"
          alt="RD logo"
          style={{
            width: 50,
          }}
        />
        <Typography
          style={{
            lineHeight: "normal",
            marginLeft: 10,
            fontWeight: "lighter",
          }}
        >
          REAL.DOG<br></br>
          #FEEDREAL
        </Typography>
      </Grid>
    </Container>
  );
};

export default IngredientsComponent;

export const IngredientComponentInfoDialog = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <InfoIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent>
          <img src={IngredientsComponentHintEditor}></img>
          <Typography>
            On left column;{" "}
            <ul>
              <li>
                use the button to upload the image indicated by the green arrow
              </li>
              <li>
                use the text field ,image indicated by the green arrow with
                green rectangle, to give some small detail of product
              </li>
              <li>
                use the text field to give title to the red box indicated by the
                blue arrow
              </li>
              <li>
                Click Add Analysis button. When you ckick it you will see text
                field that you can add Analysis for product. if you need to add
                more analysis click to add analysis button again (each row has
                corresponding text field)
              </li>
            </ul>
            On right column;{" "}
            <ul>
              <li>
                There are three field to fill, each one shown with green arrows{" "}
              </li>
            </ul>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
