import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import { MEMBERSHIP_LIST, ANALYTICS_MEMBERSHIP } from "../../../queries";

import Search from "./components/search";
import { getSubscription } from "../../../globals";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 500,
  },
  noMaxWidth: {
    maxWidth: "none",
  },
  searchBtn: {
    width: "100%",
    marginTop: "10px",
  },
}));

function Membership(props) {
  const classes = useStyles();
  const [analytics, setAnalytics] = useState({});
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    loading: loadingAnalytics,
    data: dataAnalytics,
    refetch: refetchAnalytics,
  } = useQuery(ANALYTICS_MEMBERSHIP, {
    variables: { category },
  });
  const { subscribeToMore, loading, error, data, refetch, fetchMore } =
    useQuery(MEMBERSHIP_LIST, {
      variables: {
        offset: page,
        limit: rowsPerPage,
        category,
      },
    });

  useEffect(() => {
    refetch();
  }, [refetch, rowsPerPage]);

  useEffect(() => {
    refetch();
    setPage(0);
    setRowsPerPage(10);
  }, [category, refetch]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const loadPage = () => {
    fetchMore({
      variables: {
        category: category,
        offset: data.membershipList.length,
        limit: rowsPerPage,
      },
    });
  };

  useEffect(() => {
    if (dataAnalytics) {
      const { analyticsMembersip } = dataAnalytics;
      if (analyticsMembersip) {
        setAnalytics(analyticsMembersip);
      }
    }
  }, [dataAnalytics, loadingAnalytics]);

  const filterTable = (category) => {
    setCategory(category.length > 2 ? category : "");
    refetch();
    refetchAnalytics();
  };

  if (loading) return <CircularProgress className="loadingbutton" />;

  if (error) return <div>Error.</div>;

  return (
    <>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Membership</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <Typography color="textPrimary">
        {category ? category : "Membership"}
      </Typography>
      <Grid container spacing={3} className="max1200">
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper className={`borderblue summarybox ${classes.paper}`}>
                Daily <b>{analytics ? analytics.daily : 0} </b>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={`borderred summarybox ${classes.paper}`}>
                Weekly <b>{analytics ? analytics.weekly : 0} </b>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={`borderyellow summarybox ${classes.paper}`}>
                Monthly <b>{analytics ? analytics.monthly : 0} </b>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Search
        history={props.history}
        category={category}
        filterTable={filterTable}
      />
      <br />
      <Typography color="textPrimary">Memberships</Typography>
      <TableUI
        {...data}
        refetch={refetch}
        history={props.history}
        loading={loading}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

const TableUI = (props) => {
  const { loading, history } = props;
  const checkIfActive = (subs, type = "rdb_membership") => {
    let sub = getSubscription(subs, type);
    if (sub && ["active", "trialing", "past_due"].includes(sub.data.status)) {
      return true;
    }
    return true;
  };
  return (
    <ul>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Starting Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Category</TableCell>
            </TableRow>
          </TableHead>
          {!loading && (
            <TableBody>
              {props.membershipList && props.membershipList.length > 0
                ? props.membershipList
                    .slice(
                      props.page * props.rowsPerPage,
                      props.page * props.rowsPerPage + props.rowsPerPage
                    )
                    .map((field) => {
                      return (
                        <TableRow key={field.id}>
                          <TableCell component="th" scope="row">
                            {field.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {field.email}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {getSubscription(field.subscriptions)
                              ? moment(
                                  getSubscription(field.subscriptions).data
                                    .createdAt
                                ).format("MM/DD/YYYY")
                              : moment(
                                  getSubscription(
                                    field.subscriptions,
                                    "feedreal"
                                  ).data.createdAt
                                ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {checkIfActive(field.subscriptions) ||
                            checkIfActive(field.subscriptions, "feedreal")
                              ? "Active"
                              : "Cancelled"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {getSubscription(field.subscriptions)
                              ? "Real.Dog"
                              : "Feedreal"}
                          </TableCell>
                          <TableCell component="th" align="left" scope="row">
                            <Button
                              className="redborder"
                              variant="outlined"
                              onClick={() => {
                                history.push(`/dashboard/customer/${field.id}`);
                              }}
                              size="small"
                            >
                              <b className="redborder">Details</b>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                : ""}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={
          props.membershipListCount
            ? props.membershipListCount
                .map((m) => m.count)
                .reduce((a, b) => a + b)
            : 0
        }
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onPageChange={props.handleChangePage}
        onRowsPerPageChange={props.handleChangeRowsPerPage}
      />
    </ul>
  );
};

export default Membership;
