import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { MULTIPLE_CHOICE, QUESTION_ANSWER } from "../../../../globals";
import AddIcon from "@mui/icons-material/Add";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMutation } from "@apollo/client";
import { CREATE_QUESTION, UPDATE_QUESTION } from "../../../../queries";

const QUESTION_MIN_LENGTH = 1;
const QUESTION_OPTION_LENGTH = 1;
const QUESTION_OPTIONS_MIN = 2;
const QUESTION_OPTIONS_MAX = 4;
const INITIAL_ANSWERINDEX = -1;

const questionBase = {
  question: "",
  options: ["", ""],
  answerIndex: INITIAL_ANSWERINDEX,
  questionType: MULTIPLE_CHOICE,
  status: true,
  tags: [],
  categories: [],
  answer: "",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function QuestionDialog({
  update = false,
  create = false,
  originalQuestionItem = {},
  refetch,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const [questionItem, setQuestionItem] = useState(
    create ? questionBase : { ...originalQuestionItem }
  );

  useEffect(() => {
    if (update) {
      setValue(originalQuestionItem.questionType === MULTIPLE_CHOICE ? 0 : 1);
    }
  }, []);

  useEffect(() => {
    if (open) {
      setQuestionItem(create ? questionBase : { ...originalQuestionItem });
    }
  }, [open]);

  const [createQuestionMutation, { loading: createLoading }] = useMutation(
    CREATE_QUESTION,
    {
      onCompleted: (response) => {
        console.log(response);
        refetch();
        handleClose();
      },
    }
  );
  const [updateQuestionMutation, { loading: updateLoading }] = useMutation(
    UPDATE_QUESTION,
    {
      onCompleted: (response) => {
        console.log(response);
        refetch();
        handleClose();
      },
    }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSetQuestionText = (text) => {
    const copy = JSON.parse(JSON.stringify(questionItem));
    copy.question = text;
    setQuestionItem(copy);
  };
  const onSetOptionText = (text, index) => {
    const copy = JSON.parse(JSON.stringify(questionItem));
    copy.options[index] = text;
    setQuestionItem(copy);
  };
  const onDeleteOption = (index) => {
    const copy = JSON.parse(JSON.stringify(questionItem));
    copy.options.splice(index, 1);
    setQuestionItem(copy);
  };
  const onAddOption = () => {
    const copy = JSON.parse(JSON.stringify(questionItem));
    copy.options.push("");
    setQuestionItem(copy);
  };
  const onSetAnswer = (index) => {
    const copy = JSON.parse(JSON.stringify(questionItem));
    copy.answerIndex = index;
    setQuestionItem(copy);
  };

  const setStatus = (data) => {
    const copy = JSON.parse(JSON.stringify(questionItem));
    copy.status = data;
    setQuestionItem(copy);
  };
  const onSetAnswerText = (text) => {
    const copy = JSON.parse(JSON.stringify(questionItem));
    copy.answer = text;
    setQuestionItem(copy);
  };

  const onClickSubmit = () => {
    if (update) {
      onUpdateQuestion();
    }
    if (create) {
      onCreateQuestion();
    }
  };

  const onUpdateQuestion = () => {
    const valid = value === 0 ? isValidOption() : isValidQA();
    const input = createInput();
    delete input["id"];
    if (valid) {
      updateQuestionMutation({
        variables: {
          id: questionItem.id,
          input: input,
        },
      });
    }
  };
  const onCreateQuestion = () => {
    const valid = value === 0 ? isValidOption() : isValidQA();
    const input = createInput();
    if (valid) {
      createQuestionMutation({
        variables: {
          input: input,
        },
      });
    }
  };
  const createInput = () => {
    const copy = JSON.parse(JSON.stringify(questionItem));
    if (value === 0) {
      return copy;
    }
    if (value === 1) {
      return { ...copy, options: [], questionType: QUESTION_ANSWER };
    }
  };

  const isValidOption = () => {
    if (questionItem.answerIndex === INITIAL_ANSWERINDEX) {
      enqueueSnackbar(`Please select correct answer`, {
        variant: "error",
      });
      return false;
    }
    if (questionItem.question.length < QUESTION_MIN_LENGTH) {
      enqueueSnackbar(
        `Quiz questions is not valid (min lenght ${QUESTION_MIN_LENGTH})`,
        {
          variant: "error",
        }
      );
      return false;
    }
    if (
      questionItem.options.length < QUESTION_OPTIONS_MIN ||
      questionItem.options.length > QUESTION_OPTIONS_MAX
    ) {
      enqueueSnackbar(
        `Options length is not valid (min lenght ${QUESTION_OPTIONS_MIN} max length  ${QUESTION_OPTIONS_MIN})`,
        {
          variant: "error",
        }
      );
      return false;
    }
    if (
      !questionItem.options.every(
        (item) => item.length >= QUESTION_OPTION_LENGTH
      )
    ) {
      enqueueSnackbar(
        `Options items length is not valid (min length ${QUESTION_OPTION_LENGTH} )`,
        {
          variant: "error",
        }
      );
      return false;
    }
    return true;
  };
  const isValidQA = () => {
    if (questionItem.question.length < QUESTION_MIN_LENGTH) {
      enqueueSnackbar(
        `Quiz questions is not valid (min lenght ${QUESTION_MIN_LENGTH})`,
        {
          variant: "error",
        }
      );
      return false;
    }
    return true;
  };

  return (
    <div>
      {create ? (
        <Button color="secondary" variant="outlined" onClick={handleClickOpen}>
          Create Question
        </Button>
      ) : (
        <IconButton onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          {update && "Update Question"}
          {create && "Create Question"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ width: "100%" }}>
              {create && (
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Multiple Choice" {...a11yProps(0)} />
                    <Tab label="Question Answer" {...a11yProps(1)} />
                  </Tabs>
                </Box>
              )}
              <TabPanel value={value} index={0}>
                <MultipleOptionQuiz
                  question={questionItem.question}
                  setQuestion={(text) => {
                    onSetQuestionText(text);
                  }}
                  options={questionItem.options}
                  setOptionsText={(text, index) => {
                    onSetOptionText(text, index);
                  }}
                  onDeleteOption={(index) => {
                    onDeleteOption(index);
                  }}
                  onAddOption={() => {
                    onAddOption();
                  }}
                  answerIndex={questionItem.answerIndex}
                  onSetAnswer={(index) => {
                    onSetAnswer(index);
                  }}
                  loading={createLoading}
                  status={questionItem.status}
                  setStatus={(data) => {
                    setStatus(data);
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <QuestionAnswerOptionQuiz
                  question={questionItem.question}
                  setQuestion={(text) => {
                    onSetQuestionText(text);
                  }}
                  loading={createLoading}
                  status={questionItem.status}
                  setStatus={(data) => {
                    setStatus(data);
                  }}
                  answer={questionItem.answer}
                  setAnswer={(text) => {
                    onSetAnswerText(text);
                  }}
                />
              </TabPanel>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              onClickSubmit();
            }}
            autoFocus
          >
            {update && "Update"}
            {create && "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const MultipleOptionQuiz = ({
  question,
  setQuestion,
  options,
  setOptionsText,
  onDeleteOption,
  onAddOption,
  answerIndex,
  onSetAnswer,
  loading,
  status,
  setStatus,
}) => {
  return (
    <Paper
      style={{
        marginBottom: 20,
        backgroundColor: "#252525",
        position: "relative",
      }}
      className={"every-child-margin "}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={status}
            onClick={() => {
              setStatus(!status);
            }}
            color={"secondary"}
          />
        }
        label="Status (unselect if you do not want user to see) "
      />

      <TextField
        fullWidth
        label="Question"
        value={question}
        disabled={loading}
        onChange={(e) => {
          setQuestion(e.target.value);
        }}
      />

      {options.map((option, index) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={1}>
              <IconButton
                disabled={loading}
                onClick={() => {
                  onSetAnswer(index);
                }}
                edge="end"
              >
                {answerIndex == index ? (
                  <RadioButtonCheckedIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                disabled={loading}
                value={option}
                onChange={(e) => {
                  setOptionsText(e.target.value, index);
                }}
                label={"Option"}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                color="error"
                edge="end"
                disabled={options.length <= 2 || loading}
                onClick={() => {
                  onDeleteOption(index);
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          disabled={options.length >= 4}
          onClick={() => {
            onAddOption();
          }}
          variant="outlined"
        >
          <AddIcon /> Add Option
        </Button>
      </Grid>
    </Paper>
  );
};

const QuestionAnswerOptionQuiz = ({
  question,
  setQuestion,
  loading,
  status,
  setStatus,
  answer,
  setAnswer,
}) => {
  return (
    <Paper
      style={{
        marginBottom: 20,
        backgroundColor: "#252525",
        position: "relative",
      }}
      className={"every-child-margin "}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={status}
            onClick={() => {
              setStatus(!status);
            }}
          />
        }
        label="Status (unselect if you do not want user to see)"
      />

      <TextField
        fullWidth
        label="Question"
        value={question}
        disabled={loading}
        onChange={(e) => {
          setQuestion(e.target.value);
        }}
      />
      <TextField
        fullWidth
        label="Answer"
        value={answer}
        disabled={loading}
        onChange={(e) => {
          setAnswer(e.target.value);
        }}
      />
    </Paper>
  );
};
