import { gql } from "@apollo/client";

const FETCH_APPOINTMENTS_BY_DATE = gql`
  query GetAppointmentsByDate($date: String) {
    getAppointmentsByDate(date: $date) {
      id
      customer {
        id
        name
        first_name
        last_name
      }
      support {
        id
        name
      }
      status
      dateTime
      dateString
      period
    }
  }
`;

const GET_SUPPORT_MEMBERS = gql`
  query GetSupportMembers {
    supportMembers {
      id
      name
      canAddSchedule
      img
    }
  }
`;

const GET_AVAILABLE_SUPPORT_MEMBERS = gql`
  query GetAvailableSupportingMembers($date: String) {
    getAvailableSupportMembers(date: $date) {
      id
      name
    }
  }
`;

const GET_APPOINTMENT_BY_STATUS = gql`
  query GetAppointmentsByStatus($status: AppointmentStatus) {
    getAppointmentsByStatus(status: $status) {
      id
      customer {
        id
        name
        first_name
        last_name
      }
      support {
        id
        name
        surname
      }
      status
      dateTime
      dateString
      period
    }
  }
`;

const CREATE_APPOINTMENT = gql`
  mutation AddAppointmentSchedule(
    $supportId: String
    $input: AppointScheduleInput
  ) {
    addAppointmentSchedule(supportId: $supportId, input: $input) {
      period
      schedules {
        dateTime
        duration
        status
        active
      }
    }
  }
`;

const UPDATE_APPOINTMENT = gql`
  mutation UpdateAppointment(
    $appointmentId: String
    $input: UpdateAppointmentInput
  ) {
    updateAppointment(appointmentId: $appointmentId, input: $input) {
      id
      status
    }
  }
`;

const ADD_NOTES_APPOINTMENT = gql`
  mutation AddAppointmentComment($appointmentId: String, $comment: String) {
    addAppointmentComment(appointmentId: $appointmentId, comment: $comment) {
      id
      comment
    }
  }
`;

const GET_SUPPORT_MEMBER_SCHEDULE = gql`
  query GetSupportMemberSchedules($supportMemberId: ID, $date: String) {
    getSupportMemberSchedules(supportMemberId: $supportMemberId, date: $date) {
      period
      status
      schedules {
        id
        active
        dateTime
        dateString
        status
        customer {
          name
          email
        }
      }
    }
  }
`;

const GET_SUPPORT_MEMBER_APPOINTMENTS = gql`
  query GetSupportMemberAppointments($supportMemberId: ID, $date: String) {
    getSupportMemberAppointments(
      supportMemberId: $supportMemberId
      date: $date
    ) {
      id
      period
      status
      dateTime
      dateString
      support {
        email
        id
        name
        surname
      }
      customer {
        id
        first_name
        last_name
      }
    }
  }
`;

const GET_AVAILABLEDAYS_SUPPORTMEMBER = gql`
  query GetCalendarAppointmentsOfSupporMember($supportMemberId: ID) {
    getCalendarAppointmentsOfSupporMember(supportMemberId: $supportMemberId) {
      day
      hasAvaiableAppointment
    }
  }
`;

const ADD_NOTE_APPOINTMENT = gql`
  mutation AddAppointmentNote(
    $customerId: ID
    $appointmentId: ID
    $note: String
  ) {
    addAppointmentNote(
      appointmentId: $appointmentId
      customerId: $customerId
      note: $note
    ) {
      note
    }
  }
`;

const GET_APPOINTMENT_STATS = gql`
  query GetAppointmentStats($supportMemberId: ID) {
    getAppointmentStats(supportMembeId: $supportMemberId) {
      weekly {
        available
        reserved
        pending
        canceled
        completed
      }
      members
    }
  }
`;

const CREATE_SCHEDULE_FROM_PRESET = gql`
  mutation CreateScheduleFromPreset(
    $accountManagerId: ID!
    $input: [CreateScheduleFromPresetInput]
  ) {
    createScheduleFromPresets(
      accountManagerId: $accountManagerId
      input: $input
    ) {
      status
      period
      schedules {
        dateTime
        active
      }
    }
  }
`;

const CREATE_SAVED_SCHEDULE = gql`
  mutation CreateAppointmentPreset($input: SchedulePresetInput) {
    createSchedulePreset(input: $input) {
      id
      name
    }
  }
`;

const FETCH_SAVED_SCHEDULE = gql`
  query GetAppointmentPresets($supportMembeId: ID) {
    getSchedulePresets(supportMembeId: $supportMembeId) {
      id
      name
      schedules {
        name
        value
      }
    }
  }
`;

const CONFIRM_SCHEDULE = gql`
  mutation ConfirmSchedule($input: [ConfirmScheduleInput]) {
    confirmSchedule(input: $input) {
      status
    }
  }
`;

const ALLOW_STM = gql`
  mutation AllowAddingSchedule($input: [AllowScheduleInput]) {
    allowAddingSchedule(input: $input) {
      email
      canAddSchedule
    }
  }
`;

const GET_APPOINTMENT_NOTES = gql`
  query GetAppointmentNotes($customerId: ID) {
    getAppointmentNotes(customerId: $customerId) {
      admin {
        email
        name
      }
      customer {
        email
        name
      }
      note
      createdAt
    }
  }
`;

const CHANGE_ACCOUNT_MANAGER = gql`
  mutation ChangeAccountManager($id: ID!, $managerId: ID!) {
    changeAccountManager(id: $id, managerId: $managerId) {
      accountManager {
        email
      }
    }
  }
`;

const GET_ACCOUNT_MANAGER = gql`
  query GetAccountManagers {
    accountManagers {
      id
      name
      email
      img
    }
  }
`;

const UPDATE_SCHEDULE = gql`
  mutation UpdateSchedule($id: ID!, $input: UpdateScheduleInput) {
    updateSchedule(id: $id, input: $input) {
      id
      status
      active
      dateTime
    }
  }
`;

const MAKE_APPOINTMENT = gql`
  mutation MakeAppointment(
    $customerId: String
    $scheduleId: String
    $reserve: Boolean
  ) {
    makeAppointment(
      customerId: $customerId
      scheduleId: $scheduleId
      reserve: $reserve
    ) {
      status
    }
  }
`;

const INTAKE_FORM = gql`
  mutation AddIntakeForm($dogId: ID, $input: [IntakeFormInput]) {
    addIntakeForm(dogId: $dogId, input: $input) {
      id
      questions
    }
  }
`;

const GET_APPONTMENT_MEMBER = gql`
  query GetAllCustomerAppointments($customerId: String) {
    getCustomerAppointments(customerId: $customerId) {
      appointments {
        id
        support {
          img
          name
          title
        }
        customer {
          id
        }
        dateTime
        status
        dateString
      }
    }
  }
`;

export {
  FETCH_APPOINTMENTS_BY_DATE,
  GET_SUPPORT_MEMBERS,
  GET_AVAILABLE_SUPPORT_MEMBERS,
  GET_APPOINTMENT_BY_STATUS,
  CREATE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  ADD_NOTES_APPOINTMENT,
  GET_SUPPORT_MEMBER_SCHEDULE,
  GET_SUPPORT_MEMBER_APPOINTMENTS,
  GET_AVAILABLEDAYS_SUPPORTMEMBER,
  ADD_NOTE_APPOINTMENT,
  GET_APPOINTMENT_STATS,
  CREATE_SCHEDULE_FROM_PRESET,
  CREATE_SAVED_SCHEDULE,
  FETCH_SAVED_SCHEDULE,
  CONFIRM_SCHEDULE,
  ALLOW_STM,
  GET_APPOINTMENT_NOTES,
  CHANGE_ACCOUNT_MANAGER,
  GET_ACCOUNT_MANAGER,
  UPDATE_SCHEDULE,
  MAKE_APPOINTMENT,
  INTAKE_FORM,
  GET_APPONTMENT_MEMBER,
};
