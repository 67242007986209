import { Grid, Paper, Chip, Button } from "@mui/material";
import Badge from "@mui/material/Badge";
import React from "react";
import { ROOMS } from "../../../queries";
import { useQuery } from "@apollo/client";
import SeeNotes from "./components/seeNotes";

const Rooms = () => {
  const { data, loading, error, refetch } = useQuery(ROOMS);
  console.log(data);

  if (error) {
    return (
      <div>
        <h1>Error.</h1>
      </div>
    );
  }

  return (
    <div
      style={{
        paddingTop: 55,
      }}
    >
      <Grid container spacing={2}>
        {data &&
          data.getRooms.map((room) => (
            <RoomComp room={room} refetch={refetch} key={room.id} />
          ))}
      </Grid>
    </div>
  );
};

export default Rooms;

const RoomComp = ({ room, refetch }) => {
  console.log("room", room);
  return (
    <Grid item lg={3} xs={12} md={4} sm={6}>
      <Paper
        sx={{
          display: "flex",
          padding: "16px 16px",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontWeight: "bolder",
            }}
          >
            {room.name}
          </p>
          <Chip
            color={room.status ? "success" : "error"}
            label={room.status ? "Available" : "Under Maintenance"}
            size="md"
            variant="outlined"
          ></Chip>
        </div>

        <SeeNotes room={room} refetch={refetch} />
      </Paper>
    </Grid>
  );
};
