import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_FLAG,
  UPDATE_FLAG,
  GET_COURSES_CUSTOMER,
} from "../../../../queries";
import { LinearProgress } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const KEY = "feedreal-discount-reward";

export default function FeedrealDiscountReward() {
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState({});
  const [courses, setCourses] = useState([]);
  const [percentage, setPercentage] = useState("");
  const [everyone, setEveryone] = useState(false);
  const [refPercentage, setRefPercentage] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [getFlag, { loading, refetch }] = useLazyQuery(GET_FLAG, {
    onCompleted: (data) => {
      setFlag(data.getFlag);
      setPercentage(data.getFlag.data.percentage);
      setEveryone(data.getFlag.data.everyone);
      setRefPercentage(data.getFlag.data.ref_percentage);
    },
    fetchPolicy: "no-cache",
  });
  const [getCourses, { loading: coursesLoading }] = useLazyQuery(
    GET_COURSES_CUSTOMER,
    {
      onCompleted: (data) => {
        setCourses(data.getCoursesForCustomer);
      },
      fetchPolicy: "no-cache",
    }
  );
  console.log("courses", courses);
  const [updateFlagMutation, { loading: loadingUpdate }] =
    useMutation(UPDATE_FLAG);

  useEffect(() => {
    getFlag({
      variables: {
        key: KEY,
      },
    });
    getCourses();
  }, [getFlag, open]);

  const onChangeStatus = () => {
    const copy = copyFlag();
    copy.status = !copy.status;
    setFlag(copy);
  };

  const copyFlag = () => JSON.parse(JSON.stringify(flag));

  const update = () => {
    updateFlagMutation({
      variables: {
        id: flag.id,
        input: {
          key: KEY,
          status: flag.status,
          data: {
            percentage: percentage,
            everyone: everyone,
          },
        },
      },
    })
      .then((res) => {
        handleClose();
      })
      .catch((error) => {});
  };

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <Grid item xs>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        style={{
          borderLeft: "5px solid",
          borderLeftColor: flag.status ? "#63b475" : "#dc5a68",
        }}
        className="btn-reward"
      >
        Feedreal Discount Reward
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Feedreal Discount Reward"}
        </DialogTitle>
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                control={
                  <Switch
                    checked={flag.status}
                    color="secondary"
                    onClick={() => {
                      onChangeStatus();
                    }}
                  />
                }
                label="Reward"
              />
              <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Referred</Typography>
                <Switch
                  checked={everyone}
                  color="secondary"
                  onClick={() => {
                    setEveryone(!everyone);
                  }}
                />
                <Typography>Everyone</Typography>
              </Stack>
              <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />
              <TextField
                fullWidth
                type="number"
                id="standard-basic"
                label="Percentage"
                variant="standard"
                value={percentage}
                onChange={(e) => {
                  setPercentage(e.target.value);
                }}
              />
            </DialogContentText>
          </DialogContent>
        )}
        {coursesLoading ? (
          <LinearProgress />
        ) : (
          <List
            sx={{
              width: "100%",
              margin: "8px",
              padding: "8px",
              border: "1px solid #787878",
              width: "auto",
              borderRadius: "5px",
              bgcolor: "background.paper",
            }}
          >
            <ListItem alignItems="flex-start" id="title">
              <Typography>Published Courses</Typography>
            </ListItem>
            {courses &&
              courses.map((course, index) => {
                return (
                  <>
                    <ListItem alignItems="flex-start" id={index}>
                      <ListItemAvatar>
                        <Avatar src={course.featured_img} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={course.title}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {flag.status ? (
                                <>
                                  <del>${course.price}</del> $
                                  {(course.price *
                                    (100 - parseFloat(percentage))) /
                                    100}
                                </>
                              ) : (
                                `$${course.price}`
                              )}
                            </Typography>{" "}
                            - {course.courseDetail}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    {courses.length - 1 !== index && (
                      <Divider variant="inset" component="li" />
                    )}
                  </>
                );
              })}
          </List>
        )}
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>cancel</Button>
            <Button onClick={update} autoFocus>
              Update
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Grid>
  );
}
