import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Container, Grid, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MULTIPLE_CHOICE, QUESTION_ANSWER, FINAL_EXAM } from "../../../globals";
import { CREATE_QUIZ, GET_SINGLE_QUIZ, UPDATE_QUIZ } from "../../../queries";
import QuizCategories from "./components/QuizCategories";
import QuizQuestions from "./components/QuizQuestions";
import QuizSelectQuestion from "./components/QuizSelectQuestion";
import QuizStatus from "./components/QuizStatus";
import QuizTags from "./components/QuizTags";
import QuizTitle from "./components/QuizTitle";

const QUIZ_TITLE_MIN_LENGTH = 1;
const QUESTION_OPTIONS_MIN = 2;

const Quiz = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id, quizType: quizTypeParam } = useParams();

  const [quizType, setQuizType] = useState("");

  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(true);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [getSingleQuiz, { loading: loadingGetSingleQuiz, refetch }] =
    useLazyQuery(GET_SINGLE_QUIZ, {
      variables: {
        input: {
          id,
        },
      },
      fetchPolicy: "no-cache",
      onCompleted: (response) => {
        console.log(response);
        const { title, status, tags, categories, questions, quizType } =
          response.getSingleQuiz;
        setTitle(title);
        setStatus(status);
        setTags(tags);
        setCategories(categories);
        setQuestions(questions);
        setQuizType(quizType);
      },
    });

  const [updateQuizMutation, { loading: loadingUpdateQuiz }] = useMutation(
    UPDATE_QUIZ,
    {
      onCompleted: (response) => {
        // refetch();
        enqueueSnackbar("Quiz Updated", {
          variant: "success",
        });
        history.push("/dashboard/course/questions/quiz");
      },
    }
  );
  const [createQuizMutation, { loading: loadingCreateQuiz }] = useMutation(
    CREATE_QUIZ,
    {
      onCompleted: (response) => {
        enqueueSnackbar("Quiz Created", {
          variant: "success",
        });
        history.push("/dashboard/course/questions/quiz");
      },
    }
  );
  useEffect(() => {
    if (!id) {
      if (quizTypeParam === QUESTION_ANSWER) {
        setQuizType(QUESTION_ANSWER);
      }
      if (quizTypeParam === MULTIPLE_CHOICE) {
        setQuizType(MULTIPLE_CHOICE);
      }
      if (quizTypeParam === FINAL_EXAM) {
        setQuizType(FINAL_EXAM);
      }

      newInitialState();
    } else {
      existingInitialState();
    }
  }, [id, quizTypeParam]);

  useEffect(() => {
    // console.log(title, status, tags, categories, questions);
  }, [title, status, tags, categories, questions]);

  const newInitialState = () => {
    setTitle("");
    setStatus(true);
    setTags([]);
    setCategories([]);
    setQuestions([]);
  };
  const existingInitialState = () => {
    getSingleQuiz();
  };

  const onUpdateQuiz = () => {
    const input = createInput();
    const valid = isValid();
    if (!valid) {
      return;
    }
    updateQuizMutation({
      variables: {
        id,
        input,
      },
    });
  };

  const onCreateQuiz = () => {
    const input = createInput();
    const valid = isValid();
    if (!valid) {
      return;
    }
    createQuizMutation({
      variables: {
        input,
      },
    });
  };

  const createInput = () => {
    const input = {
      title,
      status,
      tags,
      categories,
      questions: questions.map((item) => item.id),
      quizType,
    };
    return input;
  };

  const isValid = () => {
    if (title.length < QUIZ_TITLE_MIN_LENGTH) {
      enqueueSnackbar("Quiz title is not valid (min lenght 10)", {
        variant: "error",
      });
      return false;
    }
    const questionsValid = questions
      .map((item) => item.question || "")
      .every((item) => item.length > QUIZ_TITLE_MIN_LENGTH);
    if (!questionsValid) {
      enqueueSnackbar(`Quiz questions (min lenght ${QUIZ_TITLE_MIN_LENGTH})`, {
        variant: "error",
      });
      return false;
    }
    const optionsValid = questions
      .filter((item) => item.questionType === MULTIPLE_CHOICE)
      .map((item) => item.options)
      .every((item) => item.length >= QUESTION_OPTIONS_MIN);

    if (!optionsValid) {
      enqueueSnackbar("Options Error (min 2 options required)", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const onAddQuestion = (question) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const existingObj = questions.find((item) => item.id === question.id);
    if (existingObj) {
      enqueueSnackbar("This obj already exist in question list", {
        variant: "info",
      });
      return;
    }
    copy.push(question);
    setQuestions(copy);
  };
  const onDeleteQuestion = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    copy.splice(index, 1);
    setQuestions(copy);
  };

  const onMoveDown = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const temp = copy[index + 1];
    copy[index + 1] = copy[index];
    copy[index] = temp;
    setQuestions(copy);
    enqueueSnackbar("Question moved down", {
      variant: "success",
    });
  };
  const onMoveUp = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const temp = copy[index - 1];
    copy[index - 1] = copy[index];
    copy[index] = temp;
    setQuestions(copy);
    enqueueSnackbar("Question moved up", {
      variant: "success",
    });
  };

  if (
    loadingGetSingleQuiz ||
    loadingUpdateQuiz ||
    loadingCreateQuiz ||
    quizType === ""
  ) {
    return <LinearProgress></LinearProgress>;
  }
  return (
    <div>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={9}>
            <QuizTitle title={title} setTitle={setTitle} />
            <br />
            <QuizQuestions
              questions={questions}
              deleteQ={(index) => {
                onDeleteQuestion(index);
              }}
              onMoveDown={(index) => {
                onMoveDown(index);
              }}
              onMoveUp={(index) => {
                onMoveUp(index);
              }}
            />
            <QuizSelectQuestion
              onConfirmQuestion={(question) => onAddQuestion(question)}
              quizType={quizType}
            />
          </Grid>
          <Grid item xs={3}>
            <div style={{ position: "fixed", maxWidth: 250 }}>
              <QuizStatus status={status} setStatus={setStatus} />
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    if (id) {
                      onUpdateQuiz();
                    } else {
                      onCreateQuiz();
                    }
                  }}
                >
                  {id ? "Update Quiz" : "CREATE QUIZ"}
                </Button>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Quiz;
