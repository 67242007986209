import { useQuery } from "@apollo/client";
import Edit from "@mui/icons-material/Edit";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET_PAGES, GET_GIFT_COUPONS } from "../../../queries";
import GenerateButton from "./Components/generateButton";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import RedeemIcon from "@mui/icons-material/Redeem";

function GiftCodeGeneratorPrinted(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // query get data
  const { subscribeToMore, loading, error, data, refetch, fetchMore } =
    useQuery(GET_GIFT_COUPONS, {
      variables: {
        limit: rowsPerPage,
        offset: 0,
        type: "printed",
      },
      fetchPolicy: "no-cache",
    });
  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.getGiftCoupons.length,
        limit: rowsPerPage,
        type: "printed",
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;
  const { permission } = props.session.activeAdmin;
  console.log("data", data);

  return (
    <div>
      {data.getGiftCoupons.length > 0 ? (
        <PageUI
          {...data}
          permission={permission}
          loading={loading}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refetch={refetch}
          history={props.history}
        />
      ) : (
        ""
      )}
    </div>
  );
}
const PageUI = (props) => {
  const [god, setGod] = useState(false);
  const [secretshop, setSecretshop] = useState(false);
  const getUserEmail = (user) => {
    if (!user) {
      return "";
    }
    return user.email;
  };
  useEffect(() => {
    setGod(props.permission.god ? props.permission.god.admin : false);
    setSecretshop(
      props.permission.secretshop ? props.permission.secretshop : false
    );
  }, [props.permission]);

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <a color="inherit" href="/gift_code_generate">
              Gift Codes
            </a>
            <Typography color="textPrimary">Printed</Typography>
          </Breadcrumbs>
        </div>
        {god || secretshop.admin || secretshop.write ? (
          <div className="right" style={{ marginLeft: "10px" }}>
            <GenerateButton refetch={props.refetch} />
          </div>
        ) : (
          ""
        )}
        {god || secretshop.admin || secretshop.write ? (
          <div className="right">
            <Button
              component={Link}
              variant="outlined"
              color="primary"
              startIcon={<FormatListBulletedIcon />}
              to={"/dashboard/gift_code_generate"}
              sx={{ marginLeft: "10px" }}
            >
              Gift Codes
            </Button>
          </div>
        ) : (
          ""
        )}
        {god || secretshop.admin || secretshop.write ? (
          <div className="right">
            <Button
              component={Link}
              variant="outlined"
              color="primary"
              startIcon={<RedeemIcon />}
              to={"/dashboard/gift_code_generate/redeemed"}
            >
              Redeemed
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ul>
            {
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Coupon</TableCell>
                      <TableCell>Recipient Email</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Expiry Date</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Printed By</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.getGiftCoupons && props.getGiftCoupons.length > 0 ? (
                      props.getGiftCoupons
                        .slice(
                          props.page * props.rowsPerPage,
                          props.page * props.rowsPerPage + props.rowsPerPage
                        )
                        .map((data) => {
                          return (
                            <TableRow key={data.id}>
                              <TableCell component="th" scope="row">
                                {`${data.coupon.slice(
                                  0,
                                  4
                                )}-${data.coupon.slice(
                                  4,
                                  8
                                )}-${data.coupon.slice(
                                  8,
                                  12
                                )}-${data.coupon.slice(12, 16)}`}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {data.recipient_email
                                  ? data.recipient_email
                                  : ""}
                              </TableCell>

                              <TableCell component="th" scope="row">
                                ${data.amount}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {data.expiryDate
                                  ? moment
                                      .unix(data.expiryDate)
                                      .format("MM/DD/YYYY")
                                  : "Unlimited"}
                              </TableCell>

                              <TableCell component="th" scope="row">
                                {data.admin
                                  ? getUserEmail(data.admin)
                                  : getUserEmail(data.customer)}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {data.printedAdmin?.email}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </ul>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.giftCouponCount ? props.giftCouponCount : 0}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default GiftCodeGeneratorPrinted;
