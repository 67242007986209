import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinkIcon from "@mui/icons-material/Link";
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import {
  GET_COMPLETED_COURSE_IDS,
  GET_COMPLETED_COURSE_LENGTH,
  GET_COPLETED_COURSE_BY_ID,
  SEARCH_COMPLETED_COURSE_IDS,
} from "../../../queries/Course";

import { GET_COURSES } from "../../../queries";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TablePagination from "@mui/material/TablePagination";
import LinearLoading from "../LinearLoading";
import CheckWorkshopDone from "./CheckWorkshopDone";
import BadgeDialog from "./BadgeDialog";
import { UpdateAttendanceContainer } from "../WorkshopsList/UpdateAttendance";
import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";

const CourseCompleted = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(0);
  const [lenght, setLength] = useState(10);
  const [courseId, setCourseId] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { loading, data, error, refetch } = useQuery(GET_COMPLETED_COURSE_IDS, {
    variables: {
      offset: page,
      limit: rowsPerPage,
      order: sortOrder,
      courseId: courseId,
    },
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      setCompleted(res.getCompletedCoursesIDs);
    },
  });

  const { loading: loadingLength } = useQuery(GET_COMPLETED_COURSE_LENGTH, {
    onCompleted: (res) => {
      setLength(res.getCompletedCoursesLength);
    },
  });

  const [completed, setCompleted] = useState([]);
  const [filterArr, setFilterArr] = useState([]);

  const sortIncreaseDate = () => {
    const copy = JSON.parse(JSON.stringify(completed));
    copy.sort((a, b) => {
      return a.completedCourse.completedAt - b.completedCourse.completedAt;
    });
    setCompleted(copy);
  };
  const sortDecreaseDate = () => {
    const copy = JSON.parse(JSON.stringify(completed));
    copy.sort((a, b) => {
      return b.completedCourse.completedAt - a.completedCourse.completedAt;
    });
    setCompleted(copy);
  };

  const [searchMutation, { loadin: loadingSearchMutatin }] = useLazyQuery(
    SEARCH_COMPLETED_COURSE_IDS
  );

  useEffect(() => {
    if (search.length > 3) {
      searchMutation({
        variables: {
          search,
        },
      })
        .then((res) => {
          console.log(res);
          setCompleted(res.data.searchCompletedCoursesIDs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [search]);

  if (loading) {
    return (
      <div>
        <LinearLoading />
      </div>
    );
  }
  if (error) {
    return <div>Error </div>;
  }

  return (
    <div>
      <TextField
        style={{ minWidth: 300 }}
        variant="outlined"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          endAdornment: <Search />,
        }}
        placeholder="search by customer email"
        size="small"
      />{" "}
      <Button
        onClick={() => {
          setSearch("");
          refetch()
            .then((res) => {
              console.log(res);
              setCompleted(res.data.getCompletedCoursesIDs);
            })
            .catch(() => {});
        }}
      >
        Clean Search
      </Button>
      <br></br>
      <br></br>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ maxWidth: 300 }}>
                Customer
              </TableCell>
              <TableCell align="left">
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  Course <FilterDialog setCourseId={setCourseId} />
                </div>
              </TableCell>
              <TableCell>
                Complete Date <br></br>
                <IconButton
                  aria-label="fingerprint"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setSortOrder("asc");
                  }}
                >
                  <ArrowDropUpIcon />
                </IconButton>{" "}
                <IconButton
                  aria-label="fingerprint"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setSortOrder("desc");
                  }}
                >
                  <ArrowDropDownIcon />
                </IconButton>{" "}
              </TableCell>
              {/* <TableCell align="left">Workshop</TableCell> */}
              <TableCell align="left">Code</TableCell>
              <TableCell align="left">Certificate</TableCell>
              <TableCell align="left">Badge</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {completed.map((completedCourseId, index) => {
              return (
                <SingleCompletedCourseItem
                  completedCourseId={completedCourseId}
                  index={index}
                />
              );
            })}
            <TableRow>
              <TablePagination
                count={lenght}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CourseCompleted;

const FilterDialog = ({ courseId, setCourseId }) => {
  const [open, setOpen] = React.useState(false);
  const { data, error, loading } = useQuery(GET_COURSES);
  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <div></div>;
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        aria-label="fingerprint"
        color="secondary"
        size="small"
        onClick={() => {
          handleClickOpen();
        }}
        style={{
          marginTop: -7,
        }}
      >
        <FilterAltIcon />
      </IconButton>{" "}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent>
          {data.getCourses.map((course) => {
            return (
              <FormControlLabel
                key={course.id}
                control={<Checkbox />}
                onClick={() => {
                  handleClose();
                  setCourseId(course.id);
                }}
                label={course.title}
              />
            );
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
};

const Filter = ({ filterArr, onClickFilter }) => {
  const { data, error, loading } = useQuery(GET_COURSES);
  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <div></div>;
  }
  return (
    <div>
      <FormGroup>
        {data.getCourses.map((course) => {
          return (
            <FormControlLabel
              key={course.id}
              control={<Checkbox checked={filterArr.includes(course.id)} />}
              onClick={() => {
                onClickFilter(course.id);
              }}
              label={course.title}
            />
          );
        })}
      </FormGroup>
    </div>
  );
};

const SingleCompletedCourseItem = ({ completedCourseId, index }) => {
  const [loadingUp, setLoadingUp] = useState(false);
  const { data, loading, error, refetch } = useQuery(
    GET_COPLETED_COURSE_BY_ID,
    {
      variables: {
        id: completedCourseId,
      },
      fetchPolicy: "no-cache",
    }
  );
  if (loading) {
    return (
      <TableRow>
        <LinearProgress />
      </TableRow>
    );
  }
  if (error) {
    return <Typography>Something went wrong</Typography>;
  }
  const completedCourse = data.getCompletedCoursesByID;
  return (
    <React.Fragment>
      {false ? (
        <LinearProgress />
      ) : (
        <TableRow key={completedCourse.id + "" + index}>
          <TableCell component="th" scope="row" style={{ maxWidth: 300 }}>
            {completedCourse.customer.name}
            <br></br>
            <Link
              to={`/dashboard/customer/${completedCourse.customer.id}`}
              style={{ color: "white" }}
            >
              {completedCourse.customer.email}
            </Link>
          </TableCell>

          <TableCell component="th" scope="row">
            {completedCourse.course.title}
          </TableCell>
          <TableCell component="th" align="left" scope="row">
            {moment(
              new Date(completedCourse.completedCourse.completedAt * 1000)
            ).format("MM/DD/YYYY")}
          </TableCell>
          {/* <TableCell>
            <UpdateAttendanceContainer customer={completedCourse.customer} />
          </TableCell> */}
          <TableCell component="th" align="left" scope="row">
            {completedCourse.completedCourse.completeCode}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  completedCourse.completedCourse.completeCode
                );
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </TableCell>

          <TableCell>
            {completedCourse.completedCourse.certificatePdfUri !== "" ? (
              <Button
                startIcon={<LinkIcon />}
                onClick={() => {
                  navigator.clipboard.writeText(
                    completedCourse.completedCourse.certificatePdfUri
                  );
                }}
              >
                Copy Pdf link
              </Button>
            ) : (
              <CheckWorkshopDone
                loadingUp={loadingUp}
                setLoadingUp={setLoadingUp}
                completedCourse={completedCourse}
                course={completedCourse.course}
                customer={completedCourse.customer}
                refetch={refetch}
              />
              // <Button
              //   onClick={() => {
              //     onCreateCertificate(
              //       completedCourse.completedCourse.completeCode.replace(
              //         /\D/g,
              //         ""
              //       )
              //     );
              //   }}
              // >
              //   Create Certificate
              // </Button>
            )}
          </TableCell>
          <TableCell align="left">
            <BadgeDialog completedCourse={completedCourse} refetch={refetch} />
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
