import React, { Component, useEffect, useState } from "react";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import {
  GET_STOCK_UNS,
  ADD_STOCK_UN,
  GET_SUPPLIERS,
  GET_INVENTORY_PRODUCTS,
  GET_INV_PRODUCT,
  GET_ACTIVE_ADMIN,
  GET_STOCK_UN,
  UPDATE_STOCK_UN_MANUAL,
} from "../../../queries";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";

function UnProcessing(props) {
  const initialState = {
    quantity: "",
    supplier: "",
    projected_units: "",
    order_number: "",
    comments: "",
    brand: "",
    price: "",
  };

  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [form, setState] = useState(initialState);
  const [updateProduct, setUpdateProduct] = useState("");
  const [currentProductCategory, setCurrentProductCategory] = useState("");
  const [currentProduct, setCurrentProduct] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [stockNumber, setStockNumber] = useState("");
  const [me, setMe] = useState(props.session.activeAdmin.id);
  const [secretshop, setSecretshop] = useState(false);
  const [selectedStockUn, setSelectedStockUn] = useState("");
  const [selectedStockLeft, setSelectedStockLeft] = useState("");
  const [selectedStockLeftNew, setSelectedStockLeftNew] = useState("");
  const [selectedStockName, setSelectedStockName] = useState("");
  const [selectedComment, setSelectedComment] = useState("");

  const {
    quantity,
    brand,
    price,
    admin_id,
    projected_units,
    status,
    supplier,
    order_number,
    comments,
  } = form;

  const { subscribeToMore, loading, error, data, refetch } =
    useQuery(GET_STOCK_UNS);

  const { loading: loadingAdmin, data: dataAdmin } = useQuery(GET_ACTIVE_ADMIN);

  const {
    loading: loadingSup,
    error: errorSup,
    data: dataSup,
  } = useQuery(GET_SUPPLIERS);
  const {
    loading: loadingProd,
    error: errorProd,
    data: dataProd,
  } = useQuery(GET_INVENTORY_PRODUCTS);

  const [createStockUn, { loading: loadingAddStock }] =
    useMutation(ADD_STOCK_UN);

  const [updateStockUnManual, { loading: loadingStockUn }] = useMutation(
    UPDATE_STOCK_UN_MANUAL
  );

  const [getProduct, { loading: loadingGetProd, data: dataGetProd }] =
    useLazyQuery(GET_INV_PRODUCT, {
      variables: {
        id: updateProduct,
      },
      onCompleted: (data) => {
        ////.map("data", true);
        setCurrentProduct(data.inv_product.name);
        setCurrentProductCategory(data.inv_product.category);
      },
    });

  const [getStockUn, { loading: loadingGetStockUn, data: dataGetStockUn }] =
    useLazyQuery(GET_STOCK_UN, {
      fetchPolicy: "no-cache",
      variables: {
        id: selectedStockUn,
      },
      onCompleted: (data) => {
        setSelectedStockLeft(data.stock_un.left);
        setSelectedStockLeftNew(data.stock_un.left);
        setSelectedStockName(data.stock_un.product.name);
        handleClickUpdate();
        //console.log('datatest', data);
      },
    });

  if (loading || loadingSup || loadingProd)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  const handleClickOpen = () => {
    setOpen(true);
    generateStockNumber();
  };

  const handleClickUpdate = () => {
    setOpenUpdate(true);
  };

  const handleProduct = (e) => {
    console.log("value", e);

    setUpdateProduct(e);
    getProduct();

    //console.log(updateProduct);
  };

  const handleSecretshop = (e) => {
    //console.log(e.target.checked);
    setSecretshop(e.target.checked);
  };

  //console.log(data);
  const generateStockNumber = () => {
    // today
    let today = moment().tz("America/Los_Angeles").format("YYYY-MM-DD");
    today = moment(today).format("X");
    let todayDataset = data.stock_uns.filter((item) => item.rec_date >= today);
    //console.log(todayDataset);
    ////console.log(data.inv_processs);
    //console.log(today);
    //console.log("now", moment().unix());

    if (todayDataset.length > 0) {
      //console.log(true);
      // const lastBatch = todayDataset[0];
      // let degisken2 = lastBatch.stock_number.split("-");
      // const newNum = parseInt(degisken2[2]) + 1;
      // const degisken = `S-${degisken2[1]}-${newNum > 9 ? newNum : `0${newNum}`}`;

      //console.log(true);
      const lastBatch = todayDataset[0];
      let degisken2 = lastBatch.stock_number.split("-");

      const year = moment().tz("America/Los_Angeles").format("YY");
      const month = moment().tz("America/Los_Angeles").format("MM");
      const day = moment().tz("America/Los_Angeles").format("DD");
      const batchNo = year + month + day + "-01";

      const newNum =
        parseInt(degisken2[1].substring(4, 6)) < parseInt(day)
          ? 1
          : parseInt(degisken2[2]) + 1;

      const degisken = `S-${year}${month}${day}-${
        newNum > 9 ? newNum : `0${newNum}`
      }`;

      //console.log("lastbatch", degisken);
      setStockNumber(degisken);
    } else {
      // generate batch no today
      const year = moment().format("YY");
      const month = moment().format("MM");
      const day = moment().format("DD");
      const batchNo = "S-" + year + month + day + "-01";
      // last batch find

      ////console.log(data.inv_processs);
      setStockNumber(batchNo);
    }
  };

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
    console.log(form);
  };

  const updateLeft = (e) => {
    setSelectedStockLeftNew(e.target.value);
  };

  const updateComment = (e) => {
    setSelectedComment(e.target.value);
  };

  const formValidate = () => {
    if (currentProductCategory == "super-chew") {
      return (
        !quantity ||
        !brand ||
        !price ||
        !supplier ||
        !order_number ||
        !updateProduct ||
        !projected_units
      );
    }
    return (
      !quantity ||
      !brand ||
      !price ||
      !supplier ||
      !order_number ||
      !updateProduct
    );
  };

  const handleClose = () => {
    setOpen(false);
    setOpenUpdate(false);
    setOpenConfirm(false);
    resetState();
  };

  const handleUpdateStock = (id) => {
    setSelectedStockUn(id);
    getStockUn();
    console.log("stock id", id);
  };

  const handleClickOpenConfirm = () => {
    setOpen(false);
    setOpenConfirm(true);
    getProduct();
  };

  const resetState = () => {
    setState({ ...initialState });
    setSecretshop(false);
    setSelectedComment();
    setSelectedStockUn();
    setSelectedStockLeft();
    setSelectedStockLeftNew();
    setSelectedStockName();
  };

  const addStockFunc = () => {
    createStockUn({
      variables: {
        product: updateProduct,
        stock_number: stockNumber,
        quantity,
        rec_date: moment().unix(),
        admin_id: me,
        left: quantity,
        status: 1,
        supplier,
        brand,
        price,
        order_number,
        comments,
        secretshop,
      },
    }).then(async ({ data }) => {
      resetState();
      handleClose();
      refetch();
      //console.log(data);
    });
  };

  const updateStockFunc = () => {
    console.log("id", selectedStockUn);
    console.log("left", selectedStockLeftNew);
    updateStockUnManual({
      variables: {
        id: selectedStockUn,
        left: selectedStockLeftNew,
        oldleft: selectedStockLeft,
        product_name: selectedStockName,
        rec_date: 0,
        admin_id: me,
        comment: selectedComment,
      },
    }).then(async ({ data }) => {
      resetState();
      handleClose();
      refetch();
      console.log(data);
    });
  };

  return (
    <div>
      <Dialog
        open={openUpdate}
        onClose={handleClickUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          Update Stock - {selectedStockName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dataAdmin.activeAdmin.name}!, Are you sure you update this stock?
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                name="left"
                label="Left"
                type="number"
                value={selectedStockLeftNew}
                onChange={updateLeft}
                width="50%"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                multiline
                rows={4}
                margin="normal"
                value={selectedComment}
                name="comments"
                label="Was there anything special or different about this update?"
                type="text"
                fullWidth
                onChange={updateComment}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={updateStockFunc} color="secondary" autoFocus>
            Update Stock
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={handleClickOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dataAdmin.activeAdmin.name}!, Are you sure you want to add{" "}
            {form.quantity} /lbs {currentProduct} to stock?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpen} color="primary">
            Back
          </Button>
          <Button onClick={addStockFunc} color="secondary" autoFocus>
            Approved
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <div>
          <DialogTitle id="form-dialog-title">
            Add UnProcessing Stock
          </DialogTitle>
          <DialogContent>
            <FormControl required variant="outlined" className="width100">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {/* <Autocomplete
                    id="combo-box-demo"
                    fullWidth
                    className="width100"
                    options={dataProd.inv_products}
                    getOptionLabel={option => option.name}

                    onChange={(e, value) => {
                      let newValue = value || "";
                      return handleProduct(newValue.id);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Product"
                        variant="outlined"
                      />
                    )}
                  /> */}
                  <InventoryAutoComplete
                    dataProd={dataProd.inv_products}
                    handleProduct={handleProduct}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    className="secretshopbox"
                    control={
                      <Checkbox
                        checked={secretshop}
                        onChange={handleSecretshop}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Secretshop Product"
                  />
                </Grid>
                <Grid item xs={12}>
                  <p className="whitelink">
                    If there is no product in the list, you can add a product{" "}
                    <a href="/dashboard/inventorysettings">here.</a>
                  </p>
                </Grid>
              </Grid>
            </FormControl>
            <br />
            <br />
            <FormControl required variant="outlined" className="width100">
              <InputLabel id="category">Select Supplier</InputLabel>
              <Select
                value={form.supplier || ""}
                onChange={updateField}
                label="category"
                name="supplier"
              >
                {dataSup.inv_suppliers &&
                  dataSup.inv_suppliers.map((suppliers) => {
                    return (
                      <MenuItem key={suppliers.id} value={suppliers.id}>
                        {suppliers.company}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="brand"
                  label="Brand"
                  type="text"
                  value={form.brand}
                  onChange={updateField}
                  width="50%"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="price"
                  label="Price"
                  value={form.price}
                  onChange={updateField}
                  type="number"
                  width="sm"
                  fullWidth
                />
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              name="quantity"
              label="Qty Received (in lbs):"
              required={true}
              type="number"
              fullWidth
              onChange={updateField}
            />
            {currentProductCategory === "super-chew" ? (
              <TextField
                variant="outlined"
                margin="normal"
                name="projected_units"
                label="Projected Units"
                required={true}
                type="number"
                fullWidth
                onChange={updateField}
              />
            ) : (
              ""
            )}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  name="batchNumber"
                  label="Stock number"
                  type="text"
                  value={stockNumber}
                  //onChange={handleChange}
                  width="50%"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="order_number"
                  label="Order Number"
                  value={form.order_number}
                  onChange={updateField}
                  type="text"
                  width="sm"
                  fullWidth
                />
              </Grid>
            </Grid>

            <TextField
              variant="outlined"
              multiline
              rows={4}
              margin="normal"
              value={form.comments}
              name="comments"
              label="Was there anything special or different about this shipment?"
              type="text"
              fullWidth
              onChange={updateField}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              onClick={handleClickOpenConfirm}
              color="secondary"
              disabled={formValidate()}
            >
              Add Stock
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <UnProcessingUI
        {...data}
        refetch={refetch}
        open={handleClickOpen}
        updateStock={handleUpdateStock}
      />
    </div>
  );
}

export class UnProcessingUI extends Component {
  componentDidMount() {
    this.props.refetch();
    // console.log(this.props);
  }
  render() {
    return (
      <div>
        <div className="pagetop">
          <div className="left">
            <Breadcrumbs aria-label="breadcrumb">
              <a color="inherit" href="/">
                Dashboard
              </a>
              <Typography color="textPrimary">Raw Stock</Typography>
            </Breadcrumbs>
          </div>
          <div className="right">
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.props.open}
            >
              Add Stock
            </Button>
          </div>
        </div>
        <ul>
          {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell align="right">Stock Number</TableCell>
                    <TableCell align="right">Date</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Secretshop</TableCell>
                    <TableCell align="right">Left</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.stock_uns.map((stock) => (
                    <TableRow key={stock.id}>
                      <TableCell component="th" scope="row">
                        {stock.product.name}
                      </TableCell>

                      <TableCell align="right" component="th" scope="row">
                        {stock.stock_number}
                      </TableCell>

                      <TableCell align="right" component="th" scope="row">
                        {moment
                          .unix(stock.rec_date)
                          .format("DD/MM/YYYY HH:mm:ss")}
                      </TableCell>

                      <TableCell align="right" component="th" scope="row">
                        {stock.quantity}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {stock.secretshop ? (
                          <CheckBoxIcon className="green" />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {stock.left}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        <Button
                          onClick={() => this.props.updateStock(stock.id)}
                        >
                          <Edit />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </ul>
      </div>
    );
  }
}

export default UnProcessing;

export const InventoryAutoComplete = ({ dataProd, handleProduct }) => {
  return (
    <Autocomplete
      id="combo-box-demo"
      fullWidth
      className="width100"
      options={dataProd}
      getOptionLabel={(option) => option.name}
      onChange={(e, value) => {
        let newValue = value || "";
        return handleProduct(newValue.id);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Select Product" variant="outlined" />
      )}
    />
  );
};
