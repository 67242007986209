import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip, Grid, IconButton, Paper, Typography } from "@mui/material";
import {
  CUSTOMER_ADDRESSES,
  FETCH_CUSTOMER_COURSES,
  GETDOGS_SUMMARY,
  GET_COURSES,
  SINGLE_ADDRESS,
} from "../../../queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import LinearLoading from "../LinearLoading";
import PetsIcon from "@mui/icons-material/Pets";
import moment from "moment";
export default function CustomerSum({ customer }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <VisibilityIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          <b>Full Name: </b> {customer.name}
          <br></br>
          <b>User Name: </b> {customer.username}
          <br></br>
          <b>Created at: </b>
          {moment(parseInt(customer.created_at) * 1000).format("MM/DD/YYYY")}
          {customer.ref && (
            <>
              <br></br>
              <b>Referrer: </b>
              {customer.ref.username}
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {customer.premium === 1 && (
              <Chip color="success" label="RD Membership Active" size="small" />
            )}
            <Typography>{customer.email}</Typography>
            <Typography>{customer.phone_number}</Typography>
            <DogsList open={open} customerId={customer.id} />
            <AddressList open={open} customerId={customer.id} />
            <CourseSummary open={open} customerId={customer.id} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const DogsList = ({ open, customerId }) => {
  const [dogs, setDogs] = React.useState([]);
  const [getDogs, { loading: loadingGetDogs, data: dataGetDogs }] =
    useLazyQuery(GETDOGS_SUMMARY, {
      fetchPolicy: "no-cache",
      variables: {
        owner: customerId,
      },
      onCompleted: (res) => {
        console.log(res.getMongoDogsByOwnerID);
        setDogs(res.getMongoDogsByOwnerID);
      },
    });
  React.useEffect(() => {
    if (open) {
      getDogs();
    }
  }, [open]);
  if (loadingGetDogs) {
    return <LinearLoading />;
  }
  return (
    <div>
      <h4>Dogs List </h4>
      {dogs.length === 0 && <div>There is no dog </div>}
      {dogs.map((singleDog) => {
        return (
          <Paper elevation={3} style={{ padding: 10 }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={1}>
                <PetsIcon />
              </Grid>
              <Grid item xs={11}>
                {singleDog.name}
                {singleDog.subscriptions &&
                  Object.keys(singleDog.subscriptions).length > 0 && (
                    <div>
                      {singleDog.subscriptions.status ? (
                        <Chip color="success" label="Active" size="small" />
                      ) : (
                        <Chip color="error" label="Inactive" size="small" />
                      )}
                      {singleDog.subscriptions.items.map((prod) => {
                        return (
                          <div>
                            {prod.name} {prod.quantity}
                            {"x"}
                          </div>
                        );
                      })}
                    </div>
                  )}
                <Shipping id={singleDog.shippingId} />
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </div>
  );
};
const Shipping = ({ id }) => {
  const { loading, error, data } = useQuery(SINGLE_ADDRESS, {
    variables: {
      addressId: id,
    },
  });
  if (loading) {
    return <LinearLoading />;
  }
  if (error) {
    return <div> error </div>;
  }
  const address = data.ownerSingleAddress;
  const addressTxt = `${address.name} / ${address.address1} / ${address.address2} / ${address.city} / ${address.state} / ${address.postal_code}`;
  return <div> {addressTxt} </div>;
};
const AddressList = ({ open, customerId }) => {
  const [addressList, setAddressList] = React.useState([]);
  const [fetchAddresses, { loading, error }] = useLazyQuery(
    CUSTOMER_ADDRESSES,
    {
      variables: {
        owner: customerId,
      },
      onCompleted: (res) => {
        console.log(res);
        setAddressList(res.ownerAddress);
      },
      fetchPolicy: "no-cache",
    }
  );
  React.useEffect(() => {
    if (open) {
      fetchAddresses();
    }
  }, [open]);
  if (loading) {
    return <LinearLoading />;
  }
  if (error) {
    return <div> error </div>;
  }
  return (
    <div>
      <h4>Address List </h4>
      {addressList.length === 0 && <div>There is no address </div>}
      {addressList.map((ownerSingleAddress) => {
        const address = ownerSingleAddress;
        const addressTxt = `${address.name} / ${address.address1} / ${address.address2} / ${address.city} / ${address.state} / ${address.postal_code}`;

        return (
          <Paper elevation={3} style={{ padding: 10 }}>
            <div>{addressTxt}</div>
          </Paper>
        );
      })}
    </div>
  );
};
const CourseSummary = ({ open, customerId }) => {
  const [courses, setCourses] = React.useState([]);
  const [purchasedCourses, setPurchasedCourses] = React.useState([]);

  const [fetchPrePurchasedCourses, { loading, error }] = useLazyQuery(
    FETCH_CUSTOMER_COURSES,

    {
      variables: {
        customerId: customerId,
      },
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        console.log(res);
        const idMap = res.purchasedCoursesOfCustomer.map(
          (item) => item.courseId
        );
        setPurchasedCourses(idMap);
      },
    }
  );

  const [getCourses, { loading: loadingCourses }] = useLazyQuery(GET_COURSES, {
    onCompleted: (res) => {
      setCourses(res.getCourses);
    },
    onError: (err) => {
      console.log("error", err);
    },
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    if (open) {
      getCourses();
      fetchPrePurchasedCourses();
    }
  }, [open]);
  if (loading) {
    return <LinearLoading />;
  }
  if (error) {
    return <div> error </div>;
  }
  return (
    <div>
      <h4>Courses </h4>
      {purchasedCourses.length === 0 && <div>There is no course </div>}
      {courses
        .filter((item) => purchasedCourses.includes(item.id))
        .map((singleCourseItem) => {
          return (
            <Paper elevation={3} style={{ padding: 10 }}>
              <div>{singleCourseItem.title}</div>
            </Paper>
          );
        })}
    </div>
  );
};
