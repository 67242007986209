import {
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  MenuItem,
  Breadcrumbs,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_RETAILER_REQ_PENDING,
  RETAILER_STATUS_UPDATE,
  UPDATE_RETAILER_REQ,
} from "../../../queries";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import { States } from "../Customers/Components/constants";
import { Link } from "react-router-dom";
import { load } from "dotenv";

const APPROVED = "approved";
const DECLINED = "declined";

const RetailerRequests = () => {
  const { data, loading, error, refetch } = useQuery(GET_RETAILER_REQ_PENDING);

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <div>Error</div>;
  }
  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Retailer Requests</Typography>
          </Breadcrumbs>
        </div>
        <div className="right">
          <Link to={"/dashboard/vet-requests"}>
            <Button>Vet Requests</Button>
          </Link>
        </div>
      </div>
      {data.getRetailersReqPending.map((item, index) => {
        return (
          <RetailerItem
            key={"retailer-key-" + item.id}
            item={item}
            refetch={refetch}
          />
        );
      })}
    </div>
  );
};
export default RetailerRequests;

const RetailerItem = ({ item, refetch = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateStatusMutation, { loading }] = useMutation(
    RETAILER_STATUS_UPDATE
  );
  const bussinessName = item.bussiness_name;
  const type_of_bussiness = item.type_of_bussiness;
  const firstName = item.first_name;
  const lastName = item.last_name;
  const phone = item.phone_number;
  const email = item.email;
  const line1 = item.shipping.address1;
  const line2 = item.shipping.address2;
  const state = item.shipping.state;
  const city = item.shipping.city;
  const zipCode = item.shipping.postal_code;
  const status = item.status;
  const website = item.social.website;
  const tiktok = item.social.tiktok;
  const facebook = item.social.facebook;
  const instagram = item.social.instagram;

  const reqTime = item.createdAt;

  const addressText = `${line1} / ${line2} / ${state} / ${city} / ${zipCode} `;

  const onConfirm = () => {
    updateStatusMutation({ variables: { id: item.id, status: APPROVED } })
      .then((res) => {
        refetch();
        enqueueSnackbar("Updated Successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      });
  };
  const onDecline = (status) => {
    updateStatusMutation({ variables: { id: item.id, status: DECLINED } })
      .then((res) => {
        refetch();
        enqueueSnackbar("Declined Successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar("Error", {
          variant: "error",
        });
      });
    refetch();
  };
  return (
    <div
      style={{
        maxWidth: 600,
        borderRadius: 5,
        padding: 20,
        marginBottom: 10,
        position: "relative",
        backgroundColor: "#222429",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      }}
    >
      <div
        style={{
          position: "absolute",
          right: 10,
          top: 10,
        }}
      >
        <UpdateRetailer item={item} refetch={refetch} />
        <Typography>{moment(reqTime * 1000).format("MM/DD/YYYY")}</Typography>
        <Typography variant="subtitle2" textAlign={"center"}>
          {moment(reqTime * 1000).format("LT")}
        </Typography>
      </div>

      <Typography variant="h5">
        {firstName} {lastName}
      </Typography>
      <Typography>
        <b>Business Name: </b> {bussinessName}
      </Typography>
      <Typography>
        <b>Type of Bussiness: </b> {type_of_bussiness}
      </Typography>
      <Typography>
        <b>Phone Number: </b> {phone}
      </Typography>
      <Typography>
        <b>Email:</b> {email}
      </Typography>
      <Typography>
        <b>Address: </b> {addressText}
      </Typography>
      {website && (
        <Typography>
          <b>Website: </b> {website}
        </Typography>
      )}
      {tiktok && (
        <Typography>
          <b>Tiktok: </b> {tiktok}
        </Typography>
      )}
      {instagram && (
        <Typography>
          <b>Instagram: </b> {instagram}
        </Typography>
      )}
      {facebook && (
        <Typography>
          <b>Facebook: </b> {facebook}
        </Typography>
      )}
      <br></br>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Chip
          color="secondary"
          label={status.toUpperCase()}
          style={{
            backgroundColor: "yellow",
            color: "black",
          }}
        />
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <ConfirmRetailer
              type={DECLINED}
              firstName={firstName}
              lastName={lastName}
              onConfirm={onDecline}
              loading={loading}
            />
            <ConfirmRetailer
              type={APPROVED}
              firstName={firstName}
              lastName={lastName}
              onConfirm={onConfirm}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const ConfirmRetailer = ({ type, firstName, lastName, onConfirm, loading }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const color = type === APPROVED ? "success" : "error";
  return (
    <div>
      <Button
        variant="outlined"
        color={color}
        onClick={handleClickOpen}
        style={{
          marginLeft: 10,
        }}
      >
        {type === APPROVED && "Confirm"}
        {type === DECLINED && "Decline"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to create Retailer User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {firstName} {lastName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              handleClose();
            }}
            color={color}
            autoFocus
            disabled={loading}
          >
            {type === APPROVED && !loading && "Confirm"}
            {type === DECLINED && !loading && "Decline"}
            {loading && <CircularProgress />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const UpdateRetailer = ({ item, refetch }) => {
  const [updateRetailerReqMutation] = useMutation(UPDATE_RETAILER_REQ);
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const [bussinessName, setBussinessName] = useState(item.bussiness_name);
  const [firstName, setFirstName] = useState(item.first_name);
  const [lastName, setLastName] = useState(item.last_name);
  const [typeOfBusiness, setTypeOfBusiness] = useState(item.type_of_bussiness);
  const [phone, setPhone] = useState(item.phone_number);
  const [email, setEmail] = useState(item.email);

  const [firstNameInvalid, setFirstNameInvalid] = useState(false);
  const [lastNameInvalid, setLastNameInvalid] = useState(false);
  const [line1, setLine1] = useState(item.shipping.address1);
  const [line1Invalid, setLine1Invalid] = useState(false);
  const [line2, setLine2] = useState(item.shipping.address2);
  const [line2Invalid, setLine2Invalid] = useState(false);
  const [city, setCity] = useState(item.shipping.city);
  const [cityInvalid, setCityInvalid] = useState(false);
  const [state, setState] = useState(item.shipping.state);
  const [stateInvalid, setStateInvalid] = useState(false);
  const [zipCode, setZipCode] = useState(item.shipping.postal_code);
  const [zipCodeInvalid, setZipCodeInvalid] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    updateRetailerRequest();
  };

  const createInput = () => {
    const input = {
      bussiness_name: bussinessName,
      type_of_bussiness: typeOfBusiness,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phone,
      shipping: {
        name: `${firstName} ${lastName}`,
        address: `${line1} ${line2}`,
        address1: line1,
        address2: line2,
        postal_code: zipCode,
        state: state,
        city: city,
      },
    };
    return input;
  };
  const updateRetailerRequest = () => {
    const input = createInput();
    updateRetailerReqMutation({
      variables: { id: item.id, input },
    })
      .then((res) => {
        enqueueSnackbar("Updated Successfully", {
          variant: "success",
        });
        handleClose();
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar("Error", {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edit"}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            paddingTop: "10px",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            ["& .MuiTextField-root"]: {
              marginTop: "12px",
              width: "48%",
            },
          }}
        >
          <TextField
            variant="outlined"
            label="Business Name"
            value={bussinessName}
            onChange={(e) => setBussinessName(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Type of Bussiness"
            value={typeOfBusiness}
            onChange={(e) => setTypeOfBusiness(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Line1"
            value={line1}
            onChange={(e) => setLine1(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Line2"
            value={line2}
            onChange={(e) => setLine2(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Zip Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
          <TextField
            fullWidth
            id="state"
            select
            label="Select State"
            value={state}
            onChange={(e) => {
              setState(e.target.value);
            }}
            helperText={"Please select your state"}
          >
            {States.map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
