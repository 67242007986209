import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { createRandKey, MULTIPLE_CHOICE } from "../../../../globals";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { useQuery } from "@apollo/client";
import { GET_SINGLE_QUESTION } from "../../../../queries";
import QuestionDialog from "./QuestionDialog";

const QuizQuestions = ({
  questions,
  deleteQ,
  onMoveDown,
  onMoveUp,
  preview = false,
}) => {
  return (
    <div className="every-child-margin ">
      {questions.map((question, index) => {
        return (
          <QuestionDisabled
            key={question.id}
            onMoveUp={() => onMoveUp(index)}
            onMoveDown={() => onMoveDown(index)}
            questionComing={question}
            deleteQ={() => {
              deleteQ(index);
            }}
            index={index}
            length={questions.length}
            preview={preview}
          />
        );
      })}
    </div>
  );
};

export default QuizQuestions;

const QuestionDisabled = ({
  questionComing,
  deleteQ,
  onMoveUp,
  onMoveDown,
  index,
  length,
  preview = false,
}) => {
  const [question, setQuestion] = useState(questionComing);
  const { loading, error, data, refetch } = useQuery(GET_SINGLE_QUESTION, {
    variables: {
      id: questionComing.id,
    },
    onCompleted: (response) => {
      setQuestion(response.getSingleQuestions);
    },
  });

  if (loading) {
    return <LinearProgress></LinearProgress>;
  }

  if (error) {
    return <Typography>Error</Typography>;
  }
  return (
    <Paper
      style={{
        padding: 15,
        position: "relative",
      }}
      className="every-child-margin "
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={11} className="every-child-margin ">
          <TextField
            style={{
              width: "95%",
            }}
            label="Question"
            value={question.question}
            disabled={true}
          />
          {question.questionType === MULTIPLE_CHOICE ? (
            question.options.map((option, index) => {
              return (
                <Grid
                  key={"question-key-" + createRandKey(6)}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={1}>
                    <IconButton disabled={true} edge="end">
                      {question.answerIndex == index ? (
                        <RadioButtonCheckedIcon />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </IconButton>
                  </Grid>
                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      disabled={true}
                      value={option}
                      label={"Option"}
                    />
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <TextField
              fullWidth
              disabled
              value={question.answer}
              label="Answer"
              placeholder="This field will be filled by customers"
            />
          )}
        </Grid>
        {!preview && (
          <Grid item xs={1}>
            <IconButton
              onClick={() => {
                deleteQ();
              }}
              color={"error"}
            >
              <DeleteForeverIcon />
            </IconButton>
            <QuestionDialog
              update={true}
              originalQuestionItem={question}
              refetch={refetch}
            />
            <IconButton
              onClick={() => {
                onMoveUp();
              }}
              disabled={index === 0}
            >
              <ArrowCircleUpIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                onMoveDown();
              }}
              disabled={index === length - 1}
            >
              <ArrowCircleDownIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
