import { useQuery } from "@apollo/client";
import Edit from "@mui/icons-material/Edit";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET_PAGES, GET_GIFT_COUPONS } from "../../../queries";
import GenerateButton from "./Components/generateButton";
import PrintButton from "./Components/printButton";
import RedeemIcon from "@mui/icons-material/Redeem";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import SeeNote from "./Components/seeNote";
import CommentButton from "./Components/commentButton";
import CancelCouponButton from "./Components/CancelButton";

function GiftCodeGenerator(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // query get data
  const { subscribeToMore, loading, error, data, refetch, fetchMore } =
    useQuery(GET_GIFT_COUPONS, {
      variables: {
        limit: rowsPerPage,
        offset: 0,
      },
    });
  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.getGiftCoupons.length,
        limit: rowsPerPage,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;
  const { permission } = props.session.activeAdmin;

  return (
    <div>
      {Array.isArray(data.getGiftCoupons) ? (
        <PageUI
          {...data}
          permission={permission}
          loading={loading}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refetch={refetch}
          history={props.history}
        />
      ) : (
        ""
      )}
    </div>
  );
}
const PageUI = (props) => {
  console.log(props);
  const [god, setGod] = useState(false);
  const [secretshop, setSecretshop] = useState(false);
  const [now] = useState(moment().unix());
  const diff = 7776000; //90days

  useEffect(() => {
    setGod(props.permission.god ? props.permission.god.admin : false);
    setSecretshop(
      props.permission.secretshop ? props.permission.secretshop : false
    );
  }, [props.permission]);

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Gift Codes</Typography>
          </Breadcrumbs>
        </div>
        {god || secretshop.admin || secretshop.write ? (
          <div className="right" style={{ marginLeft: "10px" }}>
            <GenerateButton refetch={props.refetch} />
          </div>
        ) : (
          ""
        )}
        {god || secretshop.admin || secretshop.write ? (
          <div className="right">
            <Button
              component={Link}
              variant="outlined"
              color="primary"
              startIcon={<LocalPrintshopIcon />}
              to={"/dashboard/gift_code_generate/printed"}
              sx={{ marginLeft: "10px" }}
            >
              Printed
            </Button>
          </div>
        ) : (
          ""
        )}
        {god || secretshop.admin || secretshop.write ? (
          <div className="right">
            <Button
              component={Link}
              variant="outlined"
              color="primary"
              startIcon={<RedeemIcon />}
              to={"/dashboard/gift_code_generate/redeemed"}
            >
              Redeemed
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ul>
            {
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Coupon</TableCell>
                      <TableCell>Group</TableCell>
                      <TableCell>Recipient Email</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Expiry Date</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Note</TableCell>
                      <TableCell>Actions</TableCell>

                      {god || secretshop.admin || secretshop.write ? (
                        <TableCell></TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.getGiftCoupons && props.getGiftCoupons.length > 0 ? (
                      props.getGiftCoupons
                        .slice(
                          props.page * props.rowsPerPage,
                          props.page * props.rowsPerPage + props.rowsPerPage
                        )
                        .map((data) => {
                          console.log(data);
                          return (
                            <TableRow key={data.id}>
                              <TableCell component="th" scope="row">
                                {new Date(data.createdAt * 1000).toLocaleString(
                                  "en-US",
                                  {
                                    timeZone: "America/Los_Angeles",
                                  }
                                )}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {`${data.coupon.slice(
                                  0,
                                  4
                                )}-${data.coupon.slice(
                                  4,
                                  8
                                )}-${data.coupon.slice(
                                  8,
                                  12
                                )}-${data.coupon.slice(12, 16)}`}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {data.group ? data.group.name : ""}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {data.recipient_email
                                  ? data.recipient_email
                                  : ""}
                              </TableCell>

                              <TableCell component="th" scope="row">
                                ${data.amount}
                              </TableCell>

                              <TableCell component="th" scope="row">
                                {data.expiryDate
                                  ? moment
                                      .unix(data.expiryDate)
                                      .format("MM/DD/YYYY")
                                  : "Unlimited"}
                              </TableCell>

                              <TableCell component="th" scope="row">
                                {data.admin
                                  ? data.admin.email
                                  : data.customer?.email}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <SeeNote
                                  email={
                                    data.admin
                                      ? data.admin.email
                                      : data.customer?.email
                                  }
                                  coupon={`${data.coupon.slice(
                                    0,
                                    4
                                  )}-${data.coupon.slice(
                                    4,
                                    8
                                  )}-${data.coupon.slice(
                                    8,
                                    12
                                  )}-${data.coupon.slice(12, 16)}`}
                                  note={data.note}
                                />
                              </TableCell>
                              {god || secretshop.admin || secretshop.write ? (
                                <TableCell component="th" scope="row">
                                  <PrintButton
                                    refetch={props.refetch}
                                    id={data.id}
                                  />
                                  <CommentButton
                                    refetch={props.refetch}
                                    id={data.id}
                                  />
                                  <CancelCouponButton
                                    refetch={props.refetch}
                                    id={data.id}
                                  />
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </ul>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.giftCouponCount ? props.giftCouponCount : 0}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default GiftCodeGenerator;
