import { useLazyQuery } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import { Paper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import { PAGES_FIND } from "../../../../queries";

const FEEDREAL_BASE = "feedreal.com";
const FeedrealTutorial = ({ article = {}, onSelectArticle = () => {} }) => {
  const [search, setSearch] = useState("");
  const [dataset, setDataset] = useState([]);
  const [open, setOpen] = useState(false);
  const isInitialMount = useRef(true);

  const [getArticles, { loading: loadingSearch, data: searchData }] =
    useLazyQuery(PAGES_FIND, {
      fetchPolicy: "no-cache",
      variables: {
        key: search,
        website: FEEDREAL_BASE,
      },
      onCompleted: (data) => {
        if (data) {
          setDataset(data.pagesFind);
        }
      },
    });

  useEffect(() => {
    if (!isInitialMount.current) {
      getArticles();
      isInitialMount.current = true;
    }
  }, [search]);

  const searchInput = (event) => {
    const { value } = event.target;
    if (value.length > 2) {
      isInitialMount.current = false;
      setSearch(value);
    }
  };
  const onMouseLeave = (event) => {
    setOpen(false);
  };

  const onMouseOver = (event) => {
    setOpen(true);
  };

  return (
    <Paper
      style={{
        padding: 20,
        backgroundColor: "black",
      }}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {Object.keys(article).length !== 0 ? (
        <List dense={true}>
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                onClick={() => {
                  onSelectArticle({});
                }}
              >
                <EditIcon />
              </IconButton>
            }
          >
            <ListItemText primary={article.title} />
          </ListItem>
        </List>
      ) : (
        <Autocomplete
          id="REALDOG - REAL BLOG SEARCH"
          noOptionsText={"Autocomplete Search Feature"}
          loading={loadingSearch}
          options={dataset}
          valueInput={search}
          getOptionLabel={(option) => option.title}
          open={open}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <div className="searchresult">
                <b>{option.title} </b>
                <span>
                  {option.website} - {option.status ? "Active" : "Draft"}
                </span>
              </div>
            </li>
          )}
          onChange={(e, value) => {
            let newValue = value || "";
            onSelectArticle(newValue);
          }}
          renderInput={(articles) => (
            <TextField
              {...articles}
              label="Search Article or Page (feedreal)"
              margin="normal"
              onChange={searchInput}
              variant="outlined"
              InputProps={{
                ...articles.InputProps,
                value: search,
                type: "search",
              }}
            />
          )}
        />
      )}
    </Paper>
  );
};

export default FeedrealTutorial;
