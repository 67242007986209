import React, { Component } from "react";
import { ThemeProvider, createTheme, adaptV4Theme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import LoginPage from "./Dashboard/Login";
import Dashboard from "./Dashboard/Dashboard";

import sessionWrapper from "./sessionWrapper";

const Root = ({ refetch, session }) => (
  <Router>
    <Switch>
      <Route
        path="/"
        exact
        render={() => <LoginPage refetch={refetch} session={session} />}
      />
      <Route
        path="/dashboard"
        render={() => <Dashboard refetch={refetch} session={session} />}
      />
      <Redirect to="/" />
    </Switch>
  </Router>
);
const RootSession = sessionWrapper(Root);

class App extends Component {
  state = { darkState: false };
  render() {
    const themeDark = createTheme({
      palette: {
        mode: "dark",
        background: {
          default: "#282b33",
          paper: "#21242a",
        },
        primary: {
          light: "#fff",
          dark: "#fff",
          main: "#ccc",
          contrastText: "#ccc",
        },
        secondary: {
          main: "#63b475",
          dark: "#fff",
          light: "#fff",
          contrastText: "#fff",
        },
      },
      typography: {
        useNextVariants: true,
      },
    });

    return (
      <ThemeProvider theme={themeDark}>
        <CssBaseline />
        <div className="App">
          <RootSession theme={themeDark} />
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
