import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { GET_SINGLE_COURSE } from "../../../../queries";
import { useLazyQuery } from "@apollo/client";
import { createRandKey } from "../../../../globals";
import {
  Avatar,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import CreateUnitDialog from "./CreateUnitDialog";

export default function UnitsDialog({ courseId, activeAdmin }) {
  const [open, setOpen] = useState(false);
  const [units, setUnits] = useState([]);
  const [title, setTitle] = useState("");

  const [getSingleCourse, { loading: getSingleCourseLoading }] = useLazyQuery(
    GET_SINGLE_COURSE,
    {
      onCompleted: (response) => {
        const { title, units } = response.getSingleCourse;
        setUnits(units);
        setTitle(title);
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (courseId && open) {
      getSingleCourse({
        variables: {
          input: { id: courseId },
        },
      });
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (getSingleCourseLoading) {
    return <LinearProgress />;
  }

  return (
    <div style={{ width: "100%" }}>
      <Button
        style={{
          float: "right",
        }}
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
      >
        Units
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {"Course: " + title}
          <CreateUnitDialog
            courseId={courseId}
            activeAdmin={activeAdmin}
            unitsList={units}
          />
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {units.map((unit) => {
              return (
                <UnitListItem
                  key={"unit-list-key-" + createRandKey(6)}
                  unit={unit}
                  courseId={courseId}
                />
              );
            })}
            {units.length === 0 && (
              <Typography
                align="center"
                variant="h4"
                style={{ width: "100%", marginTop: 10 }}
              >
                There is no unit yet
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const UnitListItem = ({ unit, courseId }) => {
  const style = unit.status
    ? {
        background: "green",
        color: "white",
      }
    : {
        background: "red",
        color: "white",
      };
  return (
    <ListItem
      style={{
        backgrounColor: "black",
      }}
      secondaryAction={
        <Link to={`/dashboard/course/edit/${courseId}/unit/edit/${unit.id}`}>
          <IconButton edge="end" aria-label="delete">
            <EditIcon />
          </IconButton>
        </Link>
      }
    >
      <ListItemAvatar>
        <Avatar style={style}>
          <FolderIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={unit.title}
        // secondary={true ? "Secondary text" : null}
      />
    </ListItem>
  );
};
