import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { RESUME_SUB } from "../../../../../queries";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  backdropShippingCalc: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000",
    position: "absolute",
  },
}));

export default function ResumeDialog({ subscription, refetch }) {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [resumeMutation, { loading, data, error }] = useMutation(RESUME_SUB);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirmResume = () => {
    resumeMutation({
      variables: {
        id: subscription.id,
      },
    })
      .then((res) => {
        enqueueSnackbar(`Subscription resumed`, {
          variant: "success",
        });
        handleClose();
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(`Subscription couldn't resumed`, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Button
        fullWidth
        size={"small"}
        variant={"outlined"}
        color="primary"
        style={{
          marginTop: 10,
        }}
        onClick={handleClickOpen}
      >
        resume subscription
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to resume subscription"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText fontSize={12}>
            Note: Confirming this action will <b>immediately charge </b>
            the subscription
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            close
          </Button>
          <Button
            onClick={() => {
              onConfirmResume();
            }}
            color="primary"
            autoFocus
          >
            ok
          </Button>
        </DialogActions>
        <Backdrop className={classes.backdropShippingCalc} open={loading}>
          <Typography
            variant={"subtitle1"}
            style={{ color: "white" }}
            align={"center"}
          >
            <CircularProgress color="primary" />
            <br />
            Please wait for Subscription resume
          </Typography>
        </Backdrop>
      </Dialog>
    </div>
  );
}
