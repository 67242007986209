import { gql } from "@apollo/client";

const RDC_TRANSFER = gql`
  mutation TransferCoins($customerId: ID, $amount: Int) {
    transferCoins(customerId: $customerId, amount: $amount) {
      coins
    }
  }
`;
export { RDC_TRANSFER };
