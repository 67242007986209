import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#63b274",
    "&:hover": {
      backgroundColor: alpha("#63b274", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#63b274",
  },
}));

const QuizStatus = ({ status, setStatus }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <GreenSwitch
            checked={status}
            onChange={() => {
              setStatus(!status);
            }}
          />
        }
        label={
          status
            ? "Active (Users can see this.) "
            : "Deactive (Users can't see this.)"
        }
      />
    </FormGroup>
  );
};

export default QuizStatus;
