import React, { useState, useEffect, useRef } from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment-timezone";

import {
  ADD_SHORTLEASH_BLOG,
  GET_ADMINS,
  SINGLE_UPLOAD,
  CREATE_SHORTLEASH,
} from "../../../queries";
//
import {
  Button,
  CssBaseline,
  TextField,
  Container,
  CircularProgress,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

//utils
import { UploadFile } from "../../Tools/Upload";
import { startSingleUpload } from "../../Funcs/Upload";

import Editor from "./Editor"; //delete

import { useSnackbar } from "notistack";
import { createRandKey } from "../../../globals";

import WebsiteCategories from "./Components/websiteCat";

const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

const ShortleashAdd = (props) => {
  //useStates
  const { enqueueSnackbar } = useSnackbar();
  const onAuto = useRef(false);
  const [form, setForm] = useState({
    title: "",
    admin_id: "",
  });
  const [status, setStatus] = useState(false);
  const [articleText, setArticleText] = useState({});
  const [adminList, setAdminList] = useState([]);
  const [me, setMe] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const [website, setWebsite] = useState("");
  const defaultWebsite = "shortleash.co";

  const [node, setNode] = useState([
    {
      title: "",
      html: "",
      thumbnail: "",
    },
  ]);

  //useQueries
  const { data: data_admin, loading: loading_admin } = useQuery(GET_ADMINS);

  //useMutation
  const [createShortleash, { error, loading: loadingAddBlog }] =
    useMutation(CREATE_SHORTLEASH);
  const [singleUpload, { loading: uploadLoading, error: uploadError }] =
    useMutation(SINGLE_UPLOAD);

  useEffect(() => {
    if (!loading_admin && data_admin) {
      const me = data_admin.admins.filter(
        (x) => x.id === props.session.activeAdmin.id
      );
      setAdminList(data_admin);
      setMe(me[0]);
      setForm({
        ...form,
        admin_id: me[0].id,
      });
    }
  }, [data_admin, loading_admin]);

  //on Functions
  const updateField = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event) => {
    setStatus(event.target.checked);
  };

  const formValidate = () => {
    const { title } = form;
    return !title;
  };

  const onChangeEditor = (text) => {
    const currentText = articleText.text;
    const newText = JSON.stringify(text);
    if (currentText !== newText) {
      // Content has changed
      setArticleText({ text: newText });
    }
  };

  const onSubmit = async () => {
    const { admin_id, title } = form;
    if (website === "") {
      setWebsite("shortleash.co");
    }
    createShortleash({
      variables: {
        input: {
          title: title,
          admin_id: admin_id,
          status: status,
          leashes: node,
          website: website !== "" ? website : defaultWebsite,
          createdAt: moment().unix(),
        },
      },
    })
      .then(async ({ data }) => {
        props.history.push(
          `/dashboard/newsletter/edit/${data.createShortleash.id}`
        );
      })
      .catch((e) => {
        enqueueSnackbar(`${e.message}`, {
          variant: "error",
        });
        console.log(e);
      });
  };

  const uploadFile = async (file, imageBlock) => {
    await startSingleUpload(file, singleUpload, async (result) => {
      imageBlock.state.url = result.uri;
      imageBlock.updateData();
      imageBlock.stopLoader();
    });
  };

  const resetFeaturedPhoto = () => {
    setSelectedPhoto("");
  };

  const uploadPhoto = (file) => {
    setSelectedPhoto(file.uri);
  };

  //Node Action
  const handleNodeMore = (e) => {
    setNode((node) => [
      ...node,
      {
        title: "",
        html: "",
        thumbnail: "",
      },
    ]);
  };

  const handleNodeLess = (e) => {
    let nodeLess = node.slice(0, node.length - 1);
    setNode(nodeLess);
  };

  const changeNodeTitle = (event, num) => {
    node[num]["title"] = event.target.value;
    setNode((node) => [...node]);
  };

  const changeNodeText = (text, num) => {
    const currentText = articleText.text;
    const newText = JSON.stringify(text);
    if (currentText !== newText) {
      // Content has changed
      setArticleText({ text: newText });
      onAuto.current = true;
    }
    node[num]["html"] = newText;
    setNode((node) => [...node]);
  };

  let uploadNodePhoto = ({ uri }, num) => {
    node[num]["thumbnail"] = uri;
    setNode((node) => [...node]);
  };

  const resetNodePhoto = (num) => {
    node[num]["thumbnail"] = "";
    setNode((node) => [...node]);
    setNode([...node]);
  };

  if (loading_admin)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );

  if (error) {
    console.log(JSON.stringify({ error }));
  }
  return (
    <div>
      <Container>
        <Grid container spacing={3}>
          <CssBaseline />
          <Grid item xs={9}>
            <div className="">
              <TextField
                onChange={updateField}
                variant="outlined"
                inputProps={{ style: { fontSize: 22 } }}
                InputLabelProps={{ style: { fontSize: 22 } }}
                margin="normal"
                value={form.title}
                required
                fullWidth
                name="title"
                label="Title"
                type="text"
                id="title"
              />
              {node.map((field, i) => (
                <NodeArea
                  num={i}
                  lastNode={node.length - 1}
                  field={field}
                  nodeLess={handleNodeLess}
                  moreNode={handleNodeMore}
                  changeTitle={changeNodeTitle}
                  changeNodeText={changeNodeText}
                  uploadNodePhoto={uploadNodePhoto}
                  resetNodePhoto={resetNodePhoto}
                />
              ))}
            </div>
          </Grid>
          <Grid item xs={3}>
            <Paper className="padding10 margintop15">
              <div>
                <FormControl variant="outlined" className="width100">
                  <InputLabel id="category">Select Author</InputLabel>
                  <Select
                    name="admin_id"
                    value={form.admin_id}
                    onChange={updateField}
                    label="category"
                  >
                    <MenuItem value={props.session.activeAdmin.id}>
                      {me.name} (Me)
                    </MenuItem>

                    {adminList.admins &&
                      adminList.admins.map((admin) => {
                        if (admin.id !== props.session.activeAdmin.id) {
                          return (
                            <MenuItem key={admin.id} value={admin.id}>
                              {admin.name}
                            </MenuItem>
                          );
                        }
                      })}
                  </Select>
                </FormControl>
              </div>
              <br />
              {/* {!selectedPhoto ? (
                <UploadFile
                  uploadPhoto={uploadPhoto}
                  buttonName={"Add Featured Image"}
                />
              ) : (
                <div className="featuredImgBlock">
                  <div className="deletephotobtn">
                    <IconButton
                      onClick={resetFeaturedPhoto}
                      color="secondary"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                  <img width={"100%"} src={selectedPhoto} />
                </div>
              )} */}
              <br />
              <WebsiteCategories website={website} setWebsite={setWebsite} />
              <br />
              <FormControlLabel
                control={
                  <Switch
                    className="padding10"
                    checked={status}
                    onChange={handleChange}
                    name="status"
                    color="secondary"
                    inputProps={{
                      "aria-label": "secondary checkbox",
                    }}
                  />
                }
                label={status ? "Publish" : "Archive"}
              />
              <br /> <br />
              {node.filter((data) => data.title == "").length > 0 && (
                <p>Titles are required.</p>
              )}
              <br />
              <Button
                type="submit"
                fullWidth
                onClick={onSubmit}
                variant="outlined"
                color={status ? "secondary" : "primary"}
                size="large"
                disabled={
                  loadingAddBlog ||
                  formValidate() ||
                  node.filter((data) => data.title == "").length > 0
                }
              >
                {status ? "PUBLISH" : "SAVE DRAFT"}
                {loadingAddBlog && <SpinnerAdornment />}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withApollo(ShortleashAdd);

const NodeArea = (props) => {
  return (
    <div key={props.num} className="nodes_box">
      <Grid container>
        <Grid item xs={12}>
          <div className="titlebox">Add Nodes</div>

          <TextField
            onChange={(event) => props.changeTitle(event, props.num)}
            variant="outlined"
            inputProps={{ style: { fontSize: 22 } }}
            InputLabelProps={{ style: { fontSize: 22 } }}
            margin="normal"
            required
            fullWidth
            name="title"
            label="Title"
            type="text"
            id="title"
          />
          <br />
          <div className="editordante node">
            <Editor
              content={""}
              onChangeEditor={props.changeNodeText}
              num={props.num}
            />
          </div>
          {/* <TextField
            id="outlined-multiline-static"
            label="Text"
            multiline
            fullWidth
            rows={4}
            placeholder="Change my mind"
            variant="outlined"
            onChange={(event) => props.changeNodeText(event, props.num)}
          /> */}
          <br />
          {props.field.thumbnail ? (
            <div className="featuredImgBlock">
              <div className="deletephotobtn">
                <IconButton
                  onClick={() => props.resetNodePhoto(props.num)}
                  color="secondary"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <img width={"100%"} src={props.field.thumbnail} />
            </div>
          ) : (
            <UploadFile
              idKey={"uplad-image-key-" + createRandKey(6)}
              uploadPhoto={(file) => props.uploadNodePhoto(file, props.num)}
              buttonName={"Add Featured Image"}
            />
          )}
        </Grid>
      </Grid>

      <br />
      {props.lastNode === props.num ? (
        <div>
          {" "}
          <Button variant="outlined" color="secondary" onClick={props.moreNode}>
            {" "}
            + Add Node{" "}
          </Button>{" "}
          {props.num != 0 ? (
            <Button variant="outlined" color="primary" onClick={props.nodeLess}>
              {" "}
              DELETE{" "}
            </Button>
          ) : (
            ""
          )}{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
