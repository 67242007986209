import React, { useEffect, useState } from "react";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import "date-fns";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import { makeStyles } from "@mui/styles";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Chip from "@mui/material/Chip";
import CircleIcon from "@mui/icons-material/Circle";
import {
  GET_CANCELS_FILTER,
  WRITE_COMMENT,
  CHECK_REACH,
  CHANGE_STATUS_CANCEL,
  CANCELMEMBERSHIP_BYCANCELID,
} from "../../../queries";
import { useSnackbar } from "notistack";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import cancelReasons from "../../../globals/reasons";
import CancelMembershipDialog from "./CancelMembershipDialog";
import PendingIcon from "@mui/icons-material/Pending";
import Search from "./components/Search";

const ADMIN = "ADMIN";
const MEMBER = "MEMBER";
const ARCHIVE = "ARCHIVE";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 500,
  },
  noMaxWidth: {
    maxWidth: "none",
  },
  searchBtn: {
    width: "100%",
    marginTop: "10px",
  },
}));

function Archive(props) {
  console.log("props", props);
  const classes = useStyles();
  const now = new Date();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");
  const [offset, setOffset] = useState(0);

  const [dateDiff, setDateDiff] = useState(7);
  const [startDate, setStartDate] = useState(
    moment(now).subtract(7, "day").unix()
  );
  const [endDate, setEndDate] = useState(moment().endOf("day").unix());

  const { getCancels, loading, data, error, refetch, fetchMore } = useQuery(
    GET_CANCELS_FILTER,
    {
      variables: {
        startDate,
        endDate,
        offset: 0,
        limit: rowsPerPage,
        order: sortOrder,
        requestStatus: ARCHIVE,
      },
    }
  );

  const [saveNote, { loadingSave }] = useMutation(WRITE_COMMENT);
  const [checkReach, { loadingReach }] = useMutation(CHECK_REACH);

  useEffect(() => {
    refetch();
  }, [refetch, rowsPerPage, sortOrder]);

  const loadPage = () => {
    setOffset(data.cancelFilter.length);
    fetchMore({
      variables: {
        startDate,
        endDate,
        offset: data.cancelFilter.length,
        limit: rowsPerPage,
      },
    });
  };
  const loadSamePage = () => {
    fetchMore({
      variables: {
        startDate,
        endDate,
        offset: offset,
        limit: rowsPerPage,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };

  const sortCreatedDate = (event) => {
    setSortOrder(event.target.value);
    refetch({
      variables: {
        order: event.target.value,
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const changeStartDate = (date) => {
    setStartDate(moment(date).unix());
    refetch();
  };

  const changeEndDate = (date) => {
    setEndDate(moment(date).unix() + 86399);
    refetch();
  };

  useEffect(() => {
    const diff = Math.ceil(
      moment
        .duration(moment.unix(endDate).diff(moment.unix(startDate)))
        .asDays()
    );
    setDateDiff(diff);
  }, [startDate, endDate]);

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  return (
    <div>
      <CancelUI
        {...data}
        activeAdmin={props.session.activeAdmin}
        loadingSave={loadingSave}
        saveNote={saveNote}
        checkReach={checkReach}
        loading={loading}
        classes={classes}
        diff={dateDiff}
        startDate={startDate}
        endDate={endDate}
        changeStartDate={changeStartDate}
        changeEndDate={changeEndDate}
        rowsPerPage={rowsPerPage}
        page={page}
        sortOrder={sortOrder}
        sortCreatedDate={sortCreatedDate}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        refetch={loadSamePage}
      />
    </div>
  );
}

const CancelUI = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const time = moment().unix();
  const [open, setOpen] = React.useState(false);
  const [openMembership, setOpenMembership] = React.useState(false);
  const [dialogData, setDialogData] = useState();

  const [changeStatus, { loading: loadingChange }] =
    useMutation(CHANGE_STATUS_CANCEL);
  const [changeMembership, { loading: loadingChangeMembership }] = useMutation(
    CANCELMEMBERSHIP_BYCANCELID
  );
  const onChangeStatus = (
    id,
    status,
    cancelReason,
    cancelAtTheEnd,
    handleClose,
    type
  ) => {
    console.log("type", type);
    if (id && status && type !== "MEMBERSHIP") {
      changeStatus({
        variables: {
          id: id,
          status: status,
          cancelReason: cancelReason,
          createdAt: moment().unix(),
          cancelAtPeriodEnd: cancelAtTheEnd,
        },
      })
        .then((data) => {
          enqueueSnackbar(`Checked Successfully`, {
            variant: "success",
          });
          props.refetch();
          handleClose();
          return true;
        })
        .catch((e) => {
          enqueueSnackbar(`${e.message}`, {
            variant: "error",
          });
        });
    } else if (id && status && type === "MEMBERSHIP") {
      if (status === "APPROVED") {
        changeMembership({
          variables: {
            id: id,
            message: cancelReason,
            cancelAtPeriodEnd: cancelAtTheEnd,
          },
        })
          .then((data) => {
            enqueueSnackbar(`Checked Successfully`, {
              variant: "success",
            });
            props.refetch();
            handleClose();
            return true;
          })
          .catch((e) => {
            enqueueSnackbar(`${e.message}`, {
              variant: "error",
            });
          });
      } else {
        changeStatus({
          variables: {
            id: id,
            status: status,
            cancelReason: cancelReason,
            createdAt: moment().unix(),
            cancelAtPeriodEnd: cancelAtTheEnd,
          },
        })
          .then((data) => {
            enqueueSnackbar(`Checked Successfully`, {
              variant: "success",
            });
            props.refetch();
            handleClose();
            return true;
          })
          .catch((e) => {
            enqueueSnackbar(`${e.message}`, {
              variant: "error",
            });
          });
      }
    }
  };

  const handleClickOpen = (data) => {
    // if (data.canceledProduct === "MEMBERSHIP") {
    //   setDialogData(data);
    //   setOpenMembership(true);
    //   return;
    // }
    if (data) {
      setOpen(true);
      setDialogData(data);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getColor = (status) => {
    let color = "";
    switch (status) {
      case "PENDING":
        color = "orange";
        break;
      case "APPROVED":
        color = "red";
        break;
      case "REJECTED":
        color = "green";
        break;
      case "CANCELED":
        color = "red";
        break;
      default:
        color = "red";
    }
    return color;
  };

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              variant="text"
              // color="tertiary"
              // className="redborder"
              className="menu-link"
              size="small"
              to={"/dashboard"}
            >
              Dashboard
            </Link>
            <Link
              variant="text"
              // color="tertiary"
              // className="redborder"
              className="menu-link"
              size="small"
              to={"/dashboard/reports/cancel"}
            >
              Cancellations
            </Link>

            <Typography color="textPrimary">Archive</Typography>
          </Breadcrumbs>
        </div>
        <div className="right">
          <Button
            component={Link}
            variant="outlined"
            color="primary"
            to="/dashboard/reports/cancel"
            startIcon={<PendingIcon />}
          >
            Pending
          </Button>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid className="badgemenu">
              <Grid item xs={12} className="member-data">
                <Typography
                  style={{ marginBottom: "15px" }}
                  textAlign={"center"}
                >
                  Cancellation Detail
                  <br />
                  <br />
                  {dialogData &&
                    dialogData.dontCancel &&
                    dialogData.dontCancel === true && (
                      <Chip label="Continue" color="success" />
                    )}
                </Typography>
                {dialogData && dialogData.canceledBy.type && (
                  <TextField
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    label="Canceled By"
                    defaultValue={
                      dialogData.canceledBy.type === "MEMBER"
                        ? "MEMBER"
                        : "MS: " + dialogData.canceledBy.email
                    }
                    variant="outlined"
                  />
                )}

                {dialogData && dialogData.createdAt && (
                  <TextField
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    label="Cancelled At"
                    defaultValue={moment
                      .unix(dialogData.createdAt)
                      .format("MM/DD/YYYY")}
                    variant="outlined"
                  />
                )}
                {dialogData && dialogData.canceledSub && (
                  <TextField
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    label="Subscription ID"
                    defaultValue={dialogData.canceledSub}
                    variant="outlined"
                  />
                )}

                {dialogData && dialogData.canceledAt && (
                  <TextField
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    label="End of the Cycle"
                    defaultValue={moment
                      .unix(dialogData.canceledAt)
                      .format("MM/DD/YYYY")}
                    variant="outlined"
                  />
                )}
                {dialogData && dialogData.member.name && (
                  <TextField
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    label="Name"
                    defaultValue={dialogData.member.name}
                    variant="outlined"
                  />
                )}
                {dialogData && dialogData.member.email && (
                  <Link
                    variant="text"
                    // color="tertiary"
                    // className="redborder"
                    className="textfield-link"
                    size="small"
                    to={`/dashboard/customer/${dialogData.owner}`}
                    target="_blank"
                  >
                    <TextField
                      fullWidth
                      disabled
                      id="outlined-disabled"
                      label="Email"
                      defaultValue={dialogData.member.email}
                      variant="outlined"
                    />
                  </Link>
                )}
                {dialogData && dialogData.canceledProduct && (
                  <TextField
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    label="Canceled Product"
                    defaultValue={dialogData.canceledProduct}
                    variant="outlined"
                  />
                )}
                {dialogData && dialogData.cancelReason && (
                  <TextField
                    fullWidth
                    disabled
                    multiline
                    id="outlined-multiline-static"
                    label="Reason"
                    maxRows={4}
                    rows={4}
                    defaultValue={dialogData.cancelReason}
                    variant="outlined"
                  />
                )}
                {dialogData && dialogData.note && dialogData.note.message && (
                  <TextField
                    fullWidth
                    disabled
                    multiline
                    id="outlined-multiline-static"
                    label={`${dialogData.note.admin.email}'s Note`}
                    maxRows={4}
                    rows={4}
                    defaultValue={dialogData.note.message}
                    variant="outlined"
                  />
                )}

                {dialogData &&
                  dialogData.dontCancel === true &&
                  dialogData.canceledAt && (
                    <TextField
                      fullWidth
                      disabled
                      id="outlined-disabled"
                      label="Dont Cancel At"
                      defaultValue={moment
                        .unix(dialogData.canceledAt)
                        .format("MM/DD/YYYY")}
                      variant="outlined"
                    />
                  )}
                {dialogData &&
                  dialogData.dontCancel === true &&
                  dialogData.dontCanceledBy.type && (
                    <TextField
                      fullWidth
                      disabled
                      id="outlined-disabled"
                      label="Canceled By"
                      defaultValue={
                        dialogData.dontCanceledBy.type === "MEMBER"
                          ? "MEMBER"
                          : "MS: " + dialogData.dontCanceledBy.email
                      }
                      variant="outlined"
                    />
                  )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogData && dialogData.cancelRequest?.status === "PENDING" && (
            <>
              {dialogData.canceledProduct === "MEMBERSHIP" && (
                <CancelMembershipDialog
                  memberId={dialogData.member.id}
                  cancelId={dialogData.id}
                  dialogData={dialogData}
                />
              )}
              <CancelSub
                isCancel={false}
                confirmCancelSub={(cancelAtTheEnd, message) => {
                  return onChangeStatus(
                    dialogData.id,
                    "APPROVED",
                    message,
                    cancelAtTheEnd,
                    handleClose,
                    dialogData.canceledProduct
                  );
                }}
                loadingChange={loadingChange || loadingChangeMembership}
                isMembership={dialogData.canceledProduct === "MEMBERSHIP"}
              />
              <CancelSub
                isCancel={true}
                confirmCancelSub={(cancelAtTheEnd, message) => {
                  return onChangeStatus(
                    dialogData.id,
                    "REJECTED",
                    message,
                    cancelAtTheEnd,
                    handleClose,
                    dialogData.canceledProduct
                  );
                }}
                loadingChange={loadingChange || loadingChangeMembership}
              />
            </>
          )}
          <div>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3} className="max1200">
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className="paddingdate bordergreen">
                <DesktopDatePicker
                  disableToolbar
                  margin="normal"
                  id="startdate"
                  disableFuture={true}
                  placeholder={"MM/dd/yyyy"}
                  label="Start Date"
                  inputFormat={"MM/dd/yyyy"}
                  maxDate={moment(new Date()).format("MM/DD/YYYY")}
                  value={moment.unix(props.startDate).format("MM/DD/YYYY")}
                  onChange={props.changeStartDate}
                  renderInput={(params) => (
                    <TextField {...params} variant={"standard"} error={false} />
                  )}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <DesktopDatePicker
                  disableToolbar
                  margin="normal"
                  id="enddate"
                  disableFuture={true}
                  minDate={moment.unix(props.startDate).format("MM/DD/YYYY")}
                  maxDate={moment(new Date()).format("MM/DD/YYYY")}
                  placeholder={"MM/dd/yyyy"}
                  label="End Date"
                  inputFormat={"MM/dd/yyyy"}
                  value={moment.unix(props.endDate).format("MM/DD/YYYY")}
                  onChange={props.changeEndDate}
                  renderInput={(params) => (
                    <TextField {...params} variant={"standard"} error={false} />
                  )}
                />
              </Paper>
            </Grid>
            <Grid item xs={6} spacing={3}>
              <Grid item xs={12}>
                <Paper
                  className={`borderblue summarybox ${props.classes.paper}`}
                >
                  Cancellation <b>{props.diff} days</b>
                </Paper>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <Paper
                  className={`borderred summarybox ${props.classes.paper}`}
                >
                  Total{" "}
                  <b>
                    {props.loading ? (
                      <CircularProgress />
                    ) : props.cancelsCount ? (
                      props.cancelsCount
                    ) : (
                      0
                    )}
                  </b>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <div>
            <Search
              {...props}
              openDialog={(n) => {
                setDialogData(n);
                setOpen(true);
              }}
            />
          </div>
          <ul>
            {
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* 
                      <TableCell>Cancel Date</TableCell>
                      <TableCell>User Since</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Customer Email</TableCell>
                      <TableCell>Canceled By</TableCell>
                      <TableCell>Status</TableCell> */}
                      {/* Cancel Date - Used (1m ~ 5y 10m) - Sub (red/green dot) - Member (red/green dot) - Customer Name - Reason */}
                      <TableCell>Canceled</TableCell>
                      <TableCell>Used</TableCell>
                      <TableCell>Product</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Reason</TableCell>
                      <TableCell>Canceled By</TableCell>
                      <TableCell>Reached</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.cancelFilter && props.cancelFilter.length > 0 ? (
                      props.cancelFilter
                        .slice(
                          props.page * props.rowsPerPage,
                          props.page * props.rowsPerPage + props.rowsPerPage
                        )
                        .map((cancel) => {
                          return (
                            <TableRow key={cancel.id}>
                              <TableCell
                                onClick={() => {
                                  handleClickOpen(cancel);
                                }}
                                component="th"
                                scope="row"
                              >
                                {moment
                                  .unix(cancel.createdAt)
                                  .format("hh:mm a  MM/DD/YYYY")}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  handleClickOpen(cancel);
                                }}
                                component="th"
                                scope="row"
                              >
                                {cancel.subCreatedAt
                                  ? moment(cancel.createdAt * 1000)
                                      .diff(
                                        moment(cancel.subCreatedAt * 1000),
                                        `${
                                          cancel.createdAt -
                                            cancel.subCreatedAt >
                                          31556926
                                            ? "years"
                                            : cancel.createdAt -
                                                cancel.subCreatedAt >
                                              2629743
                                            ? "months"
                                            : "days"
                                        }`,
                                        cancel.createdAt - cancel.subCreatedAt >
                                          31556926
                                      )
                                      .toFixed(
                                        cancel.createdAt - cancel.subCreatedAt >
                                          31556926
                                          ? 1
                                          : 0
                                      ) +
                                    `${
                                      cancel.createdAt - cancel.subCreatedAt >
                                      31556926
                                        ? " y"
                                        : cancel.createdAt -
                                            cancel.subCreatedAt >
                                          2629743
                                        ? " m"
                                        : " d"
                                    }`
                                  : "No Data"}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  handleClickOpen(cancel);
                                }}
                                component="th"
                                scope="row"
                                sx={{
                                  ...(cancel.canceledProduct ===
                                    "MEMBERSHIP" && { color: "#62b575" }),
                                }}
                              >
                                {cancel.canceledProduct}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  handleClickOpen(cancel);
                                }}
                                component="th"
                                scope="row"
                              >
                                {cancel.member?.name}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  handleClickOpen(cancel);
                                }}
                                component="th"
                                scope="row"
                              >
                                {cancel.cancelReason &&
                                cancel.cancelReason.length > 35
                                  ? cancel.cancelReason.slice(0, 32) + "..."
                                  : cancel.cancelReason}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  handleClickOpen(cancel);
                                }}
                                component="th"
                                scope="row"
                              >
                                {cancel.canceledBy.type === MEMBER ? (
                                  <Typography>
                                    <PassiveButton color="green" text="User" />
                                  </Typography>
                                ) : (
                                  <PassiveButton
                                    color="red"
                                    text={"Admin:" + cancel.canceledBy.name}
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  cancel.reached.status &&
                                    handleClickOpen(cancel);
                                }}
                                component="th"
                                scope="row"
                              >
                                <CheckButton
                                  data={cancel.reached}
                                  checkReach={props.checkReach}
                                  refetch={props.refetch}
                                  id={cancel.id}
                                />
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  handleClickOpen(cancel);
                                }}
                                component="th"
                                scope="row"
                              >
                                {/* {cancel.canceledProduct === "MEMBERSHIP" && (
                                  <CancelMembershipDialog
                                    memberId={cancel.member.id}
                                    cancelId={cancel.id}
                                    dialogData={dialogData}
                                    openUp={openMembership}
                                    onContinue={() => {
                                      setOpenMembership(false);
                                      setOpen(true);
                                    }}
                                  />
                                )} */}

                                <Typography>
                                  <PassiveButton
                                    color={getColor(
                                      cancel.cancelRequest?.status
                                    )}
                                    text={
                                      cancel.cancelRequest?.status &&
                                      cancel.cancelRequest?.status ===
                                        "APPROVED"
                                        ? "CANCELED"
                                        : cancel.cancelRequest?.status
                                        ? cancel.cancelRequest?.status
                                        : "CANCELED-OLD"
                                    }
                                  />
                                </Typography>
                              </TableCell>

                              {/* Note button here =>
                              <TableCell component="th" scope="row">
                                <Typography>
                                  <NoteButton
                                    activeAdmin={props.activeAdmin}
                                    loading={props.loadingSave}
                                    saveNote={props.saveNote}
                                    refetch={props.refetch}
                                    id={cancel.id}
                                    note={cancel.note}
                                    color={
                                      cancel.note.message ? "green" : "red"
                                    }
                                    text={cancel.note.message ? "See" : "Write"}
                                  />
                                </Typography>
                              </TableCell> */}

                              {/* <TableCell
                                component="th"
                                align="left"
                                scope="row"
                              >
                                {cancel.member.created_at
                                  ? moment
                                      .unix(cancel.member.created_at)
                                      .format("MM/DD/YYYY")
                                  : "No Data"}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {cancel.member.name}
                              </TableCell>
                              <TableCell
                                component="th"
                                align="left"
                                scope="row"
                              >
                                {cancel.member.email}
                              </TableCell>

                              <TableCell
                                component="th"
                                align="left"
                                scope="row"
                              >
                                {cancel.canceledBy &&
                                cancel.canceledBy.type === "MEMBER" ? (
                                  <PassiveButton color="green" text="MEMBER" />
                                ) : cancel.canceledBy.name ? (
                                  <PassiveButton
                                    color="red"
                                    text={"Admin:" + cancel.canceledBy.name}
                                  />
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell
                                component="th"
                                align="left"
                                scope="row"
                              >
                                {cancel.dontCancel === null ? (
                                  <PassiveButton color="red" text="Cancelled" />
                                ) : cancel.dontCancel === false &&
                                  cancel.cancelAt < time ? (
                                  <PassiveButton color="red" text="Cancelled" />
                                ) : cancel.dontCancel === true ? (
                                  <PassiveButton
                                    color="green"
                                    text="Continue"
                                  />
                                ) : (
                                  <PassiveButton
                                    color="red"
                                    text="end of the cycle"
                                  />
                                )}
                              </TableCell> */}
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </ul>
          <TablePagination
            rowsPerPageOptions={
              props.cancelsCount < 25
                ? [10]
                : props.cancelsCount >= 10 && props.cancelsCount <= 100
                ? [10, 25]
                : [10, 25, 100]
            }
            component="div"
            count={props.cancelsCount ? props.cancelsCount : 0}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const PassiveButton = (props) => {
  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      className={
        props.color === "green"
          ? "greenborder"
          : props.color === "orange"
          ? "orangeborder"
          : props.color === "blue"
          ? "blueborder"
          : "redborder"
      }
      disabled
    >
      <b className="redborder">{props.text}</b>
    </Button>
  );
};

const NoteButton = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [edit, setEdit] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  useEffect(() => {
    if (props.note.message) {
      setMessage(props.note.message);
    }
  }, [props]);

  const onClick = () => {
    props
      .saveNote({
        variables: {
          id: props.id,
          input: {
            message: message,
            createdAt: moment().unix(),
          },
        },
      })
      .then((data) => {
        enqueueSnackbar(`Note Saved Successfully`, {
          variant: "success",
        });
        handleClose();
        props.refetch();
      })
      .catch((e) => {
        enqueueSnackbar(`${e.message}`, {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        className={props.color === "green" ? "greenborder" : "redborder"}
        onClick={() => setOpen(true)}
      >
        <b className="redborder">{props.text}</b>
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid className="badgemenu">
              <Grid item xs={12} className="member-data">
                <Typography style={{ marginBottom: "15px" }} textAlign={"left"}>
                  Note:
                  <br />
                  <br />
                  {props.note.admin && props.note.admin.email && (
                    <TextField
                      fullWidth
                      disabled
                      id="outlined-disabled"
                      label="Admin"
                      defaultValue={props.note.admin.email}
                      variant="outlined"
                      sx={{ marginTop: "10px" }}
                    />
                  )}
                  {props.note.createdAt && (
                    <TextField
                      fullWidth
                      disabled
                      id="outlined-disabled"
                      label="Admin"
                      defaultValue={moment(props.note.createdAt * 1000).format(
                        "MM/DD/YYYY"
                      )}
                      variant="outlined"
                      sx={{ marginTop: "10px" }}
                    />
                  )}
                  <TextField
                    fullWidth
                    disabled={
                      props.note.message &&
                      props.note.message.length > 0 &&
                      !edit
                        ? true
                        : false
                    }
                    id="outlined-disabled"
                    label="Message"
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    variant="outlined"
                    sx={{ marginTop: "10px" }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {props.note.admin?.id &&
            props.note.admin?.id === props.activeAdmin.id &&
            !edit && <Button onClick={() => setEdit(true)}>Edit</Button>}
          {props.note.admin && props.note.admin.email && !edit ? (
            ""
          ) : (
            <Button onClick={onClick}>
              {props.loading ? <CircularProgress /> : "Save"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const CheckButton = (props) => {
  console.log("props", props);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    props
      .checkReach({
        variables: {
          id: props.id,
          createdAt: moment().unix(),
        },
      })
      .then((data) => {
        enqueueSnackbar(`Checked Successfully`, {
          variant: "success",
        });
        handleClose();
        props.refetch();
      })
      .catch((e) => {
        enqueueSnackbar(`${e.message}`, {
          variant: "error",
        });
      });
  };

  return (
    <>
      <FormGroup
        sx={{
          alignItems: "center",
          "& label": {
            marginRight: "0px",
          },
        }}
      >
        <FormControlLabel
          onClick={() => {
            !props.data.status && setOpen(true);
          }}
          control={
            <Checkbox
              checked={props.data.status}
              sx={{
                ...(props.data.status && {
                  color: "#62b575",
                  "&.Mui-checked": {
                    color: "#62b575",
                  },
                }),
              }}
            />
          }
          label={
            props.data.admin && props.data.admin.name
              ? props.data.admin.name
              : ""
          }
        />
      </FormGroup>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid className="badgemenu">
              <Typography>Do you want to save it?</Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>

          <Button onClick={onClick}>
            {props.loading ? <CircularProgress /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Archive;

const CancelSub = ({
  confirmCancelSub,
  isCancel,
  loadingChange,
  isMembership = false,
}) => {
  const [open, setOpen] = React.useState(false);

  const [cancelAtTheEnd, setCancelAtTheEnd] = useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [reason, setReason] = React.useState("");

  const handleChangeReason = (event) => {
    setReason(event.target.value);
  };

  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      {!isCancel ? (
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleClickOpen}
          className="greenborder"
          disabled={loadingChange}
        >
          {loadingChange ? <CircularProgress /> : "Approve"}
        </Button>
      ) : (
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          className="redborder"
          disabled={loadingChange}
        >
          {loadingChange ? <CircularProgress /> : "Reject"}
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {!isCancel
            ? "Are you sure you want to cancel subscription ?"
            : "Are you sure you want to REJECT the cancel request ?"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogContent>
          {!isCancel && (
            <TextField
              id="outlined-multiline-static"
              label="Note"
              multiline
              maxRows={4}
              rows={4}
              value={value}
              sx={{ height: "125px", width: "100%" }}
              onChange={handleChange}
              placeholder="Please take a note more than 5 letters."
            />
          )}
          {!isCancel && !isMembership && (
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    value={cancelAtTheEnd}
                    onClick={() => {
                      setCancelAtTheEnd(!cancelAtTheEnd);
                    }}
                  />
                }
                label="Cancel at the end of the period"
              />
            </DialogContentText>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            onClick={() => {
              console.log(isCancel);
              if (!isCancel) {
                if (value.length === 0) {
                  enqueueSnackbar("Please choose a reason.", {
                    variant: "error",
                  });
                } else if (value.length <= 5) {
                  enqueueSnackbar(
                    "Please write a reason more than 5 letters.",
                    {
                      variant: "error",
                    }
                  );
                } else {
                  confirmCancelSub(cancelAtTheEnd, value) && handleClose();
                }
              } else {
                confirmCancelSub(cancelAtTheEnd, value) && handleClose();
              }
            }}
            variant="outlined"
            color="primary"
            autoFocus
            className="redborder"
            disabled={loadingChange}
          >
            {loadingChange ? <CircularProgress /> : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
