import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BookOnlineIcon from "@mui/icons-material/BookOnline";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  GET_AVAILABLEDAYS_SUPPORTMEMBER,
  GET_SUPPORT_MEMBER_SCHEDULE,
  MAKE_APPOINTMENT,
} from "../../../../../queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  DatePickerComponent,
  ListOffAppointments,
  UpdateAppointmentDialog,
} from "../../AssignedConsultation";
import moment from "moment";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import LinearLoading from "../../../LinearLoading";

const steps = ["Select Date", "Select Time ", "Summary"];

export default function AssignConsultation({ customer }) {
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedTime, setSelectedTime] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    if (customer.premium !== 1) {
      enqueueSnackbar("Customer does not have membership", {
        variant: "error",
      });
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    if (!assingAppointment) {
      setOpen(false);
    }
  };

  const onClickPrev = () => {
    setActiveStep((current) => current - 1);
  };
  const onClickNext = () => {
    if (activeStep === 2) {
      onAssingUserToAppointment();
      return;
    }
    setActiveStep((current) => current + 1);
  };

  useEffect(() => {
    if (activeStep === 1) {
      setSelectedTime({});
    }
  }, [activeStep]);
  const [assingUserToAppointment, { loading: assingAppointment }] =
    useMutation(MAKE_APPOINTMENT);

  const onAssingUserToAppointment = () => {
    assingUserToAppointment({
      variables: {
        customerId: customer.id,
        scheduleId: selectedTime.id,
        reserve: true,
      },
    })
      .then((res) => {
        enqueueSnackbar("Assinged Successfully", { variant: "Success" });
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Could not Assinged", { variant: "error" });
      });
  };

  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        onClick={handleClickOpen}
        style={{
          color: "#24a3bb",
          borderColor: "#24a3bb",
        }}
        startIcon={<BookOnlineIcon />}
      >
        Book consult
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        {assingAppointment && LinearLoading}
        <DialogTitle id="alert-dialog-title">{"Consultation"}</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <SelectDate
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                accountManager={customer.accountManager}
              />
            )}
            {activeStep === 1 && (
              <SelectTime
                accountManager={customer.accountManager}
                selectedDate={selectedDate}
                setSelectedTime={(data) => {
                  console.log(data);
                  onClickNext();
                  setSelectedTime(data);
                }}
              />
            )}
            {activeStep === 2 && (
              <Summary
                accountManager={customer.accountManager}
                selectedDate={selectedDate}
                selectedTime={selectedTime}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              float: "left",
              color: "red",
              borderColor: "red",
            }}
            onClick={handleClose}
            disabled={assingAppointment}
          >
            Cancel
          </Button>
          <Button
            onClick={onClickPrev}
            startIcon={<SkipPreviousIcon />}
            disabled={activeStep === 0 || assingAppointment}
          >
            Previous
          </Button>
          <Button
            onClick={onClickNext}
            endIcon={<SkipNextIcon />}
            autoFocus
            disabled={
              (activeStep === 1 && Object.keys(selectedTime).length === 0) ||
              assingAppointment
            }
          >
            {activeStep === 2 ? "Finish" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const SelectDate = ({ accountManager, selectedDate, setSelectedDate }) => {
  const [availableDates, setAvailableDates] = useState([]);
  const {
    loading,
    error,
    data: availableDatesdata,
  } = useQuery(GET_AVAILABLEDAYS_SUPPORTMEMBER, {
    variables: {
      supportMemberId: accountManager.id,
    },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (availableDatesdata) {
      setAvailableDates(
        availableDatesdata.getCalendarAppointmentsOfSupporMember
      );
      if (availableDatesdata.getCalendarAppointmentsOfSupporMember.length > 0) {
        let dates = JSON.parse(
          JSON.stringify(
            availableDatesdata.getCalendarAppointmentsOfSupporMember
          )
        ).map((item) => item);
        // .sort((a, b) => new Date(a.day) - new Date(b.day));
        setSelectedDate(
          dates.filter((item) => new Date(item.day) >= new Date())[0] || {}
        );
      }
    }
  }, [availableDatesdata]);
  function disableWeekends(date) {
    const available = availableDates.find(
      (item) => moment(item.day).format("L") === moment(date).format("L")
    );
    return !available;
  }
  if (loading) {
    return <LinearLoading />;
  }
  if (error) {
    return <div></div>;
  }
  return (
    <div>
      {availableDates.length === 0 && <Typography>No item found</Typography>}
      <DatePickerComponent
        disableWeekends={disableWeekends}
        availableDates={availableDates}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        previousOff={true}
      />
    </div>
  );
};

const SelectTime = ({ selectedDate, accountManager, setSelectedTime }) => {
  const [schedules, setSchedules] = useState([]);
  const [
    fetchSumpportMemberSchedule,
    {
      loading: scheduleLoading,
      data: scheduleData,
      refetch: refetchSupportMemberSchedule,
    },
  ] = useLazyQuery(GET_SUPPORT_MEMBER_SCHEDULE, {
    variables: {
      supportMemberId: accountManager.id,
      date: selectedDate.day,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (Object.keys(selectedDate).length > 0) {
      fetchSumpportMemberSchedule()
        .then((res) => {
          if (res.data.getSupportMemberSchedules.length > 0)
            setSchedules(res.data.getSupportMemberSchedules[0].schedules);
        })
        .catch((e) => {});
    }
  }, [selectedDate]);
  const selectCell = (data) => {
    setSelectedTime(data);
  };
  if (scheduleLoading) {
    return <LinearProgress />;
  }
  return (
    <div>
      <Grid container spacing={1}>
        {schedules.length === 0 && <Typography>No item found</Typography>}
        {schedules.map((schedule) => {
          return (
            <Grid item md={4}>
              <UpdateAppointmentDialog
                item={schedule}
                onSelect={() => {
                  selectCell(schedule);
                }}
                onlySelect={true}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const Summary = ({ accountManager, selectedDate, selectedTime }) => {
  return (
    <div
      style={{
        padding: 20,
      }}
    >
      <Typography>
        <b>Account Manager:</b> {accountManager.name} {accountManager.surname}{" "}
      </Typography>
      <Typography>
        <b>Date:</b> {selectedDate.day}
      </Typography>
      <Typography>
        <b>Time:</b> {selectedTime.dateString}
      </Typography>
    </div>
  );
};
