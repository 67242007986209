import { useLazyQuery } from "@apollo/client";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import React, { Component, useEffect, useRef, useState } from "react";

import { QUESTIONS_FIND } from "../../../../queries";

import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { MULTIPLE_CHOICE } from "../../../../globals";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "16px",
    marginBottom: "8px",
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function AutoSearch(props) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [dataset, setDataset] = useState([]);
  const isInitialMount = useRef(true);
  const [open, setOpen] = useState(false);
  const [getArticles, { loading: loadingSearch, data: searchData }] =
    useLazyQuery(QUESTIONS_FIND, {
      fetchPolicy: "no-cache",
      variables: {
        key: search,
        questionType,
      },
      onCompleted: (data) => {
        if (data) {
          console.log(data);
          setDataset(data.questionsFind);
        }
      },
    });

  useEffect(() => {
    if (!isInitialMount.current) {
      getArticles();
      isInitialMount.current = true;
    }
  }, [search, questionType]);

  const searchInput = (event) => {
    const { value } = event.target;
    if (value.length > 2) {
      isInitialMount.current = false;
      setSearch(value);
    }
  };

  const selectQuestionType = (event) => {
    const { value } = event.target;
    if (value) {
      setQuestionType(value);
      setOpen(true);
      isInitialMount.current = false;
    }
  };

  const onMouseLeave = (event) => {
    setOpen(false);
  };

  const onMouseOver = (event) => {
    setOpen(true);
  };

  return (
    <AutoSearcUI
      history={props.history}
      edit={props.edit}
      searchInput={searchInput}
      classes={classes}
      dataset={dataset}
      search={search}
      questionType={questionType}
      selectQuestionType={selectQuestionType}
      loadingSearch={loadingSearch}
      open={open}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      refetch={props.refetch}
      setSelectedQuestion={props.setSelectedQuestion}
    />
  );
}

export class AutoSearcUI extends Component {
  render() {
    const {
      searchInput,
      dataset,
      search,
      history,
      loadingSearch,
      edit,
      questionType,
      selectQuestionType,
      classes,
      open,
      onMouseLeave,
      onMouseOver,
      refetch,
      setSelectedQuestion,
    } = this.props;
    return (
      <Grid
        container
        spacing={1}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <Grid item xs={9}>
          <Autocomplete
            id="REALDOG - REAL BLOG SEARCH"
            noOptionsText={"Autocomplete Search Feature"}
            loading={loadingSearch}
            options={dataset}
            valueInput={search}
            getOptionLabel={(option) => option.question}
            open={open}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <div className="searchresult">
                  <b>{option.question} </b>
                  {option.questionType === MULTIPLE_CHOICE ? (
                    <FormatListNumberedIcon
                      style={{
                        color: "white",
                      }}
                    />
                  ) : (
                    <SpaceBarIcon
                      style={{
                        color: "white",
                      }}
                    />
                  )}{" "}
                  - <span>{option.status ? "Active" : "Draft"}</span>
                </div>
              </li>
            )}
            onChange={(e, value) => {
              let newValue = value || "";
              setSelectedQuestion(newValue);
            }}
            renderInput={(articles) => (
              <TextField
                {...articles}
                label="Search Questions"
                margin="normal"
                onChange={searchInput}
                variant="outlined"
                InputProps={{
                  ...articles.InputProps,
                  value: search,
                  type: "search",
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Filter Questions
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={questionType}
              onChange={selectQuestionType}
            >
              <MenuItem value={"MULTIPLE_CHOICE"}>MULTIPLE CHOICE</MenuItem>
              <MenuItem value={"QUESTION_ANSWER"}>QUESTION ANSWER</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

export default AutoSearch;
