import { Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import DetailComponentHintEditor from "../../../../assets/editorHints/image-column-editor.jpg";

const DetailComponent = ({ keys, color, title = "" }) => {
  let colorText = "#" + color;
  return (
    <div>
      <Container
        style={{
          backgroundColor: colorText,
          padding: "40px 70px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <img
                style={{ width: 70 }}
                src="https://s3-us-west-1.amazonaws.com/realdog-s3/static/real-dog-food-logo.png"
                alt="RealDog Logo"
              />
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  marginLeft: 10,
                  lineHeight: "normal",
                }}
              >
                REAL
                <br></br>
                {title.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              style={{
                fontWeight: "ligther",
                marginLeft: 10,
                lineHeight: "normal",
              }}
            >
              real.dog/box
            </Typography>
          </Grid>
        </Grid>
        <br></br>
        <br></br>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          style={{
            padding: 20,
          }}
        >
          {keys.map((key) => {
            return (
              <Grid item xs={4}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <img
                      style={{
                        width: "100%",
                      }}
                      src={key.img}
                      alt="detail icon"
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      {key.text}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <br></br>
        <br></br>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                marginLeft: 10,
                lineHeight: "normal",
              }}
            >
              #feedreal
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              style={{
                fontWeight: "ligther",
                marginLeft: 10,
                lineHeight: "normal",
              }}
            >
              San Diego, CA
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DetailComponent;

export const DetailComponentInfoDialog = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <InfoIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent>
          <img style={{ width: "100%" }} src={DetailComponentHintEditor}></img>
          <Typography>
            You can add 4 photos by clicking "Add Image Column".(Photos will
            responsibly fit on the page.)
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
