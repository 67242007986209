import {
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ClearIcon from "@mui/icons-material/Clear";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import Switch from "@mui/material/Switch";

export default function WorkshopDialog({ workshop, open, handleOpen, onEdit }) {
  console.log(workshop);

  const [_workshop, _setWorkShop] = useState(workshop);

  const [_canEdit, _setCanEdit] = useState(false);

  const convertUnixToDateTime = (unix) => {
    if (typeof unix === "object") return unix;
    const date = new Date(unix * 1000);
    const timeString = new Date(
      date.getTime() + date.getTimezoneOffset() * -60 * 1000
    )
      .toISOString()
      .slice(0, 19);
    return timeString;
  };

  const convertDateToUnix = (date) => {
    if (typeof date === "number") return date;
    return Math.floor(new Date(date).getTime() / 1000);
  };

  const handleAddTime = () => {
    _setWorkShop((prevState) => {
      let currentState = Object.assign({}, prevState);
      let newDate = new Date();
      newDate.setDate(newDate.getDate() + 3);
      let time = Math.floor(newDate.getTime() / 1000); // get last

      currentState.times.push({
        startTime: time,
        endTime: time,
        quota: 8,
        status: false,
      });
      return currentState;
    });
    _setCanEdit(true);
  };

  const handleQuotaChange = (event, index) => {
    const { name, value } = event.target;
    _setWorkShop((prevState) => {
      let currentState = Object.assign({}, prevState);

      currentState.times[index][name] = parseInt(value, 10);
      return currentState;
    });
    _setCanEdit(true);
  };

  const handleTimeChange = (event, index) => {
    _setWorkShop((prevState) => {
      let currentState = Object.assign({}, prevState);
      currentState.times[index]["startTime"] = event;

      return currentState;
    });
    _setCanEdit(true);
  };
  const handleStausChange = (event, index) => {
    const { name, checked } = event.target;
    _setWorkShop((prevState) => {
      let currentState = Object.assign({}, prevState);
      currentState.times[index][name] = checked;
      return currentState;
    });
    _setCanEdit(true);
  };
  const handleComingSoonChange = (event, index) => {
    const { name, checked } = event.target;
    _setWorkShop((prevState) => {
      let currentState = Object.assign({}, prevState);
      currentState.times[index][name] = checked;
      return currentState;
    });
    _setCanEdit(true);
  };
  const handleRemoveTime = () => {
    _setWorkShop((prevState) => {
      let currentState = Object.assign({}, prevState);
      currentState.times.pop();
      return currentState;
    });
    _setCanEdit(true);
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    _setWorkShop((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    _setCanEdit(true);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">{"Update Workshop"}</DialogTitle>
        <DialogContent
          style={{
            paddingTop: 20,
          }}
        >
          <DialogContentText id="alert-dialog-description">
            <TextField
              fullWidth
              label="Title"
              name="name"
              value={_workshop.name}
              onChange={(e) => {
                handleOnChange(e);
              }}
              style={{
                marginBottom: 20,
              }}
            />
            <TextField
              fullWidth
              name="description"
              label="Course Detail"
              value={_workshop.description}
              onChange={(e) => {
                handleOnChange(e);
              }}
              style={{
                marginBottom: 20,
              }}
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-amount">
                Amount
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={_workshop.price}
                name="price"
                type={"number"}
                onChange={(e) => {
                  handleOnChange(e);
                }}
                style={{
                  marginBottom: 20,
                }}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
              />
            </FormControl>

            <br></br>

            {_workshop.times.map((item, index) => {
              return (
                <div>
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginBottom: "20px" }}
                    alignItems={"center"}
                    border={"1"}
                  >
                    <Grid item xs={6}>
                      <FormControlLabel
                        sx={{
                          marginTop: "10px",
                        }}
                        control={
                          <ClearIcon
                            color="error"
                            onClick={() => handleRemoveTime()}
                          />
                        }
                        label=""
                      />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label="Workshop time"
                          InputProps={{ name: "startTime" }}
                          value={convertUnixToDateTime(item.startTime)}
                          onChange={(e) => handleTimeChange(e, index)}
                          renderInput={(params) => {
                            return <TextField {...params} />;
                          }}
                          minDate={dayjs(new Date().toLocaleDateString())}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        id="outlined-number"
                        label="Quota"
                        placeholder="Quota"
                        name="quota"
                        type="number"
                        value={item.quota}
                        onChange={(e) => handleQuotaChange(e, index)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="status"
                            checked={item.status}
                            onChange={(e) => handleStausChange(e, index)}
                            color={"secondary"}
                          />
                        }
                        label="Active"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="comingSoon"
                            color={"secondary"}
                            checked={item.comingSoon}
                            onChange={(e) => handleComingSoonChange(e, index)}
                          />
                        }
                        label="Coming soon"
                      />
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </DialogContentText>
          <DialogContentText
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => handleAddTime()} variant="outlined">
              <AddIcon color="success" />
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen}>cancel</Button>
          <Button
            onClick={() => {
              const currentWorkshop = Object.assign({}, _workshop);
              const outputWorkshop = {
                ...currentWorkshop,
                times: currentWorkshop.times.map((t) => {
                  return {
                    ...t,
                    startTime: convertDateToUnix(t.startTime),
                  };
                }),
              };
              onEdit(outputWorkshop);
            }}
            disabled={!_canEdit}
          >
            {"Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
