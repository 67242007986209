import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CANCEL_WORKSHOP } from "../../../../../queries";
import { useSnackbar } from "notistack";

export default function CancelWorkshop({ workshop, refetch }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);

  const [cancelWorkshop, { loading }] = useMutation(CANCEL_WORKSHOP);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (loading) {
      return;
    }
    setOpen(false);
  };

  const confirmCancel = () => {
    cancelWorkshop({
      variables: {
        id: workshop.id,
      },
    })
      .then((res) => {
        console.log(res);
        enqueueSnackbar(`Workshop cancelled successfully`, {
          variant: "success",
        });
        handleClose();
        refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const current = parseInt(Date.now() / 1000);
  const startTime = workshop.startTime;
  const passed =
    startTime < current ||
    workshop.info === "cancelled" ||
    workshop.info === "rescheduled";
  if (passed) {
    return <div></div>;
  }
  return (
    <div>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <Delete />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel customer's workshop
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            close
          </Button>
          <Button disabled={loading} onClick={confirmCancel} autoFocus>
            confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
