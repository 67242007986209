import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { GET_SECRETSHOP_PRODUCT, SS_MULTIPLEPUBLISH } from "../../../queries";
import moment from "moment";
import { TextField } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000",
    position: "absolute",
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}
// valueGetter: (data) => {
//   if (!data.row.metadata) {
//     return "Inactive";
//   }
//   if (
//     data.row.metadata.show &&
//     data.row.metadata.show.toLowerCase() === "secretshop"
//   ) {
//     return "Published";
//   }
//   return "Inactive";
// },
function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const DataTable = () => {
  const { data, loading, error, refetch } = useQuery(GET_SECRETSHOP_PRODUCT, {
    fetchPolicy: "no-cache",
  });

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [premiumCountDown, setPremiumCountDown] = useState(false);

  const [publishMultipleMutation, { loading: publishLoading }] =
    useMutation(SS_MULTIPLEPUBLISH);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  useEffect(() => {
    if (data) {
      let leftItems = [];
      let rightItems = [];
      let productsCopy = JSON.parse(
        JSON.stringify(data.getSecretshopProducts)
      ).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      productsCopy.forEach((singleItem) => {
        if (!singleItem.metadata) {
          leftItems.push(singleItem);
          return;
        }
        if (
          singleItem.metadata?.show &&
          singleItem.metadata?.show.toLowerCase() === "secretshop"
        ) {
          rightItems.push(singleItem);
          return;
        }
        leftItems.push(singleItem);
      });
      setLeft(leftItems);
      setRight(rightItems);
    }
  }, [data, loading, error]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const returnStatus = (item) => {
    if (!item.metadata) {
      return "Inactive";
    }
    if (
      item.metadata?.show &&
      item.metadata?.show.toLowerCase() === "secretshop"
    ) {
      return "Published";
    }
    return "Inactive";
  };
  const customList = (items, side) => (
    <Paper
      sx={{
        width: "100%",
        height: "80vh",
        overflow: "auto",
        border: "1px solid",
        borderColor: side === "left" ? "red" : "green",
      }}
    >
      <List dense component="div" role="list">
        {items.map((item) => {
          const labelId = `transfer-list-item-${item}-label`;
          const nameStr = `${item.name}-${item.description?.substring(0, 15)}-${
            item.status
          }`;

          return (
            <ListItem
              key={item}
              role="listitem"
              button
              onClick={handleToggle(item)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(item) !== -1}
                  tabIndex={-1}
                  disableRipple
                  color="secondary"
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item md={5}>
                    {item.name}
                  </Grid>
                  <Grid item md={5}>
                    {item.description?.substring(0, 20)}
                  </Grid>
                  <Grid item md={2}>
                    {returnStatus(item) === "Inactive" ? (
                      <Typography style={{ color: "#e91e63" }}>
                        Inactive
                      </Typography>
                    ) : (
                      <Typography style={{ color: "#63b274" }}>
                        Published
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {/* {nameStr} */}
              </ListItemText>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );
  const onPublishMultiple = () => {
    let updatedCountDown = 0;
    const inputArray = right
      .map((item) => {
        return {
          id: item.stripeID,
          publish: true,
        };
      })
      .concat(
        left.map((item) => {
          return {
            id: item.stripeID,
            publish: false,
          };
        })
      );
    if (premiumCountDown) {
      updatedCountDown = moment().unix();
    }
    publishMultipleMutation({
      variables: {
        products: inputArray,
        updatedCountDown: updatedCountDown,
      },
    })
      .then((res) => {
        enqueueSnackbar("Products successfully published", {
          variant: "success",
        });
        refetch();
      })
      .catch((error) => {
        enqueueSnackbar("Products could not published", {
          variant: "error",
        });
      });
  };

  const sortLeft = (type) => {
    const leftCopy = JSON.parse(JSON.stringify(left)).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    if (type === "des") {
      setLeft(leftCopy.reverse());
      return;
    }
    setLeft(leftCopy);
  };
  const sortRight = (type) => {
    const rightCopy = JSON.parse(JSON.stringify(right)).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    if (type === "des") {
      setRight(rightCopy.reverse());
      return;
    }
    setRight(rightCopy);
  };

  const selectAllLeft = () => {
    if (left.every((item) => checked.includes(item))) {
      setChecked([]);
      return;
    }
    setChecked(left);
  };

  const selectAllRight = () => {
    if (right.every((item) => checked.includes(item))) {
      setChecked([]);
      return;
    }
    setChecked(right);
  };
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className="publish_multiple"
    >
      <Backdrop className={classes.backdrop} open={loading || publishLoading}>
        <Typography align="center">
          <CircularProgress color="primary" /> <br></br> Please Wait (this
          process may take some time)
        </Typography>
      </Backdrop>

      <Grid item md={5}>
        {/* <Button
          fullWidth
          onClick={() => {
            sortLeft();
          }}
          color="secondary"
        >
          sort Left
        </Button> */}
        <ToolBar
          selectAll={() => {
            selectAllLeft();
          }}
          isSelected={left.every((item) => checked.includes(item))}
          sortAscending={() => {
            sortLeft();
          }}
          sortDescending={() => {
            sortLeft("des");
          }}
          list={left}
          onSelect={(item) => handleToggle(item).call()}
        />
        <Typography align="center">Will be unpublished</Typography>
        {customList(left, "left")}
      </Grid>
      <Grid item md={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item md={5}>
        {/* <Button
          onClick={() => {
            sortRight();
          }}
          color="secondary"
        >
          sort Right
        </Button> */}
        <ToolBar
          selectAll={() => {
            selectAllRight();
          }}
          isSelected={right.every((item) => checked.includes(item))}
          sortAscending={() => {
            sortRight();
          }}
          sortDescending={() => {
            sortRight("des");
          }}
          list={right}
          onSelect={(item) => {
            handleToggle(item).call();
          }}
        />
        <Typography align="center">Will be published</Typography>
        {customList(right, "right")}
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <ConfirmPublish
            onConfirm={() => {
              onPublishMultiple();
            }}
            premiumCountDown={premiumCountDown}
            handlePremiumCountDownUpdate={() =>
              setPremiumCountDown(!premiumCountDown)
            }
            items={right}
            loading={publishLoading}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DataTable;

const ConfirmPublish = ({
  onConfirm,
  items,
  premiumCountDown,
  handlePremiumCountDownUpdate,
  loading,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const publishList = () => {
    onConfirm();
  };

  return (
    <div>
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Switch
              checked={premiumCountDown}
              onChange={() => handlePremiumCountDownUpdate()}
            />
          }
          label="Premium Countdown"
        />
        <Button variant="outlined" onClick={handleClickOpen}>
          Publish Selected
        </Button>
      </FormGroup>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Caution"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to publish {items.length} items ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button
            onClick={() => {
              publishList();
              handleClose();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ToolBar = ({
  selectAll,
  isSelected,
  sortAscending,
  sortDescending,
  list,
  onSelect,
}) => {
  return (
    <div
      style={{
        padding: 20,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isSelected}
              onClick={() => {
                selectAll();
              }}
            />
          }
          label=""
        />
        <Button onClick={sortAscending}>Sort [A-Z]</Button>
        <Button onClick={sortDescending}>Sort [Z-A]</Button>
        {/* <TextField size="small" /> */}
        <CheckboxesTags list={list} onSelect={onSelect} />
      </Grid>
    </div>
  );
};

const CheckboxesTags = ({ list, onSelect }) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={list}
      sx={{ width: 300 }}
      onChange={(event, newValue) => onSelect(newValue)}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField {...params} label="Search Product" size="small" />
      )}
    />
  );
};
