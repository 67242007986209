import "date-fns";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";

import Backdrop from "@mui/material/Backdrop";
import { ADD_SUBSCRIPTION_TRIAL_PERIOD } from "../../../../../queries";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  backdropShippingCalc: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000",
    position: "absolute",
  },
}));
export default function UpdateCycle({ subscription, refetch }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  console.log("subscription");
  console.log(subscription);

  const [openCycle, setOpenCycle] = useState(false);

  const [addTrialMutation, { loading, data, error }] = useMutation(
    ADD_SUBSCRIPTION_TRIAL_PERIOD
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date(subscription.current_period_end * 1000)
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClickOpenCycle = () => {
    setOpenCycle(true);
  };
  const handleCloseCycle = () => {
    setOpenCycle(false);
  };

  const onConfirmUpdateCycle = () => {
    const unixTime = moment(selectedDate).unix();
    console.log("update cycle", unixTime);
    // return
    addTrialMutation({
      variables: {
        id: subscription.id,
        input: {
          trial_end: unixTime,
        },
      },
    })
      .then((res) => {
        enqueueSnackbar(
          `Subscription cycle updated to ${moment(selectedDate).format(
            "MMM Do YY"
          )}`,
          {
            variant: "success",
          }
        );
        handleCloseCycle();
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Button
        fullWidth
        size={"small"}
        variant={"outlined"}
        style={{
          marginTop: 10,
          color: "#24a3bb",
          borderColor: "#24a3bb",
        }}
        onClick={handleClickOpenCycle}
      >
        Update Cycle Date
      </Button>
      <Dialog
        open={openCycle}
        onClose={handleCloseCycle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Select date to update cycle date"}
        </DialogTitle>
        <DialogContent>
          {/* <DatePicker
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
            minDate={new Date(subscription.current_period_end * 1000)}
          ></DatePicker> */}
          <div style={{ height: 10 }}></div>
          <DesktopDatePicker
            minDate={new Date()}
            placeholder={"YYYY-MM-DD"}
            label="Select"
            inputFormat={"yyyy-MM-dd"}
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onConfirmUpdateCycle();
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
        <Backdrop className={classes.backdropShippingCalc} open={loading}>
          <Typography
            variant={"subtitle1"}
            style={{ color: "white" }}
            align={"center"}
          >
            <CircularProgress color="primary" />
            <br />
            Please wait for Subscription pause
          </Typography>
        </Backdrop>
      </Dialog>
    </div>
  );
}
