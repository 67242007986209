import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import NumberFormat from "react-number-format";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import EventNoteIcon from "@mui/icons-material/EventNote";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { CUSTOMER_GET_ORDERS } from "../../../../../queries";
import { useMutation, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment-timezone";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSnackbar } from "notistack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CancelIcon from "@mui/icons-material/Cancel";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginTop: 20,
  },
}));

const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

const ShippingDetails = ({ email }) => {
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateField = (e) => {
    setNote(e.target.value);
  };

  const { loading, error, data, refetch } = useQuery(CUSTOMER_GET_ORDERS, {
    variables: { email },
    fetchPolicy: "no-cache",
  });

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );

  if (error) return <div>Error.</div>;

  return (
    <div>
      <Button
        style={{ zIndex: 2, marginTop: "0px" }}
        fullWidth
        variant="outlined"
        startIcon={<LocalShippingIcon />}
        className="blueborder  btn-text-left btn-text-margin-left"
        onClick={handleClickOpen}
      >
        Shipping History{" "}
        <b className="shippingbtn">{data.getShippingDetail.length}</b>
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {loading ? (
          <div>
            <div>
              <LinearProgress color="secondary" />
            </div>
          </div>
        ) : (
          <div>
            <DialogTitle id="alert-dialog-title">
              {"Shipping History"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  <div>
                    {data &&
                      data.getShippingDetail.map((data) => (
                        <ListItem alignItems="center">
                          <ListItemAvatar>
                            {data.orderStatus &&
                              (data.orderStatus === "awaiting_shipment" ? (
                                <div>
                                  <HourglassEmptyIcon
                                    fontSize="large"
                                    color="info"
                                  />
                                </div>
                              ) : data.orderStatus === "shipped" ? (
                                <div>
                                  {" "}
                                  <LocalShippingIcon
                                    fontSize="large"
                                    color="success"
                                  />
                                </div>
                              ) : data.orderStatus === "cancelled" ? (
                                <div>
                                  <CancelIcon
                                    fontSize="large"
                                    color="warning"
                                  />
                                </div>
                              ) : (
                                ""
                              ))}
                          </ListItemAvatar>

                          <ListItemText
                            className="shippingdt"
                            secondary={
                              <React.Fragment>
                                <div className="shippingdt_badges">
                                  <span>
                                    {moment(data.orderDate).format(
                                      "YYYY/MM/DD"
                                    )}
                                  </span>
                                  <span className="shippingamount">
                                    Shipping Amount: {data.shippingAmount}
                                  </span>
                                  <span className="shippingweight">
                                    Weight: {data.weight.value} /oz
                                  </span>
                                </div>
                                <div className="shippingnotes">
                                  {data.customerNotes}
                                </div>
                              </React.Fragment>
                            }
                          />

                          {data.orderStatus &&
                            (data.orderStatus === "awaiting_shipment" ? (
                              <div className="shippingdate awaitingdate">
                                Awaiting
                              </div>
                            ) : data.orderStatus === "shipped" ? (
                              <div className="shippingdate">
                                {data.shipDate}
                              </div>
                            ) : data.orderStatus === "cancelled" ? (
                              <div className="shippingdate cancelleddate">
                                Cancelled
                              </div>
                            ) : (
                              ""
                            ))}
                        </ListItem>
                      ))}
                  </div>
                </List>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default ShippingDetails;
