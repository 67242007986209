import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Badge,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation } from "@apollo/client";
import { RESCHEDULE_WORKSHOP } from "../../../../../queries";

import { useSnackbar } from "notistack";
import { AddonItem } from "./addonItem";

export default function RescheduleWorkshop({
  customerId,
  prevId,
  prevStart,
  disabled,
  workshop,
  refetch = () => {},
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [selectedTime, setSelectedTime] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (loading) {
      return;
    }
    setOpen(false);
  };

  const [rescheduleWorkshop, { loading }] = useMutation(RESCHEDULE_WORKSHOP);

  const onResuchedule = () => {
    rescheduleWorkshop({
      variables: {
        customerId: customerId,
        prevId: prevId,
        nextStartTime: selectedTime,
      },
    })
      .then(() => {
        enqueueSnackbar(`Workshop Rescheduled successfully`, {
          variant: "success",
        });
        handleClose();
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <IconButton
        aria-label="edit"
        onClick={handleClickOpen}
        disabled={disabled}
      >
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          {"Reschedule Customer Workshop"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Reminder for admin side only: Users can only reschedule 1 time and
            must request 48 hours prior to workshop date.
          </DialogContentText>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{
              marginTop: 10,
            }}
          >
            {loading && <LinearProgress />}
            {workshop.times
              .filter((item) => item.startTime !== prevStart)
              .map((item) => {
                const date = new Date(item.startTime * 1000);
                return (
                  <AddonItem
                    disabled={loading}
                    item={item}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                  />
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={loading} onClick={onResuchedule} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
