import { useMutation, useQuery } from "@apollo/client";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  CircularProgress,
  Grid,
  LinearProgress,
  Paper,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { MULTIPLE_CHOICE } from "../../../../globals";
import {
  GET_FINAL_ATTENDANCES_ANSWERS,
  UPDATE_FINAL_RESULT,
} from "../../../../queries";
import Alert from "@mui/material/Alert";

export default function ShowResults({ answer, open, closeDialog }) {
  const { enqueueSnackbar } = useSnackbar();

  const [updateResult, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_FINAL_RESULT);

  const { loading, error, data, refetch } = useQuery(
    GET_FINAL_ATTENDANCES_ANSWERS,
    {
      variables: {
        id: answer.id,
      },
    }
  );
  const [answerData, setAnswerData] = useState({});
  const [totalScore, setTotalScore] = useState(0);
  const [note, setNote] = useState("");

  useEffect(() => {
    if (data && data.getFinalQuizAttendancesAnswers) {
      setAnswerData(data.getFinalQuizAttendancesAnswers);
      setNote(data.getFinalQuizAttendancesAnswers.scoreDetail.note);

      data.getFinalQuizAttendancesAnswers.scoreDetail.totalScore &&
        setTotalScore(
          data.getFinalQuizAttendancesAnswers.scoreDetail.totalScore
        );
    }
  }, [data]);
  console.log("total", totalScore);
  const changeScore = (index, score) => {
    let tempAnswerData = JSON.parse(JSON.stringify(answerData));
    let qAnswer = tempAnswerData.questionsAnswers.find((n) => n.id == index);
    qAnswer.score = parseFloat(score) ? parseFloat(score) : 0;

    setAnswerData(tempAnswerData);
  };

  const calculateTotalScore = () => {
    let t = 0;
    for (const q of answerData.questionsAnswers) {
      if (q.score && q.score !== null) {
        t = t + q.score;
      }
    }
    setTotalScore(t);
  };

  useEffect(() => {
    if (answerData && answerData.questionsAnswers && data) {
      calculateTotalScore();
    }
  }, [answerData]);

  const submitResult = () => {
    updateResult({
      variables: {
        id: answer.id,
        input: {
          scoreDetail: {
            note: note,
          },
        },
      },
    })
      .then((res) => {
        refetch();
        enqueueSnackbar("Note saved!", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      });
  };

  const closeHandle = () => {
    closeDialog();
  };
  console.log("data", data);
  return (
    <Dialog onClose={() => closeDialog()} open={open}>
      {loading && <LinearProgress />}

      <DialogTitle>{`${
        answer.owner.email && answer.owner.email
      }'s Answers`}</DialogTitle>
      {data && data.getFinalQuizAttendancesAnswers && (
        <DialogContent
          sx={{ paddingTop: "20px !important", minWidth: "400px" }}
        >
          <Paper
            style={{
              marginBottom: 20,
              backgroundColor: "#252525",
              position: "relative",
              padding: "20px 8px",
            }}
            className={"every-child-margin "}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {data?.getFinalQuizAttendancesAnswers &&
              data.getFinalQuizAttendancesAnswers.scoreDetail.pass ? (
                <Alert
                  sx={{ marginBottom: "12px", width: "100%" }}
                  severity="success"
                >
                  Student passed the quiz.
                </Alert>
              ) : (
                <Alert
                  sx={{ marginBottom: "12px", width: "100%" }}
                  severity="error"
                >
                  Student failed the quiz.
                </Alert>
              )}

              <TextField
                fullWidth
                disabled
                value={
                  data?.getFinalQuizAttendancesAnswers &&
                  data.getFinalQuizAttendancesAnswers.scoreDetail.totalScore
                }
                label={"Total Score"}
              />
              <TextField
                fullWidth
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                label={"Note"}
                sx={{ marginTop: "20px" }}
              />
            </Grid>
          </Paper>
          {data.getFinalQuizAttendancesAnswers &&
            answerData.questions &&
            answerData.questions.map((question, index) => {
              if (question.questionType === MULTIPLE_CHOICE) {
                return (
                  <MultipleOptionQuiz
                    id={
                      data.getFinalQuizAttendancesAnswers.questionsAnswers[
                        index
                      ].id
                    }
                    question={question.question}
                    options={question.options}
                    answerIndex={question.answerIndex}
                    attandaceAnswerIndex={
                      data.getFinalQuizAttendancesAnswers.questionsAnswers[
                        index
                      ].customerAnswerIndex
                    }
                    score={answerData.questionsAnswers[index].score}
                    changeScore={changeScore}
                  />
                );
              } else {
                return (
                  <QuestionAnswerOptionQuiz
                    id={
                      data.getFinalQuizAttendancesAnswers.questionsAnswers[
                        index
                      ].id
                    }
                    question={question.question}
                    trueAnswer={question.answer}
                    answer={
                      data.getFinalQuizAttendancesAnswers.questionsAnswers[
                        index
                      ].customerAnswerText
                    }
                    score={answerData.questionsAnswers[index].score}
                    changeScore={changeScore}
                  />
                );
              }
            })}
        </DialogContent>
      )}
      {/* <DialogActions>
        <Button
          disabled={updateLoading || loading || !note || note?.length === 0}
          onClick={submitResult}
        >
          {updateLoading || loading ? (
            <CircularProgress size={20} className="loadingbutton" />
          ) : (
            "Save"
          )}
        </Button>
        <Button onClick={closeHandle}>Close</Button>
      </DialogActions> */}
    </Dialog>
  );
}

const MultipleOptionQuiz = ({
  id,
  question,
  options,
  answerIndex,
  attandaceAnswerIndex,
  score,
  changeScore,
}) => {
  return (
    <Paper
      style={{
        marginBottom: 20,
        backgroundColor: "#252525",
        position: "relative",
        padding: "20px 8px",
      }}
      className={"every-child-margin "}
    >
      <TextField fullWidth label="Question" value={question} disabled={true} />

      {options.map((option, index) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={1}>
              <IconButton
                disabled={true}
                edge="end"
                sx={{ padding: "0px !important" }}
              >
                {attandaceAnswerIndex == index ? (
                  <RadioButtonCheckedIcon
                    className={
                      answerIndex == attandaceAnswerIndex
                        ? "green-fill"
                        : "red-fill"
                    }
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    className={answerIndex == index && "green-fill"}
                  />
                )}
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                disabled={true}
                value={option}
                label={"Option"}
              />
            </Grid>
          </Grid>
        );
      })}
    </Paper>
  );
};

const QuestionAnswerOptionQuiz = ({
  id,
  question,
  answer,
  trueAnswer,
  score,
  changeScore,
}) => {
  return (
    <Paper
      style={{
        marginBottom: 20,
        backgroundColor: "#252525",
        position: "relative",
        padding: "20px 8px",
      }}
      className={"every-child-margin "}
    >
      <TextField fullWidth label="Question" value={question} disabled={true} />
      <TextField
        fullWidth
        label="True Answer"
        value={trueAnswer}
        disabled={true}
      />
      <TextField
        fullWidth
        label="Attendance' Answer"
        value={answer}
        disabled={true}
        sx={{
          ["& fieldset"]: {
            borderColor: "#84ffa44d",
          },
        }}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          fullWidth
          value={score}
          onChange={(e) => {
            const onlyNums = e.target.value.replace(/[^0-9]/g, "");
            changeScore(id, onlyNums);
          }}
          label={"Score"}
        />
      </Grid>
    </Paper>
  );
};
