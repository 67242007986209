import React, { Component, useEffect, useState, useRef } from "react";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import {
  GET_SUPPLIERS,
  ADD_INV_SUPPLIER,
  UPDATE_INV_SUPPLIER,
  GET_INV_SUPPLIER,
  GET_INVENTORY_PRODUCTS,
} from "../../../../queries";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Grid from "@mui/material/Grid";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import { useTheme, useMediaQuery } from "@mui/material";

import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";
import Input from "@mui/material/Input";

import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

function getStyles(name, productName, theme) {
  return {
    fontWeight:
      productName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function ManageSuppliers(props) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  //states
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const classes = useStyles();
  const theme = useTheme();

  const initialStateAddSupplier = {
    name: "",
    company: "",
    location: "",
    type: "",
    phone_number: "",
    cell_number: "",
    email: "",
    note: "",
  };
  // form states
  const [form, setState] = useState(initialStateAddSupplier);
  const [productName, setProductName] = useState([]);

  // modal states
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  //  data id state
  const [supId, setSupId] = useState(null);

  // first mount ref
  const isInitialMount = useRef(true);
  const isInitialMountSup = useRef(true);

  const {
    name,
    company,
    location,
    type,
    phone_number,
    cell_number,
    email,
    products,
    note,
  } = form;

  // props activeAdmin
  const admin_id = props.admin;

  // query get data

  const { loading, error, data, refetch } = useQuery(GET_SUPPLIERS);
  const {
    loading: loadingP,
    error: errorP,
    data: dataP,
    refetch: refetchP,
  } = useQuery(GET_INVENTORY_PRODUCTS);

  // mutations
  const [createSuppliers, { loading: loadingAddP }] =
    useMutation(ADD_INV_SUPPLIER);

  const [updateSuppliers, { loading: loadingAddU }] =
    useMutation(UPDATE_INV_SUPPLIER);

  // get data and setstate
  const [
    getSupplier,
    { loading: loadingGetProd, data: dataGetProd, refetch: refetchProd },
  ] = useLazyQuery(GET_INV_SUPPLIER, {
    variables: {
      id: supId,
    },
    onCompleted: async (data) => {
      resetState();
      setState({
        name: data.inv_supplier.name,
        company: data.inv_supplier.company,
        location: data.inv_supplier.location,
        type: data.inv_supplier.type,
        phone_number: data.inv_supplier.phone_number,
        cell_number: data.inv_supplier.cell_number,
        email: data.inv_supplier.email,
        note: data.inv_supplier.note,
      });
      await setProductName(JSON.parse(data.inv_supplier.products));
      console.log(form);
    },
  });

  useEffect(() => {
    if (!isInitialMount.current) {
      getSupplier();
    }
    isInitialMount.current = true;
  }, [supId]);

  // functions modals
  const handleClickOpen = () => {
    setOpen(true);
    setProductName([]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClickOpen = (id) => {
    setSupId(id);
    setEdit(true);
    isInitialMount.current = false;
  };

  const handleEditClose = () => {
    setEdit(false);
  };

  // reset state
  const resetState = () => {
    setState({ ...initialStateAddSupplier });
  };

  // onChange

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event) => {
    setProductName(event.target.value);
  };
  // add product mutation

  const addSupplierMutation = () => {
    console.log(form);
    createSuppliers({
      variables: {
        name,
        company,
        location,
        type,
        phone_number,
        cell_number,
        email,
        products: JSON.stringify(productName),
        note,
        admin_id,
      },
    }).then(async ({ data }) => {
      handleClose();
      refetch();
      console.log(data);
    });
  };

  const editSupplierMutation = () => {
    updateSuppliers({
      variables: {
        id: supId,
        name,
        company,
        location,
        type,
        phone_number,
        cell_number,
        email,
        products: JSON.stringify(productName),
        note,
        admin_id,
      },
    }).then(async ({ data }) => {
      handleEditClose();
      refetch();
      console.log(data);
    });
  };

  const formValidate = () => {
    return (
      !name ||
      !company ||
      !location ||
      !type ||
      !phone_number ||
      !cell_number ||
      !email ||
      !note
    );
  };

  if (loading || loadingP)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error || errorP) return <div>Error.</div>;

  return (
    <div>
      <div>
        <div>
          <Dialog
            open={edit}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
          >
            {loadingGetProd ? (
              <div>
                <div>
                  <LinearProgress color="secondary" />
                </div>
              </div>
            ) : (
              <div>
                <DialogTitle id="form-dialog-title">{form.name}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Edit Product for inventory
                  </DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        autoFocus
                        margin="normal"
                        value={form.name}
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        onChange={updateField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        autoFocus
                        margin="normal"
                        value={form.email}
                        name="email"
                        label="Email"
                        type="text"
                        fullWidth
                        onChange={updateField}
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    variant="outlined"
                    autoFocus
                    margin="normal"
                    value={form.company}
                    name="company"
                    label="Company Name"
                    type="text"
                    fullWidth
                    onChange={updateField}
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        autoFocus
                        margin="normal"
                        value={form.location}
                        name="location"
                        label="Location"
                        type="text"
                        fullWidth
                        onChange={updateField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        autoFocus
                        margin="normal"
                        value={form.type}
                        name="type"
                        label="Type"
                        type="text"
                        fullWidth
                        onChange={updateField}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        autoFocus
                        margin="normal"
                        value={form.phone_number}
                        name="phone_number"
                        label="Phone Number"
                        type="text"
                        fullWidth
                        onChange={updateField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        autoFocus
                        margin="normal"
                        value={form.cell_number}
                        name="cell_number"
                        label="Cell Number"
                        type="text"
                        fullWidth
                        onChange={updateField}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}></Grid>
                  </Grid>
                  <FormControl fullWidth>
                    <InputLabel id="demo-mutiple-name-label">
                      Products
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-name-label"
                      id="demo-mutiple-name"
                      multiple
                      value={productName}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                    >
                      {dataP.inv_products.map((products) => (
                        <MenuItem
                          key={products.id}
                          value={products.name}
                          style={getStyles(products.name, productName, theme)}
                        >
                          {products.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    autoFocus
                    multiline
                    rows={4}
                    margin="normal"
                    value={form.note}
                    name="note"
                    label="Note"
                    type="text"
                    fullWidth
                    onChange={updateField}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleEditClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={editSupplierMutation} color="secondary">
                    Update
                  </Button>
                </DialogActions>
              </div>
            )}
          </Dialog>
        </div>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
          >
            <DialogTitle id="form-dialog-title">Add Supplier</DialogTitle>
            <DialogContent>
              <DialogContentText>Add Supplier for inventory</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    margin="normal"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    onChange={updateField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    margin="normal"
                    name="email"
                    label="Email"
                    type="text"
                    fullWidth
                    onChange={updateField}
                  />
                </Grid>
              </Grid>
              <TextField
                variant="outlined"
                autoFocus
                margin="normal"
                name="company"
                label="Company Name"
                type="text"
                fullWidth
                onChange={updateField}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    margin="normal"
                    name="location"
                    label="Location"
                    type="text"
                    fullWidth
                    onChange={updateField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    margin="normal"
                    name="type"
                    label="Type"
                    type="text"
                    fullWidth
                    onChange={updateField}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    margin="normal"
                    name="phone_number"
                    label="Phone Number"
                    type="text"
                    fullWidth
                    onChange={updateField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    margin="normal"
                    name="cell_number"
                    label="Cell Number"
                    type="text"
                    fullWidth
                    onChange={updateField}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-mutiple-name-label">
                      Products
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-name-label"
                      id="demo-mutiple-name"
                      multiple
                      value={productName}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                    >
                      {dataP.inv_products.map((products) => (
                        <MenuItem
                          key={products.id}
                          value={products.name}
                          style={getStyles(products.name, productName, theme)}
                        >
                          {products.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <TextField
                variant="outlined"
                autoFocus
                multiline
                rows={4}
                margin="normal"
                name="note"
                label="Note"
                type="text"
                fullWidth
                onChange={updateField}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={addSupplierMutation}
                color="secondary"
                disabled={formValidate()}
              >
                Add Supplier
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <Suppliers
        {...data}
        refetch={refetch}
        open={handleClickOpen}
        edit={handleEditClickOpen}
        getSuppier={getSupplier}
      />
    </div>
  );
}

class Suppliers extends Component {
  componentDidMount() {
    this.props.refetch();
    //console.log(this.props);
    // console.log(this.props);
  }
  render() {
    return (
      <div>
        <ul>
          <div className="right">
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.props.open}
            >
              Add Supplier
            </Button>
            <br />
            <br />
          </div>
          {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.inv_suppliers.map((suppliers) => (
                    <TableRow key={suppliers.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        {suppliers.name}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        {suppliers.company}
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => this.props.edit(suppliers.id)}
                        >
                          <Edit />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </ul>
      </div>
    );
  }
}

export default ManageSuppliers;
