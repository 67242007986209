import { useQuery } from "@apollo/client";
import { InputAdornment, MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { GET_KEYWORDS } from "../../../../queries";
import { KeywordComponentInfoDialog } from "../Components/ButtonLinkComponent";
let URL = "https://feedreal.com/glossary/";

const ButtonLinkEditor = ({ onChangeNodeContent, rawContent }) => {
  const { data, loading, error } = useQuery(GET_KEYWORDS);
  const [text, setText] = useState("");
  const [label, setLabel] = useState("");
  const [link, setLink] = useState("");

  useEffect(() => {
    if (rawContent) {
      let obj = JSON.parse(rawContent);
      setLabel(obj.label);
      setLink(obj.link);
      setText(obj.text);
    }
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      let obj = {
        label,
        link,
        text,
      };
      onChangeNodeContent(JSON.stringify(obj));
    }, 700);
    return () => clearTimeout(timer);
  }, [label, link, text]);

  return (
    <div
      style={{
        position: "relative",
        border: "1px solid #ffffff30",
        padding: 10,
        borderRadius: 5,
      }}
    >
      <Typography>Button Linking Editor</Typography>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <KeywordComponentInfoDialog />
      </div>
      <br></br>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Text"
            variant="outlined"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Button Label"
            variant="outlined"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Link"
            variant="outlined"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default ButtonLinkEditor;
