import React, { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import LinearProgress from "@mui/material/LinearProgress";
import { GET_SETTINGS, SET_SETTINGS } from "../../../queries/index";
import { useSnackbar } from "notistack";

const SettingsDialog = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [permissionAll, setPermissionAll] = useState(false);
  const [getSettings, { data, loading, error }] = useLazyQuery(GET_SETTINGS, {
    variables: {
      type: "SS_CHECKOUT_OPTION",
    },
    onCompleted: (data) => {
      console.log(data);
      if (data && data.getSettingsByType && data.getSettingsByType.options) {
        setId(data.getSettingsByType.id);
        setPermissionAll(data.getSettingsByType.options.setAll ? true : false);
      }
    },
  });
  console.log(id);
  const [setSettings, { loading: setLoading, error: setError }] =
    useMutation(SET_SETTINGS);

  const handleClickOpen = () => {
    getSettings();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setSettings({
      variables: {
        id: id,
        input: {
          options: {
            setAll: permissionAll,
          },
        },
      },
    })
      .then(async ({ data }) => {
        enqueueSnackbar("Updated!", { variant: "success" });
        setOpen(false);
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  return (
    <>
      <Button
        onClick={() => handleClickOpen()}
        sx={{
          ["@media screen and (max-width:760px)"]: {
            display: "block",
          },
        }}
      >
        Settings
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Secretshop Website Settings"}
        </DialogTitle>
        {loading || setLoading ? (
          <LinearProgress />
        ) : data ? (
          <DialogContent>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={permissionAll}
                    onClick={() => {
                      setPermissionAll(!permissionAll);
                    }}
                    color="warning"
                  />
                }
                label={permissionAll ? "All" : "Only Active Subs"}
              />
            </FormGroup>
          </DialogContent>
        ) : (
          "No available settings."
        )}

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={() => handleSubmit()} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SettingsDialog;
