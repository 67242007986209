const Categories = [
  {
    name: "Articles",
    link: "/articles",
  },
  {
    name: "Components of a fresh diet",
    link: "/components-of-a-fresh-diet",
  },
  {
    name: "Foundation recipes",
    link: "/foundation-recipes",
  },
  {
    name: "Supplements",
    link: "/supplements",
  },
  {
    name: "How to Start",
    link: "/how-to-start",
  },
  {
    name: "Allergies",
    link: "/allergies",
  },
  {
    name: "Cancer",
    link: "/cancer",
  },
  {
    name: "Dental Disease",
    link: "/dental-disease",
  },
  {
    name: "Other Ailments",
    link: "/other-ailments",
  },
  {
    name: "Obesity",
    link: "/obesity",
  },
  {
    name: "Glossary",
    link: "/glossary",
  },
  {
    name: "Videos & Books",
    link: "/videos-and-books",
  },
  {
    name: "Shop Raw Food",
    link: "/shop-raw-food",
  },
  {
    name: "Veterinarians",
    link: "/veterinarians",
  },
  {
    name: "Consultations",
    link: "/consultations",
  },
  {
    name: "What is Real Food",
    link: "/what-is-real-food",
  },
  {
    name: "Why Real Food",
    link: "/why-real-food",
  },
  {
    name: "Myths",
    link: "/myths",
  },
];
export default Categories;
