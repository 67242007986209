import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useLazyQuery } from "@apollo/client";
import { CALCULATESHIPPING } from "../../../queries";
import LinearLoading from "../LinearLoading";
import { LinearProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CancelMembershipDialog({
  memberId,
  cancelId,
  dialogData,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [shippingCost, setShippingCost] = React.useState([]);

  const [calculateShipping, { loading, data: dataCalculateShipping }] =
    useLazyQuery(CALCULATESHIPPING, {
      fetchPolicy: "no-cache",
      variables: {
        id: memberId,
      },
      onCompleted: (data) => {
        setShippingCost(data.getRateForCancel);
      },
    });

  const handleClose = () => {
    if (loading) {
      return;
    }
    setOpen(false);
  };
  React.useEffect(() => {
    if (open) {
      calculateShipping();
    }
  }, [open]);

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
      >
        Shipping Rates
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>{"New shipping prices"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {loading && <LinearProgress />}
            {shippingCost.map((x) => (
              <div>
                {x.dogName}'s shipping rate will be{" "}
                {parseFloat(x.rate.shipmentCost).toFixed(2)}.
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
