import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FINAL_EXAM, MULTIPLE_CHOICE, QUIZ_CONTENT } from "../../../../globals";
import { CREATE_QUIZ, GET_SINGLE_QUIZ, UPDATE_QUIZ } from "../../../../queries";
import QuizQuestions from "../../Quiz/components/QuizQuestions";
import QuizSelectQuestion from "../../Quiz/components/QuizSelectQuestion";
import QuizTitle from "../../Quiz/components/QuizTitle";

import { Edit } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const QUIZ_TITLE_MIN_LENGTH = 1;
const QUESTION_OPTIONS_MIN = 2;

const minutes = new Array(100).fill(0).map((item, index) => {
  return {
    value: index + 1,
    label: index + 1,
  };
});

const baseComponent = {
  type: "",
  title: "",
  content: {},
  text: "",
};

export default function FinalQuizDialog({
  addQuiz,
  tutorials,
  disabled,
  update = false,
  id,
}) {
  const [open, setOpen] = useState(false);

  const gotoFinalList = () => {
    history.push(`/dashboard/course/quiz_results`);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  // const { id } = useParams();
  const quizTypeParam = FINAL_EXAM;

  const [countdown, setCountdown] = useState(20);
  const [passScore, setPassScore] = useState(70);
  const [numberOfQuestions, setNumberOfQuestions] = useState(60);

  const [quizType, setQuizType] = useState("");

  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(true);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [getSingleQuiz, { loading: loadingGetSingleQuiz, refetch }] =
    useLazyQuery(GET_SINGLE_QUIZ, {
      variables: {
        input: {
          id,
        },
      },
      fetchPolicy: "no-cache",
      onCompleted: (response) => {
        console.log("RESOINSE ");
        console.log(response);
        const {
          title,
          status,
          tags,
          categories,
          questions,
          quizType,
          countDown,
          passScore,
        } = response.getSingleQuiz;
        setTitle(title);
        setStatus(status);
        setTags(tags);
        setCategories(categories);
        setQuestions(questions);
        setQuizType(quizType);
        setPassScore(passScore);
        setCountdown(countDown / (60 * 1000));
      },
    });

  const [updateQuizMutation, { loading: loadingUpdateQuiz }] = useMutation(
    UPDATE_QUIZ,
    {
      onCompleted: (response) => {
        // refetch();
        enqueueSnackbar("Quiz Updated", {
          variant: "success",
        });
        // refetch();
        handleClose();
      },
    }
  );
  const [createQuizMutation, { loading: loadingCreateQuiz }] = useMutation(
    CREATE_QUIZ,
    {
      onCompleted: (response) => {
        const obj = JSON.parse(JSON.stringify(baseComponent));
        obj.content = { ...response.createQuiz, numberOfQuestions };
        obj.title = response.createQuiz.title;
        obj.type = QUIZ_CONTENT;
        enqueueSnackbar("Quiz Created", {
          variant: "success",
        });
        const copy = JSON.parse(JSON.stringify(tutorials));
        copy.push(obj);
        addQuiz(copy);
        handleClose();
      },
    }
  );
  useEffect(() => {
    if (!id) {
      if (quizTypeParam === FINAL_EXAM) {
        setQuizType(FINAL_EXAM);
      }

      newInitialState();
    } else {
      existingInitialState();
    }
  }, [id, quizTypeParam]);

  useEffect(() => {
    // console.log(title, status, tags, categories, questions);
  }, [title, status, tags, categories, questions]);

  const newInitialState = () => {
    setTitle("");
    setStatus(true);
    setTags([]);
    setCategories([]);
    setQuestions([]);
  };
  const existingInitialState = () => {
    getSingleQuiz();
  };

  const onUpdateQuiz = () => {
    const input = createInput();
    const valid = isValid();
    if (!valid) {
      return;
    }
    updateQuizMutation({
      variables: {
        id,
        input,
      },
    });
  };

  const onCreateQuiz = () => {
    const input = createInput();
    const valid = isValid();
    if (!valid) {
      return;
    }
    createQuizMutation({
      variables: {
        input,
      },
    });
  };

  const createInput = () => {
    const input = {
      title,
      status,
      tags,
      categories,
      questions: questions.map((item) => item.id),
      quizType,
      countDown: countdown * 60 * 1000,
      passScore: parseInt(passScore),
    };
    return input;
  };

  const isValid = () => {
    if (title.length < QUIZ_TITLE_MIN_LENGTH) {
      enqueueSnackbar("Quiz title is not valid (min lenght 10)", {
        variant: "error",
      });
      return false;
    }
    const questionsValid = questions
      .map((item) => item.question || "")
      .every((item) => item.length > QUIZ_TITLE_MIN_LENGTH);
    if (!questionsValid) {
      enqueueSnackbar(`Quiz questions (min lenght ${QUIZ_TITLE_MIN_LENGTH})`, {
        variant: "error",
      });
      return false;
    }
    const optionsValid = questions
      .filter((item) => item.questionType === MULTIPLE_CHOICE)
      .map((item) => item.options)
      .every((item) => item.length >= QUESTION_OPTIONS_MIN);

    if (!optionsValid) {
      enqueueSnackbar("Options Error (min 2 options required)", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const onSetCountdown = (value) => {
    // const obj = JSON.parse(JSON.stringify(tutorial));
    // obj.content.countdown = value;
    // setTutorial(obj);
  };
  const onSetNumberOfQuestions = (value) => {
    // const obj = JSON.parse(JSON.stringify(tutorial));
    // obj.content.numberOfQuestions = value;
    // setTutorial(obj);
  };

  const onAddQuestion = (question) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const existingObj = questions.find((item) => item.id === question.id);
    if (existingObj) {
      enqueueSnackbar("This obj already exist in question list", {
        variant: "info",
      });
      return;
    }
    copy.push(question);
    setQuestions(copy);
  };
  const onDeleteQuestion = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    copy.splice(index, 1);
    setQuestions(copy);
  };

  const onMoveDown = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const temp = copy[index + 1];
    copy[index + 1] = copy[index];
    copy[index] = temp;
    setQuestions(copy);
    enqueueSnackbar("Question moved down", {
      variant: "success",
    });
  };
  const onMoveUp = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const temp = copy[index - 1];
    copy[index - 1] = copy[index];
    copy[index] = temp;
    setQuestions(copy);
    enqueueSnackbar("Question moved up", {
      variant: "success",
    });
  };

  const onSubmitQuiz = () => {
    if (update) {
      onUpdateQuiz();
    } else {
      onCreateQuiz();
    }
  };

  return (
    <div>
      {update ? (
        <>
          <IconButton onClick={handleClickOpen}>
            <Edit />
          </IconButton>
        </>
      ) : (
        <>
          {disabled ? (
            <Button fullWidth variant="outlined" onClick={gotoFinalList}>
              See Final Results
            </Button>
          ) : (
            <Button
              fullWidth
              variant="outlined"
              onClick={handleClickOpen}
              disabled={disabled}
            >
              Final Quiz
            </Button>
          )}
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{"Final Quiz"}</DialogTitle>
        <DialogContent
          style={{
            paddingTop: 10,
          }}
        >
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={9}>
                <QuizTitle title={title} setTitle={setTitle} />
                {/* <br />
                <QuizStatus status={status} setStatus={setStatus} /> */}
                <br />
                <QuizQuestions
                  questions={questions}
                  deleteQ={(index) => {
                    onDeleteQuestion(index);
                  }}
                  onMoveDown={(index) => {
                    onMoveDown(index);
                  }}
                  onMoveUp={(index) => {
                    onMoveUp(index);
                  }}
                />
                <QuizSelectQuestion
                  onConfirmQuestion={(question) => onAddQuestion(question)}
                  quizType={quizType}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Countdown (Minutes)"
                  value={countdown}
                  onChange={(e) => {
                    setCountdown(e.target.value);
                  }}
                  helperText="Please select Countdown"
                  fullWidth
                >
                  {minutes
                    .filter((item) => item.value >= 20)
                    .map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
                <br></br>
                <br></br>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Number Of Questions"
                  value={numberOfQuestions}
                  onChange={(e) => {
                    setNumberOfQuestions(e.target.value);
                  }}
                  fullWidth
                  // helperText={`The percentage you specify is the rate of questions to be selected from the question pool`}
                  helperText={`${parseInt(
                    questions.length * (numberOfQuestions / 100)
                  )} questions will be randomly asked out of ${
                    questions.length
                  } questions `}
                >
                  <MenuItem key={60} value={60}>
                    {`60%`}
                  </MenuItem>
                  <MenuItem key={70} value={70}>
                    {`70%`}
                  </MenuItem>
                  <MenuItem key={80} value={80}>
                    {`80%`}
                  </MenuItem>
                </TextField>
                <br></br>
                <br></br>
                <TextField
                  id="pass-score"
                  label="Pass Score"
                  variant="outlined"
                  value={passScore}
                  type={"number"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setPassScore(e.target.value);
                  }}
                  helperText={`
                  ${parseInt(
                    Math.round(
                      questions.length *
                        (numberOfQuestions / 100) *
                        (passScore * 0.01)
                    )
                  )}/${parseInt(
                    questions.length * (numberOfQuestions / 100)
                  )} questions must be answered correctly.
                  `}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
          <Button
            onClick={() => {
              onSubmitQuiz();
            }}
            autoFocus
          >
            {update ? "Update Final Quiz" : "Add to do unit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
