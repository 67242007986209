import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Edit from "@mui/icons-material/Edit";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FormControl } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  GET_INVENTORY_PRODUCTS,
  CREATE_MONTHLY_BOX,
  GET_MONTHLY_BOX,
} from "../../../../queries";
import AutocompleteCustom from "./components/autocompleteCustom";
import { useSnackbar } from "notistack";
import AddIcon from "@mui/icons-material/Add";
import UpdateTopRefs from "./components/updateTopRefs";

const RandomColors = [
  "#e25065",
  "#804c9e",
  "#3b6fb4",
  "#777677",
  "#ffca02",
  "#63b274",
  "#ef5d72",
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  margin: "3px",
  height: "100%",
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

function MonthlyBoxes(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState();
  const { data, loading, error, refetch } = useQuery(GET_MONTHLY_BOX);
  const [
    getProducts,
    { data: productsData, loading: productsLoading, error: productsError },
  ] = useLazyQuery(GET_INVENTORY_PRODUCTS, {
    onCompleted: (data) => {
      if (data) {
        setProducts(data.inv_products);
      }
    },
  });
  const [
    saveMonthlyBox,
    { data: savedData, loading: saveLoading, error: saveError },
  ] = useMutation(CREATE_MONTHLY_BOX);

  const saveBox = (vars) => {
    saveMonthlyBox({
      variables: {
        input: { ...vars },
      },
    })
      .then(async ({ result }) => {
        console.log(result);
        setOpen(false);
        refetch();
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(`${e.message}`, {
          variant: "error",
        });
      });
  };

  const handleClickOpen = () => {
    getProducts();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRandomColor = () => {
    const randomColor = Math.floor(Math.random() * 7);
    return RandomColors[randomColor];
  };

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography
              sx={{ marginBottom: "5px", marginTop: "5px" }}
              color="textPrimary"
            >
              Monthly Boxes
            </Typography>
          </Breadcrumbs>
        </div>
        <div className="right">
          <UpdateTopRefs />
        </div>
      </div>
      <Grid container sx={{ maxWidth: "1000px" }}>
        {loading && <CircularProgress sx={{ position: "absolute" }} />}
        {!loading && data && data.monthlyBoxes && (
          <Grid
            md={2}
            sx={{
              marginBottom: "10px",
            }}
          >
            <Item
              elevation={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button color="secondary" onClick={handleClickOpen}>
                <div className="button_div_flex">
                  <AddIcon />
                  <span>
                    Add Box{" "}
                    {`0${
                      data.monthlyBoxes.length > 0
                        ? (
                            parseInt(data.monthlyBoxes[0].title.split(" ")[1]) +
                            1
                          ).toString()
                        : ""
                    }`}
                  </span>
                  <span style={{ color: "#9a9797" }}>
                    {data.monthlyBoxes.length > 0
                      ? moment((parseInt(data.monthlyBoxes[0].end) + 1) * 1000)
                          .tz("America/Los_Angeles")
                          .format("MM/DD/YYYY")
                      : moment().format("MM/DD/YYYY")}
                  </span>
                </div>
              </Button>
            </Item>
          </Grid>
        )}
        {open && (
          <MonthlyBoxAddDialog
            open={open}
            handleClose={handleClose}
            products={productsData?.inv_products}
            productsLoading={productsLoading}
            saveLoading={saveLoading}
            saveBox={saveBox}
          />
        )}
        {data &&
          data.monthlyBoxes &&
          data.monthlyBoxes.length > 0 &&
          data.monthlyBoxes.map((box) => {
            const randomColor = getRandomColor();
            return (
              <Grid md={2} sx={{ marginBottom: "10px" }}>
                <Item
                  elevation={3}
                  sx={{ borderTop: `4px solid ${randomColor}` }}
                >
                  <p style={{ fontWeight: "600" }}>{box.title}</p>
                  <small>
                    {moment(parseInt(box.start) * 1000)
                      .tz("America/Los_Angeles")
                      .format("MM/DD/YYYY")}
                  </small>
                  <ul style={{ listStyle: "none", textAlign: "left" }}>
                    {box.menu &&
                      box.menu.map((menuItem) => {
                        return (
                          <li>
                            {menuItem.name
                              ? menuItem.name
                              : menuItem.product.name}
                          </li>
                        );
                      })}
                  </ul>
                </Item>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}
const MonthlyBoxAddDialog = ({
  open,
  handleClose,
  products,
  productsLoading,
  saveLoading,
  saveBox,
}) => {
  const [muscleMeat, setMuscleMeat] = useState();
  const [organMeat, setOrganMeat] = useState();
  const [seafood, setSeafood] = useState();
  const [lightChew, setLightChew] = useState();
  const [mediumChew, setMediumChew] = useState();
  const [heavyChew, setHeavyChew] = useState();
  const [superChew, setSuperChew] = useState();
  const [meatball, setMeatball] = useState();

  const handleSave = () => {
    const request = {
      menu: [
        { product: muscleMeat.id.toString() },
        { product: organMeat.id.toString() },
        { product: seafood.id.toString() },
        { product: lightChew.id.toString() },
        { product: mediumChew.id.toString() },
        { product: heavyChew.id.toString() },
        { product: superChew.id.toString() },
        { product: meatball.id.toString() },
      ],
    };
    console.log("request => ", request);
    saveBox(request);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Add Monthly Boxes"}</DialogTitle>
      <DialogContent sx={{ padding: "44px 34px" }}>
        {productsLoading && !products ? (
          <CircularProgress />
        ) : (
          <DialogContentText
            id="alert-dialog-description"
            sx={{ width: "300px" }}
          >
            {products && products.length > 0 && (
              <AutocompleteCustom
                data={products.filter(
                  (data) => data.category === "muscle-meat"
                )}
                setVal={setMuscleMeat}
                title={"Muscle Meat"}
              />
            )}
            {products && products.length > 0 && (
              <AutocompleteCustom
                data={products.filter((data) => data.category === "organ-meat")}
                setVal={setOrganMeat}
                title={"Organ Meat"}
              />
            )}
            {products && products.length > 0 && (
              <AutocompleteCustom
                data={products.filter((data) => data.category === "seafood")}
                setVal={setSeafood}
                title={"Seafood"}
              />
            )}
            {products && products.length > 0 && (
              <AutocompleteCustom
                data={products.filter((data) => data.category === "light-chew")}
                setVal={setLightChew}
                title={"Light Chew"}
              />
            )}
            {products && products.length > 0 && (
              <AutocompleteCustom
                data={products.filter(
                  (data) => data.category === "medium-chew"
                )}
                setVal={setMediumChew}
                title={"Medium Chew"}
              />
            )}
            {products && products.length > 0 && (
              <AutocompleteCustom
                data={products.filter((data) => data.category === "heavy-chew")}
                setVal={setHeavyChew}
                title={"Heavy Chew"}
              />
            )}
            {products && products.length > 0 && (
              <AutocompleteCustom
                data={products.filter((data) => data.category === "super-chew")}
                setVal={setSuperChew}
                title={"Super Chew"}
              />
            )}
            {products && products.length > 0 && (
              <AutocompleteCustom
                data={products.filter(
                  (data) => data.category === "secretshop-lbs" || data.category === "secretshop"
                )}
                setVal={setMeatball}
                title={"Meatball or Others"}
              />
            )}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            saveLoading ||
            !muscleMeat ||
            !organMeat ||
            !seafood ||
            !lightChew ||
            !mediumChew ||
            !heavyChew ||
            !superChew ||
            !meatball
          }
          onClick={handleSave}
        >
          {saveLoading ? <CircularProgress /> : "Save"}
        </Button>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MonthlyBoxes;
