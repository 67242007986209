import {
  useMutation, useQuery
} from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";
import {
  Button, CircularProgress, FormControlLabel, Paper,
  Switch, TextField
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { Component, useState } from "react";
import { createRandKey } from "../../../../globals";
import { ADD_PERMISSION, CREATE_ADMIN, GET_ADMINS } from "../../../../queries";
import { MultipleFile, UploadFile } from "../../../Tools/Upload";
import AdminCreateEditComponent from "./AdminDialog";
import Permissions from "./Permissions";

const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

const Admins = (props) => {
  const initialState = {
    name: "",
    surname: "",
    email: "",
    title: "",
    password: "",
  };

  const permissionState = {
    god: {
      admin: false,
    },
    inventory: {
      admin: false,
      process: false,
      read: false,
      display: false,
    },
    author: {
      admin: false,
      write: false,
      read: false,
    },
    underhood: {
      admin: false,
      write: false,
      read: false,
    },
    secretshop: {
      admin: false,
      write: false,
      read: false,
    },
    affiliate: {
      admin: false,
      write: false,
      read: false,
    },
    shipping: {
      admin: false,
      write: false,
      read: false,
    },
    customer: {
      admin: false,
      write: false,
      read: false,
    },
    accountManager: {
      admin: false,
      write: false,
      read: false,
    },
  };

  const [form, setState] = useState(initialState);
  const [openCreateAdmin, setOpenCreateAdmin] = useState(false);
  const [permission, setPermission] = useState(permissionState);

  const [createAdmin, { loading: loadingCreateAdmin }] =
    useMutation(CREATE_ADMIN);

  const [addPermission, { loading: loadingPermission }] =
    useMutation(ADD_PERMISSION);

  const { loading, error, data, refetch } = useQuery(GET_ADMINS);

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  const { name, surname, email, title, password } = form;

  const handleChange = (e) => {
    const { name } = e.target;
    setState({ [name]: e.target.checked });
    console.log(this.state);
  };

  const handleChangePermission = (event) => {
    let name = event.target.name.split("-");
    permission[name[0]][name[1]] = event.target.checked;
    setPermission({ ...permission });
    console.log(permission);
  };

  const onChange = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
    console.log(form);
  };

  const handleClickCreateAdmin = () => {
    setOpenCreateAdmin(true);
  };

  const handleClose = () => {
    setOpenCreateAdmin(false);
  };

  const formValidate = () => {
    const { name, surname, password, email } = form;
    return !name || !surname || !password || !email;
  };

  const resetState = () => {
    setState({
      ...initialState,
    });
  };

  const createAdminFunc = () => {
    createAdmin({
      variables: {
        name,
        surname,
        password,
        email,
        title,
        permission,
      },
    }).then(async ({ data }) => {
      console.log(data);
      //await addPermissionFunc(data.createAdmin.id);
      handleClose();
      refetch();
    });
  };

  const addPermissionFunc = (adminId) => {
    addPermission({
      variables: {
        god: permission.god,
        inventory_admin: permission.inventory_admin,
        inventory: permission.inventory,
        admin_id: adminId,
      },
    }).then(async ({ data }) => {
      console.log(data);
      return "success";
    });
  };

  const uploadPhoto = (file) => {
    console.log({ file });
  };

  return (
    <div>
      <Dialog
        open={openCreateAdmin}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">Create Admin</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <UploadFile
              idKey={"admin-uplad-image-key-" + createRandKey(6)}
              uploadPhoto={uploadPhoto}
            />
            <MultipleFile uploadPhoto={uploadPhoto} />
            <TextField
              onChange={onChange}
              variant="outlined"
              margin="normal"
              value={name}
              required
              fullWidth
              name="name"
              label="Name"
              type="text"
              id="name"
            />
            <TextField
              onChange={onChange}
              variant="outlined"
              margin="normal"
              value={surname}
              required
              fullWidth
              name="surname"
              label="Surname"
              type="text"
              id="surname"
            />
            <TextField
              onChange={onChange}
              variant="outlined"
              margin="normal"
              value={email}
              required
              fullWidth
              name="email"
              label="Email"
              type="text"
              id="email"
            />
            <TextField
              onChange={onChange}
              variant="outlined"
              margin="normal"
              value={title}
              required
              fullWidth
              name="title"
              label="Title"
              type="text"
              id="title"
            />
            <TextField
              onChange={onChange}
              variant="outlined"
              margin="normal"
              value={password}
              required
              fullWidth
              name="password"
              label="Password"
              type="text"
              id="password"
            />

            <FormGroup row>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        color={"secondary"}
                        name="inventory-admin"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Inventory Admin"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        color={"secondary"}
                        name="inventory-process"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Process"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        color={"secondary"}
                        name="inventory-display"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Display PIN"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        color={"secondary"}
                        name="author-admin"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Author Admin"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        color={"secondary"}
                        name="author-write"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Author"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        color={"secondary"}
                        name="author-read"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Author (Read)"
                  />
                </Grid>
                <Grid item xs={4}>
                  {" "}
                  <FormControlLabel
                    control={
                      <Switch
                      checked={permission.accountManager.admin}
                        color={"secondary"}
                        name="accountManager-admin"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Account Manager"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.customer.admin}
                        color={"secondary"}
                        name="customer-admin"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Customer"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.secretshop.admin}
                        color={"secondary"}
                        name="secretshop-admin"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Secretshop"
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={createAdminFunc}
            color="secondary"
            disabled={formValidate()}
          >
            Create Admin
          </Button>
        </DialogActions>
      </Dialog>

      <AdminListUI
        {...data}
        refetch={refetch}
        openCreateAdmin={handleClickCreateAdmin}
      />
    </div>
  );
};

export class AdminListUI extends Component {
  componentDidMount() {
    this.props.refetch();
    console.log(this.props);
    // console.log(this.props);
  }
  render() {
    return (
      <div>
        <div className="pagetop">
          <div className="left">
            <Breadcrumbs aria-label="breadcrumb">
              <a color="inherit" href="/">
                Dashboard
              </a>
              <Typography color="textPrimary">Admin List</Typography>
            </Breadcrumbs>
          </div>
          <div className="right">
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.props.openCreateAdmin}
            >
              Create Admin
            </Button>
          </div>
        </div>
        <ul>
          {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Surname</TableCell>
                    <TableCell align="left">Title</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.admins.map((admin) => (
                    <TableRow key={admin.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        {admin.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        {admin.surname}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        {admin.title}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        {admin.email}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        {admin.status ? (
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="greenborder"
                            disabled
                          >
                            <b className="greenborder">Active</b>
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="secondary"
                            className="redborder"
                            disabled
                            size="small"
                          >
                            <b className="redborder">Inactive</b>
                          </Button>
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        <Permissions email={admin.email} permission={admin.permission} />
                      </TableCell>
                      <TableCell align="right">
                        <AdminCreateEditComponent
                          admin={admin}
                          refetch={() => {
                            this.props.refetch();
                          }}
                        />
                        {/* <Button
                          disabled
                          component={Link}
                          variant="outlined"
                          color="secondary"
                        >
                          <Edit />
                        </Button> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </ul>
      </div>
    );
  }
}

export default withApollo(Admins);
