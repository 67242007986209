import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { LinearProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_FLAG, UPDATE_FLAG } from "../../../../queries";
const KEY1 = "course-discount-banner";
const KEY2 = "rd-banner";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [flag1, setFlag1] = useState({});
  const [flag2, setFlag2] = useState({});
  const [text, setText] = useState("");
  const [text2, setText2] = useState("");
  const [link, setLink] = useState("");
  const [popups, setPopups] = useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };
  console.log("flag1", flag1);
  console.log("flag2", flag2);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [getFlag, { loading, refetch }] = useLazyQuery(GET_FLAG, {
    onCompleted: (data) => {
      console.log("data", data.getFlag);
      if (data.getFlag && data.getFlag.key === KEY1) {
        setFlag1(data.getFlag);
        setText(data.getFlag.data.text);
        setPopups(data.getFlag.data.eventPopup);
      } else if (data.getFlag && data.getFlag.key === KEY2) {
        setFlag2(data.getFlag);
        setText2(data.getFlag.data.text);
        setLink(data.getFlag.data.link);
      }
    },
    fetchPolicy: "no-cache",
  });

  const [updateFlagMutation, { loading: loadingUpdate }] =
    useMutation(UPDATE_FLAG);

  useEffect(() => {
    getFlag({
      variables: {
        key: KEY1,
      },
    }).then((res) => {
      getFlag({
        variables: {
          key: KEY2,
        },
      });
    });
  }, [getFlag, open]);

  const onChangeStatus = (n) => {
    const copy = copyFlag(n);
    copy.status = !copy.status;
    n === 1 ? setFlag1(copy) : setFlag2(copy);
  };

  const copyFlag = (n) => JSON.parse(JSON.stringify(n === 1 ? flag1 : flag2));

  const update = () => {
    updateFlagMutation({
      variables: {
        id: flag1.id,
        input: {
          key: KEY1,
          status: flag1.status,
          data: {
            text: text,
            eventPopup: popups,
          },
        },
      },
    })
      .then((res) => {
        updateFlagMutation({
          variables: {
            id: flag2.id,
            input: {
              key: KEY2,
              status: flag2.status,
              data: {
                text: text2,
                link: link,
              },
            },
          },
        })
          .then((res) => {
            handleClose();
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  return (
    <Grid item xs>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        style={{
          borderLeft: "5px solid",
          borderLeftColor:
            flag1.status && flag2.status
              ? "#63b475"
              : flag1.status || flag2.status
              ? "#dcb65a"
              : "#dc5a68",
        }}
        className="btn-reward"
      >
        Course Discount Banner
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Course Discount Banner"}
        </DialogTitle>
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogContent>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Feedreal" {...a11yProps(0)} />
                  <Tab label="Real Dog" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <p style={{ color: "#dc5a68" }}>
                  Warning: This is not a reward.
                </p>
                <p>
                  When you want to change the price of the course on exact
                  amount please go Content(CMS)>Course>Edit Details and change
                  the price. Use this to show banner for customer.
                </p>
                <FormControlLabel
                  control={
                    <Switch
                      checked={flag1.status}
                      color="secondary"
                      onClick={() => {
                        onChangeStatus(1);
                      }}
                    />
                  }
                  label="Open Banner"
                />

                <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />
                <TextField
                  fullWidth
                  type="text"
                  id="standard-basic"
                  label="Text"
                  variant="standard"
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={popups}
                      color="secondary"
                      onClick={() => {
                        setPopups(!popups);
                      }}
                    />
                  }
                  label="Open Popups"
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <p style={{ color: "#dc5a68" }}>
                  Warning: This is not a reward.
                </p>
                <p>
                  This will only show a banner on the real.dog website. It will
                  not affect the rewards.
                </p>
                <FormControlLabel
                  control={
                    <Switch
                      checked={flag2.status}
                      color="secondary"
                      onClick={() => {
                        onChangeStatus(2);
                      }}
                    />
                  }
                  label="Open Banner"
                />

                <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />
                <TextField
                  fullWidth
                  type="text"
                  id="standard-basic"
                  label="Text"
                  variant="standard"
                  value={text2}
                  onChange={(e) => {
                    setText2(e.target.value);
                  }}
                />
                <TextField
                  fullWidth
                  type="text"
                  id="standard-basic"
                  label="Link"
                  variant="standard"
                  value={link}
                  sx={{ marginTop: "10px" }}
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                />
                <p>This link will be open in a new tab.</p>
              </TabPanel>
            </Box>
          </DialogContent>
        )}
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>cancel</Button>
            <Button onClick={update} autoFocus>
              Update
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Grid>
  );
}
