import React, { Component, useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client";

import { GET_ACTIVE_ADMIN, CUSTOMER_FIND } from "../../../../../queries";
import moment from "moment";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function CustomerFind(props) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [customersData, setCustomersData] = useState([]);
  const isInitialMount = useRef(true);
  const [getCustomers, { loading: loadingSearch, data: searchData }] =
    useLazyQuery(CUSTOMER_FIND, {
      fetchPolicy: "no-cache",
      variables: {
        email: search,
        phone_number: searchPhone,
      },
      onCompleted: (data) => {
        console.log("data data", data);
        setCustomersData(data.customersFind);
      },
    });

  useEffect(() => {
    console.log("data", customersData);
    if (!isInitialMount.current) {
      getCustomers();
    }
  }, [customersData, getCustomers, search, searchPhone]);

  const searchInput = (event) => {
    const numericRegex = /^([0-9]{1,100})+$/;
    if (
      numericRegex.test(event.target.value) &&
      event.target.value.length >= 4
    ) {
      isInitialMount.current = false;
      setSearch("");
      setSearchPhone(event.target.value);
    } else if (event.target.value.length >= 4) {
      isInitialMount.current = false;
      setSearchPhone("");
      setSearch(event.target.value);
    }
  };

  const returnData = (obj) => {
      console.log("returned =>" ,obj);
    props.handleSearchResult(obj);
  };

  return (
    <CustomerFindUI
      searchInput={searchInput}
      customersData={customersData}
      loadingSearch={loadingSearch}
      classes={classes}
      returnData={returnData}
    />
  );
}

export class CustomerFindUI extends Component {
  componentDidMount() {
    console.log("comp", this.props);
  }
  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Autocomplete
              id="REALDOG - REAL SEARCH"
              noOptionsText={
                "Please search customer with phone number or email"
              }
              loading={this.props.loadingSearch}
              options={this.props.customersData}
              getOptionLabel={(option) =>
                `${option.name} - ${option.email} - ${option.username} - ${option.phone_number}`
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <div className="searchresult">
                    <b>{option.name}</b>
                    <span>{option.email}</span>
                  </div>
                </li>
              )}
              onChange={(e, value) => {
                let newValue = value || "";
                console.log(newValue);
                this.props.returnData(newValue);
              }}
              renderInput={(customer) => (
                <TextField
                  {...customer}
                  label="Search User (Email, Phone Number or Username)"
                  margin="normal"
                  onChange={this.props.searchInput}
                  variant="outlined"
                  InputProps={{ ...customer.InputProps, type: "search" }}
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default CustomerFind;
