import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  FINAL_EXAM,
  MULTIPLE_CHOICE,
  QUESTION_ANSWER,
  QUIZ_CONTENT,
} from "../../../../globals";
import { CREATE_QUIZ, GET_SINGLE_QUIZ, UPDATE_QUIZ } from "../../../../queries";
import QuizQuestions from "../../Quiz/components/QuizQuestions";
import QuizSelectQuestion from "../../Quiz/components/QuizSelectQuestion";
import QuizStatus from "../../Quiz/components/QuizStatus";
import QuizTitle from "../../Quiz/components/QuizTitle";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Edit } from "@mui/icons-material";

const QUIZ_TITLE_MIN_LENGTH = 1;
const QUESTION_OPTIONS_MIN = 2;

const minutes = new Array(100).fill(0).map((item, index) => {
  return {
    value: index + 1,
    label: index + 1,
  };
});

const baseComponent = {
  type: "",
  title: "",
  content: {},
  text: "",
};

export default function QuizTutorialDialog({ addQuiz, disabled, close }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    close();
  };

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const quizTypeParam = MULTIPLE_CHOICE;
  const [questionsCount, setQuestionsCount] = useState([]);

  const [quizType, setQuizType] = useState("");

  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(true);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [getSingleQuiz, { loading: loadingGetSingleQuiz, refetch }] =
    useLazyQuery(GET_SINGLE_QUIZ, {
      variables: {
        input: {
          id,
        },
      },
      fetchPolicy: "no-cache",
      onCompleted: (response) => {
        const { title, status, tags, categories, questions, quizType } =
          response.getSingleQuiz;
        setTitle(title);
        setStatus(status);
        setTags(tags);
        setCategories(categories);
        setQuestions(questions);
        setQuizType(quizType);
      },
    });

  const [updateQuizMutation, { loading: loadingUpdateQuiz }] = useMutation(
    UPDATE_QUIZ,
    {
      onCompleted: (response) => {
        // refetch();
        enqueueSnackbar("Quiz Updated", {
          variant: "success",
        });
        history.push("/dashboard/quiz");
      },
    }
  );
  const [createQuizMutation, { loading: loadingCreateQuiz }] = useMutation(
    CREATE_QUIZ,
    {
      onCompleted: (response) => {
        const obj = JSON.parse(JSON.stringify(baseComponent));
        obj.content = { ...response.createQuiz };
        obj.title = response.createQuiz.title;
        obj.type = QUIZ_CONTENT;
        addQuiz(obj);
        enqueueSnackbar("Quiz Created", {
          variant: "success",
        });
        handleClose();

        // history.push("/dashboard/quiz");
      },
    }
  );
  useEffect(() => {
    if (!id) {
      if (quizTypeParam === QUESTION_ANSWER) {
        setQuizType(QUESTION_ANSWER);
      }
      if (quizTypeParam === MULTIPLE_CHOICE) {
        setQuizType(MULTIPLE_CHOICE);
      }
      if (quizTypeParam === FINAL_EXAM) {
        setQuizType(FINAL_EXAM);
      }

      newInitialState();
    } else {
      existingInitialState();
    }
  }, [id, quizTypeParam]);

  useEffect(() => {
    // console.log(title, status, tags, categories, questions);
  }, [title, status, tags, categories, questions]);

  const newInitialState = () => {
    setTitle("");
    setStatus(true);
    setTags([]);
    setCategories([]);
    setQuestions([]);
  };
  const existingInitialState = () => {
    getSingleQuiz();
  };

  const onUpdateQuiz = () => {
    const input = createInput();
    const valid = isValid();
    if (!valid) {
      return;
    }
    updateQuizMutation({
      variables: {
        id,
        input,
      },
    });
  };

  const onCreateQuiz = () => {
    const valid = isValid();
    if (!valid) {
      return;
    }
    const input = createInput();
    createQuizMutation({
      variables: {
        input,
      },
    });
  };

  const createInput = () => {
    const input = {
      title,
      status,
      tags,
      categories,
      questions: questions.map((item) => item.id),
      quizType,
    };
    return input;
  };

  const isValid = () => {
    if (title.length < QUIZ_TITLE_MIN_LENGTH) {
      enqueueSnackbar("Quiz title is not valid (min lenght 10)", {
        variant: "error",
      });
      return false;
    }
    const questionsValid = questions
      .map((item) => item.question || "")
      .every((item) => item.length > QUIZ_TITLE_MIN_LENGTH);
    if (!questionsValid) {
      enqueueSnackbar(`Quiz questions (min lenght ${QUIZ_TITLE_MIN_LENGTH})`, {
        variant: "error",
      });
      return false;
    }
    const optionsValid = questions
      .filter((item) => item.questionType === MULTIPLE_CHOICE)
      .map((item) => item.options)
      .every((item) => item.length >= QUESTION_OPTIONS_MIN);

    if (!optionsValid) {
      enqueueSnackbar("Options Error (min 2 options required)", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const onSetCountdown = (value) => {
    // const obj = JSON.parse(JSON.stringify(tutorial));
    // obj.content.countdown = value;
    // setTutorial(obj);
  };
  const onSetNumberOfQuestions = (value) => {
    // const obj = JSON.parse(JSON.stringify(tutorial));
    // obj.content.numberOfQuestions = value;
    // setTutorial(obj);
  };

  const onAddQuestion = (question) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const existingObj = questions.find((item) => item.id === question.id);
    if (existingObj) {
      enqueueSnackbar("This obj already exist in question list", {
        variant: "info",
      });
      return;
    }
    copy.push(question);
    setQuestions(copy);
  };
  const onDeleteQuestion = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    copy.splice(index, 1);
    setQuestions(copy);
  };

  const onMoveDown = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const temp = copy[index + 1];
    copy[index + 1] = copy[index];
    copy[index] = temp;
    setQuestions(copy);
    enqueueSnackbar("Question moved down", {
      variant: "success",
    });
  };
  const onMoveUp = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const temp = copy[index - 1];
    copy[index - 1] = copy[index];
    copy[index] = temp;
    setQuestions(copy);
    enqueueSnackbar("Question moved up", {
      variant: "success",
    });
  };

  const onSubmitQuiz = () => {
    onCreateQuiz();
  };

  return (
    <div>
      <DialogTitle id="alert-dialog-title">{"Create Quiz1"}</DialogTitle>
      <DialogContent
        style={{
          paddingTop: 10,
        }}
      >
        <DialogContentText id="alert-dialog-description">
          <QuizTitle title={title} setTitle={setTitle} />
          {/* <br />
                <QuizStatus status={status} setStatus={setStatus} /> */}
          <br />
          <QuizQuestions
            questions={questions}
            deleteQ={(index) => {
              onDeleteQuestion(index);
            }}
            onMoveDown={(index) => {
              onMoveDown(index);
            }}
            onMoveUp={(index) => {
              onMoveUp(index);
            }}
          />
          <QuizSelectQuestion
            onConfirmQuestion={(question) => onAddQuestion(question)}
            quizType={MULTIPLE_CHOICE}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>close</Button>
        <Button
          onClick={() => {
            onSubmitQuiz();
          }}
          autoFocus
        >
          Add to do unit
        </Button>
      </DialogActions>
    </div>
  );
}

export function UpdateQuizTutorialDialog({
  refetchUnit,
  quizId,
  quizTypeOrigin,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  // const { id } = useParams();
  const id = quizId;

  const quizTypeParam = quizTypeOrigin;
  const [questionsCount, setQuestionsCount] = useState([]);

  const [quizType, setQuizType] = useState("");

  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(true);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [getSingleQuiz, { loading: loadingGetSingleQuiz, refetch }] =
    useLazyQuery(GET_SINGLE_QUIZ, {
      variables: {
        input: {
          id,
        },
      },
      fetchPolicy: "no-cache",
      onCompleted: (response) => {
        const { title, status, tags, categories, questions, quizType } =
          response.getSingleQuiz;
        setTitle(title);
        setStatus(status);
        setTags(tags);
        setCategories(categories);
        setQuestions(questions);
        setQuizType(quizType);
      },
    });

  const [updateQuizMutation, { loading: loadingUpdateQuiz }] = useMutation(
    UPDATE_QUIZ,
    {
      onCompleted: (response) => {
        // refetch();
        enqueueSnackbar("Quiz Updated", {
          variant: "success",
        });
        refetchUnit(response.updateQuiz.title);
        handleClose();
      },
    }
  );

  useEffect(() => {
    if (!id) {
      if (quizTypeParam === QUESTION_ANSWER) {
        setQuizType(QUESTION_ANSWER);
      }
      if (quizTypeParam === MULTIPLE_CHOICE) {
        setQuizType(MULTIPLE_CHOICE);
      }
      if (quizTypeParam === FINAL_EXAM) {
        setQuizType(FINAL_EXAM);
      }

      newInitialState();
    } else {
      existingInitialState();
    }
  }, [id, quizTypeParam]);

  useEffect(() => {
    // console.log(title, status, tags, categories, questions);
  }, [title, status, tags, categories, questions]);

  const newInitialState = () => {
    setTitle("");
    setStatus(true);
    setTags([]);
    setCategories([]);
    setQuestions([]);
  };
  const existingInitialState = () => {
    getSingleQuiz();
  };

  const onUpdateQuiz = () => {
    const input = createInput();
    const valid = isValid();
    if (!valid) {
      return;
    }
    console.log(input);
    updateQuizMutation({
      variables: {
        id,
        input,
      },
    });
  };
  const createInput = () => {
    const input = {
      title,
      status,
      tags,
      categories,
      questions: questions.map((item) => item.id),
      quizType,
    };
    return input;
  };

  const isValid = () => {
    if (title.length < QUIZ_TITLE_MIN_LENGTH) {
      enqueueSnackbar("Quiz title is not valid (min lenght 10)", {
        variant: "error",
      });
      return false;
    }
    const questionsValid = questions
      .map((item) => item.question || "")
      .every((item) => item.length > QUIZ_TITLE_MIN_LENGTH);
    if (!questionsValid) {
      enqueueSnackbar(`Quiz questions (min lenght ${QUIZ_TITLE_MIN_LENGTH})`, {
        variant: "error",
      });
      return false;
    }
    const optionsValid = questions
      .filter((item) => item.questionType === MULTIPLE_CHOICE)
      .map((item) => item.options)
      .every((item) => item.length >= QUESTION_OPTIONS_MIN);

    if (!optionsValid) {
      enqueueSnackbar("Options Error (min 2 options required)", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const onAddQuestion = (question) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const existingObj = questions.find((item) => item.id === question.id);
    if (existingObj) {
      enqueueSnackbar("This obj already exist in question list", {
        variant: "info",
      });
      return;
    }
    copy.push(question);
    setQuestions(copy);
  };
  const onDeleteQuestion = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    copy.splice(index, 1);
    setQuestions(copy);
  };

  const onMoveDown = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const temp = copy[index + 1];
    copy[index + 1] = copy[index];
    copy[index] = temp;
    setQuestions(copy);
    enqueueSnackbar("Question moved down", {
      variant: "success",
    });
  };
  const onMoveUp = (index) => {
    const copy = JSON.parse(JSON.stringify(questions));
    const temp = copy[index - 1];
    copy[index - 1] = copy[index];
    copy[index] = temp;
    setQuestions(copy);
    enqueueSnackbar("Question moved up", {
      variant: "success",
    });
  };

  const onSubmitQuiz = () => {
    onUpdateQuiz();
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Edit />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <QuizTitle title={title} setTitle={setTitle} />
            {/* <br />
                <QuizStatus status={status} setStatus={setStatus} /> */}
            <br />
            <QuizQuestions
              questions={questions}
              deleteQ={(index) => {
                onDeleteQuestion(index);
              }}
              onMoveDown={(index) => {
                onMoveDown(index);
              }}
              onMoveUp={(index) => {
                onMoveUp(index);
              }}
            />
            <QuizSelectQuestion
              onConfirmQuestion={(question) => onAddQuestion(question)}
              quizType={quizType}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button onClick={handleClose}>close</Button>
            <Button
              onClick={() => {
                onSubmitQuiz();
              }}
              autoFocus
            >
              Update Quiz
            </Button>
          </DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}
