import { gql } from "@apollo/client";

const GETACTIVEDOG = gql`
  query activeMongoDogs($owner: ID) {
    getActiveMongoDogsByOwnerID(owner: $owner) {
      id
      name
      restrictions
      sub_restrictions
      shippingId
      owner
      subscriptions {
        subId
      }
      birthday
      breed
      breed2
      description
      instagram
      photo
      size
      video
      videoUri
    }
  }
`;

const GETDOGS = gql`
  query mongoDogs($owner: ID) {
    getMongoDogsByOwnerID(owner: $owner) {
      id
      name
      restrictions
      sub_restrictions
      shippingId
      owner
      intakeForm {
        id
        questions
      }
      subscriptions {
        subId
      }
      birthday
      breed
      breed2
      description
      instagram
      photo
      size
      video
      videoUri
    }
  }
`;

const GETDOGS_SUMMARY = gql`
  query mongoDogs($owner: ID) {
    getMongoDogsByOwnerID(owner: $owner) {
      id
      name
      restrictions
      sub_restrictions
      shippingId
      owner
      intakeForm {
        id
        questions
      }
      subscriptions {
        subId
        createdAt
        sub_date
        metadata
        status
        stripeSubAddress {
          name
          address
          address1
          address2
          city
          state
          postal_code
          country
        }
        items {
          id
          planId
          name
          productId
          product_description
          quantity
          amount
          shippingItem
        }
      }
      birthday
      breed
      breed2
      description
      instagram
      photo
      size
      video
      videoUri
    }
  }
`;

const GET_NON_ACTIVE_DOGS = gql`
  query mongoNonActiveDogs($owner: ID) {
    getNoActiveMongoDogsByOwnerID(owner: $owner) {
      id
      name
      restrictions
      sub_restrictions
      shippingId
      owner
      subscriptions {
        subId
      }
      birthday
      breed
      breed2
      description
      instagram
      photo
      size
      video
      videoUri
    }
  }
`;

const FETCH_DOG_BY_ADDRESS_ID = gql`
  query dogsByShippingID($id: ID) {
    getMongoDogsByShippingID(id: $id) {
      id
      name
    }
  }
`;

const GET_CUSTOMERS_DOGS = gql`
  query GetDogs($id: ID) {
    getMongoDogsByOwnerID(owner: $id) {
      id
      name
      restrictions
      sub_restrictions
      shippingId
      owner
      birthday
      breed
      breed2
      description
      instagram
      photo
      size
      video
      videoUri
      subscriptions {
        pause_collection {
          behavior
          resumes_at
        }
        subId
        sub_date
        mongoAddressId
        pause_collection {
          behavior
          resumes_at
        }
        stripeSubAddress {
          name
          address
          address1
          address2
          city
          state
          postal_code
          country
        }
        items {
          planId
          id
          productId
          name
          product_description
          package_dimensions {
            weight
            height
            width
            length
          }
          amount
          quantity
          shippingItem
        }
      }
    }
  }
`;

const UPDATE_DOG_SUB_ADDRESS = gql`
  mutation UpdateStripeAddressBySubID(
    $subId: String!
    $addressId: ID!
    $itemId: String
    $shippingCost: Float
    $metadata: JSON
  ) {
    updateAddressOnStripe(
      subId: $subId
      addressId: $addressId
      itemId: $itemId
      shippingCost: $shippingCost
      metadata: $metadata
    )
  }
`;

const SET_RESTRICTIONS = gql`
  mutation SetDogRestrictions(
    $subId: String
    $restrictions: [String]
    $subRestrictions: [String]
  ) {
    setRestrictions(
      subId: $subId
      restrictions: $restrictions
      subRestrictions: $subRestrictions
    ) {
      id
      metadata
    }
  }
`;

const SET_MONGO_RESTRICTONS = gql`
  mutation SetMongoDogRestrictions(
    $dogId: ID
    $restrictions: [String]
    $subRestrictions: [String]
  ) {
    setMongoRestrictions(
      dogId: $dogId
      restrictions: $restrictions
      subRestrictions: $subRestrictions
    ) {
      id
      name
      restrictions
      sub_restrictions
    }
  }
`;

const CREATE_DOG = gql`
  mutation CreateMongoDog($input: inputMongoDog) {
    createMongoDog(input: $input) {
      id
      name
      restrictions
      sub_restrictions
      shippingId
      owner
      subscriptions {
        subId
        sub_date
        metadata
        mongoAddressId
        cancel_at_period_end
        cancel_at
        canceled_at
        pause_collection {
          behavior
          resumes_at
        }
        stripeSubAddress {
          name
          address
          address1
          address2
          city
          state
          postal_code
          country
        }
        items {
          planId
          id
          productId
          name
          product_description
          package_dimensions {
            weight
            height
            width
            length
          }
          amount
          quantity
          shippingItem
        }
      }
      birthday
      breed
      breed2
      description
      instagram
      photo
      size
      video
      videoUri
    }
  }
`;

const UPDATE_DOG_RESTRICTION = gql`
  mutation SetMongoDogRestrictions(
    $dogId: ID
    $restrictions: [String]
    $subRestrictions: [String]
  ) {
    setMongoRestrictions(
      dogId: $dogId
      restrictions: $restrictions
      subRestrictions: $subRestrictions
    ) {
      id
      name
      restrictions
      sub_restrictions
    }
  }
`;

const GET_MONGO_DOG = gql`
  query ($id: ID!) {
    getMongoDog(id: $id) {
      id
      restrictions
      sub_restrictions
    }
  }
`;

const DELETE_CUSTOMER_DOG = gql`
  mutation DeleteDog($dogId: ID) {
    deleteDog(dogId: $dogId) {
      name
    }
  }
`;

const UPDATE_CUSTOMER_MONGO_DOG = gql`
  mutation UpdateDog($id: ID!, $input: inputMongoDog) {
    updateMongoDog(id: $id, input: $input) {
      name
    }
  }
`;

export {
  GETACTIVEDOG,
  GETDOGS,
  GETDOGS_SUMMARY,
  GET_NON_ACTIVE_DOGS,
  FETCH_DOG_BY_ADDRESS_ID,
  GET_CUSTOMERS_DOGS,
  UPDATE_DOG_SUB_ADDRESS,
  SET_RESTRICTIONS,
  SET_MONGO_RESTRICTONS,
  CREATE_DOG,
  UPDATE_DOG_RESTRICTION,
  GET_MONGO_DOG,
  DELETE_CUSTOMER_DOG,
  UPDATE_CUSTOMER_MONGO_DOG,
};
