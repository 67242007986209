import React, { Component, useEffect, useState, useRef } from "react";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import {
  GET_PROCESSS,
  GET_STOCK,
  GET_SUPPLIERS,
  GET_INVENTORY_PRODUCTS,
  ADD_PROCESS,
  GET_ACTIVE_ADMIN,
  GET_INV_PRODUCT,
  START_PROCESS,
  GET_PROCESS,
  END_PROCESS,
  START_DRYER,
  SPLIT_PROCESS,
  STOP_DRYER,
  ADD_BINS,
  GET_ADMIN,
  ADD_PACKED,
  GET_ADMINS,
  PASS_VALID,
  GET_STOCK_UNS,
  UPDATE_STOCK_UN,
  ADD_STOCK,
  PROCESS_SUBSCRIPTION,
  UPDATE_STATUS,
} from "../../../queries";
import NumPad from "react-numpad";
import { ProcessDetailModal } from "../Modals/ProcessDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Check from "@mui/icons-material/Check";
import ButtonGroup from "@mui/material/ButtonGroup";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import LinearProgress from "@mui/material/LinearProgress";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import Typography from "@mui/material/Typography";
import moment from "moment-timezone";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "lodash";
import Divider from "@mui/material/Divider";

const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

function Process(props) {
  // query get data
  const initialState = {
    quantity: "",
    supplier: "",
    projected_units: "",
    order_number: "",
    comments: "",
    stock_un: "",
    brand: "",
    price: "",
  };

  const [open, setOpen] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [me, setMe] = useState(props.session.activeAdmin.id);
  const [lastweek, setLastWeek] = useState(moment().subtract(15, "d").unix());

  const [selectUser, setSelectUser] = useState("");
  const [number, setNumber] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentProduct, setCurrentProduct] = useState("");
  const [currentProductCategory, setCurrentProductCategory] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [updateProduct, setUpdateProduct] = useState("");
  const [busy, setBusy] = useState(true);
  const [form, setState] = useState(initialState);
  const isInitialMount = useRef(false);
  const [filteredData, setFilteredData] = useState([]);
  const [productId, setProductId] = useState("");

  const {
    quantity,
    brand,
    price,
    admin_id,
    projected_units,
    status,
    supplier,
    stock_un,
    order_number,
    comments,
  } = form;

  const { subscribeToMore, loading, error, data, refetch } = useQuery(
    GET_PROCESSS,
    {
      variables: {
        rec_date: lastweek,
      },
    }
  );

  const { loading: loadingAdmins, data: dataAdmins } = useQuery(GET_ADMINS);
  const {
    loading: loadingStockUn,
    data: dataStockUn,
    refetch: dataStockRefetch,
  } = useQuery(GET_STOCK_UNS);

  const { loading: loadingAdmin, data: dataAdmin } = useQuery(GET_ACTIVE_ADMIN);
  const {
    loading: loadingSup,
    error: errorSup,
    data: dataSup,
  } = useQuery(GET_SUPPLIERS);
  const {
    loading: loadingProd,
    error: errorProd,
    data: dataProd,
  } = useQuery(GET_INVENTORY_PRODUCTS);

  const { data: dataAdminList, loading: loadingGetAdmin } = useQuery(
    GET_ADMIN,
    {
      variables: { id: me },
    }
  );

  const [addProcess, { loading: loadingAddProcess }] = useMutation(ADD_PROCESS);
  const [passValid, { loading: loadingPassValid }] = useMutation(PASS_VALID);
  const [updateStockUn, { loading: loadingUpdateStockUn }] =
    useMutation(UPDATE_STOCK_UN);

  useEffect(() => {
    if (data) {
      setFilteredData(data.inv_processs);
      //console.log(filteredData);
    }
  }, [data, filteredData]);

  useEffect(() => {
    if (isInitialMount.current) {
      let name = dataStockUn.stock_uns.filter((x) => x.id === form.stock_un);
      setProductId(name[0].product.id);
    }
    isInitialMount.current = false;
  }, [form]);

  useEffect(() => {
    if (userPassword.length === 4) {
      passValidFunc();
    }
  }, [userPassword]);

  const [getProduct, { loading: loadingGetProd, data: dataGetProd }] =
    useLazyQuery(GET_INV_PRODUCT, {
      variables: {
        id: updateProduct,
      },
      onCompleted: (data) => {
        setCurrentProduct(data.inv_product.name);
        setCurrentProductCategory(data.inv_product.category);
      },
    });

  if (
    loading ||
    loadingSup ||
    loadingProd ||
    loadingAdmin ||
    loadingGetAdmin ||
    loadingAdmins ||
    loadingStockUn
  )
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  const handleClickOpen = () => {
    if (
      dataAdminList.admin.permission.god.admin ||
      dataAdminList.admin.permission.inventory.admin
    ) {
      setOpen(true);
      generateBatch();
    } else if (dataAdminList.admin.permission.inventory.process) {
      setOpenPassword(true);
    }
  };

  const generateBatch = () => {
    // today
    let today = moment().tz("America/Los_Angeles").format("YYYY-MM-DD");
    today = moment(today).format("X");
    let todayDataset = data.inv_processs.filter(
      (item) => item.rec_date >= today
    );
    //console.log(todayDataset);
    ////console.log(data.inv_processs);
    //console.log(today);
    //console.log("now", moment().unix());

    if (todayDataset.length > 0) {
      //console.log(true);
      const lastBatch = todayDataset[0];
      let degisken2 = lastBatch.batch_number.split("-");

      const year = moment().tz("America/Los_Angeles").format("YY");
      const month = moment().tz("America/Los_Angeles").format("MM");
      const day = moment().tz("America/Los_Angeles").format("DD");
      const batchNo = year + month + day + "-01";

      const newNum =
        parseInt(degisken2[0].substring(4, 6)) < parseInt(day)
          ? 1
          : parseInt(degisken2[1]) + 1;

      const degisken = `${year}${month}${day}-${
        newNum > 9 ? newNum : `0${newNum}`
      }`;

      //console.log("lastbatch", degisken);
      setBatchNumber(degisken);
    } else {
      // generate batch no today
      const year = moment().tz("America/Los_Angeles").format("YY");
      const month = moment().tz("America/Los_Angeles").format("MM");
      const day = moment().tz("America/Los_Angeles").format("DD");
      const batchNo = year + month + day + "-01";
      // last batch find

      console.log(`Batch Number : ${batchNo}`);
      ////console.log(data.inv_processs);
      setBatchNumber(batchNo);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenPassword(false);
    setOpenConfirm(false);
    setSelectUser("");
    resetState();
    resetCategory();
  };

  const handleClickOpenConfirm = () => {
    setOpen(false);
    setOpenConfirm(true);
    getProduct();
  };

  const handleSelectUser = (e) => {
    setSelectUser(e.target.value);
    //console.log(selectUser);
  };

  const handlePassword = (e) => {
    setUserPassword(e.target.value);
    //console.log(userPassword);
  };

  const handleCloseConfirm = () => {
    setOpen(true);
    setOpenConfirm(false);
  };

  const updateField = (e) => {
    if (e.target.name === "quantity") {
      let stock = dataStockUn.stock_uns.filter((x) => x.id === stock_un)[0][
        "left"
      ];
      if (stock >= parseInt(e.target.value) || e.target.value.length === 0) {
        console.log("refet");
        setState({
          ...form,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setState({
        ...form,
        [e.target.name]: e.target.value,
      });
      isInitialMount.current = true;
    }
  };

  const handleProduct = (e) => {
    setUpdateProduct(e.target.value);
    getProduct();
    //console.log(updateProduct);
  };

  const handleChange = (e) => {
    setBatchNumber(e.target.value);
  };

  const resetState = () => {
    setState({ ...initialState });
  };

  const resetCategory = () => {
    setCurrentProductCategory("");
  };

  const formValidate = () => {
    if (currentProductCategory == "super-chew") {
      return !quantity || !projected_units;
    }
    return !quantity;
  };

  //console.log(dataStockUn);

  const passValidFunc = () => {
    //console.log(selectUser, userPassword);
    passValid({
      variables: {
        email: selectUser,
        pass: parseInt(userPassword),
      },
    }).then(async ({ data }) => {
      if (data.passValid.access) {
        setMe(data.passValid.id);
        setOpenPassword(false);
        setUserPassword("");
        generateBatch();
        setOpen(true);
        //console.log(me);
      } else {
        setUserPassword("");
      }
      //console.log(data);
    });
  };

  const addProcessFunc = () => {
    addProcess({
      variables: {
        product: productId,
        batch_number: batchNumber,
        quantity,
        split: false,
        lastsplit: 0,
        rec_date: moment().unix(),
        createdAt: moment().unix(),
        admin_id: me,
        projected_units,
        status: 1,
        stock_un,
        comments,
      },
    }).then(async ({ data }) => {
      resetState();
      handleClose();
      await updateStockUnFunc();
      refetch();
      //console.log(data);
    });
  };

  const updateStockUnFunc = () => {
    const stock = dataStockUn.stock_uns.filter((x) => x.id === stock_un);
    if (stock[0].quantity >= stock[0].left - quantity) {
      updateStockUn({
        variables: {
          id: stock_un,
          left: (stock[0].left - quantity).toString(),
        },
      }).then(async ({ data }) => {
        console.log(data);
        dataStockRefetch();
        resetState();
      });
    } else {
      console.log(`ERROR Check Stock Quantity`);
    }
  };

  const handleNum = (num) => {
    //added
    if (userPassword.length < 4) {
      setUserPassword(userPassword + num);
      //console.log(userPassword);
      //process verify
    }
  };

  const handleNumRemove = () => {
    //remove last
    setUserPassword(userPassword.substring(0, userPassword.length - 1));
  };

  return (
    <div>
      <Dialog
        open={openPassword}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">Verify Account</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <FormControl component="fieldset">
                  <RadioGroup
                    className="usersradio"
                    aria-label="gender"
                    name="gender1"
                    onChange={handleSelectUser}
                  >
                    {dataAdmins.admins.map((admin, i) => {
                      console.log(admin);
                      console.log(admin.permission.inventory.admin);
                      console.log(admin.permission.inventory.process);
                      if (
                        admin.permission.inventory.admin ||
                        admin.permission.inventory.process
                      ) {
                        return (
                          <FormControlLabel
                            className="usersradioselect"
                            value={admin.email}
                            control={<Radio />}
                            label={admin.name}
                            key={i}
                          />
                        );
                      }
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div className="passValid">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={userPassword}
                    disabled
                    type="password"
                    width="sm"
                    fullWidth
                  />
                </div>
                <div className="numbers">
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("9")}
                    value={9}
                  >
                    9
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("8")}
                    value={8}
                  >
                    8
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("7")}
                    value={7}
                  >
                    7
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("6")}
                    value={6}
                  >
                    6
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("5")}
                    value={5}
                  >
                    5
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("4")}
                    value={4}
                  >
                    4
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("3")}
                    value={3}
                  >
                    3
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("2")}
                    value={2}
                  >
                    2
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("1")}
                    value={1}
                  >
                    1
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("0")}
                    value={0}
                  >
                    0
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={handleNumRemove}
                    placeholder="Remove"
                  >
                    X
                  </Button>
                </div>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirm}
        onClose={handleClickOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dataAdmin.activeAdmin.name}!, Are you sure you want to add{" "}
            {form.quantity} /lbs {currentProduct} to processing?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpen} color="primary">
            Back
          </Button>
          <Button onClick={addProcessFunc} color="secondary" autoFocus>
            Approved
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <div>
          <DialogTitle id="form-dialog-title">
            Add Received Material
          </DialogTitle>
          <DialogContent>
            <FormControl required variant="outlined" className="width100">
              <InputLabel id="category">Select Stock</InputLabel>
              <Select
                value={form.stock_un || ""}
                onChange={updateField}
                name="stock_un"
                label="Stock"
              >
                {dataStockUn.stock_uns &&
                  dataStockUn.stock_uns.map((stock) => {
                    if (stock.left > 0)
                      return (
                        <MenuItem
                          key={stock.id}
                          value={stock.id}
                          className="stocklist"
                        >
                          {stock.product.name}{" "}
                          <div className="stocknumber">{stock.left}/lbs</div>{" "}
                          <div className="stocknumb">{stock.stock_number}</div>
                        </MenuItem>
                      );
                  })}
              </Select>
            </FormControl>
            <br />

            <TextField
              variant="outlined"
              margin="normal"
              name="quantity"
              label="Qty will be used (in lbs):"
              required={true}
              type="number"
              fullWidth
              value={quantity}
              onChange={updateField}
            />
            {currentProductCategory === "super-chew" ? (
              <TextField
                variant="outlined"
                margin="normal"
                name="projected_units"
                label="Projected Units"
                required={true}
                type="number"
                fullWidth
                onChange={updateField}
              />
            ) : (
              ""
            )}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  name="batchNumber"
                  label="Batch Number"
                  type="text"
                  value={batchNumber}
                  onChange={handleChange}
                  width="50%"
                  fullWidth
                />
              </Grid>
            </Grid>

            <TextField
              variant="outlined"
              multiline
              rows={4}
              margin="normal"
              value={form.comments}
              name="comments"
              label="Is there anything special with this batch?"
              type="text"
              fullWidth
              onChange={updateField}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              onClick={handleClickOpenConfirm}
              color="secondary"
              disabled={formValidate()}
            >
              Add Stock
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      {true ? (
        <StockUI
          filteredData={filteredData}
          refetch={refetch}
          open={handleClickOpen}
          me={dataAdmin.activeAdmin}
          loading={loading}
          subscribeToProcessList={() =>
            // subscribe query
            subscribeToMore({
              document: PROCESS_SUBSCRIPTION,
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const newProcess = subscriptionData.data.inv_process;
                if (
                  !prev.inv_processs.find(
                    (inv_process) => inv_process.id === newProcess.id
                  )
                ) {
                  return {
                    ...prev,
                    inv_processs: [newProcess, ...prev.inv_processs],
                  };
                } else {
                  return prev;
                }
              },
            })
          }
        />
      ) : (
        ""
      )}
    </div>
  );
}

export function CollapsibleTable(props) {
  const processState = {
    quantity_scrapped: "",
    total_processed: "",
    units_made: "",
    pieces_grate: "",
    last_cart: "",
    carts_used: "",
    comments_process: "",
    process_yield: "",
  };

  const dryerState = {
    dryer_room: "",
    dryer_temp: "",
    dryer_hum: "",
  };

  const [open, setOpen] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [selectUser, setSelectUser] = useState("");
  const [selectUserName, setSelectUserName] = useState(props.me.name);
  const [currentSection, setCurrentSection] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [me, setMe] = useState(props.me.id);
  const [openDryerStart, setOpenDryerStart] = useState(false);
  const [openDryerStop, setOpenDryerStop] = useState(false);
  const [openAddBins, setOpenAddBins] = useState(false);
  const [askBins, setAskBins] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [openSplit, setOpenSplit] = useState(false);
  const [openStartPacking, setOpenStartPacking] = useState(false);
  const [openProcessStart, setOpenProcessStart] = useState(false);
  const [openProcessEnd, setOpenProcessEnd] = useState(false);
  const [openProcessEndSure, setOpenProcessEndSure] = useState(false);
  const [formDryerStart, setDrayerStart] = useState(dryerState);
  const [formProcess, setProcessEnd] = useState(processState);
  const [changeLbs, setChangeLbs] = useState(false);
  const [totalActiveBins, setTotalActiveBins] = useState(0);
  const [skipQuery, setSkipQuery] = useState(true);
  const [currentTime, setCurrentTime] = useState(moment().unix());

  const [lastSplitBatch, setLastSplitBatch] = useState("");
  const [lastSplitNumber, setLastSplitNumber] = useState("");
  const [sliceAdmin, setSliceAdmin] = useState("");
  const [dryerStopComment, setDryerStopComment] = useState("");
  const [unitScrapped, setUnitScrapped] = useState(0);
  const [dryerHours, setDryerHours] = useState(0);
  const [dryerHoursLeft, setDryerHoursLeft] = useState([]);
  const [inputList, setInputList] = useState([
    { bin: 1, weight: "", status: 1 },
  ]);
  const [totalBins, setTotalBins] = useState("");

  const [newSplit, setNewSplit] = useState("");
  const [currentBins, setCurrentBins] = useState([]);
  const [usedBins, setUsedBins] = useState({});
  const [usedBinsTotal, setUsedBinsTotal] = useState(0);
  const [totalBags, setTotalBags] = useState(0);
  const [leftBins, setLeftBins] = useState(0);
  const [bagsPacked, setBagsPacked] = useState("");
  const disabledBagsPacked = useRef(true);
  const disabledSelectBins = useRef(false);

  const process = props.process;

  const [allPackeds, setAllPackeds] = useState(
    process.packed ? JSON.parse(process.packed) : []
  );

  // mutations
  const { data: dataAdminList, loading: loadingGetAdmin } = useQuery(
    GET_ADMIN,
    {
      variables: { id: props.me.id },
    }
  );
  const [processStart, { loading: loadingStartProcess }] =
    useMutation(START_PROCESS);
  const [dryerStart, { loading: loadingStartDryer }] = useMutation(START_DRYER);
  const [dryerStop, { loading: loadingStopDryer }] = useMutation(STOP_DRYER);
  const [addBins, { loading: loadingAddBins }] = useMutation(ADD_BINS);
  const [addPacked, { loading: loadingAddPacked, error: addPackedError }] =
    useMutation(ADD_PACKED);
  const [splitMaterial, { loading: loadingSplit }] = useMutation(SPLIT_PROCESS);
  const [addProcess, { loading: loadingAddProcess }] = useMutation(ADD_PROCESS);
  const [passValid, { loading: loadingPassValid }] = useMutation(PASS_VALID);
  const [addStock, { loading: loadingAddStock }] = useMutation(ADD_STOCK);
  const [updateStatus, { loading: loadingUpdateStatus }] =
    useMutation(UPDATE_STATUS);

  const {
    loading: loadingAdmins,
    error: errorAdmins,
    data: dataAdmins,
  } = useQuery(GET_ADMINS);

  const category_colors = {
    "muscle-meat": "#e35267",
    "organ-meat": "#804b97",
    seafood: "#3c6eb0",
    "light-chew": "#ccc",
    "medium-chew": "#ccc",
    "heavy-chew": "#ccc",
    "super-chew": "#fec100",
    secretshop: "#ccc",
    "secretshop-lbs": "#ccc",
  };

  const [processEnd, { loading: loadingEndProcess, error: loadingEndError }] =
    useMutation(END_PROCESS);

  const [
    getBins,
    { loading: loadingGetBins, data: dataGetBins, error: dataError },
  ] = useLazyQuery(GET_PROCESS, {
    fetchPolicy: "no-cache",
    variables: {
      id: process.id,
    },
    onCompleted: (data) => {
      //console.log("data", data.inv_process.bins);
      setCurrentBins(JSON.parse(data.inv_process.bins));
    },
  });

  useEffect(() => {
    let total = 0;
    inputList.map((x) => (total += x.weight ? parseInt(x.weight) : 0));
    setTotalBins(total);
  }, [inputList]);

  useEffect(() => {
    if (userPassword.length === 4) {
      passValidFunc();
    }
  }, [userPassword]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenProcessStart(false);
    setOpenPassword(false);
    setOpenProcessEnd(false);
    setOpenProcessEndSure(false);
    setOpenDryerStart(false);
    setOpenDryerStop(false);
    setOpenSplit(false);
    setSelectUser("");
    setOpenAddBins(false);
    setAskBins(false);
    setOpenStartPacking(false);
    setLoadingButton(false);
    resetState();
    setInputList([{ bin: "", weight: "", status: 1 }]);
    setCurrentBins([]);
    setUsedBinsTotal(0);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { bin: "", weight: "", status: 1 }]);
  };

  const handleClickOpenDryerStart = () => {
    const now2 = moment().unix();
    setCurrentTime(now2);
    if (
      dataAdminList.admin.permission.god.admin ||
      dataAdminList.admin.permission.inventory.admin
    ) {
      setCurrentTime(moment().unix());
      setOpenDryerStart(true);
    } else if (dataAdminList.admin.permission.inventory.process) {
      setCurrentSection("startDryer");
      setOpenPassword(true);
    }
  };
  const handleClickOpenDryerStop = () => {
    const now2 = moment().unix();
    setCurrentTime(now2);

    let now = moment().format("DD/MM/YYYY HH:mm:ss");
    let then = moment.unix(process.dryer_ds).format("DD/MM/YYYY HH:mm:ss");
    let ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
      moment(then, "DD/MM/YYYY HH:mm:ss")
    );
    let d = moment.duration(ms);
    let s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
    setDryerHours(s.split(":")[0]);

    if (
      dataAdminList.admin.permission.god.admin ||
      dataAdminList.admin.permission.inventory.admin
    ) {
      setOpenDryerStop(true);
    } else if (dataAdminList.admin.permission.inventory.process) {
      setCurrentSection("stopDryer");
      setOpenPassword(true);
    }
  };

  const handleClickSplit = () => {
    if (
      dataAdminList.admin.permission.god.admin ||
      dataAdminList.admin.permission.inventory.admin
    ) {
      setOpenSplit(true);
      splitBatch();
    } else if (dataAdminList.admin.permission.inventory.process) {
      setCurrentSection("splitProcess");
      setOpenPassword(true);
    }
  };

  const handleClickOpenProcessStart = () => {
    if (
      dataAdminList.admin.permission.god.admin ||
      dataAdminList.admin.permission.inventory.admin
    ) {
      setOpenProcessStart(true);
    } else if (dataAdminList.admin.permission.inventory.process) {
      setCurrentSection("processStart");
      setOpenPassword(true);
    }
  };

  const handleClickOpenStartPacking = () => {
    const now2 = moment().unix();
    setCurrentTime(now2);
    if (
      dataAdminList.admin.permission.god.admin ||
      dataAdminList.admin.permission.inventory.admin
    ) {
      setOpenStartPacking(true);
      changeStatusLbs();
      getBins();
    } else if (dataAdminList.admin.permission.inventory.process) {
      changeStatusLbs();
      getBins();
      setCurrentSection("startPacking");
      setOpenPassword(true);
    }
  };

  const handleAskBins = () => {
    setAskBins(true);
    setOpenAddBins(false);
  };

  const handleClickOpenAddBins = () => {
    if (
      dataAdminList.admin.permission.god.admin ||
      dataAdminList.admin.permission.inventory.admin
    ) {
      setOpenAddBins(true);
      changeStatusLbs();
    } else if (dataAdminList.admin.permission.inventory.process) {
      setCurrentSection("addBins");
      setOpenPassword(true);
      changeStatusLbs();
    }
  };

  const handleClickOpenProcessEndSure = () => {
    setOpenProcessEndSure(true);
    setOpenProcessEnd(false);
  };

  const handleClickCloseProcessEndSure = () => {
    setOpenProcessEndSure(false);
    setOpenProcessEnd(true);
  };

  const handleClickOpenProcessEnd = () => {
    if (
      dataAdminList.admin.permission.god.admin ||
      dataAdminList.admin.permission.inventory.admin
    ) {
      setOpenProcessEnd(true);
      changeStatusLbs();
    } else if (dataAdminList.admin.permission.inventory.process) {
      setCurrentSection("processEnd");
      setOpenPassword(true);
    }
  };

  const handleSelectUser = (e) => {
    setSelectUser(e.target.value);
    //console.log(selectUser);
  };

  const handlePassword = (e) => {
    setUserPassword(e.target.value);
    //console.log(userPassword);
  };

  const changeStatusLbs = () => {
    if (
      process.stock_un.product.category === "muscle-meat" ||
      process.stock_un.product.category === "seafood" ||
      process.stock_un.product.category === "organ-meat" ||
      process.stock_un.product.category === "secretshop-lbs"
    ) {
      setChangeLbs(true);
    } else {
      setChangeLbs(false);
    }
  };

  const splitBatch = () => {
    const newNum = parseInt(process.lastsplit) + 1;
    const lastsplit = newNum > 9 ? newNum : `0${newNum}`;
    const batchnumber = `${process.batch_number}-${lastsplit}`;
    setLastSplitBatch(batchnumber);
    setLastSplitNumber(lastsplit);
  };

  const updateProcess = (e) => {
    // max 20 last card
    if (e.target.name === "last_cart") {
      //last card max value
      if (e.target.value <= 50)
        setProcessEnd({
          ...formProcess,
          [e.target.name]: e.target.value,
        });
    } else if (e.target.name === "pieces_grate") {
      if (e.target.value <= 50)
        setProcessEnd({
          ...formProcess,
          [e.target.name]: e.target.value,
        });
    } else {
      setProcessEnd({
        ...formProcess,
        [e.target.name]: e.target.value,
      });
      //console.log(formProcess);
    }
    let total = process.quantity - formProcess.quantity_scrapped;
    //console.log(typeof total);
    //console.log(process.quantity);
    ////console.log((process.quantity - formProcess.quantity_scrapped) / process.quantity);
    ////console.log(((formProcess.carts_used - 1) * 20 +
    //parseInt(formProcess.last_cart)) *
    //formProcess.pieces_grate);
  };

  const updateDryerStart = (e) => {
    setDrayerStart({
      ...formDryerStart,
      [e.target.name]: e.target.value,
    });
  };

  const resetState = () => {
    setProcessEnd({ ...processState });
  };

  const startProcessFunc = () => {
    processStart({
      variables: {
        id: process.id,
        process_dp: moment().unix(),
        process_ts: moment().unix(),
        who_ts: me,
        status: 2,
      },
    }).then(async ({ data }) => {
      //console.log(data);
      setLoadingButton(true);
      await props.refetch();
      handleClose();
    });
  };

  const handleSplit = (e) => {
    setNewSplit(e.target.value);
    ////console.log(newSplit);
    ////console.log(lastSplitNumber);
  };

  const handleAdmin = (e) => {
    setSliceAdmin(e.target.value);
    //console.log(sliceAdmin);
  };

  const handleStopComment = (e) => {
    setDryerStopComment(e.target.value);
    //console.log(dryerStopComment);
  };

  const selectBins = (e) => {
    let total = 0;
    // let left  = 1;
    let bins = [];
    if (e.target.checked) {
      currentBins.map((item, i) => {
        let status = i === parseInt(e.target.name) ? 0 : item.status;
        bins.push({
          bin: item.bin,
          weight: item.weight,
          status,
          recStatus: true,
        });
        if (status === 0) total++;
      });
      //console.log(usedBinsTotal);
      let calculateBinsTotal = parseFloat(
        Number(usedBinsTotal) + Number(e.target.value)
      ).toFixed(2);
      //console.log(calculateBinsTotal);
      setUsedBinsTotal(calculateBinsTotal);
    } else {
      currentBins.map((item, i) => {
        let status = i === parseInt(e.target.name) ? 1 : item.status;
        bins.push({
          bin: item.bin,
          weight: item.weight,
          status,
          recStatus: false,
        });
        if (status === 0) total++;
      });
      //console.log(usedBinsTotal);
      let calculateBinsTotal = parseFloat(
        Number(usedBinsTotal) - Number(e.target.value)
      ).toFixed(2);
      setUsedBinsTotal(calculateBinsTotal);
    }

    setCurrentBins(bins);
    setLeftBins(total === currentBins.length ? 0 : 1);
    disabledBagsPacked.current = total === 0 ? true : false;
  };

  const handleBagsPacked = (e) => {
    setBagsPacked(e.target.value);
    disabledSelectBins.current = e.target.value.length > 0 ? true : false;
  };

  const handleUnitsScrap = (e) => {
    setUnitScrapped(e.target.value);
  };

  const addPacking = async () => {
    let dataset = {};
    // let bins = "";
    // currentBins.map(field => {
    //   if (field.bin != undefined && field.recStatus ) {
    //     bins += `bin ${field.bin} `;
    //   }
    // });
    // dataset["bins"] = bins;
    dataset["total"] = usedBinsTotal;
    dataset["bags"] = parseInt(bagsPacked);
    dataset["date"] = moment().unix();
    dataset["who"] = selectUserName;
    dataset["whoId"] = me;
    allPackeds.push(dataset);
    await setAllPackeds(allPackeds);
    disabledBagsPacked.current = false;
    //console.log(allPackeds);
    //console.log(bagsPacked);
    //console.log(JSON.stringify(allPackeds));
    // setTotalBags(totalBags + parseInt(bagsPacked));
    await addPackedFunc();
  };

  const splitFunc = () => {
    addProcess({
      variables: {
        stock_un: process.stock_un.id,
        batch_number: lastSplitBatch,
        quantity: newSplit,
        split: true,
        rec_date: moment().unix(),
        createdAt: moment().unix(),
        admin_id: me,
        projected_units: process.projected_units,
        status: 1,
        comments: process.comments,
      },
    }).then(async ({ data }) => {
      resetState();
      handleClose();
      setLoadingButton(true);
      await props.refetch();
      //console.log(data);
    });
    splitMaterial({
      variables: {
        id: process.id,
        lastsplit: lastSplitNumber,
        quantity: (process.quantity - newSplit).toString(),
      },
    }).then(async ({ data }) => {
      //console.log(data);
      setLoadingButton(true);
      await props.refetch();
      handleClose();
    });
  };

  const endProcessFunc = () => {
    let process_yield =
      (process.quantity - formProcess.quantity_scrapped) / process.quantity;

    //console.log(typeof process_yield);
    processEnd({
      variables: {
        id: process.id,
        quantity_scrapped: formProcess.quantity_scrapped,
        total_processed: process.quantity - formProcess.quantity_scrapped,
        units_made:
          ((formProcess.carts_used - 1) * 20 +
            parseInt(formProcess.last_cart)) *
          formProcess.pieces_grate,
        pieces_grate: formProcess.pieces_grate,
        grates_used: formProcess.last_cart,
        carts_used: formProcess.carts_used,
        comments_process: formProcess.comments_process,
        slice_operator: sliceAdmin ? sliceAdmin : me,
        process_yield: process_yield ? process_yield.toString() : 1,
        process_tf: moment().unix(),
        batch_record: me,
        who_te: me,
        status: 3,
      },
    }).then(async ({ data }) => {
      //console.log(data);
      setLoadingButton(true);
      await props.refetch();
      handleClose();
    });
  };

  const startDryerFunc = () => {
    dryerStart({
      variables: {
        id: process.id,
        status: 4,
        dryer_room: formDryerStart.dryer_room,
        dryer_temp: formDryerStart.dryer_temp,
        dryer_hum: formDryerStart.dryer_hum,
        entered_by: me,
        dryer_ds: moment().unix(),
      },
    }).then(async ({ data }) => {
      //console.log(data);
      setLoadingButton(true);
      await props.refetch();
      handleClose();
    });
  };

  const stopDryerFunc = () => {
    dryerStop({
      variables: {
        id: process.id,
        status: 5,
        dryer_df: moment().unix(),
        dryer_hours: parseInt(dryerHours),
        dryer_end_by: me,
        dryer_end_comment: dryerStopComment,
      },
    }).then(async ({ data }) => {
      //console.log(data);
      setLoadingButton(true);
      await props.refetch();
      handleClose();
    });
  };

  const addBinsFunc = () => {
    let bins = [];
    inputList.map((x, i) =>
      bins.push({ bin: i + 1, weight: x.weight, status: x.status })
    );
    let expected_bags_quantity =
      parseInt(process.units_made) /
      parseInt(process.stock_un.product.quantity);
    //console.log(expected_bags_quantity);
    let expected_bags_weight =
      (totalBins * 16) / parseInt(process.stock_un.product.weight);
    //console.log();
    addBins({
      variables: {
        id: process.id,
        status: 6,
        bins_date: moment().unix(),
        bins: JSON.stringify(bins),
        bins_empitied_who: me,
        bins_total: totalBins,
        expected_bags: parseInt(expected_bags_quantity)
          ? Math.round(parseInt(expected_bags_quantity))
          : Math.round(expected_bags_weight),
      },
    }).then(async ({ data }) => {
      //console.log(data);
      setLoadingButton(true);
      await props.refetch();
      handleClose();
    });
  };

  const addPackedFunc = () => {
    //console.log(JSON.stringify(currentBins));
    //console.log(JSON.stringify(allPackeds));
    //console.log('totala', totalBags);
    let total_bags = 0;
    allPackeds.map((x) => (total_bags += x.bags));

    //added
    let waste = 0;
    if (!changeLbs) {
      waste =
        process.units_made - total_bags * process.stock_un.product.quantity;
    } else {
      waste =
        process.bins_total -
        total_bags * (process.stock_un.product.weight / 16);
    }
    addPacked({
      variables: {
        id: process.id,
        status: leftBins === 0 ? 7 : 6,
        bins: JSON.stringify(currentBins),
        packed: JSON.stringify(allPackeds),
        total_bags,
        //waste: leftBins === 0 ? waste : 0 //added bakılacak
      },
    }).then(async ({ data }) => {
      await addInventoryFunc();
      props.refetch();
      //console.log(addPackedError);
      setUsedBinsTotal(0);
      disabledSelectBins.current = false;
      setBagsPacked(0);
      disabledBagsPacked.current = true;
    });
  };

  const addInventoryFunc = () => {
    addStock({
      variables: {
        product: process.stock_un.product.id,
        process: process.id,
        stock: parseInt(bagsPacked),
        left: parseInt(bagsPacked),
        rec_date: moment().unix(),
        admin_id: me,
      },
    }).then(async ({ data }) => {
      //console.log("stock", data);
    });
  };

  useEffect(() => {
    if (process.status === 6 && process.bins) {
      let total = JSON.parse(process.bins).filter(
        (field) => field.status === 1
      );
      setTotalActiveBins(total.length);
    }
    //ß//console.log((totalBins * 16) / parseInt(process.stock_un.product.weight))
  }, [addBinsFunc]);

  // validation

  const splitValidation = () => {
    return !newSplit;
  };

  const stopProcessingValidation = () => {
    if (changeLbs) {
      return (
        !formProcess.quantity_scrapped ||
        !formProcess.carts_used ||
        !formProcess.last_cart ||
        !sliceAdmin
      );
    }
    return (
      !formProcess.quantity_scrapped ||
      !formProcess.pieces_grate ||
      !formProcess.carts_used ||
      !formProcess.last_cart
    );
  };

  const startDryerValidation = () => {
    return !formDryerStart.dryer_room;
  };

  const addBinsValidation = () => {
    return !totalBins;
  };

  const passValidFunc = () => {
    //console.log(selectUser, userPassword);
    passValid({
      variables: {
        email: selectUser,
        pass: parseInt(userPassword),
      },
    }).then(async ({ data }) => {
      if (data.passValid.access) {
        setSelectUserName(data.passValid.name);
        setMe(data.passValid.id);
        setOpenPassword(false);
        setUserPassword("");
        if (currentSection === "splitProcess") {
          setOpenSplit(true);
        } else if (currentSection === "processStart") {
          setOpenProcessStart(true);
        } else if (currentSection === "processEnd") {
          setOpenProcessEnd(true);
        } else if (currentSection === "startDryer") {
          setOpenDryerStart(true);
        } else if (currentSection === "stopDryer") {
          setOpenDryerStop(true);
        } else if (currentSection === "addBins") {
          setOpenAddBins(true);
        } else if (currentSection === "startPacking") {
          setOpenStartPacking(true);
        }
        //console.log(me);
      } else {
        setUserPassword("");
      }
      //console.log(data);
    });
  };

  const handleNum = (num) => {
    //added
    if (userPassword.length < 4) {
      setUserPassword(userPassword + num);
      //console.log(userPassword);
      //process verify
    }
  };

  const handleNumRemove = () => {
    //remove last
    setUserPassword(userPassword.substring(0, userPassword.length - 1));
  };
  //Tolgay

  const [rspdState, setRspdState] = useState(false);
  const handleRSP = () => {
    updateStatus({
      variables: {
        id: process.id,
        admin_id: dataAdminList.admin.id,
        status: 2,
      },
    }).then(async ({ data }) => {
      setRspdState(false);
      setOpenDryerStart(false);
    });
  };

  const [rs2pdState, setRs2pdState] = useState(false);

  const handleRS2P = () => {
    updateStatus({
      variables: {
        id: process.id,
        admin_id: dataAdminList.admin.id,
        status: 3,
      },
    }).then(async ({ data }) => {
      setRs2pdState(false);
      setOpenDryerStop(false);
    });
  };

  const [spdState, setSpdState] = useState(false);
  const handleSPD = () => {
    updateStatus({
      variables: {
        id: process.id,
        admin_id: dataAdminList.admin.id,
        status: 5,
      },
    }).then(async ({ data }) => {
      setSpdState(false);
      setOpenStartPacking(false);
    });
  };

  useEffect(() => {
    const now = moment().unix();
    setCurrentTime(now);
    console.log("now", now);
  }, [rs2pdState]);

  return (
    <React.Fragment>
      <Dialog
        open={openPassword}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">Verify Account</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    onChange={handleSelectUser}
                  >
                    {dataAdmins.admins.map((admin, i) => {
                      if (admin.permission.inventory.display) {
                        return (
                          <FormControlLabel
                            className="usersradioselect"
                            value={admin.email}
                            control={<Radio />}
                            label={admin.name}
                            key={i}
                          />
                        );
                      }
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="passValid">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={userPassword}
                    disabled
                    type="password"
                    width="sm"
                    fullWidth
                  />
                </div>
                <div className="numbers">
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("9")}
                    value={9}
                  >
                    9
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("8")}
                    value={8}
                  >
                    8
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("7")}
                    value={7}
                  >
                    7
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("6")}
                    value={6}
                  >
                    6
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("5")}
                    value={5}
                  >
                    5
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("4")}
                    value={4}
                  >
                    4
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("3")}
                    value={3}
                  >
                    3
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("2")}
                    value={2}
                  >
                    2
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("1")}
                    value={1}
                  >
                    1
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={() => handleNum("0")}
                    value={0}
                  >
                    0
                  </Button>
                  <Button
                    disabled={selectUser ? false : true}
                    onClick={handleNumRemove}
                    placeholder="Remove"
                  >
                    X
                  </Button>
                </div>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSplit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          Split ({process.stock_un.product.name})
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to split the process?
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            disabled
            label="Total Quantity /lbs"
            type="text"
            value={process.quantity - newSplit}
            width="50%"
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="normal"
            disabled
            label="Batch Number"
            type="text"
            value={lastSplitBatch}
            width="50%"
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="normal"
            label="Split Material Quantity /lbs"
            type="text"
            onChange={handleSplit}
            value={newSplit}
            width="50%"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={splitFunc}
            disabled={loadingButton || splitValidation()}
            autoFocus
          >
            Approved by {selectUserName}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddBins}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          Add Bins ({process.stock_un.product.name}{" "}
          {process.stock_un.product.category})
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please add all bins.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                disabled
                label="Emptied By Whom"
                type="text"
                value={selectUserName}
                width="50%"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                disabled
                label="Date Emptied"
                type="text"
                value={moment().format("DD/MM/YYYY")}
                width="50%"
                fullWidth
              />
            </Grid>
          </Grid>
          {inputList.map((x, i) => {
            return (
              <div key={i}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="bin"
                      variant="outlined"
                      label="Bins"
                      disabled
                      margin="normal"
                      value={i + 1}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="Weight"
                      focus
                      name="weight"
                      value={x.weight}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </Grid>

                  {inputList.length - 1 === i && (
                    <Grid item xs={12} sm={2}>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={handleAddClick}
                        className="mr10"
                      >
                        Add
                      </Button>
                    </Grid>
                  )}

                  {inputList.length !== 1 && (
                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="outlined"
                        className="mr10 redborder"
                        onClick={() => handleRemoveClick(i)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </div>
            );
          })}
          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              margin="normal"
              disabled
              label="Total Weight"
              type="text"
              value={totalBins}
              fullWidth
            />
            {!changeLbs ? (
              <div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  disabled
                  label="Expected bags"
                  type="text"
                  value={
                    parseInt(process.units_made) /
                    parseInt(process.stock_un.product.quantity)
                  }
                  fullWidth
                />
              </div>
            ) : (
              <div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  disabled
                  label="Expected bags (please fill bins for calculate)"
                  type="text"
                  value={
                    (totalBins * 16) / parseInt(process.stock_un.product.weight)
                  }
                  fullWidth
                />
              </div>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              disabled
              label="LOT Yield"
              type="text"
              //value={(totalBins * 16) / parseInt(process.stock_un.product.weight)}
              fullWidth
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={handleAskBins}
            autoFocus
            disabled={addBinsValidation()}
          >
            Approved by {selectUserName}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={askBins}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          Add Bins ({process.stock_un.product.name})
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to add these bins?
            {inputList.map((field, i) => {
              return (
                <div className="binlist" key={i}>
                  Bin: {i + 1}, Weight: <b>{field.weight}</b>
                </div>
              );
            })}
            <TextField
              variant="outlined"
              margin="normal"
              disabled
              label="Total Weight"
              type="text"
              value={totalBins}
              fullWidth
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpenAddBins} color="primary">
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={addBinsFunc}
            disabled={loadingButton}
            autoFocus
          >
            Approved by {selectUserName}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDryerStart}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          Start Dryer ({process.stock_un.product.name})
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to start the dryer?
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  disabled
                  label="Date Started"
                  type="text"
                  value={moment().format("DD/MM/YYYY")}
                  width="50%"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Time Started"
                  value={moment().format("HH:mm:ss")}
                  disabled
                  width="sm"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl required variant="outlined" className="width100">
                  <InputLabel id="dryerid"># Room</InputLabel>
                  <Select
                    value={formDryerStart.dryer_room}
                    onChange={updateDryerStart}
                    name="dryer_room"
                    label="dryerid"
                  >
                    <MenuItem value={"1"}>Room 1</MenuItem>
                    <MenuItem value={"2"}>Room 2</MenuItem>
                    <MenuItem value={"3"}>Room 3</MenuItem>
                    <MenuItem value={"4"}>Room 4</MenuItem>
                    <MenuItem value={"5"}>Room 5</MenuItem>
                    <MenuItem value={"6"}>Room 6</MenuItem>
                    <MenuItem value={"7"}>Room 7</MenuItem>
                    <MenuItem value={"8"}>Room 8</MenuItem>
                    <MenuItem value={"9"}>Room 9</MenuItem>
                    <MenuItem value={"10"}>Room 10</MenuItem>
                    <MenuItem value={"11"}>Room 11</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Entered By"
                  disabled
                  value={props.me.name}
                  width="sm"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Avarage Drying Hour"
                  disabled
                  value={process.stock_un.product.hours}
                  width="sm"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="dryer_temp"
                  label="Temperature"
                  onChange={updateDryerStart}
                  value={formDryerStart.dryer_temp}
                  width="sm"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="dryer_hum"
                  label="Humidity"
                  onChange={updateDryerStart}
                  value={formDryerStart.dryer_hum}
                  width="sm"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <Dialog open={rspdState} onClose={() => setRspdState(false)}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This process will be reverted to previous state.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRspdState(false)}>Disagree</Button>
            <Button onClick={handleRSP} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <DialogActions>
          {process.process_ts + 43200 >= moment().unix() &&
            (dataAdminList.admin.permission.god.admin ||
              dataAdminList.admin.permission.inventory.admin) && (
              <Button
                className="redborder"
                variant="outlined"
                onClick={() => {
                  setRspdState(true);
                }}
              >
                REVERT{" "}
                {moment
                  .utc(
                    moment
                      .unix(process.process_tf + 43200)
                      .diff(moment.unix(currentTime))
                  )
                  .format("HH:mm:ss")}
              </Button>
            )}

          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={startDryerFunc}
            disabled={loadingButton || startDryerValidation()}
          >
            Approved by {selectUserName}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDryerStop}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          Stop Dryer ({process.stock_un.product.name}) Time Left (
        </DialogTitle>
        {openDryerStop && <p>Time</p>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to stop the dryer?
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  disabled
                  label="Date Finished"
                  type="text"
                  value={moment().format("DD/MM/YYYY")}
                  width="50%"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Time Finished"
                  value={moment().format("HH:mm:ss")}
                  disabled
                  width="sm"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Total Hours"
                  disabled
                  value={dryerHours}
                  width="sm"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Product QC'd by"
                  disabled
                  value={selectUserName}
                  width="sm"
                  fullWidth
                />
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              margin="normal"
              value={dryerStopComment}
              name="comments"
              label="Comments"
              type="text"
              fullWidth
              onChange={handleStopComment}
            />
          </DialogContentText>
        </DialogContent>
        <Dialog open={rs2pdState} onClose={() => setRs2pdState(false)}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This process will be reverted to previous state.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRs2pdState(false)}>Disagree</Button>
            <Button onClick={handleRS2P} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <DialogActions>
          {process.dryer_ds + 43200 >= moment().unix() &&
            (dataAdminList.admin.permission.god.admin ||
              dataAdminList.admin.permission.inventory.admin) && (
              <Button
                className="redborder"
                variant="outlined"
                onClick={() => {
                  setRs2pdState(true);
                }}
              >
                REVERT{" "}
                {moment
                  .utc(
                    moment
                      .unix(process.dryer_ds + 43200)
                      .diff(moment.unix(currentTime))
                  )
                  .format("HH:mm:ss")}
              </Button>
            )}

          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={stopDryerFunc}
            disabled={loadingButton}
          >
            Approved by {selectUserName}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openProcessEndSure}
        onClose={handleClickCloseProcessEndSure}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          Complete Processing ({process.stock_un.product.name})
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Time Finished : {moment().format("DD/MM/YYYY HH:mm:ss")}
            <br />
            Quantity Received : {process.quantity}
            <br />
            Quantity Scrapped : {formProcess.quantity_scrapped}
            <br />
            Roughly Process Total of (lbs) :{" "}
            {process.quantity - formProcess.quantity_scrapped}
            <br />
            {!changeLbs ? (
              <div>
                # of Pieces per Grate : {formProcess.pieces_grate} <br />
              </div>
            ) : (
              ""
            )}
            <br /># of grates in the last cart: {formProcess.last_cart}
            <br />
            {!changeLbs ? (
              <div>
                Units Made :{" "}
                {((formProcess.carts_used - 1) * 20 +
                  parseInt(formProcess.last_cart)) *
                  formProcess.pieces_grate}
                <br />
              </div>
            ) : (
              ""
            )}
            Carts Used : {formProcess.carts_used}
            <br />
            {changeLbs ? (
              <div>
                Slice Operator : {sliceAdmin}
                <br />
                Yield :{" "}
                {(process.quantity - formProcess.quantity_scrapped) /
                  process.quantity}
                <br />
              </div>
            ) : (
              ""
            )}
            Batch Record : {props.me.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseProcessEndSure} color="primary">
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={endProcessFunc}
            disabled={loadingButton}
            autoFocus
          >
            Approved by {selectUserName}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openProcessStart}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          Start Processing ({process.stock_un.product.name})
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to start the process?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={startProcessFunc}
            disabled={loadingButton}
            autoFocus
          >
            Approved by {selectUserName}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openStartPacking}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"lg"}
      >
        {loadingGetBins ? (
          <div>
            <div>
              <LinearProgress color="secondary" />
            </div>
          </div>
        ) : (
          <div>
            <DialogTitle id="alert-dialog-title">
              Start Packing ({process.stock_un.product.name}){" "}
              {process.stock_un.product.category}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        disabled
                        label="Date Emptied"
                        type="text"
                        value={moment
                          .unix(process.bins_date)
                          .format("DD/MM/YYYY HH:mm:ss")}
                        width="50%"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {process.status > 5 ? (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          disabled
                          label="Who Emptied"
                          type="text"
                          value={
                            process.bins_empitied_who &&
                            process.bins_empitied_who.name
                              ? process.bins_empitied_who.name
                              : ""
                          }
                          width="50%"
                          fullWidth
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        disabled
                        label={!changeLbs ? "Units Per Bag" : "Weight Per Bag"}
                        type="text"
                        value={
                          !changeLbs
                            ? process.stock_un.product.quantity
                            : process.stock_un.product.weight
                        }
                        width="50%"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        disabled
                        label="Expected Bags:"
                        type="text"
                        value={process.expected_bags}
                        width="50%"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        disabled
                        label="Total Weight"
                        type="text"
                        value={process.bins_total}
                        width="50%"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {!changeLbs ? (
                        // waste kalan kısım tekrar paketlenmeye başlayacak split sonuna X
                        // ürün bittikten sonrs waste varsa gösterilecek.
                        <TextField
                          variant="outlined"
                          margin="normal"
                          disabled
                          label="Waste (in Qty)"
                          type={process.status === 7 ? "number" : "text"}
                          value={
                            process.status === 7
                              ? process.units_made -
                                process.total_bags *
                                  process.stock_un.product.quantity
                              : "Calculating"
                          }
                          width="50%"
                          fullWidth
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          disabled
                          label="Waste (in Lbs)"
                          type={process.status === 7 ? "number" : "text"}
                          value={
                            process.status === 7
                              ? process.bins_total -
                                process.total_bags *
                                  (process.stock_un.product.weight / 16)
                              : "Calculating"
                          }
                          width="50%"
                          fullWidth
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className="addpacking">
                        <Typography variant="h6" gutterBottom>
                          Add Packing
                        </Typography>
                        <DialogContentText id="alert-dialog-description">
                          Select bins and add packing.
                        </DialogContentText>
                        <FormControl component="fieldset">
                          <FormGroup>
                            {process.bins
                              ? JSON.parse(process.bins).map((item, i) => {
                                  return (
                                    <div key={i}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name={i.toString()}
                                            onChange={selectBins}
                                            value={Number(item.weight)}
                                            disabled={
                                              item.status === 0
                                                ? true
                                                : disabledSelectBins.current
                                                ? disabledSelectBins.current
                                                : false
                                            }
                                            // checked={usedBins[`bin-${i}`]}
                                          />
                                        }
                                        label={`Bin ${
                                          item.bin
                                        } (Weight : ${Number(item.weight)})`}
                                      />
                                    </div>
                                  );
                                })
                              : ""}
                          </FormGroup>
                        </FormControl>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              disabled
                              label="Selected Bins Weight"
                              type="text"
                              value={usedBinsTotal}
                              width="50%"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              label="Bags Packed"
                              type="text"
                              value={bagsPacked}
                              onChange={handleBagsPacked}
                              width="50%"
                              fullWidth
                              disabled={disabledBagsPacked.current}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Button
                              color="secondary"
                              variant="outlined"
                              className="mr10"
                              disabled={
                                disabledBagsPacked.current || loadingAddPacked
                              }
                              onClick={addPacking}
                            >
                              Add Packing
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell align="right">Weight</TableCell>
                              <TableCell align="right">Bags Packed</TableCell>
                              <TableCell align="right">
                                Person Counting
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {process.packed
                              ? JSON.parse(process.packed).map((field, i) => (
                                  <TableRow key={i}>
                                    <TableCell component="th" scope="row">
                                      {moment
                                        .unix(field.date)
                                        .format("DD/MM/YYYY HH:mm:ss")}
                                    </TableCell>
                                    <TableCell align="right">
                                      {field.total}
                                    </TableCell>
                                    <TableCell align="right">
                                      {field.bags}
                                    </TableCell>
                                    <TableCell align="right">
                                      {field.who}
                                    </TableCell>
                                  </TableRow>
                                ))
                              : ""}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className="summary">
                    <Grid container spacing={2}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Avg. Item Weight for distribution"
                        type="text"
                        value={parseFloat(
                          (process.bins_total / process.total_bags) * 16
                        ).toFixed(2)}
                        width="50%"
                        fullWidth
                        disabled
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Packing Yield"
                        type="text"
                        value={parseFloat(
                          process.total_bags / process.expected_bags
                        ).toFixed(2)}
                        width="50%"
                        fullWidth
                        disabled
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Cost per item"
                        type="text"
                        value={parseFloat(
                          (process.stock_un.price * process.quantity) /
                            process.total_bags
                        ).toFixed(2)}
                        width="50%"
                        fullWidth
                        disabled
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Qty Received (in lbs)"
                        type="text"
                        value={process.quantity}
                        width="50%"
                        fullWidth
                        disabled
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Total Bags"
                        type="text"
                        value={
                          process.total_bags ? process.total_bags : "Loading..."
                        }
                        width="50%"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <Dialog open={spdState} onClose={() => setSpdState(false)}>
              <DialogTitle>Are you sure?</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This process will be reverted to previous state.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setSpdState(false)}>Disagree</Button>
                <Button onClick={handleSPD} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
            <DialogActions>
              {process.bins_date + 43200 >= moment().unix() &&
                (dataAdminList.admin.permission.god.admin ||
                  dataAdminList.admin.permission.inventory.admin) && (
                  <Button
                    className="redborder"
                    variant="outlined"
                    onClick={() => {
                      setSpdState(true);
                    }}
                  >
                    REVERT{" "}
                    {moment
                      .utc(
                        moment
                          .unix(process.bins_date + 43200)
                          .diff(moment.unix(currentTime))
                      )
                      .format("HH:mm:ss")}
                  </Button>
                )}
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <div>
          <ProcessDetailModal id={process.id} close={handleClose} />
        </div>
      </Dialog>
      <Dialog
        open={openProcessEnd}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          Stop Processing ({process.stock_un.product.name})
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  disabled
                  label="Date Processed"
                  type="text"
                  value={moment.unix(process.process_dp).format("DD/MM/YYYY")}
                  width="50%"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Time Started"
                  value={moment.unix(process.process_ts).format("HH:mm:ss")}
                  disabled
                  width="sm"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  disabled
                  label="Quantity Received"
                  type="number"
                  value={process.quantity}
                  width="50%"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  name="quantity_scrapped"
                  label="Quantity Scrapped (in lbs)"
                  value={formProcess.quantity_scrapped}
                  onChange={updateProcess}
                  width="sm"
                  fullWidth
                />
              </Grid>
              {process.quantity / 10 < formProcess.quantity_scrapped ? (
                <div className="warningbox">
                  It cannot be more than 10% of the quantity of products.
                </div>
              ) : (
                ""
              )}
            </Grid>
            {formProcess.quantity_scrapped ? (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="Roughly Process Total of (lbs)"
                      type="text"
                      disabled
                      name="total_processed"
                      value={process.quantity - formProcess.quantity_scrapped}
                      onChange={updateProcess}
                      width="50%"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {!changeLbs ? (
                      <TextField
                        variant="outlined"
                        margin="normal"
                        name="units_made"
                        disabled
                        label="Units Made"
                        value={
                          formProcess.carts_used &&
                          formProcess.last_cart &&
                          formProcess.pieces_grate
                            ? ((formProcess.carts_used - 1) * 20 +
                                parseInt(formProcess.last_cart)) *
                              formProcess.pieces_grate
                            : 0
                        }
                        onChange={updateProcess}
                        width="sm"
                        fullWidth
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="Processing Yield"
                      type="text"
                      name="process_yield"
                      value={
                        ((process.quantity - formProcess.quantity_scrapped) /
                          process.quantity) *
                        100
                      }
                      disabled
                      width="50%"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {!changeLbs ? (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="# of Pieces per Grate:"
                        type="number"
                        name="pieces_grate"
                        value={formProcess.pieces_grate}
                        onChange={updateProcess}
                        width="50%"
                        fullWidth
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="# of Carts Used:"
                      type="number"
                      name="carts_used"
                      value={formProcess.carts_used}
                      onChange={updateProcess}
                      width="50%"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      name="last_cart"
                      type="number"
                      label="# of grates in the last cart"
                      value={formProcess.last_cart}
                      onChange={updateProcess}
                      inputProps={{ min: "0", max: "10", step: "1" }}
                      width="sm"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {changeLbs ? (
                    <Grid item xs={12} sm={12}>
                      <FormControl
                        required
                        variant="outlined"
                        className="width100"
                      >
                        <InputLabel id="sliceoperator">
                          Slice Operator
                        </InputLabel>
                        <Select
                          name="slice_operator"
                          onChange={handleAdmin}
                          label="sliceoperator"
                          value={sliceAdmin || ""}
                        >
                          {dataAdmins.admins &&
                            dataAdmins.admins.map((admins) => {
                              return (
                                <MenuItem key={admins.id} value={admins.id}>
                                  {admins.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
                <TextField
                  variant="outlined"
                  multiline
                  rows={4}
                  margin="normal"
                  value={formProcess.comments_process}
                  name="comments_process"
                  label="Was there anything special or different about what was processed?			"
                  type="text"
                  fullWidth
                  onChange={updateProcess}
                />
              </div>
            ) : (
              ""
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="secondary"
            autoFocus
            onClick={handleClickOpenProcessEndSure}
            disabled={stopProcessingValidation()}
          >
            Batch Record Completed by {selectUserName}
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow key={process.id}>
        <TableCell
          component="th"
          scope="row"
          className="articletitle"
          width="10%"
        >
          <div
            className="productname"
            style={{
              borderColor: `${
                category_colors[process.stock_un.product.category]
              }`,
            }}
          >
            <b>{process.stock_un.product.name}</b>
          </div>
        </TableCell>

        <TableCell align="left">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleClickOpen}
          >
            <VisibilityIcon />
          </IconButton>
          {process.batch_number}{" "}
          {process.split ? "" : <b className="splitcolor">M</b>}
        </TableCell>
        <TableCell align="left">
          {process.status === 1 ? (
            <Button variant="outlined" color="primary" disabled fullWidth>
              Arrived {moment.unix(process.rec_date).format("DD/MM/YYYY HH:mm")}
            </Button>
          ) : (
            ""
          )}

          {process.status === 2 ? (
            <Button
              variant="outlined"
              color="secondary"
              className="greenborder"
              disabled
              fullWidth
            >
              Processing{" "}
              {moment.unix(process.process_ts).format(" DD/MM/YYYY HH:mm ")}
              <CircularProgress
                color="secondary"
                size={20}
                className="floatrightcircular"
              />
            </Button>
          ) : (
            ""
          )}
          {process.status === 3 ? (
            <Button variant="outlined" color="primary" disabled fullWidth>
              Processed{" "}
              {moment.unix(process.process_tf).format("DD/MM/YYYY HH:mm")}
            </Button>
          ) : (
            ""
          )}
          {process.status === 4 ? (
            <Button
              variant="outlined"
              color="secondary"
              className="yellowborder"
              disabled
              fullWidth
            >
              Drying{" "}
              {moment.unix(process.dryer_ds).format(" DD/MM/YYYY HH:mm ")}
              <CircularProgress
                color="primary"
                size={20}
                className="floatrightcircular"
              />
            </Button>
          ) : (
            ""
          )}
          {process.status === 5 ? (
            <Button variant="outlined" color="primary" disabled fullWidth>
              Dried {moment.unix(process.dryer_df).format("DD/MM/YYYY HH:mm")}
            </Button>
          ) : (
            ""
          )}
          {process.status === 6 ? (
            <Button variant="outlined" color="primary" disabled fullWidth>
              <b className="activebins">{totalActiveBins} Bins Waiting</b> -{" "}
              {moment.unix(process.bins_date).format("DD/MM/YYYY HH:mm")}
            </Button>
          ) : (
            ""
          )}
          {process.status === 7 ? (
            <Button
              variant="outlined"
              color="secondary"
              className="blueborder opacity1"
              disabled
              fullWidth
            >
              <b className="greenborder">Packaging Completed</b> <Check />
            </Button>
          ) : (
            ""
          )}
        </TableCell>

        <TableCell align="left">
          {process.status === 1 ? (
            <div>
              <ButtonGroup
                fullWidth
                color="secondary"
                aria-label="outlined primary button group"
              >
                {!process.split ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ maxWidth: "80px" }}
                    onClick={handleClickSplit}
                  >
                    <CallSplitIcon />
                  </Button>
                ) : (
                  ""
                )}

                <Button
                  variant="outlined"
                  color="secondary"
                  className="bluecolor"
                  fullWidth
                  onClick={handleClickOpenProcessStart}
                >
                  Start Processing
                </Button>
              </ButtonGroup>
            </div>
          ) : (
            ""
          )}
          {process.status === 2 ? (
            <Button
              variant="outlined"
              className="redborder"
              onClick={handleClickOpenProcessEnd}
              fullWidth
            >
              Stop Processing
            </Button>
          ) : (
            ""
          )}
          {process.status >= 3 ? (
            <Button
              disabled
              fullWidth
              variant="outlined"
              className="greenborder opacity1"
            >
              Completed <Check />
            </Button>
          ) : (
            ""
          )}
        </TableCell>

        <TableCell align="center">
          {process.status === 3 ? (
            <Button
              fullWidth
              variant="outlined"
              className="yellowborder"
              onClick={handleClickOpenDryerStart}
            >
              Start Dryer
            </Button>
          ) : (
            ""
          )}
          {process.status < 3 ? (
            <Button
              variant="outlined"
              color="secondary"
              className="dryingbutton"
              disabled
              fullWidth
              onClick={handleClickOpenDryerStart}
            >
              Start Dryer
            </Button>
          ) : (
            ""
          )}
          {process.status === 4 ? (
            <Button
              variant="outlined"
              color="secondary"
              className="redborder"
              fullWidth
              onClick={handleClickOpenDryerStop}
            >
              Stop Dryer
            </Button>
          ) : (
            ""
          )}
          {process.status >= 5 ? (
            <Button
              disabled
              fullWidth
              variant="outlined"
              className="yellowborder opacity1"
            >
              Completed <Check />
            </Button>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          {process.status < 5 ? (
            <Button
              variant="outlined"
              color="secondary"
              className="packingbutton"
              disabled
            >
              Start Packing
            </Button>
          ) : (
            ""
          )}

          {process.status === 5 ? (
            <Button
              variant="outlined"
              color="secondary"
              className="blueborder"
              fullWidth
              disabled={loadingAddBins}
              onClick={handleClickOpenAddBins}
            >
              Add Bins
              {loadingAddBins && <SpinnerAdornment />}
            </Button>
          ) : (
            ""
          )}
          {process.status === 6 ? (
            <Button
              variant="outlined"
              color="secondary"
              className="packingbutton"
              onClick={handleClickOpenStartPacking}
            >
              Start Packing
            </Button>
          ) : (
            ""
          )}
          {process.status === 7 ? (
            <Button
              variant="outlined"
              color="secondary"
              className="packingbutton"
              className="blueborder"
              onClick={handleClickOpenStartPacking}
            >
              View Details
            </Button>
          ) : (
            ""
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export class StockUI extends Component {
  componentDidMount() {
    this.props.refetch();
    this.props.subscribeToProcessList();
    //console.log(this.props);
  }

  render() {
    return (
      <div>
        <div className="pagetop">
          <div className="left">
            <Breadcrumbs aria-label="breadcrumb">
              <a color="inherit" href="/">
                Dashboard
              </a>
              <Typography color="textPrimary">Processing</Typography>
            </Breadcrumbs>
            <div className="infobar">
              “Date”-“batch of the day”-“split processing”-“split packing”
            </div>
          </div>
          <div className="right">
            <Button
              variant="outlined"
              color="primary"
              className="marginright10"
              href="/dashboard/processing/all"
            >
              Archive
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={this.props.open}
            >
              Add Received Material
            </Button>
          </div>
        </div>
        <div>
          <TableContainer component={Paper}>
            <Table
              data={this.props.inv_processs}
              aria-label="collapsible table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell align="left">Batch Number</TableCell>
                  <TableCell align="left">Status</TableCell>

                  <TableCell align="center">Processing</TableCell>
                  <TableCell align="center">Drying</TableCell>
                  <TableCell align="center">Packing</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.props.filteredData.map((process) => (
                  <CollapsibleTable
                    key={process.id}
                    process={process}
                    me={this.props.me}
                    refetch={this.props.refetch}
                    loadingMain={this.props.loading}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}

export default Process;
