import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { CUSTOMER_ADDRESSES } from "../../../../../queries";
import { FETCH_DOG_BY_ADDRESS_ID } from "../../../../../queries";
import AddressDialog from "../addressDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

const ShippingAddress = ({
  customerMongoId,
  customerMongoEmail,
  fetchAgain,
  customerRefecth,
}) => {
  const classes = useStyles();
  const { data, loading, error, refetch } = useQuery(CUSTOMER_ADDRESSES, {
    variables: {
      owner: customerMongoId,
    },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (fetchAgain > 0) {
      refetch();
    }
  }, [fetchAgain]);
  const [open, setOpen] = useState(false);
  if (loading) {
    return <div>loading</div>;
  }

  return (
    <Grid item>
      <Paper className={classes.paper}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={6}>
            Address List
          </Grid>
          <Grid item alignItems="flex-end" xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              className="floatright"
              onClick={() => {
                setOpen(true);
              }}
            >
              Add Address
            </Button>
          </Grid>
        </Grid>
        <br />
        <Alert severity="warning">Please remove duplicate addresses.</Alert>

        <Addresslist
          userAddressList={data.ownerAddress}
          refetch={() => {
            refetch();
            customerRefecth();
          }}
          customerMongoEmail={customerMongoEmail}
          fetchAgain={fetchAgain}
        />
      </Paper>
      {open && (
        <AddressDialog
          open={open}
          close={() => {
            setOpen(false);
          }}
          customerMongoEmail={customerMongoEmail}
          refetch={() => {
            refetch();
            customerRefecth();
          }}
          newAddress={true}
        />
      )}
    </Grid>
  );
};
export default ShippingAddress;

const Addresslist = ({
  userAddressList,
  onSelectAddress = () => {},
  setEditSaveOpen = () => {},
  setAddressOpenType = () => {},
  refetch,
  customerMongoEmail,
  fetchAgain,
}) => {
  const [open, setOpen] = React.useState(
    new Array(userAddressList.length).fill(false)
  );
  const [addressBoundedList, setAddressBoundedList] = useState(
    new Array(userAddressList.length).fill(false)
  );
  const [localAddress, setLocalAddress] = React.useState({});

  return (
    <List>
      {userAddressList.map((address, index) => {
        const labelId = `checkbox-list-label-${address}`;
        return (
          <ListItem
            key={address}
            role={undefined}
            dense
            button
            onClick={() => {}}
          >
            <ListItemText
              id={labelId}
              primary={
                <div
                  style={{
                    width: "80%",
                  }}
                >
                  {`${index + 1}. ${address.name} / ${address.address} / `}
                  {`${address.city} / ${address.state} / ${address.postal_code}`}
                </div>
              }
            />

            <ListItemSecondaryAction>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <DogsOfAddressMenu
                  addressId={address.id}
                  fetchAgain={fetchAgain}
                  setAddressBounded={() => {
                    let copyArr = JSON.parse(
                      JSON.stringify(addressBoundedList)
                    );
                    copyArr[index] = true;
                    setAddressBoundedList(copyArr);
                  }}
                />
                <IconButton
                  edge="end"
                  aria-label="comments"
                  onClick={() => {
                    let openCopy = new Array(userAddressList.length).fill(
                      false
                    );
                    openCopy[index] = true;

                    setOpen(openCopy);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </ListItemSecondaryAction>
            {open[index] && (
              <AddressDialog
                open={open}
                close={() => {
                  setOpen(new Array(userAddressList.length).fill(false));
                }}
                customerMongoEmail={customerMongoEmail}
                refetch={() => {
                  refetch();
                }}
                selectedAddress={address}
                newAddress={false}
                boundedDogs={addressBoundedList[index]}
              />
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

const DogsOfAddressMenu = ({ addressId, fetchAgain, setAddressBounded }) => {
  const { loading, data, error, refetch } = useQuery(FETCH_DOG_BY_ADDRESS_ID, {
    variables: {
      id: addressId,
    },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (fetchAgain > 0) {
      refetch();
    }
  }, [fetchAgain]);
  const [dogList, setDogList] = useState([]);
  useEffect(() => {
    if (data?.getMongoDogsByShippingID) {
      setDogList(data.getMongoDogsByShippingID);
      if (data.getMongoDogsByShippingID.length > 0) {
        setAddressBounded();
      }
    }
  }, [loading, data, error]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {`Dogs (${dogList.length})`}
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {dogList.map((dog) => {
          return <MenuItem onClick={handleClose}> {dog.name} </MenuItem>;
        })}
      </Menu>
    </div>
  );
};
