import { gql } from "@apollo/client";

const GET_SECRETSHOP_PRODUCT = gql`
  query GetProducts {
    getSecretshopProducts {
      id
      name
      stripeID
      description
      metadata
      created
      updated
      hasSKU
      categories
    }
  }
`;

const GET_SECRETSHOP_PRODUCT_SINGLE = gql`
  query ($id: String) {
    product(id: $id) {
      id
      name
      description
      attributes
      metadata
      images
      active
      categories
      skus {
        id
        skuId
        inventory {
          quantity
          type
        }
        price
        active
        attributes {
          size
          gender
        }
        package_dimensions {
          weight
        }
        metadata
      }
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: ProductInput) {
    createProduct(input: $input) {
      id
      name
      attributes
      description
      metadata
      images
      active
      skus {
        id
        skuId
        inventory {
          quantity
          type
        }
        price
        active
        attributes {
          size
          gender
        }
        package_dimensions {
          weight
        }
      }
    }
  }
`;

const UPDATE_SECRETSHOP = gql`
  mutation UpdateProducts(
    $id: String
    $stripeID: String
    $input: ProductInput
  ) {
    updateProduct(id: $id, stripeID: $stripeID, input: $input) {
      id
      name
      description
      metadata
      attributes
    }
  }
`;

const UPDATE_SKU = gql`
  mutation UpdateSKU($id: String, $input: SKUUpdateInput) {
    updateSKU(id: $id, input: $input) {
      id
      inventory {
        quantity
        type
      }
      price
      active
      attributes {
        size
        gender
      }
      package_dimensions {
        weight
      }
      metadata
    }
  }
`;

const DELETE_SKU = gql`
  mutation DeleteSKU($id: String, $skuID: String) {
    deleteSKU(id: $id, skuID: $skuID)
  }
`;

const CREATE_SKU = gql`
  mutation CreateSKU($input: SKUInput) {
    createSKU(input: $input) {
      id
      inventory {
        quantity
        type
      }
      product {
        id
      }
      price
      active
      attributes {
        size
        gender
      }
      package_dimensions {
        weight
      }
    }
  }
`;

const SYNC = gql`
  mutation SYNC {
    sycSecretshopProducts
  }
`;

const UPDATECOUNTDOWN = gql`
  mutation UpdateSS($id: ID, $updatedCountDown: Int) {
    updateSecretshopCountDown(id: $id, updatedCountDown: $updatedCountDown) {
      id
      name
      description
      metadata
      active
      created
      updated
      hasSKU
      updatedCountDown
    }
  }
`;

const SS_MULTIPLEPUBLISH = gql`
  mutation PublishSecretShopProducts(
    $products: [PublishProductInput]
    $updatedCountDown: Int
  ) {
    publishSecretShopProducts(
      products: $products
      updatedCountDown: $updatedCountDown
    ) {
      active
      metadata
    }
  }
`;

const GET_SETTINGS = gql`
  query GetSetting($type: String) {
    getSettingsByType(type: $type) {
      id
      options
    }
  }
`;

const SET_SETTINGS = gql`
  mutation SetSettings($id: ID, $input: inputSettings) {
    updateSettingsById(id: $id, input: $input) {
      options
    }
  }
`;

export {
  GET_SECRETSHOP_PRODUCT,
  GET_SECRETSHOP_PRODUCT_SINGLE,
  CREATE_PRODUCT,
  UPDATE_SECRETSHOP,
  UPDATE_SKU,
  DELETE_SKU,
  CREATE_SKU,
  SYNC,
  UPDATECOUNTDOWN,
  SS_MULTIPLEPUBLISH,
  GET_SETTINGS,
  SET_SETTINGS,
};
