import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  CREATE_PRODUCT,
  GET_SECRETSHOP_PRODUCT,
  GET_SECRETSHOP_PRODUCT_SINGLE,
  SINGLE_UPLOAD,
  UPDATE_SECRETSHOP,
  UPDATE_SKU,
  UPDATECOUNTDOWN,
  CREATE_SKU,
  DELETE_SKU,
  SYNC,
} from "../../../queries/";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import Switch from "@mui/material/Switch";
import { ColumnDefs, mobileScreenFilter } from "../../Tools/Columns";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Edit from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { UploadFile, CompressedFile } from "../../Tools/Upload";
// import { startSingleUpload } from "../../Funcs/Upload";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { CreateProductDialog, CreateSKUElement } from "./create";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment-timezone";
import DeleteSkuDialog from "./deleteSKUDialog";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import SettingsDialog from "./settingsDialog";
import { Checkbox, FormGroup, Paper, Typography } from "@mui/material";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const categoriesMap = {
  ordinary: "ordinary",
  mysterybox: "mysterybox",
};

const DataTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [productShow, setProductShow] = useState(false);
  const [archiveProduct, setArchiveProduct] = useState(false);
  const [showPublished, setShowPublished] = useState(false);
  const [productRows, setProductRows] = useState();
  const [currentProduct, setCurrentProduct] = useState();
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedProductMongoid, setSelectedProductMongoid] = useState("");
  const [tempProducts, setTempProducts] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [toggleAddSku, setToggleAddSku] = useState(false);
  const [categories, setCategories] = useState([]);

  const { data, refetch: refetchProducts } = useQuery(GET_SECRETSHOP_PRODUCT);
  const [openProductDiag, setOpenProductDiag] = useState(false);
  const [createNewProduct, { loading: loadingCreateProduct }] =
    useMutation(CREATE_PRODUCT);
  const [updateSecretshop, { loading: loadingUpdateSecretshop }] =
    useMutation(UPDATE_SECRETSHOP);
  const [updateCountDown, { loading: loadingUpdateCountdown }] =
    useMutation(UPDATECOUNTDOWN);
  const [updateSKU, { loading: UpdateSKULoading }] = useMutation(UPDATE_SKU);
  const [createSKU, { loading: loadingCreateSku }] = useMutation(CREATE_SKU);
  const classes = useStyles();
  const [typeOpen, setTypeOpen] = useState(false);
  const [updatedDate, setUpdatedDate] = useState(false);
  const [retailer, setRetailer] = useState(false);

  // const [type, setType] = useState("");
  const breakPoint = theme.breakpoints.down("sm");
  const screen = useMediaQuery(breakPoint);
  const [isSmallScreen, setIsSmallScreen] = useState(screen);

  useEffect(() => {
    console.log(screen);
    setIsSmallScreen(screen);
  }, [screen]);

  const [deleteSKU] = useMutation(DELETE_SKU);
  const [syncDATA, { loading: syncLoading }] = useMutation(SYNC);
  const [
    getProduct,
    { called: productQueryCalled, loading: loadingProduct, data: productData },
  ] = useLazyQuery(GET_SECRETSHOP_PRODUCT_SINGLE, {
    fetchPolicy: "no-cache",
    variables: {
      id: selectedProduct,
    },
    onCompleted: (data) => {
      if (data.product?.skus?.length > 0) {
        for (let x = 0; x < data.product.skus.length; x++) {
          const el = data.product.skus[x];
          if (el.package_dimensions === null) {
            el.package_dimensions = {
              weight: 0,
              height: 0,
              width: 0,
              length: 0,
            };
          } else if (el.package_dimensions.weight === null) {
            el.package_dimensions = {
              weight: 0,
            };
          }
        }
      }
      setCurrentProduct(data.product);
      console.log("data.product");
      console.log(data.product);
      setCategories(data.product.categories);
      setProductShow(
        data.product.metadata.show === "secretshop" ? true : false
      );
      let boolVar = data.product.active;
      setArchiveProduct(!boolVar);
    },
  });

  useEffect(() => {
    const handleUpdateProduct = (id, mongoid) => {
      setSelectedProduct(id);
      setSelectedProductMongoid(mongoid);
      getProduct(id);
      handleClickUpdate();
    };
    if (data) {
      setProductRows(
        data.getSecretshopProducts.map((product) => {
          return { ...product, edit: handleUpdateProduct };
        })
      );
      setIsLoading(false);
    }
  }, [data, getProduct, productData]);
  const updateDATA = () => {
    syncDATA();
    refetchProducts();
  };
  const onSwitchChange = () => {
    if (!showPublished) {
      setIsLoading(true);
      setTempProducts(productRows.slice());
      const filteredProducts = productRows.filter((product) => {
        return (
          product.metadata &&
          product.metadata.show &&
          product.metadata.show.toLowerCase() === "secretshop"
        );
      });
      setProductRows(filteredProducts);
      setIsLoading(false);
      setShowPublished(true);
      return;
    }
    setProductRows(tempProducts);
    setShowPublished(!showPublished);
  };

  const handleOpenProductDiag = () => {
    setOpenProductDiag(!openProductDiag);
  };
  const handleClickUpdate = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    refetchProducts();
    setToggleAddSku(false);
  };

  const onchangePublish = (e) => {
    let newPromo = currentProduct.metadata;
    newPromo.show = e ? "secretshop" : "";
    setCurrentProduct({ ...currentProduct, metadata: newPromo });
    setProductShow(e);
  };
  const uploadPhoto = (file) => {
    if (file) {
      const imgArr = currentProduct.images;
      imgArr.push(file.uri);
      setCurrentProduct({ ...currentProduct, images: imgArr });
    }
  };
  const resetFeaturedPhoto = (link) => {
    let newArr = currentProduct.images.filter((item) => item !== link);
    if (newArr.length === 0) {
      newArr = [];
    }
    setCurrentProduct({ ...currentProduct, images: newArr });
  };

  const handleDeleteSKU = (id, stripeID) => {
    deleteSKU({
      variables: {
        id: stripeID,
        skuID: id,
      },
    })
      .then((res) => {
        getProduct(stripeID);
        enqueueSnackbar(`SKU Deleted successfully`, {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
        });
      });
  };

  const handleCreateSKU = (data) => {
    const skuInput = { ...data, product: currentProduct.id };
    createSKU({
      variables: {
        input: skuInput,
      },
    })
      .then((res) => {
        getProduct(currentProduct.id);
        setToggleAddSku(false);
        enqueueSnackbar(`SKU Created successfully`, {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
        });
      });
  };
  const handleCreateProduct = (data) => {
    createNewProduct({
      variables: {
        input: data,
      },
    }).then((res) => {
      handleOpenProductDiag(false);
      setSelectedProduct();
      setSelectedProductMongoid();
      // setCurrentProduct(res.data.createProduct);
      // setSelectedProduct(res.data.createProduct.id);
      refetchProducts();
      // handleClickUpdate();
    });
  };
  const updateSecretshopFunc = () => {
    const newInput = {
      name: currentProduct.name,
      description: currentProduct.description,
      metadata: currentProduct.metadata,
      images: currentProduct.images,
      active: currentProduct.active,
      categories: categories,
    };
    updateSecretshop({
      variables: {
        id: selectedProductMongoid.toString(),
        stripeID: selectedProduct.toString(),
        input: newInput,
      },
    }).then(async ({ data }) => {
      //resetState();
      if (updatedDate) {
        updateProductCountFunc();
        setUpdatedDate(false);
      }

      handleClose();
      // setUpdateProduct()
      //refetch();
      refetchProducts();
      console.log(data);
    });
  };

  const updateProductCountFunc = () => {
    updateCountDown({
      variables: {
        id: selectedProductMongoid.toString(),
        updatedCountDown: moment().unix(),
      },
    }).then(async ({ data }) => {
      //resetState();
      // setUpdateProduct()
      //refetch();
      refetchProducts();
      console.log(data);
    });
  };

  const nameOnChange = (e) => {
    setCurrentProduct({ ...currentProduct, name: e.target.value });
  };
  const promoOnChange = (e) => {
    let newPromo = currentProduct.metadata;
    newPromo.promotext = e.target.value;
    setCurrentProduct({ ...currentProduct, metadata: newPromo });
  };
  const onChangeArchive = (e) => {
    setCurrentProduct({ ...currentProduct, active: archiveProduct });
    setArchiveProduct(!archiveProduct);
  };

  const descOnChange = (e) => {
    setCurrentProduct({ ...currentProduct, description: e.target.value });
  };
  const nameOnSKUChange = (value, index, type, main) => {
    if (!main) {
      let newSKU = currentProduct.skus[index];
      newSKU[`${type}`] = value;
      setCurrentProduct({ ...currentProduct, sku: newSKU });
    } else {
      let newSKU = currentProduct.skus[index];

      newSKU[`${main}`][`${type}`] = value;
      setCurrentProduct({ ...currentProduct, sku: newSKU });
    }
  };
  const onChangeRetailer = (value, index) => {
    let newSKU = currentProduct.skus[index];
    console.log("newSku", newSKU);
    newSKU.metadata = {
      type: value,
    };
    setCurrentProduct({ ...currentProduct, sku: newSKU });
  };
  const updateSKUOnDB = (index) => {
    const { skuId, ...inputSKU } = currentProduct.skus[index];
    let attributes = {};
    if (inputSKU.attributes.size) {
      attributes.size = inputSKU.attributes.size
        ? inputSKU.attributes.size.toUpperCase()
        : "";
    }
    if (inputSKU.attributes.gender) {
      attributes.gender = inputSKU.attributes.gender;
    }

    updateSKU({
      variables: {
        id: skuId,
        input: {
          inventory: {
            quantity: parseInt(inputSKU.inventory.quantity),
            type: inputSKU.inventory.type,
          },
          price: parseInt(inputSKU.price * 100),
          attributes: attributes,
          package_dimensions: {
            length: 0,
            height: 0,
            width: 0,
            weight: parseFloat(inputSKU.package_dimensions.weight),
          },
          metadata: {
            type:
              inputSKU.metadata && inputSKU.metadata.type
                ? inputSKU.metadata.type
                : "member",
          },
        },
      },
    })
      .then((data) => {
        enqueueSnackbar(`SKU is updated successfully`, {
          variant: "success",
        });
      })
      .catch((e) => {
        enqueueSnackbar(`${e.message}`, {
          variant: "error",
        });
      });
  };

  const resetState = () => {
    setSelectedProduct("");
    setSelectedProductMongoid("");
  };

  console.log("World finder", currentProduct);
  const addCategory = (category) => {
    const copy = JSON.parse(JSON.stringify(categories));
    const index = copy.indexOf(category);

    if (index > -1) {
      copy.splice(index, 1);
    } else {
      copy.push(category);
    }
    if (copy.length === 0) {
      copy.push(categoriesMap.ordinary);
    }
    setCategories(copy);
  };

  const onImageLeft = (index) => {
    let imgArr = JSON.parse(JSON.stringify(currentProduct.images));
    let temp = imgArr[index - 1];
    imgArr[index - 1] = imgArr[index];
    imgArr[index] = temp;
    setCurrentProduct({ ...currentProduct, images: imgArr });
  };

  const onImageRight = (index) => {
    let imgArr = JSON.parse(JSON.stringify(currentProduct.images));
    let temp = imgArr[index + 1];
    imgArr[index + 1] = imgArr[index];
    imgArr[index] = temp;
    setCurrentProduct({ ...currentProduct, images: imgArr });
  };
  if (
    loadingCreateProduct ||
    loadingCreateSku ||
    loadingUpdateSecretshop ||
    loadingUpdateCountdown
  ) {
    return <LinearProgress />;
  }

  return (
    <div>
      <Button onClick={() => setOpenProductDiag(true)}>Add Product</Button>
      <CreateProductDialog
        openDiag={openProductDiag}
        setDiag={handleOpenProductDiag}
        handleCreateProduct={handleCreateProduct}
      />
      {/* <Link to={"/dashboard/secretshop/publish-multiple"}>
        <Button>Publish Multiple Item in Time</Button>
      </Link> */}
      <Dialog
        open={open}
        //onClose={handleClickUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        {!(productQueryCalled && loadingProduct) && (
          <div className="update_product-header">
            <FormControlLabel
              control={
                <Switch
                  checked={archiveProduct}
                  onChange={() => onChangeArchive(!archiveProduct)}
                  name="checkedA"
                  color="secondary"
                />
              }
              label="Archive"
            />
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button onClick={updateSecretshopFunc} color="secondary" autoFocus>
              Update Product
            </Button>
          </div>
        )}
        <div
          style={{
            marginTop: -60,
          }}
        >
          {productQueryCalled && loadingProduct ? (
            <div>
              <div>
                <LinearProgress color="secondary" />
              </div>
            </div>
          ) : (
            <div>
              <DialogTitle
                style={{ paddingTop: isSmallScreen && 55 }}
                id="alert-dialog-title"
              >
                Update Product
              </DialogTitle>
              {currentProduct && (
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        name="name"
                        label="Name"
                        type="text"
                        value={currentProduct.name}
                        onChange={(e) => nameOnChange(e)}
                        width="50%"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        name="promotext"
                        label="Promo Text"
                        type="text"
                        value={
                          currentProduct.metadata &&
                          currentProduct.metadata.promotext
                            ? currentProduct.metadata.promotext
                            : ""
                        }
                        onChange={(e) => promoOnChange(e)}
                        width="50%"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        width={"100%"}
                      >
                        <Grid item xs={12} sm={6}>
                          <Paper style={{ padding: 20 }} elevation={0}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={productShow}
                                  onChange={() => onchangePublish(!productShow)}
                                  name="checkedA"
                                  color="secondary"
                                />
                              }
                              label="Publish"
                            />
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={updatedDate}
                                  onChange={() => setUpdatedDate(!updatedDate)}
                                  name="checkedA"
                                  color="secondary"
                                />
                              }
                              label="Premium Countdown"
                            />
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Paper style={{ padding: 20 }} elevation={3}>
                            <Typography>Categories </Typography>
                            <FormGroup>
                              <FormControlLabel
                                color="secondary"
                                control={
                                  <Checkbox
                                    onClick={() => {
                                      addCategory(categoriesMap.ordinary);
                                    }}
                                    checked={categories.includes(
                                      categoriesMap.ordinary
                                    )}
                                  />
                                }
                                label="Secretshop"
                              />
                              <FormControlLabel
                                color="secondary"
                                control={
                                  <Checkbox
                                    onClick={() => {
                                      addCategory(categoriesMap.mysterybox);
                                    }}
                                    checked={categories.includes(
                                      categoriesMap.mysterybox
                                    )}
                                  />
                                }
                                label="Mystery box "
                              />
                            </FormGroup>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={4}
                        margin="normal"
                        value={currentProduct.description}
                        onChange={(e) => descOnChange(e)}
                        name="comments"
                        label="Description"
                        type="text"
                        fullWidth
                        //onChange={updateComment}
                      />
                      {currentProduct.images &&
                        currentProduct.images.map((nLink, index) => (
                          <div className="imagesecret">
                            <div className="featuredImgBlock">
                              <div className="deletephotobtn">
                                <IconButton
                                  onClick={() => resetFeaturedPhoto(nLink)}
                                  color="secondary"
                                  aria-label="delete"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                              <img
                                src={nLink}
                                alt={currentProduct.name + "_img"}
                              />
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <IconButton
                                  onClick={() => {
                                    onImageLeft(index);
                                  }}
                                  color="secondary"
                                  disabled={index === 0}
                                >
                                  <ChevronLeftIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    onImageRight(index);
                                  }}
                                  color="secondary"
                                  disabled={
                                    currentProduct.images.length === index + 1
                                  }
                                >
                                  <ChevronRightIcon />
                                </IconButton>
                              </Grid>
                            </div>
                          </div>
                        ))}{" "}
                      <br></br>
                      <CompressedFile
                        uploadPhoto={uploadPhoto}
                        buttonName={"Add Featured Image"}
                      />
                      <br></br>
                      {currentProduct.skus.length > 0 && !toggleAddSku ? (
                        currentProduct.skus.map((sku, index) => (
                          <div className="skus_box">
                            <h3>Update SKU</h3>
                            <div
                              style={{
                                zIndex: 100,
                                position: "absolute",
                                right: 10,
                                top: 10,
                              }}
                            >
                              <DeleteSkuDialog
                                handleDeleteSKU={() => {
                                  handleDeleteSKU(sku.skuId, currentProduct.id);
                                }}
                              />
                              {/* <IconButton
                              onClick={() =>
                                handleDeleteSKU(sku.skuId, currentProduct.id)
                              }
                              color="secondary"
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton> */}
                            </div>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  name="price"
                                  label="Price"
                                  type="number"
                                  value={sku.price}
                                  onChange={(e) =>
                                    nameOnSKUChange(
                                      e.target.value,
                                      index,
                                      "price"
                                    )
                                  }
                                  width="50%"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  name="quantity"
                                  label="Quantity"
                                  type="text"
                                  value={sku.inventory.quantity}
                                  onChange={(e) =>
                                    nameOnSKUChange(
                                      e.target.value,
                                      index,
                                      "quantity",
                                      "inventory"
                                    )
                                  }
                                  width="50%"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                  <InputLabel>Type</InputLabel>
                                  <Select
                                    open={typeOpen}
                                    onClose={() => setTypeOpen(false)}
                                    onOpen={() => setTypeOpen(true)}
                                    value={sku.inventory.type}
                                    onChange={(e) => {
                                      let newArr = currentProduct.skus;
                                      newArr[index].inventory.type =
                                        e.target.value;
                                      setCurrentProduct({
                                        ...currentProduct,
                                        skus: newArr,
                                      });
                                    }}
                                  >
                                    <MenuItem value={"finite"} selected>
                                      Finite
                                    </MenuItem>
                                    <MenuItem value={"infinite"}>
                                      Infinite
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  name="weight"
                                  label="Weight"
                                  type="text"
                                  value={
                                    sku.package_dimensions
                                      ? sku.package_dimensions.weight
                                      : 0
                                  }
                                  onChange={(e) => {
                                    nameOnSKUChange(
                                      e.target.value,
                                      index,
                                      "weight",
                                      "package_dimensions"
                                    );
                                  }}
                                  width="50%"
                                  fullWidth
                                />
                              </Grid>

                              {currentProduct.attributes.length > 0 && (
                                <Grid item xs={12} sm={12}>
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    name="size"
                                    label="Size"
                                    type="text"
                                    value={sku.attributes.size}
                                    onChange={(e) =>
                                      nameOnSKUChange(
                                        e.target.value,
                                        index,
                                        "size",
                                        "attributes"
                                      )
                                    }
                                    width="50%"
                                    fullWidth
                                  />

                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    name="gender"
                                    label="Gender"
                                    type="text"
                                    value={sku.attributes.gender}
                                    onChange={(e) =>
                                      nameOnSKUChange(
                                        e.target.value,
                                        index,
                                        "gender",
                                        "attributes"
                                      )
                                    }
                                    width="50%"
                                    fullWidth
                                  />
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={
                                      sku &&
                                      sku.metadata &&
                                      sku.metadata.type &&
                                      sku.metadata.type === "retailer"
                                    }
                                    onChange={() => {
                                      const val =
                                        sku.metadata &&
                                        sku.metadata.type !== "member"
                                          ? "member"
                                          : "retailer";
                                      onChangeRetailer(val, index);
                                    }}
                                  />
                                }
                                label={
                                  sku &&
                                  sku.metadata &&
                                  sku.metadata.type &&
                                  sku.metadata.type === "retailer"
                                    ? "Retailer"
                                    : "Members"
                                }
                              />
                            </Grid>
                            <Button
                              disabled={UpdateSKULoading}
                              color="secondary"
                              onClick={() => updateSKUOnDB(index)}
                            >
                              {UpdateSKULoading ? (
                                <CircularProgress />
                              ) : (
                                "Update"
                              )}
                            </Button>
                          </div>
                        ))
                      ) : (
                        <CreateSKUElement
                          handleSKUCreate={handleCreateSKU}
                          handleCancel={() => setToggleAddSku(false)}
                          skuCount={currentProduct.skus.length}
                          attributes={currentProduct.attributes}
                        />
                      )}
                      {!toggleAddSku && (
                        <Button
                          onClick={() => setToggleAddSku(true)}
                          fullWidth
                          variant="outlined"
                          color="secondary"
                          style={{ marginTop: 10 }}
                        >
                          Add new sku
                        </Button>
                      )}
                      {/* <RealUploadFile
                    uploadPhoto={uploadPhoto}
                    buttonName={"Add Featured Image"}
                  /> */}
                    </Grid>
                  </Grid>
                </DialogContent>
              )}
            </div>
          )}
        </div>
      </Dialog>
      <div style={{ height: 900, width: "100%" }}>
        <FormControlLabel
          control={
            <Switch
              checked={showPublished}
              onChange={onSwitchChange}
              name="checkedA"
              color="secondary"
            />
          }
          label="Show Published"
        />
        <Button
          color="secondary"
          sx={{
            "@media (max-width:465px)": {
              float: "right",
            },
          }}
          onClick={() => updateDATA()}
        >
          {syncLoading ? <CircularProgress color="secondary" /> : "SYNC"}
        </Button>

        <Link
          to={"/dashboard/secretshop/publish-multiple"}
          style={{
            float: "right",
            textDecoration: "none",
          }}
        >
          <Button color="secondary">SS Publish Multiple</Button>
        </Link>
        <SettingsDialog />
        {data && productRows && (
          <DataGrid
            style={{
              width: "100%",
            }}
            rows={productRows}
            columns={
              isSmallScreen
                ? ColumnDefs.filter(
                    (item) => !mobileScreenFilter.includes(item.field)
                  )
                : ColumnDefs
            }
            loading={isLoading}
            pageSize={15}
            // checkboxSelection
            pagination={true}
          />
        )}
      </div>
    </div>
  );
};
export default DataTable;
