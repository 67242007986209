import { gql } from "@apollo/client";

const CREATE_BARCODE = gql`
  mutation CreateBarcode($input: CreateBarcodeInput) {
    createBarcode(input: $input) {
      id
      text
      productId
    }
  }
`;

const GET_BARCODES = gql`
  query GetBarcodes {
    getBarcodes {
      id
      text
      productId
    }
  }
`;

export { CREATE_BARCODE, GET_BARCODES };
