import React, { Component } from "react";

export async function startSingleUpload(file, singleUpload, callback) {
  console.log("Starting upload");
  console.log("File", file);
  let body = [];

  await singleUpload({ variables: { file } }).then(({ data }) => {
    console.log(data.singleUpload.uri);
    body = data["singleUpload"];
    return data.singleUpload.uri;
  });

  await callback(body.uri);
}
