import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  ListItemButton,
  ListItemIcon,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  FEEDREAL_CONTENT,
  FINAL_EXAM,
  QUIZ_CONTENT,
  TEXT_EDITOR_CONTENT,
  VIDEO_CONTENT,
} from "../../../../globals";
import {
  CREATE_UNIT,
  GET_SINGLE_UNIT,
  UPDATE_UNIT,
  GET_COURSES,
  GET_SINGLE_COURSE,
} from "../../../../queries";
import QuizStatus from "../../Quiz/components/QuizStatus";
import { FeaturedImage } from "../Course";
import AddTutorialDialog, { tutorialTypes } from "./AddTutorialDialog";
import Alert from "@mui/material/Alert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  EditTutorialTextEditor,
  TutorialTextEditorPreview,
} from "./TutorialTextEditor";
import {
  EditVideoContentEditor,
  TutorialVideoContentEditorPreview,
} from "./TutorialVideoEditor";
import { TutorialQuizContentPreview } from "./QuizTutorial";
import PreviewIcon from "@mui/icons-material/Preview";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FinalQuizDialog from "./FinalQuizDialog";
import QuizTutorialDialog, {
  UpdateQuizTutorialDialog,
} from "./QuizTutorialDialog";
import { Edit } from "@mui/icons-material";
const TITLE_MIN_LENGTH = 10;
const DETAIL_MIN_LENGTH = 10;

const Unit = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { courseId: courseIdParam, unitId } = useParams();
  const { session } = props;
  const { activeAdmin } = session;

  const [initialLoad, setInitialLoad] = useState(true);

  const [title, setTitle] = useState("");
  const [unitDetail, setUnitDetail] = useState("");
  const [status, setStatus] = useState(true);
  const [courseId, setCourseId] = useState("");
  const [featuredImg, setFeaturedImg] = useState("");
  const [tutorials, setTutorials] = useState([]);
  const [units, setUnits] = useState([]);

  const [isLastFinalExam, setIsLastFinalExam] = useState(false);
  const [prerequisites, setPrerequisites] = useState([]);

  const [
    createUnitMutation,
    { loading: createUnitLoading, error: createUnitError },
  ] = useMutation(CREATE_UNIT, {
    onCompleted: (response) => {
      enqueueSnackbar(`Unit created Successfully`, {
        variant: "success",
      });
      history.push(`/dashboard/course/edit/${courseIdParam}`);
    },
  });

  const [
    updateUnitMutation,
    { loading: updateUnitLoading, error: updateUnitError },
  ] = useMutation(UPDATE_UNIT, {
    onCompleted: (response) => {
      enqueueSnackbar(`Unit Updated Successfully`, {
        variant: "success",
      });
      // history.push(`/dashboard/course/edit/${courseIdParam}`);
    },
  });

  const [
    getSingleUnitQuery,
    { loading: getSingleUnitLoading, error: gerSingleUnitError, refetch },
  ] = useLazyQuery(GET_SINGLE_UNIT, {
    variables: { input: { id: unitId } },
    onCompleted: (response) => {
      const {
        title,
        unitDetail,
        status,
        courseId,
        featured_img,
        tutorials,
        prerequisites,
      } = response.getSingleUnit;
      setTitle(title);
      setUnitDetail(unitDetail);
      setStatus(status);
      setCourseId(courseId);
      setFeaturedImg(featured_img);
      setTutorials(tutorials);
      setInitialLoad(false);
      setPrerequisites(prerequisites);
    },
    fetchPolicy: "no-cache",
  });

  const [expanded, setExpanded] = useState(false);
  const [getSingleCourse, { loading: getSingleCourseLoading }] = useLazyQuery(
    GET_SINGLE_COURSE,
    {
      onCompleted: (response) => {
        const { units } = response.getSingleCourse;
        setUnits(units);
      },
      fetchPolicy: "no-cache",
    }
  );

  const onChangeAccordion = () => {
    if (!expanded) {
      getSingleCourse({
        variables: {
          input: { id: courseId },
        },
      });
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (courseIdParam) {
      setCourseId(courseIdParam);
    }
  }, [courseIdParam]);

  useEffect(() => {
    if (unitId) {
      getSingleUnitQuery();
    }
  }, []);

  const onCreateUnit = () => {
    const valid = isValid();
    if (valid) {
      const input = createInput();
      createUnitMutation({
        variables: {
          input,
        },
      });
    }
  };
  const onUpdateUnit = (condition) => {
    const valid = isValid();
    if (valid) {
      const input = createInput();
      updateUnitMutation({
        variables: {
          id: unitId,
          input,
        },
      });
    }
  };
  const createInput = () => {
    const input = {
      admin_id: activeAdmin.id,
      title,
      unitDetail,
      status,
      courseId,
      featured_img: featuredImg,
      tutorials,
      prerequisites,
    };
    return input;
  };

  const isValid = () => {
    if (title.length < TITLE_MIN_LENGTH) {
      enqueueSnackbar(`Unit title not valid (min length ${TITLE_MIN_LENGTH})`, {
        variant: "error",
      });
      return false;
    }
    if (unitDetail.length < DETAIL_MIN_LENGTH) {
      enqueueSnackbar(
        `Unit detail not valid (min length ${DETAIL_MIN_LENGTH})`,
        {
          variant: "error",
        }
      );
      return false;
    }
    if (featuredImg.length === 0) {
      enqueueSnackbar(`Please upload an image`, {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (tutorials.length > 0) {
      let lastItem = tutorials[tutorials.length - 1];
      if (
        lastItem.type === QUIZ_CONTENT &&
        lastItem.content.quizType === FINAL_EXAM
      ) {
        setIsLastFinalExam(true);
      } else {
        setIsLastFinalExam(false);
      }
    }
  }, [tutorials]);

  const onAddTutorial = (tutorial) => {
    let copy = JSON.parse(JSON.stringify(tutorials));
    let finalQuizItem = copy.find(
      (item) =>
        item.type === QUIZ_CONTENT && item.content.quizType === FINAL_EXAM
    );
    if (finalQuizItem) {
      const index = copy.indexOf(finalQuizItem);
      copy.splice(index, 1);
    }
    copy.push(tutorial);
    if (finalQuizItem) {
      copy.push(finalQuizItem);
    }
    setTutorials(copy);
    if (unitId) {
      onUpdateUnit();
    }
  };

  const uploadPhoto = (file) => {
    setFeaturedImg(file.uri);
  };
  const resetFeaturedPhoto = () => {
    setFeaturedImg("");
  };

  const onMoveDown = (index) => {
    if (isLastFinalExam && index === tutorials.length - 2) {
      enqueueSnackbar("Final Exam already setted", {
        variant: "info",
      });
      return;
    }
    let copy = JSON.parse(JSON.stringify(tutorials));
    const temp = copy[index + 1];
    copy[index + 1] = copy[index];
    copy[index] = temp;
    setTutorials(copy);
    enqueueSnackbar("Tutorial moved down", {
      variant: "success",
    });
  };
  const onMoveUp = (index) => {
    if (isLastFinalExam && index === tutorials.length - 1) {
      enqueueSnackbar("Final Exam already setted", {
        variant: "info",
      });
      return;
    }
    let copy = JSON.parse(JSON.stringify(tutorials));
    const temp = copy[index - 1];
    copy[index - 1] = copy[index];
    copy[index] = temp;
    setTutorials(copy);
    enqueueSnackbar("Tutorial moved up", {
      variant: "success",
    });
  };
  const onDeleteTutorial = (index) => {
    let copy = JSON.parse(JSON.stringify(tutorials));
    copy.splice(index, 1);
    setTutorials(copy);
    enqueueSnackbar("Tutorial removed", {
      variant: "success",
    });
  };
  const onClickPrerequisites = (courseId) => {
    const copy = JSON.parse(JSON.stringify(prerequisites));
    const index = copy.indexOf(courseId);
    if (index !== -1) {
      copy.splice(index, 1);
    } else {
      copy.push(courseId);
    }
    setPrerequisites(copy);
  };
  const addFinalQuiz = (quizTutorial) => {
    setTutorials(quizTutorial);
  };

  const updateEditorTutorial = (text, title, index) => {
    let copy = JSON.parse(JSON.stringify(tutorials));
    copy[index].title = title;
    copy[index].text = text;
    setTutorials(copy);
  };
  const updateVideoTutorial = (text, title, index) => {
    let copy = JSON.parse(JSON.stringify(tutorials));
    copy[index].title = title;
    copy[index].text = text;
    setTutorials(copy);
  };
  const updateQuizTitle = (title, index) => {
    let copy = JSON.parse(JSON.stringify(tutorials));
    copy[index].title = title;
    setTutorials(copy);
  };

  useEffect(() => {
    if (unitId && tutorials.length > 0 && !initialLoad) {
      onUpdateUnit();
    }
  }, [tutorials]);

  if (createUnitLoading || getSingleUnitLoading || updateUnitLoading) {
    return <LinearProgress />;
  }
  if (createUnitError || gerSingleUnitError || updateUnitError) {
    return <Typography>Error</Typography>;
  }

  return (
    <Container>
      <Paper
        style={{
          padding: 20,
          marginBottom: 40,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={9} className="every-child-margin ">
            <TextField
              fullWidth
              label="Unit Title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="Unit Detail"
              value={unitDetail}
              onChange={(e) => {
                setUnitDetail(e.target.value);
              }}
            />
            <QuizStatus status={status} setStatus={setStatus} />
            <Paper style={{ marginBottom: 10 }}>
              <Accordion
                sx={{ backgroundColor: "#000 !important" }}
                expanded={expanded}
                onChange={onChangeAccordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    Please select prerequisite if it's needed!
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {getSingleCourseLoading && <LinearProgress />}

                  {units
                    .filter((unit) => unit.id !== unitId)
                    .filter((unit) => !unit.prerequisites.includes(unitId))
                    .map((unit) => {
                      return (
                        <ListItem key={unit.id} disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={() => {
                              onClickPrerequisites(unit.id);
                            }}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={prerequisites.includes(unit.id)}
                                tabIndex={-1}
                                disableRipple
                              />
                            </ListItemIcon>
                            <ListItemText primary={unit.title} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
          <Grid item xs={3} className="every-child-margin ">
            <FeaturedImage
              featuredImg={featuredImg}
              uploadPhoto={uploadPhoto}
              resetFeaturedPhoto={resetFeaturedPhoto}
            />

            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                if (unitId) {
                  onUpdateUnit(true);
                } else {
                  onCreateUnit();
                }
              }}
            >
              {unitId ? "Update" : "Create"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ padding: "20px" }}
        >
          <Grid
            item
            xs={9}
            className="every-child-margin "
            style={{
              borderRight: "1px solid",
            }}
          >
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {tutorials.map((tutorial, index) => {
                const LocalIcon = tutorialTypes.find(
                  (item) => item.type === tutorial.type
                )?.icon;
                console.log(tutorial);
                return (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <LocalIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={tutorial.title}
                      secondary={<TutorialSecondary tutorial={tutorial} />}
                    />
                    {tutorial.type === FEEDREAL_CONTENT && (
                      <IconButton
                        component={Link}
                        variant="outlined"
                        to={`/dashboard/page/edit/${tutorial.content.id}`}
                        target="_blank"
                      >
                        <Edit />
                      </IconButton>
                    )}
                    {tutorial.type === TEXT_EDITOR_CONTENT && (
                      <EditTutorialTextEditor
                        tutorial={tutorial}
                        updateEditorTutorial={(text, title) => {
                          updateEditorTutorial(text, title, index);
                        }}
                      />
                    )}
                    {tutorial.type === VIDEO_CONTENT && (
                      <EditVideoContentEditor
                        tutorial={tutorial}
                        updateVideoTutorial={(text, title) => {
                          updateVideoTutorial(text, title, index);
                        }}
                      />
                    )}
                    {tutorial.type === QUIZ_CONTENT && (
                      <>
                        {tutorial.content.quizType === FINAL_EXAM ? (
                          <FinalQuizDialog
                            disabled={isLastFinalExam}
                            update={true}
                            id={tutorial.content.id}
                          />
                        ) : (
                          <UpdateQuizTutorialDialog
                            quizId={tutorial.content.id}
                            quizTypeOrigin={tutorial.content.type}
                            refetchUnit={(title) => {
                              updateQuizTitle(title, index);
                            }}
                          />
                        )}
                      </>
                    )}

                    <IconButton
                      disabled={index === 0}
                      onClick={() => {
                        onMoveUp(index);
                      }}
                    >
                      <ArrowCircleUpIcon />
                    </IconButton>
                    <IconButton
                      disabled={index === tutorials.length - 1}
                      onClick={() => {
                        onMoveDown(index);
                      }}
                    >
                      <ArrowCircleDownIcon />
                    </IconButton>

                    <IconButton
                      color="error"
                      onClick={() => {
                        onDeleteTutorial(index);
                      }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={3} className="every-child-margin ">
            <AddTutorialDialog
              onAddTutorial={(tutorial) => {
                onAddTutorial(tutorial);
              }}
              create={true}
              // disabled={isLastFinalExam}
            />
            <FinalQuizDialog
              addQuiz={(obj) => {
                addFinalQuiz(obj);
              }}
              tutorials={tutorials}
              disabled={isLastFinalExam}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
export default Unit;

const TutorialSecondary = ({ tutorial }) => {
  if (tutorial.type === FEEDREAL_CONTENT) {
    return (
      <div>
        {/* <Button
          startIcon={<PreviewIcon />}
          onClick={() => {
            window.open(
              "https://feedreal.com/" + "17-reasons-not-to-feed-raw",
              "_blank"
            );
          }}
        >
          Visit page
        </Button> */}
      </div>
    );
  }
  if (tutorial.type === TEXT_EDITOR_CONTENT) {
    return (
      <div>
        <TutorialTextEditorPreview tutorial={tutorial} />
      </div>
    );
  }
  if (tutorial.type === VIDEO_CONTENT) {
    return (
      <div>
        <TutorialVideoContentEditorPreview tutorial={tutorial} />
      </div>
    );
  }
  if (tutorial.type === QUIZ_CONTENT) {
    return (
      <div>
        <TutorialQuizContentPreview tutorial={tutorial} />
      </div>
    );
  }
  return <div>test</div>;
};
