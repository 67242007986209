import React, { Component, useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client";

import { GET_ACTIVE_ADMIN, AFFILIATE_FIND } from "../../../../../queries";
import moment from "moment";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',

    minWidth: 250,
  },
  fixDiv:{
    display:"grid"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function AffiliateFind(props) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [customersData, setCustomersData] = useState([]);
  const isInitialMount = useRef(true);
  const [getCustomers, { loading: loadingSearch, data: searchData }] =
    useLazyQuery(AFFILIATE_FIND, {
      fetchPolicy: "no-cache",
      variables: {
        email: search,
        phone_number: searchPhone,
      },
      onCompleted: (data) => {
        console.log("data data", data);
        setCustomersData(data.affiliatesFind);
      },
    });

  useEffect(() => {
    console.log("data", customersData);
    if (!isInitialMount.current) {
      getCustomers();
    }
  }, [customersData, getCustomers, search, searchPhone]);

  const searchInput = (event) => {
    const numericRegex = /^([0-9]{1,100})+$/;
    if (
      numericRegex.test(event.target.value) &&
      event.target.value.length >= 4
    ) {
      isInitialMount.current = false;
      setSearch("");
      setSearchPhone(event.target.value);
    } else if (event.target.value.length >= 4) {
      isInitialMount.current = false;
      setSearchPhone("");
      setSearch(event.target.value);
    }
  };

  return (
    <CustomerFindUI
      history={props.history}
      searchInput={searchInput}
      order={props.sortOrder}
      sortCreatedDate={props.sortCreatedDate}
      customersData={customersData}
      loadingSearch={loadingSearch}
      classes={classes}
    />
  );
}

export class CustomerFindUI extends Component {
  componentDidMount() {
    console.log("comp", this.props);
  }
  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Autocomplete
              id="REALDOG - REAL SEARCH"
              noOptionsText={
                "Please search customer with phone number or email"
              }
              loading={this.props.loadingSearch}
              options={this.props.customersData}
              getOptionLabel={(option) =>
                `${option.name} - ${option.email} - ${option.username} - ${option.phone_number}`
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <div className="searchresult">
                    <b>{option.name ? option.name: "Affiliate"}</b>
                    <span>{option.email ? option.email :  option.username}</span>
                  </div>
                </li>
              )}
              onChange={(e, value) => {
                let newValue = value || "";
                if(newValue.affiliateType && newValue.affiliateType > 0){
                  return this.props.history.push(`/dashboard/affiliate/${newValue.id}`)
                }
                return this.props.history.push(
                  `/dashboard/customer/${newValue.id}`
                );
              }}
              renderInput={(customer) => (
                <TextField
                  {...customer}
                  label="Search User (Email, Phone Number or Username)"
                  margin="normal"
                  onChange={this.props.searchInput}
                  variant="outlined"
                  InputProps={{ ...customer.InputProps, type: "search" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <div className={this.props.classes.fixDiv}>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Sort Created Date
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.props.order}
                onChange={this.props.sortCreatedDate}
              >
                <MenuItem value={"asc"}>Ascending</MenuItem>
                <MenuItem value={"desc"}>Descending</MenuItem>
              </Select>
            </FormControl>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AffiliateFind;
