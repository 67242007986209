import { gql } from "@apollo/client";

const GET_SMS_LOGS = gql`
  query SMSLogs($limit: Int, $offset: Int) {
    smslogs(limit: $limit, offset: $offset) {
      nid
      admin {
        name
      }
      message
      created
      audience
      bindings {
        address
        status
      }
    }
    smslogsCount
  }
`;

const GET_NOTIFICATION_COUNT = gql`
  query NotificationCount($to: messageTo) {
    getNotificationCount(to: $to)
  }
`;

const SEND_SMS_NOTIFICATION = gql`
  mutation SendSMSNotification($body: String, $to: messageTo) {
    sendMessage(body: $body, to: $to) {
      sid
      status
    }
  }
`;

export { GET_SMS_LOGS, GET_NOTIFICATION_COUNT, SEND_SMS_NOTIFICATION };
