import React, { Component, useEffect, useState, useRef } from "react";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client";
import { useSnackbar } from "notistack";
import { GET_AFFILIATE, GET_ACTIVE_ADMIN, GET_STRIPE } from "../../../queries";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import LinearProgress from "@mui/material/LinearProgress";
import Autocomplete from "@mui/material/Autocomplete";
import AffiliateFind from "./Components/Affiliate";
import AffiliateDialog from "./Components/AffiliateDialog";
import { styled } from "@mui/material/styles";
import { Block } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import W9List from "./Components/Affiliate/w9";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
}));
const ItemBlock = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "block",
  marginTop: "20px",
}));
const ItemBlock2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginTop: "16px",
  marginBottom: "8px",
}));

const useStyles = makeStyles((theme) => ({
  marginDiv: {
    marginTop: "16px",
    marginBottom: "8px",
  },
}));

function AffiliateAnalytics(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");
  const [stage, setStage] = useState(false);
  const isInitialMount = useRef(true);

  const { subscribeToMore, loading, error, data, refetch, fetchMore } =
    useQuery(GET_AFFILIATE, {
      variables: {
        offset: 0,
        limit: rowsPerPage,
        order: sortOrder,
      },
    });

  useEffect(() => {
    refetch();
  }, [refetch, rowsPerPage, sortOrder]);

  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.affiliates.length,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };

  const sortCreatedDate = (event) => {
    setSortOrder(event.target.value);
    refetch({
      variables: {
        order: event.target.value,
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { loading: loadingAdmin, data: dataAdmin } = useQuery(GET_ACTIVE_ADMIN);

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  console.log(stage);
  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Affiliate</Typography>
          </Breadcrumbs>
        </div>
        <div className="right"></div>
      </div>

      <Grid container>
        <Grid item xs={12} direction="column">
          <ItemBlock>
            <div className={classes.marginDiv}>
              <Grid xs={12}>
                <Typography>
                  Make An Affiliate Member/RDB Page/Direct Page
                </Typography>
              </Grid>
            </div>
            <div className={classes.marginDiv}>
              <Grid
                container
                xs={12}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<RemoveRedEyeIcon />}
                  component={Link}
                  to={`/dashboard/affiliate/affiliate-requests`}
                >
                  Affiliate Requests
                </Button>
                <AffiliateDialog member refetch={refetch} />
                <AffiliateDialog select refetch={refetch} />
                <AffiliateDialog create refetch={refetch} />
                <Button
                  variant="outlined"
                  color={stage ? "warning" : "primary"}
                  size="large"
                  onClick={() => setStage(!stage)}
                >
                  {stage ? "Go Back List" : "Waiting Verify"}
                </Button>
              </Grid>
            </div>
          </ItemBlock>
        </Grid>
      </Grid>
      {stage ? (
        <W9List />
      ) : (
        <CustomerUI
          {...data}
          history={props.history}
          rowsPerPage={rowsPerPage}
          page={page}
          sortOrder={sortOrder}
          sortCreatedDate={sortCreatedDate}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refetch={refetch}
        />
      )}
    </div>
  );
}

export class CustomerUI extends Component {
  componentDidMount() {
    this.props.refetch();
  }
  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <ItemBlock>
              <AffiliateFind {...this.props} />
            </ItemBlock>
          </Grid>
        </Grid>
        <ul>
          {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Customer Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Phone</TableCell>

                    <TableCell align="left">Username</TableCell>
                    <TableCell align="left">Subscription</TableCell>
                    <TableCell align="left">Referrer</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.affiliates
                    .slice(
                      this.props.page * this.props.rowsPerPage,
                      this.props.page * this.props.rowsPerPage +
                        this.props.rowsPerPage
                    )
                    .map((customer) => {
                      return (
                        <TableRow key={customer.id}>
                          <TableCell component="th" align="left" scope="row">
                            {customer.name ? customer.name : ""}
                          </TableCell>
                          <TableCell component="th" align="left" scope="row">
                            {customer.email ? customer.email : ""}
                          </TableCell>
                          <TableCell component="th" align="left" scope="row">
                            {customer.phone_number ? customer.phone_number : ""}
                          </TableCell>

                          <TableCell component="th" align="left" scope="row">
                            {customer.username ? customer.username : ""}
                          </TableCell>
                          <TableCell component="th" align="left" scope="row">
                            {customer.stripe &&
                            customer.stripe.subscription.length > 0 ? (
                              <Button
                                variant="outlined"
                                color="secondary"
                                size="small"
                                className="greenborder"
                                disabled
                              >
                                <b className="greenborder">Active</b>
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                color="secondary"
                                className="redborder"
                                disabled
                                size="small"
                              >
                                <b className="redborder">Inactive</b>
                              </Button>
                            )}
                          </TableCell>
                          <TableCell component="th" align="left" scope="row">
                            {customer.ref && (
                              <Link
                                variant="text"
                                // color="tertiary"
                                // className="redborder"
                                className="linkcolorwhite"
                                to={`/dashboard/customer/${customer.ref.id}`}
                                size="small"
                              >
                                <b className="redborder">
                                  {customer.ref.username}
                                </b>
                              </Link>
                            )}
                          </TableCell>
                          <TableCell component="th" align="left" scope="row">
                            {customer.affiliateType &&
                            customer.affiliateType > 0 ? (
                              <Button
                                className="redborder"
                                variant="outlined"
                                component={Link}
                                to={`/dashboard/affiliate/${customer.id}`}
                                size="small"
                              >
                                <b className="redborder">Details</b>
                              </Button>
                            ) : (
                              <Button
                                className="redborder"
                                variant="outlined"
                                component={Link}
                                to={`/dashboard/customer/${customer.id}`}
                                size="small"
                              >
                                <b className="redborder">Details</b>
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          }
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={this.props.affiliatesCount}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            onPageChange={this.props.handleChangePage}
            onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          />
        </ul>
      </div>
    );
  }
}

export default AffiliateAnalytics;
