import React, { Component, useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client";

import { GET_ACTIVE_ADMIN, CANCEL_FIND } from "../../../../queries";
import moment from "moment";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "16px",
    marginBottom: "8px",
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Search(props) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [customersData, setCustomersData] = useState([]);
  const isInitialMount = useRef(true);
  const [getCustomers, { loading: loadingSearch, data: searchData }] =
    useLazyQuery(CANCEL_FIND, {
      fetchPolicy: "no-cache",
      variables: {
        email: search,
        phone_number: searchPhone,
      },
      onCompleted: (data) => {
        if (data) {
          setCustomersData(data.cancelFind);
        }
      },
    });

  useEffect(() => {
    if (!isInitialMount.current) {
      isInitialMount.current = true;
      getCustomers();
    }
  }, [customersData, getCustomers, search, searchPhone]);

  const searchInput = (event) => {
    const numericRegex = /^([0-9]{1,100})+$/;
    if (
      numericRegex.test(event.target.value) &&
      event.target.value.length >= 4
    ) {
      isInitialMount.current = false;
      setSearch("");
      setSearchPhone(event.target.value);
    } else if (event.target.value.length >= 4) {
      isInitialMount.current = false;
      setSearchPhone("");
      setSearch(event.target.value);
    }
  };
  const { enqueueSnackbar } = useSnackbar();

  return (
    <CustomerFindUI
      setCustomer={props.setCustomer}
      userSelection={props.userSelection || false}
      history={props.history}
      searchInput={searchInput}
      order={props.sortOrder}
      sortCreatedDate={props.sortCreatedDate}
      customersData={customersData}
      loadingSearch={loadingSearch}
      classes={classes}
      openDialog={props.openDialog}
      enqueueSnackbar={() => {
        enqueueSnackbar("Edit section is disabled for this user now ", {
          variant: "error",
        });
      }}
    />
  );
}

export class CustomerFindUI extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              id="REALDOG - REAL SEARCH"
              noOptionsText={
                "Please search customer with phone number or email"
              }
              loading={this.props.loadingSearch}
              options={this.props.customersData}
              getOptionLabel={(option) =>
                `${option.member.name} - ${option.member.email} - ${
                  option.cancelRequest?.status &&
                  option.cancelRequest?.status === "APPROVED"
                    ? "CANCELED"
                    : option.cancelRequest?.status
                    ? option.cancelRequest?.status
                    : "CANCELED-OLD"
                }`
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <div className="searchresult">
                    <b>{option.member.name}</b>
                    <span>{option.member.email}</span>
                    <span>
                      {option.cancelRequest?.status &&
                      option.cancelRequest?.status === "APPROVED"
                        ? "CANCELED"
                        : option.cancelRequest?.status
                        ? option.cancelRequest?.status
                        : "CANCELED-OLD"}
                    </span>
                  </div>
                </li>
              )}
              onChange={(e, value) => {
                let newValue = value || "";
                console.log("newValue", newValue);
                // console.log(newValue);
                if (this.props.userSelection) {
                  this.props.setCustomer(value);
                  return;
                }
                this.props.openDialog(newValue);
                // if (newValue.created_at >= 1642718118) {
                //   this.props.enqueueSnackbar();
                //   return;
                // }

                /*  return this.props.history.push(
                  `/dashboard/customer/${newValue.id}`
                ); */
              }}
              renderInput={(customer) => (
                <TextField
                  {...customer}
                  label="Search User (Email, Phone Number or Username)"
                  margin="normal"
                  onChange={this.props.searchInput}
                  variant="outlined"
                  InputProps={{ ...customer.InputProps, type: "search" }}
                />
              )}
            />
          </Grid>
         {/*  {!this.props.userSelection && (
            <Grid item xs={4}>
              <FormControl className={this.props.classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Sort Created Date
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.props.order}
                  onChange={this.props.sortCreatedDate}
                >
                  <MenuItem value={"asc"}>Ascending</MenuItem>
                  <MenuItem value={"desc"}>Descending</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )} */}
        </Grid>
      </div>
    );
  }
}

export default Search;
