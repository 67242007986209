import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import NumberFormat from "react-number-format";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { ADD_CUSTOMER_BALANCE } from "../../../../../queries";
import { useMutation } from "@apollo/client";
import ErrorIcon from "@mui/icons-material/Error";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import { useSnackbar } from "notistack";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { precisionRoundMod } from "../../../../../globals";
import VerifyAccount from "../verify";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "20px !important",
  },
  backdropShippingCalc: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000",
    position: "absolute",
  },
}));

const StripeBalance = ({ session, balance, customerStripeId, refetch }) => {
  const classes = useStyles();

  const [addBalance, setAddBalance] = useState("");

  const [isAdd, setIsAdd] = useState(true);
  const [charge, setCharge] = useState(true);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (charge) {
      setIsAdd(true);
    }
  }, [charge]);

  const setInitial = () => {
    setIsAdd(true);
    setCharge(true);
    setOpen(false);
    setAddBalance("");
  };

  return (
    <Paper className={classes.paper}>
      <Grid direction="row" justifyContent="space-between" alignItems="center">
        <Accordion
          disableGutters
          elevation={0}
          expanded={open}
          onChange={() => {
            setOpen(!open);
          }}
        >
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography> Update Balance</Typography>
              {!open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              <b>
                $
                <NumberFormat
                  decimalScale={2}
                  fixedDecimalScale
                  displayType="text"
                  value={
                    (-1 * balance +
                      (isAdd ? addBalance * 100 : addBalance * -100)) /
                    100
                  }
                />
              </b>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={charge}
                    onClick={() => setCharge(!charge)}
                    color={"secondary"}
                  />
                }
                label="Charge"
              />
            </FormGroup>

            <FormControlLabel
              checked={isAdd}
              onChange={() => setIsAdd(true)}
              disabled={charge}
              control={<Checkbox color="secondary" />}
              label="Add"
            />
            <FormControlLabel
              checked={!isAdd}
              onChange={() => setIsAdd(false)}
              disabled={charge}
              control={<Checkbox color="secondary" />}
              label="Remove"
            />

            <FormControl fullWidth sx={{ mb: 1, mt: 2 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Amount
              </InputLabel>
              <OutlinedInput
                type="number"
                value={addBalance}
                size="small"
                onChange={(e) =>
                  setAddBalance(
                    e.target.value
                    // isNaN(parseInt(e.target.value.replace(/\D/g, "")))
                    //   ? ""
                    //   : parseInt(e.target.value.replace(/\D/g, ""))
                  )
                }
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
              />
            </FormControl>

            <ConfirmAddBalance
              fullWidth
              session={session}
              balance={addBalance}
              customerId={customerStripeId}
              charge={charge}
              add={isAdd}
              success={() => {
                setInitial();
                refetch();
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Paper>
  );
};

export default StripeBalance;
const WarningDialog = ({
  message,
  title,
  isLoading,
  isOpen,
  handleOpen,
  handleClose,
  handleConfirm,
}) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Backdrop open={isLoading}>
          <Typography
            variant={"subtitle1"}
            style={{ color: "white" }}
            align={"center"}
          >
            <CircularProgress color="primary" />
            <br />
          </Typography>
        </Backdrop>
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: "flex", alignItems: "center" }}>
            <ErrorIcon
              color="warning"
              fontSize="medium"
              sx={{ margin: "auto" }}
            />
          </div>

          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleConfirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const ConfirmAddBalance = ({
  session,
  balance,
  customerId,
  charge,
  add,
  success,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [verify, setVerify] = useState(false);

  const handleClickOpen = () => {
    if (balance.length !== 0) setOpen(true);
  };
  const handleTextChange = (e) => {
    setDescription(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [addBalance, { loading }] = useMutation(ADD_CUSTOMER_BALANCE);

  const onConfirmAddBalance = () => {
    if (!description || description.length < 5) {
      enqueueSnackbar("Please enter a description with at least 5 characters", {
        variant: "error",
      });
      return;
    }
    addBalance({
      variables: {
        id: customerId,
        charge: charge,
        input: {
          amount: precisionRoundMod(add ? balance * -100 : balance * 100, 2),
          description: description,
        },
      },
    })
      .then((res) => {
        enqueueSnackbar(`Balance added succesfully`, {
          variant: "success",
        });
        setOpen(false);
        success();
      })
      .catch((error) => {
        enqueueSnackbar(`Balance could not added`, {
          variant: "error",
        });
        setOpen(false);
      });
  };

  return (
    <div>
      <Button
        fullWidth
        size="small"
        variant="outlined"
        color="secondary"
        disabled={loading || balance.length === 0}
        // onClick={handleClickOpen}
        onClick={() => {
          setOpenWarning(true);
        }}
      >
        Update Balance
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Backdrop className={classes.backdropShippingCalc} open={loading}>
          <Typography
            variant={"subtitle1"}
            style={{ color: "white" }}
            align={"center"}
          >
            <CircularProgress color="primary" />
            <br />
            Please wait for Balance Update
          </Typography>
        </Backdrop>
        <DialogTitle id="alert-dialog-title">
          {`Stripe Balance adjustment`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {add ? "add" : "remove"} <b>${balance} </b>
            to this customer's balance?
            <Typography color={"error"} style={{ fontSize: "12px" }}>
              Note: This operation cannot be reversed
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              placeholder="Add internal note description"
              label="Description"
              value={description}
              fullWidth
              onChange={handleTextChange}
              variant="standard"
              required={true}
            />
            <VerifyAccount session={session} setVerify={setVerify} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={!verify}
            onClick={() => onConfirmAddBalance()}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <WarningDialog
        title={"Stripe Balance Adjustment"}
        message={"Are you sure you want to proceed?"}
        isOpen={openWarning}
        handleClose={() => setOpenWarning(false)}
        handleConfirm={() => {
          setOpenWarning(false);
          handleClickOpen();
        }}
      />
    </div>
  );
};
