import React, { useState, useEffect, useRef } from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/client";

import {
  GET_UNDERHOOD,
  UPDATE_UNDERHOOD_PAGE,
  GET_ADMINS,
  GET_UNDERHOOD_LIST,
  LOCKED_UNDERHOOD,
  SINGLE_UPLOAD
} from "../../../queries";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dante from "Dante2";
import {
  CssBaseline,
  TextField,
  Container,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  FormControlLabel
} from "@mui/material";
import { ImageBlockConfig } from "Dante2/package/es/components/blocks/image.js";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { startSingleUpload } from "../../Funcs/Upload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { UploadFile } from "../../Tools/Upload";
import IconButton from "@mui/material/IconButton";

const SpinnerAdornment = props => (
  <CircularProgress size={20} className="loadingbutton" />
);

const EditPage = props => {
  // blog id
  const { id } = props.match.params;

  // state
  const initialState = {
    title: "",
    admin_id: ""
  };

  const [open, setOpen] = useState(false);
  const [form, setState] = useState(initialState);
  const [site, setSite]  = useState('');
  const [main, setMain]               = useState(false);
  const [order, setOrder]  = useState(0);
  const [adminList, setAdminList] = useState([]);
  const [articleText, setArticleText] = useState([]);
  const [me, setMe] = useState([]);
  const [locked, setLocked] = useState(true);
  const [stat, setStat] = useState(false);
  const timerRef = useRef(null);
  const onAuto = useRef(false);
  const isInitialMount = useRef(true);
  const [load, setLoad] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState("");

  let { title, admin_id } = form;

  // query blog with id
  const { loading, error, data } = useQuery(GET_UNDERHOOD, {
    variables: { id }
  });

  const { data: data_admin, loading: loading_admin } = useQuery(GET_ADMINS);

  // mutations
  const [editUnderhood, { loading: loadingUpdate }] = useMutation(
    UPDATE_UNDERHOOD_PAGE
  );
  const [lockedUnderhood] = useMutation(LOCKED_UNDERHOOD);
  const [
    singleUpload,
    { loading: uploadLoading, error: uploadError }
  ] = useMutation(SINGLE_UPLOAD);

  // useEffect when query get data set state

  // validate

  const formValidate = () => {
    const { title } = form;
    return !title || !articleText.text;
  };

  useEffect(() => {
    console.log(site, order);
  }, [site, order]);

  useEffect(() => {
    if (onAuto.current) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (!formValidate()) {
        timerRef.current = setTimeout(() => {
          onSubmitAuto();
        }, 10000);
      }
    }
  }, [form, articleText]);

  useEffect(() => {
    const onCompleted = data => {
      console.log(data);
      setState({
        title: data.underhood.title,
        admin_id: data.underhood.admin.id
      });
      setArticleText({
        text: data.underhood.text
      });
      setStat(data.underhood.status);
      setSite(data.underhood.site);
      setOrder(data.underhood.order);
      setMain(data.underhood.main);
      isInitialMount.current = false;
    };
    const onError = error => {
      /* magic */
    };
    if (isInitialMount.current) {
      if (onCompleted || onError) {
        if (onCompleted && !loading && !error) {
          onCompleted(data);
        } else if (onError && !loading && error) {
          onError(error);
        }
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (!loading_admin && data_admin) {
      const me = data_admin.admins.filter(
        x => x.id === props.session.activeAdmin.id
      );
      setAdminList(data_admin);
      setMe(me[0]);
    }
  }, [data_admin, loading_admin]);

  useEffect(() => {
    const { id } = props.match.params;
    lockedUnderhood({
      variables: {
        id,
        locked: locked
      }
    }).then(async ({ data }) => {
      console.log(data);
    });
    return () => {
      lockedUnderhood({
        variables: {
          id,
          locked: false
        }
      }).then(async ({ data }) => {
        console.log(data);
      });
    };
  }, [locked]);

  useEffect(() => {
    const cleanup = () => {
      console.log("clean up");
      const { id } = props.match.params;
      lockedUnderhood({
        variables: {
          id,
          locked: false
        }
      }).then(async ({ data }) => {
        console.log(data);
      });
    };

    window.addEventListener("beforeunload", cleanup);
    // useBeforeunload(() => cleanup);
  }, []);

  // if loading data
  if (loading || loading_admin)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );

  if (error) return <div>Error.</div>;

  // update onChange form
  const updateField = e => {
    setState({
      ...form,
      [e.target.name]: e.target.value
    });
    onAuto.current = true;
  };

  const updateFieldSite = e => {
    setSite(e.target.value);
    console.log(site);
  };

  const updateFieldOrder = e => {
    setOrder(e.target.value);
    console.log(order);
  };

  // status change
  const handleChange = e => {
    setStat(e.target.checked);
  };

  const handleChangeMain = event => {
    setMain(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPreview = () => {
    setOpen(true);
  };

  // editor update
  const onChangeEditor = text => {
    const currentText = articleText.text;
    const newText = JSON.stringify(text);
    if (currentText !== newText) {
      // Content has changed
      setArticleText({ text: newText });
      onAuto.current = true;
    }
  };

  // auto save
  const onSubmitAuto = () => {
    console.log("title", title);

    // console.log('load', load);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    editUnderhood({
      variables: {
        id,
        admin_id,
        title,
        text: articleText.text,
        status: stat,
        site,
        order: parseInt(order),
        main
      }
    }).then(async ({ data }) => {
      console.log(data);
    });
  };

  const uploadFile = async (file, imageBlock) => {
    await startSingleUpload(file, singleUpload, async result => {
      imageBlock.state.url = result.uri;
      imageBlock.updateData();
      imageBlock.stopLoader();
    });
  };

  // clear state
  const resetState = () => {
    setState({ ...initialState });
  };


  return (
    <div>
      <Container>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="false"
        >
          <DialogTitle>
            <h2>{form.title}</h2>
          </DialogTitle>

          <DialogContent>
            <div style={{ width: 800 }} className="fullWidthImage">
              <Dante
                read_only={true}
                content={JSON.parse(data.underhood.text)}
              />
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container spacing={3}>
          <CssBaseline />
          <Grid item xs={9}>
            <div className="">
              <TextField
                onChange={updateField}
                variant="outlined"
                inputProps={{ style: { fontSize: 22 } }}
                InputLabelProps={{ style: { fontSize: 22 } }}
                margin="normal"
                value={form.title}
                required
                fullWidth
                name="title"
                label="Title"
                type="text"
                id="title"
              />


              <div className="editordante">
                <Dante
                  className="editordark"
                  body_placeholder={"Write something..."}
                  widgets={[
                    ImageBlockConfig({
                      options: {
                        upload_handler: (file, imageBlock) =>
                          uploadFile(file, imageBlock),
                        image_caption_placeholder: "Text Here!"
                      }
                    })
                  ]}
                  content={JSON.parse(data.underhood.text)}
                  onChange={editor => {
                    onChangeEditor(editor.emitSerializedOutput());
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <Paper className="padding10 margintop15">
              <div>
                <div>
                  <FormControl variant="outlined" className="width100 ">
                    <InputLabel id="category">Select Author</InputLabel>
                    <Select
                      name="admin_id"
                      value={form.admin_id}
                      onChange={updateField}
                      label="category"
                    >
                      <MenuItem value={props.session.activeAdmin.id}>
                        {me.name} (Me)
                      </MenuItem>

                      {adminList.admins &&
                        adminList.admins.map(admin => {
                          if (admin.id !== props.session.activeAdmin.id) {
                            return (
                              <MenuItem key={admin.id} value={admin.id}>
                                {admin.name}
                              </MenuItem>
                            );
                          }
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <br/>
              <div>
              <FormControl variant="outlined" className="width100">
                <InputLabel id="site-id">Select Site</InputLabel>
                <Select
                  labelId="site-id"
                  id="site-id"
                  value={site}
                  onChange={updateFieldSite}
                >
                  <MenuItem value={'beast-village'}>Beast Village</MenuItem>
                  <MenuItem value={'real-dog'}>Real Dog</MenuItem>
                </Select>
              </FormControl>
              </div>
              <div>
              <FormControlLabel
                control={
                  <Switch
                    className="padding10"
                    checked={main}
                    onChange={handleChangeMain}
                    name="main"
                    color="secondary"
                    inputProps={{
                      "aria-label": "secondary checkbox"
                    }}
                  />
                }
                label={'Main Page'}
              />
              </div>
              <div>
                {!main ? (
              <TextField
                onChange={updateFieldOrder}
                variant="outlined"
                margin="normal"
                value={order}
                required
                fullWidth
                name="order"
                label="Order"
                type="number"
                id="order"
              />
                ): ''}
              </div>
              <br />
              <FormControlLabel
                control={
                  <Switch
                    className="padding10"
                    checked={stat}
                    onChange={handleChange}
                    name="status"
                    color="secondary"
                    inputProps={{
                      "aria-label": "secondary checkbox"
                    }}
                  />
                }
                label={stat ? "Published" : "Archive"}
              />
              <br /> <br />
              <Button
                type="submit"
                fullWidth
                onClick={handleOpenPreview}
                variant="outlined"
                color="primary"
                size="large"
              >
                <VisibilityIcon /> Preview
              </Button>
              <br /> <br />
              <Button
                type="submit"
                fullWidth
                onClick={onSubmitAuto}
                variant="outlined"
                color={stat ? "secondary" : "primary"}
                size="large"
                disabled={loading || formValidate()}
              >
                {stat ? "PUBLISH" : "SAVE DRAFT"}
                {loadingUpdate && <SpinnerAdornment />}
              </Button>
              {error && <div>{error.message}</div>}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withApollo(EditPage);
