import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
import { useQuery } from "@apollo/client";
import { GET_ADMINS } from "../../../queries";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddAuthorDialog({ onAddNewAuthor, authors }) {
  const [open, setOpen] = React.useState(false);
  const [admins, setAdmins] = useState([]);
  const { loading, error } = useQuery(GET_ADMINS, {
    onCompleted: (res) => {
      const authorsList = res.admins.filter((x) => {
        return (
          (x.permission &&
            x.permission.author &&
            x.permission.author?.admin === true) ||
          x.permission.author?.read === true ||
          x.permission.author?.write === true
        );
      });
      setAdmins(authorsList);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleListItemClick = (admin) => {
    onAddNewAuthor(admin);
    handleClose();
  };

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <div>error</div>;
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        disabled={authors.length >= 5}
      >
        add author
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle>{"Select an author"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <List sx={{ pt: 0 }}>
              {admins
                .filter(
                  (item) => !authors.map((elem) => elem.id).includes(item.id)
                )
                .map((admin) => (
                  <ListItem
                    button
                    onClick={() => handleListItemClick(admin)}
                    key={admin.id}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${admin.name} ${admin.surname}`} />
                  </ListItem>
                ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
