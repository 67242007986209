import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import KeywordComponentHintEditor from "../../../../assets/editorHints/KeywordEditor.jpeg";

const KeywordComponent = ({ color, text, link }) => {
  let darkerColor = "#b93e4e";
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        paddingTop: 60,
        paddingBottom: 60,
        backgroundColor: "#" + color,
      }}
    >
      <Typography>Learn more about</Typography>
      <Link
        to={link}
        style={{
          marginLeft: 10,
          textDecoration: "none",
        }}
      >
        <Button
          style={{
            backgroundColor: darkerColor,
            color: "white",
          }}
        >
          {text}
        </Button>
      </Link>
    </Grid>
  );
};
export default KeywordComponent;

export const KeywordComponentInfoDialog = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <InfoIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent>
          <img src={KeywordComponentHintEditor}></img>
          <Typography>
            Click the dropdown and select the item.
            <br></br>
            Color is disabled for now.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
