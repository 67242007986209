import React, { useState } from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DELETE_GIFT_COUPON } from "../../../../queries";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const CancelCouponButton = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [deleteCode, { loading }] = useMutation(DELETE_GIFT_COUPON);

  const handleGenerate = () => {
    deleteCode({
      variables: {
        id: props.id,
        input: { printed: true },
      },
    })
      .then(() => {
        enqueueSnackbar(`Saved.`, {
          variant: "success",
        });
        handleClose();
        props.refetch();
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: "error",
        });
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant="outlined" color="error" onClick={handleClickOpen}>
        <DeleteForeverIcon />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ minWidth: "200px" }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this card?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button
            disabled={loading}
            onClick={handleGenerate}
            autoFocus
            color="secondary"
            variant="outlined"
          >
            {loading ? <CircularProgress /> : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CancelCouponButton;
