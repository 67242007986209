import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useMutation } from "@apollo/client";
import {
  ADD_COMPANY_TO_DIRECTORY_STATE,
  UPDATE_COMPANY_OF_DIRECTORY_STATE,
} from "../../../queries";
import { useSnackbar } from "notistack";
import { Edit } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

/*
  name: String
  email: String
  phone: String
  city: String
  category: String
  website: String
  socialMediaAdress: String
  information: String
  zipCode: String
  latitude: String
  longitude: String
*/

const categoriesList = [
  "Butcher",
  "Co-Op",
  "Ethnic & Specialty Market",
  "Grocery Store",
  "Local Farm",
];

export default function CompanyItem({
  singleDirectoryState,
  refetch,
  singleCompany = {},
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [city, setcity] = useState("");
  const [category, setcategory] = useState("");
  const [website, setwebsite] = useState("");
  const [socialMediaAdress, setsocialMediaAdress] = useState("");
  const [information, setinformation] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");

  const [createCompany] = useMutation(ADD_COMPANY_TO_DIRECTORY_STATE);
  const [updateCompany] = useMutation(UPDATE_COMPANY_OF_DIRECTORY_STATE);

  const initialState = () => {
    setname("");
    setemail("");
    setphone("");
    setcity("");
    setcategory("");
    setwebsite("");
    setsocialMediaAdress("");
    setinformation("");
    setzipCode("");
    setlatitude("");
    setlongitude("");
  };

  const initialStateUpdate = () => {
    setname(singleCompany.name);
    setemail(singleCompany.email);
    setphone(singleCompany.phone);
    setcity(singleCompany.city);
    setcategory(singleCompany.category);
    setwebsite(singleCompany.website);
    setsocialMediaAdress(singleCompany.socialMediaAdress);
    setinformation(singleCompany.information);
    setzipCode(singleCompany.zipCode);
    setlatitude(singleCompany.latitude);
    setlongitude(singleCompany.longitude);
  };

  React.useEffect(() => {
    if (open) {
      if (Object.keys(singleCompany).length > 0) {
        initialStateUpdate();
      } else {
        initialState();
      }
    }
  }, [open]);

  const onCreateCompany = () => {
    createCompany({
      variables: {
        id: singleDirectoryState.id,
        input: {
          name,
          email,
          phone,
          city,
          category,
          website,
          socialMediaAdress,
          information,
          zipCode,
          latitude,
          longitude,
        },
      },
    })
      .then((res) => {
        handleClose();
        enqueueSnackbar("Created Successfully", {
          variant: "success",
        });
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      });
  };

  const onUpdateCompany = () => {
    updateCompany({
      variables: {
        id: singleDirectoryState.id,
        companyId: singleCompany.id,
        input: {
          name,
          email,
          phone,
          city,
          category,
          website,
          socialMediaAdress,
          information,
          zipCode,
          latitude,
          longitude,
        },
      },
    })
      .then((res) => {
        handleClose();
        enqueueSnackbar("Created Successfully", {
          variant: "success",
        });
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {Object.keys(singleCompany).length > 0 ? (
        <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
          <Edit />
        </IconButton>
      ) : (
        <Button variant="outlined" onClick={handleClickOpen}>
          <Add />
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          {Object.keys(singleCompany).length > 0
            ? `Update: ${singleCompany.name}`
            : `Add new company to ${singleDirectoryState.name}`}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            spacing={1}
            style={{
              paddingTop: 10,
            }}
          >
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="City"
                variant="outlined"
                value={city}
                onChange={(e) => {
                  setcity(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-select-currency"
                select
                label="Select"
                defaultValue={category}
                helperText="Please select category"
                onChange={(e) => {
                  setcategory(e.target.value);
                }}
                fullWidth
              >
                {categoriesList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Category"
                variant="outlined"
                value={category}
                onChange={(e) => {
                  setcategory(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                value={phone}
                onChange={(e) => {
                  setphone(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Information"
                variant="outlined"
                value={information}
                onChange={(e) => {
                  setinformation(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Website"
                variant="outlined"
                value={website}
                onChange={(e) => {
                  setwebsite(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Social Media Address"
                variant="outlined"
                value={socialMediaAdress}
                onChange={(e) => {
                  setsocialMediaAdress(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Zipcode"
                variant="outlined"
                value={zipCode}
                onChange={(e) => {
                  setzipCode(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Latitude"
                variant="outlined"
                value={latitude}
                onChange={(e) => {
                  setlatitude(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Longitude"
                variant="outlined"
                value={longitude}
                onChange={(e) => {
                  setlongitude(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
          {Object.keys(singleCompany).length > 0 ? (
            <Button onClick={onUpdateCompany}>Update</Button>
          ) : (
            <Button onClick={onCreateCompany}>Create</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
