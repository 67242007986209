import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Checkbox from "@mui/material/Checkbox";
import { COMPLETE_THE_TUTORIAL, GET_CUSTOMER } from "../../../../../queries";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CompleteTutorialDialog({
  tutorialItem,
  courseId,
  unitId,
  refetch,
}) {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: { id },
  });
  const [completeTheTutorialMutation, { loading: loadingUpdate }] = useMutation(
    COMPLETE_THE_TUTORIAL
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onMarkAsCompleted = () => {
    const input = {
      customerId: data.customer.id,
      courseId: courseId,
      unitId: unitId,
      tutorialId: tutorialItem.id,
    };
    completeTheTutorialMutation({
      variables: {
        input: input,
      },
    })
      .then(() => {
        enqueueSnackbar("Updated successfully", {
          variant: "success",
        });
        refetch();
      })
      .catch(() => {
        enqueueSnackbar("Unable to update", {
          variant: "error",
        });
      });
    handleClose();
  };
  if (loading || loadingUpdate) {
    return <CircularProgress size={20} />;
  }
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <div
        style={{
          marginRight: -12,
        }}
      >
        <Checkbox
          checked={false}
          onClick={handleClickOpen}
          disabled={tutorialItem.content?.quizType === "FINAL_EXAM"}
        />
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Be carefull"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to sign {tutorialItem.title} as completed for{" "}
            {data?.customer?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              onMarkAsCompleted();
            }}
          >
            Mark as completed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
