import React, { useEffect, useState } from "react";
import { Button, TextField, CircularProgress } from "@mui/material";
import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CREATE_ROOM_NOTES } from "../../../../queries";
import { useMutation } from "@apollo/client";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";

const OnOffSwitch = ({ room, getNotes, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const [status, setStatus] = useState(false);

  const [saveNote, { data, loading, error }] = useMutation(CREATE_ROOM_NOTES);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (room && room.status) {
      setStatus(room.status);
    }
  }, [room]);

  const handleSave = () => {
    saveNote({
      variables: {
        input: {
          note: `Turned ${status ? "on" : "off"} the ${room.name}.`,
          type: "upgrade",
          roomId: room.id,
          createdAt: moment().unix(),
          changeStatus: true,
          status: status,
        },
      },
    })
      .then(({ data }) => {
        console.log("data", data);
        getNotes();
        refetch();
        handleClose();
      })
      .catch((e) => {
        enqueueSnackbar(`${e.message}`, {
          variant: "error",
        });
      });
  };

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <Typography>Under Maintenance</Typography>
        <Switch
          color="secondary"
          checked={status}
          onClick={() => {
            setStatus(!status);
            handleClickOpen();
          }}
        />
        <Typography>Available</Typography>
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${room.name} Change Status`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>Do you want to save the change?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleSave} color="primary" disabled={loading}>
            {loading ? <CircularProgress /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OnOffSwitch;
