import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";

//Library
import {
  Button,
  CssBaseline,
  TextField,
  Container,
  CircularProgress,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  FormControlLabel,
  IconButton,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import moment from "moment-timezone";
import MailTemplate from "./Components/mailTemplate";

//utils
import { UploadFile } from "../../Tools/Upload";
import { startSingleUpload } from "../../Funcs/Upload";

//queries
import {
  GET_ADMINS,
  GET_SHORTLEASH,
  UPDATE_SHORTLEASH,
} from "../../../queries";

import { useSnackbar } from "notistack";

import Editor from "./Editor";
import { createRandKey } from "../../../globals";

import WebsiteCategories from "./Components/websiteCat";

const filter = createFilterOptions();

const ShortleashEdit = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const onAuto = useRef(false);
  const { id } = props.match.params;
  const [form, setForm] = useState({
    title: "",
    admin_id: "",
  });
  const [status, setStatus] = useState(false);
  const [articleText, setArticleText] = useState({});
  const isInitialMount = useRef(true);
  const [adminList, setAdminList] = useState([]);
  const [me, setMe] = useState([]);
  const [node, setNode] = useState([]);
  const [website, setWebsite] = useState("");

  //UseQuery & Mutation
  const { data: data_admin, loading: loading_admin } = useQuery(GET_ADMINS);
  const [
    edit_shortleash,
    { error: errorUpdate, loading: loadingAddShortleash },
  ] = useMutation(UPDATE_SHORTLEASH);
  const { loading, error, data, refetch } = useQuery(GET_SHORTLEASH, {
    variables: { id },
  });

  //useEffect
  useEffect(() => {
    if (!loading_admin && data_admin) {
      const me = data_admin.admins.filter(
        (x) => x.id === props.session.activeAdmin.id
      );
      setAdminList(data_admin);
      setMe(me[0]);
      setForm({
        ...form,
        admin_id: me[0].id,
      });
    }
  }, [data_admin, loading_admin]);

  useEffect(() => {
    const onCompleted = (data) => {
      console.log("data", data);
      const { getShortleashByIdAdmin } = data;
      if (getShortleashByIdAdmin) {
        setForm({
          title: getShortleashByIdAdmin.title,
          admin_id: getShortleashByIdAdmin.admin_id,
        });

        let newNode = [];
        getShortleashByIdAdmin.leashes.map((n) =>
          newNode.push({ title: n.title, html: n.html, thumbnail: n.thumbnail })
        );
        setNode(newNode);
        setWebsite(
          getShortleashByIdAdmin.website ? getShortleashByIdAdmin.website : ""
        );
        setStatus(getShortleashByIdAdmin.status);
        isInitialMount.current = false;
      }
    };
    const onError = (error) => {
      /* magic */
    };
    if (isInitialMount.current) {
      if (onCompleted || onError) {
        if (onCompleted && !loading && !error) {
          onCompleted(data);
        } else if (onError && !loading && error) {
          onError(error);
        }
      }
    }
  }, [loading, data, error]);

  //Default Form Action
  const updateField = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    onAuto.current = true;
  };

  const handleChange = (event) => {
    setStatus(event.target.checked);
  };

  const onSubmit = async () => {
    edit_shortleash({
      variables: {
        id,
        input: {
          title: form.title,
          admin_id: form.admin_id,
          status,
          leashes: node,
          updatedAt: moment().unix(),
          website: website,
        },
      },
    })
      .then(async ({ data }) => {
        enqueueSnackbar(`Shortleash saved successfully.`, {
          variant: "success",
        });
        refetch();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //Node Action
  const handleNodeMore = (e) => {
    setNode((node) => [
      ...node,
      {
        title: "",
        html: "",
        thumbnail: "",
      },
    ]);
  };

  const handleNodeLess = (e) => {
    let nodeLess = node.slice(0, node.length - 1);
    setNode(nodeLess);
  };

  const changeNodeTitle = (event, num) => {
    node[num]["title"] = event.target.value;
    setNode((node) => [...node]);
  };

  const changeNodeText = (text, num) => {
    const currentText = articleText.text;
    const newText = JSON.stringify(text);
    if (currentText !== newText) {
      // Content has changed
      setArticleText({ text: newText });
      onAuto.current = true;
    }
    node[num]["html"] = newText;
    setNode((node) => [...node]);
  };

  let uploadNodePhoto = ({ uri }, num) => {
    node[num]["thumbnail"] = uri;
    setNode((node) => [...node]);
  };

  const resetNodePhoto = (num) => {
    node[num]["thumbnail"] = "";
    setNode((node) => [...node]);
    setNode([...node]);
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <CssBaseline />
        <Grid item xs={9}>
          <div className="">
            <TextField
              variant="outlined"
              inputProps={{ style: { fontSize: 22 } }}
              InputLabelProps={{ style: { fontSize: 22 } }}
              margin="normal"
              required
              fullWidth
              value={form.title}
              name="title"
              label="Title"
              type="text"
              id="title"
              onChange={updateField}
            />

            {node.map((field, i) => (
              <NodeArea
                num={i}
                lastNode={node.length - 1}
                field={field}
                nodeLess={handleNodeLess}
                moreNode={handleNodeMore}
                changeTitle={changeNodeTitle}
                changeNodeText={changeNodeText}
                uploadNodePhoto={uploadNodePhoto}
                resetNodePhoto={resetNodePhoto}
              />
            ))}
          </div>
        </Grid>
        <Grid item xs={3}>
          <Paper className="padding10 margintop15">
            <div>
              <FormControl variant="outlined" className="width100">
                <InputLabel id="category">Select Author</InputLabel>
                <Select
                  name="admin_id"
                  value={form.admin_id}
                  onChange={updateField}
                  label="category"
                >
                  <MenuItem value={props.session.activeAdmin.id}>
                    {me.name} (Me)
                  </MenuItem>

                  {adminList.admins &&
                    adminList.admins.map((admin) => {
                      if (admin.id !== props.session.activeAdmin.id) {
                        return (
                          <MenuItem key={admin.id} value={admin.id}>
                            {admin.name}
                          </MenuItem>
                        );
                      }
                    })}
                </Select>
              </FormControl>
            </div>
            <br />
            <WebsiteCategories website={website} setWebsite={setWebsite} />
            <br />
            <FormControlLabel
              control={
                <Switch
                  className="padding10"
                  checked={status}
                  onChange={handleChange}
                  name="status"
                  color="secondary"
                  inputProps={{
                    "aria-label": "secondary checkbox",
                  }}
                />
              }
              label={status ? "Published" : "Archive"}
            />
            {node.filter((data) => data.title == "").length > 0 && (
              <p>Titles are required.</p>
            )}
            <br />
            <MailTemplate data={data} />
            <br />
            <Button
              type="submit"
              fullWidth
              onClick={onSubmit}
              variant="outlined"
              color={status ? "secondary" : "primary"}
              size="large"
              disabled={
                loadingAddShortleash ||
                node.filter((data) => data.title == "").length > 0
              }
            >
              {status ? "PUBLISH" : "SAVE DRAFT"}
              {/* {loadingAddShortleash && <SpinnerAdornment />} */}
            </Button>
            {errorUpdate && <div>{errorUpdate.message}</div>}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const NodeArea = (props) => {
  return (
    <div key={props.num} className="nodes_box">
      <Grid container>
        <Grid item xs={12}>
          <div className="titlebox">Add Nodes</div>
          <TextField
            onChange={(event) => props.changeTitle(event, props.num)}
            defaultValue={props.field.title}
            variant="outlined"
            inputProps={{ style: { fontSize: 22 } }}
            InputLabelProps={{ style: { fontSize: 22 } }}
            margin="normal"
            required
            fullWidth
            name="title"
            label="Title"
            type="text"
            id="title"
          />
          <br />
          <div className="editordante node">
            <Editor
              content={props.field.html}
              onChangeEditor={props.changeNodeText}
              num={props.num}
            />
          </div>
          {/* <TextField
            id="outlined-multiline-static"
            label="Text"
            multiline
            fullWidth
            rows={4}
            placeholder="Change my mind"
            variant="outlined"
            defaultValue={props.field.html}
            onChange={(event) => props.changeNodeText(event, props.num)}
          /> */}
          <br />
          {props.field.thumbnail ? (
            <div className="featuredImgBlock">
              <div className="deletephotobtn">
                <IconButton
                  onClick={() => props.resetNodePhoto(props.num)}
                  color="secondary"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <img width={"100%"} src={props.field.thumbnail} />
            </div>
          ) : (
            <UploadFile
              idKey={"uplad-image-key-" + createRandKey(6)}
              uploadPhoto={(file) => props.uploadNodePhoto(file, props.num)}
              buttonName={"Add Featured Image"}
            />
          )}
        </Grid>
      </Grid>

      <br />
      {props.lastNode === props.num ? (
        <div>
          {" "}
          <Button variant="outlined" color="secondary" onClick={props.moreNode}>
            {" "}
            + Add Node{" "}
          </Button>{" "}
          {props.num != 0 ? (
            <Button variant="outlined" color="primary" onClick={props.nodeLess}>
              {" "}
              DELETE{" "}
            </Button>
          ) : (
            ""
          )}{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

export default withApollo(ShortleashEdit);
