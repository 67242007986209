import { useQuery, useLazyQuery } from "@apollo/client";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React, { useState } from "react";
import { GET_TOP_AFFILIATES } from "../../../../../queries";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatePicker from "@mui/lab/DatePicker";
import { Link } from "react-router-dom";
import moment from "moment";

import { Button } from "@mui/material";

const days28 = 2419200;

const TopAffiliates = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    getTopAffiliates();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [getTopAffiliates, { data }] = useLazyQuery(GET_TOP_AFFILIATES, {
    variables: {
      time: moment().unix(),
    },
    context: {
      server: "bvadmin",
    },
  });

  return (
    <div style={{ marginTop: "10px" }}>
      <Button
        variant="outlined"
        color="warning"
        fullWidth
        onClick={handleClickOpen}
      >
        Show Top Affiliates
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          {data?.getLastTopAffiliates
            ? "Top 10 Affiliates (" +
              moment
                .unix(data.getLastTopAffiliates.createdAt - days28)
                .format("DD/MM/YYYY") +
              " - " +
              moment
                .unix(data.getLastTopAffiliates.createdAt)
                .format("DD/MM/YYYY") +
              ")"
            : "Top 10 Affiliates "}
        </DialogTitle>
        <DialogContent>
          <div className={"shadowed-box  gold-box topreferrers"}>
            <Grid container spacing={3}>
              <List
                sx={{
                  marginTop: "20px",
                  width: "100%",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                {data?.getLastTopAffiliates &&
                  data.getLastTopAffiliates.list.map((member, index) => {
                    return (
                      <ListComp
                        name={member.name}
                        index={`list-comp-${index}`}
                        total={member.total}
                        key={index}
                        id={member.id}
                      />
                    );
                  })}
              </List>
            </Grid>
            <small>
              <em>
                {" "}
                {data?.getLastTopAffiliates &&
                  "*List will be updated at " +
                    moment
                      .unix(data.getLastTopAffiliates.createdAt + days28)
                      .format("DD/MM/YYYY")}
              </em>
            </small>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TopAffiliates;

const ListComp = ({ name, total, index, id }) => {
  return (
    <ListItem
      className="clearfix"
      key={index}
      sx={{
        display: "block",
        width: "100%",
        margin: "10px 0px",
        height: "25px",
      }}
    >
      <Link
        style={{
          display: "block",
          float: "left",
          marginTop: 0,
          marginBottom: 0,
          color: "#fff",
          textDecoration: "none",
        }}
        to={`/dashboard/customer/${id}`}
      >
        {name}
      </Link>
      <p
        style={{
          display: "block",
          float: "right",
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        {total}
      </p>
      {index === "list-comp-0" && <EmojiEventsIcon />}
    </ListItem>
  );
};
