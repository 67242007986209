import { useQuery } from "@apollo/client";
import List from "@mui/material/List";
import React from "react";
import { GET_DIRECTIRY_STATES } from "../../../queries";
import LinearLoading from "../LinearLoading";
import SingleDirectoryState from "./SingleDirectoryState";
const DirectoryState = () => {
  const { loading, data, error } = useQuery(GET_DIRECTIRY_STATES);

  if (loading) {
    return <LinearLoading />;
  }
  if (error) {
    return <div>Error</div>;
  }
  return (
    <div>
      <List sx={{ width: "100%", maxWidth: 600, bgcolor: "background.paper" }}>
        {data.getDirectoryStates.map((singleDirectoryState) => {
          return (
            <SingleDirectoryState
              key={singleDirectoryState.id}
              singleDirectoryState={singleDirectoryState}
            />
          );
        })}
      </List>
    </div>
  );
};

export default DirectoryState;
