import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";

import { GET_WNINE_AFFILIATE, UPDATE_WNINE_AFFILIATE } from "../../../../../queries"; //Queries

import W9ErrorDialog from "../AffiliateDialog/w9";

function W9List(props) {
    const [list, setList]       = useState([]);
    const { enqueueSnackbar }   = useSnackbar();
    const [open, setOpen]       = useState(false);
    const [userId, setUserId]   = useState("");
    const [message, setMessage] = useState("");

     //Query
    const [getWaitingW9, { loading, data, refetch, error }] = useLazyQuery(GET_WNINE_AFFILIATE, {
      fetchPolicy: "no-cache",
      onCompleted: (res) => { setList(res.waitingVerifyw9);
      },
    });

    //Mutation
    const [updateW9Affiliate, { loading: loader }] = useMutation(UPDATE_WNINE_AFFILIATE);

    useEffect(() => {
      getWaitingW9()
      .then(res => {
        if(data?.waitingVerifyw9.length > 0){
          setList(data.waitingVerifyw9);
        }
      })
    }, []);

    const onSubmit = (id, status) => {
        updateW9Affiliate({
          variables: {
            id,
            status,
            message
          }
        })
        .then(result => {
          enqueueSnackbar("Updated", { variant: "success" });
          refetch();
        })
        .catch(err => enqueueSnackbar(err, { variant: "error"}));
    };

    if(loading)
      return <> <CircularProgress className="loadingbutton" /> </>

    return <>
      <ul>
      <W9ErrorDialog
        open={open}
        user={userId}
        message={message}
        handleClose={() => setOpen(false)}
        onSubmit={() => onSubmit(userId, 2)}
        setMessage={setMessage}
      />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">File</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length > 0 && list.map((user, i) => <TableRow key={user.id}>
                <TableCell component="th" align="left" scope="row">
                  {user.name}
                </TableCell>
                <TableCell component="th" align="left" scope="row">
                  {user.email}
                </TableCell>
                <TableCell component="th" align="left" scope="row">
                  {user.phone_number}
                </TableCell>
                <TableCell component="th" align="left" scope="row">
                  {user.w9Link.verify === 2 ? `Waiting Response : [${user.w9Link.message}]` : "Waiting Approve" }
                </TableCell>
                <TableCell component="th" align="left" scope="row">
                  <Button
                    className="redborder"
                    variant="outlined"
                    href={user.w9Link.file}
                    size="small"
                    target="_blank"
                  >
                    <b className="redborder">View File</b>
                  </Button>
                </TableCell>
                <TableCell component="th" align="left" scope="row">
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="greenborder"
                    onClick={() => onSubmit(user.id, 1)}
                    disabled={loader}
                  >
                    <b className="greenborder">Approve</b>
                  </Button>

                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    className="redborder"
                    onClick={() => { setOpen(true); setUserId(user.id)} }
                    style={{ "marginLeft": "40px" }}
                    disabled={user.w9Link.verify === 2 && true}
                  >
                    <b className="greenborder">Reject</b>
                  </Button>
                </TableCell>

              </TableRow>
            )}
          </TableBody>
          </Table>
        </TableContainer>
      </ul>
    </>
}

export default W9List;
