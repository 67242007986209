import { gql } from "@apollo/client";

const GET_FLAG = gql`
  query GetFlag($id: ID, $key: String) {
    getFlag(id: $id, key: $key) {
      id
      key
      message
      status
      data
    }
  }
`;
const UPDATE_FLAG = gql`
  mutation UpdateFlag($id: ID, $input: inputFlag) {
    updateFlag(id: $id, input: $input) {
      id
      message
      key
      status
      data
    }
  }
`;

export { GET_FLAG, UPDATE_FLAG };
