import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MobileDatePicker from "@mui/lab/MobileDatePicker";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FETCH_APPOINTMENTS_BY_DATE,
  GET_APPOINTMENT_BY_STATUS,
  GET_SUPPORT_MEMBERS,
  UPDATE_APPOINTMENT,
  ADD_NOTES_APPOINTMENT,
  ALLOW_STM,
  GET_AVAILABLE_SUPPORT_MEMBERS,
  GET_ACCOUNT_MANAGER,
  GET_APPONTMENT_MEMBER,
  ADD_NOTE_APPOINTMENT,
} from "../../../queries";

import ScheduleSteps from "./Components/Stepper";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CustomizedMenus } from "./Components/Stepper";
import EditDialog from "./Components/Stepper/editDialog";
import TextField from "@mui/material/TextField";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import CustomerFind from "./Components/CustomerFind";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  parent: {
    position: "relative",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000025",
    position: "absolute",
  },
  container: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "red",
  },
  containerDeclined: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "red",
  },
  containerPending: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "orange",
  },
  containerApproved: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "green",
  },
  containerReserved: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "green",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
}));

const ConsultationSearch = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const statusArr = {
    canceled: "containerDeclined",
    pending: "containerPending",
    reserved: "containerReserved",
  };
  const [customer, setCustomer] = useState({});
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [open, setOpen] = React.useState(false);
  const [filterAppointment, setFilterAppointment] = useState("pending");
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedSupportMember, setSelectedSupportMember] = useState({});
  const [useDate, setUseDate] = useState(false);
  const [appointmentList, setAppointmentList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [selectedItem, setSelectedItem] = useState({ id: "" });
  const [getAppointmentsOfUser] = useLazyQuery(GET_APPONTMENT_MEMBER);

  const {
    loading: loadingAccountManager,
    data,
    error,
  } = useQuery(GET_ACCOUNT_MANAGER);

  const handleClickOpenDialog2 = (txt, item) => {
    setDialogText(txt);
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleCloseDialog2 = () => {
    setOpenDialog(false);
    setDialogText("");
    setSelectedItem({});
  };

  const [fetchAppointmentsByDate, { loading, refetch }] = useLazyQuery(
    FETCH_APPOINTMENTS_BY_DATE,
    {
      fetchPolicy: "no-cache",
      variables: {
        date: selectedDate,
      },
      onCompleted: (data) => {
        setAppointmentList(data.getAppointmentsByDate);
      },
    }
  );
  const [
    fetchAppointmentsByStatus,
    {
      loading: stateLoading,
      data: stateData,
      error: stateError,
      refetch: refetchFilter,
    },
  ] = useLazyQuery(GET_APPOINTMENT_BY_STATUS, {
    fetchPolicy: "no-cache",
    variables: {
      status: filterAppointment,
    },
    onCompleted: (data) => {
      setAppointmentList(data.getAppointmentsByStatus);
    },
  });


  const [updateAppointment] = useMutation(UPDATE_APPOINTMENT);

  const { loading: supportLoading, data: supportMemberData } =
    useQuery(GET_SUPPORT_MEMBERS);

  useEffect(() => {
    reFetchFunction();
  }, [selectedDate, filterAppointment, useDate]);

  const reFetchFunction = () => {
    if (useDate) {
      fetchAppointmentsByDate();
    } else {
      fetchAppointmentsByStatus({
        variables: {
          status: filterAppointment,
        },
      })
        .then((res) => {
          setAppointmentList(res.data.getAppointmentsByStatus);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCancelAppointment = async () => {
    const res = await updateAppointment({
      variables: {
        appointmentId: selectedItem.id,
        input: {
          status: "canceled",
        },
      },
    });
    if (res && res.data.updateAppointment !== null) {
      setOpenDialog(false);
      setDialogText("");
      setSelectedItem({});
      refetch();
    } else {
      setOpenDialog(false);
      setDialogText("");
      setSelectedItem({});
      refetch();
    }
  };

  const onApproveAppointment = async () => {
    const res = await updateAppointment({
      variables: {
        appointmentId: selectedItem.id,
        input: {
          status: "reserved",
        },
      },
    });
    if (res && res.data.updateAppointment !== null) {
      setOpenDialog(false);
      setDialogText("");
      setSelectedItem({});
      reFetchFunction();
    } else {
      setOpenDialog(false);
      setDialogText("");
      setSelectedItem({});
      reFetchFunction();
    }
  };
  const [saveNoteMutation] = useMutation(ADD_NOTE_APPOINTMENT);
  const onSaveNotes = (item, text) => {
    saveNoteMutation({
      variables: {
        customerId: item.customer.id,
        appointmentId: item.id,
        note: text,
      },
    })
      .then((res) => {
        enqueueSnackbar("Note Added Successfully", { variant: "Success" });
      })
      .catch((err) => {
        enqueueSnackbar("Note could not", { variant: "error" });
      });
  };

  const onSearch = () => {
    getAppointmentsOfUser({
      variables: {
        customerId: customer.id,
      },
    })
      .then((res) => {
        setAppointmentList(res.data.getCustomerAppointments.appointments);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <CustomerFind
        userSelection={true}
        setCustomer={(data) => {
          setCustomer(data);
        }}
      />
      <Button
        onClick={() => {
          onSearch();
        }}
      >
        search
      </Button>
      <Backdrop className={classes.backdrop} open={loading || stateLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        maxWidth={1200}
      >
        <Grid item sm={9}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {appointmentList.map((item) => {
              return (
                <Grid item xs={12}>
                  <Paper className={classes[statusArr[item.status]]}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            {" "}
                            <div>{`${customer.name}`}</div>
                          </Grid>
                          <Grid item>
                            {moment
                              .unix(item.dateTime)
                              .format("MMMM Do, h:mm a")}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={5}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>{`${item.support.name}`}</Grid>

                          <Grid item>
                            {item.status === "pending" && (
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  let txt =
                                    `${
                                      item.customer?.first_name
                                        ? item.customer?.first_name
                                        : "Name"
                                    } ${
                                      item.customer?.last_name
                                        ? item.customer?.last_name
                                        : "Surname"
                                    } \n` +
                                    moment(
                                      new Date(item.dateTime * 1000)
                                    ).format("dddd, MMMM Do YYYY, h:mm a") +
                                    `-` +
                                    moment(
                                      new Date((item.dateTime + 1800) * 1000)
                                    ).format("h:mm a");
                                  handleClickOpenDialog2(txt, item);
                                  // onApproveAppointment(item);
                                  // For Edit Purpose
                                  // handleClickOpen();
                                  // setSelectedAppointment(item);
                                }}
                              >
                                <CheckCircleIcon />
                              </IconButton>
                            )}
                            {item.status === "reserved" && (
                              <EditDialog
                                rawText={item.comment}
                                onSave={(text) => {
                                  onSaveNotes(item, text);
                                }}
                              ></EditDialog>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to reserve this pending appointment?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog2} color="primary">
            close
          </Button>
          <Button onClick={onCancelAppointment} color="primary">
            Decline
          </Button>
          <Button onClick={onApproveAppointment} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open && Object.keys(selectedAppointment).length !== 0}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {selectedAppointment?.customer?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                {moment(new Date(selectedAppointment.dateTime * 1000)).format(
                  "LT"
                )}
              </Grid>
              <Grid item>
                {!supportLoading && (
                  <CustomizedMenus
                    members={supportMemberData.supportMembers}
                    selectedSupportMember={selectedSupportMember}
                    setSelectedSupportMember={setSelectedSupportMember}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              onApproveAppointment();
            }}
            color="primary"
            autoFocus
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConsultationSearch;
