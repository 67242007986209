import TextField from "@mui/material/TextField";
import React from "react";

const TutorialTitle = ({
  title,
  setTitle,
  disabled = false,
  placeHolder = "",
}) => {
  return (
    <TextField
      fullWidth
      value={title}
      disabled={disabled}
      label={placeHolder}
      onChange={(e) => setTitle(e.target.value)}
      // helperText={placeHolder}
      style={{
        marginBottom: 10,
      }}
    />
  );
};

export default TutorialTitle;
