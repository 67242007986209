import { useQuery } from "@apollo/client";
import { InputAdornment, MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { GET_KEYWORDS } from "../../../../queries";
import KeywordComponent, {
  KeywordComponentInfoDialog,
} from "../Components/KeywordComponent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
let URL = "https://feedreal.com/glossary/";

const KeywordEditor = ({ onChangeNodeContent, rawContent }) => {
  const { data, loading, error } = useQuery(GET_KEYWORDS);
  const [keyword, setKeyword] = useState("");
  const [link, setLink] = useState("");
  const [color, setColor] = useState("e25065");

  useEffect(() => {
    if (rawContent) {
      let obj = JSON.parse(rawContent);
      setKeyword(obj.keyword);
      setLink(obj.link);
      setColor(obj.color);
    }
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      let obj = {
        keyword,
        link,
        color,
      };
      onChangeNodeContent(JSON.stringify(obj));
    }, 700);
    return () => clearTimeout(timer);
  }, [keyword, link, color]);

  return (
    <div
      style={{
        position: "relative",
        border: "1px solid #ffffff30",
        padding: 10,
        borderRadius: 5,
      }}
    >
      <Typography>Keyword Editor</Typography>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <KeywordComponentInfoDialog />
      </div>
      <br></br>
      {/* <KeywordComponent
        color={color}
        text={keyword}
        link={link}
      ></KeywordComponent>
      <br></br> */}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={8}>
          {data && data.getKeywords && (
            <TextField
              fullWidth
              id="outlined-select-currency"
              select
              label="Select Keyword"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                setLink(
                  URL +
                    data.getKeywords.find(
                      (item) => item.word === e.target.value
                    ).sefurl
                );
              }}
            >
              {data.getKeywords.map((option) => (
                <MenuItem key={option.sefurl} value={option.word}>
                  {option.word}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid item xs={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              ),
            }}
            fullWidth
            label="Color (#FFFFFF)"
            variant="outlined"
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
            disabled
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default KeywordEditor;
