import { gql } from "@apollo/client";

const GET_AFFILIATE = gql`
  query ($offset: Int, $limit: Int, $order: String) {
    affiliates(offset: $offset, limit: $limit, order: $order) {
      id
      name
      email
      phone_number
      username
      affiliateType
      stripe {
        id
        subscription {
          id
        }
      }
    }
    affiliatesCount
  }
`;

const GET_SINGLE_AFFILIATE = gql`
  query ($id: ID!) {
    getMongoCustomerByID(id: $id) {
      id
      name
      email
      phone_number
      username
      affiliateType
      affiliatePoint
      affiliated
      isAffiliate
      created_at
      wallet {
        coins
      }
    }
    getLinkAdmin(owner: $id) {
      id
    }
  }
`;

const AFFILIATE_FIND = gql`
  query ($email: String, $phone_number: String) {
    affiliatesFind(email: $email, phone_number: $phone_number) {
      id
      name
      email
      phone_number
      username
      affiliateType
    }
  }
`;

const MAKE_AFFILIATE = gql`
  mutation MakeAffiliate($id: ID) {
    makeAffiliate(id: $id)
  }
`;

const UNMAKE_AFFILIATE = gql`
  mutation UnmakeAffiliate($id: ID) {
    unmakeAffiliate(id: $id)
  }
`;

const UPDATE_AFFILIATE_POINT = gql`
  mutation UpdateAffiliatePoint($id: ID, $point: Int) {
    updateAffiliatePoint(id: $id, point: $point)
  }
`;

const CREATE_AFFILIATE_WITH_TITLE = gql`
  mutation createAff($input: inputAffiliatePage) {
    createAffiliatePage(input: $input) {
      username
      created_at
      affiliatePoint
      affiliateType
      isAffiliate
    }
  }
`;

const GET_WNINE_AFFILIATE = gql`
  {
    waitingVerifyw9 {
      id
      name
      phone_number
      email
      w9Link {
        file
        verify
        message
      }
    }
  }
`;

const UPDATE_WNINE_AFFILIATE = gql`
  mutation UpdateVerifyW9($id: ID!, $status: Int, $message: String) {
    updateVerifyW9(id: $id, status: $status, message: $message)
  }
`;

const GET_AFFILIATE_REQ_PENDING = gql`
  query getAffiliateReqs {
    getAffiliateFormReqPending {
      id
      first_name
      last_name
      email
      phone_number
      status
      createdAt
      social {
        website
        social_media
      }
      questions {
        q
        a
      }
    }
  }
`;

const AFFILIATE_STATUS_UPDATE = gql`
  mutation ChangeStatus($id: ID!, $status: String!) {
    changeStatusAffiliateFormReq(id: $id, status: $status) {
      id
      status
    }
  }
`;

export {
  GET_AFFILIATE,
  GET_SINGLE_AFFILIATE,
  AFFILIATE_FIND,
  MAKE_AFFILIATE,
  UNMAKE_AFFILIATE,
  UPDATE_AFFILIATE_POINT,
  CREATE_AFFILIATE_WITH_TITLE,
  GET_WNINE_AFFILIATE,
  UPDATE_WNINE_AFFILIATE,
  GET_AFFILIATE_REQ_PENDING,
  AFFILIATE_STATUS_UPDATE,
};
