import { Button, Typography } from "@mui/material";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { createRandKey } from "../../../../globals";
import Resizer from "react-image-file-resizer";
import { SINGLE_UPLOAD } from "../../../../queries";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { startSingleUpload } from "../../../Funcs/Upload";
import TutorialTitle from "../../Course/components/TutorialTitle";

const compressImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1920, // maxWidth
      1080, // maxHeight
      "PNG", //compressFormat
      100, //quality
      0, // rotation
      (uri) => {
        // responseUriFunc
        resolve(uri);
      },
      "blob" // outputType
    );
  });

const TextContentEditor = ({
  onChangeNodeContent,
  rawContent,
  autoSave = true,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const [title, setTitle] = useState("");
  const [singleUpload, { loading, error }] = useMutation(SINGLE_UPLOAD);
  const uploadFile = async (file) => {
    console.log("file before compress", file);
    let img;
    if (file.type === "image/gif") {
      img = file;
    } else {
      img = await compressImage(file);
    }

    console.log("img", img);
    let res = "";
    await startSingleUpload(img, singleUpload, async (result) => {
      console.log("result single", result);
      res = result;
    });

    console.log("single upload res=>", res);
    return { data: { link: res } };
  };

  useEffect(() => {
    if (rawContent) {
      const content = JSON.parse(rawContent);
      const nextState = convertFromRaw(content);
      setEditorState(EditorState.createWithContent(nextState));
    }
  }, []);

  useEffect(() => {
    const editorContains = convertToRaw(editorState.getCurrentContent());
    let timer = setTimeout(() => {
      if (autoSave) {
        onChangeNodeContent(JSON.stringify(editorContains));
      }
    }, 350);

    return () => clearTimeout(timer);
  }, [editorState]);

  const onSaveManual = () => {
    const editorContains = convertToRaw(editorState.getCurrentContent());
    console.log(editorContains);
    onChangeNodeContent(JSON.stringify({ ...editorContains, title }));
  };

  return (
    <div
      style={{
        border: "1px solid #ffffff30",
        padding: 10,
        borderRadius: 5,
      }}
    >
      <Typography>Text Editor</Typography>
      {!autoSave && <TutorialTitle title={title} setTitle={setTitle} />}
      <Editor
        stripPastedStyles={true}
        // key={createRandKey()}
        editorStyle={{
          height: "30vh",
          width: "100%",
          overflowX: "hidden",
        }}
        initialContentState={
          rawContent
            ? JSON.parse(rawContent)
            : {
                entityMap: {},
                blocks: [],
              }
        }
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            // className: undefined,
            // component: undefined,
            // popupClassName: undefined,
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: async (e) => {
              const result = await uploadFile(e);
              console.log("uploadCallback=>", result);
              return result;
            },
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            // defaultSize: {
            //   height: "auto",
            //   width: "auto",
            // },
          },
        }}
      />
      {/*       <Button
        style={{ float: "right", marginTop: -10 }}
        variant={"contained"}
        color={"secondary"}
        onClick={() => {
          onSaveManual();
        }}
      >
        Save Changes
      </Button> */}
    </div>
  );
};
export default TextContentEditor;

export const SimpleEditor = ({ onChangeNodeContent, rawContent }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    console.log("rawContent =>", rawContent);
    if (rawContent) {
      const content = JSON.parse(rawContent);
      const nextState = convertFromRaw(content);
      setEditorState(EditorState.createWithContent(nextState));
    }
  }, []);

  useEffect(() => {
    const editorContains = convertToRaw(editorState.getCurrentContent());
    let timer = setTimeout(() => {
      onChangeNodeContent(JSON.stringify(editorContains));
    }, 700);

    return () => clearTimeout(timer);
  }, [editorState]);

  return (
    <div
      style={{
        border: "1px solid #ffffff30",
        padding: 10,
        borderRadius: 5,
        marginBottom: 10,
      }}
    >
      <Editor
        stripPastedStyles={true}
        // key={createRandKey()}
        editorStyle={{
          width: "100%",
          overflowX: "hidden",
        }}
        toolbar={{
          options: ["link", "inline"],
          inline: {
            options: ["bold", "italic"],
          },
        }}
        initialContentState={
          rawContent
            ? JSON.parse(rawContent)
            : {
                entityMap: {},
                blocks: [],
              }
        }
        // editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
};
