import React, { useEffect, useState, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useHistory } from "react-router-dom";
import _ from "lodash";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";

import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useMutation } from "@apollo/client";
import {
  CREATE_DOG,
  DELETE_CUSTOMER_DOG,
  UPDATE_CUSTOMER_MONGO_DOG,
} from "../../../../../queries";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const INTERVAL = 1000;

export default function CreateNewDog(props) {
  const [open, setOpen] = useState(false);
  const [createDogMutation, { loading, data, error }] = useMutation(CREATE_DOG);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createDog = (data) => {
    createDogMutation({
      variables: {
        input: { ...data, owner: props.ownerID },
      },
    })
      .then(() => {
        enqueueSnackbar(`${data.name} Created`, {
          variant: "success",
        });
        handleClose();
        props.handleRefetch();
      })
      .catch(() => {
        enqueueSnackbar(`${data.name} Could not created`, {
          variant: "error",
        });
        handleClose();
        props.handleRefetch();
      });
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        fullWidth
        className=" btn-text-left btn-text-margin-left"
      >
        Create New Dog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add New Dog"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ height: 20 }}></div>
            <DogComponent createDog={(data) => createDog(data)} />
          </DialogContentText>
        </DialogContent>
        {loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Dialog>
    </div>
  );
}

const DogComponent = ({ createDog, dog = {}, deleteDog, updateDog }) => {
  const [name, setName] = useState("");
  const [birthday, setBirthdate] = useState("");
  const [breed, setBreed] = useState("");
  const [description, setDescription] = useState("");
  const [instagram, setInstagram] = useState("");
  const [size, setSize] = useState("");

  const [nameValid, setNameValid] = useState(true);

  const [breedValid] = useState(true);
  const [descriptionValid] = useState(true);
  const [instagramValid] = useState(true);
  const [sizeValid] = useState(true);

  const [dogsLoading, setDogsLoading] = useState(false);

  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    console.log("dog =====>", dog);
    if (Object.keys(dog).length === 0) {
      initialEmptyDog();
    } else {
      initialDogState();
    }
  }, []);

  const initialEmptyDog = () => {
    setName("");
    setBirthdate(new Date());
    setBreed("");
    setDescription("");
    setInstagram("");
    setSize("");
  };
  const initialDogState = () => {
    console.log("initialDogState");
    setName(dog.name);
    setBirthdate(dog.birthday);
    setBreed(dog.breed);
    setDescription(dog.description);
    setInstagram(dog.instagram);
    setSize(dog.size);
  };

  // Add once click to the existing state value
  const clickOnce = (click) => {
    setClicks(click + 1);
  };
  // eslint-disable-next-line
  const debouncedClick = useCallback(
    _.debounce(
      (clicks) => {
        clickOnce(clicks);
      },
      INTERVAL,
      { leading: true, trailing: false, maxWait: INTERVAL }
    ),
    []
  );

  // eslint-disable-next-line
  const onSubmitForm = () => {
    if (!validateDog()) {
      return;
    }
    const simpleDog = {
      name,
      birthday,
      breed,
      description,
      instagram,
      size,
      restrictions: [],
      shippingId: null,
    };
    createDog(simpleDog);
  };

  useEffect(() => {
    if (clicks !== 0) {
      onSubmitForm();
    }
    // eslint-disable-next-line
  }, [clicks]);

  const validateDog = () => {
    let flag = true;

    if (name.length === 0) {
      setNameValid(false);
      flag = false;
    } else {
      setNameValid(true);
    }

    // if (breed.length === 0) {
    //   setBreedValid(false);
    //   flag = false;
    // } else {
    //   setBreedValid(true);
    // }
    // if (description.length === 0) {
    //   setDescriptionValid(false);
    //   flag = false;
    // } else {
    //   setDescriptionValid(true);
    // }
    // if (instagram.length === 0) {
    //   setInstagramValid(false);
    //   flag = false;
    // } else {
    //   setInstagramValid(true);
    // }
    // if (size.length === 0) {
    //   setSizeValid(false);
    //   flag = false;
    // } else {
    //   setSizeValid(true);
    // }

    return flag;
  };

  return (
    <div>
      <div className={"margin-t-b"}>
        {/* {!newDog && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <CardHeader className="margin-0-text">
              {newDog ? "Create" : "Update"} Dog
            </CardHeader>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                cancel();
              }}
            >
              Cancel
            </Button>
          </Grid>
        )} */}
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item md={6} xs={12}>
          <TextField
            disabled={dogsLoading}
            fullWidth
            error={!nameValid}
            variant={"outlined"}
            placeholder={"Name"}
            label={"Name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          {/* =================== */}
          {/* <DesktopDatePicker
            fullWidth
            label={"Birthday"}
            value={birthday}
            onChange={setBirthdate}
            inputVariant="outlined"
          /> */}
          <DesktopDatePicker
            disabled={dogsLoading}
            placeholder={"YYYY-MM-DD"}
            maxDate={new Date()}
            label="Birthday"
            inputFormat={"yyyy-MM-dd"}
            value={birthday}
            onChange={setBirthdate}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
          {/* <TextField
            fullWidth
            error={!birthdayValid}
            variant={"outlined"}
            placeholder={"birthday"}
            label={"birthday"}
            value={birthday}
            onChange={(e) => setBirthdate(e.target.value)}
          /> */}
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            disabled={dogsLoading}
            fullWidth
            error={!breedValid}
            variant={"outlined"}
            placeholder={"breed"}
            label={"breed"}
            value={breed}
            onChange={(e) => setBreed(e.target.value)}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            disabled={dogsLoading}
            fullWidth
            error={!descriptionValid}
            variant={"outlined"}
            placeholder={"description"}
            label={"description"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            disabled={dogsLoading}
            fullWidth
            error={!instagramValid}
            variant={"outlined"}
            placeholder={"instagram"}
            label={"instagram"}
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            disabled={dogsLoading}
            fullWidth
            error={!sizeValid}
            variant={"outlined"}
            placeholder={"size"}
            label={"size"}
            value={size}
            onChange={(e) => {
              setSize(e.target.value);
            }}
          />
        </Grid>
        {/* <Grid item md={12} xs={12}>
          {restrictions.map((item) => {
            return (
              <Chip
                variant="outlined"
                label={item}
                className={classes.margin}
                onDelete={() => {
                  onDeleteAllergen(item);
                }}
                color="primary"
              />
            );
          })}
        </Grid> */}
        {/* <Grid item md={12} xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {Object.keys(restrictionObj).map((item) => {
              return (
                <Grid item xs={2}>
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      color={restrictionObj[item] ? "secondary" : "primary"}
                      aria-label="add"
                      onClick={() => {
                        let copy = JSON.parse(JSON.stringify(restrictionObj));
                        copy[item] = !copy[item];
                        setRestrictionObj(copy);
                      }}
                    >
                      <RestrictionIcons name={item} />
                    </Fab>
                    <p style={{ marginTop: 0 }}>{item}</p>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid> */}
        {Object.keys(dog).length === 0 ? (
          <Grid item md={6} xs={12}>
            <Button
              fullWidth
              variant={"outlined"}
              color={"secondary"}
              clicks={clicks}
              onClick={() => {
                debouncedClick(clicks);
              }}
            >
              ADD
            </Button>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={6}>
                <Confirm
                  onConfirm={() => {
                    deleteDog();
                  }}
                  title={"delete"}
                  color={"red"}
                  dogName={dog.name}
                />
              </Grid>
              <Grid item xs={6}>
                <Confirm
                  onConfirm={() => {
                    const simpleDog = {
                      name,
                      birthday,
                      breed,
                      description,
                      instagram,
                      size,
                    };
                    updateDog(simpleDog);
                  }}
                  title={"update"}
                  color={"secondary"}
                  dogName={dog.name}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export const UpdateDog = (props) => {
  const [open, setOpen] = useState(false);
  const [createDogMutation, { loading, data, error }] = useMutation(CREATE_DOG);
  const [
    updateDogMutation,
    { loading: updateDogLoading, data: updateDogData },
  ] = useMutation(UPDATE_CUSTOMER_MONGO_DOG);
  const [
    deleteDogMutation,
    { loading: deleteLoading, data: deleteData, error: deleteError },
  ] = useMutation(DELETE_CUSTOMER_DOG);

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createDog = (data) => {
    createDogMutation({
      variables: {
        input: { ...data, owner: props.ownerID },
      },
    })
      .then(() => {
        enqueueSnackbar(`${data.name} Created`, {
          variant: "success",
        });
        handleClose();
        props.handleRefetch();
      })
      .catch(() => {
        enqueueSnackbar(`${data.name} Could not created`, {
          variant: "error",
        });
        handleClose();
        props.handleRefetch();
      });
  };
  const updateDog = (data) => {
    updateDogMutation({
      variables: {
        id: props.dog.id,
        input: data,
      },
    })
      .then(() => {
        enqueueSnackbar(`${props.dog.name} updated successfully`, {
          variant: "success",
        });
        handleClose();
        props.handleRefetch();
      })
      .catch(() => {
        enqueueSnackbar(`${props.dog.name} could not updated`, {
          variant: "error",
        });
        handleClose();
        props.handleRefetch();
      });
  };

  const deleteDog = () => {
    if (props.dog.subscriptions) {
      enqueueSnackbar(`${props.dog.name} has subscription`, {
        variant: "error",
      });
      return;
    }
    deleteDogMutation({
      variables: {
        dogId: props.dog.id,
      },
    })
      .then(() => {
        enqueueSnackbar(`${props.dog.name} deleted`, {
          variant: "success",
        });
        handleClose();
        props.handleRefetch();
      })
      .catch(() => {
        enqueueSnackbar(`${props.dog.name} Could not deleted`, {
          variant: "error",
        });
        handleClose();
        props.handleRefetch();
      });
  };

  return (
    <div>
      <IconButton
        onClick={() => {
          handleClickOpen();
        }}
        edge="end"
      >
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Update ${props.dog.name}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ height: 20 }}></div>
            <DogComponent
              createDog={(data) => createDog(data)}
              dog={props.dog}
              deleteDog={() => {
                deleteDog();
              }}
              updateDog={(data) => {
                updateDog(data);
              }}
            />
          </DialogContentText>
        </DialogContent>
        {(loading || deleteLoading || updateDogLoading) && (
          <div>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={open}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Dialog>
    </div>
  );
};

const Confirm = ({ onConfirm, title, color, dogName }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        onClick={handleClickOpen}
        sx={{ color: color }}
      >
        {title}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dogName}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {title} {dogName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button
            onClick={() => {
              onConfirm();
              handleClose();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
