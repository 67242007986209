import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UploadFile } from "../../../Tools/Upload";
import IngredientsComponent, {
  IngredientComponentInfoDialog,
} from "../Components/IngredientsComponent";
import { SimpleEditor } from "./textContentEditor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { createRandKey } from "../../../../globals";
const IngredientsCardEditor = ({ title, onChangeNodeContent, rawContent }) => {
  const [img, setImg] = useState("");
  const [smallDetetail, setSmallDetetail] = useState("");
  const [analysisTitle, setAnalysisTitle] = useState("");
  const [analysisArray, setAnalysisArray] = useState([]);
  const [detailNodes, setDetailNodes] = useState([]);
  const uploadPhoto = (file) => {
    setImg(file.uri);
  };
  const resetFeaturedPhoto = () => {
    setImg("");
  };
  const addAnalysistoArray = () => {
    let copy = JSON.parse(JSON.stringify(analysisArray));
    copy.push("");
    setAnalysisArray(copy);
  };
  const onDeleteRation = (index) => {
    let copy = JSON.parse(JSON.stringify(analysisArray));
    copy.splice(index, 1);
    setAnalysisArray(copy);
  };
  const addDetailNode = () => {
    let copy = JSON.parse(JSON.stringify(detailNodes));
    copy.push({
      icon: "",
      text: "",
    });
    setDetailNodes(copy);
  };
  const addThreeDetailNode = () => {
    let copy = JSON.parse(JSON.stringify(detailNodes));
    copy.push({
      icon: "https://realdog-s3.s3-us-west-1.amazonaws.com/iconscard/back1.png",
      text: "",
    });
    copy.push({
      icon: "https://realdog-s3.s3-us-west-1.amazonaws.com/iconscard/back2.png",
      text: "",
    });
    copy.push({
      icon: "https://realdog-s3.s3-us-west-1.amazonaws.com/iconscard/back3.png",
      text: "",
    });
    setDetailNodes(copy);
  };
  const setSingleAnalysis = (data, index) => {
    let copy = JSON.parse(JSON.stringify(analysisArray));
    copy[index] = data;
    setAnalysisArray(copy);
  };

  useEffect(() => {
    if (rawContent) {
      const nodeContent = JSON.parse(rawContent);
      setImg(nodeContent.img);
      setSmallDetetail(nodeContent.smallDetetail);
      setAnalysisTitle(nodeContent.analysisTitle);
      setAnalysisArray(nodeContent.analysisArray);
      setDetailNodes(nodeContent.detailNodes);
    } else {
      addThreeDetailNode();
    }
  }, []);

  useEffect(() => {
    const nodeContent = {
      img,
      smallDetetail,
      analysisTitle,
      analysisArray,
      detailNodes,
    };
    let timer = setTimeout(() => {
      onChangeNodeContent(JSON.stringify(nodeContent));
    }, 700);
    return () => clearTimeout(timer);
  }, [img, smallDetetail, analysisTitle, analysisArray, detailNodes]);

  const setDetailNodeText = (data, index) => {
    let copy = JSON.parse(JSON.stringify(detailNodes));
    copy[index].text = data;
    setDetailNodes(copy);
  };

  const uploaDetailNodePhoto = (file, index) => {
    let copy = JSON.parse(JSON.stringify(detailNodes));
    copy[index].img = file.uri;
    setDetailNodes(copy);
  };
  const resetDetailNodeFeaturedPhoto = (index) => {
    let copy = JSON.parse(JSON.stringify(detailNodes));
    copy[index].img = "";
    setDetailNodes(copy);
  };

  return (
    <div
      style={{
        position: "relative",
        border: "1px solid #ffffff30",
        padding: 10,
        borderRadius: 5,
      }}
    >
      {/* <IngredientsComponent
        title={title}
        img={img}
        smallDetetail={smallDetetail}
        analysisArray={analysisArray}
        analysisTitle={analysisTitle}
        detailNodes={detailNodes}
      />
      <br></br>
      <br></br>
      <Divider></Divider> 
      <br></br>
      <br></br>*/}
      <Typography>Keyword Editor</Typography>
      <br></br>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <IngredientComponentInfoDialog />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={5} className="every-child-margin ">
          {!img ? (
            <UploadFile
              idKey={"ingredient-img-uplad-image-key-" + createRandKey(6)}
              uploadPhoto={uploadPhoto}
              buttonName={"Add Featured Image"}
            />
          ) : (
            <div className="featuredImgBlock">
              <div className="deletephotobtn">
                <IconButton
                  onClick={resetFeaturedPhoto}
                  color="secondary"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <img width={"100%"} src={img} />
            </div>
          )}
          <TextField
            label={"Small Detail"}
            fullWidth
            value={smallDetetail}
            onChange={(e) => setSmallDetetail(e.target.value)}
          />
          <TextField
            label={"ANALYSIS Title"}
            fullWidth
            value={analysisTitle}
            onChange={(e) => setAnalysisTitle(e.target.value)}
          />
          {analysisArray.map((singleAnalysis, index) => {
            return (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      style={{
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => {
                        onDeleteRation(index);
                      }}
                      position="start"
                    >
                      <DeleteForeverIcon />
                    </InputAdornment>
                  ),
                }}
                label={"Single Analysis"}
                fullWidth
                value={singleAnalysis}
                onChange={(e) => setSingleAnalysis(e.target.value, index)}
              />
            );
          })}
          <Button fullWidth variant="outlined" onClick={addAnalysistoArray}>
            Add ANALYSIS
          </Button>
        </Grid>
        <Grid item xs={7}>
          {detailNodes.map((singleDetailNode, index) => {
            return (
              <>
                {!singleDetailNode.icon ? (
                  <UploadFile
                    idKey={"ingredient-img-uplad-image-key-" + createRandKey(6)}
                    uploadPhoto={(file) => uploaDetailNodePhoto(file, index)}
                    buttonName={"Add Featured Image"}
                  />
                ) : (
                  <div className="featuredImgBlock">
                    {/* <div className="deletephotobtn">
                      <IconButton
                        onClick={() => resetDetailNodeFeaturedPhoto(index)}
                        color="secondary"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div> */}
                    <img
                      src={singleDetailNode.icon}
                      style={{ maxWidth: "50px" }}
                    />
                  </div>
                )}
                <SimpleEditor
                  onChangeNodeContent={(text) => setDetailNodeText(text, index)}
                  rawContent={singleDetailNode.text}
                />
              </>
            );
          })}
          {/* <Button fullWidth onClick={addDetailNode}>
            Add More Detail
          </Button> */}
        </Grid>
      </Grid>
    </div>
  );
};
export default IngredientsCardEditor;
