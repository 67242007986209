import React from "react";
import ReactDOM from "react-dom";
import {
  relayStylePagination,
  offsetLimitPagination,
  getMainDefinition,
} from "@apollo/client/utilities";
import "./light.scss";
import App from "./components/App";
import { ApolloProvider, split } from "@apollo/client";
import { ApolloClient } from "@apollo/client/core";
import { ApolloLink } from "apollo-link";
import { WebSocketLink } from "@apollo/client/link/ws";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "apollo-link-error";
import { SnackbarProvider } from "notistack";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
require("dotenv").config();

const errorLink = onError(
  ({ networkError, graphQLErrors, response, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: localStorage.getItem("token") || null,
      "Access-Control-Allow-Credentials": true,
    },
  });
  return forward(operation).map((data) => {
    //Check if token has expired and refresh page
    if (data.hasOwnProperty("errors")) {
      data.errors.map(({ extensions }) => {
        if (extensions && extensions.code === "UNAUTHENTICATED") {
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
    }
    return data;
  });
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SUBSCRIPTION,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: localStorage.getItem("token"),
    },
  },
});

const httpLink = middlewareLink.concat(
  createUploadLink({
    uri: process.env.REACT_APP_HTTP_URI,
    credentials: "include",
  })
);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
  errorLink
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          customers: offsetLimitPagination(),
          inv_processs_all: offsetLimitPagination(),
          products: offsetLimitPagination(),
          affiliates: offsetLimitPagination(),
          cancelFilter: offsetLimitPagination(),
          membershipList: offsetLimitPagination(),
          getPagesAdmin: offsetLimitPagination(),
          pagesCount: {
            read(existing) {
              return existing;
            },
            merge(existing, incoming) {
              return incoming;
            },
          },
          getQuestionsAdmin: offsetLimitPagination(),
          getFinalQuizAttendances: offsetLimitPagination(),
          logs: offsetLimitPagination(),
          smslogs: offsetLimitPagination(),
          getGiftCoupons: offsetLimitPagination(),
          getCougetCourseTransactions: offsetLimitPagination(),
          getAllFinalQuizAttendances: offsetLimitPagination(),
          getWorkshopList: offsetLimitPagination(),
          landingLogsAdmin: offsetLimitPagination(),
        },
      },
    },
  }),
});

ReactDOM.render(
  <React.Fragment>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ApolloProvider client={client}>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </ApolloProvider>
    </LocalizationProvider>
  </React.Fragment>,
  document.getElementById("root")
);
