// "use strict";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  // Editor,
  EditorState,
  RichUtils,
  EditorBlock,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { Button, Grid, TextField, Container, Typography } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import Resizer from "react-image-file-resizer";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SINGLE_UPLOAD } from "../../../../queries";
import { startSingleUpload } from "../../../Funcs/Upload";
import { UploadFile } from "../../../Tools/Upload";
import ReactPlayer from "react-player/youtube";

const compressImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      700, // maxWidth
      1920, // maxHeight
      "JPEG", //compressFormat
      80, //quality
      0, // rotation
      (uri) => {
        // responseUriFunc
        resolve(uri);
      },
      "blob" // outputType
    );
  });

function RichEditorExample(props) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [published, setPublished] = useState(false);
  const [savedText, setSavedText] = useState(false);
  const editorRef = useRef();

  // query blog with id
  // const { loading, error, data } = useQuery(GET_BLOG, {
  //   variables: { id: postID },
  // });

  const [singleUpload, { loading, error }] = useMutation(SINGLE_UPLOAD);

  useEffect(() => {
    if (props && props.content !== "") {
      const localContent = JSON.parse(props.content);
      console.log("blocks=>", localContent);
      setPublished(props.content);
      setEditorState(
        EditorState.createWithContent(convertFromRaw(localContent))
      );
    }
  }, [props.content]);

  const onChange = (editorState) => {
    const save = Math.floor(Math.random() * 11) < 1;
    setSavedText(save);
    // console.log(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState);
    props.onChangeEditor(convertToRaw(editorState.getCurrentContent()));
    focus();
  };

  const uploadFile = async (file) => {
    console.log("file before compress", file);
    let img;
    if (file.type === "image/gif") {
      img = file;
    } else {
      img = await compressImage(file);
    }

    console.log("img", img);
    let res = "";
    await startSingleUpload(img, singleUpload, async (result) => {
      console.log("result single", result);
      res = result;
    });

    console.log("single upload res=>", res);
    return { data: { link: res } };
  };

  const _handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };
  const _onTab = (e) => {
    const maxDepth = 4;
    onChange(RichUtils.onTab(e, editorState, maxDepth));
  };

  const handleUpload = async (imageFile) => {
    let file = imageFile;
    const compressedFile = await compressImage(file);
    console.log(compressedFile);
    return;
    singleUpload({
      variables: { file: compressedFile },
    }).then(async ({ data }) => {
      console.log("image_link", data.singleUpload.uri);
      return data.singleUpload.uri;
    });

    // Split the filename to get the name and type
    // let fileParts = imageFile.name.split(".");
    // let fileName = fileParts[0];
    // // let fileType = fileParts[1];
    // let fileType = compressedFile.type.split("").pop();

    // return await axios
    //   .post("http://localhost:3001/upload", {
    //     fileName: fileName,
    //     fileType: fileType,
    //   })
    //   .then((response) => {
    //     var returnData = response.data.data.returnData;
    //     var signedRequest = returnData.signedRequest;
    //     var url = returnData.url;
    //     console.log("response", response);

    //     // Put the fileType in the headers for the upload
    //     var options = {
    //       headers: {
    //         "Content-Type": fileType,
    //       },
    //     };
    //     return axios
    //       .put(signedRequest, compressedFile, options)
    //       .then((result) => {
    //         return {
    //           data: {
    //             link: url,
    //           },
    //         };
    //       })
    //       .catch((error) => {
    //         alert("ERROR " + JSON.stringify(error));
    //       });
    //   })
    //   .catch((error) => {
    //     alert(JSON.stringify(error));
    //   });
  };

  const handleKeyCommand = (command) => _handleKeyCommand(command);
  const onTab = (e) => _onTab(e);
  const focus = () => {};
  let className = "RichEditor-editor";
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== "unstyled") {
      className += " RichEditor-hidePlaceholder";
    }
  }

  const blockRenderer = (block) => {
    if (block.getType() === "image") {
      const key = block.getKey();
      let localBlocks = JSON.parse(props.content).blocks;
      let currentBlock = localBlocks.find((item) => item.key === key);
      const url = localBlocks.find((item) => item.key === key).data.url;
      return {
        component: ImageRenderer,
        editable: true,
        props: {
          src: url,
          width: currentBlock.data.aspect_ratio.width,
          height: currentBlock.data.aspect_ratio.height,
          zoom: currentBlock.data.aspect_ratio.ratio,
        },
      };
    }
    if (block.getType() === "video") {
      const key = block.getKey();
      let localBlocks = JSON.parse(props.content).blocks;
      let currentBlock = localBlocks.find((item) => item.key === key);
      const url = currentBlock.text;
      return {
        component: VideoRenderer,
        editable: true,
        props: {
          src: url,
        },
      };
    }

    // const { editorState } = this.state;
    // if (block.getType() === "atomic") {
    //   const contentState = editorState.getCurrentContent();
    //   const entityKey = block.getEntityAt(0);

    //   const entity = contentState.getEntity(entityKey);
    //   if (entity && entity.type === "HORIZONTAL_RULE") {
    //     return {
    //       component: DividerBlock,
    //       editable: false,
    //     };
    //   }
    // }
    return undefined;
  };

  // if (loading) return <h1>Loading</h1>;

  return (
    <Container>
      <Grid container direction="row">
        <div className="RichEditor-root">
          <div className={className} onClick={focus}>
            <Editor
              // readOnly={true}
              stripPastedStyles={true}
              editorStyle={{
                height: "66vh",
                width: "100%",
                overflowX: "hidden",
              }}
              customBlockRenderFunc={blockRenderer}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              // blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={handleKeyCommand}
              onEditorStateChange={onChange}
              onTab={onTab}
              placeholder="Tell a story..."
              ref={editorRef}
              spellCheck={true}
              // toolbarHidden={true}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "list",
                  "textAlign",
                  "link",
                  "embedded",
                  "image",
                ],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                blockType: {
                  inDropdown: false,
                  options: ["H1", "H2", "H3", "Blockquote", "Code"],
                },
                embedded: {
                  embedCallback: embedVideoCallBack,
                },
                image: {
                  // className: undefined,
                  // component: undefined,
                  // popupClassName: undefined,
                  urlEnabled: false,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: async (e) => {
                    const result = await uploadFile(e);
                    console.log("uploadCallback=>", result);
                    return result;
                  },
                  previewImage: true,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  alt: { present: false, mandatory: false },
                  // defaultSize: {
                  //   height: "auto",
                  //   width: "auto",
                  // },
                },
              }}
            />
          </div>
        </div>
      </Grid>
    </Container>
  );
}
// }

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}
const ImageRenderer = (props) => {
  return (
    <img
      src={props.blockProps.src}
      style={{
        width: "100%",
      }}
    ></img>
  );
};
const VideoRenderer = (props) => {
  return (
    <ReactPlayer
      width="100%"
      url={props.blockProps.src}
      className="indexyoutube"
    />
  );
};

const embedVideoCallBack = (link) => {
  if (link.indexOf("youtu") >= 0) {
    link = link.replace("watch?v=", "embed/");
    link = link.replace("/watch/", "/embed/");
    link = link.replace("youtu.be/", "youtube.com/embed/");
  }
  return link;
};

export default RichEditorExample;
