import { gql } from "@apollo/client";

const SINGLE_UPLOAD = gql`
  mutation ($file: Upload) {
    singleUpload(file: $file) {
      uri
    }
  }
`;

const MULTIPE_UPLOAD = gql`
  mutation ($file: Upload) {
    multipleUpload(file: $file) {
      files
    }
  }
`;

const REQUEST_IMG = gql`
  mutation uploadTest($fileName: String, $fileType: String) {
    getSignedRequest(input: { fileName: $fileName, fileType: $fileType }) {
      signedRequest
      url
    }
  }
`;

export { SINGLE_UPLOAD, MULTIPE_UPLOAD, REQUEST_IMG };
