import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import MuiPhoneNumber from "material-ui-phone-number";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import {
  ADDMEMBERSHIP,
  CALCULATESHIPPING,
  CANCELMEMBERSHIP,
  CANCELSUBSCRIPTION,
  CREATE_USE_POINTS,
  DONTCANCELSUBSCRIPTION,
  GETACTIVEDOG,
  GETDOGS,
  GET_CHECKOUT_PLANS,
  GET_CUSTOMER,
  GET_RATE_ADMIN,
  MAKE_AFFILIATE,
  UNMAKE_AFFILIATE,
  UPDATE_AFFILIATE_POINT,
  UPDATE_CUSTOMER_USERNAME,
  UPDATE_EMAIL_OR_PHONE,
  UPDATE_QUANTITY,
} from "../../../queries";
import WalletLogList from "../WalletLogs";
import ShippingAddress from "./Components/shippingAddresses";
import ShippingCostsOfDog from "./Components/ShippingCostsOfDog";

import InfoIcon from "@mui/icons-material/Info";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import { Link } from "react-router-dom";
import SearchCustomerInput from "./Components/addReferrer";
import CommunicationSwitch from "./Components/communicationSwitch";
import CreateNewDog, { UpdateDog } from "./Components/CreateNewDog";
import CustomerNotes from "./Components/customerNotes";
import AppointmentNotes from "./Components/customerNotes/AppointmentNotes";
import SendFeedback from "./Components/feedback";
import OneTimeOrderDialog from "./Components/OneTimeOrder";
import RefLinks from "./Components/refLinks";
import ShippingDetails from "./Components/shippingDetails";
import STMOfCustomer from "./Components/STMOfCustomer";
import StripeBalance from "./Components/StripeBalance";
import SubscriptionListItem from "./Components/SubscriptionListItem";
import UpdateSubProducts from "./Components/UpdateBox";
import RestartSubscription from "./RestartSubscription";

import Switch from "@mui/material/Switch";
import cancelReasons from "../../../globals/reasons";
import LinearLoading from "../LinearLoading";
import RDCTransfer from "./Components/RDCTransfer";
import MembershipOperations from "./MembershipsDialog";
import CustomersAssistantSteps from "./CustomersAssistantSteps";
import { CUSTOMER_TYPES } from "../../../globals";
import GiveCourseAway from "./Components/giveCourseAway";
import Workshops from "./Components/Workshop";
import { formatFloat } from "../../../globals";
import AssignConsultation from "./Components/AssignConsultation";
import CustomerCourses from "./Components/customerCourses";
import CachedIcon from "@mui/icons-material/Cached";
import BanMember from "./Components/banMember";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  backdropShippingCalc: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000",
    position: "absolute",
  },
}));

function CustomerDetail(props) {
  let { session } = props;
  const { permission } = session.activeAdmin;
  const god = permission.god ? permission.god.admin : false;

  const classes = useStyles();
  // const { id } = props.match.params;
  const { id } = props.match.params;
  const shippingProdId = process.env.REACT_APP_SHIPPING_COST_ID;
  const memberShipId = process.env.REACT_APP_PREMIUM_MEMBERSHIP_ID;
  const { enqueueSnackbar } = useSnackbar();
  const [totalM, setTotalM] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [open, setOpen] = useState(false);
  const [openCancelM, setOpenCancelM] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [opensubsDetail, setOpenSubsDetail] = useState(false);
  const [customerDogs, setCustomerDogs] = useState();
  const [customerTotalDogs, setCustomerTotalDogs] = useState({});
  const [shippingCost, setShippingCost] = useState();
  const [updatedShippingCost, setUpdatedShippingCost] = useState();
  const [items, setItems] = useState();
  const [subscription, setSubscription] = useState();
  const [openAddBoxDiag, setOpenAddBoxDiag] = useState(false);
  const [plans, setPlans] = useState();
  const [plansToAdd, setPlansToAdd] = useState([]);
  const [confrimCancelSub, setConfirmCancelSub] = useState(false);
  const [openMakeAffiliate, setOpenMakeAffiliate] = useState(false);
  const [openAffiliatePoint, setOpenAffiliatePoint] = useState(false);
  const [openRegular, setOpenRegular] = useState(false);
  const [openWalletLog, setOpenWalletLogs] = useState(false);
  const [affiliatePoint, setAffiliatePoint] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER, {
    variables: { id },
  });
  const [fetchAgain, setFechAgain] = useState(0);

  const [userNameInput, setUserNameInput] = useState("");
  const [memberEndOf, setMemberEndOf] = useState(false);
  // const newPlans =

  const [updateUserNameMutation] = useMutation(UPDATE_CUSTOMER_USERNAME);

  const [type, setType] = useState("Ref");
  const [points, setPoints] = useState(1);
  const [maxPoints, setMaxPoints] = useState(1);
  const [details, setDetails] = useState("");
  const [createUsedPoints] = useMutation(CREATE_USE_POINTS);

  const [reason, setReason] = useState("");
  const [value, setValue] = useState("");

  const [freeSwitch, setFreeSwitch] = useState(false);
  const [cycleCount, setCycleCount] = useState(0);
  const [cancelAtEndOfCycle, setCancelEndOfCycle] = useState(false);

  const handleSetCycleCount = (value) => {
    setCycleCount(parseInt(value));
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeReason = (event) => {
    setReason(event.target.value);
  };

  const handleOpenWalletLogs = () => {
    setOpenWalletLogs(!openWalletLog);
  };

  // Start Update Phone or Email
  const [updatePhoneOrEmail, { loading: loadingUpdatePhoneOrEmail }] =
    useMutation(UPDATE_EMAIL_OR_PHONE);
  const [openUpdateProfile, setUpdateProfile] = useState(false);
  const [openSureProfile, setOpenSureProfile] = useState(false);
  const [updateType, setUpdateType] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updatePhone, setUpdatePhone] = useState("");
  const [isPremiumYearly, setIsPremiumYearly] = useState(false);

  const handleClickUpdateProfile = (type) => {
    if (data) {
      setUpdateEmail(data.customer.email);
      setUpdatePhone(data.customer.phone_number);
      setUpdateProfile(true);
      setUpdateType(type);
    }
  };

  const handleCloseUpdateProfile = () => {
    setUpdateProfile(false);
    setUpdateType("");
  };

  const handleClickOpenSureProfile = () => {
    setOpenSureProfile(true);
  };

  const handleCloseOpenSureProfile = () => {
    setOpenSureProfile(false);
  };

  const handleUpdatePhoneOrEmail = (event) => {
    let newPhone = updatePhone.replaceAll(" ", "");
    newPhone = newPhone.replaceAll("-", "");
    newPhone = newPhone.replaceAll("(", "");
    newPhone = newPhone.replaceAll(")", "");
    updatePhoneOrEmail({
      variables: {
        id: data.customer.id,
        input: {
          ...(updateType === "phone"
            ? { phone_number: newPhone }
            : { email: updateEmail }),
        },
      },
    })
      .then((responseData) => {
        enqueueSnackbar("Updated!", { variant: "success" });
        handleCloseOpenSureProfile();
        handleCloseUpdateProfile();
        refetch();
      })
      .catch((error) => {
        enqueueSnackbar(`Please contact to tech team. Error:${error.message}`, {
          variant: "error",
        });
        handleCloseOpenSureProfile();
        handleCloseUpdateProfile();
        refetch();
      });
  };
  // End Update Phone or Email

  const [
    getChecoutPlans,
    { loading: loadingPlans, data: boxPlans, refetch: planRefetch },
  ] = useLazyQuery(GET_CHECKOUT_PLANS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const checkoutPlans = data.checkoutPlans.map((plan) => {
        const availIndex = plansToAdd.findIndex((p) => p.id === plan.id);
        if (availIndex !== -1) {
          return {
            ...plansToAdd[availIndex],
          };
        } else {
          return {
            ...plan,
            quantity: 0,
          };
        }
      });
      const subItems = items;
      let allowedPlans = [];
      const subItemsPlanIds = subItems.map((item) => item.plan.id);
      allowedPlans = checkoutPlans.filter(
        (plan) => !subItemsPlanIds.includes(plan.id)
      );
      setPlans(allowedPlans);
    },
  });

  const [
    getActiveDog,
    { loading: loadingGetActiveDog, data: dataGetActiveDog },
  ] = useLazyQuery(GETACTIVEDOG, {
    fetchPolicy: "no-cache",
    variables: {
      owner: id,
    },
    onCompleted: (data) => {
      setCustomerDogs(data.getActiveMongoDogsByOwnerID);
    },
  });
  const [
    getDogs,
    { loading: loadingGetDogs, data: dataGetDogs, refetch: refetchUserDogs },
  ] = useLazyQuery(GETDOGS, {
    fetchPolicy: "no-cache",
    variables: {
      owner: id,
    },
    onCompleted: (res) => {
      setCustomerTotalDogs(res.getMongoDogsByOwnerID);
    },
  });

  const [
    calculateShipping,
    { loading: loadingCalculateShipping, data: dataCalculateShipping },
  ] = useLazyQuery(CALCULATESHIPPING, {
    fetchPolicy: "no-cache",
    variables: {
      id: id,
    },
    onCompleted: (data) => {
      setShippingCost(data.getRateForCancel);
    },
  });
  const updateItemQty = (id, qty, fn) => {
    let name = id;
    let value = fn === 0 ? qty - 1 : qty + 1;
    if (value < 0) {
      value = 0;
    }
    let currentItems = [...items];
    const itemIndex = items.findIndex((item) => item.id === name);
    let newItem = { ...items[itemIndex], quantity: value };
    currentItems[itemIndex] = newItem;
    setItems(currentItems);
    setCanUpdate(false);
    setUpdatedShippingCost(null);
  };
  const [
    addMembership,
    { loading: loadingAddMembership, error: loadingAddMembershipError },
  ] = useMutation(ADDMEMBERSHIP);

  const [cancelDogSuscription, { loading: loadingCancelSubscription }] =
    useMutation(CANCELSUBSCRIPTION);
  const [dontCancelSubscription, { loading: loadingDontCancelSubscription }] =
    useMutation(DONTCANCELSUBSCRIPTION);
  const [
    cancelMembership,
    { loading: loadingCancelMembership, error: loadingCancelMembershipError },
  ] = useMutation(CANCELMEMBERSHIP);

  const [getRateAdmin, { loading: loadingGetRate }] =
    useMutation(GET_RATE_ADMIN);
  const [updateSubQuanties, { loading: loadingQuanties }] =
    useMutation(UPDATE_QUANTITY);
  const [makeAAffiliate, { loading: LoadingAffiliate }] =
    useMutation(MAKE_AFFILIATE);
  const [unmakeAffiliate, { loading: LoadingUnmakeAffiliate }] =
    useMutation(UNMAKE_AFFILIATE);
  const [updateAffiliatePoint, { loading: LoadingUpdateAffiliate }] =
    useMutation(UPDATE_AFFILIATE_POINT);

  useEffect(() => {
    let total = 0;
    let totalP = 0;
    const handleRefetchDogs = () => {
      getDogs()
        .then((data) => {
          setCustomerTotalDogs(data.data.getMongoDogsByOwnerID);
        })
        .catch(() => {});
    };
    if (data) {
      setUserNameInput(data.customer.username);
      if (data.customer.stripe) {
        // .filter((elem) => elem.plan.interval !== "year") filters yearly subscription
        data.customer.stripe.subscription.map((x) =>
          x.items
            ? x.items.data
                .filter((elem) => elem.plan.interval !== "year")
                .map((y) => (total += y.plan.amount * y.quantity))
            : 0
        );
        data.customer.stripe.order.map((x) => (totalP += x ? x.amount : 0));
      }

      if (data.customer.isAffiliate) {
        setType("Affiliate");
        setAffiliatePoint(data.customer.affiliatePoint);
      }
      setPoints(data.customer.isAffiliate ? 10 : 1);
    }

    setTotalM(total);
    setTotalPayment(totalP);
    getActiveDog();
    refetchUserDogs();
  }, [data, getActiveDog, getDogs, refetchUserDogs]);

  const handleCancelDogSub = (subId, cancelAtTheEnd, isCancel, message) => {
    const dog = customerDogs.find((dog) => dog.subscriptions.subId === subId);
    if (dog) {
      if (isCancel) {
        cancelDogSuscription({
          variables: {
            dogId: dog.id,
            subId: subId,
            cancelAtPeriodEnd: cancelAtTheEnd,
            message: message,
            createdAt: moment().unix(),
          },
        })
          .then((res) => {
            refetch();
            setConfirmCancelSub(false);
            enqueueSnackbar(`Subscription for ${dog.name} has been canceled`, {
              variant: "success",
            });
          })
          .catch((error) => {
            enqueueSnackbar(`Subscription could not canceled `, {
              variant: "error",
            });
          });
      } else {
        dontCancelSubscription({
          variables: {
            subId: subId,
            time: moment().unix(),
          },
        })
          .then((res) => {
            refetch();
            setConfirmCancelSub(false);
            enqueueSnackbar(`DON'T Cancel Subscription works`, {
              variant: "success",
            });
          })
          .catch((error) => {
            enqueueSnackbar(`DON'T Cancel Subscription error `, {
              variant: "error",
            });
          });
      }
    }
  };

  const handleRefetch = () => {
    refetch();
  };
  const handleRefetchDogs = () => {
    getDogs()
      .then((data) => {
        setCustomerTotalDogs(data.data.getMongoDogsByOwnerID);
      })
      .catch(() => {});
  };
  const handleConfirmCancelSub = () => {
    handleCancelDogSub();
  };

  const handleCloseConfirmSub = () => {
    setConfirmCancelSub(false);
  };

  const handleItemsToBeAdded = () => {
    let newPlans = plans.filter((plan) => plan.quantity > 0);
    setPlansToAdd(newPlans);
    setOpenSubsDetail(true);
    setOpenAddBoxDiag(false);
  };

  const handleClickOpen = () => {
    getActiveDog();
    setOpen(true);
  };
  const handleClickOpenCancelM = () => {
    setOpenCancelM(true);
    calculateShipping();
  };

  const [openRef, setOpenRef] = useState(false);

  const handleClickOpenRef = () => {
    setOpenRef(true);
  };

  const handleCloseRef = () => {
    setOpenRef(false);
  };

  const handleSubUpdate = (newSubObj) => {
    const updateInput = {
      subId: newSubObj.subID,
      premium: Boolean(data.customer.premium),

      shippingInfo: {
        serviceCode: newSubObj.shippingRate.serviceCode,
        shipmentCost: newSubObj.shippingRate.shipmentCost,
        length: newSubObj.shippingRate.length,
        height: newSubObj.shippingRate.height,
        width: newSubObj.shippingRate.width,
        weight: newSubObj.shippingRate.weight,
        bagsLength: newSubObj.shippingRate.bagsLength,
      },
      itemsList: newSubObj.basket,
    };

    updateSubQuanties({ variables: { input: updateInput } }).then((res) => {
      enqueueSnackbar(`Subscription Updated Successfully`, {
        variant: "success",
      });
      handleClose();
      refetch();
      setPlansToAdd([]);
    });
  };

  const handleCloseAddBox = () => {
    setOpenAddBoxDiag(false);
    setOpenSubsDetail(true);
  };

  const handlePlanOnChange = (id, qty, fn) => {
    let name = id;
    let value = fn === 0 ? qty - 1 : qty + 1;

    if (value < 0) {
      value = 0;
    }
    let currentPlans = [...plans];
    const planIndex = plans.findIndex((item) => item.id === name);
    let newPlan = { ...plans[planIndex], quantity: value };
    currentPlans[planIndex] = newPlan;
    setPlans(currentPlans);
    setCanUpdate(false);
    setUpdatedShippingCost(null);
  };

  const handleBoxAdd = () => {
    getChecoutPlans();
    setOpenAddBoxDiag(true);
    setOpenSubsDetail(false);
  };
  const calculateShippingFunc = () => {
    const rateInput = {
      subId: subscription.id,
      products: items
        .filter(
          (item) => ![shippingProdId, memberShipId].includes(item.plan.product)
        )
        .map((item) => {
          return {
            productId: item.plan.product,
            planId: item.plan.id,
            quantity: parseInt(item.quantity),
          };
        }),
    };
    if (plansToAdd.length > 0) {
      plansToAdd.map((plan) => {
        rateInput.products.push({
          productId: plan.product.id,
          planId: plan.id,
          quantity: parseInt(plan.quantity),
        });
      });
    }
    const productCount = rateInput.products
      .map((product) => product.quantity)
      .reduce((a, b) => a + b, 0);
    if (!data.customer.premium && productCount <= 0) {
      enqueueSnackbar("You must have at least one item", { variant: "info" });
      return;
    }
    getRateAdmin({
      variables: {
        input: rateInput,
      },
    }).then((res) => {
      setCanUpdate(true);
      setUpdatedShippingCost(res.data.getRateAdmin);
    });
  };

  const handleClickOpenSubsDetail = (e) => {
    setItems();
    getActiveDog();
    setOpenSubsDetail(true);
    const subItems = data.customer.stripe.subscription[e].items.data;
    setSubscription(data.customer.stripe.subscription[e]);
    setItems(subItems);
  };

  const handleClose = () => {
    setOpen(false);
    setCanUpdate(false);
    setUpdatedShippingCost(null);
    setPlansToAdd([]);
    setOpenCancelM(false);
    setOpenSubsDetail(false);
    setFreeSwitch(false);
    handleSetCycleCount(0);
  };

  const addMembershipFunc = () => {
    addMembership({
      variables: {
        id,
        premiumInterval: isPremiumYearly ? 12 : 1,
        cycleCount: cycleCount,
        cancelEndOfCycle: cancelAtEndOfCycle,
      },
    })
      .then(async ({ data }) => {
        handleClose();
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(`${e.message}`, {
          variant: "error",
        });
      });
  };

  const cancelMembershipFunc = () => {
    if (reason.length === 0) {
      enqueueSnackbar("Please choose a reason.", {
        variant: "error",
      });
    } else if (reason === "other" && value.length <= 5) {
      enqueueSnackbar("Please write a reason more than 5 letters.", {
        variant: "error",
      });
    } else {
      cancelMembership({
        variables: {
          id,
          cancelAtPeriodEnd: memberEndOf,
          createdAt: moment().unix(),
          message: reason !== "other" ? reason : value,
        },
      }).then(async ({ data }) => {
        handleClose();
        refetch();
      });
    }
  };

  const onUpdateUserName = () => {
    updateUserNameMutation({
      variables: {
        id: data.customer.id,
        username: userNameInput,
      },
    })
      .then((responseData) => {
        enqueueSnackbar("Username Updated Successfully", {
          variant: "success",
        });
        refetch();
      })
      .catch(() => {
        enqueueSnackbar("Username Could not updated", {
          variant: "error",
        });
      });
  };

  const onCreateUsedPoints = () => {
    if (type === "Affiliate" && points < 10) {
      enqueueSnackbar("Only a minimum of $100 can be withdrawn", {
        variant: "error",
      });
      return;
    }
    createUsedPoints({
      variables: {
        input: {
          memberID: data.customer.id,
          details: details,
          usedPoint: parseInt(points),
          pointType: type,
        },
      },
    })
      .then((responseData) => {
        enqueueSnackbar("Points Updated Successfully", {
          variant: "success",
        });
        setOpenRef(false);
        refetch();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
  };

  const makeAnAAffiliate = (event) => {
    makeAAffiliate({
      variables: { id: data.customer.id },
    })
      .then((responseData) => {
        enqueueSnackbar("Success", { variant: "success" });
        refetch();
      })
      .catch(() =>
        enqueueSnackbar("ERROR: Please contact to member service.", {
          variant: "error",
        })
      );
  };

  const makeRegular = (event) => {
    unmakeAffiliate({
      variables: { id: data.customer.id },
    })
      .then((responseData) => {
        enqueueSnackbar("Success", { variant: "success" });
        refetch();
        setOpenRegular(false);
      })
      .catch(() =>
        enqueueSnackbar("ERROR: Please contact to member service.", {
          variant: "error",
        })
      );
  };

  const handleUpdateAffiliatePoint = (event) => {
    updateAffiliatePoint({
      variables: { id: data.customer.id, point: affiliatePoint },
    })
      .then((responseData) => {
        enqueueSnackbar("Updated!", { variant: "success" });
        refetch();
      })
      .catch(() =>
        enqueueSnackbar("ERROR: Please contact to member service.", {
          variant: "error",
        })
      );
  };

  const handleSelectChange = (event) => {
    setType(event.target.value);
  };

  const continueDate = (date) => {
    return (
      <div
        style={{
          background: "#ffca02",
          padding: 5,
          borderRadius: 4,
          color: "#252525",
          width: "fit-content",
        }}
      >
        <PauseCircleFilledIcon
          style={{
            marginBottom: -7,
          }}
        />
        Collection paused <br /> until{" "}
        {`\n${moment.unix(date).format("MMM Do")}`}
      </div>
    );
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  //

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Customer</Typography>
            <Typography color="textPrimary">
              <b>{data.customer.name}</b>
              {data.customer.premium ? (
                <Tooltip title="Membership">
                  <img
                    width="30px"
                    style={{ float: "right", marginTop: -6, marginLeft: 8 }}
                    src="https://feedreal-s3.s3.amazonaws.com/coin-icon-6.png"
                  />
                </Tooltip>
              ) : (
                ""
              )}
              <b
                className="shippingbtn"
                style={{
                  ...(data.customer.type === "BANNED" && {
                    background: "#ff0000",
                  }),
                }}
              >
                {
                  CUSTOMER_TYPES.find(
                    (item) => item.value === data.customer.type
                  ).label
                }
              </b>
            </Typography>
          </Breadcrumbs>
        </div>
        <div className="right">
          <SendFeedback
            customerId={id}
            customerEmail={data.customer.email}
            me={props.session.activeAdmin.id}
          />
        </div>
      </div>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Paper>
              <Grid className="badgemenu">
                <Grid item xs={12} className="member-data">
                  <Typography
                    style={{ marginBottom: "15px" }}
                    textAlign={"center"}
                  >
                    Summary
                  </Typography>

                  {data.customer.created_at && (
                    <TextField
                      fullWidth
                      disabled
                      id="outlined-disabled"
                      label="User Since"
                      defaultValue={moment
                        .unix(data.customer.created_at)
                        .format("MM/DD/YYYY")}
                      variant="outlined"
                    />
                  )}

                  <TextField
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    label="Name"
                    defaultValue={data.customer.name}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    label="Phone Number"
                    value={data.customer.phone_number}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          disabled={
                            props.session.activeAdmin.permission.developer &&
                            props.session.activeAdmin.permission.developer.admin
                              ? false
                              : true
                          }
                          onClick={() => {
                            handleClickUpdateProfile("phone");
                          }}
                        >
                          <SettingsIcon />
                        </IconButton>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    label="Email"
                    value={data.customer.email}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                        
                          onClick={() => {
                            handleClickUpdateProfile("email");
                          }}
                        >
                          <SettingsIcon />
                        </IconButton>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    id="outlined-disabled"
                    label="Username"
                    value={userNameInput}
                    // onChange={(e) => setUserNameInput(e.target.value)}
                    disabled
                    variant="outlined"
                  />
                  {userNameInput !== data.customer.username && (
                    <Button
                      fullWidth
                      variant={"outlined"}
                      onClick={() => {
                        onUpdateUserName();
                      }}
                      style={{ marginBottom: 20 }}
                      color={"secondary"}
                    >
                      Update
                    </Button>
                  )}
                  {/* {data.customer.ref ? (
                    <Link
                      variant="text"
                      // color="tertiary"
                      // className="redborder"
                      className="textfield-link"
                      size="small"
                      to={`/dashboard/customer/${data.customer.ref.id}`}
                    >
                      <TextField
                        fullWidth
                        disabled
                        id="outlined-disabled"
                        label="Referrer"
                        defaultValue={
                          data.customer.ref.username
                            ? data.customer.ref.username
                            : data.customer.ref.name
                            ? data.customer.ref.name
                            : "Member Link"
                        }
                        variant="outlined"
                      />
                    </Link>
                  ) : (
                    <SearchCustomerInput
                      owner={data.customer.id}
                      handleRefetch={handleRefetch}
                    />
                  )} */}
                  {/* <RefLinks
                    id={data.customer.id}
                    username={
                      data.customer.username
                        ? data.customer.username
                        : data.customer.name
                    }
                  />
                  <CommunicationSwitch
                    id={data.customer.id}
                    communication={data.customer.communication}
                    communicationPermissions={
                      data.customer.communicationPermissions
                    }
                    handleRefetch={handleRefetch}
                  /> */}
                </Grid>
              </Grid>
            </Paper>
            <br></br>
            {/* {data.customer.stripe && (
              <StripeBalance
                session={props.session}
                balance={data.customer.stripe.balance}
                customerStripeId={data.customer.stripe.id}
                refetch={() => refetch()}
              />
            )} */}
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={3}>
              {data.customer.premium !== 1 &&
                data.customer.stripe?.subscription
                  .map((sub) => parseFloat(sub.metadata.shippingAmount))
                  .reduce((a, b) => a + b, 0) > 22 && (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      This customer pays{" "}
                      <b>
                        {data.customer.stripe.subscription
                          .map((sub) => parseFloat(sub.metadata.shippingAmount))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2)}{" "}
                      </b>
                      for shipping instead a membership. Please reach out to
                      find out why they are not signing up for a membership.
                    </Alert>
                  </Grid>
                )}
              <Grid item xs={3}>
                <Paper className={`borderblue summarybox ${classes.paper}`}>
                  Total Dogs <b>{customerTotalDogs.length}</b>
                </Paper>
              </Grid>

              <Grid item xs={3}>
                <Paper
                  className={`borderred summarybox affiliatetext ${classes.paper}`}
                  // onClick={handleClickOpenRef}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {"RDC"}
                  <div style={{ float: "right", display: "flex" }}>
                    <b>
                      {data.customer.wallet
                        ? formatFloat(data.customer.wallet.coins)
                        : "0"}
                    </b>
                    <InfoIcon
                      style={{
                        marginTop: -2,
                        marginLeft: 10,
                      }}
                      onClick={() => setOpenWalletLogs(true)}
                    />
                    {data.customer.stripe && (
                      <RDCTransfer
                        balance={data.customer.stripe.balance}
                        customerStripeId={data.customer.stripe.id}
                        refetch={() => refetch()}
                        mongoCustomer={data.customer}
                        customerTotalDogs={customerTotalDogs}
                      />
                    )}{" "}
                    <WalletLogList
                      id={id}
                      open={openWalletLog}
                      handleOpen={handleOpenWalletLogs}
                      button={false}
                    />
                  </div>
                </Paper>
              </Grid>
              <div>
                <Dialog
                  open={openUpdateProfile}
                  onClose={handleCloseUpdateProfile}
                  aria-labelledby="form-dialog-title"
                >
                  <LinearLoading open={loadingUpdatePhoneOrEmail} />
                  <DialogTitle id="form-dialog-title">
                    Update User's{" "}
                    {updateType === "phone" ? "Phone Number" : "Email"}
                  </DialogTitle>
                  <DialogContent>
                    <FormControl variant="standard">
                      <MuiPhoneNumber
                        defaultCountry={"us"}
                        label="Phone Number"
                        sx={{ margin: "20px 0px" }}
                        value={updatePhone}
                        onChange={(e) => {
                          setUpdatePhone(e);
                        }}
                        fullWidth
                        disableDropdown={true}
                        id="outlined-disabled"
                        variant="outlined"
                        disabled={updateType !== "phone"}
                      />
                      {/*              <TextField
                        sx={{ margin: "20px 0px" }}
                        fullWidth
                        disabled={updateType !== "phone"}
                        id="outlined-disabled"
                        label="Phone Number"
                        value={updatePhone}
                        onChange={(e) => setUpdatePhone(e.target.value)}
                        variant="outlined"
                        aria-describedby="helper-phone"
                      /> */}
                      <TextField
                        sx={{ margin: "20px 0px" }}
                        fullWidth
                        disabled={updateType !== "email"}
                        id="outlined-disabled"
                        label="Email"
                        value={updateEmail}
                        onChange={(e) => setUpdateEmail(e.target.value)}
                        variant="outlined"
                        aria-describedby="helper-email"
                      />
                      <FormHelperText
                        id={
                          "helper-" + updateType === "phone"
                            ? "phone"
                            : "email "
                        }
                      >
                        You can't change this field on this menu. If you want to
                        update{" "}
                        {updateType === "phone" ? "phone number " : "email "} of
                        the member please click the button next to that field on
                        the customer menu.
                      </FormHelperText>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseUpdateProfile} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={handleClickOpenSureProfile}
                      color="primary"
                      disabled={
                        updateType === "phone"
                          ? data.customer.phone_number !== updatePhone
                            ? false
                            : true
                          : data.customer.email !== updateEmail
                          ? false
                          : true
                      }
                    >
                      Accept
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openSureProfile}
                  onClose={handleCloseUpdateProfile}
                  aria-labelledby="form-dialog-title"
                >
                  <LinearLoading open={loadingUpdatePhoneOrEmail} />
                  <DialogTitle id="form-dialog-title">
                    Are you sure you want to update?
                  </DialogTitle>
                  <DialogContent>
                    <Alert
                      sx={{ margin: "10px 0px" }}
                      severity={updateType === "phone" ? "warning" : "info"}
                    >
                      {data.customer.phone_number}{" "}
                      {updateType === "phone"
                        ? `will be updated to ${updatePhone}!`
                        : "won't be change."}
                    </Alert>
                    <Alert
                      sx={{ margin: "10px 0px" }}
                      severity={updateType === "email" ? "warning" : "info"}
                    >
                      {data.customer.email}{" "}
                      {updateType === "email"
                        ? `will be updated to ${updateEmail}!`
                        : "won't be changed."}
                    </Alert>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseOpenSureProfile}
                      color="primary"
                      disabled={loadingUpdatePhoneOrEmail ? true : false}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleUpdatePhoneOrEmail}
                      color="primary"
                      disabled={loadingUpdatePhoneOrEmail ? true : false}
                    >
                      {loadingUpdatePhoneOrEmail ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Accept"
                      )}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openMakeAffiliate}
                  onClose={() => setOpenMakeAffiliate(false)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Make Affiliate
                  </DialogTitle>
                  <DialogContentText>
                    &nbsp; Are you sure to affiliate {data.customer.name}?
                    &nbsp;
                  </DialogContentText>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenMakeAffiliate(false)}
                      color="primary"
                    >
                      No
                    </Button>
                    <Button onClick={makeAnAAffiliate} color="secondary">
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openAffiliatePoint}
                  onClose={() => setOpenAffiliatePoint(false)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Make Affiliate
                  </DialogTitle>
                  <DialogContentText>
                    &nbsp; Are you sure to affiliate {data.customer.name}?
                    &nbsp;
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="points"
                    label="Points"
                    type="number"
                    value={affiliatePoint}
                    onChange={(e) =>
                      e.target.value > 0
                        ? setAffiliatePoint(parseInt(e.target.value))
                        : setAffiliatePoint(1)
                    }
                    fullWidth
                  />
                  <DialogActions>
                    <Button
                      onClick={() => setOpenAffiliatePoint(false)}
                      color="primary"
                    >
                      No
                    </Button>
                    <Button
                      onClick={handleUpdateAffiliatePoint}
                      color="secondary"
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openMakeAffiliate}
                  onClose={() => setOpenMakeAffiliate(false)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Make Affiliate
                  </DialogTitle>
                  <DialogContentText>
                    &nbsp; Are you sure to affiliate {data.customer.name}?
                    &nbsp;
                  </DialogContentText>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenMakeAffiliate(false)}
                      color="primary"
                    >
                      No
                    </Button>
                    <Button onClick={makeAnAAffiliate} color="secondary">
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openRegular}
                  onClose={() => setOpenRegular(false)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Make Regular Member
                  </DialogTitle>
                  <DialogContentText>
                    &nbsp; Are you sure to convert {data.customer.name}'s
                    account to regular member? &nbsp;
                  </DialogContentText>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenRegular(false)}
                      color="primary"
                    >
                      No
                    </Button>
                    <Button onClick={makeRegular} color="secondary">
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openRef}
                  onClose={handleCloseRef}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Use Points</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please choose which type of points you want to use.
                    </DialogContentText>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={type}
                        onChange={handleSelectChange}
                        label="Type"
                      >
                        <MenuItem value={"Ref"}>Ref</MenuItem>
                        {data.customer.isAffiliate && (
                          <MenuItem value={"Affiliate"}>Affiliate</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    {type !== "Affiliate" ? (
                      <TextField
                        autoFocus
                        margin="dense"
                        id="points"
                        label="Points"
                        type="number"
                        value={points}
                        onChange={(e) =>
                          e.target.value > 0
                            ? setPoints(e.target.value)
                            : setPoints(1)
                        }
                        fullWidth
                      />
                    ) : (
                      <TextField
                        autoFocus
                        margin="dense"
                        id="points"
                        label="Money"
                        type="number"
                        value={points}
                        onChange={(e) =>
                          setPoints(e.target.value > 0 && e.target.value)
                        }
                        fullWidth
                      />
                    )}

                    <TextField
                      autoFocus
                      margin="dense"
                      id="details"
                      label="Details"
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseRef} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={onCreateUsedPoints} color="primary">
                      Accept
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <Grid item xs={3}>
                <Paper className={`bordergreen summarybox ${classes.paper}`}>
                  Monthly{" "}
                  <b>
                    $
                    <NumberFormat
                      decimalScale={2}
                      fixedDecimalScale
                      displayType="text"
                      value={totalM / 100}
                    />
                  </b>
                </Paper>
              </Grid>

              {data.customer.premium === 1 ? (
                <Grid item xs={3}>
                  <Paper className={`borderyellow summarybox ${classes.paper}`}>
                    <STMOfCustomer customer={data.customer} refetch={refetch} />
                  </Paper>
                </Grid>
              ) : (
                <Grid item xs={3}>
                  <Paper className={`borderyellow summarybox ${classes.paper}`}>
                    SS Spend
                    <b>
                      $
                      <NumberFormat
                        decimalScale={2}
                        fixedDecimalScale
                        displayType="text"
                        value={totalPayment / 100}
                      />
                    </b>
                  </Paper>
                </Grid>
              )}

              <Grid item xs={8}>
                <Paper className={classes.paper}>
                  Subscriptions3{" "}
                  <List className="nopadding subscriptions">
                    {data.customer.stripe?.subscription.map((x, index) => (
                      <div>
                        <SubscriptionListItem
                          planNickName={x.items.data
                            .filter((d) => d.plan.product !== shippingProdId)
                            .map((y) => y.plan.nickname)}
                          quantity={x.items.data
                            .filter((d) => d.plan.product !== shippingProdId)
                            .map((y) => y.quantity)}
                          dogName={x.metadata.dog}
                          status={x.status}
                          timeAndDate={() => {
                            return x.cancel_at_period_end ? (
                              <p style={{ color: "#d84d4d", fontSize: 12 }}>
                                Cancel at the end of the cycle :
                                {moment.unix(x.cancel_at).format("MM/DD/YYYY")}
                              </p>
                            ) : x.pause_collection ? (
                              // ? continueDate(x.pause_collection.resumes_at)
                              <p style={{ color: "#ffca02", fontSize: 12 }}>
                                Paused until :
                                {x.pause_collection.resumes_at
                                  ? moment
                                      .unix(x.pause_collection.resumes_at)
                                      .format("MM/DD/YYYY")
                                  : "Untill you resume!"}
                              </p>
                            ) : (
                              <p style={{ fontSize: 12 }}>
                                Renew date:
                                {moment
                                  .unix(x.current_period_end)
                                  .format("MM/DD/YYYY")}
                              </p>
                            );
                          }}
                          shippingMembership={x.items.data.map((y) => ({
                            type:
                              y.plan.product === shippingProdId
                                ? "shipping"
                                : y.plan.product === memberShipId
                                ? "membership"
                                : "",
                            price: y.plan.amount / 100,
                          }))}
                          editAddress={() => {
                            if (data) {
                              return (
                                <ShippingCostsOfDog
                                  customerMongoId={id}
                                  subscriptionId={x.id}
                                  customer={data.customer}
                                  close={() => {
                                    setOpenSubsDetail(false);
                                    refetch();
                                    setFechAgain(fetchAgain + 1);
                                  }}
                                />
                              );
                            } else {
                              return <div></div>;
                            }
                          }}
                          // editButton={() => {
                          //   return (
                          //     <UpdateSubProducts
                          //       refetch={refetch}
                          //       dogId={
                          //         Array.isArray(customerTotalDogs) &&
                          //         customerTotalDogs.find(
                          //           (item) =>
                          //             item.subscriptions?.subId === x?.id
                          //         )?.id
                          //       }
                          //       userProducts={x.items.data}
                          //       subscription={x}
                          //       handleUpdate={(data) => {
                          //         handleSubUpdate(data);
                          //       }}
                          //       onHandleCancelSub={(
                          //         cancelAtTheEnd,
                          //         isCancel,
                          //         message
                          //       ) => {
                          //         handleCancelDogSub(
                          //           x.id,
                          //           cancelAtTheEnd,
                          //           isCancel,
                          //           message
                          //         );
                          //       }}
                          //       getActiveDog={() => {
                          //         // getActiveDog();
                          //       }}
                          //       updateLoader={
                          //         loadingCancelSubscription ||
                          //         loadingQuanties ||
                          //         loadingDontCancelSubscription
                          //       }
                          //     />
                          //   );
                          // }}
                        />
                      </div>
                    ))}
                  </List>
                </Paper>
                <CustomerCourses activeAdmin={props.session.activeAdmin} />
                {/* <ShippingAddress
                  customerMongoId={id}
                  customerMongoEmail={data.customer.email}
                  fetchAgain={fetchAgain}
                  customerRefecth={() => {
                    refetch();
                  }}
                ></ShippingAddress> */}
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  Quick Tools
                  <br />
                  <br />
                  {/* <RestartSubscription
                    customer={data.customer}
                    customerMongoId={data.customer.id}
                    refetch={refetch}
                  /> */}
                  <div style={{ height: 10 }}></div>
                  <OneTimeOrderDialog
                    customerMongoId={data.customer.id}
                    stripeCustomer={data.customer.stripe}
                    email={data.customer.email}
                    id={data.customer.id}
                    refetch={refetch}
                    mongoCustomer={data.customer}
                  />
                  <div style={{ height: 10 }}></div>
                  <MembershipOperations
                    data={data}
                    handleClickOpenM={handleClickOpen}
                    handleClickOpenCancelM={handleClickOpenCancelM}
                    refetch={refetch}
                  />
                  {/* <div style={{ height: 10 }}></div> */}
                  {/* <CustomersAssistantSteps customer={data} /> */}
                  <br />
                  {/* {data.customer.isAffiliate ? (
                    <Button
                      onClick={() => setOpenRegular(true)}
                      variant="outlined"
                      fullWidth
                      disabled={LoadingUnmakeAffiliate}
                      size="small"
                      className=" btn-text-left btn-text-margin-left"
                    >
                      Back to Regular Member
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<GroupAddIcon />}
                      fullWidth
                      onClick={() => setOpenMakeAffiliate(true)}
                      className="btn-text-left btn-text-margin-left"
                    >
                      Make Affiliate
                    </Button>
                  )} */}
                  {/* <div style={{ height: 10 }}></div> */}
                  {/* <ShippingDetails email={data.customer.email} /> */}
                  {/* RDC History */}
                  {/* <div style={{ height: 10 }}></div> */}
                  {/* <CustomerNotes
                    customerId={id}
                    me={props.session.activeAdmin.id}
                  />
                  <AppointmentNotes
                    customerId={id}
                    me={props.session.activeAdmin.id}
                  /> */}
                  <br />
                  <GiveCourseAway customer={data?.customer} />
                  <Workshops customer={data?.customer} />
                  <br />
                  {/* <AssignConsultation customer={data?.customer} />
                  {god && (
                    <BanMember customer={data?.customer} refetch={refetch} />
                  )} */}
                  {/* <br />
                  <Link
                    to={`/dashboard/customer/${id}/course-progress-of-customer`}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Button fullWidth variant="outlined">
                      Course Progress Of User
                    </Button>
                  </Link> */}
                </Paper>
                {/* <Paper className={classes.paper}>
                  Dogs
                  <br />
                  <br />
                  {customerTotalDogs &&
                    Array.isArray(customerTotalDogs) &&
                    customerTotalDogs.map((dog) => (
                      <OutlinedInput
                        fullWidth
                        disabled
                        size="small"
                        id="outlined-disabled"
                        defaultValue={dog.name}
                        variant="outlined"
                        endAdornment={
                          <InputAdornment position="end">
                            <UpdateDog
                              ownerID={data.customer.id}
                              handleRefetch={() => {
                                refetch();
                                refetchUserDogs();
                              }}
                              dog={dog}
                            />
                          </InputAdornment>
                        }
                        style={{ marginBottom: 10 }}
                      />
                    ))}
                </Paper> */}
              </Grid>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"xs"}
              >
                {loadingGetActiveDog ? (
                  <div>
                    <div>
                      <LinearProgress color="secondary" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <DialogTitle id="alert-dialog-title">
                      {"Add RDB Membership"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {customerDogs ? (
                          <div>
                            There is a dog with a total of {customerDogs.length}{" "}
                            active box. Do you still want to continue?
                          </div>
                        ) : (
                          ""
                        )}

                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isPremiumYearly}
                                onChange={() =>
                                  setIsPremiumYearly(!isPremiumYearly)
                                }
                                color="warning"
                              />
                            }
                            sx={{ margin: "auto" }}
                            label={
                              isPremiumYearly ? (
                                <p>Switch to monthly subscription</p>
                              ) : (
                                <p>
                                  Save up to <strong>$60</strong> with yearly
                                  subscription
                                </p>
                              )
                            }
                          />
                        </FormGroup>

                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ marginBottom: "10px" }}
                        >
                          <FormGroup
                            sx={{ marginBottom: "10px", marginTop: "10px" }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  color="success"
                                  checked={freeSwitch}
                                  onChange={() => {
                                    if (freeSwitch) {
                                      handleSetCycleCount(0);
                                    } else {
                                      handleSetCycleCount(1);
                                    }
                                    setFreeSwitch(!freeSwitch);
                                  }}
                                />
                              }
                              label="Free Subscription"
                            />
                          </FormGroup>
                          {freeSwitch && (
                            <>
                              <FormControl sx={{ minWidth: "200px" }}>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  sx={{ marginBottom: "10px" }}
                                >
                                  Select Free Subscription Cycles
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={cycleCount}
                                  onChange={(e) =>
                                    handleSetCycleCount(e.target.value)
                                  }
                                >
                                  {Array(12)
                                    .fill(1)
                                    .map((v, i) => {
                                      return (
                                        <MenuItem value={i + 1}>
                                          {i + 1}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={cancelAtEndOfCycle}
                                    onChange={(e) =>
                                      setCancelEndOfCycle(e.target.checked)
                                    }
                                  />
                                }
                                label="Cancel at end of Cycle"
                              />
                            </>
                          )}
                        </Grid>
                        {!isPremiumYearly ? (
                          <Alert severity="warning">
                            Customer will pay monthly.(USA=$22, Canada=$27)
                          </Alert>
                        ) : (
                          <Alert severity="warning">
                            Customer will pay yearly.(USA=$204, Canada=$276)
                          </Alert>
                        )}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Disagree
                      </Button>
                      <Button
                        onClick={addMembershipFunc}
                        color="secondary"
                        autoFocus
                        disabled={loadingAddMembership}
                      >
                        Agree{" "}
                        {loadingAddMembership ? (
                          <CircularProgress size={24} />
                        ) : (
                          ""
                        )}
                      </Button>
                    </DialogActions>
                  </div>
                )}
              </Dialog>
              <Dialog
                open={openCancelM}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"xs"}
              >
                {loadingCalculateShipping ? (
                  <div>
                    <div>
                      Shipping Calculating...
                      <LinearProgress color="secondary" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <DialogTitle id="alert-dialog-title">
                      {"Cancel Membership"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {shippingCost ? (
                          <div>
                            {shippingCost.map((x) => (
                              <div>
                                {x.dogName} shipping rate{" "}
                                {parseFloat(x.rate.shipmentCost).toFixed(2)} ?
                                Do you still want to continue?
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                        <FormControl fullWidth>
                          <Select
                            value={reason}
                            onChange={handleChangeReason}
                            displayEmpty
                            focused
                            fullWidth
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                              color: "#fff !important",
                              backgroundColor: "#ffffff21 !important",
                              width: "100%",
                            }}
                          >
                            <MenuItem disabled value="">
                              <em>Please choose a reason.</em>
                            </MenuItem>
                            {cancelReasons.map((data) => (
                              <MenuItem value={data}>{data}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {reason && reason === "other" && (
                          <TextField
                            id="outlined-multiline-static"
                            label="Comment"
                            multiline
                            maxRows={4}
                            rows={4}
                            value={value}
                            sx={{
                              height: "125px",
                              width: "100%",
                              marginTop: "20px",
                            }}
                            onChange={handleChange}
                            placeholder="Please write a reason more than 5 letters."
                          />
                        )}
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              checked={memberEndOf}
                              onClick={() => {
                                setMemberEndOf(!memberEndOf);
                              }}
                            />
                          }
                          label="Cancel at the end of the cycle"
                          labelPlacement="end"
                        />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Disagree
                      </Button>
                      <Button
                        onClick={cancelMembershipFunc}
                        color="secondary"
                        autoFocus
                        disabled={loadingCancelMembership}
                      >
                        Agree{" "}
                        {loadingCancelMembership ? (
                          <CircularProgress size={24} />
                        ) : (
                          ""
                        )}
                      </Button>
                    </DialogActions>
                  </div>
                )}
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default CustomerDetail;
