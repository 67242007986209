import React, { Component } from "react";
import ResponsiveDrawer from "./Drawer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//Auth Check
import auth from "../auth";

//QRCode & General
import Admins from "./Settings/AdminSettings/Admins";
import FirstLoginPass from "./Settings/AdminSettings/FirstLoginPass";
import DashboardMain from "./Dashboard/Main";
import Log from "./Logs";
import QR_Codes from "../Dashboard/QR/List";

//Cancellation & Shipping
import Cancellations from "../Dashboard/Reports/Cancel";
import Archive from "../Dashboard/Reports/Archive";
import Shippings from "../Dashboard/Shipping/ShippingList";

//Customer & Consultation
import Consultation from "./Customers/Consultation";
import Customers from "../Dashboard/Customers/CustomerList";
import CustomerDetail from "../Dashboard/Customers/CustomerDetail";

//Stock & Inventory
import Processing from "../Dashboard/Inventory/Processing";
import AllProcessing from "../Dashboard/Inventory/AllProcess";
import ManageInventory from "./Settings/Inventory/";
import MonthlyBoxes from "./Settings/MonthlyBoxes";
import StockUn from "../Dashboard/Stock/UnProcessing";
import Stock from "../Dashboard/Stock/Stock";

//Blog
import EditPage from "./Underhood/EditPage";
import AddPage from "./Underhood/AddPage";
import UnderhoodList from "./Underhood/UnderhoodList";

//Glossary
import Glossary from "../Dashboard/Glossary/";
import GlossaryAdd from "../Dashboard/Glossary/Add";
import GlossaryEdit from "../Dashboard/Glossary/Edit";

// secretshop
import Secretshop from "../Dashboard/Secretshop/";
import SecretshopPublishMultiple from "../Dashboard/Secretshop/publishMultiple";
import AssignedConsultation from "./Customers/AssignedConsultation";
import MemberMessageList from "../Dashboard/Secretshop/messageList";

//newsletter
import ShortleashList from "../Dashboard/Shortleash/ShortleashList";
import AddShortleash from "./Shortleash/Add";
import EditShortleash from "./Shortleash/Edit";

//Membership
import Membership from "./Membership";
import ProductPage from "./ProductPage";
import CreateNewPage from "./ProductPage/createNew";
import ConsultationSearch from "./Customers/ConsultationSearch";

//Affiliate
import AffiliateAnalytics from "./Customers/AffiliateAnalytics";
import AffiliateDetail from "./Customers/AffiliateDetail";

//Quiz
import Quizzes from "./Quiz";
import Quiz from "./Quiz/Quiz";
import Courses from "./Course";
import Workshops from "./Workshops";
import WorkshopsList from "./WorkshopsList";
import Course from "./Course/Course";
import Unit from "./Course/components/Unit";

//Questions
import Questions from "./Questions";
import BalanceHistory from "./Customers/StripeBalanceHistory";
import AffiliateFormRequests from "./AffiliateRequests";

//Final Quiz
import FinalQuiz from "./FinalQuiz";
import QuizResults from "./QuizResults";
import Rewards from "./Rewards";
import BarcodeGenerator from "./BarcodeGenerator";
import RetailerRequests from "./RetailerRequests/retailerRequests";

//Website Settings
import WebsiteSettings from "./WebsiteSettings";
import VetRequests from "./VetRequests/vetRequests";

//Gift Code Generator
import GiftCodeGenerator from "./GiftCodeGenerator";
import GiftCodeGeneratorPrinted from "./GiftCodeGenerator/printed";
import GiftCodeGeneratorRedeemed from "./GiftCodeGenerator/redeemed";
import CourseProgressOfCustomer from "./Customers/Components/courseProgressOfCustomer";

//Newsletter email list
import NewsletterEmailList from "./NewsletterEmail";

import Rooms from "./Rooms";
import CourseSuccessPage from "./Course/components/Success";
import CourseTransactionList from "./CourseTransactions";
import CourseCompleted from "./Course/CourseCompleted";
import DirectoryState from "./DirectoryStates";
import Ticket from "./Ticket";
import TicketHistory from "./Ticket/TicketHistory";
class Dashboard extends Component {
  render() {
    let { session, refetch } = this.props;
    if (session.activeAdmin) {
      const { permission } = session.activeAdmin;
      const god = permission.god ? permission.god.admin : false;
      const inventory = permission.inventory ? permission.inventory : false;
      const author = permission.author ? permission.author : false;
      const underhood = permission.underhood ? permission.underhood : false;
      const accountManager = permission.accountManager?.admin ? true : false;

      return (
        <div>
          {session.activeAdmin.status ? (
            <div>
              <Router>
                <ResponsiveDrawer session={session}>
                  <Switch>
                    <Route
                      exact
                      path="/dashboard"
                      render={(props) => <DashboardMain session={session} />}
                    />
                    {/* <Route
                      exact
                      path="/dashboard/caninecare"
                      render={(props) =>
                        god || author.admin || author.write || author.read ? (
                          <CanineList
                            session={session}
                            history={props.history}
                          />
                        ) : (
                          <Redirect to="/dashboard" />
                        )
                      }
                    /> */}
                    <Route
                      exact
                      path="/dashboard/page"
                      render={(props) => (
                        <ProductPage
                          session={session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/page/add"
                      render={(props) => (
                        <CreateNewPage
                          session={session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/page/edit/:id"
                      render={(props) => (
                        <CreateNewPage
                          session={session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/underhood"
                      render={() =>
                        god ||
                        underhood.admin ||
                        underhood.write ||
                        underhood.read ? (
                          <UnderhoodList session={session} />
                        ) : (
                          <Redirect to="/dashboard" />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/underhood/edit/:id"
                      render={(props) => (
                        <EditPage session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/underhood/add"
                      render={(props) => (
                        <AddPage
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/adminsettings"
                      render={() =>
                        god ? (
                          <Admins session={this.props.session} />
                        ) : (
                          <Redirect to="/dashboard" />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/inventorysettings"
                      render={() =>
                        god || inventory.admin ? (
                          <ManageInventory session={this.props.session} />
                        ) : (
                          <Redirect to="/dashboard" />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/monthlyboxes"
                      render={() =>
                        god || inventory.admin ? (
                          <MonthlyBoxes session={this.props.session} />
                        ) : (
                          <Redirect to="/dashboard" />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/stock"
                      render={() => <Stock session={this.props.session} />}
                    />
                    <Route
                      exact
                      path="/dashboard/customerlist"
                      render={(props) => (
                        <Customers
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/affiliate"
                      render={(props) => (
                        <AffiliateAnalytics
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/affiliate/affiliate-requests"
                      render={(props) => (
                        <AffiliateFormRequests
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/affiliate/:id"
                      render={(props) => (
                        <AffiliateDetail
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/dashboard/retailer-requests"
                      render={(props) => (
                        <RetailerRequests
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/vet-requests"
                      render={(props) => (
                        <VetRequests session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/customer/:id"
                      render={(props) => (
                        <CustomerDetail
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/customer/:id/course-progress-of-customer"
                      render={(props) => (
                        <CourseProgressOfCustomer
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/consultation"
                      render={(props) =>
                        // god ? (
                        true ? (
                          <Consultation
                            session={this.props.session}
                            history={props.history}
                          />
                        ) : (
                          <Redirect to="/dashboard" />
                        )
                      }
                      // render={(props) => (
                      //   <Consultation
                      //     session={this.props.session}
                      //     history={props.history}
                      //   />
                      // )}
                    />
                    <Route
                      exact
                      path="/dashboard/balance-history"
                      render={(props) => (
                        <BalanceHistory
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/consultation/search"
                      render={(props) =>
                        // god ? (
                        true ? (
                          <ConsultationSearch
                            session={this.props.session}
                            history={props.history}
                          />
                        ) : (
                          <Redirect to="/dashboard" />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/asigned-consultation/:id"
                      render={(props) =>
                        // god || accountManager ? (
                        true ? (
                          <AssignedConsultation
                            session={this.props.session}
                            history={props.history}
                          />
                        ) : (
                          <Redirect to="/dashboard" />
                        )
                      }
                      // render={(props) => (
                      //   <AssignedConsultation
                      //     session={this.props.session}
                      //     history={props.history}
                      //   />
                      // )}
                    />
                    <Route
                      exact
                      path="/dashboard/shippinglist"
                      render={(props) => (
                        <Shippings
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/stock_un"
                      render={() => <StockUn session={this.props.session} />}
                    />
                    <Route
                      exact
                      path="/dashboard/processing"
                      render={() => <Processing session={this.props.session} />}
                    />
                    <Route
                      exact
                      path="/dashboard/processing/all"
                      render={() => (
                        <AllProcessing session={this.props.session} />
                      )}
                    />
                    <Route
                      exact
                      path={"/dashboard/rooms"}
                      render={() => <Rooms />}
                    />
                    {/* <Route  exact path="/dashboard/caninecare/add" render={(props) => <AddBlog session={this.props.session} history={props.history} />}  /> */}
                    {/*            <Route
                      exact
                      path="/dashboard/caninecare/add"
                      render={(props) => (
                        <AddBlogDraft
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    /> */}
                    {/* <Route  exact path="/dashboard/caninecare/edit/:id" render={(props) => <EditBlog session={this.props.session} {...props} />}  /> */}
                    {/*               <Route
                      exact
                      path="/dashboard/caninecare/edit/:id"
                      render={(props) => (
                        <EditBlogDraft
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    /> */}
                    <Route
                      exact
                      path="/dashboard/reports/cancel"
                      render={(props) =>
                        god ? (
                          <Cancellations
                            session={this.props.session}
                            history={props.history}
                          />
                        ) : (
                          <Redirect to="/dashboard" />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/reports/cancel/archive"
                      render={(props) =>
                        god ? (
                          <Archive
                            session={this.props.session}
                            history={props.history}
                          />
                        ) : (
                          <Redirect to="/dashboard" />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/qr-codes"
                      render={(props) => (
                        <QR_Codes
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/glossary"
                      render={(props) => (
                        <Glossary
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/glossary/add"
                      render={(props) => (
                        <GlossaryAdd
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/glossary/edit/:id"
                      render={(props) => (
                        <GlossaryEdit session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/secretshop"
                      render={(props) => (
                        <Secretshop
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/logs"
                      render={(props) => (
                        <Log
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/secretshop/publish-multiple"
                      render={(props) => (
                        <SecretshopPublishMultiple
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/sms-notification"
                      render={(props) => (
                        <MemberMessageList
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    {/* Newsletter email list */}
                    <Route
                      exact
                      path="/dashboard/newsletter_email/"
                      render={(props) => (
                        <NewsletterEmailList
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    {/* Shortleash started */}
                    <Route
                      exact
                      path="/dashboard/newsletter/"
                      render={(props) => (
                        <ShortleashList
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/newsletter/add"
                      render={(props) => (
                        <AddShortleash
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/newsletter/edit/:id"
                      render={(props) => (
                        <EditShortleash
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/questions"
                      render={(props) => (
                        <Questions session={this.props.session} {...props} />
                      )}
                    />

                    <Route
                      exact
                      path="/dashboard/course/questions/quiz"
                      render={(props) => (
                        <Quizzes session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/questions/quiz/add/:quizType"
                      render={(props) => (
                        <Quiz session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/questions/quiz/edit/:id"
                      render={(props) => (
                        <Quiz session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course"
                      render={(props) => (
                        <Courses session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/orders"
                      render={(props) => (
                        <CourseTransactionList
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/workshops"
                      render={(props) => (
                        <Workshops session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/workshops/list"
                      render={(props) => (
                        <WorkshopsList
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/edit/:id"
                      render={(props) => (
                        <Course session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/:id/success"
                      render={(props) => (
                        <CourseSuccessPage
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/edit/:courseId/unit/add"
                      render={(props) => (
                        <Unit session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/edit/:courseId/unit/edit/:unitId"
                      render={(props) => (
                        <Unit session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/edit/:courseId/unit/edit/:unitId/final"
                      render={(props) => (
                        <FinalQuiz session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/quiz_results"
                      render={(props) => (
                        <QuizResults session={this.props.session} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/course-completed"
                      render={(props) => (
                        <CourseCompleted
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/course/add"
                      render={(props) => (
                        <Course session={this.props.session} {...props} />
                      )}
                    />
                    {/* Shortleash ended */}
                    <Route
                      exact
                      path="/dashboard/website_settings"
                      render={(props) => (
                        <WebsiteSettings
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    {/* MemberShip Started */}
                    <Route
                      exact
                      path="/dashboard/membership"
                      render={(props) => (
                        <Membership session={this.props.session} {...props} />
                      )}
                    />
                    {/* MemberShip Ended */}
                    <Route
                      exact
                      path="/dashboard/rewards"
                      render={(props) => (
                        <Rewards session={this.props.session} {...props} />
                      )}
                    />
                    {/* Gift Code Generator */}
                    <Route
                      exact
                      path="/dashboard/gift_code_generate"
                      render={(props) => (
                        <GiftCodeGenerator
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/gift_code_generate/printed"
                      render={(props) => (
                        <GiftCodeGeneratorPrinted
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/gift_code_generate/redeemed"
                      render={(props) => (
                        <GiftCodeGeneratorRedeemed
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/dashboard/barcode-generator"
                      render={(props) => (
                        <BarcodeGenerator
                          session={this.props.session}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/directory-states"
                      render={(props) => (
                        <DirectoryState
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/ticket"
                      render={(props) => (
                        <Ticket
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/ticket/history"
                      render={(props) => (
                        <TicketHistory
                          session={this.props.session}
                          history={props.history}
                        />
                      )}
                    />
                    <Redirect to="/" />
                  </Switch>
                </ResponsiveDrawer>
              </Router>
            </div>
          ) : (
            <div>
              <FirstLoginPass refetch={refetch} session={session} />
            </div>
          )}
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default auth((session) => session && session.activeAdmin)(Dashboard);
