import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@apollo/client";
import { VERIFY_TICKET_NUMBER } from "../../../queries";
import { useSnackbar } from "notistack";

export default function TicketNumberVerify({ refetch }) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [ticketNumber, setTicketNumber] = React.useState("");

  const [verifyTicketNumber, { loading }] = useMutation(VERIFY_TICKET_NUMBER);

  const handleClickOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    setTicketNumber("");
  }, [open]);

  const handleClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  const onVerify = () => {
    verifyTicketNumber({
      variables: {
        ticketNumber: ticketNumber,
      },
    })
      .then((res) => {
        enqueueSnackbar(`User attended successfully`, {
          variant: "success",
        });
        handleClose();
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Verify ticket by number
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xs"}>
        <DialogTitle> Verify ticket by number</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter ticket number</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Ticket Number"
            type="email"
            fullWidth
            variant="standard"
            value={ticketNumber}
            onChange={(e) => {
              setTicketNumber(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={loading} onClick={onVerify}>
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
