import { gql } from "@apollo/client";

const GET_SHIPPINGS = gql`
  query ($status: String!, $page: Int!) {
    getOrdersByTag(status: $status, page: $page) {
      orderId
      orderStatus
      customerEmail
      orderDate
      customerNotes
      shipTo
      amountPaid
    }
  }
`;

const GET_SHIPPINGS_COUNT = gql`
  query ($status: String!) {
    pageCount(status: $status) {
      total
      pages
    }
  }
`;

const GET_SHIPPING = gql`
  query ($id: Int!) {
    getOrder(id: $id)
  }
`;

const GET_SHIPPING_COST_FOR_ADDRESSES = gql`
  mutation GetMultipleShippingRates(
    $userId: String
    $input: MultipleRateInput
  ) {
    getRatesMultiple(userId: $userId, input: $input) {
      addressId
      rate {
        serviceCode
        shipmentCost
        bagsLength
        width
        height
        length
        weight
      }
    }
  }
`;
const SINGLE_ADDRESS = gql`
  query OwnerSingleAddress($addressId: ID!) {
    ownerSingleAddress(addressId: $addressId) {
      id
      name
      address
      address1
      address2
      city
      state
      postal_code
      status
      owner
    }
  }
`;

const ADDRESS_SHIPPING_COST = gql`
  query GetAddressShippingCost($addressId: String, $input: AddressInput) {
    getAddressShippingCost(addressId: $addressId, input: $input) {
      dog {
        name
        id
      }
      rate {
        shipmentCost
      }
    }
  }
`;

const GET_RATE = gql`
  mutation GetShippingRate($input: RateInput) {
    getRate(input: $input) {
      serviceName
      serviceCode
      shipmentCost
      height
      length
      width
      weight
      bagsLength
    }
  }
`;

export {
  GET_SHIPPINGS,
  GET_SHIPPINGS_COUNT,
  GET_SHIPPING,
  GET_SHIPPING_COST_FOR_ADDRESSES,
  ADDRESS_SHIPPING_COST,
  GET_RATE,
  SINGLE_ADDRESS,
};
