import React, { Component, useEffect, useState, useRef } from "react";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import IconButton from "@mui/material/IconButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import {
  GET_SINGLE_AFFILIATE,
  CREATE_USE_POINTS,
  MAKE_AFFILIATE,
  UPDATE_AFFILIATE_POINT,
  DONTCANCELSUBSCRIPTION,
  CREATE_AFFILIATE_SIGNUP_LINK,
} from "../../../queries";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import Edit from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import { Input } from "@mui/material";
import ShippingAddress from "./Components/shippingAddresses";
import ShippingCostsOfDog from "./Components/ShippingCostsOfDog";
import Alert from "@mui/material/Alert";
import { UPDATE_CUSTOMER_USERNAME } from "../../../queries";
import Restrictions from "./Components/Restrictions";
import LinkIcon from "@mui/icons-material/Link";

import EditLocationIcon from "@mui/icons-material/EditLocation";
import Backdrop from "@mui/material/Backdrop";

import UpdateSubProducts from "./Components/UpdateBox";
import RestartSubscription from "./RestartSubscription";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import SubscriptionListItem from "./Components/SubscriptionListItem";
import CreateNewDog from "./Components/CreateNewDog";
import StripeBalance from "./Components/StripeBalance";
import RefLinks from "./Components/refLinks";

const BASE_URL = "https://members.real.dog/affiliate-register?affiliateregister=";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  backdropShippingCalc: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000",
    position: "absolute",
  },
}));

function AffiliateDetail(props) {
  const classes = useStyles();
  // const { id } = props.match.params;
  const { id } = props.match.params;

  const shippingProdId = process.env.REACT_APP_SHIPPING_COST_ID;
  const memberShipId = process.env.REACT_APP_PREMIUM_MEMBERSHIP_ID;
  const { enqueueSnackbar } = useSnackbar();

  const [totalM, setTotalM] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [customerTotalDogs, setCustomerTotalDogs] = useState({});
  const [shippingCost, setShippingCost] = useState();
  const [updatedShippingCost, setUpdatedShippingCost] = useState();
  const [items, setItems] = useState();
  const [subscription, setSubscription] = useState();
  const [openMakeAffiliate, setOpenMakeAffiliate] = useState(false);
  const [openAffiliatePoint, setOpenAffiliatePoint] = useState(false);
  const [affiliatePoint, setAffiliatePoint] = useState(0);
  const { loading, error, data, refetch } = useQuery(GET_SINGLE_AFFILIATE, {
    variables: { id },
  });
  const [fetchAgain, setFechAgain] = useState(0);

  const [userNameInput, setUserNameInput] = useState("");

  // const newPlans =

  const [updateUserNameMutation] = useMutation(UPDATE_CUSTOMER_USERNAME);
  const [createLink, { loading: LinkLoading }] = useMutation(
    CREATE_AFFILIATE_SIGNUP_LINK
  );

  const [type, setType] = useState("Ref");
  const [points, setPoints] = useState(1);
  const [maxPoints, setMaxPoints] = useState(1);
  const [details, setDetails] = useState("");
  const [link, setLink] = useState();
  const [createUsedPoints] = useMutation(CREATE_USE_POINTS);

  const [makeAAffiliate, { loading: LoadingAffiliate }] =
    useMutation(MAKE_AFFILIATE);
  const [updateAffiliatePoint, { loading: LoadingUpdateAffiliate }] =
    useMutation(UPDATE_AFFILIATE_POINT);

  const [openRef, setOpenRef] = useState(false);

  const handleClickOpenRef = () => {
    setOpenRef(true);
  };

  const handleCloseRef = () => {
    setOpenRef(false);
  };

  const onClickCreateLink = () => {
    createLink({
      variables: {
        owner: id,
      },
    })
      .then((responseData) => {
        enqueueSnackbar("Link created successfully.", {
          variant: "success",
        });
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      });
  };

  const onUpdateUserName = () => {
    updateUserNameMutation({
      variables: {
        id: data.getMongoCustomerByID.id,
        username: userNameInput,
      },
    })
      .then((responseData) => {
        enqueueSnackbar("Username Updated Successfully", {
          variant: "success",
        });
        refetch();
      })
      .catch(() => {
        enqueueSnackbar("Username Could not updated", {
          variant: "error",
        });
      });
  };

  const onCreateUsedPoints = () => {
    if (type === "Affiliate" && points < 10) {
      enqueueSnackbar("Only a minimum of $100 can be withdrawn", {
        variant: "error",
      });
      return;
    }
    createUsedPoints({
      variables: {
        input: {
          memberID: data.getMongoCustomerByID.id,
          details: details,
          usedPoint: parseInt(points),
          pointType: type,
        },
      },
    })
      .then((responseData) => {
        enqueueSnackbar("Points Updated Successfully", {
          variant: "success",
        });
        setOpenRef(false);
        refetch();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
  };

  const makeAnAAffiliate = (event) => {
    makeAAffiliate({
      variables: { id: data.getMongoCustomerByID.id },
    })
      .then((responseData) => {
        enqueueSnackbar("Success", { variant: "success" });
        refetch();
      })
      .catch(() =>
        enqueueSnackbar("ERROR: Please contact to member service.", {
          variant: "error",
        })
      );
  };

  const handleUpdateAffiliatePoint = (event) => {
    updateAffiliatePoint({
      variables: { id: data.getMongoCustomerByID.id, point: affiliatePoint },
    })
      .then((responseData) => {
        enqueueSnackbar("Success", { variant: "Updated" });
        refetch();
      })
      .catch(() =>
        enqueueSnackbar("ERROR: Please contact to member service.", {
          variant: "error",
        })
      );
  };

  const handleSelectChange = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    if (data && data.getLinkAdmin) {
      setLink(data.getLinkAdmin.id);
    }
  }, [data]);

  const continueDate = (date) => {
    return (
      <div
        style={{
          background: "#ffca02",
          padding: 5,
          borderRadius: 4,
          color: "#252525",
          width: "fit-content",
        }}
      >
        <PauseCircleFilledIcon
          style={{
            marginBottom: -7,
          }}
        />
        Collection paused <br /> until{" "}
        {`\n${moment.unix(date).format("MMM Do")}`}
      </div>
    );
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Link to="/dashboard/affiliate">
              <Typography color="textPrimary">Affiliate</Typography>
            </Link>
            <Typography color="textPrimary">
              <b>{data.getMongoCustomerByID.username}</b>
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div>
        <Grid container spacing={3} variant="masonry">
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Paper>
                  <MenuList className="badgemenu">
                    <MenuItem>Summary</MenuItem>

                    <Grid item xs={12} className="member-data">
                      {data.getMongoCustomerByID.created_at && (
                        <TextField
                          fullWidth
                          disabled
                          id="outlined-disabled"
                          label="Created At"
                          defaultValue={moment
                            .unix(data.getMongoCustomerByID.created_at)
                            .format("MM/DD/YYYY")}
                          variant="outlined"
                        />
                      )}

                      <TextField
                        disabled
                        fullWidth
                        id="outlined-disabled"
                        label="Username"
                        value={data.getMongoCustomerByID.username}
                        onChange={(e) => setUserNameInput(e.target.value)}
                        variant="outlined"
                      />

                      {data.getMongoCustomerByID.ref && (
                        <Link
                          variant="text"
                          // color="tertiary"
                          // className="redborder"
                          className="textfield-link"
                          size="small"
                          to={`/dashboard/customer/${data.getMongoCustomerByID.ref.id}`}
                        >
                          <TextField
                            fullWidth
                            disabled
                            id="outlined-disabled"
                            label="Referrer"
                            defaultValue={
                              data.getMongoCustomerByID.ref.username
                            }
                            variant="outlined"
                          />
                        </Link>
                      )}
                    </Grid>
                    <Grid item></Grid>
                  </MenuList>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  className={`borderred summarybox affiliatetext ${classes.paper}`}
                  // onClick={handleClickOpenRef}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {`Coins:`}

                  <div style={{ float: "right", display: "flex" }}>
                    {
                      <b>
                        {data.getMongoCustomerByID.wallet.coins &&
                          data.getMongoCustomerByID.wallet.coins}
                      </b>
                    }
                    <InfoIcon
                      style={{
                        marginTop: -2,
                        marginLeft: 10,
                      }}
                    />
                  </div>
                </Paper>
                <Paper className={classes.paper}>
                  Quick Tools
                  <br />
                  <br />
                  <RefLinks
                    id={data.getMongoCustomerByID.id}
                    username={
                      data.getMongoCustomerByID.username
                        ? data.getMongoCustomerByID.username
                        : data.getMongoCustomerByID.name
                    }
                  />
                  {link ? (
                    <Alert
                      icon={<LinkIcon />}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        marginTop: "10px",
                        alignItems: "center",
                      }}
                      action={
                        <div style={{ marginLeft: "-25px" }}>
                          <Button
                            color="inherit"
                            size="small"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${BASE_URL}${link}`
                              );
                            }}
                            endIcon={"copy"}
                          ></Button>
                        </div>
                      }
                    >
                      <Link
                        to={{ pathname: `${BASE_URL}${link}` }}
                        target="_blank"
                        style={{ color: "#fff" }}
                      >{`members.real.dog/affiliate-register?affiliateregister=${link}`}</Link>
                    </Alert>
                  ) : (
                    <Button
                      onClick={() => onClickCreateLink(true)}
                      variant="outlined"
                      fullWidth
                      sx={{ marginTop: "10px" }}
                      disabled={LinkLoading ? true : false}
                    >
                      {LinkLoading ? (
                        <CircularProgress />
                      ) : (
                        "Create SignUp Link"
                      )}
                    </Button>
                  )}
                  {data.getMongoCustomerByID.isAffiliate ? (
                    <Button
                      // onClick={() => setOpenAffiliatePoint(true)}
                      variant="outlined"
                      fullWidth
                      disabled
                      sx={{ marginTop: "10px" }}
                    >
                      DELETE AFFILIATE
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      onClick={() => setOpenMakeAffiliate(true)}
                      sx={{ marginTop: "10px" }}
                    >
                      Make Affiliate
                    </Button>
                  )}
                </Paper>
              </Grid>
              <div>
                <Dialog
                  open={openMakeAffiliate}
                  onClose={() => setOpenMakeAffiliate(false)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Make Affiliate
                  </DialogTitle>
                  <DialogContentText>
                    &nbsp; Are you sure to affiliate{" "}
                    {data.getMongoCustomerByID.name}? &nbsp;
                  </DialogContentText>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenMakeAffiliate(false)}
                      color="primary"
                    >
                      No
                    </Button>
                    <Button onClick={makeAnAAffiliate} color="secondary">
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openAffiliatePoint}
                  onClose={() => setOpenAffiliatePoint(false)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Make Affiliate
                  </DialogTitle>
                  <DialogContentText>
                    &nbsp; Are you sure to affiliate{" "}
                    {data.getMongoCustomerByID.name}? &nbsp;
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="points"
                    label="Points"
                    type="number"
                    value={affiliatePoint}
                    onChange={(e) =>
                      e.target.value > 0
                        ? setAffiliatePoint(parseInt(e.target.value))
                        : setAffiliatePoint(1)
                    }
                    fullWidth
                  />
                  <DialogActions>
                    <Button
                      onClick={() => setOpenAffiliatePoint(false)}
                      color="primary"
                    >
                      No
                    </Button>
                    <Button
                      onClick={handleUpdateAffiliatePoint}
                      color="secondary"
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openRef}
                  onClose={handleCloseRef}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Use Points</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please choose which type of points you want to use.
                    </DialogContentText>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={type}
                        onChange={handleSelectChange}
                        label="Type"
                      >
                        <MenuItem value={"Ref"}>Ref</MenuItem>
                        {data.getMongoCustomerByID.isAffiliate && (
                          <MenuItem value={"Affiliate"}>Affiliate</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    {type !== "Affiliate" ? (
                      <TextField
                        autoFocus
                        margin="dense"
                        id="points"
                        label="Points"
                        type="number"
                        value={points}
                        onChange={(e) =>
                          e.target.value > 0
                            ? setPoints(e.target.value)
                            : setPoints(1)
                        }
                        fullWidth
                      />
                    ) : (
                      <TextField
                        autoFocus
                        margin="dense"
                        id="points"
                        label="Money"
                        type="number"
                        value={points}
                        onChange={(e) =>
                          setPoints(e.target.value > 0 && e.target.value)
                        }
                        fullWidth
                      />
                    )}

                    <TextField
                      autoFocus
                      margin="dense"
                      id="details"
                      label="Details"
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseRef} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={onCreateUsedPoints} color="primary">
                      Accept
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AffiliateDetail;
