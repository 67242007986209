import { gql } from "@apollo/client";

const GET_COURSE_TRANSACTIONS = gql`
  query getCourseTransactions(
    $email: String
    $status: String
    $limit: Int
    $offset: Int
  ) {
    getCourseTransactions(
      email: $email
      status: $status
      limit: $limit
      offset: $offset
    ) {
      data {
        id
        customer {
          id
          email
        }
        courseId {
          id
          title
        }
        addOn {
          id
          name
        }
        admin {
          id
          email
        }
        endDate
        status
        orderId
        createdAt
      }
      meta {
        count
      }
    }
  }
`;
const COURSE_TRANSACTION_SEARCH = gql`
  query getCourseTransactions(
    $email: String
    $status: String
    $limit: Int
    $offset: Int
  ) {
    courseTransactionSearch(
      email: $email
      status: $status
      limit: $limit
      offset: $offset
    ) {
      data {
        _id
        customer {
          email
        }
        admin {
          email
        }
        courseId {
          title
        }
        addOn {
          id
          name
        }
        endDate
        status
        orderId
        createdAt
      }
      meta {
        count
      }
    }
  }
`;

const CANCEL_COURSE = gql`
  mutation CancelCourse($id: ID!, $input: CancelCourseInput) {
    cancelCourse(id: $id, input: $input) {
      status
    }
  }
`;
const COMPLETED_COURSE_CUSTOMER = gql`
  query GetCompletedCourses($offset: Int, $limit: Int, $order: String) {
    getCompletedCourses(offset: $offset, limit: $limit, order: $order) {
      customer {
        id
        name
        email
      }
      course {
        id
        title
        requiresWorkshop
      }
      completedCourse {
        courseId
        completedAt
        completeCode
        certificatePdfUri
        workshopPassed
      }
    }
  }
`;

const GET_COMPLETED_COURSE_IDS = gql`
  query GetCompletedCoursesIDs(
    $offset: Int
    $limit: Int
    $order: String
    $courseId: ID
  ) {
    getCompletedCoursesIDs(
      offset: $offset
      limit: $limit
      order: $order
      courseId: $courseId
    )
  }
`;
const SEARCH_COMPLETED_COURSE_IDS = gql`
  query SearchCompletedCoursesIDs($search: String) {
    searchCompletedCoursesIDs(search: $search)
  }
`;
const GET_COMPLETED_COURSE_LENGTH = gql`
  query GetCompletedCoursesLength {
    getCompletedCoursesLength
  }
`;

const GET_COPLETED_COURSE_BY_ID = gql`
  query GetCompletedCoursesByID($id: ID) {
    getCompletedCoursesByID(id: $id) {
      customer {
        id
        name
        email
      }
      course {
        id
        title
        requiresWorkshop
      }
      completedCourse {
        courseId
        completedAt
        completeCode
        certificatePdfUri
        workshopPassed
        badgeUrl
      }
    }
  }
`;

const WORKSHOPS_LIST = gql`
  query WorkshopList(
    $offset: Int
    $limit: Int
    $filter: inputFilterWorkshopList
  ) {
    getWorkshopList(offset: $offset, limit: $limit, filter: $filter) {
      id
      startTime
      customer {
        email
        name
        phone_number
        username
        premium
        feedrealPremium
        id
      }
      info
      attendance
    }
    getWorkshopsCount(filter: $filter)
  }
`;

const WORKSHOP_ATTENDACE_UPDATE = gql`
  mutation UpdateWorkshopAttendence($id: ID, $attendance: Boolean) {
    updateWorkshopAttendence(id: $id, attendance: $attendance) {
      id
    }
  }
`;
export {
  GET_COURSE_TRANSACTIONS,
  COURSE_TRANSACTION_SEARCH,
  CANCEL_COURSE,
  COMPLETED_COURSE_CUSTOMER,
  WORKSHOPS_LIST,
  GET_COMPLETED_COURSE_IDS,
  SEARCH_COMPLETED_COURSE_IDS,
  GET_COMPLETED_COURSE_LENGTH,
  GET_COPLETED_COURSE_BY_ID,
  WORKSHOP_ATTENDACE_UPDATE,
};
