import React, { Component, useEffect, useState } from "react";
import { useQuery, useMutation, NetworkStatus } from "@apollo/client";
import { GET_QR_CODES, CREATE_QR } from "../../../queries";
import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import QRCode from "qrcode";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";

function QrList(props) {
  const { enqueueSnackbar } = useSnackbar();
  // query get data
  const { subscribeToMore, loading, error, data, refetch } =
    useQuery(GET_QR_CODES);
  const [createQr, { loading: loadingAddP, error: errorCreateProd }] =
    useMutation(CREATE_QR);
  const [qrData, setQrData] = useState([]);
  const [value, setValue] = useState("");
  const [qrimage, setQrimage] = useState();
  const [qrDarkimage, setQrDarkimage] = useState();
  const [qrString, setQRString] = useState();
  const [open, setOpen] = useState(false);
  const [qrOpen, setQROpen] = useState(false);
  const [validate, setValidate] = useState(true);

  useEffect(() => {
    if (data) {
      setQrData(data.qrcodes);
      console.log(qrData);
    }
  }, [data, qrData]);

  const createQRCode = () => {
    // With promises

    QRCode.toDataURL(value, {
      choices: "png",
      quality: 1,
      scale: 10,
      color: {
        dark: "#fff", // Blue dots
        light: "#0000", // Transparent background
      },
    })
      .then((url) => {
        console.log(url);
        setQrimage(url);
      })
      .catch((err) => {
        console.error(err);
      });

    QRCode.toDataURL(value, { choices: "png", quality: 1, scale: 10 })
      .then((url) => {
        console.log(url);
        setQrDarkimage(url);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const addQrCode = () => {
    if (value.includes("https://") && !value.includes(" ")) {
      createQr({
        variables: {
          link: value,
          createdAt: moment().unix(),
        },
      })
        .then(async ({ data }) => {
          handleClose();
          createQRCode();
          qrModalOpen();
          refetch();
          console.log(data);
        })
        .catch((e) => {
          enqueueSnackbar(e.message, {
            variant: "error",
          });
        });
      setValidate(true);
    } else {
      setValidate(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const qrModalOpen = () => {
    setQROpen(true);
  };

  const openQr = (link) => {
    console.log("link", link);
    // With promises
    QRCode.toDataURL(link, {
      choices: "png",
      quality: 1,
      scale: 10,
      color: {
        dark: "#fff", // Blue dots
        light: "#0000", // Transparent background
      },
    })
      .then((url) => {
        setQrimage(url);
      })
      .catch((err) => {
        console.error(err);
      });

    QRCode.toDataURL(link, {
      choices: "png",
      quality: 1,
      scale: 10,
    })
      .then((url) => {
        setQrDarkimage(url);
      })
      .catch((err) => {
        console.error(err);
      });

    qrModalOpen();
  };

  const handleClose = () => {
    setOpen(false);
    setQROpen(false);
  };

  const resetState = () => {
    setValue("");
  };

  const onChangeValue = (e) => {
    if (e.target.value.includes("//")) {
      let val = e.target.value;
      val = val.replace(/(^\w+:|^)\/\//, "https://");
      setValue(val);
    } else {
      setValue(e.target.value);
    }
  };
  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;
  const { permission } = props.session.activeAdmin;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"md"}
      >
        <div>
          <DialogTitle id="form-dialog-title">Create Qr Code</DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              autoFocus
              margin="normal"
              name="name"
              label="Link"
              type="text"
              fullWidth
              value={value}
              onChange={(e) => onChangeValue(e)}
            />
            {!validate && (
              <Grid className="warningbox" sx={{ margin: "0px !important" }}>
                Link must start with "https://".Please check your link.
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={addQrCode} color="secondary">
              Create
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={qrOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <div>
          <DialogTitle id="form-dialog-title">QR CODE</DialogTitle>
          <DialogContent>
            <div className="qrbox">
              <center>
                <img src={qrimage} />
                <img src={qrDarkimage} />
              </center>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <QrListUI
        permission={permission}
        qrData={qrData}
        refetch={refetch}
        openQr={openQr}
        handleClickOpen={handleClickOpen}
      />
    </div>
  );
}

export class QrListUI extends Component {
  componentDidMount() {
    this.props.refetch();
  }
  render() {
    const { permission } = this.props;
    const god = permission.god ? permission.god.admin : false;
    const qr = permission.author
      ? permission.author.qr
        ? permission.author.qr
        : false
      : false;
    return (
      <div>
        <div className="pagetop">
          <div className="left">
            <Breadcrumbs aria-label="breadcrumb">
              <a color="inherit" href="/">
                Dashboard
              </a>
              <Typography color="textPrimary">Qr Code List</Typography>
            </Breadcrumbs>
          </div>
          {god || qr ? (
            <div className="right">
              <Button
                variant="outlined"
                color="secondary"
                onClick={this.props.handleClickOpen}
              >
                Add Qr Code
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
        <ul>
          {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Link</TableCell>

                    {god ? <TableCell align="right"></TableCell> : ""}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.qrData.map((qr) => (
                    <TableRow
                      key={qr.id}
                      //className={canine.locked ? "disabled" : "active"}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        {qr.link}
                        {
                          // {canine.locked ? (
                          //   <div className="authorlocked">
                          //     {canine.admin.name} is editing now...
                          //   </div>
                          // ) : (
                          //   ""
                          // )}
                        }
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => this.props.openQr(qr.link)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </ul>
      </div>
    );
  }
}

export default QrList;
