import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import { CUSTOMER_ADDRESSES, ONE_TIME_ORDER } from "../../../../../queries";
import { useMutation, useQuery } from "@apollo/client";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useSnackbar } from "notistack";
import { States } from "../constants";
import PaymentComponent from "./Payment";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000095",
    position: "absolute",
  },
  loader: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000095",
    position: "absolute",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
}));

const steps = [
  {
    label: "One Time Order Info",
  },
  {
    label: "Payment",
  },
];

export default function SecondTabSteps({
  customerMongoId,
  stripeCustomer,
  email,
  id,
  close,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [serviceCode, setServiceCode] = useState("");

  const [stripePaymentSource, setStripePaymentSource] = useState("");
  const [rdcUse, setRDCUse] = useState(false);

  const [makeOneTimeOrderMutation, { loading }] = useMutation(ONE_TIME_ORDER);

  useEffect(() => {
    if (
      Object.keys(stripeCustomer).length !== 0 &&
      stripeCustomer.default_source?.id
    ) {
      setStripePaymentSource(stripeCustomer.default_source.id);
    } else {
      enqueueSnackbar(`Customer has no default payment source`, {
        variant: "error",
      });
    }
  }, [stripeCustomer]);

  const handleServiceCode = (event) => {
    setServiceCode(event.target.value);
  };
  const handleNext = () => {
    if (activeStep === 0 && !firstStepValid()) {
      enqueueSnackbar(`First step is not valid`, {
        variant: "error",
      });
      return;
    }
    if (activeStep === steps.length - 1) {
      makeOneTimeOrder();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [notes, setNotes] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [sendShipStation, setSendShipStation] = useState(true);

  const [paymentNote, setPaymentNote] = useState("");

  const [address, setAddress] = useState({});

  const handleChange = (event) => {
    setAddress(event.target.value);
  };

  const firstStepValid = () => {
    let flag = true;
    if (
      notes.length === 0 ||
      price <= 0 ||
      (sendShipStation && address.length === 0) ||
      (sendShipStation && serviceCode.length === 0)
    ) {
      flag = false;
    }
    return flag;
  };

  useEffect(() => {
    let currentAddress = States.find((item) => item.value === address.state);
    if (currentAddress && currentAddress.country === "CA") {
      setServiceCode("usps_first_class_mail_international");
    } else {
      setServiceCode("");
    }
  }, [address]);

  const makeOneTimeOrder = () => {
    let orderInput = {
      notes: notes,
      description: description,
      rdcUse: rdcUse,
      price: parseFloat(price),
      shippingPrice: parseFloat(shippingPrice),
      totalPrice: parseFloat(parseFloat(price) + parseFloat(shippingPrice)),
      sendToSS: sendShipStation,
      customerStripeId: stripeCustomer.id,
      source: stripePaymentSource,
      serviceCode: serviceCode,
      email: email,
      owner: id,
    };

    if (sendShipStation) {
      orderInput = {
        ...orderInput,
        address: {
          name: address.name,
          address1: address.address,
          address2: address.address2,
          state: address.state,
          city: address.city,
          postal_code: address.postal_code,
        },
        address: {
          name: address.name,
          address1: address.address,
          address2: address.address2,
          state: address.state,
          city: address.city,
          postal_code: address.postal_code,
        },
      };
    }

    makeOneTimeOrderMutation({ variables: { input: orderInput } })
      .then((res) => {
        enqueueSnackbar(`Order created successfully`, {
          variant: "success",
        });
        close();
      })
      .catch((err) => {
        enqueueSnackbar(`Error could not created`, {
          variant: "error",
        });
      });
  };

  return (
    <Box>
      {loading && (
        <Grid
          className={classes.loader}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography align="center">
            Please wait
            <br />
            <CircularProgress color="primary" />
          </Typography>
        </Grid>
      )}
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent className={classes.container}>
              {activeStep === 0 && (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      label="Notes (Required)"
                      value={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      label="Description (Optional)"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Price (Required)"
                      value={price}
                      onChange={(e) => {
                        setPrice(
                          e.target.value
                          // isNaN(parseInt(e.target.value.replace(/\D/g, "")))
                          //   ? ""
                          //   : parseInt(e.target.value.replace(/\D/g, ""))
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Shipping Price (Optional)"
                      value={shippingPrice}
                      onChange={(e) => {
                        setShippingPrice(
                          e.target.value
                          // isNaN(parseInt(e.target.value.replace(/\D/g, "")))
                          //   ? ""
                          //   : parseInt(e.target.value.replace(/\D/g, ""))
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      label="Total"
                      disabled
                      value={parseFloat(
                        parseFloat(shippingPrice) + parseFloat(price)
                      ).toFixed(2)}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={sendShipStation}
                            onClick={() => setSendShipStation(!sendShipStation)}
                            color={"secondary"}
                          />
                        }
                        label="Send to ShipStation"
                      />
                    </FormGroup>
                  </Grid>
                  {sendShipStation && (
                    <Grid item sm={12}>
                      <AddressList
                        customerMongoId={customerMongoId}
                        address={address}
                        handleChange={handleChange}
                      />
                    </Grid>
                  )}
                  {sendShipStation && (
                    <Grid item sm={6}>
                      <ServiceCode
                        serviceCode={serviceCode}
                        handleServiceCode={handleServiceCode}
                        address={address}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              {activeStep === 1 && (
                <PaymentComponent
                  sources={stripeCustomer.sources}
                  stripePaymentSource={stripePaymentSource}
                  setStripePaymentSource={setStripePaymentSource}
                  price={price}
                  shippingPrice={shippingPrice}
                  notes={paymentNote}
                  setNotes={setPaymentNote}
                  rdcUse={rdcUse}
                  setRDCUse={setRDCUse}
                />
              )}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}

export const AddressList = ({ customerMongoId, address, handleChange }) => {
  const { data, loading, error } = useQuery(CUSTOMER_ADDRESSES, {
    variables: {
      owner: customerMongoId,
    },
    fetchPolicy: "no-cache",
  });

  return (
    <div>
      <TextField
        fullWidth
        select
        label="Shipping Address"
        value={address}
        onChange={handleChange}
        helperText="Please select Shipping Address"
      >
        {/* {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))} */}

        {data?.ownerAddress.map((address, index) => {
          const labelId = `checkbox-list-label-${address}`;
          return (
            <MenuItem key={address.id} value={address}>
              <ListItemText
                id={labelId}
                primary={`${index + 1}. ${address.name} / ${
                  address.address
                } / ${address.city} / ${address.state} / ${
                  address.postal_code
                }`}
              />
            </MenuItem>
          );
        })}
      </TextField>
    </div>
  );
};

//usps_first_class_mail
//usps_priority_mail
//usps_first_class_mail_international

const canada = [
  {
    value: "usps_first_class_mail_international",
    label: "CANADA",
  },
];
const amerika = [
  {
    value: "usps_priority_mail",
    label: "SMALL (9 x 9 x 3)",
  },
  {
    value: "usps_first_class_mail",
    label: "BIG (9 x 9 x 4)",
  },
];

const ServiceCode = ({ serviceCode, handleServiceCode, address }) => {
  return (
    <div>
      <TextField
        fullWidth
        id="outlined-select-serviceCode"
        select
        label="Select"
        value={serviceCode}
        onChange={handleServiceCode}
        helperText="Please select box"
        disabled={
          serviceCode === "usps_first_class_mail_international" ||
          Object.keys(address).length === 0
        }
      >
        {serviceCode === "usps_first_class_mail_international"
          ? canada.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          : amerika.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
      </TextField>
    </div>
  );
};
