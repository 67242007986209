import { Backdrop, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000025",
    position: "absolute",
  },
  /*   backdropFull: {
    zIndex: theme.zIndex.drawer + 1,
    width: "100%",
    height: "100%",
    color: "#fff",
    backgroundColor: "#00000025",
  },
  backdropDark: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000e0",
    position: "absolute",
  }, */
}));

const LinearLoading = (props) => {
  const { open, boxSX } = props;
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Box sx={{ ...(boxSX && boxSX), width: "90%" }}>
        <LinearProgress />
      </Box>
    </Backdrop>
  );
};

export default LinearLoading;
