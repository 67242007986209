import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import InfoIcon from "@mui/icons-material/Info";
import { useQuery } from "@apollo/client";
import { CANCELATION_STATS } from "../../../../../queries";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CancelationStats = ({ paper }) => {
  const [total, setTotal] = useState(0);
  return (
    <Paper className={paper} style={{ position: "relative" }}>
      {" "}
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <CancelatonInfoDialog setTotal={(data) => setTotal(data)} />{" "}
      </div>
      <p>
        {" "}
        Cancellation (Today) <span>{total}</span>
      </p>
    </Paper>
  );
};

function CancelatonInfoDialog({ setTotal = () => {} }) {
  const { loading, data, error } = useQuery(CANCELATION_STATS);

  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [reasonList, setReasonList] = useState([]);
  const [avarage, setAvarage] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data) {
      console.log(data);
      let copy = JSON.parse(JSON.stringify(data.cancelationStats.dayCounts));
      setReasonList(data.cancelationStats.reasonCounts);
      console.log(reasonList);
      setList(copy);
    }
  }, [loading, data, error, reasonList]);
  useEffect(() => {
    let total = list.map((item) => item.count).reduce((a, b) => a + b, 0);
    setTotal(list[list.length - 1]?.count || 0);
    setAvarage(total / list.length);
  }, [list, setTotal]);
  const data1 = {
    labels: list.map((item) => item.day),
    datasets: [
      {
        label: "Cancellations",
        data: list.map((item) => item.count),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Avarage",
        data: list.map(() => parseInt(avarage)),
        borderColor: "black",
        backgroundColor: "black",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Cancellation (All)",
      },
    },
  };

  return (
    <div>
      <Button
        size="small"
        onClick={handleClickOpen}
        style={{
          fontSize: 10,
        }}
      >
        Detail <InfoIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Line options={options} data={data1} />
            <Typography
              sx={{ padding: "8px 0px", fontWeight: "600", fontSize: ".8em" }}
            >
              These stats only reflect data between the dates above{" "}
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "#131519",
                "& .MuiTableRow-root": {
                  borderBottom: "2px solid #21242a !important",
                },
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Reason</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reasonList.map((reason, index) => {
                    return (
                      <TableRow
                        key={"reason-key-" + index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {reason.reason}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {reason.count}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CancelationStats;
