import React, { Component, useEffect, useState, useRef } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_SMS_LOGS,
  GET_NOTIFICATION_COUNT,
  SEND_SMS_NOTIFICATION,
} from "../../../queries";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReportIcon from "@mui/icons-material/Report";
import { useSnackbar } from "notistack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MESSAGE_TYPES } from "../../../globals";
import TablePagination from "@mui/material/TablePagination";

function MemberMessageList(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { permission } = props.session.activeAdmin;
  const [msgType, setMsgType] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [
    getNotificationCount,
    { data: smsListData, loading: smsListLoading, error: smsListError },
  ] = useLazyQuery(GET_NOTIFICATION_COUNT, {
    variables: {
      to: msgType,
    },
  });
  const { data, loading, refetch, fetchMore } = useQuery(GET_SMS_LOGS, {
    variables: {
      limit: rowsPerPage,
      offset: 0,
    },
  });
  const [confirm, setConfirm] = useState();
  const [messageText, setMessageText] = useState(
    "🚨🐶 Secret Shop Drop! Early access for members starts at 12pm PST today. Shop here www.real.dog/secretshop"
  );

  const handleChange = (event) => {
    setMsgType(event.target.value);

    getNotificationCount(event.target.value);
  };
  const [openCreate, setOpenCreate] = useState(false);

  const [loadingProcessing, setLoadingProcess] = useState(false);
  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.smslogs.length,
        limit: rowsPerPage,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [
    sendMessageMutation,
    { loading: messageLoading, data: messageData, error: messageError },
  ] = useMutation(SEND_SMS_NOTIFICATION);

  const onSendMessage = () => {
    sendMessageMutation({
      variables: {
        body: messageText,
        to: msgType,
      },
    })
      .then(() => {
        enqueueSnackbar(`Message sent succesfully`, {
          variant: "success",
        });
        setLoadingProcess(false);
        refetch();
      })
      .catch(() => {
        setLoadingProcess(false);
        enqueueSnackbar(`Message coult not sent`, {
          variant: "error",
        });
      });
  };
  if (
    (!permission.secretshop || !permission.secretshop.admin) &&
    !permission.god?.admin
  )
    return <div>Not Authorized</div>;
  if (loading)
    return (
      <div>
        <LoadingProcess open={true} />
      </div>
    );
  if (smsListError) return <div>Error.</div>;
  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">SMS Notification</Typography>
          </Breadcrumbs>
        </div>
        <div className="right">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpenCreate(true)}
          >
            Send Notification
          </Button>
        </div>
      </div>
      <LoadingProcess open={loadingProcessing} />
      <Dialog
        open={openCreate}
        sx={{ ["& MuiPaper-root"]: { width: "400px" } }}
      >
        <DialogTitle>{"Send SMS Notification"}</DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          <TextField
            id="outlined-multiline-static"
            label="Message"
            multiline
            rows={4}
            variant="outlined"
            placeholder="Secret shop alert message"
            fullWidth
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
          <FormControl fullWidth sx={{ marginTop: "10px" }}>
            <InputLabel id="demo-simple-select-label">
              Select Audience
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={msgType}
              label="Audience"
              onChange={handleChange}
            >
              {Object.keys(MESSAGE_TYPES).map((key, index) => {
                return (
                  <MenuItem value={MESSAGE_TYPES[key]}>
                    {MESSAGE_TYPES[key].replace("_", " ").toUpperCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button
            size="large"
            variant="outlined"
            disabled={!msgType || smsListLoading}
            fullWidth
            sx={{ marginTop: "10px" }}
            endIcon={<SendIcon color="secondary">send</SendIcon>}
            onClick={() => {
              if (messageText.length === 0) {
                enqueueSnackbar(`Message cannot be empty`, {
                  variant: "error",
                });
              } else {
                setConfirm(true);
              }
            }}
          >
            send{" "}
            {smsListLoading && (
              <CircularProgress
                sx={{
                  width: "20px !important",
                  height: "20px !important",
                }}
              />
            )}
            {smsListData &&
              smsListData.getNotificationCount &&
              smsListData.getNotificationCount}
          </Button>
          {confirm && (
            <ConfirmDialogue
              isOpen={confirm}
              setOpen={setConfirm}
              action={() => {
                setLoadingProcess(true);
                onSendMessage();
                setConfirm(false);
              }}
              message={`This message will be sent to <b>${
                smsListData && smsListData.getNotificationCount
              } members</b>. Click on <b>Yes</b> to send immediately.`}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreate(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Grid direction="row">
        <Grid item md={12} lg={12}>
          {data && (
            <ListUI
              {...data}
              permission={permission}
              loading={loading}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              refetch={refetch}
              history={props.history}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const ConfirmDialogue = ({ isOpen, setOpen, action, message }) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" align="center">
          Are you sure? <ReportIcon color="warning" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            No
          </Button>
          <Button onClick={action} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function DataDialog({ open, setOpen, data }) {
  const columns = [
    { field: "address", headerName: "Phone", flex: 1, sortable: false },
    { field: "status", headerName: "Status", flex: 1, sortable: false },
  ];
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth fullScreen>
      <DialogTitle>Message Status</DialogTitle>
      <DialogContent>
        <DataGrid
          rows={data}
          getRowId={(r) => r.address}
          columns={columns}
          componentsProps={{
            columnMenu: { background: "red", counter: data.length },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const LoadingProcess = ({ open, handleClose }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const ListUI = (props) => {
  const [open, setOpen] = useState(false);
  const [bindingData, setBindingData] = useState();
  const countStatus = (log) => {
    const counts = {};
    const statuses = [...new Set(log.bindings.map((b) => b.status))];
    statuses.map(
      (status) =>
        (counts[status] = log.bindings.filter(
          (b) => b.status === status
        ).length)
    );
    return counts;
  };

  const openDataDialog = () => {
    setOpen(true);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
          <ul>
            {
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sent Date (PST)</TableCell>
                      <TableCell align="left">Admin</TableCell>
                      <TableCell align="left">Message</TableCell>
                      <TableCell align="left">Audience</TableCell>
                      <TableCell align="right">Total</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.smslogs && props.smslogs.length > 0 ? (
                      props.smslogs
                        .slice(
                          props.page * props.rowsPerPage,
                          props.page * props.rowsPerPage + props.rowsPerPage
                        )
                        .map((log) => {
                          return (
                            <TableRow
                              key={log.nid}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {moment
                                  .unix(log.created)
                                  .format("MM/DD/YYYY h:mm:ss A z")}
                              </TableCell>
                              <TableCell align="left">
                                {log.admin && log.admin.name}
                              </TableCell>
                              <TableCell align="left">{log.message}</TableCell>
                              <TableCell align="left">
                                {log.audience &&
                                  log.audience.replace("_", " ").toUpperCase()}
                              </TableCell>
                              <TableCell align="left">
                                {log.bindings.length}
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  onClick={() => {
                                    openDataDialog();
                                    setBindingData(log.bindings);
                                    props.refetch();
                                  }}
                                >
                                  <VisibilityIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </ul>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.smslogsCount ? props.smslogsCount : 0}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
      {open && <DataDialog data={bindingData} open={open} setOpen={setOpen} />}
    </div>
  );
};

export default MemberMessageList;
