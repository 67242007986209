import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import AdapterDateFns from "@date-io/date-fns";
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import FormGroup from "@mui/material/FormGroup";

import {
  CONFIRM_SCHEDULE,
  CREATE_APPOINTMENT,
  GET_AVAILABLE_SUPPORT_MEMBERS,
  GET_SUPPORT_MEMBERS,
  GET_SUPPORT_MEMBER_SCHEDULE,
  GET_ACCOUNT_MANAGER,
  CREATE_SCHEDULE_FROM_PRESET,
} from "../../../../../queries";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import Backdrop from "@mui/material/Backdrop";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SelectDateForSchedule from "../selectDateForSchedule";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000",
    position: "absolute",
  },
}));

const labels = [
  "09:00-09:20",
  "09:30-09:50",
  "10:00-10:20",
  "10:30-10:50",
  "11:00-11:20",
  "11:30-11:50",
  "12:00-12:20",
  "12:30-12:50",
  "13:00-13:20",
  "13:30-13:50",
  "14:00-14:20",
  "14:30-14:50",
  "15:00-15:20",
  "15:30-15:50",
  "16:00-16:20",
  "16:30-16:50",
  "17:00-17:20",
  "17:30-17:50",
  "18:00-18:20",
  "18:30-18:50",
];

function getSteps() {
  return ["Select Support Member", "Create Schedule"];
}

export default function VerticalLinearStepper({
  close,
  memberId,
  refetchUsers,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [dateList, setDateList] = useState([
    { date: new Date(), schedule: "" },
  ]);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [times, setTimes] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const { loading: supportLoading, data: supportMemberData } =
    useQuery(GET_ACCOUNT_MANAGER);
  const [createScheduleMutation, { loading: createScheduleMutationLoading }] =
    useMutation(CREATE_SCHEDULE_FROM_PRESET);
  // const [
  //   fetchAvailableMembers,
  //   { loading: supportLoading, data: supportMemberData, error: supportError },
  // ] = useLazyQuery(GET_AVAILABLE_SUPPORT_MEMBERS);
  const [selectedSchema, setSelectedSchema] = useState({});
  const [
    fetchSumpportMemberSchedule,
    { loading: scheduleLoading, data: scheduleData, error: scheduleError },
  ] = useLazyQuery(GET_SUPPORT_MEMBER_SCHEDULE, {
    variables: {
      supportMemberId: memberId,
    },
    fetchPolicy: "no-cache",
  });
  const [open, setOpen] = React.useState(false);
  // const [createSchedule, { loading, data, error }] =
  //   useMutation(CREATE_APPOINTMENT);
  const [confirmSchedule, { loading, data, error }] =
    useMutation(CONFIRM_SCHEDULE);
  const [selectedSupportMember, setSelectedSupportMember] = useState({});

  useEffect(() => {
    if (Object.keys(selectedSupportMember).length !== 0) {
      fetchSumpportMemberSchedule({
        variables: {
          supportMemberId: selectedSupportMember.id,
        },
      });
    }
  }, [selectedSupportMember]);

  const handleNext = () => {
    if (activeStep == 0 && Object.keys(selectedSupportMember).length === 0) {
      enqueueSnackbar("You need to choose STM first", {
        variant: "info",
      });
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onClickCreate = () => {
    // console.log({
    //   accountManagerId: selectedSupportMember.id,
    //   input: dateList.map((item) => {
    //     return {
    //       date: item.date,
    //       preset: item.schedule,
    //     };
    //   }),
    // });
    // return;
    createScheduleMutation({
      variables: {
        accountManagerId: selectedSupportMember.id,
        input: dateList.map((item) => {
          return {
            date: item.date,
            preset: item.schedule,
          };
        }),
      },
    })
      .then(() => {
        enqueueSnackbar("Appointment Schedule Created", { variant: "Success" });
        refetchUsers();
        close();
      })
      .catch((err) => {
        console.log("ERRO");
        console.log(err);
        enqueueSnackbar("Appointment Schedule Could not Created", {
          variant: "error",
        });
      });
    return;
    confirmSchedule({
      variables: {
        input: scheduleData.getSupportMemberSchedules.map((item) => {
          return {
            supportMemberId: memberId,
            date: item.period,
          };
        }),
      },
    })
      .then(() => {
        enqueueSnackbar("Appointment Schedule Created", { variant: "Success" });
        refetchUsers();
      })
      .catch((err) => {
        console.log("ERRO");
        console.log(err);
        enqueueSnackbar("Appointment Schedule Could not Created", {
          variant: "error",
        });
      });
    close();
  };

  useEffect(() => {
    fillTime();
    // fetchAvailableMembers({
    //   variables: {
    //     date: selectedDate,
    //   },
    // });
  }, [selectedDate]);

  const fillTime = () => {
    const originOfDay = moment(
      moment(selectedDate).format("MM-DD-YYYY")
    ).unix();
    const nineHour = 3600 * 9;
    const halfHour = 1800;
    let schedule = [];
    for (let i = 0; i < 20; i++) {
      schedule.push({
        dateTime: originOfDay + nineHour + i * halfHour,
        selected: true,
      });
    }
    setTimes(schedule);
  };
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {activeStep === 0 && (
                <>
                  <CustomizedMenus
                    members={supportMemberData?.accountManagers || []}
                    selectedSupportMember={selectedSupportMember}
                    setSelectedSupportMember={setSelectedSupportMember}
                  />
                </>
              )}

              {activeStep === 1 && (
                <SelectDateForSchedule
                  dateList={dateList}
                  setDateList={setDateList}
                />
              )}

              <div className={classes.actionsContainer}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant="text"
                    color="secondary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <ConfirmDialog
                      onClickCreate={onClickCreate}
                      open={open}
                      setOpen={setOpen}
                      disabled={dateList[dateList.length - 1].schedule === ""}
                    />
                  ) : (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  )}
                </Grid>
              </div>
            </StepContent>
          </Step>
        ))}
        <Backdrop
          className={classes.backdrop}
          open={supportMemberData?.accountManagers.length === 0}
        >
          <Typography>There Is no Request Now </Typography>
        </Backdrop>
        <Backdrop
          className={classes.backdrop}
          open={createScheduleMutationLoading}
        >
          <Typography>Please wait... </Typography>
        </Backdrop>
      </Stepper>
    </div>
  );
}

const StyledMenu = withStyles({
  paper: {},
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export const CustomizedMenus = ({
  members,
  selectedSupportMember,
  setSelectedSupportMember,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      >
        {members.map((value, index) => {
          const labelId = `checkbox-list-secondary-label-${value}`;
          return (
            <ListItem
              key={value}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={(e) => {
                    e.stopPropagation();
                    setSelectedSupportMember(value);

                    // handleToggle(index);
                  }}
                  checked={value.id === selectedSupportMember.id}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar alt={`Avatar n°${value + 1}`} src={value.img} />
                </ListItemAvatar>
                <ListItemText id={labelId} primary={`${value.name}`} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

const ConfirmDialog = ({ onClickCreate, open, setOpen, disabled }) => {
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleClickOpen}
        disabled={disabled}
      >
        Confirm
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to confirm schedule"}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              onClickCreate();
              handleClose();
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
