import { useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Link, useHistory } from "react-router-dom";
import { WORKSHOPS_LIST } from "../../../queries/Course";
import UpdateAttendance from "./UpdateAttendance";
import FilterByDate from "./filterByDate";
import { Chip } from "@mui/material";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

function Customers(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");

  const isInitialMount = useRef(true);

  const { subscribeToMore, loading, error, data, refetch, fetchMore } =
    useQuery(WORKSHOPS_LIST, {
      variables: {
        offset: 0,
        limit: rowsPerPage,
        order: sortOrder,
      },
    });
  console.log(data);
  useEffect(() => {
    refetch();
  }, [refetch, rowsPerPage, sortOrder]);

  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.getWorkshopList.length,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };

  const sortCreatedDate = (event) => {
    setSortOrder(event.target.value);
    refetch({
      variables: {
        order: event.target.value,
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const filterByDate = (dates) => {
    setPage(0);
    refetch({
      filter: {
        start: dates.start,
        end: dates.end,
      },
    });
  };
  const dateFilterUnset = () => {
    window.location.reload(true);
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  return (
    <CustomerUI
      {...data}
      history={props.history}
      rowsPerPage={rowsPerPage}
      page={page}
      sortOrder={sortOrder}
      sortCreatedDate={sortCreatedDate}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      refetch={refetch}
      filterByDate={filterByDate}
      dateFilterUnset={dateFilterUnset}
    />
  );
}

const CustomerUI = (props) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Link color="inherit" to="/dashboard/workshops">
              Workshops
            </Link>
            <Typography color="textPrimary">List</Typography>
          </Breadcrumbs>
        </div>
        <div className="right"></div>
      </div>

      <ul>
        {
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">RDM</TableCell>
                  <TableCell>Attendance</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  {/* <TableCell align="left">Phone</TableCell> */}
                  <TableCell align="left">Username</TableCell>
                  <TableCell align="left">
                    Workshop Schedule{" "}
                    <FilterByDate
                      onFilterDate={(data) => {
                        props.filterByDate(data);
                      }}
                      onDateFilterUnset={props.dateFilterUnset}
                    />{" "}
                  </TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.getWorkshopList
                  .slice(
                    props.page * props.rowsPerPage,
                    props.page * props.rowsPerPage + props.rowsPerPage
                  )
                  .map((workshop) => {
                    return (
                      <TableRow key={workshop.id}>
                        <TableCell component="th" scope="row">
                          {workshop.customer.premium === 1 ? (
                            <Tooltip title={"Real Dog Membership"}>
                              <StarIcon
                                style={{
                                  color: "#ffc107",
                                }}
                              />
                            </Tooltip>
                          ) : workshop.customer.feedrealPremium ? (
                            <Tooltip title={"Feedreal Membership"}>
                              <StarHalfIcon
                                style={{
                                  color: "#ffc107",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <StarBorderIcon />
                          )}
                        </TableCell>
                        <TableCell>
                          {workshop.info === "rescheduled" ? (
                            <Chip label="rescheduled" />
                          ) : workshop.info === "cancelled" ? (
                            <Chip label="cancelled" />
                          ) : workshop.attendance === false ? (
                            <DisabledByDefaultIcon color="error" />
                          ) : (
                            <UpdateAttendance
                              workshop={workshop}
                              customer={workshop.customer}
                              refetch={props.refetch}
                            />
                          )}
                        </TableCell>

                        <TableCell component="th" align="left" scope="row">
                          {workshop.customer.name}
                        </TableCell>
                        <TableCell component="th" align="left" scope="row">
                          {workshop.customer.email}
                        </TableCell>
                        {/* <TableCell component="th" align="left" scope="row">
                          {workshop.customer.phone_number}
                        </TableCell> */}
                        <TableCell component="th" align="left" scope="row">
                          {workshop.customer.username}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {moment(parseInt(workshop.startTime) * 1000).format(
                            "MM/DD/YYYY"
                          )}
                        </TableCell>
                        <TableCell component="th" align="left" scope="row">
                          <Button
                            className="redborder"
                            variant="outlined"
                            onClick={() => {
                              history.push(
                                `/dashboard/customer/${workshop.customer.id}`
                              );
                            }}
                            size="small"
                          >
                            <b className="redborder">Details</b>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.getWorkshopsCount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      </ul>
    </div>
  );
};

export default Customers;
