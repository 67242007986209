import React, { Component, useEffect, useState, useRef } from "react";
import { useQuery } from "@apollo/client";

import { GET_LOG_LIST } from "../../../queries";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import LogSearch from "./components/LogSearch";

function Logs(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");

  const isInitialMount = useRef(true);

  const { loading, error, data, refetch, fetchMore } = useQuery(GET_LOG_LIST, {
    variables: {
      offset: 0,
      limit: rowsPerPage,
      sort: sortOrder,
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, page, rowsPerPage, sortOrder]);

  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.logs.length,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };

  const sortCreatedDate = (event) => {
    setSortOrder(event.target.value);
    refetch({
      variables: {
        order: event.target.value,
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  return (
    <LogUI
      {...data}
      history={props.history}
      rowsPerPage={rowsPerPage}
      page={page}
      totalLogs={data.totalLogs}
      sortOrder={sortOrder}
      sortCreatedDate={sortCreatedDate}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      refetch={refetch}
    />
  );
}

export class LogUI extends Component {
  componentDidMount() {
    this.props.refetch();
  }

  render() {
    return (
      <div>
        <div className="pagetop">
          <div className="left">
            <Breadcrumbs aria-label="breadcrumb">
              <a color="inherit" href="/">
                Dashboard
              </a>
              <Typography color="textPrimary">Logs</Typography>
            </Breadcrumbs>
          </div>
          <div className="right"></div>
        </div>
        <div>
          <LogSearch {...this.props} />
        </div>
        <ul>
          {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Date</TableCell>
                    <TableCell>Query</TableCell>
                    <TableCell>Admin</TableCell>
                    <TableCell align="left">Variables</TableCell>
                    <TableCell align="left">Response</TableCell>
                    <TableCell align="left">Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.logs
                    .slice(
                      this.props.page * this.props.rowsPerPage,
                      this.props.page * this.props.rowsPerPage +
                        this.props.rowsPerPage
                    )
                    .map((log) => {
                      return (
                        <TableRow key={log.id}>
                          <TableCell component="th" scope="row">
                            {moment(parseInt(log.created_at) * 1000).format(
                              "MM/DD/YYYY"
                            )}
                          </TableCell>
                          <TableCell component="th" align="left" scope="row">
                            {log.query}
                          </TableCell>
                          <TableCell component="th" align="left" scope="row">
                            {log.email}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          }
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={this.props.totalLogs}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            onPageChange={this.props.handleChangePage}
            onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          />
        </ul>
      </div>
    );
  }
}

export default Logs;
