import React, { Component, useEffect, useState, useRef } from "react";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  InMemoryCache,
  useLazyQuery
} from "@apollo/client";

import {
  GET_SHIPPINGS,
  GET_ACTIVE_ADMIN,
  GET_SHIPPINGS_COUNT,
  GET_SHIPPING
} from "../../../queries";
import Tooltip from "@mui/material/Tooltip";
import { ShippingDetails } from "../Modals/ShippingDetails";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import LinearProgress from "@mui/material/LinearProgress";
import Autocomplete from "@mui/material/Autocomplete";
import MenuList from "@mui/material/MenuList";
import SendIcon from "@mui/icons-material/Send";
import ListItemIcon from "@mui/material/ListItemIcon";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TimerIcon from "@mui/icons-material/Timer";
import PauseIcon from "@mui/icons-material/Pause";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import BlockIcon from "@mui/icons-material/Block";
import CommentIcon from "@mui/icons-material/Comment";
import NotesIcon from "@mui/icons-material/Notes";
import { makeStyles } from "@mui/styles";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary
  },
  button: {
    margin: theme.spacing(1)
  },
  customWidth: {
    maxWidth: 500
  },
  noMaxWidth: {
    maxWidth: "none"
  }
}));

function Shippings(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [shippingId, setShippingId] = useState(0);
  const [open, setOpen] = useState(false);

  const isInitialMount = useRef(true);

  const {
    subscribeToMore,
    loading,
    error,
    data,
    refetch,
    fetchMore
  } = useQuery(GET_SHIPPINGS, {
    variables: {
      status: "awaiting_shipment",
      page: page + 1
    }
  });

  const { loading: loadingPage, data: dataPage } = useQuery(
    GET_SHIPPINGS_COUNT,
    {
      variables: {
        status: "awaiting_shipment"
      }
    }
  );

  useEffect(() => {
    refetch();
  }, [rowsPerPage]);


  const loadPage = () => {
    fetchMore({
      variables: {
        page: page + 1
      }
    });
  };

  const handleClickOpen = id => {
    setShippingId(id);
    setOpen(true);
  };

  const handleChangePage = async (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
    await loadPage();
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    console.log("row", rowsPerPage);
  };

  const handleClose = () => {
    setOpen(false);

  };


  const { loading: loadingAdmin, data: dataAdmin } = useQuery(GET_ACTIVE_ADMIN);

  if (loading || loadingPage)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <div>
          <ShippingDetails id={shippingId} close={handleClose} />
        </div>
      </Dialog>
      <CustomerUI
        {...data}
        classes={classes}
        history={props.history}
        rowsPerPage={rowsPerPage}
        page={page}
        handleClickOpen={handleClickOpen}
        dataPage={dataPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        refetch={refetch}
      />
    </div>
  );
}

export class CustomerUI extends Component {
  componentDidMount() {
    console.log("comptest", this.props);
    //this.props.refetch();
  }
  render() {
    return (
      <div>
        <div className="pagetop">
          <div className="left">
            <Breadcrumbs aria-label="breadcrumb">
              <a color="inherit" href="/">
                Dashboard
              </a>
              <Typography color="textPrimary">Shipping</Typography>
            </Breadcrumbs>
          </div>
          <div className="right"></div>
        </div>
        <Grid container spacing={3} className="max1200">
          <Grid item xs={3}>
            <Paper className='sticky'>
              <MenuList className="badgemenu">
                <MenuItem>
                  <ListItemIcon>
                    <TimerIcon fontSize="small" />
                  </ListItemIcon>
                  Awaiting Shipment <b>{this.props.dataPage.pageCount.total}</b>
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <PauseIcon fontSize="small" />
                  </ListItemIcon>
                  Pending Fullfillment
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <LocalShippingIcon fontSize="small" />
                  </ListItemIcon>
                  Shipped
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <AirlineSeatReclineNormalIcon fontSize="small" />
                  </ListItemIcon>
                  On Hold
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <BlockIcon fontSize="small" />
                  </ListItemIcon>
                  Canceled
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <CommentIcon fontSize="small" />
                  </ListItemIcon>
                  Notes
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <NotesIcon fontSize="small" />
                  </ListItemIcon>
                  Logs
                </MenuItem>
              </MenuList>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Paper
                  className={`borderblue summarybox ${this.props.classes.paper}`}
                >
                  Awaiting Shipment <b>{this.props.dataPage.pageCount.total}</b>
                </Paper>
              </Grid>

              <Grid item xs={9}>
                <Paper
                  className={`borderred summarybox ${this.props.classes.paper}`}
                >
                  Last Activity <b>Test</b>
                </Paper>
              </Grid>
            </Grid>
            <ul>
              {
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Order ID</TableCell>
                        <TableCell>Order Date</TableCell>
                        <TableCell>Customer Name</TableCell>

                        <TableCell>Customer Email</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.getOrdersByTag.map(ship => {
                        return (
                          <TableRow key={ship.orderId}>
                            <TableCell component="th" scope="row">
                              <Tooltip
                                title={ship.customerNotes}
                                classes={{
                                  tooltip: this.props.classes.customWidth
                                }}
                              >
                                <Button>{ship.orderId}</Button>
                              </Tooltip>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {moment(ship.orderDate).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {ship.shipTo.name}
                            </TableCell>

                            <TableCell component="th" align="left" scope="row">
                              {ship.customerEmail}
                            </TableCell>
                            <TableCell
                              component="th"
                              align="center"
                              scope="row"
                            >
                              <HourglassEmptyIcon
                                className="yellowcolor"
                                fontSize="small"
                                color="secondary"
                              />
                            </TableCell>
                            <TableCell component="th" align="left" scope="row">
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => this.props.handleClickOpen(ship.orderId)}
                                className={this.props.classes.button}
                                startIcon={<LocalShippingIcon />}
                              >
                                Details
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
              <TablePagination
                rowsPerPageOptions={[100]}
                component="div"
                count={this.props.dataPage.pageCount.total}
                rowsPerPage={100}
                page={this.props.page}
                onChangePage={this.props.handleChangePage}
                onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
              />
            </ul>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Shippings;
