import React, { Component, useEffect, useState } from "react";
import { useQuery, NetworkStatus } from "@apollo/client";
import { GET_UNDERHOOD_LIST, UNDERHOOD_CREATE } from "../../../queries";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";

function UnderhoodList(props) {

  // query get data
  const { subscribeToMore, loading, error, data, refetch } = useQuery(
    GET_UNDERHOOD_LIST
  );

  const [underhoodData, setUnderhoodData] = useState([]);

  useEffect(() => {
    if (data) {
      setUnderhoodData(data.underhoods);
      console.log(underhoodData);
    }
  }, [data, underhoodData]);

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;
  const { permission  } = props.session.activeAdmin;

  return (
    <div>
        <UnderhoodListUI
          permission={permission}
          underhoodData={underhoodData}
          refetch={refetch}
          subscribeToUnderhoodList={() =>
            // subscribe query
            subscribeToMore({
              document: UNDERHOOD_CREATE,
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const newUnderhood = subscriptionData.data.underhood;
                if (
                  !prev.underhoods.find(
                    underhood => underhood.id === underhood.id
                  )
                ) {
                  return {
                    ...prev,
                    underhoods: [newUnderhood, ...prev.underhoods]
                  };
                } else {
                  return prev;
                }
              }
            })
          }
        />
    </div>
  );
}

export class UnderhoodListUI extends Component {
  componentDidMount() {
    this.props.subscribeToUnderhoodList();
    this.props.refetch();
    console.log(this.props);
  }
  render() {

    const { permission  } = this.props;
    const god       = permission.god  ? permission.god.admin  :  false;
    const underhood    = permission.underhood  ? permission.underhood  :  false;
    return (
      <div>
        <div className="pagetop">
          <div className="left">
            <Breadcrumbs aria-label="breadcrumb">
              <a color="inherit" href="/">
                Dashboard
              </a>
              <Typography color="textPrimary">
                Underhood Real.Dog
              </Typography>
            </Breadcrumbs>
          </div>
          {god || underhood.admin || underhood.write ?
            <div className="right">
              <Button
                component={Link}
                variant="outlined"
                color="secondary"
                to="underhood/add"
              >
                Add Page
              </Button>
            </div>  : ''}

        </div>
        <ul>
          {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Page Name</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="right">Author</TableCell>
                    <TableCell align="right">Webpage</TableCell>
                    <TableCell align="right">Created Date</TableCell>
                    <TableCell align="right">Updated Date</TableCell>
                    {god || underhood.admin || underhood.write ?  <TableCell align="right"></TableCell> : '' }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.underhoodData.map(underhood => (
                    <TableRow
                      key={underhood.id}
                      className={underhood.locked ? "disabled" : "active"}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        {underhood.title}
                        {underhood.locked ? (
                          <div className="authorlocked">
                            {underhood.admin.name} is editing now...
                          </div>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <div
                          className={underhood.status ? "published" : "archived"}
                        >
                          {underhood.status ? "Published" : "Archived"}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {underhood.admin.name} {underhood.admin.surname}
                      </TableCell>
                      <TableCell align="right">
                        {underhood.site}
                      </TableCell>
                      <TableCell align="right">
                        {moment.unix(underhood.updatedAt).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="right">
                        {moment.unix(underhood.updatedAt).format("DD/MM/YYYY")}
                      </TableCell>
                      {god || underhood.admin || underhood.write ?
                      <TableCell align="right">
                          <Button
                            component={Link}
                            variant="outlined"
                            color="secondary"
                            to={`/dashboard/underhood/edit/${underhood.id}`}
                            disabled={underhood.locked}
                          >
                            <Edit />
                          </Button>
                      </TableCell> : '' }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </ul>
      </div>
    );
  }
}

export default UnderhoodList;
