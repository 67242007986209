import { gql } from "@apollo/client";

const GET_STRIPE = gql`
  query ($email: String!) {
    stripe(email: $email) {
      id
      email
      subscription {
        id
      }
    }
  }
`;

const GET_ACTIVE_SUBSCRIPTIONS = gql`
  query GetActiveSubscriptions {
    activeSubscriptions
  }
`;

const GET_SECRETSHOP_ACTIVE = gql`
  query ActiveSecretshopProducts {
    secretShopActiveProducts
  }
`;

const FETCH_PRODUCT_PLANS = gql`
  query GetCheckoutPlans {
    checkoutPlans {
      id
      amount
      product {
        id
        name
        description
        package_dimensions {
          weight
          height
          length
          width
        }
      }
    }
  }
`;

const RESTART_SUB = gql`
  mutation RestartSubscriptionAdmin($input: RestartSubInput) {
    restartSubscriptionAdmin(input: $input) {
      id
      status
    }
  }
`;

const PAUSE_SUB = gql`
  mutation PauseSubscription($id: String, $input: PauseSubscriptionInput) {
    pauseSubscription(id: $id, input: $input) {
      id
      status
    }
  }
`;

const RESUME_SUB = gql`
  mutation ResumeSubscription($id: String) {
    resumeSubscription(id: $id) {
      id
      status
    }
  }
`;

const ONE_TIME_ORDER = gql`
  mutation OT_Order_Charge($input: inputOT_Order) {
    createOT_Order(input: $input)
  }
`;

const ONE_TIME_ORDER_TAB_ONE = gql`
  mutation CreateOTBoxOrder($input: inputOTBoxOrder) {
    createOTBoxOrder(input: $input)
  }
`;

const ONE_TIME_ORDER_TAB_THREE = gql`
  mutation CreateOTSSProductOrder($input: inputOTBoxOrder) {
    createOTSSProductOrder(input: $input)
  }
`;

const GET_PRODUCTS = gql`
  query GetProducts($limit: Int, $after: String) {
    products(limit: $limit, after: $after, show: "secretshop") {
      metadata
      id
      name
      attributes
      description
      statement_descriptor
      images
      shippable
      unit_label
      skus {
        id
        skuId
        price
        image
        attributes {
          size
          gender
        }
        inventory {
          quantity
        }
        package_dimensions {
          length
          weight
          height
          length
        }
      }

      updated
      updatedCountDown
    }
  }
`;

const ADD_SUBSCRIPTION_TRIAL_PERIOD = gql`
  mutation AddSubscriptionTrialPeriod($id: ID, $input: TrialInput) {
    addSubscriptionTrialPeriod(id: $id, input: $input) {
      status
    }
  }
`;

const UPGRADE_MEMBERSHIP = gql`
  mutation UpgradeMembershipAdmin($subId: ID, $premiumInterval: Int) {
    upgradeMembershipAdmin(subId: $subId, premiumInterval: $premiumInterval) {
      status
    }
  }
`;

export {
  GET_STRIPE,
  GET_ACTIVE_SUBSCRIPTIONS,
  GET_SECRETSHOP_ACTIVE,
  FETCH_PRODUCT_PLANS,
  RESTART_SUB,
  PAUSE_SUB,
  RESUME_SUB,
  ONE_TIME_ORDER,
  ONE_TIME_ORDER_TAB_ONE,
  ONE_TIME_ORDER_TAB_THREE,
  GET_PRODUCTS,
  ADD_SUBSCRIPTION_TRIAL_PERIOD,
  UPGRADE_MEMBERSHIP,
};
