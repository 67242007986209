import React, { useState, useEffect, useRef } from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/client";

import {
  ADD_UNDERHOOD_PAGE,
  GET_UNDERHOOD_LIST,
  GET_ADMINS,
  SINGLE_UPLOAD,
} from "../../../queries";

import {
  Button,
  CssBaseline,
  TextField,
  Container,
  CircularProgress,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Dante from "Dante2";
import { ImageBlockConfig } from "Dante2/package/es/components/blocks/image.js";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

//import Upload Function
import { startSingleUpload } from "../../Funcs/Upload";

const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

const AddPage = (props) => {
  //useStates
  const [form, setForm] = useState({ title: "", admin_id: "" });
  const [site, setSite] = useState("");
  const [main, setMain] = useState(false);
  const [order, setOrder] = useState(0);
  const [status, setStatus] = useState(false);
  const [articleText, setArticleText] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [locked, setLocked] = useState(true);
  const [adminList, setAdminList] = useState([]);
  const [me, setMe] = useState([]);

  const timerRef = useRef(null);
  const onAuto = useRef(false);

  //useQueries
  const { data: data_admin, loading: loading_admin } = useQuery(GET_ADMINS);

  //useMutation
  const [addUnderhoodPage, { error, loading: loadingAddPage }] =
    useMutation(ADD_UNDERHOOD_PAGE);
  const [singleUpload, { loading: uploadLoading, error: uploadError }] =
    useMutation(SINGLE_UPLOAD);

  //use Effects
  useEffect(() => {
    if (onAuto.current) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (!formValidate()) {
        timerRef.current = setTimeout(() => {
          onSubmit();
        }, 10000);
      }
    }
  }, [form, articleText]);

  useEffect(() => {
    if (!loading_admin && data_admin) {
      const me = data_admin.admins.filter(
        (x) => x.id === props.session.activeAdmin.id
      );
      setAdminList(data_admin);
      setMe(me[0]);
      setForm({
        ...form,
        admin_id: me[0].id,
      });
    }
  }, [data_admin, loading_admin]);

  //on Functions
  const updateField = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    onAuto.current = true;
  };

  const updateFieldSite = (e) => {
    setSite(e.target.value);
    console.log(site);
  };

  const updateFieldOrder = (e) => {
    setOrder(e.target.value);
    console.log(order);
  };

  const handleChange = (event) => {
    setStatus(event.target.checked);
  };

  const handleChangeMain = (event) => {
    setMain(event.target.checked);
  };

  const formValidate = () => {
    const { title } = form;
    return !title || !site;
  };

  const onChangeEditor = (text) => {
    const currentText = articleText.text;
    const newText = JSON.stringify(text);
    if (currentText !== newText) {
      // Content has changed
      setArticleText({ text: newText });
      onAuto.current = true;
    }
  };

  const onSubmit = async () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    addUnderhoodPage({
      variables: {
        admin_id: form.admin_id,
        title: form.title,
        text: articleText.text,
        status: status,
        locked: true,
        site: site,
        order: parseInt(order),
        main,
      },
    })
      .then(async ({ data }) => {
        props.history.push(
          `/dashboard/underhood/edit/${data.createUnderhood.id}`
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadFile = async (file, imageBlock) => {
    await startSingleUpload(file, singleUpload, async (result) => {
      imageBlock.state.url = result.uri;
      imageBlock.updateData();
      imageBlock.stopLoader();
    });
  };

  if (loading_admin)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );

  if (error) {
    console.log(JSON.stringify({ error }));
  }
  return (
    <div>
      <Container>
        <Grid container spacing={3}>
          <CssBaseline />
          <Grid item xs={9}>
            <div className="">
              <TextField
                onChange={updateField}
                variant="outlined"
                inputProps={{ style: { fontSize: 22 } }}
                InputLabelProps={{ style: { fontSize: 22 } }}
                margin="normal"
                value={form.title}
                required
                fullWidth
                name="title"
                label="Title"
                type="text"
                id="title"
              />

              <div className="editordante">
                <Dante
                  className="editordark"
                  body_placeholder={"Write something..."}
                  widgets={[
                    ImageBlockConfig({
                      options: {
                        upload_handler: (file, imageBlock) =>
                          uploadFile(file, imageBlock),
                        image_caption_placeholder: "Text Here!",
                      },
                    }),
                  ]}
                  onChange={(editor) => {
                    onChangeEditor(editor.emitSerializedOutput());
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <Paper className="padding10 margintop15">
              <div>
                <FormControl variant="outlined" className="width100">
                  <InputLabel id="category">Select Author</InputLabel>
                  <Select
                    name="admin_id"
                    value={form.admin_id}
                    onChange={updateField}
                    label="category"
                  >
                    <MenuItem value={props.session.activeAdmin.id}>
                      {me.name} (Me)
                    </MenuItem>

                    {adminList.admins &&
                      adminList.admins.map((admin) => {
                        if (admin.id !== props.session.activeAdmin.id) {
                          return (
                            <MenuItem key={admin.id} value={admin.id}>
                              {admin.name}
                            </MenuItem>
                          );
                        }
                      })}
                  </Select>
                </FormControl>
              </div>
              <br />
              <div>
                <FormControl variant="outlined" className="width100">
                  <InputLabel id="site-id">Select Site</InputLabel>
                  <Select
                    labelId="site-id"
                    id="site-id"
                    value={site}
                    onChange={updateFieldSite}
                  >
                    <MenuItem value={"beast-village"}>Beast Village</MenuItem>
                    <MenuItem value={"real-dog"}>Real Dog</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      className="padding10"
                      checked={main}
                      onChange={handleChangeMain}
                      name="main"
                      color="secondary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                  }
                  label={"Main Page"}
                />
              </div>
              <div>
                {!main ? (
                  <TextField
                    onChange={updateFieldOrder}
                    variant="outlined"
                    margin="normal"
                    value={order}
                    required
                    fullWidth
                    name="order"
                    label="Order"
                    type="number"
                    id="order"
                  />
                ) : (
                  ""
                )}
              </div>
              <br />
              <FormControlLabel
                control={
                  <Switch
                    className="padding10"
                    checked={status}
                    onChange={handleChange}
                    name="status"
                    color="secondary"
                    inputProps={{
                      "aria-label": "secondary checkbox",
                    }}
                  />
                }
                label={status ? "Published" : "Archive"}
              />
              <br /> <br />
              <Button
                type="submit"
                fullWidth
                onClick={onSubmit}
                variant="outlined"
                color={status ? "secondary" : "primary"}
                size="large"
                disabled={loadingAddPage || formValidate()}
              >
                {status ? "PUBLISH" : "SAVE DRAFT"}
                {loadingAddPage && <SpinnerAdornment />}
              </Button>
              {error && <div>{error.message}</div>}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withApollo(AddPage);
