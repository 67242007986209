import { useLazyQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Grid, IconButton, LinearProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useRef } from "react";
import {
  FINAL_EXAM,
  MULTIPLE_CHOICE,
  QUESTION_ANSWER,
} from "../../../../globals";
import { QUESTIONS_FIND } from "../../../../queries";
import QuestionDialog from "./QuestionDialog";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SpaceBarIcon from "@mui/icons-material/SpaceBar";

const QuizSelectQuestion = ({ onConfirmQuestion, quizType }) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState("");
  const isInitialMount = useRef(true);
  const [questionList, setQuestionList] = useState([]);

  const [selectedQuestion, setSelectedQuestion] = useState({});

  const [
    getArticles,
    {
      loading: questionsLoading,
      data: questionsData,
      error: questionsError,
      refetch,
    },
  ] = useLazyQuery(QUESTIONS_FIND, {
    fetchPolicy: "no-cache",
    variables: {
      key: search,
      ...(quizType !== FINAL_EXAM && { questionType: quizType }),
    },
    onCompleted: (data) => {
      if (data) {
        console.log(data);
        setQuestionList(data.questionsFind);
      }
    },
  });

  useEffect(() => {
    if (questionsData?.getQuestions) {
      const list = [...questionsData?.getQuestions];
      let filteredList = [];
      if (quizType === QUESTION_ANSWER) {
        filteredList = list.filter(
          (item) => item.questionType === QUESTION_ANSWER
        );
      }
      if (quizType === MULTIPLE_CHOICE) {
        filteredList = list.filter(
          (item) => item.questionType === MULTIPLE_CHOICE
        );
      }
      if (quizType === FINAL_EXAM) {
        filteredList = list;
      }

      setQuestionList(filteredList);
    }
  }, [questionsData]);

  useEffect(() => {
    if (open) {
      setSelectedQuestion({});
      //refetch();
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isInitialMount.current) {
      getArticles();
      isInitialMount.current = true;
    }
  }, [search]);

  const searchInput = (event) => {
    const { value } = event.target;
    if (value.length > 2) {
      isInitialMount.current = false;
      setSearch(value);
    }
  };

  const onConfirm = () => {
    if (Object.keys(selectedQuestion).length === 0) {
      enqueueSnackbar("Please Select Question first", {
        variant: "Error",
      });
      return;
    }
    onConfirmQuestion(selectedQuestion);
    handleClose();
  };

  return (
    <div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Button onClick={handleClickOpen} variant="outlined">
          <AddIcon /> Add Question
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">{"Select Question"} </DialogTitle>
        <div
          style={{
            position: "absolute",
            right: 10,
            top: 10,
          }}
        >
          <QuestionDialog create={true} refetch={refetch} />
        </div>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ paddingTop: 10, minHeight: 300 }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={questionList}
              noOptionsText={"Autocomplete Search Feature"}
              getOptionLabel={(option) => option.question}
              loading={questionsLoading}
              valueInput={search}
              renderInput={(params, options) => (
                <TextField
                  {...params}
                  label="Search Questions"
                  margin="normal"
                  onChange={searchInput}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    value: search,
                    type: "search",
                  }}
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <div className="searchresult">
                    <b>{option.question} </b>
                    {option.questionType === MULTIPLE_CHOICE ? (
                      <FormatListNumberedIcon
                        style={{
                          color: "white",
                        }}
                      />
                    ) : (
                      <SpaceBarIcon
                        style={{
                          color: "white",
                        }}
                      />
                    )}{" "}
                    - <span>{option.status ? "Active" : "Draft"}</span>
                  </div>
                </li>
              )}
              onChange={(e, value) => {
                setSelectedQuestion(value);
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const QuestionDisabled = ({ question, cancel }) => {
  return (
    <div className="every-child-margin ">
      <div
        style={{
          position: "absolute",
          right: 10,
          top: 80,
        }}
      >
        <IconButton
          onClick={() => {
            cancel();
          }}
          color={"error"}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <TextField
        style={{
          width: "95%",
        }}
        label="Question"
        value={question.question}
        disabled={true}
      />
      {question.questionType === MULTIPLE_CHOICE ? (
        question.options.map((option, index) => {
          return (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={1}>
                <IconButton disabled={true} edge="end">
                  {question.answerIndex == index ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </IconButton>
              </Grid>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={option}
                  label={"Option"}
                />
              </Grid>
            </Grid>
          );
        })
      ) : (
        <TextField
          fullWidth
          disabled
          value={question.answer}
          label="Answer"
          placeholder="This field will be filled by customers"
        />
      )}
    </div>
  );
};

export default QuizSelectQuestion;
