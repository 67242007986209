import React, { Component, useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomerFind from "./search";
import { CONNECT_REF } from "../../../../../queries";
import CircularProgress from "@mui/material/CircularProgress";

export default function addReferrer(props) {
  return <CustomerFindUI {...props} />;
}

export const CustomerFindUI = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState("");

  const [connectRef, { error, loading }] = useMutation(CONNECT_REF);

  const handleSave = () => {
    connectRef({
      variables: {
        id: props.owner,
        ref: ref,
      },
    })
      .then(async ({ data }) => {
        enqueueSnackbar(`Member successfully updated!`, {
          variant: "success",
        });
        props.handleRefetch();
        setOpen(false);
      })
      .catch((error) => {
        enqueueSnackbar(`${error}`, {
          variant: "error",
        });
        setOpen(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReffered = (refId) => {
    setRef(refId);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} fullWidth>
        Add Referrer
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Referrer/Affiliate"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add ref to this user. This will add 1 point to referrer or $10 to
            affiliate member.
          </DialogContentText>
          <CustomerFind handleReffered={handleReffered} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          {ref.length > 0 && !loading ? (
            <Button onClick={handleSave} autoFocus color="secondary">
              Agree
            </Button>
          ) : loading ? (
            <CircularProgress className="loadingbutton" />
          ) : (
            <Button disabled>Agree</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
