import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_CUSTOMERS_WORKSHOPS,
  GET_WORKSHOPS,
  WORKSHOP_ATTENDACE_UPDATE,
} from "../../../queries";
import { useSnackbar } from "notistack";
import { Info } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
export default function UpdateAttendance({
  workshop,
  customer,
  refetch,
  disabled,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [attendanceOptions, setAttendanceOption] = React.useState(true);

  const [updateAttendanceMutation, { loading }] = useMutation(
    WORKSHOP_ATTENDACE_UPDATE
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onConfirm = () => {
    updateAttendanceMutation({
      variables: {
        id: workshop.id,
        attendance: attendanceOptions === "true",
      },
    })
      .then((res) => {
        enqueueSnackbar("Updated Successfully", {
          variant: "success",
        });
        refetch();
        handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      {workshop.attendance ? (
        <CheckBoxIcon />
      ) : (
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={handleClickOpen}
          disabled={disabled}
          startIcon={<EditIcon />}
        >
          Update Attendance
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Do you confirm ${customer.name} attended the workshop`}
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Select the Attendance Option
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={"true"}
              name="radio-buttons-group"
              onChange={(e) => {
                setAttendanceOption(e.target.value);
              }}
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="Attended"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="Didn't attended"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const UpdateAttendanceContainer = ({ workshop, customer }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [workshops, setWorkshops] = React.useState([]);

  const { loading, error } = useQuery(GET_WORKSHOPS, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      setWorkshops(res.getWorkshops);
    },
  });
  const [schedules, setSchedules] = React.useState([]);
  const { loading: loadingRevS, refetch } = useQuery(GET_CUSTOMERS_WORKSHOPS, {
    variables: {
      customerId: customer.id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      const copyArr = JSON.parse(
        JSON.stringify(res.getCustomerWorkshopSchedules)
      );
      copyArr.sort((a, b) => {
        const aDate = parseInt(a.id.substring(0, 8), 16);
        const bDate = parseInt(b.id.substring(0, 8), 16);
        if (aDate > bDate) {
          return -1;
        }
        if (aDate < bDate) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
      setSchedules(copyArr);
    },
  });
  if (loading || loadingRevS) {
    return <LinearProgress />;
  }
  const attended = schedules.find((item) => item.attendance);
  if (attended) {
    return <CheckBoxIcon />;
  }
  return (
    <div>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <Info />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          {`${customer.name}'s workshops`}
        </DialogTitle>
        <DialogContent>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {schedules.length === 0 && (
              <Typography> user has not participated before </Typography>
            )}
            {schedules.map((value) => {
              const workshop = workshops.find(
                (item) => item.id === value.addOnId
              );
              const labelId = `checkbox-list-label-${value}`;
              const date = new Date(value.startTime * 1000);
              const timeString = `${date.toLocaleDateString("en-US", {
                timeZone: "America/Los_Angeles",
              })}
        ${date.toLocaleTimeString("en-US", {
          timeZone: "America/Los_Angeles",
          hourCycle: "h24",
          timeStyle: "short",
        })}
        PT /
        ${date.toLocaleTimeString("en-US", {
          timeZone: "America/New_York",
          hourCycle: "h24",
          timeStyle: "short",
        })}
        ET`;
              const disabled =
                value.info === "rescheduled" || value.info === "cancelled";

              return (
                <ListItem
                  style={{ width: "100%" }}
                  key={value}
                  secondaryAction={
                    value.info &&
                    value.info !== "" && <Chip label={value.info} />
                  }
                  disablePadding
                >
                  <ListItemIcon>
                    <IconButton disabled={disabled}>
                      {/* <EditIcon /> */}
                      <UpdateAttendance
                        customer={customer}
                        workshop={value}
                        refetch={refetch}
                        disabled={disabled}
                      />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    secondary={timeString}
                    primary={workshop?.name}
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
