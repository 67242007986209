import { useQuery } from "@apollo/client";
import { ButtonBase, Container, Grid, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FETCH_CUSTOMER_COURSES,
  GET_COURSES,
  GET_CUSTOMER,
  GET_CUSTOMER_TUTORIAL_PROGRESS,
  GET_SINGLE_COURSE,
  GET_SINGLE_UNIT,
  GET_UNIT_PROGRESS_OF_CUSTOMER,
} from "../../../../../queries";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CompleteUnitDialog from "./CompleteUnitDialog";
import CompleteTutorialDialog from "./CompleteTutorialDialog";
import CompleteDangerUnitDialog from "./CompleteDangerUnitDialog";
const CourseProgressOfCustomer = (props) => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: { id },
  });
  const {
    loading: loadingCustomerPurchase,
    data: dataCustomerPurchase,
    error: errorPurchase,
  } = useQuery(FETCH_CUSTOMER_COURSES, {
    variables: {
      customerId: id,
    },
  });
  const {
    data: dataCourses,
    loading: loadingCourses,
    error: errorCourses,
  } = useQuery(GET_COURSES);
  if (loading || loadingCourses || loadingCustomerPurchase) {
    return <LinearProgress />;
  }
  if (error || errorCourses || errorPurchase) {
    return <div>Error</div>;
  }
  if (dataCustomerPurchase?.purchasedCoursesOfCustomer.length === 0) {
    return (
      <div>
        <Typography>{data.customer.name}</Typography>
        <Typography>The user does not have any course for now</Typography>
      </div>
    );
  }
  return (
    <Container>
      <Typography>{data.customer.name}</Typography>
      <br></br>
      {dataCourses.getCourses.map((courseItem) => {
        if (
          dataCustomerPurchase.purchasedCoursesOfCustomer
            .map((item) => item.courseId)
            .includes(courseItem.id)
        )
          return (
            <CourseItem
              key={courseItem.id}
              courseItem={courseItem}
              activeAdmin={props.session.activeAdmin}
            />
          );
        return <div></div>;
      })}
    </Container>
  );
};
export default CourseProgressOfCustomer;

export const CourseItem = ({
  courseItem,
  activeAdmin,
  onlyDialog = false,
  openOutside = false,
  setOpenOutside = () => {},
}) => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [completedUnits, setCompoletedUnits] = useState([]);
  useEffect(() => {
    setOpen(openOutside);
  }, [openOutside]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenOutside(false);
  };

  const { loading, error, refetch } = useQuery(GET_UNIT_PROGRESS_OF_CUSTOMER, {
    variables: {
      courseId: courseItem.id,
      customerId: id,
    },
    onCompleted: (res) => {
      setCompoletedUnits(res.getUnitProgressOfCustomer.completedUnits);
    },
  });
  if (loading) {
    return <LinearProgress />;
  }
  return (
    <div>
      {!onlyDialog && (
        <ButtonBase onClick={handleClickOpen}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            style={{
              border: "1px solid",
              marginBottom: 20,
              borderRadius: 5,
              overflow: "hidden",
              maxWidth: 600,
            }}
          >
            <Grid item xs={4}>
              <img
                src={courseItem.featured_img}
                style={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography variant="h4" align="left">
                  {courseItem.title}
                </Typography>
                <Chip label={courseItem.status ? "Active" : "Draft"} />
              </Grid>
            </Grid>
          </Grid>
        </ButtonBase>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{courseItem.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {courseItem.units.map((unitItem) => {
              const isCompleted = completedUnits.find(
                (item) => item === unitItem.id
              );
              return (
                <UnitItem
                  key={unitItem.id}
                  unitItem={unitItem}
                  isCompleted={isCompleted}
                  refetch={refetch}
                  activeAdmin={activeAdmin}
                />
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const UnitItem = ({ unitItem, isCompleted, refetch, activeAdmin }) => {
  const { id } = useParams();
  const [completedTutorials, setCompletedTutorials] = useState([]);

  const { data, loading, error } = useQuery(GET_SINGLE_UNIT, {
    variables: { input: { id: unitItem.id } },
  });
  const {
    loading: loadingProgress,
    error: errorProgress,
    data: tutorialProgressData,
    refetch: refetchProgress,
  } = useQuery(GET_CUSTOMER_TUTORIAL_PROGRESS, {
    variables: {
      input: {
        customerId: id,
        unitId: unitItem.id,
      },
    },
    // onCompleted: (res) => {
    //   console.log("set completed", res);
    //   setCompletedTutorials(
    //     res.getCustomersTutorialProgress.completedTutorials
    //   );
    // },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (tutorialProgressData?.getCustomersTutorialProgress) {
      setCompletedTutorials(
        tutorialProgressData.getCustomersTutorialProgress.completedTutorials
      );
    }
  }, [tutorialProgressData]);

  if (loading || loadingProgress) {
    return <LinearProgress />;
  }
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: "40%", flexShrink: 0 }}>
            <Grid container>
              <Grid>
                <Chip label={unitItem.status ? "Active" : "Draft"} />
              </Grid>
              <Grid>
                {isCompleted ? (
                  <Chip
                    label={isCompleted ? "Completed" : "Not Completed"}
                    style={{
                      marginLeft: 10,
                    }}
                  />
                ) : (
                  // <CompleteUnitDialog
                  //   unit={data.getSingleUnit}
                  //   completedTutorials={completedTutorials}
                  //   customerId={id}
                  //   refetch={refetch}
                  // />
                  <CompleteDangerUnitDialog
                    unit={data.getSingleUnit}
                    completedTutorials={completedTutorials}
                    customerId={id}
                    refetch={() => {
                      refetch();
                      refetchProgress();
                    }}
                    activeAdmin={activeAdmin}
                  />
                )}
              </Grid>
            </Grid>
          </Typography>
          <Typography>{unitItem.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
            {data.getSingleUnit.tutorials.map((tutorialItem) => {
              const isCompleted = completedTutorials.find(
                (item) => item.tutorialId === tutorialItem.id
              );
              return (
                <TutorialItem
                  tutorialItem={tutorialItem}
                  key={tutorialItem.id}
                  isCompleted={isCompleted}
                  courseId={unitItem.courseId}
                  unitId={unitItem.id}
                  refetch={refetchProgress}
                />
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const TutorialItem = ({
  tutorialItem,
  isCompleted,
  courseId,
  unitId,
  refetch,
}) => {
  return (
    <ListItem
      secondaryAction={
        isCompleted ? (
          <Checkbox
            edge="end"
            inputProps={{ "aria-labelledby": "labelId" }}
            disabled={true}
            checked={isCompleted ? true : false}
            style={{
              color: isCompleted?.passed === false && "red",
            }}
          />
        ) : (
          <CompleteTutorialDialog
            tutorialItem={tutorialItem}
            courseId={courseId}
            unitId={unitId}
            refetch={refetch}
          />
        )
      }
      disablePadding
    >
      <ListItemButton>
        <ListItemAvatar>
          <Avatar alt={`alt`} src={``} />
        </ListItemAvatar>
        <ListItemText id={"labelId"} primary={`${tutorialItem.title}`} />
      </ListItemButton>
    </ListItem>
  );
};
export const CourseProgressItem = ({ courseId, customerId, activeAdmin }) => {
  const [open, setOpen] = useState(false);
  const [completedUnits, setCompoletedUnits] = useState([]);
  const [courseItem, setCourseItem] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };
  console.log("courseId, customerId ", courseId, customerId);
  const handleClose = () => {
    setOpen(false);
  };
  const { loading: loadingCourse, error: errorCourse } = useQuery(
    GET_SINGLE_COURSE,
    {
      variables: {
        input: { id: courseId },
      },
      onCompleted: (res) => {
        console.log("res.getSingleCourse=>", res.getSingleCourse);
        setCourseItem(res.getSingleCourse);
      },
    }
  );
  const { loading, error, refetch } = useQuery(GET_UNIT_PROGRESS_OF_CUSTOMER, {
    variables: {
      courseId: courseId,
      customerId: customerId,
    },
    onCompleted: (res) => {
      setCompoletedUnits(res.getUnitProgressOfCustomer.completedUnits);
    },
  });
  if (loading || loadingCourse) {
    return <LinearProgress />;
  }
  return (
    <div>
      <Button
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        See Progress
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{courseItem.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {courseItem.units?.map((unitItem) => {
              const isCompleted = completedUnits.find(
                (item) => item === unitItem.id
              );
              return (
                <UnitItem
                  key={unitItem.id}
                  unitItem={unitItem}
                  isCompleted={isCompleted}
                  refetch={refetch}
                  activeAdmin={activeAdmin}
                />
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
