import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { GET_RATE_ADMIN, GET_CHECKOUT_PLANS } from "../../../../../queries";
import Grid from "@mui/material/Grid";
import Edit from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Restrictions from "../Restrictions/restartSubRestriction";
import TextField from "@mui/material/TextField";

import Backdrop from "@mui/material/Backdrop";
import LinearProgress from "@mui/material/LinearProgress";
import PauseSubscription from "./PauseDialog";
import UpdateCycle from "./UpdateCycleDialog";
import { sub } from "date-fns";
import ResumeDialog from "./ResumeDialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useSnackbar } from "notistack";

import cancelReasons from "../../../../../globals/reasons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  backdropShippingCalc: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000",
    position: "absolute",
  },
}));

const UpdateSubProducts = ({
  userProducts,
  subscription,
  handleUpdate,
  onHandleCancelSub,
  getActiveDog,
  updateLoader,
  refetch,
  dogId,
}) => {
  const classes = useStyles();
  const { loading, data, error } = useQuery(GET_CHECKOUT_PLANS);
  const [open, setOpen] = useState(false);

  const [planList, setPlanList] = useState([]);

  const [basket, setBasket] = useState([]);

  const [rateDialog, setRateDialog] = useState(false);

  const shippingProdId = process.env.REACT_APP_SHIPPING_COST_ID;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!updateLoader) {
      handleClose();
    }
  }, [updateLoader]);
  useEffect(() => {
    if (open) {
      getActiveDog();
    }
  }, [getActiveDog, open]);
  useEffect(() => {
    if (planList.length !== 0) {
      const userProds = planList
        .filter((item) =>
          userProducts.map((element) => element.plan.id).includes(item.id)
        )
        .map((element) => {
          return {
            ...element,
            quantity: userProducts.find((prod) => prod.plan.id === element.id)
              .quantity,
          };
        });
      setBasket(userProds);
    }
  }, [userProducts, planList]);

  useEffect(() => {
    if (data) {
      setPlanList(data.checkoutPlans);
    }
  }, [loading, data, error]);

  const onHandleUpdate = (shippingRate) => {
    let siList = userProducts
      .filter((item) => item.plan.product !== shippingProdId)
      .map((item) => {
        const basketItem = basket.find(
          (element) => element.id === item.plan.id
        );
        const qty = basketItem ? basketItem.quantity : 0;
        return {
          quantity: qty,
          subItemId: item.id,
        };
      });
    let planList = basket
      .filter(
        (item) =>
          !userProducts.map((element) => element.plan.id).includes(item.id)
      )
      .map((item) => {
        return {
          newItem: true,
          quantity: item.quantity,
          planId: item.id,
        };
      });
    let obj = {
      subID: subscription.id,
      basket: [...siList, ...planList],
      shippingRate: shippingRate,
    };

    handleUpdate(obj);
  };

  const addToBasket = (product) => {
    const copy = JSON.parse(JSON.stringify(basket));
    const item = copy.find((item) => item.id === product.id);
    if (!item) {
      copy.push({ ...product, quantity: 1 });
    } else {
      const index = copy.indexOf(item);
      copy[index].quantity += 1;
    }
    setBasket(copy);
  };
  const removeToBasket = (product) => {
    const copy = JSON.parse(JSON.stringify(basket));
    const item = copy.find((item) => item.id === product.id);
    const index = copy.indexOf(item);
    if (item.quantity !== 1) {
      copy[index].quantity -= 1;
    } else {
      copy.splice(index, 1);
    }
    setBasket(copy);
  };

  const imageArr = [
    require("../../../../../assets/plans/super_chew.png"),
    require("../../../../../assets/plans/mb_idle.png"),
    require("../../../../../assets/plans/tc_idle.png"),
    require("../../../../../assets/plans/chew_idle.png"),
    require("../../../../../assets/plans/tr_idle.png"),
    require("../../../../../assets/plans/mi_idle.png"),
    require("../../../../../assets/plans/mb_idle.png"),
  ];

  return (
    <div>
      <IconButton size={"small"} color="primary" onClick={handleClickOpen}>
        <Edit />
      </IconButton>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Backdrop className={classes.backdropShippingCalc} open={updateLoader}>
          <Typography
            variant={"subtitle1"}
            style={{ color: "white" }}
            align={"center"}
          >
            <CircularProgress color="primary" />
            <br />
            Please wait for Subscription update
          </Typography>
        </Backdrop>
        {loading ? (
          <div>
            <div>
              <LinearProgress color="secondary" />
            </div>
          </div>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid
              item
              md={9}
              style={{
                padding: 20,
              }}
            >
              <DialogTitle id="alert-dialog-title" style={{ paddingLeft: 5 }}>
                {"Update Subscription"}
              </DialogTitle>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {planList.lenght !== 0 &&
                  planList.map((item, index) => {
                    const element = basket.find(
                      (element) => element.id === item.id
                    );

                    return (
                      <Grid
                        item
                        xs={6}
                        style={{
                          backgroundColor: "#191a1e",
                          border: "2px solid #222429",
                          padding: "9px",
                          borderRadius: "5px",
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item md={5}>
                            <img width={"100%"} src={imageArr[index]} />
                          </Grid>
                          <Grid item md={7}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  style={{
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography>{item.product.name}</Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  style={{
                                    justifyContent: "center",
                                  }}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    id={item.id}
                                    onClick={() => {
                                      if (element) {
                                        removeToBasket(item);
                                      }
                                    }}
                                  >
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                  <Typography variant="subtitle1">
                                    {element ? element.quantity : 0}
                                  </Typography>
                                  <IconButton
                                    aria-label="delete"
                                    id={item.id}
                                    onClick={() => addToBasket(item)}
                                  >
                                    <ControlPointIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
              <DialogActions>
                <Button
                  variant={"outlined"}
                  onClick={handleClose}
                  color="secondary"
                >
                  Close
                </Button>

                <Button
                  variant={"contained"}
                  onClick={() => {
                    // handleClose();
                    setRateDialog(true);
                  }}
                  color="secondary"
                  autoFocus
                >
                  Update
                </Button>
              </DialogActions>
            </Grid>
            <Grid
              item
              md={3}
              style={{
                backgroundColor: "#191a1e",
                width: "100%",
                padding: 15,
                borderLeft: "2pxsolid #141518",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
              >
                <Grid item xs={12}>
                  {/* <Restrictions
                    refetch={refetch}
                    subscriptionId={subscription.id}
                    oldRestrictionsProp={
                      subscription && subscription.metadata.restrictions
                        ? subscription.metadata.restrictions
                        : ""
                    }
                    oldSubRestrictionsProp={
                      subscription && subscription.metadata.sub_restrictions
                        ? subscription.metadata.sub_restrictions
                        : ""
                    }
                  /> */}
                  <Restrictions id={dogId} subId={subscription.id} />
                </Grid>
                <Grid item xs={12}>
                  <CancelSub
                    isCancel={subscription.cancel_at_period_end}
                    confirmCancelSub={(cancelAtTheEnd, message) => {
                      onHandleCancelSub(
                        cancelAtTheEnd,
                        !subscription.cancel_at_period_end,
                        message
                      );
                    }}
                  />
                </Grid>
                {subscription.status === "active" && (
                  <Grid item xs={12}>
                    <PauseSubscription
                      subscription={subscription}
                      refetch={() => {
                        refetch();
                        handleClose();
                      }}
                    ></PauseSubscription>
                  </Grid>
                )}
                {subscription.status === "active" ||
                subscription.status === "trialing" ? (
                  <Grid item xs={12}>
                    <UpdateCycle
                      subscription={subscription}
                      refetch={() => {
                        refetch();
                        handleClose();
                      }}
                    ></UpdateCycle>
                  </Grid>
                ) : (
                  ""
                )}
                {(subscription.pause_collection !== null ||
                  subscription.status === "trialing") && (
                  <Grid item xs={12}>
                    <ResumeDialog
                      subscription={subscription}
                      refetch={() => {
                        refetch();
                        handleClose();
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Dialog>
      <ConfirmCalculation
        open={rateDialog}
        handleClose={() => {
          setRateDialog(false);
        }}
        rateInput={{
          subId: subscription.id,
          products: basket.map((item) => {
            return {
              productId: item.product.id,
              planId: item.id,
              quantity: parseInt(item.quantity),
            };
          }),
        }}
        updateConfirmed={(shippingRate) => {
          onHandleUpdate(shippingRate);
          // handleClose();
        }}
      />
    </div>
  );
};

const ConfirmCalculation = ({
  open,
  handleClose,
  rateInput,
  updateConfirmed,
}) => {
  const [getRateAdmin, { loading }] = useMutation(GET_RATE_ADMIN);

  const [shippingRate, setShippingRate] = useState(0);

  useEffect(() => {
    if (open) {
      getRateAdmin({
        variables: {
          input: rateInput,
        },
      })
        .then((data) => {
          setShippingRate(data.data.getRateAdmin);
        })
        .catch(() => {
          handleClose();
        });
    }
  }, [getRateAdmin, handleClose, open, rateInput]);

  const calculate = () => {};

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to update box ? "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>
              Box preparation is in progress and current shipping cost is:{" $"}
              {parseFloat(shippingRate.shipmentCost).toFixed(2)}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              updateConfirmed(shippingRate);
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
        {loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: "rgba(0,0,0,0.8)",
              top: 0,
              left: 0,
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          </div>
        )}
      </Dialog>
    </div>
  );
};

const CancelSub = ({ confirmCancelSub, isCancel }) => {
  const [open, setOpen] = React.useState(false);

  const [cancelAtTheEnd, setCancelAtTheEnd] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [reason, setReason] = React.useState("");

  const handleChangeReason = (event) => {
    setReason(event.target.value);
  };

  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      <Button
        fullWidth
        size={"small"}
        variant="outlined"
        color="secondary"
        className="redborder"
        onClick={handleClickOpen}
        style={{
          marginTop: 10,
        }}
      >
        {!isCancel ? "Cancel Subscription" : "Don't Cancel Subscription"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {!isCancel
            ? "Are you sure you want to cancel subscription"
            : "Are you sure you want to DON'T cancel subscription"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogContent>
          {!isCancel && (
            <FormControl fullWidth>
              <Select
                value={reason}
                onChange={handleChangeReason}
                displayEmpty
                focused
                fullWidth
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  color: "#fff !important",
                  backgroundColor: "#ffffff21 !important",
                  width: "100%",
                }}
              >
                <MenuItem disabled value="">
                  <em>Please choose a reason.</em>
                </MenuItem>
                {cancelReasons.map((data) => (
                  <MenuItem value={data}>{data}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {!isCancel && reason === "other" && (
            <TextField
              id="outlined-multiline-static"
              label="Comment"
              multiline
              maxRows={4}
              rows={4}
              value={value}
              sx={{ height: "125px", width: "100%", marginTop: "20px" }}
              onChange={handleChange}
              placeholder="Please write a reason more than 5 letters."
            />
          )}
          {!isCancel && (
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    value={cancelAtTheEnd}
                    onClick={() => {
                      setCancelAtTheEnd(!cancelAtTheEnd);
                    }}
                  />
                }
                label="Cancel at the end of the period"
              />
            </DialogContentText>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            onClick={() => {
              if (!isCancel) {
                if (reason.length === 0) {
                  enqueueSnackbar("Please choose a reason.", {
                    variant: "error",
                  });
                } else if (reason === "other" && value.length <= 5) {
                  enqueueSnackbar(
                    "Please write a reason more than 5 letters.",
                    {
                      variant: "error",
                    }
                  );
                } else {
                  confirmCancelSub(
                    cancelAtTheEnd,
                    reason !== "other" ? reason : value
                  );
                  handleClose();
                }
              } else {
                confirmCancelSub(
                  cancelAtTheEnd,
                  reason !== "other" ? reason : value
                );
                handleClose();
              }
            }}
            variant="outlined"
            color="primary"
            autoFocus
            className="redborder"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateSubProducts;
