import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";

import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import LinearProgress from "@mui/material/LinearProgress";

import {
  GET_MONGO_DOG,
  UPDATE_DOG_RESTRICTION,
  SET_RESTRICTIONS,
} from "../../../../../queries";

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: theme.palette.secondary.main,
    marginLeft: 5,
  },
  switch: {
    color: theme.palette.secondary.main,
  },
}));

const RestrictionsOfDogs = ({ id, subId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const restrictionsSkeleton = {
    Beef: {
      title: "Beef",
      value: false,
      subRestrictions: [
        { title: "", value: "BeefAorta", checked: false },
        { title: "", value: "BeefBackstrap", checked: false },
        { title: "", value: "BeefSpleen", checked: false },
        { title: "", value: "BeefTendon", checked: false },
        { title: "", value: "BeefTrachea", checked: false },
        { title: "", value: "GreenTripe", checked: false },
        { title: "", value: "SteerPizzle", checked: false },
        { title: "", value: "BeefLiver", checked: false },
        { title: "", value: "BullPizzle", checked: false },
        { title: "", value: "RolledCowskin", checked: false },
        { title: "", value: "CowEar", checked: false },
      ],
    },
    Bison: {
      title: "Bison",
      value: false,
      subRestrictions: [
        { title: "", value: "BisonScapula", checked: false },
        { title: "", value: "BisonBackstrap", checked: false },
        { title: "", value: "BisonTrachea", checked: false },
        { title: "", value: "BisonSpleen", checked: false },
        { title: "", value: "BisonLung", checked: false },
      ],
    },
    Boar: {
      title: "Boar",
      value: false,
      subRestrictions: [
        { title: "", value: "BoarEars", checked: false },
        { title: "", value: "WildBoarRib", checked: false },
      ],
    },
    Bone: { title: "Bone", value: false, subRestrictions: [] },
    Chicken: {
      title: "Chicken",
      value: false,
      subRestrictions: [
        { title: "", value: "ChickenFeet", checked: false },
        { title: "", value: "ChickenHeart", checked: false },
      ],
    },
    Duck: {
      title: "Duck",
      value: false,
      subRestrictions: [
        { title: "", value: "DuckFeet", checked: false },
        { title: "", value: "DuckNeck", checked: false },
        { title: "", value: "DuckWing", checked: false },
        { title: "", value: "DuckHead", checked: false },
        { title: "", value: "DuckProtein", checked: false },
        { title: "", value: "DuckHeart", checked: false },
      ],
    },
    // Exotic: {
    //   title: "Exotic",
    //   value: false,
    //   subRestrictions: [{ title: "", value: "Elk", checked: false }],
    // },
    Elk: {
      title: "Elk",
      value: false,
      subRestrictions: [],
    },
    Fur: { title: "Fur", value: false, subRestrictions: [] },
    HeavyChews: { title: "Heavy Chews", value: false, subRestrictions: [] },
    Lamb: {
      title: "Lamb",
      value: false,
      subRestrictions: [
        { title: "", value: "LambFemur", checked: false },
        { title: "", value: "LambTrotter", checked: false },
        { title: "", value: "LambSpleen", checked: false },
        { title: "", value: "LambEars", checked: false },
        { title: "", value: "LambGullet", checked: false },
        { title: "", value: "LambLung", checked: false },
        { title: "", value: "LambTrachea", checked: false },
        { title: "", value: "LambWeasend", checked: false },
      ],
    },
    Organ: { title: "Organ", value: false, subRestrictions: [] },
    Pork: {
      title: "Pork",
      value: false,
      subRestrictions: [
        { title: "", value: "PorkHeart", checked: false },
        { title: "", value: "PorkKidney", checked: false },
        { title: "", value: "PorkLiver", checked: false },
        { title: "", value: "PorkLoin", checked: false },
        { title: "", value: "PigEar", checked: false },
        { title: "", value: "PigFoot", checked: false },
        { title: "", value: "PigSkin", checked: false },
        { title: "", value: "PigFeet", checked: false },
      ],
    },
    Protein: {
      title: "Protein",
      value: false,
      subRestrictions: [{ title: "", value: "Boar", checked: false }],
    },
    Rabbit: {
      title: "Rabbit",
      value: false,
      subRestrictions: [
        { title: "", value: "RabbitEars", checked: false },
        { title: "", value: "RabbitFeet", checked: false },
      ],
    },
    Seafood: {
      title: "Seafood",
      value: false,
      subRestrictions: [
        { title: "", value: "Capelin", checked: false },
        { title: "", value: "Rockfish", checked: false },
        { title: "", value: "SalmonChunks", checked: false },
        { title: "", value: "SalmonSkin", checked: false },
        { title: "", value: "Smelt", checked: false },
        { title: "", value: "Anchovies", checked: false },
        { title: "", value: "AlaskanPollock", checked: false },
        { title: "", value: "Pollock", checked: false },
        { title: "", value: "Sardines", checked: false },
      ],
    },
    Shellfish: {
      title: "Shellfish",
      value: false,
      subRestrictions: [
        {
          title: "",
          value: "GreenLippedMussels",
          checked: false,
        },
      ],
    },
    Turkey: {
      title: "Turkey",
      value: false,
      subRestrictions: [
        { title: "", value: "TurkeyBreast", checked: false },
        { title: "", value: "TurkeyGizzard", checked: false },
        { title: "", value: "TurkeyWing", checked: false },
        { title: "", value: "TurkeyNeck", checked: false },
      ],
    },
  };

  const [dog, setDog] = useState({});

  const [open, setOpen] = useState(false);
  const [restrictions, setRestrictions] = useState(restrictionsSkeleton);

  const {
    loading: dogLoading,
    data: dogData,
    error: dogError,
    refetch,
  } = useQuery(GET_MONGO_DOG, {
    variables: {
      id: id,
    },
    fetchPolicy: "no-cache",
  });

  const [setRestrictionsMutation, { loading: updateStripeLoading }] =
    useMutation(SET_RESTRICTIONS);

  const [updateDogRestrictions, { loading, data, error }] = useMutation(
    UPDATE_DOG_RESTRICTION
  );

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  useEffect(() => {
    if (Object.keys(dog).length !== 0) {
      setDogRestrictions();
    }
  }, [dog]);

  useEffect(() => {
    if (dogData) {
      setDog(dogData.getMongoDog);
    }
  }, [dogLoading, dogData, dogError]);

  const handleChangeRestriction = (item, index) => {
    let copyObj = JSON.parse(JSON.stringify(restrictions));
    copyObj[item].value = !copyObj[item].value;
    if (copyObj[item].value) {
      copyObj[item].subRestrictions.map((element) => (element.checked = true));
    } else {
      copyObj[item].subRestrictions.map((element) => (element.checked = false));
    }
    setRestrictions(copyObj);
  };

  const onHandleSubRestriction = (item, index) => {
    let copyObj = JSON.parse(JSON.stringify(restrictions));

    copyObj[item].subRestrictions[index].checked =
      !copyObj[item].subRestrictions[index].checked;
    setRestrictions(copyObj);
  };

  const setDogRestrictions = () => {
    let copyObj = JSON.parse(JSON.stringify(restrictionsSkeleton));
    dog.restrictions.forEach((element, index) => {
      if (copyObj[element]) {
        copyObj[element].value = true;
      }
    });
    dog.sub_restrictions.forEach((element, index) => {
      let updateElement = copyObj[findKey(element)]?.subRestrictions.find(
        (item) => item.value === element
      );
      if (updateElement) {
        updateElement.checked = true;
      }
    });
    setRestrictions(copyObj);
  };

  const findKey = (subValue) => {
    const parent = Object.keys(restrictions).find((element) =>
      restrictions[element].subRestrictions
        .map((item) => item.value)
        .includes(subValue)
    );
    return parent;
  };

  const onResetForm = () => {
    setDogRestrictions();
  };

  const confirmChanges = () => {
    if (subId) {
      updateSubscriptionRestriction();
    } else {
      updateMongoRestriction();
    }
  };

  const updateMongoRestriction = () => {
    updateDogRestrictions({
      variables: {
        dogId: dog.id,
        restrictions: getOnlyRestrictions(),
        subRestrictions: getOnlySubRestrictions(),
      },
    })
      .then(() => {
        setOpen(false);
        enqueueSnackbar("Restrictions Updated", {
          variant: "success",
        });
      })
      .catch(() => {
        setOpen(false);
        enqueueSnackbar("Restrictions could not Updated", { variant: "error" });
      });
  };

  const updateSubscriptionRestriction = () => {
    setRestrictionsMutation({
      variables: {
        subId: subId,
        restrictions: getOnlyRestrictions(),
        subRestrictions: getOnlySubRestrictions(),
      },
    })
      .then(() => {
        setOpen(false);
        enqueueSnackbar("Restrictions Updated", {
          variant: "success",
        });
      })
      .catch(() => {
        setOpen(false);
        enqueueSnackbar("Restrictions could not Updated", { variant: "error" });
      });
  };

  const getOnlyRestrictions = () => {
    let restCopy = Object.keys(restrictions)
      .filter((element) => restrictions[element].value)
      .map((element) => element);
    return restCopy;
  };

  const getOnlySubRestrictions = () => {
    let subList = [];
    let subArrOfArr = Object.keys(restrictions).map(
      (element) => restrictions[element].subRestrictions
    );
    subArrOfArr.forEach((element) => {
      element.forEach((item) => {
        subList.push(item);
      });
    });

    return subList.filter((item) => item.checked).map((item) => item.value);
  };

  const isGreen = (header) => {
    let flag = false;
    dog.sub_restrictions.forEach((item) => {
      if (findKey(item) === header) {
        flag = true;
      }
    });
    if (dog.restrictions.includes(header) || flag) {
      return true;
    }

    return false;
  };
  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        size={"small"}
        onClick={() => {
          setOpen(true);
        }}
      >
        Restrictions
      </Button>
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={open}
          onClose={() => {
            setOpen(true);
          }}
          aria-labelledby="form-dialog-title"
        >
          {dogLoading || Object.keys(dog).length === 0 ? (
            <LinearProgress />
          ) : (
            <div>
              <DialogTitle id="form-dialog-title">Restrictions</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Saved Restrictions On Member Data
                </DialogContentText>
                <DialogContentText>
                  Restrictions:{" "}
                  {dog.restrictions.map((item) => {
                    return (
                      <Chip
                        className={classes.chip}
                        size="small"
                        label={item}
                      />
                    );
                  })}
                </DialogContentText>
                <DialogContentText stype={{ overflow: "text-wrap" }}>
                  Sub Restrictions:{" "}
                  {dog.sub_restrictions.map((item) => {
                    return (
                      <Chip
                        className={classes.chip}
                        size="small"
                        label={item}
                      />
                    );
                  })}
                </DialogContentText>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  style={{
                    marginTop: 10,
                  }}
                >
                  {Object.keys(restrictions).map((item, index) => {
                    return (
                      <Grid item md={3}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                color={isGreen(item) ? "secondary" : "primary"}
                              />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              {restrictions[item].title.toUpperCase()}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={restrictions[item].value}
                                  onClick={() => {
                                    handleChangeRestriction(item, index);
                                  }}
                                  name={item}
                                  inputProps={{
                                    "aria-label": "secondary checkbox",
                                  }}
                                  color={"secondary"}
                                />
                              }
                              label={item}
                            />
                            {restrictions[item].subRestrictions.map(
                              (element, j) => {
                                return (
                                  <FormControlLabel
                                    style={{ marginLeft: "10px" }}
                                    control={
                                      <Switch
                                        checked={element.checked}
                                        onClick={() => {
                                          onHandleSubRestriction(item, j);
                                        }}
                                        name={element.value}
                                        inputProps={{
                                          "aria-label": "secondary checkbox",
                                        }}
                                        color={"secondary"}
                                      />
                                    }
                                    label={element.value}
                                  />
                                );
                              }
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    );
                  })}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  color="primary"
                  variant="outlined"
                  className="redborder"
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                    onResetForm();
                  }}
                  disabled={loading}
                  color="primary"
                >
                  RESET Changes
                </Button>
                <Button
                  onClick={() => {
                    confirmChanges();
                  }}
                  color="primary"
                >
                  {loading || updateStripeLoading ? (
                    <CircularProgress />
                  ) : (
                    "Save"
                  )}
                </Button>
              </DialogActions>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default RestrictionsOfDogs;
