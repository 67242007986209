import "date-fns";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Grid from "@mui/material/Grid";

import AdapterDateFns from "@date-io/date-fns";
// import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";

import Backdrop from "@mui/material/Backdrop";
import {
  PAUSE_SUB,
  ADD_SUBSCRIPTION_TRIAL_PERIOD,
} from "../../../../../queries";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { fontSize } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  backdropShippingCalc: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000",
    position: "absolute",
  },
}));
export default function PauseSubscription({ subscription, refetch }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  console.log("subscription");
  console.log(subscription);
  const [open, setOpen] = useState(false);
  const [pauseMutation, { loading, data, error }] = useMutation(PAUSE_SUB);
  const [selectedDate, setSelectedDate] = useState(
    new Date(subscription.current_period_end * 1000)
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirmPause = () => {
    const unixTime = moment(selectedDate).unix();
    // return
    pauseMutation({
      variables: {
        id: subscription.id,
        input: {
          pause_collection: {
            resumes_at: unixTime,
          },
        },
      },
    })
      .then((res) => {
        enqueueSnackbar(
          `Subscription paused until ${moment(selectedDate).format(
            "MMM Do YY"
          )}`,
          {
            variant: "success",
          }
        );
        handleClose();
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(`Subscription paused error`, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Button
        fullWidth
        size={"small"}
        variant={"outlined"}
        style={{
          marginTop: 10,
          color: "#ffca02",
          borderColor: "#ffca02",
        }}
        onClick={handleClickOpen}
      >
        {subscription.pause_collection
          ? "Update Resume Date"
          : "pause Subscription"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Pause subscription until the selected date"}
        </DialogTitle>
        <DialogContent>
          <p style={{ fontSize: "12px" }}>
            Note: When confirmed, no invoice will be generated on the selected
            therefore customer will not be charged. Ideally, pause date should
            be a day after the renew date of the subscription. This ensures that
            when the subscription renews the customer is not charged.
            <br />
            This does not change the billing cycle of the subscription.
          </p>
          <div style={{ height: 10 }}></div>

          <DesktopDatePicker
            minDate={new Date(subscription.current_period_end * 1000)}
            placeholder={"YYYY-MM-DD"}
            label="Select"
            inputFormat={"yyyy-MM-dd"}
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onConfirmPause();
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
        <Backdrop className={classes.backdropShippingCalc} open={loading}>
          <Typography
            variant={"subtitle1"}
            style={{ color: "white" }}
            align={"center"}
          >
            <CircularProgress color="primary" />
            <br />
            Please wait for Subscription pause
          </Typography>
        </Backdrop>
      </Dialog>
    </div>
  );
}

// const DatePicker = ({ selectedDate, handleDateChange, minDate }) => {
//   // The first commit of Material-UI
//   return (
//     <Grid container justifyContent="space-around">
//       <MobileDatePicker
//         margin="normal"
//         id="date-picker-dialog"
//         label="Select Date"
//         format="MM/dd/yyyy"
//         value={selectedDate}
//         onChange={handleDateChange}
//         KeyboardButtonProps={{
//           "aria-label": "change date",
//         }}
//         minDate={new Date(minDate)}
//       />
//     </Grid>
//   );
// };
