import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MobileDatePicker from "@mui/lab/MobileDatePicker";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FETCH_APPOINTMENTS_BY_DATE,
  GET_APPOINTMENT_BY_STATUS,
  GET_SUPPORT_MEMBERS,
  UPDATE_APPOINTMENT,
  ADD_NOTES_APPOINTMENT,
  ALLOW_STM,
  GET_AVAILABLE_SUPPORT_MEMBERS,
  GET_ACCOUNT_MANAGER,
} from "../../../queries";

import ScheduleSteps from "./Components/Stepper";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CustomizedMenus } from "./Components/Stepper";
import EditDialog from "./Components/Stepper/editDialog";
import TextField from "@mui/material/TextField";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  parent: {
    position: "relative",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000025",
    position: "absolute",
  },
  container: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "red",
  },
  containerDeclined: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "red",
  },
  containerPending: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "orange",
  },
  containerApproved: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "green",
  },
  containerReserved: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "green",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
}));

const Appointment = (props) => {
  const classes = useStyles();
  const statusArr = {
    canceled: "containerDeclined",
    pending: "containerPending",
    reserved: "containerReserved",
  };
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [open, setOpen] = React.useState(false);
  const [filterAppointment, setFilterAppointment] = useState("pending");
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedSupportMember, setSelectedSupportMember] = useState({});
  const [useDate, setUseDate] = useState(false);
  const [appointmentList, setAppointmentList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [selectedItem, setSelectedItem] = useState({ id: "" });

  const {
    loading: loadingAccountManager,
    data,
    error,
  } = useQuery(GET_ACCOUNT_MANAGER);

  const handleClickOpenDialog2 = (txt, item) => {
    setDialogText(txt);
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleCloseDialog2 = () => {
    setOpenDialog(false);
    setDialogText("");
    setSelectedItem({});
  };

  const [fetchAppointmentsByDate, { loading, refetch }] = useLazyQuery(
    FETCH_APPOINTMENTS_BY_DATE,
    {
      fetchPolicy: "no-cache",
      variables: {
        date: selectedDate,
      },
      onCompleted: (data) => {
        setAppointmentList(data.getAppointmentsByDate);
      },
    }
  );
  const [
    fetchAppointmentsByStatus,
    {
      loading: stateLoading,
      data: stateData,
      error: stateError,
      refetch: refetchFilter,
    },
  ] = useLazyQuery(GET_APPOINTMENT_BY_STATUS, {
    fetchPolicy: "no-cache",
    variables: {
      status: filterAppointment,
    },
    onCompleted: (data) => {
      setAppointmentList(data.getAppointmentsByStatus);
    },
  });

  const [addNotesToAppointment] = useMutation(ADD_NOTES_APPOINTMENT);

  const [updateAppointment] = useMutation(UPDATE_APPOINTMENT);

  const { loading: supportLoading, data: supportMemberData } =
    useQuery(GET_SUPPORT_MEMBERS);

  useEffect(() => {
    reFetchFunction();
  }, [selectedDate, filterAppointment, useDate]);

  const reFetchFunction = () => {
    if (useDate) {
      fetchAppointmentsByDate();
    } else {
      fetchAppointmentsByStatus({
        variables: {
          status: filterAppointment,
        },
      })
        .then((res) => {
          setAppointmentList(res.data.getAppointmentsByStatus);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCancelAppointment = async () => {
    const res = await updateAppointment({
      variables: {
        appointmentId: selectedItem.id,
        input: {
          status: "canceled",
        },
      },
    });
    if (res && res.data.updateAppointment !== null) {
      setOpenDialog(false);
      setDialogText("");
      setSelectedItem({});
      refetch();
    } else {
      setOpenDialog(false);
      setDialogText("");
      setSelectedItem({});
      refetch();
    }
  };

  const onApproveAppointment = async () => {
    const res = await updateAppointment({
      variables: {
        appointmentId: selectedItem.id,
        input: {
          status: "reserved",
        },
      },
    });
    if (res && res.data.updateAppointment !== null) {
      setOpenDialog(false);
      setDialogText("");
      setSelectedItem({});
      reFetchFunction();
    } else {
      setOpenDialog(false);
      setDialogText("");
      setSelectedItem({});
      reFetchFunction();
    }
  };
  const onSaveNotes = (item, text) => {
    // return;
    addNotesToAppointment({
      variables: {
        appointmentId: item.id,
        comment: text,
      },
    });
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading || stateLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        maxWidth={1200}
      >
        <Grid item sm={3}>
          {/* {supportMemberData && (
            <STMSelect membersCopy={supportMemberData.supportMembers || []} />
          )} */}
          <CreateSchadule memberId={props.session.activeAdmin.id} />
          <br />
          <Paper className={classes.paper}>
            Account Managers
            {!loading &&
              data?.accountManagers.map((item) => {
                return (
                  <Link
                    className={classes.link}
                    to={`/dashboard/asigned-consultation/${item.id}`}
                  >
                    <ListItem key={item.id} disablePadding>
                      <ListItemButton>
                        <ListItemText primary={`${item.name}`} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                );
              })}
          </Paper>
        </Grid>
        <Grid item sm={9}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <MobileDatePicker
              value={selectedDate}
              onChange={(date) => {
                handleDateChange(date);
                setUseDate(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                />
              )}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={!useDate}
                  onChange={() => setUseDate(!useDate)}
                  name="checkedB"
                  color="secondary"
                />
              }
              label="All"
            />
            <FilterButtons
              useDate={useDate}
              filterAppointment={filterAppointment}
              setFilterAppointment={(value) => {
                setFilterAppointment(value);
              }}
            />
            {!loading &&
              appointmentList &&
              appointmentList
                .filter((item) => {
                  if (filterAppointment === "all") {
                    return item;
                  }
                  if (
                    filterAppointment === "pending" &&
                    item.status === "pending"
                  ) {
                    return item;
                  }
                  if (
                    filterAppointment === "canceled" &&
                    item.status === "canceled"
                  ) {
                    return item;
                  }
                  if (
                    filterAppointment === "reserved" &&
                    item.status === "reserved"
                  ) {
                    return item;
                  }
                })
                .map((item) => {
                  return (
                    <Grid item xs={12}>
                      <Paper className={classes[statusArr[item.status]]}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>
                                {" "}
                                <div>
                                  <Button
                                    component={Link}
                                    to={`/dashboard/customer/${item.customer?.id}`}
                                    target="_blank"
                                    variant="outlined"
                                    startIcon={<AccountCircleIcon />}
                                  >
                                    {`${
                                      item.customer?.first_name
                                        ? item.customer?.first_name
                                        : "Name"
                                    } ${
                                      item.customer?.last_name
                                        ? item.customer?.last_name
                                        : "Surname"
                                    }`}
                                  </Button>
                                </div>
                              </Grid>
                              <Grid item>
                                <div>{`${item.period.substring(
                                  5,
                                  7
                                )}/${item.period.substring(
                                  8
                                )}/${item.period.substring(0, 4)}`}</div>
                                <br />
                                <div>{item.dateString}</div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={5}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>{`${item.support.name}`}</Grid>

                              <Grid item>
                                {item.status === "pending" && (
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                      let txt =
                                        `${
                                          item.customer?.first_name
                                            ? item.customer?.first_name
                                            : "Name"
                                        } ${
                                          item.customer?.last_name
                                            ? item.customer?.last_name
                                            : "Surname"
                                        } \n` +
                                        moment(
                                          new Date(item.dateTime * 1000)
                                        ).format("dddd, MMMM Do YYYY, h:mm a") +
                                        `-` +
                                        moment(
                                          new Date(
                                            (item.dateTime + 1800) * 1000
                                          )
                                        ).format("h:mm a");
                                      handleClickOpenDialog2(txt, item);
                                      // onApproveAppointment(item);
                                      // For Edit Purpose
                                      // handleClickOpen();
                                      // setSelectedAppointment(item);
                                    }}
                                  >
                                    <CheckCircleIcon />
                                  </IconButton>
                                )}
                                {item.status === "reserved" && (
                                  <EditDialog
                                    rawText={item.comment}
                                    onSave={(text) => {
                                      onSaveNotes(item, text);
                                    }}
                                  ></EditDialog>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                })}
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to reserve this pending appointment?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog2} color="primary">
            close
          </Button>
          <Button onClick={onCancelAppointment} color="primary">
            Decline
          </Button>
          <Button onClick={onApproveAppointment} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open && Object.keys(selectedAppointment).length !== 0}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {selectedAppointment?.customer?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                {moment(new Date(selectedAppointment.dateTime * 1000)).format(
                  "LT"
                )}
              </Grid>
              <Grid item>
                {!supportLoading && (
                  <CustomizedMenus
                    members={supportMemberData.supportMembers}
                    selectedSupportMember={selectedSupportMember}
                    setSelectedSupportMember={setSelectedSupportMember}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              onApproveAppointment();
            }}
            color="primary"
            autoFocus
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Appointment;

const FilterButtons = ({
  useDate,
  filterAppointment,
  setFilterAppointment,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Button
        disabled={!useDate}
        color={"secondary"}
        variant={filterAppointment === "all" ? "contained" : "outlined"}
        onClick={() => {
          setFilterAppointment("all");
        }}
      >
        All
      </Button>
      <Button
        color={"secondary"}
        variant={filterAppointment === "pending" ? "contained" : "outlined"}
        onClick={() => {
          setFilterAppointment("pending");
        }}
      >
        PENDING
      </Button>
      <Button
        color={"secondary"}
        variant={filterAppointment === "canceled" ? "contained" : "outlined"}
        onClick={() => {
          setFilterAppointment("canceled");
        }}
      >
        DECLINED
      </Button>
      <Button
        color={"secondary"}
        variant={filterAppointment === "reserved" ? "contained" : "outlined"}
        onClick={() => {
          setFilterAppointment("reserved");
        }}
      >
        ACCEPTED
      </Button>
      <Link to={"/dashboard/consultation/search"}>
        <Button
          color={"secondary"}
          variant={filterAppointment === "reserved" ? "contained" : "outlined"}
        >
          search by email
        </Button>
      </Link>
    </Grid>
  );
};

const CreateSchadule = ({ VerticalLinearStepper, memberId }) => {
  const [fetchAvailableMembers, { refetch }] = useLazyQuery(
    GET_AVAILABLE_SUPPORT_MEMBERS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        setCount(res.getAvailableSupportMembers.length);
      },
    }
  );
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    refetchUsers();
  }, [open]);

  const refetchUsers = () => {
    // fetchAvailableMembers()
    //   .then((res) => {
    //     if (res.data) {
    //       console.log("REFETCH =>");
    //       console.log(res.data);
    //       setCount(res.data.getAvailableSupportMembers.length);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    refetch();
  };

  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        color={"secondary"}
        onClick={handleClickOpen}
        style={{ marginTop: 20 }}
      >
        BOOK CONSULT
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogContent>
          <ScheduleSteps
            close={handleClose}
            memberId={memberId}
            refetchUsers={() => {
              console.log("PleaseREFETCH =>");

              refetchUsers();
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const STMSelect = ({ membersCopy }) => {
  const [expanded, setExpanded] = useState(false);
  const [members, setMembers] = useState(
    JSON.parse(JSON.stringify(membersCopy))
  );

  const [updateSTMAccess] = useMutation(ALLOW_STM);

  const handleToggle = (index) => {
    let copy = JSON.parse(JSON.stringify(members));
    copy[index].canAddSchedule = !copy[index].canAddSchedule;
    setMembers(copy);
  };

  const onConfirm = () => {
    updateSTMAccess({
      variables: {
        input: members.map((item) => {
          return {
            supportMemberId: item.id,
            canAddSchedule: item.canAddSchedule,
          };
        }),
      },
    })
      .then((res) => {
        setExpanded(false);
      })
      .catch((error) => {});
  };

  return (
    <div>
      <Accordion expanded={expanded} onClick={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Members</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List
            dense
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {members.map((value, index) => {
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <ListItem
                  key={value}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={(e) => {
                        e.stopPropagation();
                        setExpanded(true);
                        handleToggle(index);
                      }}
                      checked={value.canAddSchedule}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar alt={`Avatar n°${value + 1}`} src={value.img} />
                    </ListItemAvatar>
                    <ListItemText id={labelId} primary={`${value.name}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={onConfirm}
          >
            Update
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
