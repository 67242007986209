import React, { forwardRef, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Grid, LinearProgress, MenuItem, TextField } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { CHECK_USER_EXIST, CREATE_CUSTOMER } from "../../../../../queries";
import { CUSTOMER_TYPES } from "../../../../../globals";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MIN_NAME_LENGTH = 2;
const PHONE_LENGTH = 12;

export default function CreateNewCustomer({ refetch }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastNAme] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [customerType, setCustomerType] = useState("");

  const [checkIfUserExists, { loading: loadingIfUserExist }] = useMutation(
    CHECK_USER_EXIST,
    {
      onError: (err) => {
        const { message } = JSON.parse(JSON.stringify(err));
        enqueueSnackbar(message, { variant: "error" });
      },
    }
  );
  const [createCustomerMutaion, { loading }] = useMutation(CREATE_CUSTOMER, {
    onCompleted: (res) => {
      console.log(res);
      enqueueSnackbar("Customer Created Successfully", { variant: "Success" });
      refetch();
      handleClose();
    },
    onError: (err) => {
      const { message } = JSON.parse(JSON.stringify(err));
      enqueueSnackbar(message, { variant: "error" });
    },
  });

  useEffect(() => {
    if (open) {
      initialState();
    }
  }, [open]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (loading || loadingIfUserExist) {
      return;
    }
    setOpen(false);
  };

  const initialState = () => {
    setFirstName("");
    setLastNAme("");
    setEmail("");
    setPhone("");
    setCustomerType("");
  };

  const onCreateCustomer = () => {
    if (!validateForm()) {
      return;
    }
    checkIfUserExists({
      variables: {
        email: email,
        phone: onlyPhoneNumber(),
      },
    }).then((res) => {
      console.log(res);
      if (res.data?.userExists == false) {
        console.log("CAN CREATE CUSTOMER");
        const input = createInput();
        createCustomerMutaion({
          variables: {
            input: input,
          },
        });
      }
      if (res.data?.userExists == true) {
        enqueueSnackbar("The user with email or phone already exits", {
          variant: "error",
        });
      }
    });
  };

  const validateForm = () => {
    if (firstName.length < MIN_NAME_LENGTH) {
      enqueueSnackbar("First Name min length 2", { variant: "error" });
      return false;
    }
    if (lastName.length < MIN_NAME_LENGTH) {
      enqueueSnackbar("Last Name min length 2", { variant: "error" });
      return false;
    }
    if (!validateEmail(email)) {
      enqueueSnackbar("Email is invalid", { variant: "error" });
      return false;
    }
    if (!isPossiblePhoneNumber(phone)) {
      enqueueSnackbar("Phone number is invalid", { variant: "error" });
      return false;
    }
    if (customerType === "") {
      enqueueSnackbar("Please select customer type", { variant: "error" });
      return false;
    }
    return true;
  };

  const createInput = () => {
    return {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email,
      phone: onlyPhoneNumber(),
      type: customerType,
    };
  };

  const validateEmail = (email) => {
    const re =
      // eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };
  const isPossiblePhoneNumber = (p) => {
    let newPhone = p.replaceAll(" ", "");
    newPhone = newPhone.replaceAll("-", "");
    newPhone = newPhone.replaceAll("(", "");
    newPhone = newPhone.replaceAll(")", "");
    console.log(newPhone);
    onlyPhoneNumber();
    return true;
    return newPhone.length === PHONE_LENGTH;
  };

  const onlyPhoneNumber = () => {
    let newPhone = phone.replaceAll(" ", "");
    newPhone = newPhone.replaceAll("-", "");
    newPhone = newPhone.replaceAll("(", "");
    newPhone = newPhone.replaceAll(")", "");
    return newPhone;
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create New Customer
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Create New Customer"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                height: 10,
              }}
            ></div>
            {(loading || loadingIfUserExist) && <LinearProgress />}
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item sm={6} xs={12}>
                <TextField
                  disabled={loading || loadingIfUserExist}
                  fullWidth
                  variant="outlined"
                  value={firstName}
                  label={"First Name"}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  disabled={loading || loadingIfUserExist}
                  fullWidth
                  variant="outlined"
                  value={lastName}
                  label={"Last Name"}
                  onChange={(e) => setLastNAme(e.target.value)}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  disabled={loading || loadingIfUserExist}
                  fullWidth
                  variant="outlined"
                  value={email}
                  label={"Email Address"}
                  onChange={(e) =>
                    setEmail(e.target.value.toLocaleLowerCase().trim())
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <MuiPhoneNumber
                  disabled={loading || loadingIfUserExist}
                  defaultCountry={"us"}
                  label="Phone Number"
                  sx={{ margin: "20px 0px" }}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e);
                  }}
                  fullWidth
                  disableDropdown={true}
                  id="outlined-disabled"
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  disabled={loading || loadingIfUserExist}
                  select
                  label="Customer Type"
                  value={customerType}
                  onChange={(e) => {
                    setCustomerType(e.target.value);
                  }}
                  fullWidth
                >
                  {CUSTOMER_TYPES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            disabled={loading || loadingIfUserExist}
          >
            Cancel
          </Button>
          <Button
            onClick={onCreateCustomer}
            disabled={loading || loadingIfUserExist}
          >
            Create User
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
