import { gql } from "@apollo/client";

const GET_CUSTOMERS = gql`
  query ($offset: Int, $limit: Int, $order: String) {
    customers(offset: $offset, limit: $limit, order: $order) {
      id
      name
      email
      phone_number
      family_name
      username
      created_at
      premium
      feedrealPremium
      ref {
        id
        username
      }
      userId
      stripe {
        id
        subscription {
          id
        }
      }
    }
    customersCount
  }
`;

const GET_CUSTOMER = gql`
  query ($id: ID!) {
    customer(id: $id) {
      id
      name
      email
      username
      premium
      feedrealPremium
      phone_number
      refPoint
      affiliatePoint
      isAffiliate
      created_at
      communication
      communicationPermissions {
        email
        sms
        push
      }
      type
      wallet {
        coins
      }
      accountManager {
        email
        surname
        img
        name
        id
      }
      ref {
        id
        username
      }
      stripe {
        id
        balance
        sources {
          id
          last4
        }
        default_source {
          id
          last4
        }
        subscription {
          id
          status
          metadata
          items
          current_period_end
          pause_collection
          cancel_at
          canceled_at
          cancel_at_period_end
        }
        order {
          id
          amount
          metadata
        }
      }
    }
  }
`;

const CUSTOMER_FIND = gql`
  query ($email: String, $phone_number: String) {
    customersFind(email: $email, phone_number: $phone_number) {
      id
      name
      email
      phone_number
      username
      created_at
    }
  }
`;

const GET_CHECKOUT_PLANS = gql`
  query GetCheckoutPlans {
    checkoutPlans {
      id
      amount
      product {
        id
        name
        description
        package_dimensions {
          weight
          height
          length
          width
        }
      }
    }
  }
`;

const ADDMEMBERSHIP = gql`
  mutation createMembership(
    $id: ID
    $premiumInterval: Int
    $cycleCount: Int
    $cancelEndOfCycle: Boolean
  ) {
    createMembershipSubscriptionAdmin(
      id: $id
      premiumInterval: $premiumInterval
      cycleCount: $cycleCount
      cancelEndOfCycle: $cancelEndOfCycle
    ) {
      id
      metadata
    }
  }
`;

const CALCULATESHIPPING = gql`
  query GetCancelPremiumShippingRate($id: ID) {
    getRateForCancel(id: $id) {
      rate {
        serviceName
        shipmentCost
        length
        weight
      }
      dogName
      description
    }
  }
`;

const GET_RATE_ADMIN = gql`
  mutation getRateAdmin($input: RateInputAdmin) {
    getRateAdmin(input: $input) {
      serviceName
      serviceCode
      shipmentCost
      length
      height
      width
      weight
      bagsLength
    }
  }
`;

const UPDATE_QUANTITY = gql`
  mutation updateQuantity($input: inputSubscriptionQuantity) {
    updateSubscriptionQuantity(input: $input) {
      id
    }
  }
`;

const CUSTOMER_ADDRESSES = gql`
  query OwnerAddress($owner: ID!) {
    ownerAddress(owner: $owner) {
      id
      name
      address
      address2
      city
      state
      postal_code
      status
    }
  }
`;

const CREATE_ADDRESS = gql`
  mutation CreateAddress($email: String, $input: inputShippingAddress) {
    createAddress(email: $email, input: $input, website: "member") {
      id
      name
      address
      address2
      city
      state
      postal_code
    }
  }
`;

const UPDATE_ADDRESS = gql`
  mutation UpdateAdress($id: ID!, $input: inputUpdateShippingAddress) {
    updateAddress(id: $id, input: $input) {
      id
      name
      address
      address2
      city
      state
      postal_code
      status
    }
  }
`;

const UPDATE_MONGO_ADDRESS = gql`
  mutation UpdateAdress($id: ID!, $input: inputUpdateShippingAddress) {
    updateAddressWithoutDog(id: $id, input: $input) {
      id
      name
      address
      address2
      city
      state
      postal_code
      status
    }
  }
`;

const CONFIRM_UPDATE_ADDRESS = gql`
  mutation UpdateDogAddress($addressId: String, $input: UpdateAddressInput) {
    updateDogAddresses(addressId: $addressId, input: $input) {
      id
    }
  }
`;

const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: ID) {
    deleteAddress(id: $id)
  }
`;

const UPDATE_CUSTOMER_USERNAME = gql`
  mutation Username($id: ID, $username: String) {
    updateMongoCustomerUsername(id: $id, username: $username) {
      email
      username
    }
  }
`;

const CREATE_USE_POINTS = gql`
  mutation CreateUsedPoints($input: inputCreateUsedPoints) {
    createUsedPoints(input: $input) {
      id
      memberID
      usedPoint
      pointType
      remainingPoints
      details
      createdAt
    }
  }
`;

const GET_ACTIVE_USERS = gql`
  query GetActiveCustomers {
    activeCustomers
  }
`;

const GET_ALL_CUSTOMERS_COUNT = gql`
  query GetAllCustomers {
    customersCount
  }
`;

const ADD_CUSTOMER_BALANCE = gql`
  mutation CreateCustomerBalance(
    $id: ID
    $charge: Boolean
    $input: CustomerBalanceInput
  ) {
    createCustomerBalance(id: $id, charge: $charge, input: $input) {
      id
    }
  }
`;

const CUSTOMER_NOTES = gql`
  query ($id: ID!) {
    customerNotes(id: $id) {
      id
      createdAt
      admin {
        name
      }
      note
    }
  }
`;

const CUSTOMER_NOTES_ADD = gql`
  mutation (
    $admin_id: ID!
    $note: String!
    $customer_id: ID!
    $createdAt: Int!
  ) {
    customerNoteAdd(
      data: {
        admin_id: $admin_id
        note: $note
        customer_id: $customer_id
        createdAt: $createdAt
      }
    ) {
      note
    }
  }
`;

const CUSTOMER_GET_ORDERS = gql`
  query GetOrders($email: String) {
    getShippingDetail(email: $email) {
      orderDate
      shipDate
      shipTo
      orderStatus
      shippingAmount
      customerNotes
      dimensions
      weight
    }
  }
`;

const GET_REF_LINKS = gql`
  query RefLinks($id: ID) {
    getAssociatedLinks(id: $id) {
      id
      username
      email
      phone_number
      created_at
    }
  }
`;

const CONNECT_REF = gql`
  mutation Link2Accounts($id: ID, $ref: ID) {
    linkRef2Accounts(id: $id, ref: $ref) {
      id
      email
      ref
    }
  }
`;

const UPDATE_COM_METHOD = gql`
  mutation UpdateComMethod($id: ID, $communication: Int) {
    updateMongoCommunicationMethod(id: $id, communication: $communication) {
      id
      communication
    }
  }
`;

const UPDATE_COM_PERMISSONS = gql`
  mutation UpdateComMethod(
    $id: ID!
    $sms: Boolean
    $email: Boolean
    $push: Boolean
  ) {
    updateCommunicationPermissions(
      id: $id
      sms: $sms
      email: $email
      push: $push
    ) {
      id
      communicationPermissions {
        sms
        email
        push
      }
    }
  }
`;

const ANALYTICS_MEMBERSHIP = gql`
  query ($category: String) {
    analyticsMembersip(category: $category) {
      daily
      weekly
      monthly
    }
  }
`;

const MEMBERSHIP_SEARCH = gql`
  query ($key: String!, $category: String) {
    findMembership(key: $key, category: $category) {
      id
      name
      email
      phone_number
      subscriptions {
        type
        months
        data {
          status
          sub_date
        }
      }
    }
  }
`;

const MEMBERSHIP_LIST = gql`
  query ($offset: Int!, $limit: Int!, $category: String) {
    membershipList(offset: $offset, limit: $limit, category: $category) {
      id
      email
      name
      subscriptions {
        type
        data {
          status
          createdAt
        }
      }
    }
    membershipListCount(category: $category) {
      _id
      count
    }
  }
`;

const MEMBERSHIP_LIST_FILTER_LENGTH = gql`
  query ($category: String!) {
    membershipListCount(category: $category) {
      _id
      count
    }
  }
`;

const FEEDREAL_MEMBERSHIP = gql`
  mutation CreateFeedrealSubscriptionAdmin($input: InputFeedRealMembership) {
    createFeedrealSubscriptionAdmin(input: $input) {
      status
    }
  }
`;

const CANCEL_FEEDREAL_MEMBERSHP = gql`
  mutation CancelFeedrealSubscriptionAdmin(
    $customer: ID
    $cancel_at_period_end: Boolean
  ) {
    cancelFeedrealSubscriptionAdmin(
      customer: $customer
      cancel_at_period_end: $cancel_at_period_end
    ) {
      status
    }
  }
`;

const UPDATE_EMAIL_OR_PHONE = gql`
  mutation UpdateCustomerDetails($id: ID!, $input: inputMongoCustomer) {
    updateCustomerDetails(id: $id, input: $input) {
      name
      email
      phone_number
    }
  }
`;
const GET_CUSTOMER_WALLET_LOGS = gql`
  query GetCustomerWalletLogs($id: ID!, $limit: Int, $offset: Int) {
    customerWalletLogs(id: $id, limit: $limit, offset: $offset) {
      id
      customer {
        id
        name
        email
      }
      admin {
        id
        name
        email
      }
      type
      amount
      message
      meta
      createdAt
    }
  }
`;

const GET_TOP_AFFILIATES = gql`
  query TopAffiliates($time: Int) {
    getLastTopAffiliates(time: $time) {
      id
      createdAt
      list {
        id
        total
        name
      }
    }
  }
`;
const CREATE_TOP_REFS = gql`
  mutation TopRefs($time: Int!) {
    createLastTopRefs(time: $time) {
      list {
        name
        total
      }
      createdAt
    }
  }
`;
const CHECK_USER_EXIST = gql`
  mutation UserExists($email: String, $phone: String) {
    userExists(email: $email, phone: $phone)
  }
`;

const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CreateCustomerInput) {
    createCustomer(input: $input) {
      mongoCustomer {
        id
      }
    }
  }
`;

const GET_CUSTOMERS_WORKSHOPS = gql`
  query GetCustomerWorkshopSchedules($customerId: ID) {
    getCustomerWorkshopSchedules(customerId: $customerId) {
      id
      startTime
      endTime
      courseId
      addOnId
      createdAt
      updatedAt
      name
      scheduleDate
      status
      info
      attendance
    }
  }
`;
const GET_WORKSHOPS = gql`
  query GetWorkshps($customerId: ID) {
    getWorkshops(customerId: $customerId) {
      id
      name
      description
      price
      times {
        endTime
        startTime
        status
        quota
        comingSoon
      }
      endTime
      startTime
      status
    }
  }
`;

export const ADMIN_BOOK_WORKSHOP = gql`
  mutation AdminBookWorkshop(
    $customerId: ID
    $startTime: Int
    $workshopId: ID
    $chargeOption: Boolean
  ) {
    adminBookWorkshop(
      customerId: $customerId
      startTime: $startTime
      workshopId: $workshopId
      chargeOption: $chargeOption
    ) {
      id
    }
  }
`;

const RESCHEDULE_WORKSHOP = gql`
  mutation RescheduleCustomersBook(
    $customerId: ID
    $prevId: ID
    $nextStartTime: Int
  ) {
    rescheduleCustomersBook(
      customerId: $customerId
      prevId: $prevId
      nextStartTime: $nextStartTime
    ) {
      id
    }
  }
`;
const CANCEL_WORKSHOP = gql`
  mutation CancelWorkshop($id: ID) {
    cancelWorkshop(id: $id) {
      id
    }
  }
`;
export {
  GET_CUSTOMERS,
  GET_CUSTOMER,
  CUSTOMER_FIND,
  ADDMEMBERSHIP,
  GET_CHECKOUT_PLANS,
  CALCULATESHIPPING,
  GET_RATE_ADMIN,
  UPDATE_QUANTITY,
  CUSTOMER_ADDRESSES,
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  UPDATE_MONGO_ADDRESS,
  CONFIRM_UPDATE_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_CUSTOMER_USERNAME,
  CREATE_USE_POINTS,
  GET_ACTIVE_USERS,
  GET_ALL_CUSTOMERS_COUNT,
  ADD_CUSTOMER_BALANCE,
  CUSTOMER_NOTES,
  CUSTOMER_NOTES_ADD,
  CUSTOMER_GET_ORDERS,
  GET_REF_LINKS,
  CONNECT_REF,
  UPDATE_COM_METHOD,
  ANALYTICS_MEMBERSHIP,
  MEMBERSHIP_SEARCH,
  MEMBERSHIP_LIST,
  MEMBERSHIP_LIST_FILTER_LENGTH,
  FEEDREAL_MEMBERSHIP,
  CANCEL_FEEDREAL_MEMBERSHP,
  UPDATE_EMAIL_OR_PHONE,
  GET_CUSTOMER_WALLET_LOGS,
  GET_TOP_AFFILIATES,
  CREATE_TOP_REFS,
  CHECK_USER_EXIST,
  CREATE_CUSTOMER,
  GET_WORKSHOPS,
  GET_CUSTOMERS_WORKSHOPS,
  RESCHEDULE_WORKSHOP,
  CANCEL_WORKSHOP,
  UPDATE_COM_PERMISSONS,
};
