import React, { Component, useEffect, useState } from "react";
import { useQuery, NetworkStatus } from "@apollo/client";
import { GET_SHORTLEASHES } from "../../../queries";
import moment from "moment";
import Edit from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import AutoSearch from "./Components/filter/search";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Breadcrumbs,
  Typography,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  TableFooter,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";

function ShortleashList(props) {
  const [start, setStart] = useState(0);
  const [pageSize, setPageSize] = useState(10); //end value
  // query get data
  const { subscribeToMore, loading, error, data, refetch, fetchMore } =
    useQuery(GET_SHORTLEASHES, {
      variables: {
        start,
        end: pageSize,
      },
    });

  const [shortleashData, setShortleashData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [postsLength, setPostsLength] = useState(0);

  useEffect(() => {
    if (data) {
      setShortleashData(data.shortleashLimitAdmin);
      setHasNextPage(data.shortleashAllFieldsAdmin.hasNextPage);
      setPostsLength(data.shortleashAllFieldsAdmin.length);
    }
  }, [data, shortleashData]);

  const hasNextFunc = (event) => {
    setStart(start >= 0 ? start + pageSize : 0);
    refetch();
  };

  const hasPrevFunc = (event) => {
    setStart(start >= 10 ? start - 10 : 0);
    refetch();
  };

  const pageSizeFunc = (event) => {
    const { value } = event.target;
    if (value >= 5) {
      setPageSize(value);
      refetch();
    }
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;
  const { permission } = props.session.activeAdmin;

  return (
    <div>
      {shortleashData.length > 0 ? (
        <ShortleashListUI
          permission={permission}
          shortleashData={shortleashData}
          refetch={refetch}
          history={props.history}
          hasNext={hasNextPage}
          lengthPosts={postsLength}
          start={start}
          pageSize={pageSize}
          hasPrevFunc={hasPrevFunc}
          hasNextFunc={hasNextFunc}
          pageSizeFunc={pageSizeFunc}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export class ShortleashListUI extends Component {
  componentDidMount() {
    this.props.refetch();
    // console.log(this.props);
  }
  render() {
    const {
      permission,
      lengthPosts,
      hasNext,
      start,
      hasPrevFunc,
      hasNextFunc,
      pageSize,
      pageSizeFunc,
    } = this.props;
    const god = permission.god ? permission.god.admin : false;
    const author = permission.author ? permission.author : false;
    return (
      <div>
        <div className="pagetop">
          <div className="left">
            <Breadcrumbs aria-label="breadcrumb">
              <a color="inherit" href="/">
                Dashboard
              </a>
              <Typography color="textPrimary">Newsletter</Typography>
            </Breadcrumbs>
          </div>
          {god || author.admin || author.write ? (
            <div className="right">
              <Button
                component={Link}
                variant="outlined"
                color="secondary"
                to="newsletter/add"
              >
                Add Newsletter
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <AutoSearch
            history={this.props.history}
            edit={god || author.admin || author.write}
          />
        </div>
        <ul>
          {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="right">Author</TableCell>
                    <TableCell align="right">Website</TableCell>
                    {/* <TableCell align="right">Created Date</TableCell>
                    <TableCell align="right">Updated Date</TableCell> */}
                    {god || author.admin || author.write ? (
                      <TableCell align="right"></TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.shortleashData.map((shortleash) => (
                    <TableRow
                      key={shortleash.id}
                      //className={shortleash.locked ? "disabled" : "active"}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        {shortleash.title}
                        {
                          // {shortleash.locked ? (
                          //   <div className="authorlocked">
                          //     {shortleash.admin.name} is editing now...
                          //   </div>
                          // ) : (
                          //   ""
                          // )}
                        }
                      </TableCell>
                      <TableCell align="center">
                        <div
                          className={
                            shortleash.status ? "published" : "archived"
                          }
                        >
                          {shortleash.status ? "Published" : "Archived"}
                        </div>
                      </TableCell>

                      {/* <TableCell align="right">
                        {moment.unix(shortleash.updatedAt).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="right">
                        {moment.unix(shortleash.updatedAt).format("DD/MM/YYYY")}
                      </TableCell> */}
                      <TableCell align="right">
                        {shortleash.admin.name}
                      </TableCell>
                      <TableCell align="right">{shortleash.website}</TableCell>
                      {god || author.admin || author.write ? (
                        <TableCell align="right">
                          <Button
                            component={Link}
                            variant="outlined"
                            color="secondary"
                            to={`/dashboard/newsletter/edit/${shortleash.id}`}
                            //disabled={shortleash.locked}
                          >
                            <Edit />
                          </Button>
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {start >= 10 && (
                      <TableCell align="left" onClick={hasPrevFunc}>
                        Back
                      </TableCell>
                    )}
                    <TableCell align="center">
                      Page {start + pageSize}/{lengthPosts}
                    </TableCell>
                    <TableCell align="center">
                      <FormControl fullWidth>
                        <InputLabel id="caninePageSize">Page Size</InputLabel>
                        <Select
                          labelId="caninePageSize"
                          id="caninePageSize"
                          label="Page Size"
                          value={pageSize}
                          onChange={pageSizeFunc}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    {hasNext && (
                      <TableCell align="right" onClick={hasNextFunc}>
                        Next
                      </TableCell>
                    )}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          }
        </ul>
      </div>
    );
  }
}

export default ShortleashList;
