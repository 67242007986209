import React, { Component } from "react";
import { Mutation } from "@apollo/client/react/components";
import { FIRST_LOGIN } from "../../../../queries";
import { withRouter } from "react-router-dom";

import {
  Button,
  CssBaseline,
  TextField,
  Container,
  CircularProgress,
} from "@mui/material";

const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

const initialState = {
  password: "",
  passwordRepeat: "",
  pass: 0,
};

class FirstLoginPass extends Component {
  state = {
    ...initialState,
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    console.log(this.state);
  };

  formValidate = () => {
    const { password, passwordRepeat } = this.state;
    const isInvalid =
      !password || !passwordRepeat || password !== passwordRepeat;

    return isInvalid;
  };

  resetState = () => {
    this.setState({
      ...initialState,
    });
  };

  onSubmit = (e, firstLogin) => {
    e.preventDefault();
    firstLogin().then(async ({ data }) => {
      console.log(data);
      await this.props.refetch();
      this.resetState();
      this.props.history.push("/dashboard");
    });
  };

  render() {
    const id = this.props.session.activeAdmin.id;
    const { password, pass } = this.state;
    return (
      <div>
        <div>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className="loginbox">
              <img
                alt="Logo Real Dog"
                src="https://s3-us-west-1.amazonaws.com/realdog-s3/static/real-dog-food-logo.png"
              />
              <Mutation
                mutation={FIRST_LOGIN}
                variables={{ id, password, pass: parseInt(pass) }}
              >
                {(firstLogin, { loading, error }) => (
                  <form
                    onSubmit={(e) => {
                      this.onSubmit(e, firstLogin);
                    }}
                  >
                    <TextField
                      onChange={this.onChange}
                      variant="outlined"
                      margin="normal"
                      value={password}
                      required
                      fullWidth
                      name="password"
                      label="New Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                    />
                    <TextField
                      onChange={this.onChange}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="passwordRepeat"
                      label="New Password Repeat"
                      type="password"
                      id="passwordRepeat"
                      autoComplete="current-password"
                    />
                    {this.props.session.activeAdmin.permission.inventory
                      .process ||
                    this.props.session.activeAdmin.permission.inventory
                      .admin ? (
                      <TextField
                        onChange={this.onChange}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="pass"
                        label="Pin Code"
                        type="number"
                        id="pass"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 4);
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <br /> <br />
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      color="primary"
                      size="large"
                      disabled={loading || this.formValidate()}
                    >
                      Update Password
                      {loading && <SpinnerAdornment />}
                    </Button>
                    {error && <div>{error.message}</div>}
                  </form>
                )}
              </Mutation>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(FirstLoginPass);
