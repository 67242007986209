import React, { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  InputAdornment,
  Link,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  CANCEL_COURSE,
  COURSE_TRANSACTION_SEARCH,
  GET_COURSE_TRANSACTIONS,
} from "../../../../queries";
import _ from "lodash";
import {
  Dangerous,
  DeleteOutline,
  ListAlt,
  ReportGmailerrorredSharp,
} from "@mui/icons-material";
import { CourseProgressItem } from "../../Customers/Components/courseProgressOfCustomer";

const TransactionList = (props) => {
  const [transactions, setTransaction] = useState([]);
  const [transactionCount, setTransactionCount] = useState(0);
  const activeAdmin = props.session.activeAdmin;

  const [selectedTranx, setSelectedTranx] = useState();
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const {
    loading,
    error,
    refetch,
    data: queryResults,
  } = useQuery(GET_COURSE_TRANSACTIONS, {
    variables: {
      limit: rowsPerPage,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  const [cancelCourse] = useMutation(CANCEL_COURSE);
  useEffect(() => {
    if (queryResults) {
      setTransactionCount(queryResults.getCourseTransactions.meta.count);
      setTransaction(queryResults.getCourseTransactions.data);
    }
  }, [queryResults]);

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(!openCancelDialog);
  };
  const handleOnCancel = (transaction) => {
    cancelCourse({
      variables: {
        id: transaction.id ? transaction.id : transaction._id,
        input: {
          refund: false,
        },
      },
    }).then(() => {
      handleOpenCancelDialog();
      refetch();
    });
  };

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setPage, setRowsPerPage]
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  if (loading) return <p>Loading...</p>;
  if (error) return `Error! ${error}`;

  return (
    <div>
      <Box sx={{ height: 400, width: 1 }}>
        <SearchBar
          onSearch={(res) => {
            setTransaction(res.data);
            setTransactionCount(res.meta.count);
          }}
        />
        <TableContainer component={Paper}>
          <Table aria-label="course transaction table">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>

                <TableCell>Created Date</TableCell>
                <TableCell width={50}>Email</TableCell>
                <TableCell>Course/ Workshop</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Progress</TableCell>
                <TableCell>End Date</TableCell>

                <TableCell align="center" width={150}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {new Date(parseInt(row.createdAt)).toLocaleString()}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.customer?.email}
                  </TableCell>{" "}
                  <TableCell component="th" scope="row">
                    {row.courseId ? row.courseId?.title : row.addOn?.name}
                  </TableCell>{" "}
                  <TableCell component="th" scope="row">
                    {row.status} 
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.courseId?.id && row.customer?.id && (
                      <CourseProgressItem
                        courseId={row.courseId.id}
                        customerId={row.customer.id}
                        activeAdmin={activeAdmin}
                      />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {new Date(row.endDate * 1000).toLocaleString()}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <div>
                      {row.orderId.toLowerCase() !== "free" ? (
                        <a
                          href={`https://dashboard.stripe.com/payments/${row.orderId}`}
                          target={"_blank"}
                          rel="noopener"
                        >
                          <IconButton aria-label="delete" disabled>
                            <ListAlt color="secondary" />
                          </IconButton>
                        </a>
                      ) : (
                        ""
                      )}
                      {row.status.toLowerCase() !== "canceled" ? (
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => {
                            handleOpenCancelDialog();
                            setSelectedTranx(row);
                          }}
                        >
                          <Dangerous color="primary" />
                        </IconButton>
                      ) : (
                        ""
                      )}

                      {/* <IconButton aria-label="delete" disabled color="error">
                        <DeleteOutline color="error" />
                      </IconButton> */}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ConfirmDialog
          isOpen={openCancelDialog}
          setOpen={handleOpenCancelDialog}
          action={() => {
            handleOnCancel(selectedTranx);
          }}
          message={
            "You want to cancel this transaction. Cancelling does not refund any amount"
          }
        />
        <TablePagination
          rowsPerPageOptions={[50, 150, 250, 300]}
          component="div"
          count={transactionCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => handleChangePage(event, newPage)}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
        />
      </Box>
    </div>
  );
};

const SearchBar = ({ placeholder, searchParams, onSearch }) => {
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");

  const [search, { data, loading, error }] = useLazyQuery(
    COURSE_TRANSACTION_SEARCH,
    {
      fetchPolicy: "no-cache",
      onCompleted: (results) => {
        onSearch(results.courseTransactionSearch);
      },
    }
  );

  const delayedCallback = _.debounce((data) => {
    handleSearch(data);
  }, 400);

  // useEffect(() => {
  //   delayedCallback();
  // }, [delayedCallback, email, status]);
  const handleSearch = (data) => {
    search({
      variables: {
        email: data.email,
        status: data.status,
        limit: 50,
        offset: 0,
      },
    });
  };
  return (
    <div>
      <TextField
        sx={{ width: "50%" }}
        placeholder={placeholder || "Search..."}
        value={email}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          setEmail(e.target.value);
          let searchData = {
            email: e.target.value,
            status: status,
          };
          delayedCallback(searchData);
        }}
      />
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="status"
          value={status}
          label="Status"
          onChange={(e) => {
            setStatus(e.target.value);
            let searchData = {
              email: email,
              status: e.target.value,
            };
            delayedCallback(searchData);
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          <MenuItem value={"succeeded"}>Succeeded</MenuItem>
          <MenuItem value={"canceled"}>Canceled</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

const ConfirmDialog = ({ isOpen, setOpen, action, message }) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => setOpen()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" align="center">
          <div>
            <ReportGmailerrorredSharp color="error" />
          </div>
          Are you sure?{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen()} color="primary">
            No
          </Button>
          <Button onClick={action} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TransactionList;
