import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import KeyIcon from "@mui/icons-material/Key";
import ListIcon from "@mui/icons-material/List";
import LoupeIcon from "@mui/icons-material/Loupe";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import {
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Switch,
  TextField,
  FormControl,
  Alert,
  CircularProgress,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import draftToHtml from "draftjs-to-html";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  createRandKey,
  pageLinkGenerator,
  sefUrlGenerator,
} from "../../../globals";
import {
  CREATE_PAGE,
  GET_ADMINS,
  GET_SINGLE_PAGE,
  UPDATE_PAGE,
} from "../../../queries";
import { UploadFile } from "../../Tools/Upload";
import FeedrealCategories from "../../../globals/categories/FeedrealCategories";
import RealdogCategories from "../../../globals/categories/RealdogCategories";
import DetailComponent from "./Components/DetailComponent";
import IngredientsComponent from "./Components/IngredientsComponent";
import KeywordComponent from "./Components/KeywordComponent";
import VideoComponent from "./Components/VideoComponent";
import DetailCardEditor from "./Editors/detailCardEditor";
import IngredientsCardEditor from "./Editors/ingredientCardEditor";
import KeywordEditor from "./Editors/keywordEditor";
import TextContentEditor from "./Editors/textContentEditor";
import VideoContentEditor from "./Editors/videoContentEditor";
import VideoEditorIcon from "../../../assets/editorHints/videoEditorIcon.png";
import MultipleImageIcon from "../../../assets/editorHints/multipleImageIcon.png";
import DetailEditorIcon from "../../../assets/editorHints/detailEditorIcon.png";

import ImageColumnEditorIcon from "../../../assets/editorHints/imageColumnEditorIcon.png";
import ButtonLinkingEditorIcon from "../../../assets/editorHints/buttonLinkingEditorIcon.png";
import ImageColumnEditor from "./Editors/imageColumnEditor";
import ImageColumnComponent from "./Components/ImageColumnComponent";
import AlignedImageEditor from "./Editors/alignedImageEditor";
import AlignedImageComponent from "./Components/AlignedImageComponent";
import ButtonLinkEditor from "./Editors/buttonLinkEditor";
import ButtonLinkComponent from "./Components/ButtonLinkComponent";
import QRCode from "qrcode";
import LinkIcon from "@mui/icons-material/Link";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Info from "@mui/icons-material/Info";
import TableEditor from "./Editors/tableEditor";
import TableComponent from "./Components/TableComponent";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { green } from "@mui/material/colors";

import Chip from "@mui/material/Chip";
import AddAuthorDialog from "./addAuthorDialog";

const VIDEO_CONTENT = "VIDEO";
const KEYWORD_BANNER = "BANNER";
const INGREDIENT_CARD = "INGREDIENTS";
const DETAIL_CARD = "CARD";
const TEXT_CONTENT = "TEXT";
const IMAGE_COLUMNS = "IMAGE_COL";
const ALIGNED_IMAGE = "ALIGNED_IMAGE";
const BUTTON_LINK = "BUTTON_LINK";
const TABLE = "TABLE";

const CreateNewPage = ({ session }) => {
  let { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [nodes, setNodes] = useState([]);
  const [seo, setSeo] = useState({ description: "" });
  const [adminList, setAdminList] = useState([]);
  const [me, setMe] = useState({});
  const [website, setWebsite] = useState("");
  const [category, setCategory] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const [archive, setArchive] = useState(false);
  const [review, setReview] = useState(false);
  const [reviewLock, setReviewLock] = useState(false);
  const [author, setAuthor] = useState("");
  const [keys, setKeys] = useState([]);
  const [statContent, setStatContent] = useState("everyone");
  const [newLinks, setNewLinks] = useState([]);
  const [linkChange, setLinkChange] = useState(false);
  const [sefurlLock, setSefurlLock] = useState(false);
  const [showFeaturedImg, setShowFeaturedImg] = useState(true);

  const [authors, setAuthors] = useState([]);

  const addAuthor = (singleAuthor) => {
    const index = authors.findIndex((item) => item.id === singleAuthor.id);
    const copy = JSON.parse(JSON.stringify(authors));
    if (index === -1) {
      copy.push(singleAuthor);
    } else {
      copy.splice(index, 1);
    }
    setAuthors(copy);
  };

  const { data: data_admin, loading: loading_admin } = useQuery(GET_ADMINS);
  const [createPageMutation, { loading, error, data }] =
    useMutation(CREATE_PAGE);
  const [
    updatePageMutation,
    {
      loading: updatePageLoading,
      error: updatePageerror,
      data: updatePageData,
    },
  ] = useMutation(UPDATE_PAGE);

  const [
    getSinglePage,
    { loading: pageDataLoading, error: pageDataError, data: pageDataData },
  ] = useLazyQuery(GET_SINGLE_PAGE, {
    fetchPolicy: "no-cache",
    variables: {
      id: id,
    },
  });

  useEffect(() => {
    if (!loading_admin && data_admin) {
      const me = data_admin.admins.filter(
        (x) => x.id === session.activeAdmin.id
      );
      const authors = data_admin.admins.filter((x) => {
        return (
          (x.permission &&
            x.permission.author &&
            x.permission.author?.admin === true) ||
          x.permission.author?.read === true ||
          x.permission.author?.write === true
        );
      });
      setAuthor(me[0].id);
      setMe(me[0]);
      setAdminList(authors);
    }
  }, [data_admin]);

  useEffect(() => {
    if (id) {
      getSinglePage();
    }
  }, [id]);

  useEffect(() => {
    console.log("pageDataData", pageDataData);
    if (pageDataData && pageDataData.getPageByIdAdmin) {
      let thisPageData = pageDataData.getPageByIdAdmin;
      setTitle(thisPageData.title);
      setKeys(
        new Array(thisPageData.nodes.length)
          .fill("")
          .map(() => "create-edit-page-id" + createRandKey(6))
      );
      setNodes(
        thisPageData.nodes.map((item) => ({
          text: item.text,
          type: item.type,
        }))
      );
      setReview(thisPageData.review ? thisPageData.review : false);
      setReviewLock(thisPageData.reviewLock ? thisPageData.reviewLock : false);
      setArchive(thisPageData.status);
      setWebsite(thisPageData.website);
      setCategory(thisPageData.category);
      setNewLinks(thisPageData.redirect_sefurls);
      setLink(
        pageLinkGenerator(
          thisPageData.website,
          thisPageData.sefurl,
          thisPageData.category
        )
      );
      setStatContent(
        thisPageData.courseLock
          ? "course"
          : thisPageData.articleLock
          ? "membership"
          : "everyone"
      );
      setSelectedPhoto(thisPageData.featured_img);
      setShowFeaturedImg(thisPageData.showFeaturedImg);
      setSeo(thisPageData.seo ? thisPageData.seo : { description: "" });
      setAuthor(thisPageData.admin.id);
      setAuthors(thisPageData.authors);
      setSefurlLock(thisPageData.sefurlLock ? thisPageData.sefurlLock : false);
    }
  }, [pageDataLoading, pageDataError, pageDataData]);

  const onAddNode = (type) => {
    let obj = {
      type: type,
      text: "",
    };
    const nodesCopy = JSON.parse(JSON.stringify(nodes));
    nodesCopy.push(obj);
    let copyKeys = JSON.parse(JSON.stringify(keys));
    copyKeys.push("create-edit-page-id" + createRandKey(6));
    setKeys(copyKeys);
    setNodes(nodesCopy);
  };

  const onChangeNodeContent = (index, content) => {
    let copy = JSON.parse(JSON.stringify(nodes));
    copy[index].text = content;
    setNodes(copy);
  };

  const onDeleteNode = (index) => {
    let copy = JSON.parse(JSON.stringify(nodes));
    copy.splice(index, 1);
    let copyKeys = JSON.parse(JSON.stringify(keys));
    copyKeys.splice(index, 1);
    setKeys(copyKeys);
    setNodes(copy);
  };
  const onMoveUp = (index) => {
    let copy = JSON.parse(JSON.stringify(nodes));
    let temp = copy[index - 1];
    copy[index - 1] = copy[index];
    copy[index] = temp;
    let copyKeys = JSON.parse(JSON.stringify(keys));
    let tempKeys = copyKeys[index - 1];
    copyKeys[index - 1] = copyKeys[index];
    copyKeys[index] = tempKeys;
    setKeys(copyKeys);
    setNodes(copy);
  };
  const onMoveDown = (index) => {
    let copy = JSON.parse(JSON.stringify(nodes));
    let temp = copy[index + 1];
    copy[index + 1] = copy[index];
    copy[index] = temp;
    let copyKeys = JSON.parse(JSON.stringify(keys));
    let tempKeys = copyKeys[index + 1];
    copyKeys[index + 1] = copyKeys[index];
    copyKeys[index] = tempKeys;
    setKeys(copyKeys);
    setNodes(copy);
  };

  useEffect(() => {
    if (linkChange) {
      if (id) {
        onSubmitForm();
      }
      setLinkChange(false);
    }
  }, [newLinks, linkChange]);

  const onSubmitForm = () => {
    if (id) {
      onUpdatePage();
    } else {
      onCreatePage();
    }
  };

  const onCreatePage = () => {
    let input = {
      redirect_sefurls: newLinks,
      title: title,
      website: website,
      category: category,
      featured_img: selectedPhoto,
      status: archive,
      review: review,
      reviewLock: reviewLock,
      admin_id: author,
      nodes: nodes,
      seo: seo,
      authors: authors.map((item) => item.id),
    };
    createPageMutation({
      variables: {
        input: input,
      },
    })
      .then(({ data }) => {
        enqueueSnackbar("Page Created Successfully", {
          variant: "success",
        });
        history.push(`/dashboard/page/edit/${data.createPage.id}`);
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: "error",
        });
      });
  };
  const onUpdatePage = () => {
    let input = {
      redirect_sefurls: newLinks,
      title: title,
      ...(session.activeAdmin.permission.god?.admin && {
        sefurlLock: sefurlLock,
      }),
      website: website,
      category: category,
      featured_img: selectedPhoto,
      showFeaturedImg: showFeaturedImg,
      status: archive,
      review: review,
      reviewLock: reviewLock,
      admin_id: author,
      nodes: nodes,
      articleLock: statContent === "everyone" ? false : true,
      courseLock: statContent === "course" ? true : false,
      seo: seo,
      authors: authors.map((item) => item.id),
    };
    updatePageMutation({
      variables: {
        id: id,
        input: input,
      },
    })
      .then(({ data }) => {
        setLink(
          pageLinkGenerator(
            data.updatePage.website,
            data.updatePage.sefurl,
            data.updatePage.category
          )
        );
        setSefurlLock(data.updatePage.sefurlLock);
        enqueueSnackbar("Page Updated Successfully", {
          variant: "success",
        });
      })
      .catch((e) => {
        enqueueSnackbar(`Error:${e.message}`, {
          variant: "error",
        });
      });
  };
  const uploadPhoto = (file) => {
    setSelectedPhoto(file.uri);
  };
  const resetFeaturedPhoto = () => {
    setSelectedPhoto("");
  };
  // content state change
  const handleContentStateChange = (e) => {
    switch (e.target.value) {
      case "everyone":
        setStatContent("everyone");
        break;
      case "membership":
        setStatContent("membership");
        break;
      case "course":
        setStatContent("course");
        break;
      default:
        setStatContent("everyone");
    }
  };
  const changeValueSeo = (type, value) => {
    const copySeo = seo;
    copySeo[type] = value;
    setSeo(copySeo);
  };
  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={9}>
          <Grid item sx={12}>
            <TextField
              variant="outlined"
              label="Title"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></TextField>
          </Grid>
          <br></br>
          {id && (
            <QrCodeDialog
              source={link}
              admin={session.activeAdmin}
              sefurlLock={sefurlLock}
              setSefurlLock={setSefurlLock}
            />
          )}
          {/* <Grid item sx={12}>
            <TextField
              variant="outlined"
              label="Link"
              fullWidth
              value={link}
              disabled
            ></TextField>
            <br></br>
          </Grid> */}
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            {nodes.map((item, index) => {
              if (item.type === VIDEO_CONTENT) {
                return (
                  <FullWidthComponentLayout
                    key={keys[index]}
                    upDisabled={index === 0}
                    downDisabled={index === nodes.length - 1}
                    onMoveUp={() => onMoveUp(index)}
                    onMoveDown={() => onMoveDown(index)}
                    onDeleteNode={() => onDeleteNode(index)}
                  >
                    <VideoContentEditor
                      onChangeNodeContent={(data) => {
                        onChangeNodeContent(index, data);
                      }}
                      rawContent={item.text}
                    />
                  </FullWidthComponentLayout>
                );
              }
              if (item.type === KEYWORD_BANNER) {
                return (
                  <FullWidthComponentLayout
                    key={keys[index]}
                    upDisabled={index === 0}
                    downDisabled={index === nodes.length - 1}
                    onMoveUp={() => onMoveUp(index)}
                    onMoveDown={() => onMoveDown(index)}
                    onDeleteNode={() => onDeleteNode(index)}
                  >
                    <KeywordEditor
                      onChangeNodeContent={(data) => {
                        onChangeNodeContent(index, data);
                      }}
                      rawContent={item.text}
                    />
                  </FullWidthComponentLayout>
                );
              }
              if (item.type === BUTTON_LINK) {
                return (
                  <FullWidthComponentLayout
                    key={keys[index]}
                    upDisabled={index === 0}
                    downDisabled={index === nodes.length - 1}
                    onMoveUp={() => onMoveUp(index)}
                    onMoveDown={() => onMoveDown(index)}
                    onDeleteNode={() => onDeleteNode(index)}
                  >
                    <ButtonLinkEditor
                      onChangeNodeContent={(data) => {
                        onChangeNodeContent(index, data);
                      }}
                      rawContent={item.text}
                    />
                  </FullWidthComponentLayout>
                );
              }
              if (item.type === INGREDIENT_CARD) {
                return (
                  <FullWidthComponentLayout
                    key={keys[index]}
                    upDisabled={index === 0}
                    downDisabled={index === nodes.length - 1}
                    onMoveUp={() => onMoveUp(index)}
                    onMoveDown={() => onMoveDown(index)}
                    onDeleteNode={() => onDeleteNode(index)}
                  >
                    <IngredientsCardEditor
                      onChangeNodeContent={(data) => {
                        onChangeNodeContent(index, data);
                      }}
                      title={title}
                      rawContent={item.text}
                    />
                  </FullWidthComponentLayout>
                );
              }
              if (item.type === DETAIL_CARD) {
                return (
                  <FullWidthComponentLayout
                    key={keys[index]}
                    upDisabled={index === 0}
                    downDisabled={index === nodes.length - 1}
                    onMoveUp={() => onMoveUp(index)}
                    onMoveDown={() => onMoveDown(index)}
                    onDeleteNode={() => onDeleteNode(index)}
                  >
                    <DetailCardEditor
                      onChangeNodeContent={(data) => {
                        onChangeNodeContent(index, data);
                      }}
                      rawContent={item.text}
                      title={title}
                    />
                  </FullWidthComponentLayout>
                );
              }
              if (item.type === IMAGE_COLUMNS) {
                return (
                  <FullWidthComponentLayout
                    key={keys[index]}
                    upDisabled={index === 0}
                    downDisabled={index === nodes.length - 1}
                    onMoveUp={() => onMoveUp(index)}
                    onMoveDown={() => onMoveDown(index)}
                    onDeleteNode={() => onDeleteNode(index)}
                  >
                    <ImageColumnEditor
                      onChangeNodeContent={(data) => {
                        onChangeNodeContent(index, data);
                      }}
                      rawContent={item.text}
                      title={title}
                    />
                  </FullWidthComponentLayout>
                );
              }
              if (item.type === ALIGNED_IMAGE) {
                return (
                  <FullWidthComponentLayout
                    key={keys[index]}
                    upDisabled={index === 0}
                    downDisabled={index === nodes.length - 1}
                    onMoveUp={() => onMoveUp(index)}
                    onMoveDown={() => onMoveDown(index)}
                    onDeleteNode={() => onDeleteNode(index)}
                  >
                    <AlignedImageEditor
                      onChangeNodeContent={(data) => {
                        onChangeNodeContent(index, data);
                      }}
                      rawContent={item.text}
                      title={title}
                    />
                  </FullWidthComponentLayout>
                );
              }
              if (item.type === TEXT_CONTENT) {
                return (
                  <FullWidthComponentLayout
                    key={keys[index]}
                    upDisabled={index === 0}
                    downDisabled={index === nodes.length - 1}
                    onMoveUp={() => onMoveUp(index)}
                    onMoveDown={() => onMoveDown(index)}
                    onDeleteNode={() => onDeleteNode(index)}
                  >
                    <TextContentEditor
                      onChangeNodeContent={(data) => {
                        onChangeNodeContent(index, data);
                      }}
                      rawContent={item.text}
                    />
                  </FullWidthComponentLayout>
                );
              }
              if (item.type === TABLE) {
                return (
                  <FullWidthComponentLayout
                    key={keys[index]}
                    upDisabled={index === 0}
                    downDisabled={index === nodes.length - 1}
                    onMoveUp={() => onMoveUp(index)}
                    onMoveDown={() => onMoveDown(index)}
                    onDeleteNode={() => onDeleteNode(index)}
                  >
                    <TableEditor
                      onChangeNodeContent={(data) => {
                        onChangeNodeContent(index, data);
                      }}
                      rawContent={item.text}
                    />
                  </FullWidthComponentLayout>
                );
              }
            })}
          </Grid>
          <br></br>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <AddNodeDialog onAddNode={onAddNode} />
          </Grid>
        </Grid>
        <Grid item xs={3} className={"every-child-margin"}>
          {/*  <TextField
            id="outlined-select-currency"
            select
            label="Select Author"
            fullWidth
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          >
            <MenuItem value={session.activeAdmin.id}>{me.name} (Me)</MenuItem>
            {adminList.map((admin) => {
              if (admin.id !== session.activeAdmin.id) {
                return (
                  <MenuItem key={admin.id} value={admin.id}>
                    {admin.name}
                  </MenuItem>
                );
              }
            })}
          </TextField> */}
          <div
            style={{
              padding: "10px",
              backgroundColor: "#000",
              borderRadius: "5px",
            }}
          >
            <p>
              <b>Authors</b>
            </p>
            {authors.map((author) => {
              return (
                <Chip
                  key={author.id}
                  label={`${author.name} ${author.surname}`}
                  variant="outlined"
                  onDelete={() => addAuthor(author)}
                  style={{
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                />
              );
            })}
            <AddAuthorDialog
              onAddNewAuthor={(data) => addAuthor(data)}
              authors={authors}
            />
          </div>

          <TextField
            id="outlined-select-currency"
            select
            label="Select Website"
            fullWidth
            value={website}
            onChange={(e) => {
              switch (e.target.value) {
                case "real.dog":
                  setCategory(RealdogCategories[0].link);
                  break;
                case "feedreal.com":
                  setCategory(FeedrealCategories[0].link);
                  break;
                case "canine.care":
                  setCategory("nutrition");
                  break;
                default:
                  setCategory("");
              }
              setWebsite(e.target.value);
            }}
          >
            <MenuItem disabled={true} value={"Select"}>
              Select
            </MenuItem>
            <MenuItem value={"canine.care"}>Canine.care</MenuItem>
            <MenuItem value={"feedreal.com"}>Feedreal.com</MenuItem>
            <MenuItem value={"real.dog"}>Real.dog</MenuItem>
          </TextField>
          <FormControl variant="outlined" className="width100">
            <InputLabel id="category">Select Category</InputLabel>
            {website !== "feedreal.com" ? (
              website && website === "canine.care" ? (
                <Select
                  id="category"
                  name="category"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  label="category"
                >
                  <MenuItem value="">
                    <em>Select Category</em>
                  </MenuItem>
                  <MenuItem value={"nutrition"}>Nutrition</MenuItem>
                  <MenuItem value={"training"}>Training</MenuItem>
                  <MenuItem value={"activities"}>Activites</MenuItem>
                  <MenuItem value={"getting-started"}>Getting Started</MenuItem>
                  <MenuItem value={"basic-care"}>Basic Care</MenuItem>
                </Select>
              ) : (
                <Select
                  id="category"
                  name="category"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  label="category"
                >
                  <MenuItem value="">
                    <em>Select Category</em>
                  </MenuItem>
                  {RealdogCategories.map((data) => (
                    <MenuItem value={data.link}>{data.name}</MenuItem>
                  ))}
                </Select>
              )
            ) : (
              <Select
                id="category"
                name="category"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                label="category"
              >
                <MenuItem value="">
                  <em>Select Category</em>
                </MenuItem>
                {FeedrealCategories.map((data) => (
                  <MenuItem value={data.link}>{data.name}</MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          {!selectedPhoto ? (
            <UploadFile
              idKey={"create-new-uplad-image-key-" + createRandKey(6)}
              uploadPhoto={uploadPhoto}
              buttonName={"Add Featured Image"}
            />
          ) : (
            <div className="featuredImgBlock thumbnail">
              <div
                className="deletephotobtn"
                style={{
                  right: "14px",
                }}
              >
                <IconButton
                  onClick={resetFeaturedPhoto}
                  color="secondary"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <img width={"100%"} src={selectedPhoto} />
              {website === "real.dog" && (
                <FormControlLabel
                  control={
                    <Switch
                      className="padding10"
                      checked={showFeaturedImg}
                      onChange={() => {
                        setShowFeaturedImg(!showFeaturedImg);
                      }}
                      name="status"
                      color="secondary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                  }
                  label={showFeaturedImg ? "Show on page" : "Dont show on page"}
                />
              )}
            </div>
          )}
          <FormControlLabel
            control={
              <Switch
                className="padding10"
                checked={archive}
                onChange={() => {
                  setArchive(!archive);
                }}
                name="status"
                color="secondary"
                inputProps={{
                  "aria-label": "secondary checkbox",
                }}
              />
            }
            label={archive ? "Published" : "Archive"}
          />
          {website === "feedreal.com" && (
            // <FormControlLabel
            //   control={
            //     <Switch
            //       className="padding10"
            //       checked={statContent}
            //       onChange={handleContentStateChange}
            //       name="status"
            //       color="secondary"
            //       inputProps={{
            //         "aria-label": "secondary checkbox",
            //       }}
            //     />
            //   }
            //   label={statContent ? "Membership Content" : "Show Everyone"}
            // />
            <FormControl className="contentPermission">
              <FormLabel id="demo-radio-buttons-group-label">
                Permission Setting
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="everyone"
                name="radio-buttons-group"
                value={statContent}
                onChange={handleContentStateChange}
              >
                <FormControlLabel
                  value="everyone"
                  control={<Radio />}
                  label="Show Everyone"
                />
                <FormControlLabel
                  value="membership"
                  control={<Radio />}
                  label="Membership Content"
                />
                <FormControlLabel
                  value="course"
                  control={<Radio />}
                  label="Course Content"
                />
              </RadioGroup>
            </FormControl>
          )}
          {website === "real.dog" && (
            <FormControlLabel
              control={
                <Switch
                  className="padding10"
                  checked={review}
                  onChange={() => {
                    setReview(!review);
                  }}
                  name="status"
                  color="secondary"
                  inputProps={{
                    "aria-label": "secondary checkbox",
                  }}
                />
              }
              label={"Review"}
            />
          )}
          {website === "real.dog" && (
            <FormControlLabel
              control={
                <Switch
                  className="padding10"
                  checked={reviewLock}
                  onChange={() => {
                    setReviewLock(!reviewLock);
                  }}
                  name="status"
                  color="secondary"
                  inputProps={{
                    "aria-label": "secondary checkbox",
                  }}
                />
              }
              label={"Review Lock"}
            />
          )}
          <Button
            variant="contained"
            color="secondary"
            disabled={title === ""}
            fullWidth
            onClick={() => {
              onSubmitForm();
            }}
            className="update-btn"
          >
            {id ? "Update" : "create"}
          </Button>
          <SEO
            seo={seo}
            changeValueSeo={changeValueSeo}
            onSubmitForm={onSubmitForm}
            loading={updatePageLoading || loading}
            title={title}
          />
          <Preview nodes={nodes} title={title} />
          <LinksComponent
            setNewLinks={setNewLinks}
            newLinks={newLinks}
            page={website}
            category={category}
            setLinkChange={setLinkChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateNewPage;

const AddNodeDialog = ({ onAddNode }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addNewNode = (type) => {
    onAddNode(type);
    handleClose();
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add New Node
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {"Please select the type"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    addNewNode(VIDEO_CONTENT);
                  }}
                >
                  {/* <ListItemIcon>
                    <OndemandVideoIcon />
                  </ListItemIcon> */}
                  <img
                    style={{
                      width: 150,
                      height: 75,
                      borderRadius: 20,
                      marginRight: 10,
                    }}
                    src={VideoEditorIcon}
                  ></img>
                  <ListItemText primary="Video" />
                </ListItemButton>
              </ListItem>
              {/*  <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    addNewNode(KEYWORD_BANNER);
                  }}
                >
                  <img
                    style={{
                      width: 150,
                      borderRadius: 5,
                      objectFit: "contain",
                      marginRight: 10,
                    }}
                    src={KeywordEditorIcon}
                  ></img>

                  <ListItemText primary="Keyword" />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    addNewNode(BUTTON_LINK);
                  }}
                >
                  {/* <ListItemIcon>
                    <KeyIcon />
                  </ListItemIcon> */}
                  <img
                    style={{
                      width: 150,
                      borderRadius: 5,
                      objectFit: "contain",
                      marginRight: 10,
                    }}
                    src={ButtonLinkingEditorIcon}
                  ></img>

                  <ListItemText primary="Button Linking" />
                </ListItemButton>
              </ListItem>
              {/*               <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    addNewNode(INGREDIENT_CARD);
                  }}
                >
                  <img
                    style={{
                      width: 150,
                      borderRadius: 5,
                      objectFit: "contain",
                      marginRight: 10,
                    }}
                    src={IngredientsEditorIcon}
                  ></img>

                  <ListItemText primary="Ingredients" />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    addNewNode(DETAIL_CARD);
                  }}
                >
                  {/* <ListItemIcon>
                    <LoupeIcon />
                  </ListItemIcon> */}
                  <img
                    style={{
                      width: 150,
                      borderRadius: 5,
                      objectFit: "contain",
                      marginRight: 10,
                    }}
                    src={DetailEditorIcon}
                  ></img>
                  <ListItemText primary="Details" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    addNewNode(IMAGE_COLUMNS);
                  }}
                >
                  {/* <ListItemIcon>
                    <LoupeIcon />
                  </ListItemIcon> */}
                  <img
                    style={{
                      width: 150,
                      borderRadius: 5,
                      objectFit: "contain",
                      marginRight: 10,
                    }}
                    src={ImageColumnEditorIcon}
                  ></img>
                  <ListItemText primary="Aligned Image Editor" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    addNewNode(ALIGNED_IMAGE);
                  }}
                >
                  {/* <ListItemIcon>
                    <LoupeIcon />
                  </ListItemIcon> */}
                  <img
                    style={{
                      width: 150,
                      borderRadius: 5,
                      objectFit: "contain",
                      marginRight: 10,
                    }}
                    src={MultipleImageIcon}
                  ></img>
                  <ListItemText primary="Multiple Image And Text Editor" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    addNewNode(TEXT_CONTENT);
                  }}
                >
                  <ListItemIcon>
                    <FormatColorTextIcon />
                  </ListItemIcon>
                  <ListItemText primary="Text" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    addNewNode(TABLE);
                  }}
                >
                  <ListItemIcon>
                    <FormatColorTextIcon />
                  </ListItemIcon>
                  <ListItemText primary="Table" />
                </ListItemButton>
              </ListItem>
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const FullWidthComponentLayout = ({
  children,
  onMoveUp,
  onMoveDown,
  onDeleteNode,
  upDisabled,
  downDisabled,
}) => {
  return (
    <Grid
      item
      xs={12}
      style={{
        marginTop: 10,
        marginBottom: 5,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={1}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <IconButton
              onClick={onMoveUp}
              disabled={upDisabled}
              color="secondary"
            >
              <ExpandCircleDownIcon
                style={{
                  transform: "rotate(180deg)",
                }}
              />
            </IconButton>
            <IconButton onClick={onDeleteNode} color="error">
              <DeleteIcon></DeleteIcon>
            </IconButton>
            <IconButton
              onClick={onMoveDown}
              disabled={downDisabled}
              color="secondary"
            >
              <ExpandCircleDownIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

const Preview = ({ nodes, title }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} fullWidth>
        Preview Page
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="every-child-margin"
          >
            {nodes.map((item, index) => {
              if (item.type === VIDEO_CONTENT) {
                let obj = item.text
                  ? JSON.parse(item.text)
                  : {
                      youtubeLink: "",
                      title: "",
                    };
                let url = obj.youtubeLink;
                let videoTitle = obj.title;
                return (
                  <VideoComponent
                    title={title}
                    src={url}
                    videoTitle={videoTitle}
                  />
                );
              }
              if (item.type === KEYWORD_BANNER) {
                let obj = item.text
                  ? JSON.parse(item.text)
                  : {
                      color: "",
                      keyword: "",
                      link: "",
                    };
                const color = obj.color;
                const keyword = obj.keyword;
                const link = obj.link;
                return (
                  <KeywordComponent
                    color={color}
                    text={keyword}
                    link={link}
                  ></KeywordComponent>
                );
              }
              if (item.type === BUTTON_LINK) {
                let obj = item.text
                  ? JSON.parse(item.text)
                  : {
                      label: "",
                      link: "",
                    };
                const label = obj.label;
                const link = obj.link;
                const text = obj.text;
                return (
                  <ButtonLinkComponent
                    text={text}
                    label={label}
                    link={link}
                  ></ButtonLinkComponent>
                );
              }
              if (item.type === INGREDIENT_CARD) {
                let obj = item.text
                  ? JSON.parse(item.text)
                  : {
                      color: "",
                      keyword: "",
                      link: "",
                    };
                const img = obj.img;
                const smallDetetail = obj.smallDetetail;
                const analysisArray = obj.analysisArray;
                const analysisTitle = obj.analysisTitle;
                const detailNodes = obj.detailNodes;

                return (
                  <IngredientsComponent
                    title={title}
                    img={img}
                    smallDetetail={smallDetetail}
                    analysisArray={analysisArray}
                    analysisTitle={analysisTitle}
                    detailNodes={detailNodes}
                  />
                );
              }
              if (item.type === DETAIL_CARD) {
                let obj = item.text
                  ? JSON.parse(item.text)
                  : {
                      color: "",
                      keys: "",
                    };
                let keys = obj.keys;
                const color = obj.color;
                return (
                  <DetailComponent keys={keys} color={color} title={title} />
                );
              }
              if (item.type === IMAGE_COLUMNS) {
                let obj = item.text
                  ? JSON.parse(item.text)
                  : {
                      color: "",
                      keys: "",
                    };
                let keys = obj.keys;
                const color = obj.color;
                return (
                  <ImageColumnComponent
                    keys={keys}
                    color={color}
                    title={title}
                  />
                );
              }
              if (item.type === ALIGNED_IMAGE) {
                let obj = item.text
                  ? JSON.parse(item.text)
                  : {
                      color: "",
                      keys: "",
                    };
                let keys = obj.keys;
                const color = obj.color;
                return (
                  <AlignedImageComponent
                    keys={keys}
                    color={color}
                    title={title}
                  />
                );
              }
              if (item.type === TEXT_CONTENT) {
                const content = item.text
                  ? JSON.parse(item.text)
                  : {
                      entityMap: {},
                      blocks: [],
                    };
                const editorState = draftToHtml(content);
                return (
                  <div
                    id={"article"}
                    dangerouslySetInnerHTML={{ __html: editorState }}
                  ></div>
                );
              }
              if (item.type === TABLE) {
                let obj = item.text
                  ? JSON.parse(item.text)
                  : {
                      cols: [],
                      rows: [],
                    };
                let cols = obj.cols;
                const rows = obj.rows;
                return <TableComponent cols={cols} rows={rows} />;
              }
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const QrCodeDialog = ({ source = "", admin, sefurlLock, setSefurlLock }) => {
  const [open, setOpen] = useState(false);

  const [qrimage, setQrimage] = useState();
  // eslint-disable-next-line
  const [qrDarkimage, setQrDarkimage] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createQRCode = () => {
    // With promises
    QRCode.toDataURL(`${source}`, {
      choices: "png",
      quality: 1,
      scale: 10,
      color: {
        dark: "#fff", // Blue dots
        light: "#0000", // Transparent background
      },
    })
      .then((url) => {
        setQrimage(url);
      })
      .catch((err) => {
        console.error(err);
      });

    QRCode.toDataURL(`${source}`, {
      choices: "png",
      quality: 1,
      scale: 10,
    })
      .then((url) => {
        setQrDarkimage(url);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    createQRCode();
    // eslint-disable-next-line
  }, [source]);
  return (
    <div>
      <RDBUrl
        showQR={true}
        source={source}
        handleClick={handleClickOpen}
        showAdmin={admin.permission.god?.admin}
        sefurlLock={sefurlLock}
        setSefurlLock={setSefurlLock}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            src={qrimage}
            alt={"qr-link"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const RDBUrl = ({
  showQR,
  handleClick,
  source,
  showAdmin,
  sefurlLock,
  setSefurlLock,
}) => {
  return (
    <Alert
      icon={<LinkIcon />}
      style={{ backgroundColor: "black", color: "white" }}
      action={
        <div style={{ marginLeft: "-25px" }}>
          <FormControlLabel
            control={
              <Switch
                className="padding10"
                checked={sefurlLock}
                onChange={() => {
                  setSefurlLock(!sefurlLock);
                }}
                disabled={!showAdmin}
                name="status"
                color="secondary"
                inputProps={{
                  "aria-label": "secondary checkbox",
                }}
              />
            }
            label={sefurlLock ? "Locked" : "Auto"}
          />

          <Button
            color="inherit"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(`${source}`);
            }}
            endIcon={"copy"}
          ></Button>

          {showQR && (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => handleClick()}
              className="mobile_hide"
            >
              <QrCodeIcon />
            </IconButton>
          )}
        </div>
      }
    >
      <Link
        style={{
          color: "white",
          textDecoration: "none",
        }}
        to={{ pathname: `${source}` }}
        target="_blank"
      >
        {source}
      </Link>
    </Alert>
  );
};

const LinksComponent = ({
  newLinks,
  setNewLinks,
  page,
  category,
  setLinkChange,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [text, setText] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (index) => {
    let copy = JSON.parse(JSON.stringify(newLinks));
    copy.splice(index, 1);
    setNewLinks(copy);
    setLinkChange(true);
    /*     enqueueSnackbar("Link deleted successfully", {
      variant: "error",
    }); */
  };

  const addNode = () => {
    const textCopy = text.trim();
    if (!textCopy || textCopy.length === 0 || textCopy === "") {
      return;
    }
    let copy = JSON.parse(JSON.stringify(newLinks));
    copy.push(sefUrlGenerator(text));
    setNewLinks(copy);
    setText("");
    setLinkChange(true);
    /*     enqueueSnackbar("Link added successfully", {
      variant: "success",
    }); */
  };
  return (
    <div
      style={{
        padding: "10px",
        backgroundColor: "#000",
        borderRadius: "5px",
      }}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {newLinks.length === 0 && "No link saved for now "}
            {newLinks.map((item, index) => {
              let urlText = pageLinkGenerator(page, item, category);
              return (
                <Alert
                  icon={<LinkIcon />}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    marginBottom: 5,
                  }}
                  action={
                    <div style={{ marginLeft: "-25px" }}>
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(`${urlText}`);
                        }}
                        endIcon={"copy"}
                      ></Button>
                      <IconButton
                        color="inherit"
                        size="small"
                        onClick={() => handleDelete(index)}
                        className="mobile_hide"
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </div>
                  }
                >
                  {urlText}
                </Alert>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: "flex",
          fontWeight: "400",
          justifyContent: "space-between",
          fontSize: "16px",
          alignItems: "center",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        {newLinks.length === 0
          ? "No Redirected Link"
          : newLinks.length === 1
          ? `1 Link Saved`
          : `${newLinks.length} Links Saved`}
        <IconButton
          onClick={handleClickOpen}
          sx={{ padding: "0px !important" }}
        >
          <Info />
        </IconButton>
      </div>

      <TextField
        fullWidth
        id="outlined-basic"
        label="Text Link"
        size="small"
        variant="outlined"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <Button
        fullWidth
        variant="outlined"
        sx={{ marginTop: "10px" }}
        onClick={() => {
          addNode();
        }}
      >
        Add Link
      </Button>
    </div>
  );
};

const SEO = ({ seo, changeValueSeo, title, onSubmitForm, loading }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} fullWidth>
        SEO
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">SEO Setting</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={6}
            sx={{ marginTop: "20px", minWidth: "300px" }}
            defaultValue={seo?.description}
            onChange={(e) => {
              changeValueSeo("description", e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            color="secondary"
            variant="outlined"
            className="update-btn"
            onClick={() => {
              onSubmitForm();
            }}
          >
            {loading ? <CircularProgress /> : "Update"}
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
