import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_FLAG, UPDATE_FLAG } from "../../../../queries";
import { LinearProgress, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import InfoIcon from "@mui/icons-material/Info";

const KEY = "freeMembership";

export default function FreeMembership() {
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState({});
  const [percentage, setPercentage] = useState("");
  const [alignment, setAlignment] = useState(null);
  const [alignment2, setAlignment2] = useState("ALL");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleChange2 = (event, newAlignment) => {
    setAlignment2(newAlignment);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [getFlag, { loading, refetch }] = useLazyQuery(GET_FLAG, {
    onCompleted: (data) => {
      setFlag(data.getFlag);
      setAlignment(data.getFlag.data.only);
      setAlignment2(data.getFlag.data.type);
    },
    fetchPolicy: "no-cache",
  });

  const [updateFlagMutation, { loading: loadingUpdate }] =
    useMutation(UPDATE_FLAG);

  useEffect(() => {
    getFlag({
      variables: {
        key: KEY,
      },
    });
  }, [getFlag, open]);

  const onChangeStatus = () => {
    const copy = copyFlag();
    copy.status = !copy.status;
    setFlag(copy);
  };

  const copyFlag = () => JSON.parse(JSON.stringify(flag));

  const update = () => {
    updateFlagMutation({
      variables: {
        id: flag.id,
        input: {
          key: KEY,
          status: flag.status,
          data: {
            ...flag.data,
            only: alignment,
            type: alignment2,
          },
        },
      },
    })
      .then((res) => {
        handleClose();
      })
      .catch((error) => {});
  };

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <Grid item xs>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        style={{
          borderLeft: "5px solid",
          borderLeftColor: flag.status ? "#63b475" : "#dc5a68",
        }}
        className="btn-reward"
      >
        Free Membership
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {"Free Membership (1 month)"}
        </DialogTitle>
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                sx={{
                  display: "flex",
                  marginBottom: "30px",
                  ["& .MuiToggleButton-root.Mui-selected"]: {
                    backgroundColor: "#63b475",
                    color: "#fff",
                  },
                }}
              >
                <ToggleButton value={null}>Everyone</ToggleButton>
                <ToggleButton value="US">USA</ToggleButton>
                <ToggleButton value="CA">CA</ToggleButton>
              </ToggleButtonGroup>
              <ToggleButtonGroup
                color="primary"
                value={alignment2}
                exclusive
                onChange={handleChange2}
                aria-label="Platform"
                sx={{
                  display: "flex",
                  marginBottom: "10px",
                  ["& .MuiToggleButton-root.Mui-selected"]: {
                    backgroundColor: "#63b475",
                    color: "#fff",
                  },
                }}
              >
                <ToggleButton value={"ALL"}>ALL</ToggleButton>
                <ToggleButton value="NEW_BOX">NEW BOX</ToggleButton>
              </ToggleButtonGroup>
              <div style={{ marginBottom: "30px" }}>
                <InfoIcon sx={{ fontSize: "1.2em", marginRight: "5px",verticalAlign:"text-bottom" }} />
                <Typography variant="span" sx={{ fontSize: ".9em" }}>
                  {`${
                    alignment2 === "NEW_BOX"
                      ? "All new box (no super/no meatballs only)"
                      : "All"
                  } subscribers will get free membership.`}
                </Typography>
              </div>

              <FormControlLabel
                control={
                  <Switch
                    checked={flag.status}
                    color="secondary"
                    onClick={() => {
                      onChangeStatus();
                    }}
                  />
                }
                label="Reward"
              />
            </DialogContentText>
          </DialogContent>
        )}
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>cancel</Button>
            <Button onClick={update} autoFocus>
              Update
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Grid>
  );
}
