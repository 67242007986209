import { useLazyQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import { IconButton, Switch } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";

import {
  CREATE_TICKET,
  GET_TICKET_BY_ID,
  UPDATE_TICKET,
} from "../../../queries";
import { FeaturedImage } from "../Course/Course";

export default function TicketCreateUpdate({ id, refetch = () => {} }) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [rawPrice, setRawPrice] = useState(0);

  const [insurancePrice, setInsurancePrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [status, setStatus] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [quota, setQuota] = useState(0);
  const [img, setImg] = useState("");

  const [getSingleTicketByID, { loading, data, error }] = useLazyQuery(
    GET_TICKET_BY_ID,
    {
      variables: { id: id },
    }
  );
  const [createTicketMutation] = useMutation(CREATE_TICKET);
  const [updateTicketMutation] = useMutation(UPDATE_TICKET);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      if (id) {
        initialStateForExist();
      } else {
        initialState();
      }
    }
  }, [open]);

  const updateRawPrice = (val) => {
    setRawPrice(val);
    setPrice(val - (val * discount) / 100);
  };
  const updatePrice = (val) => {
    setRawPrice(val + (val * discount) / 100);
    setPrice(val);
  };
  const updateDiscount = (val) => {
    setDiscount(val);
    setPrice(rawPrice - (rawPrice * val) / 100);
  };

  const initialStateForExist = () => {
    getSingleTicketByID().then((res) => {
      console.log(res);
      if (res && res.data && res.data.getTicketById) {
        const resTicket = res.data.getTicketById;
        setName(resTicket.name);
        setDescription(resTicket.description);
        setRawPrice(resTicket.rawPrice);
        setPrice(resTicket.price);
        setStatus(resTicket.status);
        setDiscount(resTicket.discount);
        setQuota(resTicket.quota);
        setImg(resTicket.img);
      }
    });
  };
  const initialState = () => {
    setName("");
    setDescription("");
    setRawPrice(0);
    setPrice(0);
    setStatus(true);
    setDiscount(0);
    setQuota(0);
    setImg("");
  };

  const createTicket = () => {
    if (!validation()) {
      enqueueSnackbar(`Invalid Inputs`, {
        variant: "error",
      });
    }

    const input = createInput();
    createTicketMutation({
      variables: {
        input: input,
      },
    })
      .then((res) => {
        console.log(res);
        enqueueSnackbar(`Ticket created successfully`, {
          variant: "success",
        });
        refetch();
        handleClose();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      });
  };
  const updateTicket = () => {
    if (!validation()) {
      enqueueSnackbar(`Invalid Inputs`, {
        variant: "error",
      });
    }

    const input = createInput();
    updateTicketMutation({
      variables: {
        id: id,
        input: input,
      },
    })
      .then((res) => {
        console.log(res);
        enqueueSnackbar(`Ticket updated successfully`, {
          variant: "success",
        });
        refetch();
        handleClose();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      });
  };

  const validation = () => {
    return true;
  };

  const createInput = () => {
    const data = {
      name,
      description,
      rawPrice: parseFloat(rawPrice),
      price: parseFloat(price),
      status,
      discount: parseFloat(discount),
      quota: parseFloat(quota),
      img,
      insurancePrice: parseFloat(insurancePrice),
    };
    return data;
  };
  const resetFeaturedPhoto = () => {
    setImg("");
  };
  const uploadPhoto = (file) => {
    setImg(file.uri);
  };
  return (
    <div>
      {id ? (
        <IconButton
          aria-label="delete"
          onClick={handleClickOpen}
          style={{ position: "absolute", right: 0, top: 0 }}
        >
          <EditIcon />
        </IconButton>
      ) : (
        <Button variant="outlined" onClick={handleClickOpen}>
          Create New Ticket
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{id ? "Update Ticket" : "Create Ticket"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Ticket Title"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            fullWidth
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            margin="dense"
            id="rawPrice"
            label="Price"
            fullWidth
            variant="outlined"
            value={rawPrice}
            type="number"
            onChange={(e) => updateRawPrice(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="discount"
            label="Discount"
            fullWidth
            variant="outlined"
            value={discount}
            onChange={(e) => updateDiscount(e.target.value)}
            type="number"
          />
          <TextField
            autoFocus
            margin="dense"
            id="price"
            label="Discounted Price"
            fullWidth
            variant="outlined"
            value={price}
            onChange={(e) => updatePrice(e.target.value)}
            type="number"
          />
          <TextField
            autoFocus
            margin="dense"
            id="price"
            label="Insurance Price"
            fullWidth
            variant="outlined"
            value={insurancePrice}
            onChange={(e) => setInsurancePrice(e.target.value)}
            type="number"
          />

          <div>
            offline
            <Switch
              color="success"
              defaultChecked
              checked={status}
              onClick={() => {
                setStatus(!status);
              }}
            />
            online
          </div>
          <TextField
            autoFocus
            margin="dense"
            id="quota"
            label="Quota"
            fullWidth
            variant="outlined"
            value={quota}
            onChange={(e) => setQuota(e.target.value)}
          />

          <FeaturedImage
            featuredImg={img}
            uploadPhoto={uploadPhoto}
            resetFeaturedPhoto={resetFeaturedPhoto}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="img"
            label="img"
            fullWidth
            variant="outlined"
            value={img}
            onChange={(e) => setImg(e.target.value)}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          {id ? (
            <Button onClick={updateTicket}>Update</Button>
          ) : (
            <Button onClick={createTicket}>Create</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
