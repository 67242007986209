import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Button,
    FormControlLabel, IconButton, Switch
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { permissionState } from "../../../../globals/index";

const Permissions = (props) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (props.permission) {
      setPermission({ ...permission, ...props.permission });
    }
  }, [props]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [permission, setPermission] = useState(permissionState);
  const handleChangePermission = (event) => {
    /*  let name = event.target.name.split("-");
    let copyPermission = JSON.parse(JSON.stringify(permission));
    copyPermission[name[0]][name[1]] = event.target.checked;
    setPermission({ ...copyPermission }); */
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <VisibilityIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${props.email}'s Permissions`}
        </DialogTitle>
        <DialogContent>
          <FormGroup row>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={permission.inventory.admin}
                      color={"secondary"}
                      name="inventory-admin"
                      onChange={handleChangePermission}
                    />
                  }
                  label="Inventory Admin"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={permission.inventory.process}
                      color={"secondary"}
                      name="inventory-process"
                      onChange={handleChangePermission}
                    />
                  }
                  label="Process"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={permission.inventory.display}
                      color={"secondary"}
                      name="inventory-display"
                      onChange={handleChangePermission}
                    />
                  }
                  label="Display PIN"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={permission.author.admin}
                      color={"secondary"}
                      name="author-admin"
                      onChange={handleChangePermission}
                    />
                  }
                  label="Author Admin"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={permission.author.write}
                      color={"secondary"}
                      name="author-write"
                      onChange={handleChangePermission}
                    />
                  }
                  label="Author"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={permission.author.read}
                      color={"secondary"}
                      name="author-read"
                      onChange={handleChangePermission}
                    />
                  }
                  label="Author (Read)"
                />
              </Grid>
              {/* <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.customer.admin}
                        color={"secondary"}
                        name="customer"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Customer"
                  />
                </Grid> */}
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={permission.accountManager.admin}
                      color={"secondary"}
                      name="accountManager-admin"
                      onChange={handleChangePermission}
                    />
                  }
                  label="Account Manager"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={permission.customer.admin}
                      color={"secondary"}
                      name="customer-admin"
                      onChange={handleChangePermission}
                    />
                  }
                  label="Customer"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={permission.secretshop.admin}
                      color={"secondary"}
                      name="secretshop-admin"
                      onChange={handleChangePermission}
                    />
                  }
                  label="Secretshop"
                />
              </Grid>
            </Grid>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Permissions;
