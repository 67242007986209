import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { makeStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useMutation, useQuery } from "@apollo/client";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";

import { useSnackbar } from "notistack";

import {
  FETCH_PRODUCT_PLANS,
  CUSTOMER_ADDRESSES,
  GETACTIVEDOG,
  GET_SHIPPING_COST_FOR_ADDRESSES,
  RESTART_SUB,
  GET_NON_ACTIVE_DOGS,
} from "../../../queries";
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
} from "@mui/material";
import RestrictionsOfDogs from "./Components/Restrictions/restartSubRestriction";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RestartSubscription = ({ customer, customerMongoId, refetch }) => {
  const [open, setOpen] = React.useState(false);
  const [freeSwitch, setFreeSwitch] = useState(false);
  const [cycleCount, setCycleCount] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFreeSwitch(false);
    handleSetCycleCount(0);
    setOpen(false);
  };

  const handleSetCycleCount = (value) => {
    setCycleCount(parseInt(value));
  };

  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        color="secondary"
        startIcon={<RestartAltIcon />}
        onClick={handleClickOpen}
        style={{
          marginTop: 10,
        }}
        // disabled
        className=" btn-text-left btn-text-margin-left"
      >
        Start Subscription
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{
          minWidth: "300px",
        }}
      >
        {/* <DialogTitle id="alert-dialog-slide-title">{"RESTART"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <RestartStep
              customerMongoId={customerMongoId}
              handleClose={handleClose}
              refetch={refetch}
              loading={loading}
              setLoading={setLoading}
              initial={open}
              customer={customer}
              cycleCount={cycleCount}
              freeSwitch={freeSwitch}
              setFreeSwitch={setFreeSwitch}
              setCylceCount={handleSetCycleCount}
            />
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            className="redborder"
          >
            CANCEL
          </Button>
          <ConfirmButton />
        </DialogActions> */}
        {loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: "rgba(0,0,0,0.8)",
              top: 0,
              left: 0,
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default RestartSubscription;

const ConfirmButton = ({ onConfirm }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={handleClickOpen}
      >
        Confirm
      </Button>
      <Dialog
        style={{
          minWidth: "300px",
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Are you sure you want to restart"}
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            className="redborder"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              onConfirm();
              handleClose();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Select Dog",
    "Restrictions",
    "Select Box",
    "Select Shipping Address",
    "Summary",
  ];
}

const RestartStep = ({
  customerMongoId,
  handleClose,
  refetch,
  setLoading,
  initial,
  customer,
  cycleCount,
  freeSwitch,
  setFreeSwitch,
  setCylceCount,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep === 0 && Object.keys(dog).length === 0) {
      enqueueSnackbar("Need to select dog first", { variant: "error" });
      return;
    }
    if (activeStep === 2 && basket.length === 0) {
      enqueueSnackbar("Need to select at least one box", { variant: "error" });
      return;
    }
    if (activeStep === 3 && Object.keys(shippingAddress).length === 0) {
      enqueueSnackbar("Need to select shipping address", { variant: "error" });
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancelAtOfCycle = (value) => {
    console.log({ value });
    setCancelAtEndofCycle(value);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [dog, setDog] = useState({});
  const [basket, setBasket] = useState([]);
  const [shippingRate, setShippingRate] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [membership, setMembership] = useState(false);
  const [cancelAtEndOfCycle, setCancelAtEndofCycle] = React.useState(false);

  useEffect(() => {
    setMembership(customer.premium === 1);
  }, [customer]);

  const [restartSubMutation] = useMutation(RESTART_SUB);

  const restartSubscription = () => {
    setLoading(true);
    restartSubMutation({
      variables: {
        input: {
          dogId: dog.id,
          addressId: shippingAddress.id,
          items: basket.map((item) => {
            return {
              price: item.id,
              quantity: item.quantity,
            };
          }),
          shippingCost: shippingRate.rate?.shipmentCost,
          metadata: {
            shippingAmount: shippingRate.rate?.shipmentCost,
            serviceCode: shippingRate.rate?.serviceCode,
            bagsLength: shippingRate.rate?.bagsLength,
            height: shippingRate.rate?.height,
            length: shippingRate.rate?.length,
            width: shippingRate.rate?.width,
            weight: shippingRate.rate?.weight,
          },
          withPremium: membership,
          cycleCount: cycleCount,
          cancelEndOfCycle: cancelAtEndOfCycle,
        },
      },
    })
      .then((data) => {
        enqueueSnackbar("Need to select shipping address", {
          variant: "success",
        });
        refetch();
        setFreeSwitch(false);
        setCylceCount(0);
        handleClose();
        setLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar("Unable to restart subscription", { variant: "error" });
        setLoading(false);
      });
  };

  const initialState = () => {
    setActiveStep(0);
    setDog({});
    setBasket([]);
    setShippingRate({});
    setShippingAddress({});
  };

  useEffect(() => {
    initialState();
  }, [initial]);

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <DogList
              customerMongoId={customerMongoId}
              setDog={(data) => setDog(data)}
            />
          </div>
        );
      case 1:
        return <RestrictionsOfDogs dog={dog} id={dog.id} />;
      case 2:
        return (
          <div>
            <PlanList
              basket={basket}
              setBasket={(data) => {
                setBasket(data);
              }}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <AddressList
              customerMongoId={customerMongoId}
              basket={basket}
              setShippingRate={(data) => setShippingRate(data)}
              setShippingAddress={(data) => setShippingAddress(data)}
            />
          </div>
        );
      case 4:
        return (
          <div>
            <Summary
              basket={basket}
              dog={dog}
              shippingCost={shippingRate.rate?.shipmentCost.toFixed(2)}
              address={shippingAddress}
              membership={membership}
              cycleCount={cycleCount}
              cancelAtEndOfCycle={cancelAtEndOfCycle}
              setCancelAtEndofCycle={handleCancelAtOfCycle}
              freeSwitch={freeSwitch}
              setFreeSwitch={setFreeSwitch}
              setCylceCount={setCylceCount}
              setMembership={setMembership}
            />
          </div>
        );
      default:
        return <div>"Unknown stepIndex"</div>;
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            <div>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  width: "100%",
                }}
              >
                {/* <Button
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                  className="redborder"
                >
                  CANCEL
                </Button> */}
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>

                {activeStep === steps.length - 1 ? (
                  <ConfirmButton
                    onConfirm={() => {
                      restartSubscription();
                    }}
                  />
                ) : (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
              </Grid>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DogList = ({ customerMongoId, setDog }) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const { data, loading, error } = useQuery(GET_NON_ACTIVE_DOGS, {
    variables: {
      owner: customerMongoId,
    },
    fetchPolicy: "no-cache",
  });

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [];

    if (currentIndex === -1) {
      newChecked.push(value);
    }
    // else {
    //   newChecked.splice(currentIndex, 1);
    // }

    setChecked(newChecked);
    setDog(newChecked[0]);
  };

  if (loading) {
    return <Typography>Loading ...</Typography>;
  }
  if (error) {
    return <Typography>Something went wrong</Typography>;
  }
  return (
    <List dense className={classes.root}>
      {data.getNoActiveMongoDogsByOwnerID.map((value) => {
        const labelId = `checkbox-list-secondary-label-${value}`;
        const text = `${value.name}`;
        return (
          <ListItem key={value.id} button>
            <ListItemIcon>
              <Checkbox
                edge="end"
                onClick={() => {
                  if (checked.indexOf(value) === -1) {
                    handleToggle(value);
                  }
                }}
                checked={checked.indexOf(value) !== -1}
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>

            {/* <ListItemAvatar>
              <Avatar
                alt={`Avatar n°${value + 1}`}
                src={`/static/images/avatar/${value + 1}.jpg`}
              />
            </ListItemAvatar> */}
            <ListItemText id={labelId} primary={text} />
          </ListItem>
        );
      })}
      {data.getNoActiveMongoDogsByOwnerID.length === 0 && "No available dog"}
    </List>
  );
};

export const PlanList = ({ setBasket, basket }) => {
  const { loading, data, error } = useQuery(FETCH_PRODUCT_PLANS);

  const [checked, setChecked] = React.useState([]);

  const addToBasket = (product) => {
    const copy = JSON.parse(JSON.stringify(basket));
    const item = copy.find((item) => item.id === product.id);
    if (!item) {
      copy.push({ ...product, quantity: 1 });
    } else {
      const index = copy.indexOf(item);
      copy[index].quantity += 1;
    }
    setBasket(copy);
  };
  const removeToBasket = (product) => {
    const copy = JSON.parse(JSON.stringify(basket));
    const item = copy.find((item) => item.id === product.id);
    const index = copy.indexOf(item);
    if (item.quantity !== 1) {
      copy[index].quantity -= 1;
    } else {
      copy.splice(index, 1);
    }
    setBasket(copy);
  };

  if (loading) {
    return <Typography>Loading ...</Typography>;
  }
  if (error) {
    return <Typography>Something went wrong</Typography>;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {data.checkoutPlans.map((item) => {
        const element = basket.find((element) => element.id === item.id);
        return (
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography>{item.product.name}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    id={item.id}
                    onClick={() => {
                      if (element) {
                        removeToBasket(item);
                      }
                    }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  <Typography variant="subtitle1">
                    {element ? element.quantity : 0}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    id={item.id}
                    onClick={() => addToBasket(item)}
                  >
                    <ControlPointIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

const AddressList = ({
  customerMongoId,
  basket,
  setShippingRate,
  setShippingAddress,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const [getRates, { data: rateData, loading: rateLoading, error: rateError }] =
    useMutation(GET_SHIPPING_COST_FOR_ADDRESSES, {
      variables: {
        input: {
          plans: basket.map((item) => {
            return { planId: item.id, quantity: item.quantity };
          }),
        },
        userId: customerMongoId,
      },
    });
  const { data, loading, error } = useQuery(CUSTOMER_ADDRESSES, {
    variables: {
      owner: customerMongoId,
    },
  });
  useEffect(() => {
    getRates().catch(() => {});
  }, [getRates]);
  const handleToggle = (value, rate) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [];

    if (currentIndex === -1) {
      newChecked.push(value);
    }
    // else {
    //   newChecked.splice(currentIndex, 1);
    // }

    setChecked(newChecked);
    setShippingAddress(value);
    setShippingRate(rate);
  };
  if (loading || rateLoading) {
    return <Typography>Loading ...</Typography>;
  }
  if (error || rateError) {
    return <Typography>Something went wrong</Typography>;
  }
  if (!rateData) {
    return <Typography>Wait for data</Typography>;
  }
  return (
    <List dense className={classes.root}>
      {data.ownerAddress.map((value) => {
        const labelId = `checkbox-list-secondary-label-${value}`;
        const text = `${value.name} / ${value.address} / ${value.address2} / ${value.city} / ${value.postal_code}`;
        const rate = rateData.getRatesMultiple.find(
          (item) => item.addressId === value.id
        );
        return (
          <ListItem key={value.id} button>
            <ListItemIcon>
              <Checkbox
                edge="end"
                onClick={() => {
                  if (checked.indexOf(value) === -1) {
                    handleToggle(value, rate);
                  }
                }}
                checked={checked.indexOf(value) !== -1}
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>

            <ListItemText
              id={labelId}
              primary={text}
              style={{
                marginRight: 20,
              }}
            />
            <ListItemSecondaryAction>
              ${rate.rate.shipmentCost.toFixed(2)}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};
const Summary = ({
  dog,
  basket,
  shippingCost,
  address,
  membership,
  cycleCount,
  cancelAtEndOfCycle,
  setCancelAtEndofCycle,
  setMembership,
  setCylceCount,
  freeSwitch,
  setFreeSwitch,
}) => {
  const addressText = `${address.name} / ${address.address} / ${address.address2} / ${address.city} / ${address.postal_code}`;
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>{dog.name}</Grid>
          {basket.map((item) => {
            return (
              <Grid item>
                {item.quantity} x {item.product.name} {" = > "}$
                {parseFloat((item.quantity * item.amount) / 100).toFixed(2)}
              </Grid>
            );
          })}
          <Grid item>Shipping Address: {addressText}</Grid>

          <Grid item>
            Shipping cost:{" "}
            {membership ? "Free (Membership) " : `$${shippingCost}`}
          </Grid>
          <Grid item>
            Total:{" $"}
            {parseFloat(
              basket
                .map((item) => item.quantity * item.amount)
                .reduce((a, b) => a + b, 0) /
                100 +
                (membership ? 0 : parseFloat(shippingCost))
            ).toFixed(2)}{" "}
          </Grid>
          {/* <Grid item>
            <ListItem button>
              <ListItemIcon>
                <Checkbox
                  edge="end"
                  onClick={() => {
                    setMembership(!membership);
                  }}
                  checked={membership}
                />
              </ListItemIcon>

              <ListItemText primary={"Membership"} />
            </ListItem>
          </Grid> */}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormGroup sx={{ marginBottom: "10px", marginTop: "10px" }}>
            <FormControlLabel
              control={
                <Switch
                  color="success"
                  checked={freeSwitch}
                  onChange={() => {
                    if (freeSwitch) {
                      setCylceCount(0);
                    } else {
                      setCylceCount(1);
                    }
                    setFreeSwitch(!freeSwitch);
                  }}
                />
              }
              label="Free Subscription"
            />
          </FormGroup>
          {freeSwitch && (
            <FormControl sx={{ minWidth: "200px" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ marginBottom: "10px" }}
              >
                Select Free Subscription Cycles
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cycleCount}
                label="Age"
                onChange={(e) => setCylceCount(e.target.value)}
              >
                {Array(12)
                  .fill(1)
                  .map((v, i) => {
                    return <MenuItem value={i + 1}>{i + 1}</MenuItem>;
                  })}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cancelAtEndOfCycle}
                    onChange={(e) => setCancelAtEndofCycle(e.target.checked)}
                  />
                }
                label="Cancel at end of Cycle"
              />
            </FormControl>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
