import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { CREATE_SAVED_SCHEDULE } from "../../../../../queries";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000025",
    position: "absolute",
  },
}));

export default function SavedScheduleDetail({
  schedule,
  refetch,
  id,
  savedScheduleId,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(schedule.name);
  const [schedules, setSchedules] = React.useState(
    schedule.schedules.map((item) => item.value)
  );

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const onSetValue = (index) => {
    let copy = JSON.parse(JSON.stringify(schedules));
    copy[index] = !copy[index];
    setSchedules(copy);
  };
  const [createSaved, { loading }] = useMutation(CREATE_SAVED_SCHEDULE);

  const updateSavedSchedule = () => {
    let data = {
      id: savedScheduleId,
      name: name,
      admin: id,
      schedules: schedules.map((item, index) => {
        return {
          name: labels[index],
          value: item,
        };
      }),
    };
    createSaved({
      variables: {
        input: data,
      },
    })
      .then((res) => {
        refetch();
        // need success page
        enqueueSnackbar(`Your consult updated successfully`, {
          variant: "success",
        });
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(`Your consult is not created `, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <EditIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <DialogTitle id="alert-dialog-title">
          Update {schedule.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              style={{ marginTop: 20 }}
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => {
                e.stopPropagation();
                setName(e.target.value);
              }}
            />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {schedules.map((item, index) => {
                return (
                  <Grid item sm={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            color="secondary"
                            checked={item}
                            onClick={() => {
                              onSetValue(index);
                            }}
                          />
                        }
                        label={labels[index]}
                      />
                    </FormGroup>
                  </Grid>
                );
              })}
              <Grid item sm={4}>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={schedules.every((item) => item)}
                      onClick={() => {
                        setSchedules(Array(20).fill(true));
                      }}
                    />
                  }
                  label={"All"}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={updateSavedSchedule} autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const labels = [
  "09:00-09:20",
  "09:30-09:50",
  "10:00-10:20",
  "10:30-10:50",
  "11:00-11:20",
  "11:30-11:50",
  "12:00-12:20",
  "12:30-12:50",
  "13:00-13:20",
  "13:30-13:50",
  "14:00-14:20",
  "14:30-14:50",
  "15:00-15:20",
  "15:30-15:50",
  "16:00-16:20",
  "16:30-16:50",
  "17:00-17:20",
  "17:30-17:50",
  "18:00-18:20",
  "18:30-18:50",
];
