import { gql } from "@apollo/client";

const GET_CANCELS = gql`
  query {
    cancel {
      name
      email
      phone
      userId
      subId
      ref
      url
      date
    }
  }
`;

const GET_CANCELS_FILTER = gql`
  query (
    $startDate: Int!
    $endDate: Int!
    $offset: Int
    $limit: Int
    $order: String
    $requestStatus: String
  ) {
    cancelFilter(
      startDate: $startDate
      endDate: $endDate
      offset: $offset
      limit: $limit
      order: $order
      requestStatus: $requestStatus
    ) {
      id
      owner
      member {
        id
        name
        email
        created_at
      }
      dog
      subCreatedAt
      createdAt
      cancelReason
      canceledBy {
        type
        id
        name
        email
      }
      dontCanceledBy {
        type
        id
        name
        email
      }
      canceledAt
      dontCancel
      canceledSub
      canceledProduct
      note {
        admin {
          id
          email
        }
        message
        createdAt
      }
      cancelRequest {
        status
      }
      reached {
        admin {
          name
          email
        }
        status
        createdAt
      }
    }
    cancelsCount(
      startDate: $startDate
      endDate: $endDate
      requestStatus: $requestStatus
    )
  }
`;

const CANCELSUBSCRIPTION = gql`
  mutation CancelDogSubscription(
    $dogId: ID
    $subId: String
    $cancelAtPeriodEnd: Boolean
    $createdAt: Int
    $message: String
  ) {
    cancelSubscription(
      dogID: $dogId
      subID: $subId
      cancelAtPeriodEnd: $cancelAtPeriodEnd
      createdAt: $createdAt
      message: $message
    ) {
      id
      status
    }
  }
`;

const DONTCANCELSUBSCRIPTION = gql`
  mutation DontCancelSubscription(
    $subId: String
    $time: Int
    $membership: Boolean
  ) {
    dontCancelSubscription(
      subID: $subId
      time: $time
      membership: $membership
    ) {
      id
      status
      cancel_at
      canceled_at
      cancel_at_period_end
    }
  }
`;

const CANCELMEMBERSHIP = gql`
  mutation CancelPremiumSub(
    $id: ID
    $cancelAtPeriodEnd: Boolean
    $message: String
    $createdAt: Int
  ) {
    cancelMembershipSubscriptionAdmin(
      id: $id
      cancelAtPeriodEnd: $cancelAtPeriodEnd
      message: $message
      createdAt: $createdAt
    ) {
      id
    }
  }
`;

const CANCELMEMBERSHIP_BYCANCELID = gql`
  mutation CancelPremiumSubByCancelId(
    $id: ID
    $cancelAtPeriodEnd: Boolean
    $message: String
  ) {
    cancelMembershipByCancelId(
      id: $id
      cancelAtPeriodEnd: $cancelAtPeriodEnd
      message: $message
    ) {
      id
    }
  }
`;

const CANCELATION_STATS = gql`
  query CancelationStats {
    cancelationStats {
      dayCounts {
        day
        count
      }
      reasonCounts {
        reason
        count
      }
    }
  }
`;

const WRITE_COMMENT = gql`
  mutation WriteComment($id: ID, $input: inputAdminCancelMessage) {
    writeAdminMessage(id: $id, input: $input) {
      note {
        message
        createdAt
      }
    }
  }
`;
const CHECK_REACH = gql`
  mutation ($id: ID, $createdAt: Int) {
    checkCancelationAdmin(id: $id, createdAt: $createdAt) {
      reached {
        status
        createdAt
      }
    }
  }
`;

const CHANGE_STATUS_CANCEL = gql`
  mutation CancelReq(
    $id: ID
    $status: String
    $cancelReason: String
    $createdAt: Int
    $cancelAtPeriodEnd: Boolean
  ) {
    changeStatusCancelReq(
      id: $id
      status: $status
      cancelReason: $cancelReason
      createdAt: $createdAt
      cancelAtPeriodEnd: $cancelAtPeriodEnd
    ) {
      id
      dog
      cancelRequest {
        status
      }
    }
  }
`;

const CANCEL_FIND = gql`
  query ($email: String, $phone_number: String) {
    cancelFind(email: $email, phone_number: $phone_number) {
      id
      owner
      member {
        id
        name
        email
        created_at
      }
      dog
      subCreatedAt
      createdAt
      cancelReason
      canceledBy {
        type
        id
        name
        email
      }
      dontCanceledBy {
        type
        id
        name
        email
      }
      canceledAt
      dontCancel
      canceledSub
      canceledProduct
      note {
        admin {
          id
          email
        }
        message
        createdAt
      }
      cancelRequest {
        status
      }
      reached {
        admin {
          name
          email
        }
        status
        createdAt
      }
    }
  }
`;

export {
  GET_CANCELS,
  GET_CANCELS_FILTER,
  CANCELSUBSCRIPTION,
  DONTCANCELSUBSCRIPTION,
  CANCELMEMBERSHIP,
  CANCELMEMBERSHIP_BYCANCELID,
  CANCELATION_STATS,
  WRITE_COMMENT,
  CHECK_REACH,
  CHANGE_STATUS_CANCEL,
  CANCEL_FIND,
};
