import React from "react";
import TextField from "@mui/material/TextField";

const QuizTitle = ({ title, setTitle }) => {
  return (
    <div>
      <TextField
        fullWidth
        label="Quiz Title"
        variant="outlined"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />
    </div>
  );
};

export default QuizTitle;
