import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import EditLocationIcon from "@mui/icons-material/EditLocation";

import { useMutation, useQuery } from "@apollo/client";
import {
  CUSTOMER_ADDRESSES,
  GET_SHIPPING_COST_FOR_ADDRESSES,
} from "../../../../../queries";
import {
  GET_CUSTOMERS_DOGS,
  UPDATE_DOG_SUB_ADDRESS,
} from "../../../../../queries";
import { useSnackbar } from "notistack";
const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    height: 100,
    marginTop: 20,
  },
  gridItem: {
    position: "relative",
  },
  cancelIcon: {
    position: "absolute",
    right: "30px",
    top: "15px",
  },
  subBtn: {
    marginLeft: "5px",
  },
  parent: {
    position: "relative",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000025",
    position: "absolute",
  },
  backdropShippingCalc: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000",
    position: "absolute",
  },
  cancelBtn: {
    backgroundColor: "red",
  },
}));
const ShippingCostsOfDog = ({
  customerMongoId,
  subscriptionId,
  customer,
  close,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [rawRate, setRawRate] = useState([]);
  const [dog, setDog] = useState([]);

  const [open, setOpen] = useState(false);
  const [subAddressUpdating, setSubAddressUpdating] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    close();
  };

  const [shipingCostForAddresses] = useMutation(
    GET_SHIPPING_COST_FOR_ADDRESSES
  );
  const { loading, data, error } = useQuery(GET_CUSTOMERS_DOGS, {
    variables: { id: customerMongoId },
  });
  const {
    data: addressData,
    loading: addressLoading,
    error: addressError,
  } = useQuery(CUSTOMER_ADDRESSES, {
    variables: {
      owner: customerMongoId,
    },
  });
  const [costLoading, setConstLoading] = useState(true);
  useEffect(() => {
    if (data && subscriptionId && open) {
      const singleDog = data.getMongoDogsByOwnerID.find(
        (dog) => dog.subscriptions?.subId === subscriptionId
      );
      setDog(singleDog);
      calculateAllShippingCost(singleDog.subscriptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error, open]);

  const calculateAllShippingCost = (sub) => {
    if (sub.items == null || sub.items == undefined) {
      enqueueSnackbar("Missing data on db.Please contact to developer team.", {
        variant: "error",
      });
      handleClose();

      return;
    }
    const localItems = sub.items;
    const prodReqParam = localItems
      .filter(
        (item) =>
          item.productId !== process.env.REACT_APP_MEMBERSHIP_PROD_ID &&
          item.package_dimensions
      )
      .map((item) => {
        return {
          planId: item.planId,
          quantity: item.quantity,
        };
      });
    shipingCostForAddresses({
      variables: {
        input: { plans: prodReqParam },
        userId: customerMongoId,
      },
    })
      .then((response) => {
        setRawRate(response.data.getRatesMultiple);
        setConstLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("Unable to fetch shipping costs", {
          variant: "error",
        });
        setConstLoading(false);
      });
  };

  if (loading) {
    return <div>loading</div>;
  }
  return (
    <div>
      {addressData && addressData.ownerAddress.length > 1 && (
        <IconButton color="primary" size={"small"} onClick={handleClickOpen}>
          <EditLocationIcon />
        </IconButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Backdrop
          className={classes.backdropShippingCalc}
          open={addressLoading || loading || costLoading}
        >
          <Typography
            variant={"subtitle1"}
            style={{ color: "white" }}
            align={"center"}
          >
            <CircularProgress color="primary" />
            <br />
            Please wait for the shipment fee calculation
          </Typography>
        </Backdrop>
        <Backdrop
          className={classes.backdropShippingCalc}
          open={subAddressUpdating}
        >
          <Typography
            variant={"subtitle1"}
            style={{ color: "white" }}
            align={"center"}
          >
            <CircularProgress color="primary" />
            <br />
            Please wait subscription address is updating
          </Typography>
        </Backdrop>
        <DialogTitle id="alert-dialog-title">{"Shipping Costs"}</DialogTitle>
        <DialogContent>
          <Addresslist
            rawRate={rawRate}
            userAddressList={addressData?.ownerAddress}
            loading={addressLoading}
            sub={dog.subscriptions}
            customer={customer}
            close={handleClose}
            setSubAddressUpdating={setSubAddressUpdating}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ShippingCostsOfDog;

const Addresslist = ({
  userAddressList,
  rawRate,
  loading,
  sub,
  customer,
  close,
  setSubAddressUpdating,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [addressDialogConfirm, setAddressDialogConfirm] = useState(false);
  const [choosenAddress, setChoosenAddress] = useState({});
  const [updateDogSubAddress] = useMutation(UPDATE_DOG_SUB_ADDRESS);
  const acceptTheShippingCost = async () => {
    setSubAddressUpdating(true);
    let costItem = rawRate.find((item) => item.addressId === choosenAddress.id);
    setAddressDialogConfirm(false);
    let metadata = {};
    if (customer.premium === 0) {
      const singleOn = rawRate.find(
        (item) => item.addressId === choosenAddress.id
      );
      metadata = {
        shippingAmount: singleOn.rate.shipmentCost,
        bagsLength: singleOn.rate.bagsLength,
        width: singleOn.rate.width,
        height: singleOn.rate.height,
        length: singleOn.rate.length,
        weight: singleOn.rate.weight,
      };
    }

    updateDogSubAddress({
      variables: {
        subId: sub.subId,
        addressId: choosenAddress.id,
        shippingCost: costItem.shippingAmount,
        metadata: metadata,
      },
    })
      .then((response) => {
        close();
        enqueueSnackbar("Subscription Address Updated", {
          variant: "success",
        });
        setSubAddressUpdating(false);
      })
      .catch((err) => {
        close();
        enqueueSnackbar("Subscription Address Could not Updated", {
          variant: "error",
        });
        setSubAddressUpdating(false);
      });

    setAddressDialogConfirm(false);
  };
  if (loading) {
    return "Loading";
  }
  return (
    <div>
      <List>
        {userAddressList.map((address, index) => {
          const labelId = `checkbox-list-label-${address}`;
          let costItem = rawRate.find((item) => item.addressId === address.id);
          return (
            <ListItem
              key={address}
              role={undefined}
              dense
              button
              onClick={() => {
                setAddressDialogConfirm(true);
                setChoosenAddress(address);
              }}
            >
              <ListItemText
                style={{ marginRight: 20, width: "80%" }}
                id={labelId}
                primary={
                  <div>
                    {`${index + 1}. ${address.name} / ${address.address} / `}
                    {`${address.city} / ${address.state} / ${address.postal_code}`}
                  </div>
                }
              />

              <ListItemSecondaryAction>
                {costItem
                  ? parseFloat(costItem.rate.shipmentCost).toFixed(2)
                  : ""}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <Dialog
        open={addressDialogConfirm}
        onClose={() => {
          setAddressDialogConfirm(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you select the address the next shipment will be delivered to the
            selected address. If you want new shipment to selected address
            contact to support team.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddressDialogConfirm(false);
            }}
            color="primary"
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              acceptTheShippingCost();
            }}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// req={
//   addressId, newAddress:{}
// }

// response= [
//   {
//     dog:{
//       name: "Goku"
//     },
//     rate: {
//       shippingCost: 10.81
//     }
//   }
// ]
