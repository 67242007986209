import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { makeStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useMutation, useQuery } from "@apollo/client";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import { useSnackbar } from "notistack";
import { GET_PRODUCTS } from "../../../../../queries";
import LinearProgress from "@mui/material/LinearProgress";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";

const SSProducts = ({ setBasket, basket }) => {
  const { loading, data, error } = useQuery(GET_PRODUCTS);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (data && data.products) {
      console.log("data=>", data);
      setProducts(
        data.products.map((item) => {
          return { ...item, selectedSku: 0 };
        })
      );
    }
  }, [loading, data, error]);

  const addToBasket = (product, prod) => {
    const copy = JSON.parse(JSON.stringify(basket));
    const item = copy.find((item) => item.id === product.id);
    if (!item) {
      copy.push({ ...product, quantity: 1, name: prod.name });
    } else {
      const index = copy.indexOf(item);
      copy[index].quantity += 1;
    }
    setBasket(copy);
  };
  const removeToBasket = (product) => {
    const copy = JSON.parse(JSON.stringify(basket));
    const item = copy.find((item) => item.id === product.id);
    const index = copy.indexOf(item);
    if (item.quantity !== 1) {
      copy[index].quantity -= 1;
    } else {
      copy.splice(index, 1);
    }
    setBasket(copy);
  };

  const setSkuIndex = (index, sku) => {
    let productsCopy = JSON.parse(JSON.stringify(products));
    productsCopy[index].selectedSku = sku;
    setProducts(productsCopy);
  };

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <Typography>Something went wrong</Typography>;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {products.map((item, index) => {
        const element = basket.find(
          (basketItem) => item.skus[item.selectedSku]?.id === basketItem.id
        );
        //  basket.find((element) => element.id === item.id);
        return (
          <Grid item xs={4}>
            <div className="ssproducts">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography align="center">
                      <div className="itemname"> {item.name}</div>

                      {item.skus.length === 1 && (
                        <div>
                          <Chip
                            label={`$${item.skus[0].price}`}
                            variant="outlined"
                            size="small"
                            color="success"
                          />
                          <Chip
                            label={`Stock: ${item.skus[0].inventory.quantity}`}
                            variant="outlined"
                            size="small"
                            color="primary"
                          />
                        </div>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                {item.skus.length > 1 && (
                  <Grid>
                    <SelectSku
                      list={item.skus}
                      handleChange={(skuIndex) => setSkuIndex(index, skuIndex)}
                      selected={item.selectedSku}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                <div className='quantitybox'>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconButton
                      aria-label="delete"
                      id={item.id}
                      onClick={() => {
                        if (element) {
                          removeToBasket(item.skus[item.selectedSku]);
                        }
                      }}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                    <Typography variant="subtitle1">
                      {element ? element.quantity : 0}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      id={item.id}
                      onClick={() =>
                        addToBasket(item.skus[item.selectedSku], item)
                      }
                    >
                      <ControlPointIcon />
                    </IconButton>
                  </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SSProducts;

const SelectSku = ({ list, selected, handleChange }) => {
  return (
    <div>
      <TextField
        size={"small"}
        id="outlined-select-currency"
        select
        label="Select"
        value={selected}
      
      >
        {list.map((option, index) => (
          <MenuItem
            key={option.value}
            onClick={() => handleChange(index)}
            value={index}
          >
            {option.attributes.size} - {option.attributes.gender}
            <Chip
              label={`$${option.price}`}
              variant="outlined"
              size="small"
              color="success"
            />
            <Chip
              label={`${option.inventory.quantity}`}
              variant="outlined"
              size="small"
              color="primary"
            />
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};
