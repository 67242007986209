import { gql } from "@apollo/client";

const GET_QUIZZES = gql`
  query GetQuizzes {
    getQuizzes {
      id
      tags
      title
      categories
      status
      quizType
      questions {
        question
      }
    }
  }
`;

const GET_SINGLE_QUIZ = gql`
  query GetSingleQuiz($input: inputSlugId) {
    getSingleQuiz(input: $input) {
      title
      status
      sefurl
      tags
      categories
      quizType
      passScore
      countDown
      questions {
        id
        question
        options
        answerIndex
        questionType
        answer
        status
      }
    }
  }
`;

const UPDATE_QUIZ = gql`
  mutation UpdateQuiz($id: ID, $input: inputCreateQuiz) {
    updateQuiz(id: $id, input: $input) {
      title
      questions {
        question
      }
    }
  }
`;

const CREATE_QUIZ = gql`
  mutation CreateQuiz($input: inputCreateQuiz) {
    createQuiz(input: $input) {
      id
      tags
      title
      categories
      status
      quizType
      questions {
        question
      }
    }
  }
`;

const GET_COURSES = gql`
  query GetCourses {
    getCourses {
      id
      title
      featured_img
      courseDetail
      prerequisites
      status
      units {
        id
        title
        courseId
        tutorials {
          id
        }
        status
      }
    }
  }
`;

const GET_COURSES_CUSTOMER = gql`
  query GetCoursesForCustomer {
    getCoursesForCustomer {
      id
      title
      status
      sefurl
      tags
      featured_img
      categories
      courseDetail
      price
    }
  }
`;

const CREATE_COURSE = gql`
  mutation CreateCourse($input: inputCourse) {
    createCourse(input: $input) {
      title
      id
    }
  }
`;

const UPDATE_COURSE = gql`
  mutation UpdateCourse($id: ID, $input: inputCourse) {
    updateCourse(id: $id, input: $input) {
      title
      id
    }
  }
`;
const GET_SINGLE_COURSE = gql`
  query GetSingleCourse($input: inputSingleCourse) {
    getSingleCourse(input: $input) {
      id
      title
      status
      featured_img
      tags
      categories
      courseDetail
      prerequisites
      price
      courseDescription
      addOns {
        addOn {
          name
          description
          id
          price
        }
        isPriceIncluded
      }
      units {
        id
        title
        status
        prerequisites
      }
    }
  }
`;

const CREATE_UNIT = gql`
  mutation CreateUnit($input: inputUnit) {
    createUnit(input: $input) {
      id
    }
  }
`;
const UPDATE_UNIT = gql`
  mutation UpdateUnit($id: ID, $input: inputUnit) {
    updateUnit(id: $id, input: $input) {
      id
      tutorials {
        id
      }
    }
  }
`;

const GET_SINGLE_UNIT = gql`
  query GetSingleUnit($input: inputSlugId) {
    getSingleUnit(input: $input) {
      id
      title
      sefurl
      unitDetail
      status
      featured_img
      prerequisites
      tutorials {
        id
        title
        content
        type
        text
      }
      courseId
    }
  }
`;

const GET_QUESTIONS = gql`
  query GetQuestions {
    getQuestions {
      id
      question
      options
      answer
      answerIndex
      questionType
      tags
      categories
      status
    }
  }
`;

const GET_QUESTIONS_ADMIN = gql`
  query GetQuestionsAdmin($limit: Int, $offset: Int) {
    getQuestionsAdmin(limit: $limit, offset: $offset) {
      id
      question
      options
      answer
      answerIndex
      questionType
      tags
      categories
      status
    }
    questionsCount
  }
`;

const QUESTIONS_FIND = gql`
  query ($key: String, $questionType: String) {
    questionsFind(key: $key, questionType: $questionType) {
      id
      question
      options
      answer
      answerIndex
      questionType
      tags
      categories
      status
    }
  }
`;

const GET_SINGLE_QUESTION = gql`
  query GetSingleQuestions($id: ID) {
    getSingleQuestions(id: $id) {
      id
      question
      options
      answer
      answerIndex
      questionType
      tags
      categories
      status
    }
  }
`;

const CREATE_QUESTION = gql`
  mutation CreateQuestion($input: inputQuestion) {
    createQuestion(input: $input) {
      question
      options
      answerIndex
      questionType
      tags
      categories
      status
    }
  }
`;

const UPDATE_QUESTION = gql`
  mutation UpdateQuestion($id: ID, $input: inputQuestion) {
    updateQuestion(id: $id, input: $input) {
      question
      options
      answerIndex
      questionType
      tags
      categories
      status
    }
  }
`;

const GET_FINAL_ALL_ATTENDANCES = gql`
  query GetAttendance($unitId: ID, $limit: Int, $offset: Int) {
    getAllFinalQuizAttendances(
      unitId: $unitId
      limit: $limit
      offset: $offset
    ) {
      id
      createdAt
      scoreDetail {
        totalScore
        pass
        note
      }
      owner {
        id
        name
        phone_number
        email
      }
      unit {
        id
        title
      }
      course {
        id
        title
      }
    }
    getAllFinalQuizAttendancesCount
  }
`;

const GET_FINAL_ATTENDANCES = gql`
  query GetAttendance($unitId: ID, $limit: Int, $offset: Int) {
    getFinalQuizAttendances(unitId: $unitId, limit: $limit, offset: $offset) {
      id
      owner {
        id
        name
        phone_number
        email
      }
    }
    getFinalQuizAttendancesCount(unitId: $unitId)
  }
`;

const GET_FINAL_ATTENDANCES_ANSWERS = gql`
  query GetAttendanceAnswers($id: ID) {
    getFinalQuizAttendancesAnswers(id: $id) {
      id
      scoreDetail {
        totalScore
        pass
        note
      }
      questionsAnswers {
        id
        questionId
        customerAnswerIndex
        customerAnswerText
        score
      }
      questions {
        id
        questionType
        question
        options
        answerIndex
        answer
      }
    }
  }
`;

const UPDATE_FINAL_RESULT = gql`
  mutation UpdateFinalResult($id: ID, $input: inputSubmitQuizResult) {
    submitQuizResult(id: $id, input: $input) {
      id
      scoreDetail {
        totalScore
        note
      }
      questionsAnswers {
        id
        questionId
        customerAnswerIndex
        customerAnswerText
        score
      }
    }
  }
`;

const GET_ADDONS = gql`
  query GetAddOns {
    getAddOns {
      id
      name
      description
      price
    }
  }
`;

const PURCHASE_COURSE = gql`
  mutation PurchaseCourseAdmin(
    $courseId: ID
    $customerId: ID
    $input: PurchaseCourseInput
  ) {
    purchaseCourseAdmin(
      courseId: $courseId
      customerId: $customerId
      input: $input
    ) {
      name
      email
    }
  }
`;

const FETCH_CUSTOMER_COURSES = gql`
  query PurchasedCoursesOfCustomer($customerId: ID) {
    purchasedCoursesOfCustomer(customerId: $customerId) {
      status
      id
      orderId
      customer
      courseId
      endDate
      createdAt
    }
  }
`;

const GET_CUSTOMER_TUTORIAL_PROGRESS = gql`
  query GetCustomersTutorialProgress($input: inputCustomerTutorialProgress) {
    getCustomersTutorialProgress(input: $input) {
      unitId
      customerId
      completedTutorials {
        tutorialId
        passed
      }
      currentTutorial
    }
  }
`;

const UPDATE_WORKSHOP = gql`
  mutation UpdateWorkshop($id: ID, $input: WorkshopUpdateInput) {
    updateWorkshop(id: $id, input: $input) {
      name
    }
  }
`;
const GET_SINGLE_TUTORIAL = gql`
  query getSingleTutorial($input: inputSingleTutorial) {
    getSingleTutorial(input: $input) {
      id
      title
    }
  }
`;

const GET_UNIT_PROGRESS_OF_CUSTOMER = gql`
  query getUnitProgressOfCustomer($customerId: ID, $courseId: ID) {
    getUnitProgressOfCustomer(customerId: $customerId, courseId: $courseId) {
      id
      customerId
      courseId
      completedUnits
    }
  }
`;
const COMPLETE_UNIT = gql`
  mutation CompleteUnit($input: inputCompleteUnit) {
    completeUnit(input: $input) {
      id
    }
  }
`;
const COMPLETE_DANGER_UNIT = gql`
  mutation completeDangerUnit($input: inputCompleteUnit) {
    completeDangerUnit(input: $input) {
      id
    }
  }
`;

const GET_WORKSHOP = gql`
  query GetWorkshop {
    getWorkshop {
      id
      name
      description
      times {
        endTime
        startTime
        status
        comingSoon
      }
      categories
      status
      quizType
      questions {
        question
      }
    }
  }
`;
const GET_COURSE_SUCCESS_PAGE = gql`
  query GetCourseSuccessPage($courseId: ID) {
    getCourseSuccessPage(courseId: $courseId) {
      courseId
      text
    }
  }
`;
const UPDATE_COURSE_SUCCESS_PAGE = gql`
  mutation updateCourseSuccessPage(
    $courseId: ID
    $input: inputCourseSuccessPage
  ) {
    updateCourseSuccessPage(courseId: $courseId, input: $input) {
      courseId
      text
    }
  }
`;
const GET_NUMBER_OF_COURSE_USER = gql`
  query GetNumberOfCourseUser {
    getNumberOfCourseUser {
      nutrition {
        total
        paid
        free
      }
      pro {
        total
        paid
        free
      }
    }
  }
`;
const COMPLETE_THE_TUTORIAL = gql`
  mutation completeTheTutorial($input: inputCompleteTutorial) {
    completeTheTutorial(input: $input) {
      unitId
      currentTutorial
      completedTutorials {
        tutorialId
        passed
      }
    }
  }
`;

const GET_CERTIFICATION_VERIFY = gql`
  query GetCertificationVerify(
    $code: String
    $workshopScheduleIds: [ID]
    $customerId: ID
  ) {
    getCertificationVerify(
      customerId: $customerId
      code: $code
      workshopScheduleIds: $workshopScheduleIds
    ) {
      customer {
        id
        name
        email
      }
      courseProgress {
        id
        completedCourses {
          courseId
          completedAt
          completeCode
        }
      }
      course {
        title
        courseDetail
        id
      }
      pdfUri
    }
  }
`;
const CREATE_BADGE = gql`
  mutation CreateBadge($code: String) {
    createBadge(code: $code)
  }
`;

export {
  GET_QUIZZES,
  GET_SINGLE_QUIZ,
  UPDATE_QUIZ,
  CREATE_QUIZ,
  GET_COURSES,
  GET_COURSES_CUSTOMER,
  CREATE_COURSE,
  UPDATE_COURSE,
  GET_SINGLE_COURSE,
  CREATE_UNIT,
  GET_SINGLE_UNIT,
  UPDATE_UNIT,
  GET_QUESTIONS,
  GET_QUESTIONS_ADMIN,
  CREATE_QUESTION,
  UPDATE_QUESTION,
  GET_SINGLE_QUESTION,
  QUESTIONS_FIND,
  GET_FINAL_ATTENDANCES,
  GET_FINAL_ATTENDANCES_ANSWERS,
  UPDATE_FINAL_RESULT,
  GET_FINAL_ALL_ATTENDANCES,
  GET_ADDONS,
  GET_WORKSHOP,
  UPDATE_WORKSHOP,
  PURCHASE_COURSE,
  FETCH_CUSTOMER_COURSES,
  GET_CUSTOMER_TUTORIAL_PROGRESS,
  GET_SINGLE_TUTORIAL,
  GET_UNIT_PROGRESS_OF_CUSTOMER,
  COMPLETE_UNIT,
  GET_COURSE_SUCCESS_PAGE,
  UPDATE_COURSE_SUCCESS_PAGE,
  GET_NUMBER_OF_COURSE_USER,
  COMPLETE_THE_TUTORIAL,
  COMPLETE_DANGER_UNIT,
  GET_CERTIFICATION_VERIFY,
  CREATE_BADGE,
};
