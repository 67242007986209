import React, { useEffect, useState } from "react";
import {
  CREATE_ADMIN,
  GET_ADMINS,
  ADD_PERMISSION,
  UPDATE_ADMIN,
} from "../../../../queries";
import { useQuery, useMutation } from "@apollo/client";
import {
  Button,
  TextField,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Edit from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import { UploadFile, MultipleFile } from "../../../Tools/Upload";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import { permissionState } from "../../../../globals/index";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000095",
    position: "absolute",
  },
}));
const AdminCreateEditComponent = ({ admin, refetch }) => {
  const initialState = {
    name: "",
    surname: "",
    email: "",
    title: "",
  };
  const classes = useStyles();

  const [form, setState] = useState(initialState);
  const [openCreateAdmin, setOpenCreateAdmin] = useState(false);
  const [permission, setPermission] = useState(permissionState);

  const [updateAdmin, { loading }] = useMutation(UPDATE_ADMIN);

  const { name, surname, email, title, password } = form;

  const handleChangePermission = (event) => {
    let name = event.target.name.split("-");
    let copyPermission = JSON.parse(JSON.stringify(permission));
    copyPermission[name[0]][name[1]] = event.target.checked;
    setPermission({ ...copyPermission });
  };

  const onChange = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickCreateAdmin = () => {
    setOpenCreateAdmin(true);
  };

  const handleClose = () => {
    setOpenCreateAdmin(false);
  };

  const formValidate = () => {
    const { name, surname, password, email } = form;
    return !name || !surname || !password || !email;
  };

  useEffect(() => {
    if (admin) {
      let copy = {
        name: admin.name,
        surname: admin.surname,
        email: admin.email,
        title: admin.title,
      };
      setPermission({ ...permission, ...admin.permission });
      setState(copy);
    }
  }, [admin]);

  const updateAdminFunc = () => {
    updateAdmin({
      variables: {
        id: admin.id,
        input: {
          name: form.name,
          surname: form.surname,
          title: form.title,
          //   email: form.email,
          permission: permission,
        },
      },
    })
      .then(async ({ data }) => {
        handleClose();
        refetch();
      })
      .catch((error) => {});
  };

  const uploadPhoto = (file) => {
    console.log({ file });
  };

  return (
    <div>
      <Button
        // disabled
        variant="outlined"
        color="secondary"
        onClick={() => {
          handleClickCreateAdmin();
        }}
      >
        <Edit />
      </Button>
      <Dialog
        open={openCreateAdmin}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <Typography align="center">
            Please wait
            <br />
            <CircularProgress color="primary" />
          </Typography>
        </Backdrop>
        <DialogTitle id="alert-dialog-title">UPDATE Admin</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            {/* <UploadFile uploadPhoto={uploadPhoto} /> */}
            {/* <MultipleFile uploadPhoto={uploadPhoto} /> */}
            <TextField
              onChange={onChange}
              variant="outlined"
              margin="normal"
              value={name}
              required
              fullWidth
              name="name"
              label="Name"
              type="text"
              id="name"
            />
            <TextField
              onChange={onChange}
              variant="outlined"
              margin="normal"
              value={surname}
              required
              fullWidth
              name="surname"
              label="Surname"
              type="text"
              id="surname"
            />
            <TextField
              disabled
              onChange={onChange}
              variant="outlined"
              margin="normal"
              value={email}
              required
              fullWidth
              name="email"
              label="Email"
              type="text"
              id="email"
            />
            <TextField
              onChange={onChange}
              variant="outlined"
              margin="normal"
              value={title}
              required
              fullWidth
              name="title"
              label="Title"
              type="text"
              id="title"
            />

            <FormGroup row>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.inventory.admin}
                        color={"secondary"}
                        name="inventory-admin"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Inventory Admin"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.inventory.process}
                        color={"secondary"}
                        name="inventory-process"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Process"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.inventory.display}
                        color={"secondary"}
                        name="inventory-display"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Display PIN"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.author.admin}
                        color={"secondary"}
                        name="author-admin"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Author Admin"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.author.write}
                        color={"secondary"}
                        name="author-write"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Author"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.author.read}
                        color={"secondary"}
                        name="author-read"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Author (Read)"
                  />
                </Grid>
                {/* <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.customer.admin}
                        color={"secondary"}
                        name="customer"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Customer"
                  />
                </Grid> */}
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.accountManager.admin}
                        color={"secondary"}
                        name="accountManager-admin"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Account Manager"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.customer.admin}
                        color={"secondary"}
                        name="customer-admin"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Customer"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.secretshop.admin}
                        color={"secondary"}
                        name="secretshop-admin"
                        onChange={handleChangePermission}
                      />
                    }
                    label="Secretshop"
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={updateAdminFunc}
            color="secondary"
            // disabled={formValidate()}
          >
            Update Admin
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AdminCreateEditComponent;
