import Edit from "@mui/icons-material/Edit";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import React, { useEffect, useState } from "react";
import QuizStatus from "../Quiz/components/QuizStatus";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FeaturedImage } from "./Course";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_COURSE,
  GET_SINGLE_COURSE,
  UPDATE_COURSE,
  GET_COURSES,
  GET_ADDONS,
} from "../../../queries";
import { useSnackbar } from "notistack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const COURSE_TITLE_MIN_LENGTH = 10;
const COURSE_DETAIL_MIN_LENGTH = 10;

export default function CourseDialog({ id, refetch }) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [courseDetail, setCourseDetail] = useState("");
  const [prerequisites, setPrerequisites] = useState([]);
  const [status, setStatus] = useState(true);
  const [courseList, setCourseList] = useState([]);
  const [featuredImg, setFeaturedImg] = useState("");
  const [units, setUnits] = useState([]);
  const [courseDescription, setCourseDescription] = useState("");
  const [addOns, setAddOns] = useState([]);
  const [addonsList, setAddonsList] = useState([]);

  const { loading: loadingAddons, error: errorAddons } = useQuery(GET_ADDONS, {
    onCompleted: (res) => {
      setAddonsList(res.getAddOns);
    },
  });

  const [getSingleCourse, { loading: getSingleCourseLoading }] = useLazyQuery(
    GET_SINGLE_COURSE,
    {
      onCompleted: (response) => {
        const {
          title,
          status,
          featured_img,
          tags,
          categories,
          courseDetail,
          prerequisites,
          units,
          price,
          courseDescription,
          addOns: addOnsL,
        } = response.getSingleCourse;
        setTitle(title);
        setStatus(status);
        setFeaturedImg(featured_img);
        setCourseDetail(courseDetail);
        setPrerequisites(prerequisites);
        setCourseDescription(courseDescription);
        setPrice(price);
        setAddOns(
          addOnsL.map((item) => {
            return {
              ...item.addOn,
              isPriceIncluded: item.isPriceIncluded,
            };
          })
        );
      },
      fetchPolicy: "no-cache",
    }
  );

  const [createCourseMutation, { loading: createCourseLoading }] = useMutation(
    CREATE_COURSE,
    {
      onCompleted: (response) => {
        enqueueSnackbar("Course Created Successfully", {
          variant: "success",
        });
        refetch();
        handleClose();
      },
    }
  );
  const [updateCourseMutation, { loading: updateCourseLoading }] = useMutation(
    UPDATE_COURSE,
    {
      onCompleted: (response) => {
        enqueueSnackbar("Course updated Successfully", {
          variant: "success",
        });
        refetch();
        handleClose();
      },
    }
  );
  const {
    loading: coursesLoading,
    data: coursesData,
    error: coursesError,
  } = useQuery(GET_COURSES);
  useEffect(() => {
    if (coursesData?.getCourses) {
      setCourseList(coursesData.getCourses);
    }
  }, [coursesData]);

  useEffect(() => {
    if (id && open) {
      getSingleCourse({
        variables: {
          input: { id },
        },
      });
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickPrerequisites = (courseId) => {
    const copy = JSON.parse(JSON.stringify(prerequisites));
    const index = copy.indexOf(courseId);
    if (index !== -1) {
      copy.splice(index, 1);
    } else {
      copy.push(courseId);
    }
    setPrerequisites(copy);
  };

  const uploadPhoto = (file) => {
    setFeaturedImg(file.uri);
  };
  const resetFeaturedPhoto = () => {
    setFeaturedImg("");
  };

  const onSubmitCourse = () => {
    if (id) {
      onUpdate();
    } else {
      onCreate();
    }
  };

  const onUpdate = () => {
    const valid = isValid();
    if (valid) {
      const input = createInput();
      updateCourseMutation({
        variables: {
          id: id,
          input: input,
        },
      });
    }
  };
  const onCreate = () => {
    const valid = isValid();
    if (valid) {
      const input = createInput();
      createCourseMutation({
        variables: {
          input: input,
        },
      });
    }
  };

  const createInput = () => {
    const input = {
      title: title,
      status: status,
      featured_img: featuredImg,
      //   tags: tags,
      //   categories: categories,
      courseDetail: courseDetail,
      prerequisites: prerequisites,
      price: parseFloat(price),
      courseDescription: courseDescription,
      addOns: addOns.map((item) => {
        return { addOn: item.id, isPriceIncluded: item.isPriceIncluded };
      }),
    };
    return input;
  };
  const onClickAddOns = (addOnsItem) => {
    const copy = JSON.parse(JSON.stringify(addOns));
    const index = copy.map((item) => item.id).indexOf(addOnsItem.id);

    if (index === -1) {
      copy.push({ ...addOnsItem, isPriceIncluded: false });
    } else {
      copy.splice(index, 1);
    }
    setAddOns(copy);
  };
  const onPriceIncluded = (addOnsItem) => {
    const copy = JSON.parse(JSON.stringify(addOns));
    const index = copy.map((item) => item.id).indexOf(addOnsItem.id);
    copy[index].isPriceIncluded = !copy[index].isPriceIncluded;
    setAddOns(copy);
  };

  const isValid = () => {
    if (title.length < COURSE_TITLE_MIN_LENGTH) {
      enqueueSnackbar(`Course title min length ${COURSE_TITLE_MIN_LENGTH}`, {
        variant: "error",
      });
      return false;
    }
    if (courseDetail.length < COURSE_DETAIL_MIN_LENGTH) {
      enqueueSnackbar(`Course detail min length ${COURSE_DETAIL_MIN_LENGTH}`, {
        variant: "error",
      });
      return false;
    }
    if (featuredImg.length === 0) {
      enqueueSnackbar(`Please upload an image first`, {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  if (getSingleCourseLoading || updateCourseLoading || createCourseLoading) {
    return <LinearProgress />;
  }

  return (
    <div>
      {id ? (
        <Button
          variant="outlined"
          fullWidth
          onClick={handleClickOpen}
          color="primary"
        >
          Edit Details
        </Button>
      ) : (
        <Button
          variant="outlined"
          color={"secondary"}
          onClick={handleClickOpen}
        >
          Add Course
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          {id ? "Update Course" : "Create Course"}
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: 20,
          }}
        >
          <DialogContentText id="alert-dialog-description">
            <TextField
              fullWidth
              label="Title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              style={{
                marginBottom: 20,
              }}
            />
            <TextField
              fullWidth
              label="Course Detail"
              value={courseDetail}
              onChange={(e) => {
                setCourseDetail(e.target.value);
              }}
              style={{
                marginBottom: 20,
              }}
            />
            <TextField
              fullWidth
              label="Course Description"
              value={courseDescription}
              onChange={(e) => {
                setCourseDescription(e.target.value);
              }}
              style={{
                marginBottom: 20,
              }}
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-amount">
                Amount
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={price}
                type={"number"}
                onChange={(e) => {
                  setPrice(parseFloat(e.target.value));
                }}
                style={{
                  marginBottom: 20,
                }}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
              />
            </FormControl>

            <QuizStatus status={status} setStatus={setStatus} />
            <br></br>
            {addonsList.map((item) => {
              return (
                <div>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={addOns
                              .map((item) => item.id)
                              .includes(item.id)}
                            color="secondary"
                            onClick={() => {
                              onClickAddOns(item);
                            }}
                          />
                        }
                        label="Offer"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{`${item.name} - $${item.price}`}</Typography>
                      <Typography variant="subtitle2">{`${item.description}`}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {addOns.map((item) => item.id).includes(item.id) && (
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              checked={
                                addOns.find((element) => element.id === item.id)
                                  .isPriceIncluded
                              }
                              onClick={() => {
                                onPriceIncluded(item);
                              }}
                            />
                          }
                          label="Price Included"
                        />
                      )}
                    </Grid>
                  </Grid>
                  <br></br>
                </div>
              );
            })}

            <FeaturedImage
              featuredImg={featuredImg}
              uploadPhoto={uploadPhoto}
              resetFeaturedPhoto={resetFeaturedPhoto}
            />
            <Accordion sx={{ backgroundColor: "#000 !important" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Please select prerequisite if it's needed!
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {courseList
                  .filter((course) => course.id !== id)
                  .filter((course) => !course.prerequisites.includes(id))
                  .map((course) => {
                    return (
                      <ListItem key={course.id} disablePadding>
                        <ListItemButton
                          role={undefined}
                          onClick={() => {
                            onClickPrerequisites(course.id);
                          }}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={prerequisites.includes(course.id)}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={course.title} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button
            onClick={() => {
              onSubmitCourse();
            }}
          >
            {id ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
