import { Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import DetailComponentHintEditor from "../../../../assets/editorHints/DetailEditor.jpeg";

const ImageColumnComponent = ({ keys, color, title = "" }) => {
  let colorText = "#" + color;
  return (
    <div>
      <Container
        style={{
          // backgroundColor: colorText,
          padding: "40px 70px",
        }}
      >
        <br></br>
        <br></br>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{
            padding: 20,
          }}
        >
          {keys.map((key) => {
            return (
              <Grid
                item
                xs={
                  keys.length === 4
                    ? 3
                    : keys.length === 3
                    ? 4
                    : keys.length === 2
                    ? 6
                    : 12
                }
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      {key.text}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      style={{
                        width: "100%",
                      }}
                      src={key.img}
                      alt="detail icon"
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <br></br>
        <br></br>
      </Container>
    </div>
  );
};

export default ImageColumnComponent;

export const DetailComponentInfoDialog = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <InfoIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent>
          <img src={DetailComponentHintEditor}></img>
          <Typography>
            For each cell click to add detail button. When you click the button
            there will be button to upload icon or image, and detail.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
