import React, { forwardRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useMutation } from "@apollo/client";
import { BAN_MEMBER } from "../../../../../queries/Admin";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  CircularProgress,
  RadioGroup,
  TextField,
} from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";
import GavelIcon from "@mui/icons-material/Gavel";
import { useSnackbar } from "notistack";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BanMember({ customer, refetch }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [reason, setReason] = useState("");

  const [banMember, { loading }] = useMutation(BAN_MEMBER, {
    variables: {
      id: customer.id,
      reason: reason,
    },
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      console.log("res", res);
      refetch();
      setOpen(false);
      setOpen2(false);
      enqueueSnackbar("Member banned successfully!", { variant: "success" });
    },
    onError: (err) => {
      console.log("err", err);
      enqueueSnackbar(err.message, { variant: "error" });
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setReason("");
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Button
        fullWidth
        variant="outlined"
        onClick={handleClickOpen}
        style={{
          color: "#e25065",
          borderColor: "#e25065",
        }}
        startIcon={<GavelIcon />}
      >
        BAN MEMBER
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Reason"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {loading && <LinearProgress />}

            <FormControl>
              <TextField
                id="outlined-multiline-static"
                label="Reason"
                multiline
                rows={4}
                value={reason}
                placeholder="Write a reason here..."
                variant="outlined"
                sx={{ marginTop: "10px" }}
                onChange={(e) => setReason(e.target.value)}
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen2(true)} disabled={loading}>
            BAN MEMBER
          </Button>
          <Button onClick={handleClose} disabled={loading}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen2(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure you want to ban this member?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {loading && <LinearProgress />}

            <FormControl>
              <p>
                Please double check member's stripe data and other memberships.
                If you see any error,please contact via make-a-coder-friend
                channel!
              </p>
              <p>Your reason:{reason}</p>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={banMember} disabled={loading}>
            {loading ? <CircularProgress /> : "Yes"}
          </Button>
          <Button onClick={() => setOpen2(false)} disabled={loading}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
