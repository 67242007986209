import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@apollo/client";
import { CREATE_TOP_REFS } from "../../../../../queries";
import moment from "moment";
import { useSnackbar } from "notistack";

const UpdateTopRefs = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openData, setOpenData] = useState(false);
  const [createTopRefs, { loading }] = useMutation(CREATE_TOP_REFS);
  const [data, setData] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenData(false);
  };

  const handleSubmit = async () => {
    createTopRefs({
      variables: {
        time: moment().unix(),
      },
    })
      .then(async ({ data }) => {
        console.log(data);
        setOpenData(true);
        setData(data);
        setOpen(false);
        enqueueSnackbar(`Top Refs Created`, {
          variant: "success",
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: "error",
        });
      });
  };
  return (
    <>
      <Button onClick={() => handleClickOpen()} variant="outlined">
        Update Top Refs
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update Top Refs"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to update top referrers?
            <br />
            Note: If 28 days didnt pass yet you will receive an error message.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disable={loading} onClick={handleSubmit} autoFocus>
            {loading ? <CircularProgress /> : "Agree"}
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {data && (
        <Dialog
          open={openData}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Top Refs"}</DialogTitle>
          <DialogContent>
            {data.createLastTopRefs &&
              data.createLastTopRefs.list.map((n) => (
                <DialogContentText id="alert-dialog-description">
                  {n.name} - {n.total}
                </DialogContentText>
              ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UpdateTopRefs;
