import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

function W9ErrorDialog({  open, setMessage, onSubmit,  handleClose  }){

  return   <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Reject W9 File"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div style={{ marginTop: 20 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={12}>
                <TextField
                  id="outlined-size-small"
                  label="Message"
                  variant="outlined"
                  size="medium"
                  onChange={({ target: { value } }) =>  setMessage(value) }
                  style={{ "width": "100%", "height": "60px", "paddingBottom": "80px" }}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          className="redborder"
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          autoFocus
          onClick={onSubmit}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
}

export default W9ErrorDialog;
