import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  GET_CERTIFICATION_VERIFY,
  GET_CUSTOMERS_WORKSHOPS,
  GET_WORKSHOPS,
} from "../../../queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Chip,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const buttons = {
  confirmWorkshop: "confirmWorkshop",
  verify: "verify",
  noWorkshop: "noWorkshop",
};
export default function CheckWorkshopDone({
  refetch = () => {},
  completedCourse,
  course,
  customer,
  loadingUp,
  setLoadingUp,
}) {
  const [open, setOpen] = useState(false);
  const [buttonType, setButtonType] = useState(buttons.confirmWorkshop);
  const [loadingCreation, setLoadingCreation] = useState(false);
  const [workshops, setWorkshops] = useState([]);
  const [buttonText, setButtonText] = useState("");
  const [pendingWorkshop, setPendingWorkshop] = useState({});
  const [attendanceList, setAttendanceList] = useState([]);
  const [verifyCertification, { loading: loadingVerify }] = useLazyQuery(
    GET_CERTIFICATION_VERIFY
  );
  const { loading: loadingWorkshop, error: errorWorkshop } = useQuery(
    GET_CUSTOMERS_WORKSHOPS,
    {
      variables: {
        customerId: customer.id,
      },
      onCompleted: (res) => {
        setWorkshops(res.getCustomerWorkshopSchedules);
      },
      fetchPolicy: "no-cache",
    }
  );
  const { enqueueSnackbar } = useSnackbar();
  const handleClickOpen = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    setLoadingUp(loadingVerify);
  }, [loadingVerify]);
  React.useEffect(() => {
    setLoadingUp(loadingWorkshop);
  }, [loadingWorkshop]);

  const handleClose = () => {
    if (loadingCreation) {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    const needWorkshop = course.requiresWorkshop;

    if (needWorkshop) {
      const waiting = workshops.filter(
        (item) => item.info !== "cancelled" && item.info !== "rescheduled"
      );

      if (waiting.length > 0) {
        setButtonType(buttons.confirmWorkshop);
        const value = waiting[waiting.length - 1];
        setPendingWorkshop(value);
        const date = new Date(value.startTime * 1000);
        const timeString = `${date.toLocaleDateString("en-US", {
          timeZone: "America/Los_Angeles",
        })}
       `;
        setButtonText(`Workshop scheduled for ${timeString}`);
      } else {
        setButtonType(buttons.noWorkshop);
      }
    } else {
      setButtonType(buttons.verify);
    }
  }, [course, completedCourse, workshops]);

  const onCreateCertificate = (attendaceCheck) => {
    const attended = workshops.find((item) => item.attendance);
    if (attendaceCheck && !attended && attendanceList.length === 0) {
      enqueueSnackbar(
        `the user has no workshop that has attendance before therefore you need to select the workshop from the list that you want to mark as attended `,
        {
          variant: "info",
        }
      );
      return;
    }
    const code = completedCourse.completedCourse.completeCode.replace(
      /\D/g,
      ""
    );
    setLoadingCreation(true);
    verifyCertification({
      variables: {
        code: code,
        workshopScheduleIds: attendanceList,
        customerId: customer.id,
      },
    })
      .then((res) => {
        enqueueSnackbar(`Certificate Created Successfully`, {
          variant: "success",
        });
        handleClose();
        refetch();
      })
      .catch((e) => {
        handleClose();
        enqueueSnackbar(`${e.message}`, {
          variant: "error",
        });
      });
  };
  const onClectAttendace = (singleWorkshop) => {
    const copy = JSON.parse(JSON.stringify(attendanceList));
    const index = copy.indexOf(singleWorkshop.id);
    if (index >= 0) {
      copy.splice(index, 1);
    } else {
      copy.push(singleWorkshop.id);
    }
    setAttendanceList(copy);
  };

  return (
    <div>
      {buttonType === buttons.confirmWorkshop && (
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          disabled={loadingCreation}
        >
          {buttonText}
        </Button>
      )}
      {buttonType === buttons.verify && (
        <Button
          onClick={() => {
            onCreateCertificate();
          }}
          disabled={loadingCreation}
        >
          Create Certificate
        </Button>
      )}
      {buttonType === buttons.noWorkshop && (
        <Typography>User hasn't scheduled a workshop</Typography>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {loadingCreation && <LinearProgress />}
        <DialogTitle id="alert-dialog-title">{`User took the workshop`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select workshop(s) that you want to mark as attended and
            click the "Confirm workshop and verify the certificate"
          </DialogContentText>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {workshops.length === 0 && (
              <Typography> user has not participated before </Typography>
            )}
            {workshops.map((value) => {
              const workshop = workshops.find(
                (item) => item.id === value.addOnId
              );
              const labelId = `checkbox-list-label-${value}`;
              const date = new Date(value.startTime * 1000);
              const passedDate = new Date() < date;
              const timeString = `${date.toLocaleDateString("en-US", {
                timeZone: "America/Los_Angeles",
              })}
            ${date.toLocaleTimeString("en-US", {
              timeZone: "America/Los_Angeles",
              hourCycle: "h24",
              timeStyle: "short",
            })}
            PT /
            ${date.toLocaleTimeString("en-US", {
              timeZone: "America/New_York",
              hourCycle: "h24",
              timeStyle: "short",
            })}
            ET`;
              const disabled =
                value.info === "rescheduled" || value.info === "cancelled";

              return (
                <ListItem
                  style={{ width: "100%" }}
                  key={value}
                  secondaryAction={
                    value.info &&
                    value.info !== "" && <Chip label={value.info} />
                  }
                  disablePadding
                >
                  <ListItemIcon>
                    <IconButton
                      disabled={
                        disabled ||
                        loadingVerify ||
                        loadingCreation ||
                        value.attendance ||
                        passedDate
                      }
                      onClick={() => {
                        onClectAttendace(value);
                      }}
                    >
                      {/* <EditIcon /> */}
                      {attendanceList.includes(value.id) || value.attendance ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    secondary={timeString}
                    primary={workshop?.name}
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button disabled={loadingCreation} onClick={handleClose}>
            cancel
          </Button>
          <Button
            disabled={loadingCreation}
            onClick={() => {
              onCreateCertificate(true);
            }}
            autoFocus
          >
            Confirm workshop and verify the certificate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
