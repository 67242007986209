import React, { Component, useEffect, useState, useRef } from "react";

import { Link } from "react-router-dom";

import ManageSuppliers from "./Suppliers";
import ManageProduct from "./Products";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export class ManageInventory extends Component {

  render() {
    const admin_id = this.props.session.activeAdmin.id;
    return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Inventory Settings</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <ManageProduct admin={admin_id}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ManageSuppliers admin={admin_id}/>
        </Grid>
      </Grid>
    </div>
  );
}
}


export default ManageInventory;
