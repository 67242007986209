import * as React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useMutation } from "@apollo/client";
import {
  CREATE_AFFILIATE_WITH_TITLE,
  MAKE_AFFILIATE,
} from "../../../../../queries";
import moment from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import { useSnackbar } from "notistack";

import SearchTitle from "../TitleSearch";
import CustomerFind from "../CustomerSearch";
import { now } from "lodash";

const useStyles = makeStyles({
  affiliate_button: {
    display: "inline-block",
    margin: "0px 20px 0px 20px",
    float: "left",
    width: "auto",
  },
  full_size: {
    width: "100%",
  },
});

const AffiliateDialog = (props) =>{
  const [buttons, setButtons] = useState({
    select: false,
    create: false,
    member: false,
  });

  useEffect(() => {
    {
      const obj = {
        select: props.select ? props.select : false,
        create: props.create ? props.create : false,
        member: props.member ? props.member : false,
      };

      setButtons(obj);
    }
  }, []);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefetch = () => {
    props.refetch();
  };

  return (
    <div className={classes.affiliate_button}>
      <Button
        variant="outlined"
        color={buttons.member && "secondary"}
        onClick={handleClickOpen}
        size="large"
      >
        {buttons.member && "Select Member"}
        {buttons.select && "Select Page"}
        {buttons.create && "Create Direct Link"}
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {buttons.member && "Select Member To Make Affiliate"}
          {buttons.select && "Select a Page To Make Affiliate"}
          {buttons.create && "Create Affiliate Page"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {buttons.member && (
              <Member handleClose={handleClose} handleRefetch={handleRefetch} />
            )}
            {buttons.select && (
              <Select handleClose={handleClose} handleRefetch={handleRefetch} />
            )}
            {buttons.create && (
              <Create handleClose={handleClose} handleRefetch={handleRefetch} />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            className="redborder"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Member = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = React.useState("");
  const [makeAff, { loading, data, error }] = useMutation(MAKE_AFFILIATE);

  const handleSearchResult = (obj) => {
    setUser(obj);
  };

  const onCreateAff = () => {
    makeAff({
      variables: {
        id: user.id,
      },
    })
      .then(() => {
        enqueueSnackbar(`Member successfully updated!`, {
          variant: "success",
        });
        props.handleRefetch();
        props.handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(`${error}`, {
          variant: "error",
        });
      });
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={12}>
          <CustomerFind handleSearchResult={handleSearchResult} />
        </Grid>
        <Grid item md={12}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              onCreateAff();
            }}
            fullWidth
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const Select = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [text, setText] = React.useState("");
  const [createAff, { loading, data, error }] = useMutation(
    CREATE_AFFILIATE_WITH_TITLE
  );

  const handleSearchResult = (username) => {
    console.log("username => ", username);
    setText(username);
  };
  const onCreateAff = () => {
    createAff({
      variables: {
        input: {
          username: text,
          created_at: moment().unix(),
          affiliateType: 2,
          affiliatePoint: 0,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(`Affiliate Page created`, {
          variant: "success",
        });
        props.handleRefetch();
        props.handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(`${error}`, {
          variant: "error",
        });
      });
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={12}>
          <SearchTitle
            website="real.dog"
            handleSearchResult={handleSearchResult}
          />
        </Grid>
        <Grid item md={12}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              onCreateAff();
            }}
            fullWidth
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
const Create = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [text, setText] = React.useState("");
  const [createAff, { loading, data, error }] = useMutation(
    CREATE_AFFILIATE_WITH_TITLE
  );

  const onChangeInput = (event) => {
    let username = event.target.value.replace(" ", "-");
    username = username.toLowerCase();
    setText(username);
  };

  const onCreateAff = () => {
    createAff({
      variables: {
        input: {
          username: text,
          created_at: moment().unix(),
          affiliateType: 2,
          affiliatePoint: 0,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(`Affiliate Page created`, {
          variant: "success",
        });
        props.handleRefetch();
        props.handleClose();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(`${error}`, {
          variant: "error",
        });
      });
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={12}>
          <TextField
            id="outlined-size-small"
            label="Link Name"
            variant="outlined"
            size="small"
            className={classes.full_size}
            onChange={(e) => onChangeInput(e)}
            value={text}
          />
        </Grid>
        <br /> <br />
        <Grid item md={12}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              onCreateAff();
            }}
            fullWidth
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};


export default AffiliateDialog;