import React, { Component, useEffect, useState, useRef } from "react";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import { GET_PROCESS } from "../../../queries";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import moment from "moment-timezone";
import Button from "@mui/material/Button";

export function ProcessDetailModal(props) {
  //console.log(props);

  const { data, loading, error } = useQuery(GET_PROCESS, {
    fetchPolicy: "no-cache",
    variables: { id: props.id },
  });

  return (
    <div>
      {loading ? (
        <div>
          <div>
            <LinearProgress color="secondary" />
          </div>
        </div>
      ) : (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <DialogTitle id="form-dialog-title">
                Received Material
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Batch Number: <b>{data.inv_process.batch_number}</b>
                </DialogContentText>
                <DialogContentText>
                  Record Date:{" "}
                  <b>
                    {moment
                      .unix(data.inv_process.rec_date)
                      .format("DD/MM/YYYY HH:mm:ss")}
                  </b>
                </DialogContentText>
                <DialogContentText>
                  Created Date:{" "}
                  <b>
                    {moment
                      .unix(data.inv_process.createdAt)
                      .format("DD/MM/YYYY HH:mm:ss")}
                  </b>
                </DialogContentText>
                <DialogContentText>
                  Split: <b>{data.inv_process.split ? "Splited" : "No"}</b>
                </DialogContentText>
                <DialogContentText>
                  Qty Received: <b>{data.inv_process.quantity}</b>
                </DialogContentText>
                <DialogContentText>
                  Admin Name: <b>{data.inv_process.admin.name}</b>
                </DialogContentText>
                <DialogContentText>
                  Supplier: <b>{data.inv_process.stock_un.supplier.company}</b>
                </DialogContentText>
                <DialogContentText>
                  Brand: <b>{data.inv_process.stock_un.brand}</b>
                </DialogContentText>
                <DialogContentText>
                  Price: <b>{data.inv_process.stock_un.price}</b>
                </DialogContentText>
                <DialogContentText>
                  Order Number: <b>{data.inv_process.stock_un.order_number}</b>
                </DialogContentText>
                <DialogContentText>
                  Comments: <b>{data.inv_process.comments}</b>
                </DialogContentText>
              </DialogContent>
            </Grid>
            {data.inv_process.status > 1 ? (
              <Grid item xs={12} sm={3}>
                <DialogTitle id="form-dialog-title">Processing</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Process Date:{" "}
                    <b>
                      {moment
                        .unix(data.inv_process.process_dp)
                        .format("DD/MM/YYYY HH:mm:ss")}
                    </b>
                  </DialogContentText>
                  <DialogContentText>
                    Process Start Name: <b>{data.inv_process.who_ts.name}</b>
                  </DialogContentText>
                  {data.inv_process.status > 2 ? (
                    <div>
                      <DialogContentText>
                        Quantity Scrapped:{" "}
                        <b>{data.inv_process.quantity_scrapped}</b>
                      </DialogContentText>
                      <DialogContentText>
                        Total Processed:{" "}
                        <b>{data.inv_process.total_processed}</b>
                      </DialogContentText>
                      <DialogContentText>
                        Units Made: <b>{data.inv_process.units_made}</b>
                      </DialogContentText>
                      <DialogContentText>
                        Pieces Grate: <b>{data.inv_process.pieces_grate}</b>
                      </DialogContentText>
                      <DialogContentText>
                        Grates Used: <b>{data.inv_process.grates_used}</b>
                      </DialogContentText>
                      <DialogContentText>
                        Carts Used: <b>{data.inv_process.carts_used}</b>
                      </DialogContentText>
                      <DialogContentText></DialogContentText>
                      <DialogContentText>
                        Yield: <b>{data.inv_process.process_yield}</b>
                      </DialogContentText>
                      <DialogContentText>
                        Comment: <b>{data.inv_process.comments_process}</b>
                      </DialogContentText>
                      <DialogContentText>
                        Process Start End: <b>{data.inv_process.who_te.name}</b>
                      </DialogContentText>
                    </div>
                  ) : (
                    ""
                  )}
                </DialogContent>
              </Grid>
            ) : (
              ""
            )}
            {data.inv_process.status > 3 ? (
              <Grid item xs={12} sm={3}>
                <DialogTitle id="form-dialog-title">Dryer</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Dryer Ds Date:{" "}
                    <b>
                      {moment
                        .unix(data.inv_process.dryer_ds)
                        .format("DD/MM/YYYY HH:mm:ss")}
                    </b>
                  </DialogContentText>
                  <DialogContentText>
                    Dryer Hum: <b>{data.inv_process.dryer_hum}</b>
                  </DialogContentText>
                  <DialogContentText>
                    Dryer Temp: <b>{data.inv_process.dryer_temp}</b>
                  </DialogContentText>
                  <DialogContentText>
                    Dryer Room: <b>{data.inv_process.dryer_room}</b>
                  </DialogContentText>
                  <DialogContentText>
                    Entered By: <b>{data.inv_process.entered_by.name}</b>
                  </DialogContentText>
                  {data.inv_process.status > 4 ? (
                    <div>
                      <DialogContentText>
                        Dryer Df Date:{" "}
                        <b>
                          {moment
                            .unix(data.inv_process.dryer_df)
                            .format("DD/MM/YYYY HH:mm:ss")}
                        </b>
                      </DialogContentText>
                      <DialogContentText>
                        Dryer End By:{" "}
                        <b>{data.inv_process.dryer_end_by.name}</b>
                      </DialogContentText>
                      <DialogContentText>
                        Dryer Hours: <b>{data.inv_process.dryer_hours}</b>
                      </DialogContentText>
                      <DialogContentText>
                        Comment: <b>{data.inv_process.dryer_end_comment}</b>
                      </DialogContentText>
                    </div>
                  ) : (
                    ""
                  )}
                </DialogContent>
              </Grid>
            ) : (
              ""
            )}
            {data.inv_process.status > 5 ? (
              <Grid item xs={12} sm={3}>
                <DialogTitle id="form-dialog-title">Bins</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Emptied Date:{" "}
                    <b>
                      {moment
                        .unix(data.inv_process.bins_date)
                        .format("DD/MM/YYYY HH:mm:ss")}
                    </b>
                  </DialogContentText>
                  <DialogContentText>
                    Emptied Who:{" "}
                    <b>{data.inv_process.bins_empitied_who.name}</b>
                  </DialogContentText>

                  <DialogContentText>
                    Total Weight: <b>{data.inv_process.bins_total}</b>
                  </DialogContentText>
                  <DialogContentText>
                    Expected Bags: <b>{data.inv_process.expected_bags}</b>
                  </DialogContentText>
                  <DialogContentText>
                    {JSON.parse(data.inv_process.bins).map((item, i) => {
                      return (
                        <div key={i}>
                          Bin: {item.bin} - Weight: {item.weight}
                        </div>
                      );
                    })}
                  </DialogContentText>
                </DialogContent>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <DialogActions>
            <Button onClick={props.close} color="primary">
              Close
            </Button>
          </DialogActions>
        </div>
      )}
    </div>
  );
}
