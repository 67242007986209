import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  UPDATE_APPOINTMENT,
  GET_SUPPORT_MEMBER_SCHEDULE,
  GET_SUPPORT_MEMBER_APPOINTMENTS,
  GET_AVAILABLEDAYS_SUPPORTMEMBER,
  ADD_NOTE_APPOINTMENT,
  GET_APPOINTMENT_STATS,
  UPDATE_SCHEDULE,
  MAKE_APPOINTMENT,
} from "../../../queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import EditDialog from "./Components/Stepper/editDialog";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import { Typography } from "@mui/material";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSnackbar } from "notistack";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import CustomerFind from "./Components/CustomerFind";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

var oneDay = 60 * 60 * 24 * 1000;

const useStyles = makeStyles((theme) => ({
  parent: {
    position: "relative",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000025",
    position: "absolute",
  },
  container: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "red",
  },
  containerDeclined: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "red",
  },
  containerPending: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "orange",
  },
  containerApproved: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "green",
  },
  containerReserved: {
    padding: 20,
    borderLeft: "5px solid",
    borderLeftWidth: 5,
    borderLeftColor: "green",
  },
  weekSummary: {
    padding: 20,
    width: "100%",
    marginBottom: 10,
  },
  pending: {
    border: "1px solid yellow",
  },
  reserved: {
    border: "1px solid green",
  },
  reserved: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
  pending: {
    color: "#ffa726",
    borderColor: "#ffa726",
  },
  available: {
    color: "#24a3bb",
    borderColor: "#24a3bb",
  },
  empty: {},
}));

const SCHEDULE = "SCHEDULE";
const APPOINTMENTS = "APPOINTMENTS";
const statusArr = {
  canceled: "containerDeclined",
  pending: "containerPending",
  reserved: "containerReserved",
};
const AssignedConsultation = (props) => {
  const { id } = useParams();

  const classes = useStyles();

  // available;
  // reserved;
  // pending;
  // canceled;

  const [showFormat, setShowFormat] = useState(SCHEDULE);
  const [schedules, setSchedules] = useState([]);
  const [selectedDate, handleDateChange] = useState({});
  const [availableDates, setAvailableDates] = useState([]);

  // eslint-disable-next-line
  const { loading: availableDatesLoading, data: availableDatesdata } = useQuery(
    GET_AVAILABLEDAYS_SUPPORTMEMBER,
    {
      variables: {
        supportMemberId: id,
      },
      fetchPolicy: "no-cache",
    }
  );

  const [
    fetchSumpportMemberSchedule,
    {
      loading: scheduleLoading,
      data: scheduleData,
      refetch: refetchSupportMemberSchedule,
    },
  ] = useLazyQuery(GET_SUPPORT_MEMBER_SCHEDULE, {
    variables: {
      supportMemberId: id,
      date: selectedDate.day,
    },
    fetchPolicy: "no-cache",
  });

  const [
    fetchSupportMemberAppointments,
    { loading, data: appointmentsData, refetch },
  ] = useLazyQuery(GET_SUPPORT_MEMBER_APPOINTMENTS, {
    variables: {
      supportMemberId: id,
      date: selectedDate.day,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (Object.keys(selectedDate)) {
      fetchSupportMemberAppointments()
        .then((res) => {
          // console.log(res.data);
        })
        .catch((e) => {});
      fetchSumpportMemberSchedule()
        .then((res) => {
          if (res.data.getSupportMemberSchedules.length > 0)
            setSchedules(res.data.getSupportMemberSchedules[0].schedules);
        })
        .catch((e) => {});
    }
  }, [
    fetchSumpportMemberSchedule,
    fetchSupportMemberAppointments,
    selectedDate,
  ]);

  useEffect(() => {
    if (availableDatesdata) {
      setAvailableDates(
        availableDatesdata.getCalendarAppointmentsOfSupporMember
      );
      if (availableDatesdata.getCalendarAppointmentsOfSupporMember.length > 0) {
        let dates = JSON.parse(
          JSON.stringify(
            availableDatesdata.getCalendarAppointmentsOfSupporMember
          )
        ).map((item) => item);
        // .sort((a, b) => new Date(a.day) - new Date(b.day));
        handleDateChange(
          dates.filter((item) => new Date(item.day) >= new Date())[0] || {}
        );
      }
    }
  }, [availableDatesdata]);

  useEffect(() => {
    if (scheduleData && scheduleData.getSupportMemberSchedules.length > 0) {
      setSchedules(scheduleData.getSupportMemberSchedules[0].schedules);
    }
  }, [scheduleData]);
  function disableWeekends(date) {
    const available = availableDates.find(
      (item) => moment(item.day).format("L") === moment(date).format("L")
    );
    return !available;
  }
  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={loading || availableDatesLoading || scheduleLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          // style={{ border: "1px solid red", width: "100%" }}
        >
          <Grid item lg={6} md={6} sm={6}>
            <DatePickerComponent
              disableWeekends={disableWeekends}
              availableDates={availableDates}
              selectedDate={selectedDate}
              setSelectedDate={handleDateChange}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6}>
            <WeeklyReport id={id} />
          </Grid>
          <Grid item lg={8} md={12}>
            <FilterButtons
              showFormat={showFormat}
              setShowFormat={(data) => setShowFormat(data)}
              refetch={() => {
                refetch();
                refetchSupportMemberSchedule();
              }}
            />
          </Grid>
        </Grid>
        {showFormat === SCHEDULE && scheduleData && (
          <ListOffAppointments
            list={schedules}
            refetch={() => {
              refetch();
              refetchSupportMemberSchedule();
            }}
          />
        )}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item lg={8} md={12}>
            {showFormat === APPOINTMENTS && appointmentsData && (
              <AppointmentList
                list={appointmentsData.getSupportMemberAppointments}
                refetch={() => {
                  refetch();
                }}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AssignedConsultation;

const FilterButtons = ({ showFormat, setShowFormat, refetch }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: 10 }}
    >
      <Grid item md={2}>
        <Button
          fullWidth
          color={"secondary"}
          variant={showFormat === SCHEDULE ? "contained" : "outlined"}
          onClick={() => {
            setShowFormat(SCHEDULE);
            refetch();
          }}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          BOOK
        </Button>
      </Grid>
      <Grid item md={2}>
        <Button
          fullWidth
          color={"secondary"}
          variant={showFormat === APPOINTMENTS ? "contained" : "outlined"}
          onClick={() => {
            setShowFormat(APPOINTMENTS);
            refetch();
          }}
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          CONSULT
        </Button>
      </Grid>
    </Grid>
  );
};

export const DatePickerComponent = ({
  availableDates,
  disableWeekends,
  selectedDate,
  setSelectedDate,
  previousOff = false,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(new Date());

  const handleToggle = (value) => {
    setSelectedDate(value);
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {previousOff === false && (
        <Grid item xs={12}>
          <PreviousDates
            list={availableDates.filter(
              (item) =>
                new Date(item.day).getTime() < new Date().getTime() - oneDay
            )}
            availableDates={availableDates}
            selectedDate={selectedDate}
            handleToggle={handleToggle}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <List sx={{ width: "100%", bgcolor: "background.paper", marginTop: 1 }}>
          {availableDates
            .filter(
              (item) =>
                new Date(item.day).getTime() >= new Date().getTime() - oneDay
            )
            .splice(0, 8)
            .map((value) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem key={value} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={() => handleToggle(value)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedDate.day === value.day}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${value.day.substring(
                        5,
                        7
                      )}/${value.day.substring(8)}/${value.day.substring(
                        0,
                        4
                      )}`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Grid>
    </Grid>
  );
};

const WeeklyReport = ({ id }) => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_APPOINTMENT_STATS, {
    variables: { supportMemberId: id },
  });
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
      // style={{ border: "1px solid red", height: "100%" }}
    >
      {!loading && data.getAppointmentStats && (
        <Paper elevation={0} className={classes.weekSummary}>
          <Typography>
            The number of member:{data.getAppointmentStats.members}
          </Typography>
          <Typography>This week</Typography>
          <Typography>
            Available: {data.getAppointmentStats.weekly.available}
          </Typography>
          <Typography>
            Canceled: {data.getAppointmentStats.weekly.canceled}
          </Typography>
          <Typography>
            Completed: {data.getAppointmentStats.weekly.completed}
          </Typography>
          <Typography>
            Pending: {data.getAppointmentStats.weekly.pending}
          </Typography>
          <Typography>
            Reserved: {data.getAppointmentStats.weekly.reserved}
          </Typography>
        </Paper>
      )}

      {/* <Paper elevation={0} className={classes.weekSummary}>
        <Typography>Your Next Appointmet at : 12/12/2021</Typography>
        <Typography>You need to add note last meeting</Typography>
      </Paper> */}
    </Grid>
  );
};

const AppointmentList = ({ list, refetch }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            centered
            indicatorColor="secondary"
          >
            <Tab label="Completed" value="1" />
            <Tab label="Upcoming" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          {list.filter((item) => item.dateTime * 1000 < Date.now()).length ===
            0 && <Typography>No Item Found</Typography>}
          {list
            .filter((item) => item.dateTime * 1000 < Date.now())
            .map((item) => {
              return (
                <AppointmentItem
                  item={item}
                  refetch={() => {
                    refetch();
                  }}
                />
              );
            })}
        </TabPanel>
        <TabPanel value="2">
          {list.filter((item) => item.dateTime * 1000 > Date.now()).length ===
            0 && <Typography>No Item Found</Typography>}
          {list
            .filter((item) => item.dateTime * 1000 > Date.now())
            .map((item) => {
              return (
                <AppointmentItem
                  item={item}
                  refetch={() => {
                    refetch();
                  }}
                  editVisible={true}
                />
              );
            })}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

const ListOffAppointments = ({ list, refetch }) => {
  const classes = useStyles();
  if (list.length === 0) {
    return <Typography variant="h5">No consults for today</Typography>;
  }
  return (
    <Grid item lg={8} md={12}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
        style={{ marginTop: 10 }}
      >
        {list.map((item, index) => {
          return (
            <Grid item md={3}>
              <UpdateAppointmentDialog
                item={item}
                refetch={() => {
                  refetch();
                }}
              />
              {/* <Button
                disabled={!item.active || !item.status === "available"}
                className={
                  item.status === "pending"
                    ? classes.pending
                    : item.status === "reserved"
                    ? classes.reserved
                    : ""
                }
                fullWidth
                variant={"outlined"}
                color={item.status === "available" && "secondary"}
                onClick={() => {
                  // onReqAppointment(item.id);
                }}
              >
                {moment(new Date(item.dateTime * 1000)).format("LT")}
                {" - "}
                {moment(new Date(item.dateTime * 1000))
                  .add(20, "minutes")
                  .format("LT")}
              </Button> */}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const AppointmentItem = ({ item, editVisible = false, refetch }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [saveNoteMutation] = useMutation(ADD_NOTE_APPOINTMENT);

  const onSaveNotes = (text) => {
    saveNoteMutation({
      variables: {
        customerId: item.customer.id,
        appointmentId: item.id,
        note: text,
      },
    })
      .then((res) => {
        enqueueSnackbar("Note Added Successfully", { variant: "Success" });
      })
      .catch((err) => {
        enqueueSnackbar("Note could not", { variant: "error" });
      });
  };

  return (
    <Grid item xs={12} style={{ marginTop: 10 }}>
      <Paper className={classes[statusArr[item.status]]}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Button
                  component={Link}
                  to={`/dashboard/customer/${item.customer?.id}`}
                  target="_blank"
                  variant="outlined"
                  startIcon={<AccountCircleIcon />}
                >{`${
                  item.customer?.first_name ? item.customer?.first_name : "Name"
                } ${
                  item.customer?.last_name
                    ? item.customer?.last_name
                    : "Surname"
                }`}</Button>
              </Grid>
              <Grid>
                <div style={{ padding: "10px 5px" }}>
                  {`${item.period.substring(5, 7)}/${item.period.substring(
                    8
                  )}/${item.period.substring(0, 4)}`}
                  <br />
                  {item.dateString}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>{`${item.support.name}`}</Grid>

              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {/* {editVisible && item.status !== "canceled" && ( */}
                  <UpdateStatusOfAppointment
                    refetch={() => {
                      refetch();
                    }}
                    selectedItem={item}
                  />
                  {/* )} */}
                  {item.status === "reserved" && (
                    <EditDialog
                      rawText={item.comment}
                      onSave={(text) => {
                        onSaveNotes(text);
                      }}
                      userId={item.customer.id}
                    ></EditDialog>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
const UpdateStatusOfAppointment = ({ refetch, selectedItem }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAppointment, { loading }] = useMutation(UPDATE_APPOINTMENT);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const onCancelAppointment = async () => {
    const res = await updateAppointment({
      variables: {
        appointmentId: selectedItem.id,
        input: {
          status: "canceled",
        },
      },
    })
      .then(() => {
        enqueueSnackbar("Updated successfully", { variant: "Success" });
        handleCloseDialog();
        refetch();
      })
      .catch((error) => {
        console.log("error", error);
        enqueueSnackbar("Could not updated successfully", { variant: "error" });
      });
  };

  const onApproveAppointment = async () => {
    const res = await updateAppointment({
      variables: {
        appointmentId: selectedItem.id,
        input: {
          status: "reserved",
        },
      },
    })
      .then(() => {
        enqueueSnackbar("Updated successfully", { variant: "Success" });
        handleCloseDialog();
        refetch();
      })
      .catch((error) => {
        console.log("error", error);
        enqueueSnackbar("Could not updated successfully", { variant: "error" });
      });
  };

  return (
    <div>
      <IconButton
        onClick={() => {
          handleOpenDialog();
        }}
        aria-label="delete"
      >
        <EditIcon />
      </IconButton>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <DialogTitle id="alert-dialog-title">
          {"Do you want to update status of appointment"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Update appointment status
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            close
          </Button>
          <Button onClick={onCancelAppointment} color="primary">
            Decline
          </Button>
          <Button onClick={onApproveAppointment} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const UpdateAppointmentDialog = ({
  item,
  refetch,
  onSelect,
  onlySelect = false,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");
  const [customer, setCustomer] = useState({});
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [updateSchedule, { loading }] = useMutation(UPDATE_SCHEDULE);
  const [assingUserToAppointment, { loading: assingAppointment }] =
    useMutation(MAKE_APPOINTMENT);

  const onUpateSchedule = (value) => {
    updateSchedule({
      variables: {
        id: item.id,
        input: {
          active: value,
          status: "available",
        },
      },
    })
      .then((res) => {
        enqueueSnackbar("Updated successfully", { variant: "Success" });
        handleClose();
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar("Could not updated", { variant: "error" });
      });
  };

  const onAssingUserToAppointment = () => {
    assingUserToAppointment({
      variables: {
        customerId: customer.id,
        scheduleId: item.id,
        reserve: true,
      },
    })
      .then((res) => {
        enqueueSnackbar("Assinged Successfully", { variant: "Success" });
        handleClose();
        refetch();
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Could not Assinged", { variant: "error" });
      });
  };

  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => {
          if (onlySelect === true) {
            if (item.status === "available" && item.active) {
              onSelect(item);
            }
            return;
          }
          handleClickOpen();
        }}
        className={
          item.status === "available" && !item.active
            ? classes.empty
            : classes[item.status]
          // item.status === "available" ? classes.selected : classes.pending
        }
      >
        {item.dateString}
        {/* {`${moment(new Date(item.dateTime * 1000)).format("LT")}
                 - 
                ${moment(new Date(item.dateTime * 1000))
                  .add(20, "minutes")
                  .format("LT")}`} */}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <Backdrop
          className={classes.backdrop}
          open={loading || assingAppointment}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <DialogTitle id="alert-dialog-title">
          {item.dateString}
          {/* {`${moment(new Date(item.dateTime * 1000)).format("LT")}
                 - 
                ${moment(new Date(item.dateTime * 1000))
                  .add(20, "minutes")
                  .format("LT")}`} */}
        </DialogTitle>

        <TabContext value={value}>
          {item.active && (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                centered
              >
                <Tab label="Update Active" value="1" />
                <Tab label="Assign a User" value="2" />
              </TabList>
            </Box>
          )}
          <TabPanel value="1">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {item.status !== "available"
                  ? "Yo need to drop the appointment first then you can update the status of the appointment"
                  : "Are you sure you want to update?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {item.status === "available" ? (
                <>
                  <Button onClick={handleClose}>close</Button>
                  <Button
                    onClick={() => {
                      onUpateSchedule(!item.active);
                    }}
                    autoFocus
                  >
                    {item.active ? "Set Inactive" : "Set Active"}
                  </Button>
                </>
              ) : (
                <Button onClick={handleClose}>close</Button>
              )}
            </DialogActions>
          </TabPanel>
          <TabPanel value="2">
            <CustomerFind
              userSelection={true}
              setCustomer={(data) => {
                setCustomer(data);
              }}
            />
            <DialogActions>
              <Button
                disabled={!customer || Object.keys(customer).length === 0}
                onClick={() => {
                  onAssingUserToAppointment();
                }}
              >
                Assign
              </Button>
            </DialogActions>
          </TabPanel>
        </TabContext>
      </Dialog>
    </div>
  );
};

const PreviousDates = ({ list, selectedDate, handleToggle }) => {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Previous Dates</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {list.map((value) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem key={value} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={() => handleToggle(value)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedDate.day === value.day}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${value.day.substring(
                        5,
                        7
                      )}/${value.day.substring(8)}/${value.day.substring(
                        0,
                        4
                      )}`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
