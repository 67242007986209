import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { OPEN_ISSUE } from "../../../../../queries";
import { useMutation } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { red } from "@mui/material/colors";
import { orange } from "@mui/material/colors";
import { yellow } from "@mui/material/colors";
import { lightGreen } from "@mui/material/colors";

import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

const SendFeedback = ({ customerId, customerEmail, me }) => {
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  // const [priority, setPriority] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const [setIssue, { loading }] = useMutation(OPEN_ISSUE);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateField = (e) => {
    setNote(e.target.value);
  };

  // const handleChange = (event) => {
  //   setPriority(event.target.value);
  // };

  const saveIssue = () => {
    setIssue({
      variables: {
        input: {
          note: note,
          priority: selectedValue,
          customerEmail: customerEmail,
          screenShotLink: "",
          customerLink:
            "https://manage.beastvillage.org/dashboard/customer/" + customerId,
          date: moment().format("YYYY:MM:DD HH:MM"),
        },
      },
    })
      .then((data) => {
        handleClose();
        setNote("");
        setSelectedValue("low");
        enqueueSnackbar("Success", { variant: "success" });
      })
      .catch(() =>
        enqueueSnackbar("ERROR: Please contact to member service.", { variant: "error" })
      );
  };

  const [selectedValue, setSelectedValue] = useState("low");

  const handleChangeCheck = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChangeCheck,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  return (
    <div>
      <Button
        style={{ border: "none", zIndex: 2 }}
        fullWidth
        variant="outlined"
        startIcon={<HelpOutlineIcon />}
        color="secondary"
        className="yellowborder"
        onClick={handleClickOpen}
      >
        Open Issue
      </Button>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <DialogTitle id="alert-dialog-title">
            {"Create New Issue"}
          </DialogTitle>
          <DialogContent>
            <div>
              <FormControlLabel
                value="urgent"
                control={
                  <Radio
                    {...controlProps("urgent")}
                    sx={{
                      color: red[800],
                      "&.Mui-checked": {
                        color: red[600],
                      },
                    }}
                  />
                }
                label="Urgent"
              />

              <FormControlLabel
                value="high"
                control={
                  <Radio
                    {...controlProps("high")}
                    sx={{
                      color: orange[800],
                      "&.Mui-checked": {
                        color: orange[600],
                      },
                    }}
                  />
                }
                label="High"
              />

              <FormControlLabel
                value="medium"
                control={
                  <Radio
                    {...controlProps("medium")}
                    sx={{
                      color: yellow[500],
                      "&.Mui-checked": {
                        color: yellow[400],
                      },
                    }}
                  />
                }
                label="Medium"
              />

              <FormControlLabel
                value="low"
                control={
                  <Radio
                    {...controlProps("low")}
                    sx={{
                      color: lightGreen[800],
                      "&.Mui-checked": {
                        color: lightGreen[600],
                      },
                    }}
                  />
                }
                label="Low"
              />
            </div>

            <DialogContentText id="alert-dialog-description">
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={priority}
                  label="Priority"
                  onChange={handleChange}
                >
                  <MenuItem value={"urgent"}>Urgent</MenuItem>
                  <MenuItem value={"high"}>High</MenuItem>
                  <MenuItem value={"medium"}>Medium</MenuItem>
                  <MenuItem value={"low"}>Low</MenuItem>
                </Select>
              </FormControl> */}

              <br />
              <TextField
                id="outlined-multiline-static"
                label="Please describe the error"
                helperText="This message will be sent to #make-a-coder-friend channel."
                fullWidth
                multiline
                rows={5}
                onChange={(e) => updateField(e)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {loading ? (
              <SpinnerAdornment />
            ) : note.length > 0 ? (
              <Button color="secondary" onClick={saveIssue}>
                Send It!
              </Button>
            ) : (
              <Button disabled color="secondary">
                Send It!
              </Button>
            )}
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default SendFeedback;
