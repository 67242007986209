import React, { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import _ from "lodash";

import { useSnackbar } from "notistack";

import { States } from "../constants";
import {
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  ADDRESS_SHIPPING_COST,
  CONFIRM_UPDATE_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_MONGO_ADDRESS,
} from "../../../../../queries";

// import {
//   updateAddressReducer,
//   createAddressReducer,
//   deleteAddressReducer,
// } from "../../store/Address";

// import { updateBillingAddressReducer } from "../../store/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  parent: {
    position: "relative",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000085",
    position: "absolute",
  },
}));
const INTERVAL = 1000;
const AddressComponent = ({
  billingAddress = false,
  newAddress = false,
  selectedAddress = {},
  close = () => {},
  customerMongoEmail,
  refetch,
  boundedDogs = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createAddressMutation, { loading: addressSaveLoading }] =
    useMutation(CREATE_ADDRESS);
  const [updateAddressMutation, { loading: updateAddressLoading }] =
    useMutation(UPDATE_ADDRESS);
  const [
    confirmUpdateAddressMutation,
    { loading: updateAddressandStripeLoading },
  ] = useMutation(CONFIRM_UPDATE_ADDRESS);

  const [updateMongoAddressMutation] = useMutation(UPDATE_MONGO_ADDRESS);

  const [deleteAddressMutation] = useMutation(DELETE_ADDRESS);

  const [
    fetchShippingCostOfDogsMQuery,
    {
      data: dogsShippingCosts,
      loading: loadingShippingCost,
      error: shippingCostError,
    },
  ] = useLazyQuery(ADDRESS_SHIPPING_COST);

  const classes = useStyles();
  const mongoCustomer = useState({
    first_name: "first_name",
    last_name: "last_name",
    billing: {
      line1: "line1",
      line2: "line2",
      city: "city",
      state: "state",
      postal_code: "postal_code",
    },
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [name, setName] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postal, setPostal] = useState("");

  // Validation States
  const [nameValid, setNameValid] = useState(true);
  const [line1Valid, setLine1Valid] = useState(true);
  const [cityValid, setCityValid] = useState(true);
  const [stateValid, setStateValid] = useState(true);
  const [postalValid, setPostalValid] = useState(true);

  const [loading, setLoading] = useState(false);

  const [dogsAndCosts, setDogsAndCosts] = useState([]);
  const [dogsAndCostsDialog, setDogsAndCostsDialog] = useState(false);

  const [deleteAddressDialog, setDeleteAddressDialog] = useState(false);
  const [updateMongoAddressDialog, setUpdateMongoAddressDialog] = useState();
  const [clicks, setClicks] = useState(0);

  // Add once click to the existing state value
  const clickOnce = (click) => {
    setClicks(click + 1);
  };

  // debounce the click function
  // note that the clicks are passed as a parameter to avoid repeating the initial value
  const debouncedClick = useCallback(
    _.debounce(
      (clicks) => {
        clickOnce(clicks);
      },
      INTERVAL,
      { leading: true, trailing: false, maxWait: INTERVAL }
    ),
    []
  );

  const initialBillingAddressState = () => {
    setName(
      `${mongoCustomer.first_name || ""} ${mongoCustomer.last_name || ""}`
    );
    setLine1(mongoCustomer.billing.line1 || "");
    setLine2(mongoCustomer.billing.line2 || "");
    setCity(mongoCustomer.billing.city || "");
    setState(mongoCustomer.billing.state || "");
    setPostal(mongoCustomer.billing.postal_code || "");
  };
  const initialNewAddress = () => {
    setLine1("");
    setLine2("");
    setCity("");
    setState("");
    setPostal("");
  };
  const initialSelectedAddress = () => {
    setName(selectedAddress.name || "");
    setLine1(selectedAddress.address || "");
    setLine2(selectedAddress.address2 || "");
    setCity(selectedAddress.city || "");
    setState(selectedAddress.state || "");
    setPostal(selectedAddress.postal_code || "");
  };
  useEffect(() => {
    if (billingAddress) {
      initialBillingAddressState();
    }
    if (newAddress) {
      initialNewAddress();
    }
    if (Object.keys(selectedAddress).length !== 0) {
      initialSelectedAddress();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (billingAddress) {
      initialBillingAddressState();
    }
    // eslint-disable-next-line
  }, [billingAddress]);
  useEffect(() => {
    if (newAddress) {
      initialNewAddress();
    }
    // eslint-disable-next-line
  }, [newAddress]);
  useEffect(() => {
    if (Object.keys(selectedAddress).length !== 0) {
      initialSelectedAddress();
    }
    // eslint-disable-next-line
  }, [selectedAddress]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (clicks > 0) {
      onSubmitForm();
    }
  }, [clicks]);

  const onSubmitForm = () => {
    if (!isFormValid()) {
      return;
    }
    if (newAddress) {
      onCreateNewCustomerAddress();
    } else {
      onUpdateCustomerAddress();
    }
  };

  const onDeleteCustomerAddress = async () => {
    deleteAddressMutation({
      variables: {
        id: selectedAddress.id,
      },
    })
      .then((response) => {
        enqueueSnackbar("Address Deleted Successfully", { variant: "success" });
        console.log("DELETE RESPONSE =>");
        console.log(response);
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar("Address Could not Deleted", { variant: "error" });
        console.log("DELETE ERR RESPONSE =>");
        console.log(err);
        refetch();
      });
  };
  const onUpdateAddressOnMongo = () => {
    const opdateAddressObj = {
      name: name,
      address: line1,
      address2: line2,
      state: state,
      postal_code: postal,
      city: city,
    };
    // console.log(shippingCost);
    // return;
    setLoading(true);

    updateMongoAddressMutation({
      variables: {
        id: selectedAddress.id,
        input: opdateAddressObj,
      },
    })
      .then((response) => {
        enqueueSnackbar("Address Updated Successfully", { variant: "success" });
        close();
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar("Address Could not Updated", { variant: "error" });
        close();
        refetch();
      });
    setLoading(false);
  };
  const confirmUpdateAddress = (shippingCost) => {
    const opdateAddressObj = {
      name: name,
      line1: line1,
      line2: line2,
      state: state,
      postal_code: postal,
      city: city,
    };
    // console.log(shippingCost);
    // return;
    setLoading(true);

    confirmUpdateAddressMutation({
      variables: {
        addressId: selectedAddress.id,
        input: {
          address: opdateAddressObj,
          dogRates: shippingCost,
        },
      },
    })
      .then((response) => {
        enqueueSnackbar("Address Updated Successfully", { variant: "success" });
        close();
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar("Address Could not Updated", { variant: "error" });
        close();
        refetch();
      });
    setLoading(false);
  };

  useEffect(() => {
    if (dogsShippingCosts) {
      setDogsAndCosts(dogsShippingCosts.getAddressShippingCost);
      setDogsAndCostsDialog(true);
      setLoading(false);
    }
    if (shippingCostError) {
      console.log("=======> errData");
      console.log(shippingCostError);
      setLoading(false);
    }
  }, [dogsShippingCosts, loadingShippingCost, shippingCostError]);
  const onUpdateCustomerAddress = async () => {
    // setUpdateMongoAddressDialog(true);
    // console.log(boundedDogs);
    // return;
    if (!boundedDogs) {
      setUpdateMongoAddressDialog(true);
      return;
    }

    fetchShippingCostOfDogsMQuery({
      variables: {
        addressId: selectedAddress.id,
        input: {
          line1: line1,
          line2: line2,
          city: city,
          state: state,
          postal_code: postal,
        },
      },
      fetchPolicy: "no-cache",
    })
      .then((data) => {
        console.log("data");
        console.log(data);
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
      });
  };
  const onCreateNewCustomerAddress = async () => {
    setLoading(true);
    const opdateAddressObj = {
      name: name,
      address: line1,
      address2: line2,
      state: state,
      postal_code: postal,
      city: city,
      status: 0,
    };

    createAddressMutation({
      variables: {
        email: customerMongoEmail,
        input: opdateAddressObj,
      },
    })
      .then((response) => {
        enqueueSnackbar("Address Created Successfully", { variant: "success" });
        close();
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar("Address Could not Created", { variant: "error" });
        close();
        refetch();
      });

    setLoading(false);
  };
  const onUpdateBillingAddress = async () => {};

  const isFormValid = () => {
    let flag = true;
    if (!name && name.length === 0) {
      flag = false;
      setNameValid(false);
    } else {
      setNameValid(true);
    }
    if (line1.length === 0) {
      flag = false;
      setLine1Valid(false);
    } else {
      setLine1Valid(true);
    }

    if (city.length === 0) {
      flag = false;
      setCityValid(false);
    } else {
      setCityValid(true);
    }
    if (state.length === 0) {
      flag = false;
      setStateValid(false);
    } else {
      setStateValid(true);
    }
    if (postal.length === 0) {
      flag = false;
      setPostalValid(false);
    } else {
      setPostalValid(true);
    }
    return flag;
  };

  return (
    <div className={classes.parent}>
      <Backdrop
        className={classes.backdrop}
        open={loadingShippingCost || updateAddressandStripeLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        {!billingAddress && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div>
              <TextField
                disabled={loading}
                error={!nameValid}
                variant={"outlined"}
                fullWidth
                label={"Address Name"}
                placeholder={"Address Name"}
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></TextField>
            </div>
          </Grid>
        )}
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <div>
            <TextField
              disabled={loading}
              error={!line1Valid}
              variant={"outlined"}
              fullWidth
              label={"Address Line 1"}
              placeholder={"Address Line 1"}
              value={line1}
              onChange={(e) => setLine1(e.target.value)}
            ></TextField>
          </div>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <div>
            <TextField
              disabled={loading}
              variant={"outlined"}
              fullWidth
              label={"Address Line 2"}
              placeholder={"Address Line 2"}
              value={line2}
              onChange={(e) => setLine2(e.target.value)}
            ></TextField>
          </div>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <div>
            <TextField
              disabled={loading}
              error={!cityValid}
              variant={"outlined"}
              fullWidth
              label={"City"}
              placeholder={"City"}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            ></TextField>
          </div>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <div>
            <TextField
              disabled={loading}
              error={!postalValid}
              variant={"outlined"}
              fullWidth
              label={"Postal Code"}
              placeholder={"Postal Code"}
              value={postal}
              onChange={(e) => setPostal(e.target.value)}
            ></TextField>
          </div>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <div>
            <Button
              disabled={loading}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              fullWidth
              variant={"outlined"}
              color={!stateValid ? "secondary" : "primary"}
              size={"large"}
            >
              {States.find((singleState) => singleState.value === state)
                ? States.find((singleState) => singleState.value === state)
                    .label
                : "SELECT STATE"}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {States.map((singleState) => {
                return (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setState(singleState.value);
                    }}
                    fullWidth
                  >
                    {singleState.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </Grid>

        {Object.keys(selectedAddress).length !== 0 ? (
          <Grid item md={6} sm={12} xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {/* <Grid item md={6} sm={6} xs={6}>
                <div>
                  <Button
                    disabled={loading}
                    fullWidth
                    color={"secondary"}
                    variant={"contained"}
                    onClick={() => onDeleteCustomerAddress()}
                  >
                    {loading ? (
                      <CircularProgress color="white" size={24} />
                    ) : (
                      "Delete"
                    )}
                  </Button>
                </div>
              </Grid> */}
              <Grid item md={12} sm={12} xs={12}>
                <div>
                  <Button
                    disabled={loading}
                    fullWidth
                    color={"secondary"}
                    variant={"contained"}
                    clicks={clicks}
                    onClick={() => debouncedClick(clicks)}
                  >
                    {loading ? (
                      <CircularProgress color="white" size={24} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item md={6} sm={12} xs={12}>
            <div>
              <Button
                disabled={loading}
                fullWidth
                color={"secondary"}
                variant={"contained"}
                clicks={clicks}
                onClick={() => debouncedClick(clicks)}
              >
                {loading ? (
                  <CircularProgress color="white" size={24} />
                ) : newAddress ? (
                  "Create"
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Grid>
        )}
        {Object.keys(selectedAddress).length !== 0 && !boundedDogs && (
          <Grid item xs={12}>
            <Button
              fullWidth
              variant={"outlined"}
              color={"secondary"}
              onClick={() => {
                setDeleteAddressDialog(true);
              }}
            >
              DELETE
            </Button>
          </Grid>
        )}
      </Grid>

      <UsedAddressDialog
        dogsAndCostsDialog={dogsAndCostsDialog}
        close={() => {
          setDogsAndCostsDialog(false);
          setLoading(false);
        }}
        dogsAndCosts={dogsAndCosts}
        confirmUpdate={(data) => {
          confirmUpdateAddress(data);
        }}
      />
      <Dialog
        open={deleteAddressDialog}
        onClose={() => {
          setDeleteAddressDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are You Sure"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This Address will be removed everywhere
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteAddressDialog(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDeleteAddressDialog(false);
              onDeleteCustomerAddress();
            }}
            color="primary"
            autoFocus
          >
            Delete Address
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={updateMongoAddressDialog}
        onClose={() => {
          setUpdateMongoAddressDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are You Sure"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This Address will be updated everywhere
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setUpdateMongoAddressDialog(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setUpdateMongoAddressDialog(false);
              onUpdateAddressOnMongo();
            }}
            color="primary"
            autoFocus
          >
            Update Address
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddressComponent;

const UsedAddressDialog = ({
  dogsAndCosts,
  dogsAndCostsDialog,
  close,
  confirmUpdate,
}) => {
  const handleClose = () => {
    close();
  };

  return (
    <div>
      <Dialog
        open={dogsAndCostsDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"This Address Used By this Dogs (New shipping cost)"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dogsAndCosts.map((item) => {
              console.log("ITEM =>");
              console.log(item);
              return (
                <p>
                  {item.dog.name} $({item.rate.shipmentCost})
                </p>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              close();
              confirmUpdate(
                dogsAndCosts.map((item) => {
                  return {
                    dogId: item.dog.id,
                    rate: item.rate.shipmentCost,
                  };
                })
              );
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
