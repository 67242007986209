import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { GETDOGS, INTAKE_FORM } from "../../../../../queries";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  parent: {
    position: "relative",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000085",
    position: "absolute",
  },
}));

export default function EditDialog({ rawText, onSave, userId }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dogs, setDogs] = useState([]);
  const [value, setValue] = useState("1");
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [
    getDogs,
    { loading: loadingGetDogs, data: dataGetDogs, refetch: refetchUserDogs },
  ] = useLazyQuery(GETDOGS, {
    fetchPolicy: "no-cache",
    variables: {
      owner: userId,
    },
    onCompleted: (res) => {
      setDogs(res.getMongoDogsByOwnerID);
    },
  });
  useEffect(() => {
    if (open) {
      refetchUserDogs();
    }
  }, [open]);
  const initialIntakeForm = {
    dog: {},
    gender: "",
    primaryFood: "",
    medical: "",
    fleaHearthworm: "",
    improveOnFirst: "",
  };
  const [intakeForm, setIntakeForm] = useState(initialIntakeForm);

  const [intakeFormMutation, { loading: intakeFormLoading, data, error }] =
    useMutation(INTAKE_FORM);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const onSaveIntakeForm = () => {
    intakeFormMutation({
      variables: {
        dogId: intakeForm.dog.id,
        input: [
          {
            question: "gender",
            answer: intakeForm.gender,
          },
          {
            question: "Primary food (Type of food and brand)",
            answer: intakeForm.primaryFood,
          },
          {
            question:
              "Do you use flea or heartworm prevention? If so, what brand, oral or topical, and how often? ",
            answer: intakeForm.fleaHearthworm,
          },
          {
            question:
              "Any medical conditions or currently on medication? (Please note: we cannot provide medical advice or consult regarding nutrition if your dog is on medication.)",
            answer: intakeForm.medical,
          },
          {
            question:
              "What do you want to improve on first? For example, allergies, digestion, dental health, obesity or disease prevention, etc.",
            answer: intakeForm.improveOnFirst,
          },
        ],
      },
    })
      .then((res) => {
        console.log(res);
        enqueueSnackbar("Intake form saved", {
          variant: "success",
        });
        setIntakeForm(initialIntakeForm);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Intake form could not saved", {
          variant: "error",
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [notes, setNotes] = useState("");
  useEffect(() => {
    if (open) {
      //   setNotes("");
    }
  }, [open]);
  useEffect(() => {
    // setNotes("");
    if (rawText) {
      setNotes(rawText);
    }
  }, [rawText]);
  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <NoteAltIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <Backdrop
          className={classes.backdrop}
          open={loadingGetDogs || intakeFormLoading}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              centered
              indicatorColor="secondary"
            >
              <Tab label="Comment" value="1" />
              <Tab label="Intake Form" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <DialogTitle id="alert-dialog-title">
              {"Comments for the consuntation"}
            </DialogTitle>
            <DialogContent>
              <TextField
                style={{
                  marginTop: 10,
                }}
                fullWidth
                id="outlined-multiline-static"
                label="Comments"
                multiline
                rows={4}
                defaultValue="Leave Comments for the consultation"
                variant="outlined"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                CANCEL
              </Button>
              <Button
                onClick={() => {
                  onSave(notes);
                  handleClose();
                }}
                color="primary"
                autoFocus
              >
                SAVE
              </Button>
            </DialogActions>
          </TabPanel>
          <TabPanel value="2">
            {" "}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Select Dog"
                      value={intakeForm.dog}
                      onChange={(e) => {
                        setIntakeForm({ ...intakeForm, dog: e.target.value });
                      }}
                      fullWidth
                    >
                      {dogs.map((option) => (
                        <MenuItem key={option.value} value={option}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {Object.keys(intakeForm.dog).length !== 0 &&
                    intakeForm.dog.intakeForm && (
                      <Grid item xs={12}>
                        <Alert severity="success">
                          {intakeForm.dog.name} has already filled intake form
                        </Alert>
                      </Grid>
                    )}
                  {Object.keys(intakeForm.dog).length !== 0 &&
                    !intakeForm.dog.intakeForm && (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Gender"
                            value={intakeForm.gender}
                            onChange={(e) => {
                              setIntakeForm({
                                ...intakeForm,
                                gender: e.target.value,
                              });
                            }}
                            fullWidth
                          >
                            {["male", "female"].map((option) => (
                              <MenuItem key={option.value} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          Primary food (Type of food and brand) *
                          <TextField
                            fullWidth
                            value={intakeForm.primaryFood}
                            onChange={(e) => {
                              setIntakeForm({
                                ...intakeForm,
                                primaryFood: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          Do you use flea or heartworm prevention? If so, what
                          brand, oral or topical, and how often? *
                          <TextField
                            fullWidth
                            value={intakeForm.medical}
                            onChange={(e) => {
                              setIntakeForm({
                                ...intakeForm,
                                medical: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          Any medical conditions or currently on medication?
                          (Please note: we cannot provide medical advice or
                          consult regarding nutrition if your dog is on
                          medication.) *
                          <TextField
                            fullWidth
                            value={intakeForm.fleaHearthworm}
                            onChange={(e) => {
                              setIntakeForm({
                                ...intakeForm,
                                fleaHearthworm: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          What do you want to improve on first? For example,
                          allergies, digestion, dental health, obesity or
                          disease prevention, etc. *
                          <TextField
                            fullWidth
                            value={intakeForm.improveOnFirst}
                            onChange={(e) => {
                              setIntakeForm({
                                ...intakeForm,
                                improveOnFirst: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </>
                    )}
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                CANCEL
              </Button>
              <Button
                onClick={() => {
                  onSaveIntakeForm();
                }}
                color="primary"
                autoFocus
                disabled={
                  Object.keys(intakeForm.dog).length === 0 ||
                  intakeForm.gender === "" ||
                  intakeForm.primaryFood === "" ||
                  intakeForm.medical === "" ||
                  intakeForm.fleaHearthworm === "" ||
                  intakeForm.improveOnFirst === ""
                }
              >
                Save Intake Form
              </Button>
            </DialogActions>
          </TabPanel>
        </TabContext>
      </Dialog>
    </div>
  );
}
