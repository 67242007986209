import { useLazyQuery, useQuery } from "@apollo/client";
import { Grid, LinearProgress, Paper, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useEffect, useState } from "react";
import { GET_QUIZZES, GET_SINGLE_QUIZ } from "../../../../queries";

import PreviewIcon from "@mui/icons-material/Preview";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import QuizQuestions from "../../Quiz/components/QuizQuestions";

const QuizTutorial = ({ quiz, setQuiz }) => {
  const { loading, data, error } = useQuery(GET_QUIZZES);

  if (loading) {
    return <LinearProgress />;
  }
  console.log(data);
  return (
    <Paper
      style={{
        padding: 20,
        backgroundColor: "black",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {data.getQuizzes.map((simpleQuiz) => {
          return (
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={simpleQuiz?.id === quiz.id} />}
                label={`${simpleQuiz.title} (${simpleQuiz.questions.length} questions)`}
                onClick={() => {
                  console.log(simpleQuiz);
                  setQuiz({ ...simpleQuiz, quizId: simpleQuiz.id });
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

export default QuizTutorial;

export const TutorialQuizContentPreview = ({ tutorial }) => {
  const { id } = tutorial.content;
  const [questions, setQuestions] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [getSingleQuizQuery, { loading, error }] = useLazyQuery(
    GET_SINGLE_QUIZ,
    {
      variables: {
        input: {
          id,
        },
      },
      onCompleted: (response) => {
        setQuestions(response.getSingleQuiz.questions);
      },
    }
  );
  useEffect(() => {
    if (open) {
      getSingleQuizQuery();
    }
  }, [open]);

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <Typography>Error</Typography>;
  }
  return (
    <div>
      <Button startIcon={<PreviewIcon />} onClick={handleClickOpen}>
        Preview
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{tutorial.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <QuizQuestions preview={true} questions={questions} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
