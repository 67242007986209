import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

//Library
import {
  TablePagination,
  Breadcrumbs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import Edit from "@mui/icons-material/Edit";

//queries
import { GET_FEEDREALS } from "../../../queries";
import AutoCompleteSearch from "./AutoCompleteSearch";

function GlossaryIndex(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_FEEDREALS, {
    variables: {
      offset: 0,
      limit: rowsPerPage,
    },
  });
  useEffect(() => {
    refetch();
  }, [rowsPerPage]);

  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.feedreals.length,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    console.log("page change");
    setPage(newPage);
    await loadPage();
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("handleChangeRowsPerPage");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>{error.message}.</div>;

  // console.log(data);
  return (
    <>
      <AutoCompleteSearch list={data.feedreals} />

      <GlossaryUI
        {...data}
        history={props.history}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={(a, b) => {
          handleChangePage(a, b);
        }}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        refetch={refetch}
      />
    </>
  );
}

class GlossaryUI extends React.Component {
  componentDidMount() {
    // console.log("comptest", this.props);
    this.props.refetch();
  }

  render() {
    return (
      <>
        <div className="pagetop">
          <div className="left">
            <Breadcrumbs aria-label="breadcrumb">
              <a color="inherit" href="/">
                Dashboard
              </a>
              <Typography color="textPrimary">Glossary</Typography>
            </Breadcrumbs>
          </div>
          <div className="right"></div>
          <div className="right">
            <Button
              component={Link}
              variant="outlined"
              color="secondary"
              to="glossary/add"
            >
              Add Glossary
            </Button>
          </div>
        </div>
        <ul>
          {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell align="left">Sefurl</TableCell>
                    <TableCell align="left">Category</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.feedreals
                    .slice(
                      this.props.page * this.props.rowsPerPage,
                      this.props.page * this.props.rowsPerPage +
                        this.props.rowsPerPage
                    )
                    .map((feedreal) => {
                      return (
                        <TableRow key={feedreal.id}>
                          <TableCell component="th" align="left" scope="row">
                            {feedreal.title}
                          </TableCell>
                          <TableCell component="th" align="left" scope="row">
                            {feedreal.sefurl}
                          </TableCell>
                          <TableCell component="th" align="left" scope="row">
                            {feedreal.category}
                          </TableCell>
                          <TableCell component="th" align="left" scope="row">
                            <Button
                              variant="outlined"
                              color="secondary"
                              className={
                                feedreal.status ? "greenborder" : "redborder"
                              }
                              disabled
                              size="small"
                            >
                              <b
                                className={
                                  feedreal.status ? "greenborder" : "redborder"
                                }
                              >
                                {feedreal.status ? "Active" : "Inactive"}
                              </b>
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              component={Link}
                              variant="outlined"
                              color="secondary"
                              to={`/dashboard/glossary/edit/${feedreal.id}`}
                            >
                              <Edit />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          }

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={this.props.feedrealsCount}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            onPageChange={this.props.handleChangePage}
            onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          />
        </ul>
      </>
    );
  }
}

export default GlossaryIndex;
