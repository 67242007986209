import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import {
  UPDATE_COM_METHOD,
  UPDATE_COM_PERMISSONS,
} from "../../../../../queries";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { set } from "lodash";

const useStyles = makeStyles({
  whiteBorder: {
    border: ".5px solid #55565a",
    borderRadius: "3px",
    textAlign: "center",
    marginTop: "20px",
  },
});

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#4caf50" : "#4caf50",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function CustomizedSwitches(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles(props);
  const [commType, setCommType] = useState(false);
  const [showupdate, setShowUpdate] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCommType(props.communication === 0 ? false : true);
  }, []);

  const [updateComm, { error, loading }] = useMutation(UPDATE_COM_METHOD);

  const handleSwitch = (e) => {
    setCommType(e.target.checked);
    if (e.target.checked == props.communication) {
      setShowUpdate(false);
    } else {
      setShowUpdate(true);
    }
  };

  const updateChanges = async () => {
    updateComm({
      variables: {
        id: props.id,
        communication: commType ? 1 : 0,
      },
    })
      .then(async ({ data }) => {
        enqueueSnackbar(`Member communication setting successfully updated!`, {
          variant: "success",
        });
        props.handleRefetch();
        setShowUpdate(false);
      })
      .catch((error) => {
        enqueueSnackbar(`${error}`, {
          variant: "error",
        });
      });
  };
  return (
    <div>
      <FormGroup className={classes.whiteBorder}>
        <FormLabel component="legend" style={{ marginTop: "5px" }}>
          Communication
        </FormLabel>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "5px" }}
        >
          <Typography>Mail</Typography>
          <AntSwitch
            checked={commType}
            onChange={(e) => handleSwitch(e)}
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography>SMS</Typography>
        </Stack>
      </FormGroup>
      <CommunicationPermissions
        communicationPermissions={props.communicationPermissions}
        id={props.id}
        handleRefetch={props.handleRefetch}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
      <Button
        fullWidth
        variant={"outlined"}
        size={"large"}
        color={"primary"}
        onClick={() => {
          setOpen(true);
        }}
      >
        Communication Permissions
      </Button>
      {showupdate && (
        <Button
          fullWidth
          variant={"outlined"}
          size={"large"}
          color={"secondary"}
          onClick={updateChanges}
        >
          Update
        </Button>
      )}
    </div>
  );
}

const CommunicationPermissions = (props) => {
  const classes = useStyles(props);
  const [sms, setSms] = useState(false);
  const [email, setEmail] = useState(false);
  const [push, setPush] = useState(false);
  const [showupdate, setShowUpdate] = useState(false);
  const { onClose, open, communicationPermissions, id, handleRefetch } = props;
  const [updateComm, { error, loading }] = useMutation(UPDATE_COM_PERMISSONS);
  const { enqueueSnackbar } = useSnackbar();

  const updateChanges = async () => {
    updateComm({
      variables: {
        id: id,
        sms: sms,
        email: email,
        push: push,
      },
    })
      .then(async ({ data }) => {
        enqueueSnackbar(`Member communication setting successfully updated!`, {
          variant: "success",
        });
        handleClose();
        handleRefetch();
      })
      .catch((error) => {
        enqueueSnackbar(`${error}`, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    setSms(communicationPermissions.sms);
    setEmail(communicationPermissions.email);
    setPush(communicationPermissions.push);
  }, [communicationPermissions]);

  const handleClose = () => {
    onClose();
    setSms(communicationPermissions.sms);
    setEmail(communicationPermissions.email);
    setPush(communicationPermissions.push);
  };

  useEffect(() => {
    if (
      sms === communicationPermissions.sms &&
      email === communicationPermissions.email &&
      push === communicationPermissions.push
    ) {
      setShowUpdate(false);
    } else if (
      sms !== communicationPermissions.sms ||
      email !== communicationPermissions.email ||
      push !== communicationPermissions.push
    ) {
      setShowUpdate(true);
    }
  }, [sms, email, push]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set Customer Communication Permissions</DialogTitle>
      <DialogContent>
        <FormGroup className={classes.whiteBorder}>
          <FormLabel component="legend" style={{ marginTop: "5px" }}>
            Email
          </FormLabel>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "5px" }}
          >
            <Typography>Close</Typography>
            <AntSwitch
              checked={email}
              onChange={(e) => {
                setEmail(e.target.checked);
              }}
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>Open</Typography>
          </Stack>
        </FormGroup>
        <FormGroup className={classes.whiteBorder}>
          <FormLabel component="legend" style={{ marginTop: "5px" }}>
            SMS
          </FormLabel>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "5px" }}
          >
            <Typography>Close</Typography>
            <AntSwitch
              checked={sms}
              onChange={(e) => {
                setSms(e.target.checked);
              }}
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>Open</Typography>
          </Stack>
        </FormGroup>
        <FormGroup className={classes.whiteBorder}>
          <FormLabel component="legend" style={{ marginTop: "5px" }}>
            PUSH NOTIFICATIONS
          </FormLabel>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "5px" }}
          >
            <Typography>Close</Typography>
            <AntSwitch
              checked={push}
              onChange={(e) => {
                setPush(e.target.checked);
              }}
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>Open</Typography>
          </Stack>
        </FormGroup>
        {showupdate && (
          <Button
            fullWidth
            variant={"outlined"}
            size={"large"}
            color={"secondary"}
            sx={{ marginTop: "10px" }}
            onClick={updateChanges}
          >
            Update
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};
