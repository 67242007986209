import ConstructionIcon from "@mui/icons-material/Construction";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import React, { forwardRef, useEffect, useState } from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { GET_CUSTOMERS_WORKSHOPS, GET_WORKSHOPS } from "../../../../../queries";
import { useMutation, useQuery } from "@apollo/client";
import { AddonItem } from "./addonItem";
import { Grid } from "@mui/material";
import WorkshopHistory from "./workshopHistory";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ADMIN_BOOK_WORKSHOP } from "../../../../../queries/Customer";
import { useSnackbar } from "notistack";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Workshops({ customer }) {
  const [open, setOpen] = useState(false);
  const [workshops, setWorkshops] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const { loading, error, refetch } = useQuery(GET_WORKSHOPS, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      setWorkshops(res.getWorkshops);
    },
  });
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
  }, [open]);

  return (
    <div style={{ marginTop: "20px" }}>
      <Button
        fullWidth
        variant="outlined"
        onClick={handleClickOpen}
        style={{
          color: "rgb(238 206 92)",
          borderColor: "rgb(238 206 92)",
        }}
        startIcon={<ConstructionIcon />}
      >
        Workshops
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Workshop History" {...a11yProps(0)} />
                <Tab label="Book Workshop" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={1}>
              {workshops.map((workshop) => {
                return (
                  <WorkshopContainer
                    workshop={workshop}
                    customer={customer}
                    close={handleClose}
                  />
                );
              })}
            </TabPanel>
            <TabPanel value={value} index={0}>
              <WorkshopHistory customer={customer} workshops={workshops} />
            </TabPanel>
          </Box>
        </DialogContent>

        {value === 1 && (
          <DialogActions>
            <Button
              onClick={handleClose}
              // disabled={loading || loadingRev }
            >
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

const WorkshopContainer = ({ workshop, customer, close }) => {
  const [selectedTime, setSelectedTime] = React.useState("");
  const [free, setFree] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const [bookWorkshopMutation, { loading }] = useMutation(ADMIN_BOOK_WORKSHOP);

  const onBookForAWorkshop = () => {
    bookWorkshopMutation({
      variables: {
        customerId: customer.id,
        workshopId: workshop.id,
        startTime: selectedTime,
        chargeOption: !free,
      },
      fetchPolicy: "no-cache",
    })
      .then((res) => {
        enqueueSnackbar(`Workshop booked successfully`, {
          variant: "success",
        });
        close();
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Typography>{workshop.name}</Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        {workshop.times.map((item) => {
          return (
            <AddonItem
              disabled={loading}
              item={item}
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
            />
          );
        })}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <FormControlLabel
          control={
            <Checkbox
              disabled={loading}
              checked={free}
              onClick={() => {
                setFree(true);
              }}
            />
          }
          label="Free"
        />
        <FormControlLabel
          control={
            <Checkbox
              // disabled={loading}
              disabled={false}
              checked={!free}
              onClick={() => {
                setFree(false);
              }}
            />
          }
          label="Charge"
        />
        <Button
          disabled={loading || selectedTime === ""}
          onClick={() => {
            onBookForAWorkshop();
          }}
        >
          Book workshop
        </Button>
      </Grid>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
