import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useMutation } from "@apollo/client";
import { CREATE_BADGE } from "../../../queries";
import LinearLoading from "../LinearLoading";
import { LinearProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BadgeDialog({ completedCourse, refetch }) {
  const [open, setOpen] = React.useState(false);

  const [createBadgeMutation, { loading, error, data }] =
    useMutation(CREATE_BADGE);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCreateBadge = () => {
    createBadgeMutation({
      variables: {
        code: completedCourse.completedCourse.completeCode.replace(/ /g, ""),
      },
    })
      .then((res) => {
        refetch();
      })
      .catch((err) => {
        console.log();
      });
  };

  return (
    <div>
      {completedCourse.completedCourse.badgeUrl ? (
        <Button variant="outlined" onClick={handleClickOpen}>
          see badge
        </Button>
      ) : (
        <Button variant="outlined" onClick={onCreateBadge} disabled={loading}>
          Create Badge
        </Button>
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle>{""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={completedCourse.completedCourse.badgeUrl}
              style={{
                width: "100%",
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
          <Button
            onClick={() => {
              window.open(completedCourse.completedCourse.badgeUrl);
            }}
          >
            download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
