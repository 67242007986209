import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import { useQuery } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import { GET_CUSTOMERS_WORKSHOPS } from "../../../../../queries";
import { Chip, Grid, Typography } from "@mui/material";
import RescheduleWorkshop from "./RescheduleWorkshop";
import CancelWorkshop from "./CancelWorkshop";

export default function WorkshopHistory({ customer, workshops }) {
  const [checked, setChecked] = React.useState([0]);
  const [schedules, setSchedules] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const { loading: loadingRevS, refetch } = useQuery(GET_CUSTOMERS_WORKSHOPS, {
    variables: {
      customerId: customer.id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      const copyArr = JSON.parse(
        JSON.stringify(res.getCustomerWorkshopSchedules)
      );
      copyArr.sort((a, b) => {
        const aDate = parseInt(a.id.substring(0, 8), 16);
        const bDate = parseInt(b.id.substring(0, 8), 16);
        if (aDate > bDate) {
          return -1;
        }
        if (aDate < bDate) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
      setSchedules(copyArr);
    },
  });
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {schedules.length === 0 && (
        <Typography> user has not participated before </Typography>
      )}
      {schedules.map((value) => {
        const workshop = workshops.find((item) => item.id === value.addOnId);
        const labelId = `checkbox-list-label-${value}`;
        const date = new Date(value.startTime * 1000);
        const timeString = `${date.toLocaleDateString("en-US", {
          timeZone: "America/Los_Angeles",
        })}
        ${date.toLocaleTimeString("en-US", {
          timeZone: "America/Los_Angeles",
          hourCycle: "h24",
          timeStyle: "short",
        })}
        PT /
        ${date.toLocaleTimeString("en-US", {
          timeZone: "America/New_York",
          hourCycle: "h24",
          timeStyle: "short",
        })}
        ET`;
        const disabled = new Date() > date || value.info === "rescheduled";

        return (
          <ListItem
            style={{ width: "100%" }}
            key={value}
            secondaryAction={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <CancelWorkshop workshop={value} refetch={refetch} />
                {value.info && value.info !== "" && <Chip label={value.info} />}
              </Grid>
            }
            disablePadding
          >
            <ListItemIcon>
              <IconButton disabled={disabled}>
                {/* <EditIcon /> */}
                <RescheduleWorkshop
                  disabled={disabled || value.info === "cancelled"}
                  customerId={customer.id}
                  prevId={value ? value.id : ""}
                  prevStart={value ? value.startTime : ""}
                  workshop={workshop}
                  refetch={() => {
                    refetch();
                  }}
                />
              </IconButton>
            </ListItemIcon>
            <ListItemText
              id={labelId}
              secondary={timeString}
              primary={workshop.name}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
