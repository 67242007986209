import { useQuery } from "@apollo/client";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import { Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { MULTIPLE_CHOICE } from "../../../globals";
import { GET_FINAL_ALL_ATTENDANCES } from "../../../queries";
import { Button } from "@mui/material";
import ShowResults from "./components/showResults";

const QuizResults = (props) => {
  console.log("props", props);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // query get data
  const { loading, error, data, refetch, fetchMore } = useQuery(
    GET_FINAL_ALL_ATTENDANCES,
    {
      variables: {
        limit: rowsPerPage,
        offset: 0,
      },
    }
  );
  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.getAllFinalQuizAttendances.length,
        limit: rowsPerPage,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  const { permission } = props.session.activeAdmin;

  return (
    <div>
      {data.getAllFinalQuizAttendances.length > 0 ? (
        <PageUI
          {...data}
          permission={permission}
          loading={loading}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refetch={refetch}
          history={props.history}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const PageUI = (props) => {
  const [god, setGod] = useState(false);
  const [author, setAuthor] = useState(false);
  const [open, setOpen] = useState(false);
  const [answer, setAnswer] = useState({});

  const openDialog = (answer) => {
    setAnswer(answer);
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
    setAnswer({});
  };

  useEffect(() => {
    setGod(props.permission.god ? props.permission.god.admin : false);
    setAuthor(props.permission.author ? props.permission.author : false);
  }, [props.permission]);

  return (
    <div>
      {open && (
        <ShowResults answer={answer} open={open} closeDialog={closeDialog} />
      )}
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={"/"}>Dashboard</Link>
            <Link to={"/dashboard/course"}>Course</Link>
            <Typography color="textPrimary">Quiz Results</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div>
        {/* <AutoSearch
          history={props.history}
          edit={god || author.admin || author.write}
          refetch={props.refetch}
          setSelectedQuestion={setSelectedQuestion}
        /> */}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ul>
            {
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      {/* <TableCell>Email</TableCell> */}
                      <TableCell>Unit</TableCell>
                      <TableCell>Course</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Status</TableCell>
                      {god || author.admin || author.write ? (
                        <TableCell></TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.getAllFinalQuizAttendances &&
                    props.getAllFinalQuizAttendances.length > 0 ? (
                      props.getAllFinalQuizAttendances
                        .slice(
                          props.page * props.rowsPerPage,
                          props.page * props.rowsPerPage + props.rowsPerPage
                        )
                        .map((answer) => {
                          return (
                            <TableRow key={answer.id}>
                              <TableCell component="th" scope="row">
                                {answer.owner.name}
                              </TableCell>
                              {/* <TableCell component="th" scope="row">
                                {answer.owner.email}
                              </TableCell> */}
                              <TableCell component="th" scope="row">
                                {answer.unit.title}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {answer.course.title}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {moment(answer.createdAt).format("MM/DD/YYYY")}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {answer.scoreDetail.pass ? (
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    className="greenborder"
                                    disabled
                                  >
                                    <b className="greenborder">Passed</b>
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    className="redborder"
                                    disabled
                                    size="small"
                                  >
                                    <b className="redborder">Failed</b>
                                  </Button>
                                )}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Button
                                  variant="outlined"
                                  onClick={() => openDialog(answer)}
                                >
                                  See Answers
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </ul>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={
              props.getAllFinalQuizAttendancesCount
                ? props.getAllFinalQuizAttendancesCount
                : 0
            }
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default QuizResults;
