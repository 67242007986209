import React, { Component, useEffect, useState, useRef } from "react";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  useLazyQuery
} from "@apollo/client";
import {
  GET_INVENTORY_PRODUCTS,
  GET_SUPPLIERS,
  ADD_INV_PRODUCT,
  GET_INV_PRODUCT,
  UPDATE_INV_PRODUCT,
  ADD_INV_SUPPLIER,
  UPDATE_INV_SUPPLIER,
  GET_INV_SUPPLIER
} from "../../../../queries";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Grid from "@mui/material/Grid";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";

function ManageProduct(props) {
  // states
  const initialStateAddProduct = {
    name: "",
    category: "",
    product_id: "",
    weight: "",
    yield_number: "",
    hours: "",
    quantity: "",
    keywords: ""
  };
  // form states
  const [form, setState] = useState(initialStateAddProduct);

  // modal states
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [changeLbs, setChangeLbs] = useState(false);

  //  data id state
  const [prodId, setProdId] = useState(null);

  // first mount ref
  const isInitialMount = useRef(true);

  const {
    name,
    category,
    product_id,
    weight,
    keywords,
    yield_number,
    hours,
    quantity
  } = form;

  // props activeAdmin
  const admin_id = props.admin;

  // query get data

  const { loading, error, data, refetch } = useQuery(GET_INVENTORY_PRODUCTS);
  const {
    loading: loadingS,
    error: errorS,
    data: dataS,
    refetch: refetchS
  } = useQuery(GET_SUPPLIERS);

  // mutations
  const [
    createInvProduct,
    { loading: loadingAddP, error: errorCreateProd }
  ] = useMutation(ADD_INV_PRODUCT);

  const [updateInvProduct, { loading: loadingAddU }] = useMutation(
    UPDATE_INV_PRODUCT
  );

  // get data and setstate
  const [
    getProduct,
    { loading: loadingGetProd, data: dataGetProd }
  ] = useLazyQuery(GET_INV_PRODUCT, {
    variables: {
      id: prodId
    },
    onCompleted: data => {
      resetState();
      setState({
        name: data.inv_product.name,
        category: data.inv_product.category,
        product_id: data.inv_product.product_id,
        weight: data.inv_product.weight,
        yield_number: data.inv_product.yield_number,
        hours: data.inv_product.hours,
        quantity: data.inv_product.quantity,
        keywords: data.inv_product.keywords
      });
    }
  });

  useEffect(() => {
    if (!isInitialMount.current) {
      getProduct();
    }
    isInitialMount.current = true;
  }, [prodId]);

  useEffect(() => {
    if (
      category === "muscle-meat" ||
      category === "seafood" ||
      category === "organ-meat" ||
      category === "secretshop-lbs"
    ) {
      setChangeLbs(true);
    } else {
      setChangeLbs(false);
    }
  }, [category]);

  // functions modals
  const handleClickOpen = () => {
    resetState();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClickOpen = id => {
    setProdId(id);
    setEdit(true);
    isInitialMount.current = false;
  };

  const handleEditClose = () => {
    setEdit(false);
  };

  // reset state
  const resetState = () => {
    setState({ ...initialStateAddProduct });
  };

  // onChange

  const updateField = e => {
    setState({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const formValidate = () => {
    if (!changeLbs) {
      return (
        !name ||
        !category ||
        !product_id ||
        !keywords ||
        !yield_number ||
        !hours ||
        !quantity
      );
    }
    return (
      !name ||
      !category ||
      !product_id ||
      !weight ||
      !keywords ||
      !yield_number ||
      !hours
    );
  };

  // add product mutation

  const addProductMutation = () => {
    createInvProduct({
      variables: {
        name,
        category,
        admin_id,
        product_id,
        weight,
        quantity,
        yield_number,
        hours,
        keywords
      }
    }).then(async ({ data }) => {
      handleClose();
      refetch();
      console.log(data);
    });
  };

  const editProductMutation = () => {
    updateInvProduct({
      variables: {
        id: prodId,
        name,
        category,
        admin_id,
        product_id,
        weight,
        quantity,
        yield_number,
        hours,
        keywords
      }
    }).then(async ({ data }) => {
      handleEditClose();
      refetch();
      console.log(data);
    });
  };

  if (loading || loadingS)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error || errorS) return <div>Error.</div>;

  return (
    <div>
      <div>
        <div>
          <Dialog
            open={edit}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
          >
            {loadingGetProd ? (
              <div>
                <div>
                  <LinearProgress color="secondary" />
                </div>
              </div>
            ) : (
              <div>
                <DialogTitle id="form-dialog-title">{form.name}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Edit Product for inventory
                  </DialogContentText>
                  <TextField
                    variant="outlined"
                    autoFocus
                    margin="normal"
                    value={form.name}
                    name="name"
                    label="Product Name"
                    type="text"
                    fullWidth
                    onChange={updateField}
                  />
                  <FormControl variant="outlined" className="width100">
                    <InputLabel id="category">Select Category</InputLabel>
                    <Select
                      name="category"
                      onChange={updateField}
                      value={form.category}
                      label="category"
                    >
                      <MenuItem value="">
                        <em>Select Category</em>
                      </MenuItem>
                      <MenuItem value="muscle-meat">Muscle Meat</MenuItem>
                      <MenuItem value="organ-meat">Organ Meat</MenuItem>
                      <MenuItem value="seafood">Seafood</MenuItem>
                      <MenuItem value="light-chew">Light Chew</MenuItem>
                      <MenuItem value="medium-chew">Medium Chew</MenuItem>
                      <MenuItem value="heavy-chew">Heavy Chew</MenuItem>
                      <MenuItem value="super-chew">Super Chew</MenuItem>
                      <MenuItem value="secretshop-lbs">
                        Secretshop(lbs)
                      </MenuItem>
                      <MenuItem value="secretshop">Secretshop(qty)</MenuItem>
                    </Select>
                  </FormControl>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        name="product_id"
                        label="Product ID"
                        type="text"
                        value={form.product_id}
                        onChange={updateField}
                        width="50%"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        variant="outlined"
                        disabled={!changeLbs}
                        margin="normal"
                        name="weight"
                        label="Bag Weight(oz)"
                        value={form.weight}
                        onChange={updateField}
                        type="text"
                        width="sm"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        disabled={changeLbs}
                        name="quantity"
                        label="Quantity in a bag"
                        value={form.quantity}
                        onChange={updateField}
                        type="text"
                        width="sm"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        name="yield_number"
                        label="Drying Yield (decimal)"
                        value={form.yield_number}
                        onChange={updateField}
                        type="text"
                        width="sm"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        name="hours"
                        value={form.hours}
                        label="Drying Hours"
                        onChange={updateField}
                        type="text"
                        width="sm"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={form.keywords}
                    name="keywords"
                    onChange={updateField}
                    label="Keywords (separate with commas)"
                    type="text"
                    width="sm"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleEditClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={editProductMutation} color="secondary">
                    Update
                  </Button>
                </DialogActions>
              </div>
            )}
          </Dialog>
        </div>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
          >
            <DialogTitle id="form-dialog-title">Add Product</DialogTitle>
            <DialogContent>
              <DialogContentText>Add Product for inventory</DialogContentText>
              <TextField
                variant="outlined"
                autoFocus
                margin="normal"
                name="name"
                label="Product Name"
                type="text"
                fullWidth
                onChange={updateField}
              />
              <FormControl variant="outlined" className="width100">
                <InputLabel id="category">Select Category</InputLabel>
                <Select name="category" onChange={updateField} label="category">
                  <MenuItem value="">
                    <em>Select Category</em>
                  </MenuItem>
                  <MenuItem value="muscle-meat">Muscle Meat</MenuItem>
                  <MenuItem value="organ-meat">Organ Meat</MenuItem>
                  <MenuItem value="seafood">Seafood</MenuItem>
                  <MenuItem value="light-chew">Light Chew</MenuItem>
                  <MenuItem value="medium-chew">Medium Chew</MenuItem>
                  <MenuItem value="heavy-chew">Heavy Chew</MenuItem>
                  <MenuItem value="super-chew">Super Chew</MenuItem>
                  <MenuItem value="secretshop-lbs">Secretshop(lbs)</MenuItem>
                  <MenuItem value="secretshop">Secretshop(qty)</MenuItem>
                </Select>
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    name="product_id"
                    label="Product ID"
                    type="text"
                    onChange={updateField}
                    width="50%"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    variant="outlined"
                    disabled={!changeLbs}
                    margin="normal"
                    name="weight"
                    label="Bag Weight(oz)"
                    onChange={updateField}
                    type="text"
                    width="sm"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    disabled={changeLbs}
                    name="quantity"
                    label="Quantity"
                    onChange={updateField}
                    type="text"
                    width="sm"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    name="yield_number"
                    label="Drying Yield (decimal)"
                    onChange={updateField}
                    type="text"
                    width="sm"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    name="hours"
                    label="Drying Hours"
                    onChange={updateField}
                    type="text"
                    width="sm"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <TextField
                variant="outlined"
                margin="normal"
                name="keywords"
                onChange={updateField}
                label="Keywords (separate with commas)"
                type="text"
                width="sm"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={addProductMutation} color="secondary" disabled={formValidate()}>
                Add Product
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <InventoryProduct
        {...data}
        refetch={refetch}
        open={handleClickOpen}
        edit={handleEditClickOpen}
        getProduct={getProduct}
      />
    </div>
  );
}

export class InventoryProduct extends Component {
  componentDidMount() {
    this.props.refetch();
    //console.log(this.props);
    // console.log(this.props);
  }
  render() {
    return (
      <div>
        <ul>
          <div className="right">
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.props.open}
            >
              Add Product
            </Button>
            <br />
            <br />
          </div>
          {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Category</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.inv_products.map(products => (
                    <TableRow key={products.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="articletitle"
                      >
                        {products.name}
                      </TableCell>

                      <TableCell align="right">{products.category}</TableCell>

                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => this.props.edit(products.id)}
                        >
                          <Edit />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </ul>
      </div>
    );
  }
}

export default ManageProduct;
