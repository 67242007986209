import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GET_COURSE_SUCCESS_PAGE,
  UPDATE_COURSE_SUCCESS_PAGE,
} from "../../../../queries";
import LinearLoading from "../../LinearLoading";
import RichEditorExample from "../../Caninecare/Editor";

const CourseSuccessPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { loading, data, error } = useQuery(GET_COURSE_SUCCESS_PAGE, {
    fetchPolicy: "network-only",
    variables: { courseId: id },
  });
  let updatedText = "";
  const [updateCourseSuccess, { loading: loadingUpdate, error: errorUpdate }] =
    useMutation(UPDATE_COURSE_SUCCESS_PAGE);

  const [text, setText] = useState("");
  const changeNodeText = (data) => {
    updatedText = data;
  };
  useEffect(() => {
    if (data) {
      setText(data.getCourseSuccessPage.text);
    }
  }, [data]);

  const onUpdateCourseSuccess = () => {
    updateCourseSuccess({
      variables: {
        courseId: id,
        input: {
          text: JSON.stringify(updatedText),
        },
      },
    })
      .then(() => {
        history.push("/dashboard/course/");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (loading || loadingUpdate) {
    return <LinearLoading />;
  }
  if (error || errorUpdate) {
    return <div>error</div>;
  }
  return (
    <div>
      <RichEditorExample content={text} onChangeEditor={changeNodeText} />
      <div
        style={{
          width: "100%",
          padding: 10,
          paddingRight: 40,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            onUpdateCourseSuccess();
          }}
          style={{
            float: "right",
          }}
        >
          Update
        </Button>
      </div>
    </div>
  );
};
export default CourseSuccessPage;
