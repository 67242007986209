import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_FLAG, UPDATE_FLAG } from "../../../../queries";
import { LinearProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

const KEY = "free-box-promo-checkout";

export default function FreeBoxReward() {
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [getFlag, { loading, refetch }] = useLazyQuery(GET_FLAG, {
    onCompleted: (data) => {
      setFlag(data.getFlag);
    },
    fetchPolicy: "no-cache",
  });

  const [updateFlagMutation, { loading: loadingUpdate }] =
    useMutation(UPDATE_FLAG);

  useEffect(() => {
    getFlag({
      variables: {
        key: KEY,
      },
    });
  }, [getFlag, open]);

  const onChangeStatus = () => {
    setFlag((prevState) => {
      return { ...prevState, status: !prevState.status };
    });
  };
  const onChangeMessageHandler = (message) => {
    setFlag((prevState) => {
      return { ...prevState, message: message };
    });
  };
  const handleProductStatusUpdate = (index, status) => {
    setFlag((prevState) => {
      let currentFlag = Object.assign({}, prevState);
      currentFlag.data.products[index].status = status;
      return currentFlag;
    });
  };

  const update = () => {
    updateFlagMutation({
      variables: {
        id: flag.id,
        input: {
          key: KEY,
          status: flag.status,
          message: flag.message,
          data: flag.data,
        },
      },
    })
      .then((res) => {
        handleClose();
      })
      .catch((error) => {});
  };

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <Grid item xs>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        style={{
          borderLeft: "5px solid",
          borderLeftColor: flag.status ? "#63b475" : "#dc5a68",
        }}
        className="btn-reward"
      >
        Any Box BOGO
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{"Any Box BOGO"}</DialogTitle>
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                control={
                  <Switch
                    checked={flag.status}
                    color="secondary"
                    onClick={() => {
                      onChangeStatus();
                    }}
                  />
                }
                label="Reward"
              />
            </DialogContentText>

            <DialogContentText
              id="alert-dialog-description"
              sx={{ textAlign: "justify", textJustify: "inter-word" }}
            ></DialogContentText>
            <Box sx={{ width: "100%", marginTop: "30px" }}>
              {flag && flag.status && (
                <span>
                  <TextField
                    fullWidth
                    sx={{ marginBottom: "30px" }}
                    value={flag.message}
                    onChange={(e) => onChangeMessageHandler(e.target.value)}
                    label="Promotion Message"
                  />
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    {flag.data.products.map((product, index) => {
                      return (
                        <Grid item xs={6} sm={6} md={6} key={index}>
                          <FormControlLabel
                            key={product.id}
                            control={
                              <Switch
                                checked={product.status}
                                color="secondary"
                                onClick={(e) => {
                                  handleProductStatusUpdate(
                                    index,
                                    e.target.checked
                                  );
                                }}
                              />
                            }
                            label={product.name}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </span>
              )}
            </Box>
          </DialogContent>
        )}
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>cancel</Button>
            <Button onClick={update} autoFocus>
              Update
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Grid>
  );
}
