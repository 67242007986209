import React, { Component, useEffect, useState } from "react";
import { useQuery, useMutation, NetworkStatus } from "@apollo/client";
import {
  GET_QR_CODES,
  CREATE_QR,
  GET_BARCODES,
  GET_INV_PRODUCT,
} from "../../../queries";
import { Grid, LinearProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import QRCode from "qrcode";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import CreateBarcodeDialog, { ShowBarcode } from "./dialog";

const BarcodeList = (props) => {
  const { permission } = props.session.activeAdmin;
  const god = permission.god ? permission.god.admin : false;
  const qr = permission.author
    ? permission.author.qr
      ? permission.author.qr
      : false
    : false;
  const [barcodes, setBarcodes] = useState([]);

  const { data, loading, error, refetch } = useQuery(GET_BARCODES, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      console.log(data);
      setBarcodes(data.getBarcodes);
    }
  }, [data]);

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Barcode List</Typography>
          </Breadcrumbs>
        </div>
        {god || qr ? (
          <div className="right">
            <CreateBarcodeDialog refetch={refetch} />
          </div>
        ) : (
          ""
        )}
      </div>
      <ul>
        {
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Text</TableCell>
                  <TableCell>Product Name</TableCell>

                  {god ? <TableCell align="right"></TableCell> : ""}
                </TableRow>
              </TableHead>
              <TableBody>
                {barcodes.map((barcode) => (
                  <TableRow key={barcode.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className="articletitle"
                    >
                      {barcode.text}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="articletitle"
                    >
                      <BarcodeProd id={barcode.productId} />
                    </TableCell>
                    <TableCell align="center">
                      <ShowBarcode text={barcode.text} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </ul>
    </div>
  );
};

export default BarcodeList;

const BarcodeProd = ({ id }) => {
  const { loading, data, error } = useQuery(GET_INV_PRODUCT, {
    variables: {
      id,
    },
  });
  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <Typography> Error </Typography>;
  }
  return <div>{data.inv_product.name}</div>;
};
