import { useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Link, useHistory } from "react-router-dom";
import { GET_ACTIVE_ADMIN, GET_LANDINGLOGS } from "../../../queries";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import FromFind from "./FromFind";

function Customers(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState("");

  const isInitialMount = useRef(true);

  const { subscribeToMore, loading, error, data, refetch, fetchMore } =
    useQuery(GET_LANDINGLOGS, {
      variables: {
        offset: 0,
        limit: rowsPerPage,
        order: sortOrder,
        from: from,
      },
    });

  useEffect(() => {
    refetch();
  }, [refetch, rowsPerPage, sortOrder, from]);

  const loadPage = () => {
    console.log("loadPage", data.landingLogsAdmin.length);
    fetchMore({
      variables: {
        offset: data.landingLogsAdmin.length,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };

  const sortCreatedDate = (event) => {
    setSortOrder(event.target.value);
    refetch({
      variables: {
        order: event.target.value,
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { loading: loadingAdmin, data: dataAdmin } = useQuery(GET_ACTIVE_ADMIN);

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  return (
    <CustomerUI
      {...data}
      history={props.history}
      rowsPerPage={rowsPerPage}
      page={page}
      sortOrder={sortOrder}
      sortCreatedDate={sortCreatedDate}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      refetch={refetch}
      setFrom={setFrom}
    />
  );
}

const CustomerUI = (props) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Newsletter Email List</Typography>
          </Breadcrumbs>
        </div>
        <div className="right">
          <Button
            variant="outlined"
            sx={{ "&:disabled": { color: "#cbcbcb", borderColor: "#cbcbcb" } }}
            disabled
          >
            Export(Coming Soon)
          </Button>
        </div>
      </div>
      <FromFind setFrom={props.setFrom} />

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          {Object.keys(dialogData).map((key) => {
            return (
              <div>
                <Typography>
                  <strong>{key}:</strong> {dialogData[key]}
                </Typography>
              </div>
            );
          })}
        </DialogContent>
      </Dialog>
      <ul>
        {
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">From</TableCell>
                  <TableCell align="left">Created Date</TableCell>
                  <TableCell align="left">Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.landingLogsAdmin
                  .slice(
                    props.page * props.rowsPerPage,
                    props.page * props.rowsPerPage + props.rowsPerPage
                  )
                  .map((log) => {
                    return (
                      <TableRow key={log.id}>
                        <TableCell component="th" scope="row">
                          {log.data.email}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {log.data.page}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {moment(parseInt(log.createdAt)).format("MM/DD/YYYY")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <IconButton
                            aria-label="details"
                            onClick={() => {
                              setDialogData(log.data);
                              setOpen(true);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.landingLogsCount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      </ul>
    </div>
  );
};

export default Customers;
