import AddIcon from "@mui/icons-material/Add";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import QuizIcon from "@mui/icons-material/Quiz";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import {
  Grid,
  ListItemButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  FEEDREAL_CONTENT,
  FEEDREAL_LOGO,
  FINAL_EXAM,
  QUIZ_CONTENT,
  TEXT_EDITOR_CONTENT,
  VIDEO_CONTENT,
} from "../../../../globals";
import FeedrealTutorial from "./FeedrealTutorial";
import QuizTutorial from "./QuizTutorial";
import TutorialTextEditor from "./TutorialTextEditor";
import TutorialTitle from "./TutorialTitle";
import TutorialVideoContentEditor from "./TutorialVideoEditor";
import * as React from "react";
import QuizTutorialDialog from "./QuizTutorialDialog";

const MIN_TITLE_LENGTH = 10;
const MIN_YOUTUBE_LINK = 10;

const minutes = new Array(100).fill(0).map((item, index) => {
  return {
    value: index + 1,
    label: index + 1,
  };
});

const baseComponent = {
  type: "",
  title: "",
  content: {},
  text: "",
};

const FeedrealIcon = () => {
  return (
    <img
      style={{
        width: "100%",
      }}
      src={FEEDREAL_LOGO}
    ></img>
  );
};

export const tutorialTypes = [
  {
    type: FEEDREAL_CONTENT,
    title: "Feedreal Content",
    icon: FeedrealIcon,
  },
  {
    type: TEXT_EDITOR_CONTENT,
    title: "Text Editor",
    icon: TextFormatIcon,
  },
  {
    type: VIDEO_CONTENT,
    title: "Video Content",
    icon: OndemandVideoIcon,
  },
  {
    type: QUIZ_CONTENT,
    title: "Quiz Content",
    icon: QuizIcon,
  },
];

export default function AddTutorialDialog({
  update = false,
  create = false,
  onAddTutorial,
  selectedTutorial = {},
  disabled = false,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [tutorial, setTutorial] = useState(baseComponent);
  const [title, setTitle] = useState("");
  const [countdown, setCountdown] = useState(20);
  const [numberOfQuestions, setNumberOfQuestions] = useState(60);
  const [questionsCount, setQuestionsCount] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSelectFeedrealArticle = (content) => {
    const obj = {
      ...baseComponent,
      content,
      title: content.title,
      type: FEEDREAL_CONTENT,
    };
    setTitle(content.title);
    setTutorial(obj);
  };
  const onChangeTextContent = (text, simpleTitle) => {
    const obj = {
      ...baseComponent,
      title: simpleTitle,
      text: text,
      type: TEXT_EDITOR_CONTENT,
    };
    setTutorial(obj);
  };
  const onChangeVideoContent = (text, simpleTitle) => {
    const obj = {
      ...baseComponent,
      title: simpleTitle,
      text: text,
      type: VIDEO_CONTENT,
    };
    setTutorial(obj);
  };

  const setQuiz = (content) => {
    // const obj = {
    //   ...baseComponent,
    //   content,
    //   title: content.title,
    //   type: QUIZ_CONTENT,
    // };
    onAddTutorial(content);
    handleClose();
  };
  const onSetCountdown = (value) => {
    const obj = JSON.parse(JSON.stringify(tutorial));
    obj.content.countdown = value;
    setTutorial(obj);
  };

  const onSetNumberOfQuestions = (value) => {
    const obj = JSON.parse(JSON.stringify(tutorial));
    obj.content.numberOfQuestions = value;
    setTutorial(obj);
  };

  const onAddNode = () => {
    if (isValid()) {
      onAddTutorial(tutorial);
      handleClose();
    }
  };

  // useEffect(() => {
  //   if (tutorial.type === QUIZ_CONTENT) {
  //     setQuestionsCount(
  //       new Array(tutorial.content.questions.length)
  //         .fill(0)
  //         .map((item, index) => index)
  //     );
  //   }
  // }, [tutorial]);

  const isValid = () => {
    if (title?.length < MIN_TITLE_LENGTH) {
      enqueueSnackbar(`Tutorial title min length ${MIN_TITLE_LENGTH}`, {
        variant: "error",
      });
      return false;
    }
    if (tutorial.type === VIDEO_CONTENT) {
      const { youtubeLink } = JSON.parse(tutorial.text);
      if (youtubeLink.length < MIN_YOUTUBE_LINK) {
        enqueueSnackbar(`Please fill the youtube link`, {
          variant: "error",
        });
        return false;
      }
    }
    return true;
  };

  const [addButtonDisabled, setAddButtonDisabled] = useState(false);

  return (
    <div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Button
          variant="outlined"
          aria-controls={openAnchor ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openAnchor ? "true" : undefined}
          onClick={handleClick}
          disabled={disabled}
          startIcon={<AddIcon />}
          color={"secondary"}
          fullWidth
        >
          Add Node
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openAnchor}
          onClose={handleAnchorClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          fullWidth
        >
          {tutorialTypes.map((tutorialType) => {
            return (
              <MenuItem
                className={
                  selectedType === tutorialType.type && "selected-tutorial-type"
                }
                onClick={() => {
                  setSelectedType(tutorialType.type);
                  setOpen(true);
                  setTitle("");
                  handleAnchorClose();
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <tutorialType.icon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={tutorialType.title} />
              </MenuItem>
            );
          })}
        </Menu>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={12}
                style={{
                  paddingTop: 10,
                }}
              >
                {selectedType === "" && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "20%",
                    }}
                  >
                    <Typography variant="h4">
                      please select a type first on right list
                    </Typography>
                  </div>
                )}
                {selectedType === FEEDREAL_CONTENT && (
                  <>
                    <FeedrealTutorial
                      article={tutorial.content}
                      onSelectArticle={onSelectFeedrealArticle}
                    />
                  </>
                )}
                {selectedType === TEXT_EDITOR_CONTENT && (
                  <>
                    <TutorialTitle
                      title={title}
                      setTitle={setTitle}
                      placeHolder={"Title"}
                    />
                    <TutorialTextEditor
                      onChangeNodeContent={(data) => {
                        onChangeTextContent(data, title);
                      }}
                      setDisabled={setAddButtonDisabled}
                      // rawContent={tutorial.text || ""}
                    />
                  </>
                )}
                {selectedType === VIDEO_CONTENT && (
                  <>
                    <TutorialTitle
                      title={title}
                      setTitle={setTitle}
                      placeHolder={"Title"}
                    />
                    <TutorialVideoContentEditor
                      onChangeNodeContent={(data) => {
                        onChangeVideoContent(data, title);
                      }}
                      setDisabled={setAddButtonDisabled}
                      // rawContent={tutorial.text}
                    />
                  </>
                )}

                {selectedType === QUIZ_CONTENT && (
                  <>
                    <QuizTutorialDialog
                      addQuiz={(data) => {
                        setQuiz(data);
                      }}
                      close={() => {
                        handleClose();
                      }}
                    />
                    {/* <QuizTutorial quiz={tutorial.content} setQuiz={setQuiz} /> */}
                    <br></br>
                  </>
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        {selectedType !== QUIZ_CONTENT && (
          <DialogActions>
            <Button onClick={handleClose}>CLOSE</Button>
            <Button
              onClick={() => {
                onAddNode();
              }}
              disabled={
                (selectedType === TEXT_EDITOR_CONTENT ||
                  selectedType === VIDEO_CONTENT) &&
                addButtonDisabled
              }
            >
              {update ? "Update" : "Add Node"}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
