import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

const WebsiteCategories = ({ website, setWebsite }) => {
  return (
    <TextField
      id="outlined-select-currency"
      select
      label="Select Website"
      fullWidth
      value={website}
      onChange={(e) => {
        setWebsite(e.target.value);
      }}
    >
      <MenuItem disabled={true} value={"Select"}>
        Select
      </MenuItem>
      <MenuItem value={"shortleash.co"}>Shortleash.co</MenuItem>
      <MenuItem value={"canine.care"}>Canine.care</MenuItem>
      <MenuItem value={"feedreal.com"}>Feedreal.com</MenuItem>
      <MenuItem value={"real.dog"}>Real.dog</MenuItem>
    </TextField>
  );
};
export default WebsiteCategories;
