import React, { useState } from "react";
import { Button, TextField, CircularProgress } from "@mui/material";
import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CREATE_ROOM_NOTES } from "../../../../queries";
import { useMutation } from "@apollo/client";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";

const TakeNote = ({ room, getNotes, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const [type, setType] = useState(false); //false = upgrade true = issue

  const [saveNote, { data, loading, error }] = useMutation(CREATE_ROOM_NOTES);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (note.length > 0) {
      saveNote({
        variables: {
          input: {
            note: note,
            type: type ? "issue" : "upgrade",
            roomId: room.id,
            createdAt: moment().unix(),
          },
        },
      })
        .then(({ data }) => {
          console.log("data", data);
          getNotes();
          refetch();
          handleClose();
        })
        .catch((e) => {
          enqueueSnackbar(`${e.message}`, {
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar(`Please type a note.`, {
        variant: "error",
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Button fullWidth variant="outlined" onClick={handleClickOpen}>
        Take Notes
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${room.name} Take A Note`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Upgrade</Typography>
              <Switch
                color="primary"
                checked={type}
                onClick={() => {
                  setType(!type);
                }}
              />
              <Typography>Issue</Typography>
            </Stack>
            <TextField
              onChange={(e) => setNote(e.target.value)}
              variant="outlined"
              margin="normal"
              value={note}
              required
              fullWidth
              name="note"
              label="Note"
              type="text"
              id="note"
              multiline
              rows={4}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleSave} color="primary" disabled={loading}>
            {loading ? <CircularProgress /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TakeNote;
