import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const TableEditor = ({ onChangeNodeContent, rawContent }) => {
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (rawContent) {
      const content = JSON.parse(rawContent);
      if (content.cols) {
        setCols(content.cols);
      }
      if (content.rows) {
        setRows(content.rows);
      }
    }
  }, []);
  useEffect(() => {
    console.log(cols);
    console.log(rows);
  });
  useEffect(() => {
    let timer = setTimeout(() => {
      onChangeNodeContent(JSON.stringify({ cols, rows }));
    }, 700);
    return () => clearTimeout(timer);
  }, [rows, cols]);
  const onAddCols = () => {
    let copy = JSON.parse(JSON.stringify(cols));
    copy.push("");
    setCols(copy);
    let copyRows = JSON.parse(JSON.stringify(rows));
    copyRows.map((item) => {
      item.push("");
    });
    setRows(copyRows);
  };
  const onAddRows = () => {
    let copy = JSON.parse(JSON.stringify(rows));
    let arr = new Array(cols.length).fill("");
    copy.push(arr);
    setRows(copy);
  };

  const onRemoveCols = (index) => {
    let copyCols = JSON.parse(JSON.stringify(cols));
    copyCols.splice(index, 1);
    let copyRows = JSON.parse(JSON.stringify(rows));
    copyRows.map((item) => {
      item.splice(index, 1);
    });
    setCols(copyCols);
    setRows(copyRows);
  };
  const onRemoveRow = (index) => {
    let copy = JSON.parse(JSON.stringify(rows));
    copy.splice(index, 1);
    setRows(copy);
  };

  const setRowCell = (text, rowIndex, colIndex) => {
    let copy = JSON.parse(JSON.stringify(rows));
    copy[rowIndex][colIndex] = text;
    setRows(copy);
  };
  const setColCell = (text, colIndex) => {
    let copy = JSON.parse(JSON.stringify(cols));
    copy[colIndex] = text;
    setCols(copy);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead style={{ backgroundColor: "darkslategray" }}>
            <TableRow>
              {cols.map((col, colIndex) => {
                return (
                  <TableCell>
                    {/* <TextField
                      id="outlined-basic"
                      label="Outlined"
                      variant="outlined"
                      fullWidth
                      value={col}
                      onChange={(e) => {
                        setColCell(e.target.value, colIndex);
                      }}
                    /> */}
                    <FormControl
                      sx={{ m: 1, width: "25ch" }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        {"Header Column " + (colIndex + 1)}
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        value={col}
                        onChange={(e) => {
                          setColCell(e.target.value, colIndex);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <DeleteCol
                              onDeleteCol={() => {
                                onRemoveCols(colIndex);
                              }}
                              col={col}
                            />
                          </InputAdornment>
                        }
                        label={"Header Column " + (colIndex + 1)}
                      />
                    </FormControl>
                  </TableCell>
                );
              })}
              <TableCell align="right">
                <Button onClick={() => onAddCols()}>Add Column</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {row.map((cell, colIndex) => {
                  return (
                    <TableCell component="th" scope="row">
                      <TextField
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                        value={cell}
                        onChange={(e) => {
                          setRowCell(e.target.value, rowIndex, colIndex);
                        }}
                      />
                    </TableCell>
                  );
                })}
                <TableCell align="right">
                  <Button
                    onClick={() => {
                      onRemoveRow(rowIndex);
                    }}
                  >
                    Delete Row
                  </Button>
                </TableCell>
                {/*  <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            onClick={() => {
              onAddRows();
            }}
          >
            Add Row
          </Button>
        </Grid>
      </TableContainer>
    </div>
  );
};
export default TableEditor;

const DeleteCol = ({ onDeleteCol, col }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickOpen}
        edge="end"
      >
        <DeleteIcon color="error" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure You want to delete {col}.<br></br>
            If you delete this Column, you will delete every corresponding cells
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              onDeleteCol();
              handleClose();
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
