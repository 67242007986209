import { useQuery } from "@apollo/client";
import React from "react";
import { GET_ALL_TICKETS, GET_TICKET_BY_ID } from "../../../queries";
// import TicketCreateUpdate from "./TicketCreateUpdate";
import { Chip, Grid, Link, Paper, Typography } from "@mui/material";
import LinearLoading from "../LinearLoading";
import TicketCreateUpdate from "./TicketCreateUpdate";
import GiveTicket from "./GiveTicket";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const Ticket = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_TICKETS, {
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <div>loading . . . </div>;
  }
  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Tickets</Typography>
          </Breadcrumbs>
        </div>
        <div className="right">
          <Link href="/dashboard/ticket/history">Ticket Purchase List</Link>
        </div>
      </div>
      {data.getTickets.map((item) => {
        return <SingleTicketItem id={item.id} />;
      })}
      <TicketCreateUpdate refetch={refetch} />
    </div>
  );
};

export default Ticket;

const SingleTicketItem = ({ id }) => {
  const { loading, data, error, refetch } = useQuery(GET_TICKET_BY_ID, {
    variables: { id: id },
    fetchPolicy: "no-cache",
  });
  if (loading) {
    return <LinearLoading />;
  }
  if (error) {
    return <div>Error</div>;
  }
  const ticketItem = data.getTicketById;
  return (
    <Paper
      elevation={0}
      style={{
        padding: 20,
        maxWidth: 600,
        position: "relative",
        marginBottom: 10,
      }}
    >
      <TicketCreateUpdate id={id} refetch={refetch} />
      {/* {JSON.stringify(data.getTicketById)} */}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={4} style={{ padding: 20 }}>
          <img src={ticketItem.img} style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={8} style={{ padding: 20 }}>
          <Typography>
            <b>Name: </b> {ticketItem.name}{" "}
          </Typography>
          <Typography>
            <b>Description: </b> {ticketItem.description}{" "}
          </Typography>
          <Typography>
            <b>Discount: </b> {ticketItem.discount}{" "}
          </Typography>
          <Typography>
            <b>Price: </b> {ticketItem.rawPrice}{" "}
          </Typography>
          <Typography>
            <b>Discounted Price: </b> {ticketItem.price}{" "}
          </Typography>
          <Typography>
            <b>Quota </b> {ticketItem.quota}{" "}
          </Typography>
          <Typography>
            <b>Active: </b>
            {ticketItem.status ? (
              <Chip label="online" color="success" />
            ) : (
              <Chip label="offline" color="error" />
            )}
          </Typography>
          {ticketItem.quota !== 0 && ticketItem.status && (
            <GiveTicket ticketItem={ticketItem} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
