import { gql } from "@apollo/client";

const CREATE_QR = gql`
  mutation ($link: String!, $createdAt: Int!) {
    createQr(data: { link: $link, createdAt: $createdAt }) {
      id
    }
  }
`;

const GET_QR_CODES = gql`
  query {
    qrcodes {
      id
      link
      createdAt
    }
  }
`;

export { CREATE_QR, GET_QR_CODES };
