import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

export default function SubscriptionListItem({
  dogName,
  status,
  planNickName,
  quantity,
  timeAndDate,
  shippingMembership,
  editButton = () => {},
  editAddress,
}) {
  return (
    <Grid
      container
      spacing={0}
      style={{
        background: "#292b32",
        borderRadius: 5,
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 20,
        borderBottom: "1px solid #000",
      }}
    >
      <Grid
        item
        xs={3}
        style={{ borderRight: "1px solid #474b54", padding: 10 }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {dogName && (
            <Typography
              style={{
                color: "#fff",
                borderLeft: "3px solid #2196f3",
                padding: "2px 10px",
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "3px",
                marginRight: 5,
                textAlign: "left",
                width: "100%",
              }}
            >
              {dogName}
            </Typography>
          )}
          <div style={{ marginTop: 10, marginBottom: 10, width: "100%" }}>
            <div className={status}>{status}</div>
          </div>
          {shippingMembership.map((item) => {
            if (item.type === "shipping") {
              return (
                <div className="shippingbadge">Shipping ${item.price}</div>
              );
            }
            if (item.type === "membership") {
              return (
                <div className="premiumbadge">Membership ${item.price}</div>
              );
            }
            return <div></div>;
          })}
        </Grid>
      </Grid>
      <Grid item xs={9} style={{ padding: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} style={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: "100%" }}
            >
              <Grid item xs={10}>
                {planNickName?.map((item, index) => (
                  <b className={planNickName[index] ? "" : "green"}>
                    {` ${planNickName[index] ? quantity[index] + " x " : ""}${
                      planNickName[index] || "Membership"
                    }`}{" "}
                    <br></br>{" "}
                  </b>
                ))}
              </Grid>
              <Grid item xs={1}>
                {!shippingMembership.find(
                  (item) => item.type === "membership"
                ) && editAddress()}
              </Grid>
              <Grid item xs={1}>
                {!shippingMembership.find(
                  (item) => item.type === "membership"
                ) && editButton()}
              </Grid>
            </Grid>
          </Grid>

          {timeAndDate()}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
              marginTop: -10,
            }}
          >
            <Grid item xs={3}></Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
