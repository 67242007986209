import { useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Link, useHistory } from "react-router-dom";
import { GET_ACTIVE_ADMIN, GET_CUSTOMERS } from "../../../queries";
import CreateNewCustomer from "./Components/CreateNewCustomer";
import CustomerFind from "./Components/CustomerFind";
import CustomerSum from "./CustomerSum";

function Customers(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");

  const isInitialMount = useRef(true);

  const { subscribeToMore, loading, error, data, refetch, fetchMore } =
    useQuery(GET_CUSTOMERS, {
      variables: {
        offset: 0,
        limit: rowsPerPage,
        order: sortOrder,
      },
    });

  useEffect(() => {
    refetch();
  }, [refetch, rowsPerPage, sortOrder]);

  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.customers.length,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };

  const sortCreatedDate = (event) => {
    setSortOrder(event.target.value);
    refetch({
      variables: {
        order: event.target.value,
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { loading: loadingAdmin, data: dataAdmin } = useQuery(GET_ACTIVE_ADMIN);

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  return (
    <CustomerUI
      {...data}
      history={props.history}
      rowsPerPage={rowsPerPage}
      page={page}
      sortOrder={sortOrder}
      sortCreatedDate={sortCreatedDate}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      refetch={refetch}
    />
  );
}

const CustomerUI = (props) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Customers</Typography>
          </Breadcrumbs>
        </div>
        <div className="right">
          <CreateNewCustomer refetch={props.refetch} />
        </div>
      </div>
      <div>
        <CustomerFind {...props} />
      </div>
      <ul>
        {
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Sum</TableCell>
                  <TableCell align="left">RDM</TableCell>
                  <TableCell align="left">Created Date</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell align="left">Email/Phone</TableCell>
                  {/* <TableCell align="left">Phone/</TableCell> */}

                  <TableCell align="left">Username</TableCell>
                  <TableCell align="left">Subscription</TableCell>
                  <TableCell align="left">Referrer</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.customers
                  .slice(
                    props.page * props.rowsPerPage,
                    props.page * props.rowsPerPage + props.rowsPerPage
                  )
                  .map((customer) => {
                    return (
                      <TableRow key={customer.id}>
                        <TableCell component="th" scope="row">
                          <CustomerSum customer={customer} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {customer.premium === 1 ? (
                            <Tooltip title={"Real Dog Membership"}>
                              <StarIcon
                                style={{
                                  color: "#ffc107",
                                }}
                              />
                            </Tooltip>
                          ) : customer.feedrealPremium ? (
                            <Tooltip title={"Feedreal Membership"}>
                              <StarHalfIcon
                                style={{
                                  color: "#ffc107",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <StarBorderIcon />
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {moment(parseInt(customer.created_at) * 1000).format(
                            "MM/DD/YYYY"
                          )}
                        </TableCell>
                        <TableCell component="th" align="left" scope="row">
                          {customer.name}
                        </TableCell>
                        <TableCell component="th" align="left" scope="row">
                          {customer.email}
                          <br></br>
                          {customer.phone_number}
                        </TableCell>

                        <TableCell component="th" align="left" scope="row">
                          {customer.username}
                        </TableCell>
                        <TableCell component="th" align="left" scope="row">
                          {customer.stripe &&
                          customer.stripe.subscription.length > 0 ? (
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className="greenborder"
                              disabled
                            >
                              <b className="greenborder">Active</b>
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="secondary"
                              className="redborder"
                              disabled
                              size="small"
                            >
                              <b className="redborder">Inactive</b>
                            </Button>
                          )}
                        </TableCell>
                        <TableCell component="th" align="left" scope="row">
                          {customer.ref && (
                            <Link
                              variant="text"
                              // color="tertiary"
                              // className="redborder"
                              className="linkcolorwhite"
                              to={`/dashboard/customer/${customer.ref.id}`}
                              size="small"
                            >
                              <b className="redborder">
                                {customer.ref.username}
                              </b>
                            </Link>
                          )}
                        </TableCell>
                        <TableCell component="th" align="left" scope="row">
                          <Button
                            className="redborder"
                            variant="outlined"
                            onClick={() => {
                              history.push(
                                `/dashboard/customer/${customer.id}`
                              );
                              // if (customer.created_at <= 1642718118) {
                              //   history.push(
                              //     `/dashboard/customer/${customer.id}`
                              //   );
                              // } else {
                              //   enqueueSnackbar(
                              //     "Edit section is disabled for this user now ",
                              //     {
                              //       variant: "error",
                              //     }
                              //   );
                              // }
                            }}
                            size="small"
                          >
                            <b className="redborder">Details</b>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.customersCount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      </ul>
    </div>
  );
};

export default Customers;
