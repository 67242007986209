import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useHistory } from "react-router-dom";

export default function AutoCompleteSearch({ list }) {
  const history = useHistory();
  // console.log(list);
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={list.map((item) => {
        return {
          ...item,
          label: item.title,
        };
      })}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Type here to seach..."
          onSelect={(e) => {
            let conditionItem = list.find(
              (item) => item.title === e.target.value
            );
            if (conditionItem) {
              history.push(`/dashboard/glossary/edit/${conditionItem.id}`);
            }
          }}
        />
      )}
    />
  );
}
