import { useQuery } from "@apollo/client";
import Edit from "@mui/icons-material/Edit";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET_PAGES } from "../../../queries";
import AutoSearch from "./Components/searchPage";

function ProductPage(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [website, setWebsite] = useState();
  // query get data
  const { subscribeToMore, loading, error, data, refetch, fetchMore } =
    useQuery(GET_PAGES, {
      variables: {
        limit: rowsPerPage,
        offset: 0,
      },
    });

  useEffect(() => {
    fetchMore({
      variables: {
        limit: rowsPerPage,
        offset: 0,
        website: website,
      },
    });
  }, [website]);

  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.getPagesAdmin.length,
        limit: rowsPerPage,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;
  const { permission } = props.session.activeAdmin;
  console.log("data", data);
  return (
    <div>
      {data.getPagesAdmin.length > 0 ? (
        <PageUI
          {...data}
          permission={permission}
          loading={loading}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refetch={refetch}
          history={props.history}
          setWebsite={setWebsite}
        />
      ) : (
        ""
      )}
    </div>
  );
}
const PageUI = (props) => {
  console.log(props);
  const [god, setGod] = useState(false);
  const [author, setAuthor] = useState(false);
  const [now] = useState(moment().unix());
  const diff = 7776000; //90days

  useEffect(() => {
    setGod(props.permission.god ? props.permission.god.admin : false);
    setAuthor(props.permission.author ? props.permission.author : false);
  }, [props.permission]);

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Product Pages</Typography>
          </Breadcrumbs>
        </div>
        {god || author.admin || author.write ? (
          <div className="right">
            <Button
              component={Link}
              variant="outlined"
              color="secondary"
              to={"/dashboard/page/add"}
            >
              Add Page
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        <AutoSearch
          history={props.history}
          edit={god || author.admin || author.write}
          setWebsite={props.setWebsite}
        />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ul>
            {
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Webpage</TableCell>
                      <TableCell>Author</TableCell>
                      <TableCell>Updated Date</TableCell>
                      {god || author.admin || author.write ? (
                        <TableCell></TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.getPagesAdmin && props.getPagesAdmin.length > 0 ? (
                      props.getPagesAdmin
                        .slice(
                          props.page * props.rowsPerPage,
                          props.page * props.rowsPerPage + props.rowsPerPage
                        )
                        .map((page) => {
                          return (
                            <TableRow key={page.id}>
                              <TableCell
                                component="th"
                                scope="row"
                                className={
                                  now - page.updatedAt > diff &&
                                  "red-line tooltip"
                                }
                              >
                                {page.title}
                                {now - page.updatedAt > diff && (
                                  <span className="tooltiptext">
                                    This article has not been updated for 3
                                    months.
                                  </span>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <div
                                  className={
                                    page.status ? "published" : "archived"
                                  }
                                >
                                  {page.status ? "Published" : "Archived"}
                                </div>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {page.category}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {page.website}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {page.admin.name}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {moment
                                  .unix(page.updatedAt)
                                  .format("MM/DD/YYYY")}
                              </TableCell>
                              {god || author.admin || author.write ? (
                                <TableCell component="th" scope="row">
                                  <Button
                                    component={Link}
                                    variant="outlined"
                                    color="secondary"
                                    to={"/dashboard/page/edit/" + page.id}
                                    target="_blank"
                                  >
                                    <Edit />
                                  </Button>
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </ul>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.pagesCount ? props.pagesCount : 0}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductPage;
