import { useQuery } from "@apollo/client";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET_QUIZZES } from "../../../queries";
import AddQuizDialog from "./components/AddQuizDialog";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import { FINAL_EXAM, MULTIPLE_CHOICE, QUESTION_ANSWER } from "../../../globals";

const Quizzes = (props) => {
  const [god, setGod] = useState(false);
  const [author, setAuthor] = useState(false);

  useEffect(() => {
    const { permission } = props.session.activeAdmin;
    setGod(permission.god ? permission.god.admin : false);
    setAuthor(permission.author ? permission.author : false);
  }, [props.session]);

  const { loading, data, error } = useQuery(GET_QUIZZES, {
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <LinearProgress></LinearProgress>;
  }

  return (
    <Container>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to={"/"}>
              Dashboard
            </Link>
            <Link color="inherit" to={"/dashboard/course"}>
              Course
            </Link>
            <Link color="inherit" to={"/dashboard/course/questions"}>
              Questions
            </Link>
            <Typography color="textPrimary">Quiz Pages</Typography>
          </Breadcrumbs>
        </div>
        <div className="right">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            {/*    <Link
              to={"/dashboard/course/questions"}
              style={{
                marginRight: 10,
                textDecoration: "none",
              }}
            >
              <Button color="secondary" variant="">
                View All Questions
              </Button>
            </Link> */}
            {/* <AddQuizDialog /> */}
          </Grid>
        </div>
      </div>
      <List>
        {data?.getQuizzes.map((item) => {
          let secondary = "";
          console.log(item);
          if (item.quizType === MULTIPLE_CHOICE) {
            secondary = "Multiple Choice";
          }
          if (item.quizType === QUESTION_ANSWER) {
            secondary = "Question Answer";
          }
          if (item.quizType === FINAL_EXAM) {
            secondary = "Final Exam";
          }
          return (
            <ListItem
              secondaryAction={
                <Link
                  key={item.id}
                  style={{ color: "white" }}
                  to={"/dashboard/course/questions/quiz/edit/" + item.id}
                >
                  <IconButton edge="end" aria-label="delete">
                    <EditIcon />
                  </IconButton>
                </Link>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item.title} secondary={secondary} />
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};

export default Quizzes;
