import React, { useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import { CUSTOMER_TICKET } from "../../../queries";
import LinearLoading from "../LinearLoading";
import { Button, Link, IconButton, Dialog, Typography } from "@mui/material";
import TicketAttendance from "./TicketAttendance";
import TicketNumberVerify from "./TicketNumberVerify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const TicketHistory = () => {
  const { loading, data, error, refetch } = useQuery(CUSTOMER_TICKET, {
    fetchPolicy: "no-cache",
  });
  console.log(loading, data, error);
  if (loading) {
    return <LinearLoading />;
  }
  if (error) {
    return <div>Error</div>;
  }
  return (
    <div>
      <TicketNumberVerify refetch={refetch} />
      <GistoryGrid data={data.customersTickets} refetch={refetch} />
    </div>
  );
};

function GistoryGrid({ data, refetch }) {
  const [detail, setDetail] = useState();
  const [openDetails, setOpenDetails] = useState(false);
  const columns = [
    {
      field: "attendance",
      headerName: "Attendance",
      renderCell: (params) => {
        return (
          <TicketAttendance
            ticket={params.row}
            refetch={() => {
              console.log(params.row);
              refetch();
            }}
          />
        );
      },
      width: 140,
    },
    {
      field: "details",
      headerName: "Details",
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton
            color="primary"
            onClick={() => {
              console.log("rowww", params.row);
              setDetail(params.row);
              setOpenDetails(true);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        );
      },
    },
    {
      field: "ticketNumber",
      headerName: "Ticket Number",
      width: 160,
    },
    {
      field: "ticketName",
      headerName: "Ticket Name",
      width: 100,
    },
    {
      field: "buyer",
      headerName: "Buyer",
      width: 160,
      renderCell: (params) => {
        console.log("params", params);
        return (
          <Link
            href={`/dashboard/customer/${params.row.buyer.id}`}
            style={{
              color: "#fff",
              cursor: "pointer",
            }}
          >
            {params.row.buyer.email}
          </Link>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      type: "number",
    },
  ];
  const rowsData = data.map((item) => {
    return {
      id: item.id,
      name:
        item.guest.firstName && item.guest.lastName
          ? item.guest.firstName + " " + item.guest.lastName
          : "Not Set",
      buyer: { ...item.customer },
      email: item.guest.email || "Not Set",
      phone_number: item.guest.phone || "Not Set",
      attendance: item.attendance,
      ticketNumber: item.ticketNumber,
      ticketName: item.ticketId.name,
      title: item.guest.title,
      question: item.guest.question,
    };
  });
  console.log(rowsData);
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Details
        open={openDetails}
        handleClose={() => {
          setOpenDetails(false);
        }}
        data={detail}
      />

      <DataGrid
        rows={rowsData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}
export default TicketHistory;

const Details = ({ data, open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Details"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>
            <strong>Ticket Name:</strong> {data?.ticketName}
          </Typography>
          <Typography>
            <strong>Ticket Number:</strong> {data?.ticketNumber}
          </Typography>
          <Typography>
            <strong>Buyer:</strong> {data?.buyer?.email}
          </Typography>
          <Typography>
            <strong>Ticket Detail</strong>
          </Typography>
          <Typography>
            <strong>Name:</strong> {data?.name || "Not Set"}
          </Typography>
          <Typography>
            <strong>Email:</strong> {data?.email || "Not Set"}
          </Typography>
          <Typography>
            <strong>Phone Number:</strong> {data?.phone_number || "Not Set"}
          </Typography>
          <Typography>
            <strong>Title:</strong> {data?.title || "Not Set"}
          </Typography>
          <Typography>
            <strong>Question:</strong> {data?.question || "Not Set"}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
