import { useQuery } from "@apollo/client";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import { Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MULTIPLE_CHOICE } from "../../../globals";
import { GET_QUESTIONS_ADMIN } from "../../../queries";
import QuestionDialog from "./components/QuestionDialog";
import AutoSearch from "./components/SearchQuestion";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const Questions = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // query get data
  const { subscribeToMore, loading, error, data, refetch, fetchMore } =
    useQuery(GET_QUESTIONS_ADMIN, {
      variables: {
        limit: rowsPerPage,
        offset: 0,
      },
    });
  const loadPage = () => {
    fetchMore({
      variables: {
        offset: data.getQuestionsAdmin.length,
        limit: rowsPerPage,
      },
    });
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await loadPage();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  const { permission } = props.session.activeAdmin;
  console.log("data", data);

  return (
    <div>
      {data.getQuestionsAdmin.length > 0 ? (
        <PageUI
          {...data}
          permission={permission}
          loading={loading}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refetch={refetch}
          history={props.history}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const PageUI = (props) => {
  const [god, setGod] = useState(false);
  const [author, setAuthor] = useState(false);
  const [now] = useState(moment().unix());
  const diff = 7776000; //90days
  const [selectedQuestion, setSelectedQuestion] = useState();

  useEffect(() => {
    setGod(props.permission.god ? props.permission.god.admin : false);
    setAuthor(props.permission.author ? props.permission.author : false);
  }, [props.permission]);

  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={"/"}>Dashboard</Link>
            <Link to={"/dashboard/course"}>Course</Link>
            <Typography color="textPrimary">Questions</Typography>
          </Breadcrumbs>
        </div>

        {god || author.admin || author.write ? (
          <div className="right">
            <QuestionDialog create={true} refetch={props.refetch} />
          </div>
        ) : (
          ""
        )}
        {god || author.admin || author.write ? (
          <div className="right" style={{ marginRight: "10px" }}>
            <Link
              style={{
                textDecoration: "none",
              }}
              to={"/dashboard/course/questions/quiz"}
            >
              <Button
                variant="outlined"
                sx={{ borderColor: "#282b32 !important" }}
              >
                Archive Quizzes
              </Button>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        <AutoSearch
          history={props.history}
          edit={god || author.admin || author.write}
          refetch={props.refetch}
          setSelectedQuestion={setSelectedQuestion}
        />
        {selectedQuestion && (
          <QuestionDialog
            update={true}
            originalQuestionItem={selectedQuestion}
            refetch={props.refetch}
            setDialog={true}
          />
        )}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ul>
            {
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Question</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Question Type</TableCell>
                      {god || author.admin || author.write ? (
                        <TableCell></TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.getQuestionsAdmin &&
                    props.getQuestionsAdmin.length > 0 ? (
                      props.getQuestionsAdmin
                        .slice(
                          props.page * props.rowsPerPage,
                          props.page * props.rowsPerPage + props.rowsPerPage
                        )
                        .map((page) => {
                          console.log("page", page);
                          return (
                            <TableRow key={page.id}>
                              <TableCell
                                component="th"
                                scope="row"
                                className={
                                  now - page.updatedAt > diff &&
                                  "red-line tooltip"
                                }
                              >
                                {page.question.length > 45
                                  ? page.question.slice(0, 42) + "..."
                                  : page.question}
                              </TableCell>
                              <TableCell align="center">
                                <div
                                  className={
                                    page.status ? "published" : "archived"
                                  }
                                >
                                  {page.status ? "Published" : "Archived"}
                                </div>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ textAlign: "center" }}
                              >
                                {page.questionType === MULTIPLE_CHOICE ? (
                                  <FormatListNumberedIcon
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                ) : (
                                  <SpaceBarIcon
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                )}
                              </TableCell>
                              {god || author.admin || author.write ? (
                                <TableCell component="th" scope="row">
                                  <QuestionDialog
                                    update={true}
                                    originalQuestionItem={page}
                                    refetch={props.refetch}
                                  />
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </ul>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.questionsCount ? props.questionsCount : 0}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Questions;
