import { useQuery } from "@apollo/client";
import Edit from "@mui/icons-material/Edit";
import {
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createRandKey } from "../../../globals";
import { GET_COURSES } from "../../../queries";
import UnitsDialog from "./components/Units";
import TransactionList from "./components/TransactionList";

import CourseDialog from "./CourseDialog";

const Courses = (props) => {
  const [god, setGod] = useState(false);
  const [openTransaction, setOpenTransaction] = useState(false);
  const [author, setAuthor] = useState(false);

  useEffect(() => {
    const { permission } = props.session.activeAdmin;
    setGod(permission.god ? permission.god.admin : false);
    setAuthor(permission.author ? permission.author : false);
  }, [props.session]);

  const handleOpenTransaction = () => {
    setOpenTransaction(!openTransaction);
  };
  const { loading, data, error, refetch } = useQuery(GET_COURSES, {
    fetchPolicy: "no-cache",
  });
  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <Typography>Error</Typography>;
  }
  return (
    <Container>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Course</Typography>
          </Breadcrumbs>
        </div>
        <div className="right">
          <CourseDialog refetch={refetch} />
          {/* <Link
            style={{
              textDecoration: "none",
            }}
            to={"/dashboard/course/add"}
          >
            <Button variant="outlined" color={"secondary"}>
              Add Course
            </Button>
          </Link> */}
        </div>
        <div className="right" style={{ marginRight: "10px" }}>
          <Link
            style={{
              textDecoration: "none",
            }}
            to={"/dashboard/course/course-completed"}
          >
            <Button
              variant="outlined"
              sx={{ borderColor: "#282b32 !important" }}
            >
              Course Completed
            </Button>
          </Link>
          
        </div>
        <div className="right" style={{ marginRight: "10px" }}>
          <Link
            style={{
              textDecoration: "none",
            }}
            to={"/dashboard/course/orders"}
          >
            <Button
              variant="outlined"
              sx={{ borderColor: "#282b32 !important" }}
            >
              Course Orders
            </Button>
          </Link>
          
        </div>
        <div className="right" style={{ marginRight: "10px" }}>
          <Link
            style={{
              textDecoration: "none",
            }}
            to={"/dashboard/course/questions"}
          >
            <Button
              variant="outlined"
              sx={{ borderColor: "#282b32 !important" }}
            >
              All Questions
            </Button>
          </Link>
        </div>
        <div className="right" style={{ marginRight: "10px" }}>
          <Link
            style={{
              textDecoration: "none",
            }}
            to={"/dashboard/course/quiz_results"}
          >
            <Button
              variant="outlined"
              sx={{ borderColor: "#282b32 !important" }}
            >
              Show Results
            </Button>
          </Link>
        </div>
      </div>
      <div>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {data?.getCourses?.map((course) => {
            return (
              <SingleCourseItem
                key={"single-course-key-" + createRandKey(6)}
                courseItem={course}
                refetch={refetch}
                activeAdmin={props.session.activeAdmin}
              />
            );
          })}
          {data?.getCourses?.length === 0 && (
            <Typography
              align="center"
              variant="h4"
              style={{ width: "100%", marginTop: 100 }}
            >
              There is no course yet
            </Typography>
          )}
        </Grid>
      </div>
    </Container>
  );
};

// const TransactionWindow = ({ open, handleOpen }) => {
//   return (
//     <>
//       <Dialog open={open} onClose={handleOpen} fullWidth maxWidth="650">
//         <DialogTitle>Course Order List</DialogTitle>
//         <DialogContent>
//           <TransactionList></TransactionList>
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// };
const SingleCourseItem = ({ courseItem, refetch, activeAdmin }) => {
  const { title, id, featured_img, courseDetail, units } = courseItem;
  const [tutorials, setTutorials] = useState([]);
  useEffect(() => {
    const tutorialList = [];
    units.forEach((unit) => {
      unit.tutorials.forEach((tutorial) => {
        tutorialList.push(tutorial);
      });
    });
    setTutorials(tutorialList);
  }, [courseItem, units]);
  return (
    <Grid
      item
      xs={3}
      style={{
        position: "relative",
      }}
    >
      {/* <Link
        to={"/dashboard/course/edit/" + id}
        style={{
          textDecoration: "none",
        }}
      > */}
      <Card sx={{ width: "100%" }}>
        <CardMedia
          component="img"
          height="140"
          image={featured_img}
          alt={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {courseDetail}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Units:</strong> {units.length}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Tutorials:</strong> {tutorials.length}
          </Typography>
        </CardContent>
        <CardActions sx={{ paddingTop: "0px" }}>
          <div
            style={{
              width: "100%",
            }}
          >
            <CourseDialog id={id} refetch={refetch} />
          </div>
        </CardActions>
        <CardActions sx={{ paddingTop: "0px" }}>
          <div
            style={{
              width: "100%",
            }}
          >
            <Link
              style={{
                textDecoration: "none",
              }}
              to={`/dashboard/course/${id}/success`}
            >
              <Button variant="outlined" fullWidth>
                Edit SuccessPage
              </Button>
            </Link>
          </div>
        </CardActions>

        <CardActions sx={{ paddingTop: "0px" }}>
          <div
            style={{
              width: "100%",
            }}
          >
            <UnitsDialog courseId={id} activeAdmin={activeAdmin} />
          </div>
        </CardActions>
      </Card>
      {/* </Link> */}
    </Grid>
  );
};

export default Courses;
