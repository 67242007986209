import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import ButtonLinking from "../../../../assets/editorHints/button_linking.jpg";

const ButtonLinkComponent = ({ text, label, link }) => {
  let darkerColor = "#63b274";
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        paddingTop: 60,
        paddingBottom: 60,
      }}
    >
      <Typography>{text}</Typography>
      <br></br>
      <Link
        to={{
          pathname: link,
        }}
        style={{
          marginLeft: 10,
          textDecoration: "none",
        }}
        target={"_blank"}
      >
        <Button
          style={{
            backgroundColor: darkerColor,
            color: "white",
          }}
        >
          {label}
        </Button>
      </Link>
    </Grid>
  );
};
export default ButtonLinkComponent;

export const KeywordComponentInfoDialog = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <InfoIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent>
          <img style={{ width: "100%" }} src={ButtonLinking}></img>
          <Typography>
            Click the dropdown and select the item.
            <br></br>
            Color is disabled for now.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
