import { gql } from "@apollo/client";

const GET_ALL_TICKETS = gql`
  query GetTickets {
    getTickets {
      id
    }
  }
`;
const GET_TICKET_BY_ID = gql`
  query GetTicketById($id: ID) {
    getTicketById(id: $id) {
      id
      name
      description
      rawPrice
      price
      discount
      status
      quota
      img
    }
  }
`;
const CREATE_TICKET = gql`
  mutation CreateTicket($input: inputTicket) {
    createTicket(input: $input) {
      id
      name
      description
      rawPrice
      price
      discount
      status
      quota
    }
  }
`;
const UPDATE_TICKET = gql`
  mutation UpdateTicket($id: ID, $input: inputTicket) {
    updateTicket(id: $id, input: $input) {
      id
      name
      description
      rawPrice
      price
      discount
      status
      quota
    }
  }
`;
const PURCHASE_TICKET_ADMIN = gql`
  mutation PurchaseTicketAdmin($input: inputAdminPurchaseTicket) {
    purchaseTicketAdmin(input: $input) {
      id
    }
  }
`;
const CUSTOMER_TICKET = gql`
  query CustomersTickets {
    customersTickets {
      id
      ticketId {
        id
        name
        img
      }
      orderId
      status
      orderPrice
      orderDiscount
      ticketNumber
      insurance
      customer {
        id
        email
        name
        phone_number
      }
      guest {
        firstName
        lastName
        email
        phone
        title
        question
      }
      attendance
    }
    customersTicketsCount
  }
`;

const TICKET_ATTENDANCE = gql`
  mutation TicketAttendance($id: ID) {
    ticketAttendance(id: $id) {
      attendance
    }
  }
`;

const VERIFY_TICKET_NUMBER = gql`
  mutation VerifyTicketNumber($ticketNumber: String) {
    verifyTicketNumber(ticketNumber: $ticketNumber) {
      attendance
    }
  }
`;

export {
  GET_ALL_TICKETS,
  GET_TICKET_BY_ID,
  CREATE_TICKET,
  UPDATE_TICKET,
  PURCHASE_TICKET_ADMIN,
  CUSTOMER_TICKET,
  TICKET_ATTENDANCE,
  VERIFY_TICKET_NUMBER,
};
