import { gql } from "@apollo/client";
const GET_VET_REQ_PENDING = gql`
  query GetVetFormPending {
    getVetFormPending {
      id
      firstName
      lastName
      companyName
      emailAddress
      phoneNumber
      businessType
      nutritionFree
      endorsing
      carryMoreInfo
      struggle
      struggleText
      status
      createdAt
      updatedAt
    }
  }
`;

const VET_STATUS_UPDATE = gql`
  mutation ChangeStatus($id: ID!, $status: String!) {
    changeStatusVetForm(id: $id, status: $status) {
      id
      status
    }
  }
`;

const UPDATE_VET_FORM = gql`
  mutation UpdateVetForm($id: ID!, $input: inputVetForm) {
    updateVetForm(id: $id, input: $input) {
      id
      status
    }
  }
`;

export { GET_VET_REQ_PENDING, VET_STATUS_UPDATE, UPDATE_VET_FORM };
