import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const AutocompleteCustom = ({ data, setVal, title }) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={data}
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => {
        setVal(newValue);
      }}
      sx={{
        marginTop: "10px",
        marginBottom: "10px",
      }}
      renderInput={(params) => <TextField {...params} label={title} fullWidth />}
    />
  );
};

export default AutocompleteCustom;
