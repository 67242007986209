import { useQuery } from "@apollo/client";
import {
  Avatar,
  ButtonBase,
  Grid,
  List,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import {
  FETCH_CUSTOMER_COURSES,
  GET_COURSES,
  GET_SINGLE_COURSE,
  GET_UNIT_PROGRESS_OF_CUSTOMER,
} from "../../../../../queries";
import LinearLoading from "../../../LinearLoading";
import { CourseItem } from "../courseProgressOfCustomer";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
}));

const CustomerCourses = ({ activeAdmin }) => {
  const classes = useStyles();
  const { id: customerId } = useParams();

  const {
    loading: loadingCustomerPurchase,
    data: dataCustomerPurchase,
    error: errorPurchase,
  } = useQuery(FETCH_CUSTOMER_COURSES, {
    variables: {
      customerId: customerId,
    },
  });
  if (loadingCustomerPurchase) {
    return (
      <Paper className={classes.paper}>
        <LinearLoading />
      </Paper>
    );
  }
  if (errorPurchase) {
    return <Paper className={classes.paper}>Something went wrong</Paper>;
  }
  return (
    <Paper className={classes.paper}>
      Courses{" "}
      <List className="nopadding subscriptions">
        {dataCustomerPurchase.purchasedCoursesOfCustomer
          .filter((item) => item.status === "succeeded")
          .map((purchase) => {
            return (
              <CoursePurchaseItem
                purchase={purchase}
                activeAdmin={activeAdmin}
              />
            );
          })}
      </List>
      {dataCustomerPurchase.purchasedCoursesOfCustomer.length === 0 &&
        "No course purchased yet"}
    </Paper>
  );
};
export default CustomerCourses;

const CoursePurchaseItem = ({ purchase, activeAdmin }) => {
  const [open, setOpen] = useState(false);
  const [completedUnits, setCompoletedUnits] = useState([]);
  const [progressP, setProgressP] = useState(0);
  const { id: customerId } = useParams();
  const {
    data: dataCourses,
    loading: loadingCourses,
    error: errorCourses,
    refetch: refetchCourses,
  } = useQuery(GET_SINGLE_COURSE, {
    variables: {
      input: {
        id: purchase.courseId,
      },
    },
    skip: !purchase.courseId,
  });

  useEffect(() => {
    if (purchase && purchase.courseId) {
      refetchCourses();
    }
  }, [purchase]);

  const { loading, error, refetch } = useQuery(GET_UNIT_PROGRESS_OF_CUSTOMER, {
    variables: {
      courseId: purchase.courseId,
      customerId: customerId,
    },
    onCompleted: (res) => {
      setCompoletedUnits(res.getUnitProgressOfCustomer.completedUnits);
    },
  });
  useEffect(() => {
    if (completedUnits.length > 0 && dataCourses?.getSingleCourse) {
      const calculateP = calculateProgress();
      setProgressP(calculateP);
    }
  }, [completedUnits, dataCourses]);
  const calculateProgress = () => {
    const filterByStatus = dataCourses.getSingleCourse.units.filter(
      (item) => item.status === true
    );
    const passed = filterByStatus
      .map((item) => item.id)
      .filter((item) => completedUnits.includes(item));
    const p =
      passed.length / filterByStatus.length > 1
        ? 1
        : passed.length / filterByStatus.length;
    return parseInt(p * 100);
  };
  if (loadingCourses || loading) {
    return <LinearLoading />;
  }
  if (errorCourses || error) {
    return <div>Something went wrong</div>;
  }
  return (
    <>
      {dataCourses ? (
        <div>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Grid item>
              <CircularProgressWithLabel value={progressP} />
            </Grid>
            <Grid item xs>
              <Typography fontWeight={700}>
                {dataCourses.getSingleCourse.title}
              </Typography>
              <Typography variant="subtitle2">
                Purchased on:{" "}
                {moment(parseInt(purchase.createdAt)).format("MM-DD-yyyy")}
              </Typography>
              <Typography variant="subtitle2">
                End date: {moment(purchase.endDate * 1000).format("MM-DD-yyyy")}{" "}
              </Typography>
            </Grid>
          </Grid>
          {dataCourses && (
            <CourseItem
              courseItem={dataCourses?.getSingleCourse}
              activeAdmin={activeAdmin}
              onlyDialog={true}
              openOutside={open}
              setOpenOutside={(data) => {
                setOpen(data);
              }}
            />
          )}
        </div>
      ) : (
        <LinearLoading />
      )}
    </>
  );
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress color="success" variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
