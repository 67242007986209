import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { PlanList } from "../../RestartSubscription";
import { useSnackbar } from "notistack";
import { AddressList } from "./SecondTabSteps";
import PaymentComponent from "./Payment";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_PRODUCTS,
  GET_RATE,
  ONE_TIME_ORDER_TAB_THREE,
} from "../../../../../queries";
import { States } from "../constants";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import SSProducts from "./SSProducts";

const steps = [
  {
    label: "Select Products",
  },
  {
    label: "Payment",
  },
];

const useStyles = makeStyles((theme) => ({
  loader: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000095",
    position: "absolute",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
}));
export default function FirstTabSteps({
  customerMongoId,
  stripeCustomer,
  email,
  id,
  close,
  mongoCustomer,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [basket, setBasket] = useState([]);
  const [address, setAddress] = useState({});
  const [stripePaymentSource, setStripePaymentSource] = useState("");
  const [getShippingRate] = useMutation(GET_RATE);
  const [rateLoading, setRateLoading] = useState(false);
  const [shippingPrice, setShippingPrice] = useState({});
  const classes = useStyles();
  const [notes, setNotes] = useState("");
  const [sendShipStation, setSendShipStation] = useState(true);
  const [chargeShipping, setChargeShipping] = useState(true);

  const [rdcUse, setRDCUse] = useState(false);

  const [makeOneTimeOrderMutation, { loading: orderLoading }] = useMutation(
    ONE_TIME_ORDER_TAB_THREE
  );

  useEffect(() => {
    if (Object.keys(stripeCustomer).length !== 0) {
      setStripePaymentSource(stripeCustomer.default_source.id);
    }
  }, [stripeCustomer]);

  const handleNext = () => {
    if (activeStep === 0 && !firstStepValid()) {
      enqueueSnackbar(`First step is not valid`, {
        variant: "error",
      });
      return;
    }
    if (activeStep === 0) {
      calculateShipping();
    }
    if (activeStep === steps.length - 1) {
      makeOneTimeOrder();
      return;
    }
    if (firstStepValid()) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (event) => {
    setAddress(event.target.value);
  };

  const firstStepValid = () => {
    let flag = true;
    if (address.length === 0 || basket.length === 0) {
      flag = false;
    }
    return flag;
  };
  console.log(basket);
  const calculateShipping = () => {
    setRateLoading(true);

    let country = States.find((item) => item.value === address.state);

    let shippingInput = {
      products: basket.map((product) => {
        return {
          skuId: product.id,
          quantity: product.quantity,
          description: product.name,
          package_dimensions: {
            weight: product.package_dimensions.weight,
            height: product.package_dimensions.height,
            length: product.package_dimensions.length,
            width: product.package_dimensions.width,
          },
        };
      }),
      shipping: {
        address: {
          name: address.name,
          line1: address.address,
          line2: address.address2,
          city: address.city,
          country: country ? country.country : "US",
          state: address.state,
          postal_code: address.postal_code,
        },
      },
    };

    getShippingRate({
      variables: {
        input: shippingInput,
      },
    })
      .then((res) => {
        setRateLoading(false);
        setShippingPrice(res.data.getRate[0]);
      })
      .catch((error) => {
        enqueueSnackbar(`Shipping Rate Calculation Error`, {
          variant: "error",
        });
        console.log(error);
        setRateLoading(false);
        setActiveStep(0);
      });
  };

  // [
  //   {
  //     description: "Treat & Chew Box",
  //     quantity: 2,
  //     amount: 3400,
  //   },
  //   {
  //     description: "Super Chew",
  //     quantity: 2,
  //     amount: 1000,
  //   },
  // ],

  const makeOneTimeOrder = () => {
    let orderInput = {
      notes: notes,
      items: basket.map((item) => {
        return {
          skuId: item.skuId,
          description: item.name,
          quantity: item.quantity,
          amount: item.price * 100,
        };
      }),
      rdcUse: rdcUse,
      chargeShipping: chargeShipping,
      sendToSS: sendShipStation,
      customerStripeId: stripeCustomer.id,
      source: stripePaymentSource,
      email: email,
      owner: id,
    };

    if (Object.keys(address).length === 0) {
      enqueueSnackbar(`You must select address`, {
        variant: "error",
      });
      return;
    }
    if (Object.keys(shippingPrice).length === 0) {
      enqueueSnackbar(`You must calculate shipping`, {
        variant: "error",
      });
      return;
    }

    // if (sendShipStation) {
    if (true) {
      orderInput = {
        ...orderInput,
        address: {
          name: address.name,
          address1: address.address,
          address2: address.address2,
          state: address.state,
          city: address.city,
          postal_code: address.postal_code,
        },
        rate: {
          serviceCode: shippingPrice.serviceCode,
          shipmentCost: shippingPrice.shipmentCost,
          height: shippingPrice.height,
          length: shippingPrice.length,
          width: shippingPrice.width,
          weight: shippingPrice.weight,
        },
      };
    }

    makeOneTimeOrderMutation({ variables: { input: orderInput } })
      .then((res) => {
        enqueueSnackbar(`Order created successfully`, {
          variant: "success",
        });
        close();
      })
      .catch((error) => {
        enqueueSnackbar(`Error could not created`, {
          variant: "error",
        });
      });
  };

  return (
    <Box>
      {(rateLoading || orderLoading) && (
        <Grid
          className={classes.loader}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography align="center">
            Please wait
            <br />
            <CircularProgress color="primary" />
          </Typography>
        </Grid>
      )}
      <Stepper activeStep={activeStep} orientation="vertical" color="secondary">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {activeStep === 0 && (
                <div>
                  <SSProducts
                    basket={basket}
                    setBasket={(data) => {
                      setBasket(data);
                    }}
                  />
                  <div style={{ height: 10 }} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={sendShipStation}
                        onClick={() => setSendShipStation(!sendShipStation)}
                        color={"secondary"}
                      />
                    }
                    label="Send to ShipStation"
                  />
                  <div style={{ height: 10 }} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chargeShipping}
                        onClick={() => setChargeShipping(!chargeShipping)}
                        color={"secondary"}
                      />
                    }
                    label="Charge Shipping"
                  />
                  {mongoCustomer.premium == 1 && (
                    <b
                      style={{
                        backgroundColor: "rgb(255 199 49)",
                        borderRadius: "5px",
                        padding: "2px 8px",
                        display: "inline-flex",
                        fontSize: ".8em",
                        color: "#000",
                      }}
                    >
                      This user is RDB Membership. <br />
                      If you do not want to charge shipping, unselect it.
                    </b>
                  )}
                  <div style={{ height: 10 }} />

                  <AddressList
                    customerMongoId={customerMongoId}
                    address={address}
                    handleChange={handleChange}
                  />
                </div>
              )}
              {activeStep === 1 && (
                <PaymentComponent
                  sources={stripeCustomer.sources}
                  stripePaymentSource={stripePaymentSource}
                  setStripePaymentSource={setStripePaymentSource}
                  price={basket
                    .map((item) => item.quantity * item.price)
                    .reduce((a, b) => a + b, 0)}
                  shippingPrice={
                    !chargeShipping
                      ? 0
                      : Object.keys(shippingPrice).length !== 0
                      ? shippingPrice.shipmentCost
                      : 0
                  }
                  notes={notes}
                  setNotes={setNotes}
                  rdcUse={rdcUse}
                  setRDCUse={setRDCUse}
                />
              )}

              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
