import { gql } from "@apollo/client";

const GET_ACTIVE_ADMIN = gql`
  query {
    activeAdmin {
      name
      surname
      id
      email
      status
      createdAt
      permission
      canAddSchedule
    }
  }
`;

const GET_ADMINS = gql`
  query {
    admins {
      id
      name
      surname
      title
      email
      status
      permission
    }
  }
`;

const GET_ADMIN = gql`
  query ($id: ID!) {
    admin(id: $id) {
      id
      permission
    }
  }
`;

const CREATE_ADMIN = gql`
  mutation (
    $name: String!
    $surname: String!
    $email: String!
    $password: String!
    $title: String
    $permission: JSON
  ) {
    createAdmin(
      data: {
        name: $name
        surname: $surname
        email: $email
        password: $password
        title: $title
        status: 0
        permission: $permission
      }
    ) {
      name
      id
    }
  }
`;

const UPDATE_ADMIN = gql`
  mutation UpdateMutation($id: ID!, $input: UpdateAdminInput) {
    updateAdmin(id: $id, input: $input) {
      name
      permission
    }
  }
`;
const GET_MUTATION_LOGS_ADMIN = gql`
  query MutationLogs(
    $offset: Int
    $limit: Int
    $query: String
    $sort: SortType
    $from: Int
    $to: Int
  ) {
    logs(
      offset: $offset
      limit: $limit
      query: $query
      sort: $sort
      from: $from
      to: $to
    ) {
      id
      admin {
        name
      }
      customer {
        id
        name
        email
      }
      created_at
      variables
      response
    }
    totalLogs(
      offset: $offset
      limit: $limit
      query: $query
      sort: $sort
      from: $from
      to: $to
    )
  }
`;
const GET_WALLET_LOGS_ADMIN = gql`
  query GetWalletLogs($options: LogOptions) {
    getWalletLogs(options: $options) {
      id
      admin {
        name
      }
      createdAt
      type
      customer {
        stripeId
        email
        name
      }
      amount
    }
    getWalletLogsCount(options: $options)
  }
`;

const BAN_MEMBER = gql`
  mutation BanMember($id: ID!, $reason: String) {
    judgeMember(id: $id, reason: $reason) {
      id
      reason
      name
      first_name
      last_name
      email
      phone_number
      createdAt
      updatedAt
      data
    }
  }
`;

export {
  UPDATE_ADMIN,
  CREATE_ADMIN,
  GET_ADMIN,
  GET_ADMINS,
  GET_ACTIVE_ADMIN,
  GET_WALLET_LOGS_ADMIN,
  GET_MUTATION_LOGS_ADMIN,
  BAN_MEMBER,
};
