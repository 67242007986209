import { useMutation } from "@apollo/client";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Grid,
  LinearProgress,
  ListItemButton,
  ListItemIcon,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CREATE_UNIT } from "../../../../queries";
import QuizStatus from "../../Quiz/components/QuizStatus";
import { FeaturedImage } from "../Course";

const TITLE_MIN_LENGTH = 10;
const DETAIL_MIN_LENGTH = 10;

const CreateUnitDialog = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { courseId, activeAdmin, unitsList } = props;

  const [title, setTitle] = useState("");
  const [unitDetail, setUnitDetail] = useState("");
  const [status, setStatus] = useState(true);

  const [featuredImg, setFeaturedImg] = useState("");
  const [tutorials, setTutorials] = useState([]);
  const [open, setOpen] = useState(false);
  const [prerequisites, setPrerequisites] = useState([]);

  const [
    createUnitMutation,
    { loading: createUnitLoading, error: createUnitError },
  ] = useMutation(CREATE_UNIT, {
    onCompleted: (response) => {
      console.log("response", response);

      enqueueSnackbar(`Unit created Successfully`, {
        variant: "success",
      });
      history.push(
        `/dashboard/course/edit/${courseId}/unit/edit/${response.createUnit.id}`
      );
    },
  });

  const onClickPrerequisites = (courseId) => {
    const copy = JSON.parse(JSON.stringify(prerequisites));
    const index = copy.indexOf(courseId);
    if (index !== -1) {
      copy.splice(index, 1);
    } else {
      copy.push(courseId);
    }
    setPrerequisites(copy);
  };

  const onCreateUnit = () => {
    const valid = isValid();
    if (valid) {
      const input = createInput();
      createUnitMutation({
        variables: {
          input,
        },
      });
    }
  };
  const createInput = () => {
    const input = {
      admin_id: activeAdmin.id,
      title,
      unitDetail,
      status,
      courseId,
      featured_img: featuredImg,
      tutorials,
    };
    return input;
  };

  const isValid = () => {
    if (title.length < TITLE_MIN_LENGTH) {
      enqueueSnackbar(`Unit title not valid (min length ${TITLE_MIN_LENGTH})`, {
        variant: "error",
      });
      return false;
    }
    if (unitDetail.length < DETAIL_MIN_LENGTH) {
      enqueueSnackbar(
        `Unit detail not valid (min length ${DETAIL_MIN_LENGTH})`,
        {
          variant: "error",
        }
      );
      return false;
    }
    if (featuredImg.length === 0) {
      enqueueSnackbar(`Please upload an image`, {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const uploadPhoto = (file) => {
    setFeaturedImg(file.uri);
  };
  const resetFeaturedPhoto = () => {
    setFeaturedImg("");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (createUnitLoading) {
    return <LinearProgress />;
  }
  if (createUnitError) {
    return <Typography>Error</Typography>;
  }

  return (
    <div>
      <Button onClick={handleClickOpen} color="secondary" variant="outlined">
        add a new unit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">{"Add New Unit"}</DialogTitle>

        <DialogContent
          sx={{ marginTop: "10px", paddingTop: "10px !important" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} className="every-child-margin ">
              <TextField
                fullWidth
                label="Unit Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <TextField
                fullWidth
                label="Unit Detail"
                value={unitDetail}
                onChange={(e) => {
                  setUnitDetail(e.target.value);
                }}
              />
              <QuizStatus status={status} setStatus={setStatus} />
              <Paper style={{ marginBottom: 10 }}>
                <Accordion sx={{ backgroundColor: "#000 !important" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      Please select prerequisite if it's needed!
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {unitsList.map((unit) => {
                      return (
                        <ListItem key={unit.id} disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={() => {
                              onClickPrerequisites(unit.id);
                            }}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={prerequisites.includes(unit.id)}
                                tabIndex={-1}
                                disableRipple
                              />
                            </ListItemIcon>
                            <ListItemText primary={unit.title} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </Paper>

              <FeaturedImage
                featuredImg={featuredImg}
                uploadPhoto={uploadPhoto}
                resetFeaturedPhoto={resetFeaturedPhoto}
              />

              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  onCreateUnit();
                }}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default CreateUnitDialog;
