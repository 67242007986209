import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { GET_ROOM_NOTES } from "../../../../queries";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import TakeNote from "./takeNote";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import BugReportIcon from "@mui/icons-material/BugReport";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import OnOffSwitch from "./onOffSwitch";

const SeeNotes = ({ room, refetch }) => {
  const [getNotes, { data, loading, error }] = useLazyQuery(GET_ROOM_NOTES, {
    fetchPolicy: "no-cache",
    variables: { id: room.id },
  });
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    getNotes();
  };

  const handleClose = () => {
    setOpen(false);
  };
  console.log("data", data);
  return (
    <div style={{ width: "100%" }}>
      <Badge
        sx={{ width: "100%", marginBottom: "10px" }}
        badgeContent={room.notesCount}
        color="success"
      >
        <Button fullWidth variant="outlined" onClick={handleClickOpen}>
          Details
        </Button>
      </Badge>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            margin: "0px !important",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {`${room.name} Notes`}
        </DialogTitle>
        <DialogContent
          sx={{
            "@media (max-width:390px)": {
              padding: 0,
            },
          }}
        >
          <OnOffSwitch room={room} getNotes={getNotes} refetch={refetch} />
          <TakeNote room={room} getNotes={getNotes} refetch={refetch} />
          <div>
            <Timeline position="alternate" sx={{ padding: "0px !important" }}>
              {data &&
                data.getRoomNotes.map(
                  (note, index) =>
                    index % 2 ? (
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ py: "12px", px: 2 }}
                          align="right"
                          variant="body2"
                          color="text.secondary"
                        >
                          <Typography>
                            {moment
                              .unix(note.createdAt)
                              .format("hh:mm MM/DD/YYYY")}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            color={
                              note.type === "issue" ? "error" : "secondary"
                            }
                          >
                            {note.type === "issue" ? (
                              <BugReportIcon />
                            ) : (
                              <UpgradeIcon />
                            )}
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="p"
                            component="span"
                            color="text.secondary"
                          >
                            {note.admin.email}
                          </Typography>
                          <Typography>{note.note}</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ) : (
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ py: "12px", px: 2 }}
                          variant="body2"
                          color="text.secondary"
                        >
                          <Typography>
                            {moment
                              .unix(note.createdAt)
                              .format("hh:mm MM/DD/YYYY")}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            color={
                              note.type === "issue" ? "error" : "secondary"
                            }
                          >
                            {note.type === "issue" ? (
                              <BugReportIcon />
                            ) : (
                              <UpgradeIcon />
                            )}
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="p"
                            component="span"
                            color="text.secondary"
                          >
                            {note.admin.email}
                          </Typography>
                          <Typography>{note.note}</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    )

                  // <p>
                  //   {note.note} - {note.admin.email} -
                  //   {moment.unix(note.createdAt).format("hh:mm MM/DD/YYYY")}
                  // </p>
                )}
            </Timeline>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SeeNotes;
