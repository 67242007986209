import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { List, ListItem, ListItemText } from "@mui/material";
import { useMutation } from "@apollo/client";
import { COMPLETE_UNIT } from "../../../../../queries";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CompleteUnitDialog({
  unit,
  completedTutorials,
  customerId,
  refetch,
}) {
  const [open, setOpen] = React.useState(false);
  const [nonCompletedTutorials, setNonCompletedTutorials] = React.useState([]);
  const [completeTheUnit, { loading, error }] = useMutation(COMPLETE_UNIT, {
    variables: {
      input: {
        unitId: unit.id,
        courseId: unit.courseId,
        customerId: customerId,
      },
    },
    onCompleted: () => {
      setOpen(false);
      refetch();
    },
  });
  React.useEffect(() => {
    let non = [];
    unit.tutorials.forEach((tutorial) => {
      const passed = completedTutorials.find(
        (item) => item.tutorialId === tutorial.id && item.passed === true
      );
      if (!passed) {
        non.push(tutorial);
      }
    });
    setNonCompletedTutorials(non);
  }, [unit, completedTutorials]);

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };
  const onMakeUnitCompleted = (e) => {
    e.stopPropagation();
    handleClickOpen(e);
  };
  const unitCompleted = (e) => {
    e.stopPropagation();
    completeTheUnit();
  };
  console.log(nonCompletedTutorials);
  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<CheckBoxOutlineBlankIcon />}
        style={{
          marginLeft: 10,
        }}
        size="small"
        onClick={onMakeUnitCompleted}
      >
        Make Completed
      </Button>
      {nonCompletedTutorials.length === 0 ? (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth={"sm"}
        >
          <DialogTitle>
            {"Are you sure you want to mark unit as completed"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>close</Button>
            <Button onClick={unitCompleted}>confirm</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth={"sm"}
        >
          <DialogTitle>
            {"User should complete the tutorials below"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <List>
                {nonCompletedTutorials.map((item) => {
                  return (
                    <ListItem key={item.id}>
                      <ListItemText primary={item.title} />
                    </ListItem>
                  );
                })}
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>close</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
