import StarIcon from "@mui/icons-material/Star";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import { UPGRADE_MEMBERSHIP } from "../../../../../queries";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import LinearLoading from "../../../LinearLoading";

const UpgradeMembership = ({ data, refetch, onClose }) => {
  console.log("data", data);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [upgradeMembership, { data: upgradeData, loading, error }] =
    useMutation(UPGRADE_MEMBERSHIP);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const upgradeFunc = () => {
    if (
      data.customer.stripe.subscription.filter(
        (x) =>
          x.items?.data &&
          x.items.data.find(
            (y) =>
              y.plan.id === process.env.REACT_APP_PREMIUM_MONTHLY_RD_USA ||
              y.plan.id === process.env.REACT_APP_PREMIUM_MONTHLY_RD_CA
          )
      ).length > 0
    ) {
      const subid = data.customer.stripe.subscription.filter(
        (x) =>
          x.items?.data &&
          x.items.data.find(
            (y) =>
              y.plan.id === process.env.REACT_APP_PREMIUM_MONTHLY_RD_USA ||
              y.plan.id === process.env.REACT_APP_PREMIUM_MONTHLY_RD_CA
          )
      )[0].id;

      upgradeMembership({
        variables: {
          subId: subid,
          premiumInterval: 12,
        },
      })
        .then(async ({ data }) => {
          handleClose();
          onClose();
          refetch();
          enqueueSnackbar(
            "Membership is upgraded.It will be charged on their renewal date.",
            {
              variant: "success",
            }
          );
        })
        .catch((e) => {
          enqueueSnackbar(`${e.message}`, {
            variant: "error",
          });
        });
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<StarIcon />}
        fullWidth
        className="yellowborder btn-text-left btn-text-margin-left"
        style={{
          marginBottom: "20px",
        }}
        onClick={() => handleClickOpen()}
      >
        Upgrade to RDB Yearly Membership
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          {"Upgrade Yearly RD Membership"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <LinearLoading open={loading} />
            <Alert severity="warning">
              Customer will pay yearly.(USA=$204, Canada=$276)
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading || upgradeData}
            onClick={() => upgradeFunc()}
          >
            Agree
          </Button>
          <Button onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpgradeMembership;
