import React, { Component, useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { GET_LOG_LIST } from "../../../../queries";
import Select from "@mui/material/Select";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const LogSearch = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [logs, setLogs] = useState([]);
  const isInitialMount = useRef(true);
  const [getLogs, { loading: loadingSearch, data: searchData }] = useLazyQuery(
    GET_LOG_LIST,
    {
      fetchPolicy: "no-cache",
      variables: {
        options: {
          query,
          email,
          sort: props.sortOrder,
        },
      },
      onCompleted: (data) => {
        if (data) {
          setLogs(data.logs);
        }
      },
    }
  );

  useEffect(() => {
    if (!isInitialMount.current) {
      isInitialMount.current = true;
      getLogs();
    }
  }, [logs, getLogs, query, email]);

  const searchInput = (event) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(event.target.value) && event.target.value.length >= 4) {
      isInitialMount.current = false;
      setQuery("");
      setEmail(event.target.value);
    } else if (event.target.value.length >= 4) {
      isInitialMount.current = false;
      setEmail("");
      setQuery(event.target.value);
    }
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Autocomplete
            id="REALDOG - REAL SEARCH"
            noOptionsText={"Please search customer with phone number or email"}
            loading={loadingSearch}
            options={logs}
            getOptionLabel={(option) => `${option.query} - ${option.email}`}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <div className="searchresult">
                  <b>{option.query}</b>
                  <span>{option.email}</span>
                </div>
              </li>
            )}
            onChange={(e, value) => {
              let newValue = value || "";
              return props.history.push(`/dashboard/customer/${newValue.id}`);
            }}
            renderInput={(log) => (
              <TextField
                {...log}
                label="Search Query or Email"
                margin="normal"
                onChange={(e) => searchInput(e)}
                variant="outlined"
                InputProps={{ ...log.InputProps, type: "search" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Sort Created Date
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={this.props.order}
              onChange={classes.sortCreatedDate}
            >
              <MenuItem value={"asc"}>Ascending</MenuItem>
              <MenuItem value={"desc"}>Descending</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default LogSearch;
