import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@apollo/client";
import { TICKET_ATTENDANCE } from "../../../queries";
import { useSnackbar } from "notistack";
import { LinearProgress } from "@mui/material";

export default function TicketAttendance({ ticket, refetch }) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [confirmAttendance, { loading }] = useMutation(TICKET_ATTENDANCE);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    confirmAttendance({
      variables: {
        id: ticket.id,
      },
    })
      .then((res) => {
        enqueueSnackbar(`User attended successfully`, {
          variant: "success",
        });
        refetch();
      })
      .catch((error) => {
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
        });
      });
  };

  if (ticket.attendance) {
    return <div>Attended</div>;
  }
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Approve
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ textAlign: "center" }}>
          {"Confirm Attendance"}
        </DialogTitle>
        {loading && <LinearProgress />}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Can you confirm that this individual was present at the event?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-around",
          }}
        >
          <Button
            disabled={loading}
            onClick={handleClose}
            sx={{
              width: "40%",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={onConfirm}
            autoFocus
            sx={{
              width: "40%",
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
