import { gql } from "@apollo/client";

const GET_CUSTOMER_WIZARD_STEP = gql`
  query GetUsersWizardSteps($id: String!) {
    getUsersWizardSteps(id: $id) {
      wizardSteps {
        tag
        isDone
        data {
          belongsTo
          dogId
          question
          answer
        }
      }
    }
  }
`;
export { GET_CUSTOMER_WIZARD_STEP };
