import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import BackspaceIcon from "@mui/icons-material/Backspace";
import { IconButton, Switch, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { PURCHASE_TICKET_ADMIN } from "../../../queries";
import CustomerFind from "../Customers/Components/CustomerFind";
export default function GiveTicket({ ticketItem }) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [customer, setCustomer] = useState({});
  const [charge, setCharge] = useState(true);
  const [insurance, setInsurance] = useState(false);

  const [purchaseTicketAdmin, { loading }] = useMutation(PURCHASE_TICKET_ADMIN);

  const handleClickOpen = () => {
    initialState();
    setOpen(true);
  };
  const handleClose = () => {
    if (loading) {
      return;
    }
    setOpen(false);
  };
  const initialState = () => {
    setCustomer({});
    setCharge(true);
    setInsurance(false);
  };
  const onPurchase = () => {
    if (!validate()) {
      enqueueSnackbar("Check your inputs", {
        variant: "error",
      });
    }

    const input = createInput();
    purchaseTicketAdmin({
      variables: {
        input: input,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.data && res.data.purchaseTicketAdmin.id) {
          enqueueSnackbar("Ticket successfully created", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "success",
          });
        }
        handleClose();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      });
  };

  const createInput = () => {
    return {
      customer: customer.id,
      ticketId: ticketItem.id,
      charge: charge,
      price: ticketItem.price,
      insurance: insurance,
    };
  };

  const validate = () => {
    if (Object.keys(customer).length === 0) {
      return false;
    }
    return true;
  };
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Sell ticket to a user
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>Sell Ticket to a user </DialogTitle>
        <DialogTitle>
          <b>Ticket name:</b> {ticketItem.name}
          <br />
          <b>Ticket price:</b> {ticketItem.price}
        </DialogTitle>

        <DialogContent>
          {Object.keys(customer).length === 0 ? (
            <CustomerFind
              userSelection={true}
              setCustomer={(data) => {
                setCustomer(data);
              }}
            />
          ) : (
            <React.Fragment>
              <Typography>
                {customer.email} ({customer.name})
                <IconButton
                  disabled={loading}
                  onClick={() => {
                    setCustomer({});
                  }}
                >
                  <BackspaceIcon color="error" />
                </IconButton>
              </Typography>
            </React.Fragment>
          )}
          <div>
            Free
            <Switch
              disabled={loading}
              color="success"
              defaultChecked
              checked={charge}
              onClick={() => {
                setCharge(!charge);
              }}
            />
            Charge
          </div>
          {charge && (
            <div>
              <Switch
                color="success"
                disabled={loading}
                defaultChecked
                checked={insurance}
                onClick={() => {
                  setInsurance(!insurance);
                }}
              />
              Insurance
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={loading} onClick={onPurchase}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
