import { gql } from "@apollo/client";

const SIGNIN_USER = gql`
  mutation ($email: String!, $password: String!) {
    signIn(data: { email: $email, password: $password }) {
      token
    }
  }
`;

const CREATE_AFFILIATE_SIGNUP_LINK = gql`
  mutation createAffiliateSignUpLink($owner: ID!) {
    createAffiliateSignUpLink(owner: $owner) {
      id
      owner {
        id
        username
      }
      createdAt
      expiry
    }
  }
`;

const FIRST_LOGIN = gql`
  mutation ($id: ID!, $password: String!, $pass: Int) {
    firstLogin(id: $id, data: { password: $password, status: 1, pass: $pass }) {
      token
    }
  }
`;

const PASS_VALID = gql`
  mutation ($email: String!, $pass: Int!) {
    passValid(data: { email: $email, pass: $pass }) {
      id
      access
      name
    }
  }
`;

export { SIGNIN_USER, CREATE_AFFILIATE_SIGNUP_LINK, FIRST_LOGIN, PASS_VALID };
