import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_FLAG, UPDATE_FLAG } from "../../../../queries";
import { LinearProgress } from "@mui/material";

const KEY = "discountedMembership";

export default function DiscountedMembership() {
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState();
  const [percentage, setPercentage] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [getFlag, { loading, refetch }] = useLazyQuery(GET_FLAG, {
    onCompleted: (data) => {
      setFlag(data.getFlag);
      setPercentage(data.getFlag.data.percentage);
    },
    fetchPolicy: "no-cache",
  });

  const [updateFlagMutation, { loading: loadingUpdate }] =
    useMutation(UPDATE_FLAG);

  useEffect(() => {
    getFlag({
      variables: {
        key: KEY,
      },
    });
  }, [getFlag, open]);

  const onChangeStatus = () => {
    const copy = copyFlag();
    copy.status = !copy.status;
    setFlag(copy);
  };

  const copyFlag = () => JSON.parse(JSON.stringify(flag));

  const handlePercentageUpdate = (e) => {
    setPercentage(e.target.value);
  };

  const update = () => {
    updateFlagMutation({
      variables: {
        id: flag.id,
        input: {
          key: KEY,
          status: flag.status,
          data: {
            ...flag.data,
            percentage: Number(percentage),
          },
        },
      },
    })
      .then((res) => {
        handleClose();
      })
      .catch((error) => {});
  };

  if (loading || !flag) {
    return <LinearProgress />;
  }
  return (
    <Grid item xs>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        style={{
          borderLeft: "5px solid",
          borderLeftColor: flag.status ? "#63b475" : "#dc5a68",
        }}
        className="btn-reward"
      >
        Discounted Yearly Membership (%{percentage})
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {`Yearly Membership Discount (%${flag.data.percentage})`}
        </DialogTitle>
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                control={
                  <Switch
                    checked={flag.status}
                    color="secondary"
                    onClick={() => {
                      onChangeStatus();
                    }}
                  />
                }
                label="Reward"
              />
              <TextField
                fullWidth
                type="number"
                id="standard-basic"
                label="Percentage"
                variant="standard"
                value={percentage}
                onChange={handlePercentageUpdate}
              />
            </DialogContentText>
          </DialogContent>
        )}
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>cancel</Button>
            <Button onClick={update} autoFocus>
              Update
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Grid>
  );
}
