import InfoIcon from "@mui/icons-material/Info";
import { Container, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import ReactPlayer from "react-player/youtube";
import VideoComponentHintEditor from "../../../../assets/editorHints/VideoEditor.jpeg";

const VideoComponent = ({ title, src, videoTitle }) => {
  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h3"
            style={{
              color: "white",
              fontWeight: "lighter",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <br></br>
        <br></br>
        <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
          <Typography
            align="center"
            variant="body1"
            style={{
              color: "white",
            }}
          >
            {videoTitle}
          </Typography>
        </Grid>
        <br></br>
        <br></br>
        <Grid item xs={8}>
          <ReactPlayer
            style={{ paddingLeft: 16, marginBottom: 20 }}
            width="100%"
            url={src}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default VideoComponent;

export const VideoComponentInfoDialog = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <InfoIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent>
          <img src={VideoComponentHintEditor}></img>
          <Typography>
            Please copy and paste the youtube url. The Video will be shown like
            the picture above
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
