import React, { Component, useEffect, useState, useRef } from "react";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  InMemoryCache,
  useLazyQuery
} from "@apollo/client";

import {
  GET_ALL_PROCESS,
  GET_ACTIVE_ADMIN,
  GET_STRIPE,
  GET_INVENTORY_PRODUCTS
} from "../../../queries";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import LinearProgress from "@mui/material/LinearProgress";
import Autocomplete from "@mui/material/Autocomplete";
import { ProcessDetailModal } from "../Modals/ProcessDetails";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";

import { DataGrid, CellParams } from '@mui/x-data-grid';

function AllProcess(props) {
  const [open, setOpen] = useState(false);
  const [processId, setProcessId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isInitialMount = useRef(true);
  const [ products, setProducts]  = useState([]);

  const {
    subscribeToMore,
    loading,
    error,
    data,
    refetch,
    fetchMore
  } = useQuery(GET_ALL_PROCESS);

  const { loading: loadingProduct, data: filterProduct  } = useQuery(GET_INVENTORY_PRODUCTS);

  useEffect(() => {
    refetch();
  }, [rowsPerPage]);

  useEffect(() => {
    if(!loading)
      setProducts(data.inv_processs_all)
  }, [loading]);

  const loadPage = () => {
    console.log("length", data.inv_processs_all.length);
    fetchMore({
      variables: {
        offset: data.inv_processs_all.length,
        limit: rowsPerPage
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openDetail = id => {
    setProcessId(id);
    setOpen(true);
  };

  const handleChangePage = async (event, newPage) => {
    console.log("değişti", newPage);
    setPage(newPage);
    await loadPage();
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { loading: loadingAdmin, data: dataAdmin } = useQuery(GET_ACTIVE_ADMIN);

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  console.log("test", data.inv_processs_all.length);
  return (
    <ProcessUI
      {...data}
      all_inventory_products={filterProduct}
      inv_processs_all={products}
      rowsPerPage={rowsPerPage}
      page={page}
      open={open}
      processId={processId}
      handleClose={handleClose}
      openDetail={openDetail}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      refetch={refetch}
    />
  );
}

export class ProcessUI extends Component {
  componentDidMount() {
    console.log("comp", this.props);
    this.props.refetch();
  }
  render() {

    const columns = [
      { field: 'id', headerName: 'Batch Number', flex:1},
      { field: 'product_name', headerName: 'Product Name', flex:1 },
      { field: 'avg', headerName: 'Avg Item Weight', flex:1 },
      { field: 'packing_yield', headerName: 'Packing Yield', flex:1},
      { field: 'cost_per_item', headerName: 'Cost Per Item', flex:1},
      { field: 'qty_rec', headerName: 'Qty Rec', flex:1},
      { field: 'total_bags', headerName: 'Total Bags', flex:1 },
      {
        field: 'button',
        headerName: ' ',
        disableClickEventBubbling: true,
          renderCell: (params: CellParams) => <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.props.openDetail(params.row.button)}
                   >
              <VisibilityIcon />
            </IconButton>
      },
    ]

    const rows = [];
    this.props.inv_processs_all
      .map((data, i) => {
        rows.push({
          id: data.batch_number,
          product_name: data.stock_un.product.name,
          avg: parseFloat((data.bins_total / data.total_bags) * 16).toFixed(2),
          packing_yield: parseFloat(data.total_bags / data.expected_bags).toFixed(2),
          cost_per_item: parseFloat((data.stock_un.price * data.quantity) / data.total_bags).toFixed(2),
          qty_rec:data.quantity,
          total_bags:data.total_bags,
          button: data.id
        })
      });

    console.log({rows});
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={"lg"}
        >
          <div>
            <ProcessDetailModal
              id={this.props.processId}
              close={this.props.handleClose}
            />
          </div>
        </Dialog>
        <div className="pagetop">
          <div className="left">
            <Breadcrumbs aria-label="breadcrumb">
              <a color="inherit" href="/">
                Dashboard
              </a>
              <Typography color="textPrimary">Completed Process</Typography>
            </Breadcrumbs>
          </div>
          <div className="right">
            <Button
              variant="outlined"
              color="primary"
              className="marginright10"
              href="/dashboard/processing"
            >
              Back
            </Button>
          </div>
        </div>
        <div style={{ height: 670, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={10} checkboxSelection />
        </div>
        <ul>

          {/* {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
            <TableHead>
            <TableRow>
            <TableCell align="left">Batch Number</TableCell>
            <TableCell align="left">Product Name</TableCell>
            <TableCell align="right">Avg Item Weight</TableCell>
            <TableCell align="right">Packing Yield</TableCell>
            <TableCell align="right">Cost Per Item</TableCell>
            <TableCell align="right">Qty Rec.</TableCell>

            <TableCell align="right">Total Bags</TableCell>
            <TableCell align="right"></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {this.props.inv_processs_all
            .slice(
            this.props.page * this.props.rowsPerPage,
            this.props.page * this.props.rowsPerPage +
            this.props.rowsPerPage
            )
            .map(process => {
            return (
            <TableRow key={process.id}>
            <TableCell component="th" scope="row">
            {process.batch_number}
            </TableCell>
            <TableCell component="th" scope="row">
            {process.stock_un.product.name}
            </TableCell>
            <TableCell component="th" align="right" scope="row">
            {parseFloat(
            (process.bins_total / process.total_bags) * 16
            ).toFixed(2)}
            </TableCell>
            <TableCell component="th" align="right" scope="row">
            {parseFloat(
            process.total_bags / process.expected_bags
            ).toFixed(2)}
            </TableCell>
            <TableCell component="th" align="right" scope="row">
            {parseFloat(
            (process.stock_un.price * process.quantity) /
            process.total_bags
            ).toFixed(2)}
            </TableCell>
            <TableCell component="th" align="right" scope="row">
            {process.quantity}
            </TableCell>

            <TableCell component="th" align="right" scope="row">
            {process.total_bags}
            </TableCell>
            <TableCell component="th" align="right" scope="row">
            <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => this.props.openDetail(process.id)}
            >
            <VisibilityIcon />
            </IconButton>
            </TableCell>
            </TableRow>
            );
            })}
            </TableBody>
              </Table>
            </TableContainer>
            }
            <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={this.props.processCount}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            onChangePage={this.props.handleChangePage}
            onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
          /> */}
        </ul>
      </div>
    );
  }
}

export default AllProcess;
