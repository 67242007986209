import { useQuery } from "@apollo/client";
import Edit from "@mui/icons-material/Edit";
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET_COURSES } from "../../../queries";
import RDFrontEnd from "./components/rd_frontend";

const WebsiteSettings = (props) => {
  const [god, setGod] = useState(false);
  const [author, setAuthor] = useState(false);

  useEffect(() => {
    const { permission } = props.session.activeAdmin;
    setGod(permission.god ? permission.god.admin : false);
    setAuthor(permission.author ? permission.author : false);
  }, [props.session]);

  return (
    <Container>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Website Settings</Typography>
          </Breadcrumbs>
        </div>
        <Grid container spacing={2}>
        <Grid item xs={2} md={2}>
          <RDFrontEnd />
        </Grid>
   
      </Grid>

      </div>
    </Container>
  );
};

export default WebsiteSettings;
