import { gql } from "@apollo/client";

const OPEN_ISSUE = gql`
  mutation sendToSlack($input: inputSlackRequest) {
    sendSlackRequest(input: $input)
  }
`;

const GET_LOG_LIST = gql`
  query GetLogs(
    $query: String
    $sort: SortType
    $email: String
    $limit: Int
    $offset: Int
    $from: Int
    $to: Int
  ) {
    logs(
      query: $query
      sort: $sort
      email: $email
      limit: $limit
      offset: $offset
      from: $from
      to: $to
    ) {
      admin {
        email
      }
      email
      query
      variables
      created_at
    }
    totalLogs
  }
`;

export { OPEN_ISSUE, GET_LOG_LIST };
