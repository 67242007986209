import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import { Grid, Paper, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { GET_CONSUME_STATISTICS } from "../../../../../queries";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import {
  FEEDREAL_LOGO,
  REALDOG_GOLDEN_LOGO,
} from "../../../Customers/Components/constants";
import { useTheme } from "@mui/material/styles";

export default function Statistics() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [getConsumeStatistics, { loading, data, error }] = useMutation(
    GET_CONSUME_STATISTICS
  );

  const onSubmitSearch = () => {
    getConsumeStatistics({
      variables: {
        input: {
          startDate: moment(startDate).unix().toString(),
          endDate: moment(endDate).unix().toString(),
        },
      },
      fetchPolicy: "no-cache",
    });
  };

  return (
    <div>
      <Button
        variant="outlined"
        fullWidth
        color="secondary"
        onClick={handleClickOpen}
      >
        Search Statistics by date
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          {"Enter dates and get statistics"}
        </DialogTitle>
        <DialogContent>
          <Dates
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            onSearch={onSubmitSearch}
            loading={loading}
          />
        </DialogContent>
        {error && (
          <DialogContent>
            <Typography>Error occured try other dates</Typography>
          </DialogContent>
        )}
        {data && (
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                borderRadius: 5,
                marginBottom: 20,
                backgroundColor: "black",
                border: "1px solid",
                borderColor: theme.palette.secondary.main + 60,
              }}
            >
              {data.getConsumeStatistics.planStatistics.map((item) => {
                return <PlanItem title={item.planName} count={item.count} />;
              })}
            </Grid>
            <MembershipStats
              membership={
                data.getConsumeStatistics.membershipStatistics.RDGMembership
              }
              feedrealMembership={
                data.getConsumeStatistics.membershipStatistics.feedrealMemberhip
              }
              newCustomers={data.getConsumeStatistics.newCustomers}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Dates = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onSearch,
  loading,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
      style={{ marginTop: 10 }}
    >
      <Grid item xs={6}>
        <DatePicker
          value={startDate || ""}
          onChange={(newValue) => {
            setStartDate(newValue);
          }}
          maxDate={new Date()}
          disabled={loading}
          renderInput={(params) => (
            <TextField
              style={{
                marginTop: -24,
              }}
              {...params}
              fullWidth
              error={false}
              label="Start date"
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          value={endDate || ""}
          onChange={(newValue) => {
            setEndDate(newValue);
          }}
          maxDate={new Date()}
          minDate={startDate}
          disabled={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={false}
              label="End date"
              helperText={"You must choose start date first"}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <LoadingButton
          fullWidth
          variant="outlined"
          onClick={() => {
            onSearch();
          }}
          color="secondary"
          loading={loading}
        >
          Search
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

const PlanItem = ({ title, count }) => {
  return (
    <Grid item xs={6} style={{ textAlign: "center", marginBottom: 10 }}>
      <Typography>{title}</Typography>
      <Typography>{count}</Typography>
    </Grid>
  );
};

const MembershipStats = ({ membership, feedrealMembership, newCustomers }) => {
  const theme = useTheme();
  return (
    <Paper elevation={0}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          borderRadius: 5,
          marginBottom: 20,
          backgroundColor: "black",
          border: "1px solid",
          borderColor: theme.palette.secondary.main + 60,
        }}
      >
        <Grid item xs={6} style={{ textAlign: "center", marginBottom: 10 }}>
          <img
            style={{
              width: "20%",
            }}
            src={REALDOG_GOLDEN_LOGO}
          />
          <Typography>
            {" "}
            New Membership <br></br> {membership}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "center", marginBottom: 10 }}>
          <img
            style={{
              width: "20%",
            }}
            src={FEEDREAL_LOGO}
          />
          <Typography>
            New Membership <br></br> {feedrealMembership}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
