import { useMutation } from "@apollo/client";
import { IconButton, Paper } from "@mui/material";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import Resizer from "react-image-file-resizer";
import { SINGLE_UPLOAD } from "../../../../queries";
import { startSingleUpload } from "../../../Funcs/Upload";
import PreviewIcon from "@mui/icons-material/Preview";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import draftToHtml from "draftjs-to-html";
import { Edit } from "@mui/icons-material";
import TutorialTitle from "./TutorialTitle";

const compressImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      700, // maxWidth
      1920, // maxHeight
      "JPEG", //compressFormat
      80, //quality
      0, // rotation
      (uri) => {
        // responseUriFunc
        resolve(uri);
      },
      "blob" // outputType
    );
  });

const TutorialTextEditor = ({
  onChangeNodeContent,
  rawContent,
  setDisabled = () => {},
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const [singleUpload, { loading, error }] = useMutation(SINGLE_UPLOAD);
  const uploadFile = async (file) => {
    console.log("file before compress", file);
    let img;
    if (file.type === "image/gif") {
      img = file;
    } else {
      img = await compressImage(file);
    }

    console.log("img", img);
    let res = "";
    await startSingleUpload(img, singleUpload, async (result) => {
      console.log("result single", result);
      res = result;
    });

    console.log("single upload res=>", res);
    return { data: { link: res } };
  };

  useEffect(() => {
    if (rawContent) {
      const content = JSON.parse(rawContent);
      const nextState = convertFromRaw(content);
      setEditorState(EditorState.createWithContent(nextState));
    }
  }, []);
  // const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const editorContains = convertToRaw(editorState.getCurrentContent());
    let timer = setTimeout(() => {
      onChangeNodeContent(JSON.stringify(editorContains));
      setDisabled(false);
    }, 300);
    setDisabled(true);
    return () => clearTimeout(timer);
  }, [editorState]);

  return (
    <Paper
      style={{
        padding: 20,
        backgroundColor: "black",
      }}
    >
      <Editor
        stripPastedStyles={true}
        editorStyle={{
          height: "30vh",
          width: "100%",
          overflowX: "hidden",
        }}
        initialContentState={
          rawContent
            ? JSON.parse(rawContent)
            : {
                entityMap: {},
                blocks: [],
              }
        }
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            // className: undefined,
            // component: undefined,
            // popupClassName: undefined,
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: async (e) => {
              const result = await uploadFile(e);
              console.log("uploadCallback=>", result);
              return result;
            },
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            // defaultSize: {
            //   height: "auto",
            //   width: "auto",
            // },
          },
        }}
      />
    </Paper>
  );
};

export default TutorialTextEditor;

export const TutorialTextEditorPreview = ({ tutorial }) => {
  const editorState = draftToHtml(JSON.parse(tutorial.text));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button startIcon={<PreviewIcon />} onClick={handleClickOpen}>
        Preview
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{tutorial.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              id={"article"}
              dangerouslySetInnerHTML={{ __html: editorState }}
            ></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const EditTutorialTextEditor = ({
  tutorial,
  updateEditorTutorial = () => {},
}) => {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState(tutorial.title);
  let temp = tutorial.text;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitSave = () => {
    updateEditorTutorial(temp, title);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Edit />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{tutorial.title}</DialogTitle>
        <DialogContent>
          <TutorialTitle
            title={title}
            setTitle={setTitle}
            placeHolder={"Title"}
          />
          <DialogContentText id="alert-dialog-description">
            {/* <div
              id={"article"}
              dangerouslySetInnerHTML={{ __html: editorState }}
            ></div> */}
            <TutorialTextEditor
              onChangeNodeContent={(data) => {
                temp = data;
              }}
              rawContent={tutorial.text}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
          <Button onClick={onSubmitSave}>save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
