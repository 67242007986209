import React, { useState } from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { GENERATE_GIFT, GET_GIFT_GROUPS } from "../../../../queries";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AddGiftGroup from "./addGiftGroup";

const GenerateButton = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [amount, setAmount] = useState(2);
  const [note, setNote] = useState("");
  const [expireSwitch, setExpireSwitch] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [giftGroup, setGiftGroup] = useState(null);

  const { data: giftGroups, refetch } = useQuery(GET_GIFT_GROUPS);
  const [createGift, { loading }] = useMutation(GENERATE_GIFT);

  const handleGenerate = () => {
    if (quantity > 0 && amount > 0) {
      createGift({
        variables: {
          input: {
            number: parseInt(quantity),
            amount: parseFloat(amount),
            createdAt: moment().unix(),
            ...(expireSwitch && { expiry: moment(selectedDate).unix() }),
            ...(note.length > 0 && { note: note }),
            ...(giftGroup && { group: giftGroup }),
          },
        },
      })
        .then(() => {
          enqueueSnackbar(`Coupons are created successfully.`, {
            variant: "success",
          });
          handleClose();
          props.refetch();
        })
        .catch((e) => {
          enqueueSnackbar(e.message, {
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar("Please write valid inputs.", {
        variant: "error",
      });
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  console.log(giftGroups);
  console.log(giftGroup);
  return (
    <>
      <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
        Generate Gift Code
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ minWidth: "200px" }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Generate Gift Code"}
        </DialogTitle>
        <DialogContent>
          <FormGroup
            sx={{
              "& .MuiFormControl-root": {
                marginBottom: "5px",
                marginTop: "5px",
              },
            }}
          >
            <TextField
              id="outlined-basic"
              label="Quantity"
              variant="outlined"
              type="number"
              fullWidth
              value={quantity}
              onChange={(e) => {
                let value = e.target.value || 1;
                if (value < 1) {
                  setQuantity(1);
                  return;
                } else {
                  setQuantity(e.target.value);
                }
              }}
            />
            <TextField
              id="outlined-basic"
              label="Amount"
              variant="outlined"
              type="number"
              fullWidth
              value={amount}
              onChange={(e) => {
                let value = e.target.value || 2;
                if (value < 2) {
                  setAmount(2);
                  return;
                } else {
                  setAmount(e.target.value);
                }
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  value={expireSwitch}
                  onChange={() => {
                    setExpireSwitch(!expireSwitch);
                  }}
                />
              }
              label={!expireSwitch ? "Does not expire" : "Selected Date"}
            />
            {expireSwitch && (
              <DesktopDatePicker
                minDate={new Date()}
                placeholder={"YYYY-MM-DD"}
                label="Expired Date"
                inputFormat={"yyyy-MM-dd"}
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{
                  "& .css-z1qp82-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                    {
                      color: "#000",
                    },
                }}
              />
            )}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Gift Group</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={giftGroup}
                label="Gift Group"
                onChange={(e) => {
                  setGiftGroup(e.target.value);
                }}
              >
                {giftGroups?.getGiftGroups.map((group) => (
                  <MenuItem value={group.id}>{group.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <AddGiftGroup refetch={refetch} />
            <TextField
              id="outlined-basic"
              label="Note"
              variant="outlined"
              type="text"
              multiline
              rows={4}
              fullWidth
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={handleGenerate}
            autoFocus
            color="secondary"
          >
            {loading ? <CircularProgress /> : "Generate"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenerateButton;
