import { Edit } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CompanyItem from "./CompanyItem";
import { useQuery } from "@apollo/client";
import { GET_DIRECTIRY_STATE } from "../../../queries";
import { LinearProgress } from "@mui/material";

const SingleDirectoryState = ({ singleDirectoryState }) => {
  const labelId = `checkbox-list-label-${singleDirectoryState.id}`;
  const [open, setOpen] = useState(false);

  const { data, loading, error, refetch } = useQuery(GET_DIRECTIRY_STATE, {
    variables: {
      id: singleDirectoryState.id,
    },
    fetchPolicy: "no-cache",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <div>Error </div>;
  }
  return (
    <React.Fragment>
      <ListItem key={data.getDirectoryState.id} disablePadding>
        <ListItemIcon>
          <IconButton
            edge="end"
            aria-label="comments"
            onClick={handleClickOpen}
          >
            <Edit />
          </IconButton>
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={`${data.getDirectoryState.name} (${data.getDirectoryState.companies.length} companies)`}
        />
      </ListItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          State Name: {data.getDirectoryState.name}
        </DialogTitle>
        <DialogContent>
          <List>
            {data.getDirectoryState.companies.map((companyItem) => {
              return (
                <ListItem>
                  <ListItemIcon>
                    <CompanyItem
                      singleDirectoryState={data.getDirectoryState}
                      refetch={refetch}
                      singleCompany={companyItem}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${companyItem.name} `}
                    secondary={`${companyItem.phone} `}
                  />
                </ListItem>
              );
            })}
          </List>
          <CompanyItem
            singleDirectoryState={data.getDirectoryState}
            refetch={refetch}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SingleDirectoryState;
