import gql from "graphql-tag";

export const GET_CONSUME_STATISTICS = gql`
  mutation GetConsumeStatistics($input: inputStatistics) {
    getConsumeStatistics(input: $input) {
      planStatistics {
        planName
        count
      }
      membershipStatistics {
        RDGMembership
        feedrealMemberhip
      }
      newCustomers
    }
  }
`;
