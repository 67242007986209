import React, { useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { createRandKey } from "../../../globals";
import { UploadFile } from "../../Tools/Upload";
import { styled } from "@mui/material/styles";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const CreateProductDialog = ({ openDiag, setDiag, handleCreateProduct }) => {
  const [productShow, setProductShow] = useState(false);
  const [attributesSettings, setAttributesSettings] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [promoText, setPromoText] = useState("");
  const isInitialMount = useRef(true);

  const uploadProductImage = (file) => {
    if (file) {
      const imgArr = images;
      imgArr.push(file.uri);
      setImages((images) => [...imgArr]);
    }
  };

  const onImageDelete = (image) => {
    const currentImages = images.slice();
    setImages(currentImages.filter((img) => img !== image));
  };
  return (
    <Dialog
      open={openDiag}
      //onClose={handleClickUpdate}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <div>
        <DialogTitle id="alert-dialog-title">New Product</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                name="name"
                label="Name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                width="50%"
                fullWidth
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={productShow}
                    onChange={() => setProductShow(!productShow)}
                    name="checkedA"
                  />
                }
                label="Publish"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={attributesSettings}
                    onChange={() => setAttributesSettings(!attributesSettings)}
                    name="checkedA"
                  />
                }
                label="Attributes(Gender/Size)"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                name="promotext"
                label="Promo Text"
                type="text"
                value={promoText}
                onChange={(e) => setPromoText(e.target.value)}
                width="50%"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                multiline
                rows={4}
                margin="normal"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                name="comments"
                label="Description"
                type="text"
                fullWidth
                //onChange={updateComment}
              />

              {images &&
                images.map((image) => (
                  <div className="imagesecret">
                    <div className="featuredImgBlock">
                      <div className="deletephotobtn">
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          onClick={() => onImageDelete(image)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                      <img width={"100%"} src={image} />
                    </div>
                  </div>
                ))}
              <UploadFile
                idKey={"create-uplad-image-key-" + createRandKey(6)}
                uploadPhoto={uploadProductImage}
                buttonName={"Add Featured Image"}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDiag()} color="primary">
            Close
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              let arr = {
                name,
                description,
                type: "good",
                metadata: {
                  show: productShow ? "secretshop" : " ",
                  promotext: promoText ? promoText : " ",
                },
                images: images,
              };
              arr = {
                ...arr,
                attributes: attributesSettings ? ["size", "gender"] : [],
              };
              setName();
              setDescription();
              setPromoText();
              setProductShow();
              setAttributesSettings();
              handleCreateProduct(arr);
            }}
            autoFocus
          >
            Add Product
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

const CreateSKUElement = ({
  handleSKUCreate,
  handleCancel,
  skuCount,
  attributes,
}) => {
  const [price, setPrice] = useState();
  const [quantity, setQuantiy] = useState();
  const [size, setSize] = useState(null);
  const [weight, setWeigt] = useState();
  const [gender, setGender] = useState(null);
  const [typeOpen, setTypeOpen] = useState(false);
  const [type, setType] = useState("");
  const [retailer, setRetailer] = useState(false);
  const classes = useStyles();

  const addStockUnits = (e) => {
    e.preventDefault();
    let data = {
      price: parseInt(price * 100),
      currency: "usd",
      inventory: {
        quantity: parseInt(quantity),
        type: type || "finite",
      },
      package_dimensions: {
        weight: parseFloat(weight),
        length: 0,
        width: 0,
        height: 0,
      },
      attributes: {},
      metadata: {
        type: retailer ? "retailer" : "member",
      },
    };
    if (gender) {
      data.attributes.gender = gender;
    }
    if (size) {
      data.attributes.size = size;
    }
    handleSKUCreate(data);
  };
  return (
    <div>
      <form onSubmit={(e) => addStockUnits(e)} className="skus_box">
        <h3>Add New Sku</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              name="price"
              label="Price ($)"
              type="number"
              required
              value={price}
              width="50%"
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              name="quantity"
              label="Quantity"
              type="number"
              required
              value={quantity}
              width="50%"
              onChange={(e) => setQuantiy(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              name="weight"
              label="Weight"
              type="number"
              required
              value={weight}
              width="50%"
              onChange={(e) => setWeigt(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <div
              style={{
                paddingTop: 16,
              }}
            >
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Type</InputLabel>
                <Select
                  open={typeOpen}
                  required
                  onClose={() => setTypeOpen(false)}
                  onOpen={() => setTypeOpen(true)}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={"finite"} selected>
                    Finite
                  </MenuItem>
                  <MenuItem value={"infinite"}>Infinite</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          {attributes.length > 0 ? (
            <Grid item xs={12} sm={12}>
              <TextField
                required
                variant="outlined"
                margin="normal"
                name="size"
                label="Size"
                type="text"
                value={size}
                width="50%"
                onChange={(e) => setSize(e.target.value)}
                fullWidth
              />
            </Grid>
          ) : (
            ""
          )}
          {attributes.length > 0 ? (
            <Grid item xs={12} sm={12}>
              <TextField
                required
                variant="outlined"
                margin="normal"
                name="gender"
                label="Gender"
                type="text"
                value={gender}
                width="50%"
                onChange={(e) => setGender(e.target.value)}
                fullWidth
              />
            </Grid>
          ) : (
            ""
          )}
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={retailer}
                  onChange={() => setRetailer(!retailer)}
                  name="jason"
                />
              }
              label={retailer ? "Retailer" : "Members"}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button type="submit">Add Stock</Button>
          </Grid>

          {skuCount > 0 && (
            <Grid item xs={12} sm={12}>
              <Button onClick={() => handleCancel()}>Cancel</Button>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

export { CreateProductDialog, CreateSKUElement };
