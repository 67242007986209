import React, { useEffect, useState } from "react";
import bwipjs from "bwip-js";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Alert from "@mui/material/Alert";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_BARCODE,
  GET_BARCODES,
  GET_INVENTORY_PRODUCTS,
} from "../../../queries";
import { useSnackbar } from "notistack";
import { InventoryAutoComplete } from "../Stock/UnProcessing";

const BARCODE_HEIGHT = 24;

const CreateBarcodeDialog = ({ refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [inventoryId, setInventoryId] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [text, setText] = useState("");
  const [textOrigin, setTextOrigin] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");

  const [createBarcodeMutation, { loading }] = useMutation(CREATE_BARCODE);
  const [savedBarcodes, setSavedBarcodes] = useState([]);
  const {
    loading: loadingProd,
    error: errorProd,
    data: dataProd,
  } = useQuery(GET_INVENTORY_PRODUCTS);

  const {
    loading: loadingBarcodes,
    error: errorBarcodes,
    data: dataBarcodes,
    refetch: refetchBarcodes,
  } = useQuery(GET_BARCODES);

  useEffect(() => {
    refetchBarcodes();
  }, [open]);
  useEffect(() => {
    if (dataBarcodes) {
      setSavedBarcodes(dataBarcodes.getBarcodes);
    }
  }, [loadingBarcodes, errorBarcodes, dataBarcodes]);

  const createBarcode = () => {
    if (inventoryId === "") {
      enqueueSnackbar("Please select a Product", {
        variant: "error",
      });
    }
    try {
      setShow(true);
      setError("");
      bwipjs.toCanvas("mycanvas", {
        bcid: "upca",
        text: text,
        scale: 2,
        height: BARCODE_HEIGHT,
        includetext: true,
        textxalign: "center",
      });
      setTextOrigin(text);
      setText("");
    } catch (e) {
      console.log("ERROR");
      console.log(e);
      setError("Change Text Input");
      setTextOrigin("");
    }
  };

  const saveBarcode = () => {
    createBarcodeMutation({
      variables: {
        input: {
          text: textOrigin,
          productId: inventoryId,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(`Barcode Created Successfully`, {
          variant: "success",
        });
        handleClose();
        refetch();
      })
      .catch((e) => {
        const message = JSON.parse(JSON.stringify(e)).message;
        enqueueSnackbar(message, {
          variant: "error",
        });
      });
  };
  const handleProduct = (id) => {
    setInventoryId(id);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create Barcode
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{" Create Barcode"}</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item>
              <div
                style={{
                  width: "100%",
                  minHeight: 150,
                  backgroundColor: "white",
                  borderRadius: 5,
                  textAlign: "center",
                  paddingTop: 30,
                }}
              >
                <canvas
                  id="mycanvas"
                  style={{
                    display: (error.length !== 0 || !show) && "none",
                  }}
                ></canvas>
                {error.length !== 0 && (
                  <Typography
                    color={"error"}
                    style={{
                      paddingTop: 40,
                    }}
                  >
                    {error}
                  </Typography>
                )}
                {!show && (
                  <Typography
                    color={"black"}
                    style={{
                      paddingTop: 40,
                    }}
                  >
                    Please enter code
                  </Typography>
                )}
              </div>
              <br></br>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12}>
                  <InventoryAutoComplete
                    dataProd={
                      dataProd?.inv_products?.filter(
                        (item) =>
                          !savedBarcodes
                            .map((element) => element.productId)
                            .includes(item.id)
                      ) || []
                    }
                    handleProduct={handleProduct}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    label="Code"
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    size={"small"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    color="secondary"
                    disabled={text.length === 0 || inventoryId === ""}
                    onClick={() => {
                      createBarcode();
                    }}
                    fullWidth
                  >
                    Create{" "}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={textOrigin.length === 0}
            onClick={saveBarcode}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CreateBarcodeDialog;

export const ShowBarcode = ({ text }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createBarcode = () => {
    try {
      bwipjs.toCanvas("mycanvas", {
        bcid: "upca",
        text: text,
        scale: 2,
        height: BARCODE_HEIGHT,
        includetext: true,
        textxalign: "center",
      });
    } catch (e) {
      console.log("ERROR =======>", e);
    }
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        createBarcode();
      }, 300);
    }
  }, [open]);

  return (
    <div>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={handleClickOpen}
      >
        <VisibilityIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          <Alert
            icon={false}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(`${text}`);
                }}
              >
                <InsertLinkIcon />
              </Button>
            }
          >
            {text}
          </Alert>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              width: "100%",
              minHeight: 150,
              paddingTop: 30,
              backgroundColor: "white",
              borderRadius: 5,
              textAlign: "center",
            }}
          >
            <canvas id="mycanvas"></canvas>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
