import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Edit from "@mui/icons-material/Edit";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Tooltip from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { Typography } from "@mui/material";
export const mobileScreenFilter = ["description", "created", "updated"];

const ColumnDefs = [
  { field: "name", headerName: "Name", flex: 1, sortable: true },
  { field: "description", headerName: "Description", flex: 1, sortable: false },
  {
    field: "metadata",
    headerName: "Status",
    sortable: false,
    width: 70,
    // valueGetter: (data) => {
    //   if (!data.row.metadata) {
    //     return "Inactive";
    //   }
    //   if (
    //     data.row.metadata.show &&
    //     data.row.metadata.show.toLowerCase() === "secretshop"
    //   ) {
    //     return "Published";
    //   }
    //   return "Inactive";
    // },
    renderCell: (data) => {
      if (!data.row.metadata) {
        return (
          <Typography
            style={{
              width: "100%",
            }}
            align="center"
          >
            <CircleIcon
              style={{
                fill: "red",
              }}
            />
          </Typography>
        );
      }
      if (
        data.row.metadata.show &&
        data.row.metadata.show.toLowerCase() === "secretshop"
      ) {
        return (
          <Typography
            style={{
              width: "100%",
            }}
            align="center"
          >
            <CircleIcon
              style={{
                fill: "green",
              }}
            />
          </Typography>
        );
      }
      return (
        <Typography
          style={{
            width: "100%",
          }}
          align="center"
        >
          <CircleIcon
            style={{
              fill: "red",
            }}
          />
        </Typography>
      );
    },
  },
  {
    field: "created",
    headerName: "Created Date",
    flex: 1,
    renderCell: (data) => {
      return new Date(data.row.created * 1000).toLocaleDateString("en-US");
    },
    valueGetter: (data) => {
      return data.row.updated;
    },
  },
  {
    field: "updated",
    headerName: "Updated Date",
    flex: 1,
    renderCell: (data) => {
      return new Date(data.row.updated * 1000).toLocaleDateString("en-US");
    },
    valueGetter: (data) => {
      return data.row.updated;
    },
  },
  {
    field: "action",
    headerName: "Actions",
    flex: 1,
    sortable: false,
    renderCell: (data) => (
      <>
        {!data.row.hasSKU ? (
          <>
            <Link
              component={Button}
              variant="outlined"
              color="secondary"
              onClick={() => data.row.edit(data.row.stripeID, data.row.id)} // ID is here
            >
              <Edit />
            </Link>
            <Tooltip title="There is no SKU in this product.">
              <NotificationsActiveIcon
                color="secondary"
                fontSize="large"
                style={{ cursor: "pointer" }}
                onClick={() => data.row.edit(data.row.stripeID, data.row.id)} // ID is here
              />
            </Tooltip>
          </>
        ) : (
          <Link
            component={Button}
            variant="outlined"
            color="secondary"
            onClick={() => data.row.edit(data.row.stripeID, data.row.id)} // ID is here
          >
            <Edit />
          </Link>
        )}
      </>
    ),
  },
];

export { ColumnDefs };
