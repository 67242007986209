import React, { Component, useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client";

import { GET_ACTIVE_ADMIN, FROM_FIND } from "../../../queries";
import moment from "moment";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "16px",
    marginBottom: "8px",
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function FromFind(props) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [customersData, setCustomersData] = useState([]);
  const isInitialMount = useRef(true);
  const [getFrom, { loading: loadingSearch, data: searchData }] = useLazyQuery(
    FROM_FIND,
    {
      fetchPolicy: "no-cache",
      variables: {
        from: search,
      },
      onCompleted: (data) => {
        if (data) {
          console.log("data", data);
          setCustomersData(data.fromFind);
        }
      },
    }
  );

  useEffect(() => {
    console.log("searchData", isInitialMount);
    console.log("search", search);

    if (!isInitialMount.current) {
      isInitialMount.current = true;
      getFrom();
    }
  }, [customersData, search]);

  const searchInput = (event) => {
    if (event.target.value.length >= 3) {
      isInitialMount.current = false;
      setSearch(event.target.value);
    }
  };
  const { enqueueSnackbar } = useSnackbar();

  return (
    <CustomerFindUI
      setCustomer={props.setCustomer}
      history={props.history}
      searchInput={searchInput}
      customersData={customersData}
      loadingSearch={loadingSearch}
      classes={classes}
      enqueueSnackbar={() => {
        enqueueSnackbar("Edit section is disabled for this user now ", {
          variant: "error",
        });
      }}
      setFrom={props.setFrom}
    />
  );
}

export class CustomerFindUI extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              id="REALDOG - REAL SEARCH"
              noOptionsText={
                "Please search customer with phone number or email"
              }
              loading={this.props.loadingSearch}
              options={this.props.customersData}
              getOptionLabel={(option) => `${option}`}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <div className="searchresult">
                    <b>{option}</b>
                  </div>
                </li>
              )}
              onChange={(e, value) => {
                let newValue = value || "";
                // console.log(newValue);

                console.log("newValue", newValue);
                // if (newValue.created_at >= 1642718118) {
                //   this.props.enqueueSnackbar();
                //   return;
                // }

                // return this.props.history.push(
                //   `/dashboard/customer/${newValue.id}`
                // );
                this.props.setFrom(newValue);
              }}
              renderInput={(customer) => (
                <TextField
                  {...customer}
                  label="Search From"
                  margin="normal"
                  onChange={this.props.searchInput}
                  variant="outlined"
                  InputProps={{ ...customer.InputProps, type: "search" }}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default FromFind;
