import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DoNotCancelMembership from "./Components/DontCancelMembership";
import ClearIcon from "@mui/icons-material/Clear";
import StarIcon from "@mui/icons-material/Star";
import Alert from "@mui/material/Alert";
import FeedrealMembershipDialog, {
  FeedrealMembershipCancelDialog,
} from "./FeedrealMembershipDialog";
import UpgradeMembership from "./Components/upgradeMembership";

export default function MembershipOperations({
  data,
  handleClickOpenCancelM,
  handleClickOpenM,
  refetch,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        startIcon={<StarIcon />}
        className="yellowborder btn-text-left btn-text-margin-left"
      >
        Memberships Operations
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">{"Membership"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data.customer.premium &&
            data.customer.stripe.subscription.filter(
              (x) =>
                x.items?.data &&
                x.items.data.find(
                  (y) =>
                    y.plan.id ===
                      process.env.REACT_APP_PREMIUM_MONTHLY_RD_USA ||
                    y.plan.id === process.env.REACT_APP_PREMIUM_MONTHLY_RD_CA
                )
            ).length > 0 ? (
              <UpgradeMembership
                data={data}
                refetch={refetch}
                onClose={handleClose}
              />
            ) : (
              ""
            )}
            {/* {data.customer.stripe?.subscription.find(
              (item) =>
                item.items.data[0].plan.product ===
                process.env.REACT_APP_PREMIUM_MEMBERSHIP_ID
            )?.cancel_at_period_end ? (
              <DoNotCancelMembership
                subId={
                  data.customer.stripe?.subscription.find(
                    (item) =>
                      item.items.data[0].plan.product ===
                      process.env.REACT_APP_PREMIUM_MEMBERSHIP_ID
                  ).id
                }
                done={() => {
                  refetch();
                }}
              />
            ) : data.customer.premium ? (
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className="redborder btn-text-left  btn-text-left btn-text-margin-left"
                startIcon={<ClearIcon />}
                onClick={handleClickOpenCancelM}
                //disabled={canine.locked}
              >
                Cancel RDB Membership
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<StarIcon />}
                fullWidth
                className="yellowborder btn-text-left btn-text-margin-left"
                onClick={handleClickOpenM}
                //disabled={canine.locked}
              >
                Add RDB Membership
              </Button>
            )} */}

            <div style={{ height: 10 }}></div>
            {data.customer.premium === 0 ? (
              <>
                {data.customer.feedrealPremium === 1 ? (
                  <FeedrealMembershipCancelDialog
                    customerData={data}
                    refetch={refetch}
                  />
                ) : (
                  <FeedrealMembershipDialog
                    customerData={data}
                    refetch={refetch}
                  />
                )}
              </>
            ) : (
              <>
                <Alert severity="info">
                  RDB Membership covers Feedreal Membership
                </Alert>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
