import { gql } from "@apollo/client";
const GET_RETAILER_REQ_PENDING = gql`
  query getRetailerReqs {
    getRetailersReqPending {
      id
      bussiness_name
      type_of_bussiness
      first_name
      last_name
      email
      phone_number
      status
      createdAt
      shipping {
        name
        address
        address1
        address2
        postal_code
        state
        city
      }
      social {
        website
        tiktok
        instagram
        facebook
      }
    }
  }
`;
const UPDATE_RETAILER_REQ = gql`
  mutation UpdateRetailerReq($id: ID!, $input: inputRetailerRequest) {
    updateRetailerReq(id: $id, input: $input) {
      id
      bussiness_name
      type_of_bussiness
      first_name
      last_name
      email
      phone_number
      createdAt
      shipping {
        name
        address
        address1
        address2
        postal_code
        state
        city
      }
    }
  }
`;

const RETAILER_STATUS_UPDATE = gql`
  mutation ChangeStatus($id: ID!, $status: String!) {
    changeStatusRetailerReq(id: $id, status: $status) {
      id
      bussiness_name
      status
    }
  }
`;

export {
  GET_RETAILER_REQ_PENDING,
  UPDATE_RETAILER_REQ,
  RETAILER_STATUS_UPDATE,
};
