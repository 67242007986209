import React, { Component, useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client";

import { GET_ACTIVE_ADMIN, TITLE_FIND } from "../../../../../queries";
import moment from "moment";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SearchTitle(props) {
  const isInitialMount = useRef(true);
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [result, setResult] = useState([]);

  const [getSearchResult, { loading: loadingSearch, data: searchData }] =
    useLazyQuery(TITLE_FIND, {
      fetchPolicy: "no-cache",
      variables: {
        filter: search,
        website: props.website,
      },
      onCompleted: (data) => {
        console.log("data =>", data);
        setResult(data.titleFind);
      },
    });

  useEffect(() => {
    console.log("use effect data=>", result);
    if (!isInitialMount.current) {
      getSearchResult();
    }
  }, [result, getSearchResult, search]);

  const handleSearchChange = (event) => {
    if (event.target.value.length >= 4) {
      isInitialMount.current = false;
      setSearch(event.target.value);
    }
  };
  const handleSearchResult = (res) => {
    this.props.handleSearchResult(res);
  };

  return (
    <Autocomplete
      id="REALDOG - REAL SEARCH"
      noOptionsText={"Search a title for Real Dog"}
      loading={loadingSearch}
      options={result}
      getOptionLabel={(option) => `${option.title}`}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <div className="searchresult">
            <b>{option.title}</b>
            <span>{option.sefurl}</span>
          </div>
        </li>
      )}
      onChange={(e, value) => {
        let newValue = value || "";
        console.log("onchange=>", newValue);
        props.handleSearchResult(newValue.sefurl ? newValue.sefurl : newValue);
      }}
      renderInput={(res) => (
        <TextField
          {...res}
          label="Search Title"
          margin="normal"
          onChange={(e) => handleSearchChange(e)}
          variant="outlined"
          size="small"
          InputProps={{ ...res.InputProps, type: "search" }}
        />
      )}
    />
  );
}
