import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

import { useMutation } from "@apollo/client";
import {
  CANCEL_FEEDREAL_MEMBERSHP,
  FEEDREAL_MEMBERSHIP,
} from "../../../queries";
import { CheckBox } from "@mui/icons-material";
export default function FeedrealMembershipDialog({ customerData, refetch }) {
  const [open, setOpen] = useState(false);
  const [freeSwitch, setFreeSwitch] = useState(false);
  const [cycleCount, setCycleCount] = useState(0);
  const [cancelEndOfCycle, setCancelEndOfCycle] = useState(false);

  const handleSetCycleCount = (value) => {
    setCycleCount(parseInt(value));
  };

  const handleCancelAtEndOfCycle = (value) => {
    setCancelEndOfCycle(value);
  };

  const [makeFeedrealMembership, { loading, data, error }] =
    useMutation(FEEDREAL_MEMBERSHIP);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFreeSwitch(false);
    handleSetCycleCount(0);
  };
  const onConfirmFeedrealMembership = () => {
    makeFeedrealMembership({
      variables: {
        input: {
          customer: customerData.customer.stripe.id,
          cycleCount: cycleCount,
          cancelEndOfCycle: cancelEndOfCycle,
        },
      },
    })
      .then((res) => {
        handleClose();
        refetch();
      })
      .catch(() => {
        console.log(error);
      });
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        startIcon={<StarHalfIcon />}
        className="yellowborder btn-text-left btn-text-margin-left"
      >
        Add Feedreal Membership
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add Feedreal Membership Membership"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Free Access to articles.
            <br></br> Are you sure?
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ marginBottom: "10px" }}
            >
              <FormGroup sx={{ marginBottom: "10px", marginTop: "10px" }}>
                <FormControlLabel
                  control={
                    <Switch
                      color="success"
                      checked={freeSwitch}
                      onChange={() => {
                        if (freeSwitch) {
                          handleSetCycleCount(0);
                        } else {
                          handleSetCycleCount(1);
                        }
                        setFreeSwitch(!freeSwitch);
                      }}
                    />
                  }
                  label="Free Subscription"
                />
              </FormGroup>
              {freeSwitch && (
                <>
                  <FormControl sx={{ minWidth: "200px" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ marginBottom: "10px" }}
                    >
                      Select Free Subscription Cycles
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={cycleCount}
                      label="Age"
                      onChange={(e) => handleSetCycleCount(e.target.value)}
                    >
                      {Array(12)
                        .fill(1)
                        .map((v, i) => {
                          return <MenuItem value={i + 1}>{i + 1}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cancelEndOfCycle}
                        onChange={(e) => setCancelEndOfCycle(e.target.checked)}
                      />
                    }
                    label="Cancel at end of Cycle"
                  />
                </>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button
            onClick={() => {
              onConfirmFeedrealMembership();
            }}
          >
            Confirm
          </Button> */}
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            onClick={() => {
              onConfirmFeedrealMembership();
            }}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const FeedrealMembershipCancelDialog = ({ customerData, refetch }) => {
  const [open, setOpen] = React.useState(false);
  const [cancelEndOfCycle, setCancelEndOfCycle] = useState(true);

  const [cancelFeedrealMembership, { loading, data, error }] = useMutation(
    CANCEL_FEEDREAL_MEMBERSHP
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onConfirmCancelFeedrealMembership = () => {
    cancelFeedrealMembership({
      variables: {
        customer: customerData.customer.stripe.id,
        cancel_at_period_end: cancelEndOfCycle,
      },
    })
      .then((res) => {
        handleClose();
        refetch();
      })
      .catch(() => {
        console.log(error);
      });
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        startIcon={<StarHalfIcon />}
        className="fdborder btn-text-left btn-text-margin-left"
      >
        Cancel Feedreal Membership
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add Feedreal Membership Membership"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Free Access to articles.
            <br></br> Are you sure you want to cancel feedreal membership?
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": {
                    ...(cancelEndOfCycle
                      ? {
                          color: "#4caf50",
                        }
                      : {
                          color: "#f44336",
                        }),
                  },
                }}
                checked={cancelEndOfCycle}
                onChange={(e) => setCancelEndOfCycle(e.target.checked)}
              />
            }
            label="Cancel at end of Cycle"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            onClick={() => {
              onConfirmCancelFeedrealMembership();
            }}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
