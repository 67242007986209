import { useMutation, useQuery } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import {
  Breadcrumbs,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MuiPhoneNumber from "material-ui-phone-number";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import {
  GET_VET_REQ_PENDING,
  UPDATE_VET_FORM,
  VET_STATUS_UPDATE,
} from "../../../queries";

const APPROVED = "approved";
const DECLINED = "declined";
const bussinessTypes = [
  {
    value: "Veterinarian",
    label: "Veterinarian",
  },
  {
    value: "Veterinary Technician / Nurse",
    label: "Veterinary Technician / Nurse",
  },
  {
    value: "Veterinary Staff",
    label: "Veterinary Staff",
  },
  {
    value: "Retailer",
    label: "Retailer",
  },
  {
    value: "Trainer",
    label: "Trainer",
  },
  {
    value: "Groomer",
    label: "Groomer",
  },
  {
    value: "Food Maker",
    label: "Food Maker",
  },
];

const VetRequests = () => {
  const { data, loading, error, refetch } = useQuery(GET_VET_REQ_PENDING);

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <div>Error</div>;
  }
  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Typography color="textPrimary">Vet Requests</Typography>
          </Breadcrumbs>
        </div>

        {data.getVetFormPending.map((item, index) => {
          return (
            <VetRequestItem
              key={"retailer-key-" + item.id}
              item={item}
              refetch={refetch}
            />
          );
        })}
      </div>
    </div>
  );
};
export default VetRequests;

const VetRequestItem = ({ item, refetch = () => {} }) => {
  const {
    firstName,
    lastName,
    companyName,
    emailAddress,
    phoneNumber,
    businessType,
    nutritionFree,
    endorsing,
    carryMoreInfo,
    struggle,
    struggleText,
    status,
    createdAt,
  } = item;
  const { enqueueSnackbar } = useSnackbar();
  console.log(createdAt);
  const reqTime = new Date(parseInt(createdAt));
  const [updateStatusMutation, { loading }] = useMutation(VET_STATUS_UPDATE);

  const onConfirm = () => {
    updateStatusMutation({ variables: { id: item.id, status: APPROVED } })
      .then((res) => {
        refetch();
        enqueueSnackbar("Updated Successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        const errorMessage = JSON.parse(JSON.stringify(err));
        enqueueSnackbar(errorMessage.message, {
          variant: "error",
        });
      });
    refetch();
  };
  const onDecline = (status) => {
    updateStatusMutation({ variables: { id: item.id, status: DECLINED } })
      .then((res) => {
        refetch();
        enqueueSnackbar("Declined Successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        const errorMessage = JSON.parse(JSON.stringify(err));
        enqueueSnackbar(errorMessage.message, {
          variant: "error",
        });
      });
    refetch();
  };
  console.log(item);

  return (
    <div
      style={{
        maxWidth: 600,
        borderRadius: 5,
        padding: 20,
        marginBottom: 10,
        position: "relative",
        backgroundColor: "#222429",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      }}
    >
      <div
        style={{
          position: "absolute",
          right: 10,
          top: 10,
        }}
      >
        <UpdateRetailer item={item} refetch={refetch} />
        <Typography>{moment(reqTime).format("MM/DD/YYYY")}</Typography>
        <Typography variant="subtitle2" textAlign={"center"}>
          {moment(reqTime).format("LT")}
        </Typography>
      </div>

      <Typography variant="h5">
        {firstName} {lastName}
      </Typography>
      <Typography>
        <b>Business Name: </b> {companyName}
      </Typography>
      <Typography>
        <b>Type of Bussiness: </b> {businessType}
      </Typography>
      <Typography>
        <b>Phone Number: </b> {phoneNumber}
      </Typography>
      <Typography>
        <b>Email:</b> {emailAddress}
      </Typography>
      {/* <Typography>
        <b>Address: </b> {addressText}
      </Typography> */}
      <br></br>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Chip
          color="secondary"
          label={status.toUpperCase()}
          style={{
            backgroundColor: "yellow",
            color: "black",
          }}
        />
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <ConfirmVet
              type={DECLINED}
              firstName={firstName}
              lastName={lastName}
              onConfirm={onDecline}
              loading={loading}
            />
            <ConfirmVet
              type={APPROVED}
              firstName={firstName}
              lastName={lastName}
              onConfirm={onConfirm}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const ConfirmVet = ({ type, firstName, lastName, onConfirm, loading }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const color = type === APPROVED ? "success" : "error";
  return (
    <div>
      <Button
        variant="outlined"
        color={color}
        onClick={handleClickOpen}
        style={{
          marginLeft: 10,
        }}
      >
        {type === APPROVED && "Confirm"}
        {type === DECLINED && "Decline"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to create Vet User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {firstName} {lastName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              handleClose();
            }}
            color={color}
            autoFocus
            disabled={loading}
          >
            {type === APPROVED && "Confirm"}
            {type === DECLINED && "Decline"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const UpdateRetailer = ({ item, refetch }) => {
  const [updateVetFormMutation] = useMutation(UPDATE_VET_FORM);
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const [companyName, setCompanyName] = useState(item.companyName);
  const [firstName, setFirstName] = useState(item.firstName);
  const [lastName, setLastName] = useState(item.lastName);
  const [businessType, setBusinessType] = useState(item.businessType);
  const [phoneNumber, setPhoneNumber] = useState(item.phoneNumber);
  const [emailAddress, setEmailAddress] = useState(item.emailAddress);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    updateRetailerRequest();
  };

  const createInput = () => {
    const input = {
      companyName: companyName,
      businessType: businessType,
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      phoneNumber: phoneNumber,
    };
    return input;
  };
  const updateRetailerRequest = () => {
    const input = createInput();
    updateVetFormMutation({
      variables: { id: item.id, input },
    })
      .then((res) => {
        enqueueSnackbar("Updated Successfully", {
          variant: "success",
        });
        handleClose();
        refetch();
      })
      .catch((err) => {
        const errorMessage = JSON.parse(JSON.stringify(err));
        enqueueSnackbar(errorMessage.message, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edit Vet"}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            paddingTop: "10px",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            ["& .MuiTextField-root"]: {
              marginTop: "12px",
              width: "48%",
            },
          }}
        >
          <TextField
            variant="outlined"
            label="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <TextField
            fullWidth
            // disabled={loading}
            id="outlined-select-currency"
            select
            label="Business type"
            value={businessType}
            onChange={(e) => {
              setBusinessType(e.target.value);
            }}
            FormHelperTextProps={{
              style: {
                fontSize: 14,
              },
            }}
          >
            {bussinessTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          <MuiPhoneNumber
            defaultCountry={"us"}
            label="Phone Number"
            sx={{ margin: "20px 0px" }}
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e);
            }}
            fullWidth
            disableDropdown={true}
            id="outlined-disabled"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

/*
{
    "id": "631f3ba2592d7226db0dbb50",
    "firstName": "Oğuzhan",
    "lastName": "Atasever",
    "companyName": "RDB",
    "emailAddress": "oguz@real.dog",
    "phoneNumber": "+905062930340",
    "businessType": "Veterinary Technician / Nurse",
    "nutritionFree": false,
    "endorsing": false,
    "carryMoreInfo": false,
    "struggle": "Staff education generally",
    "struggleText": "",
    "status": "pending",
    "createdAt": "1662991266357",
    "updatedAt": "1662991266357"
}
*/
