import { useLazyQuery, useMutation } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { GET_SETTINGS, SET_SETTINGS } from "../../../../queries";
import { useSnackbar } from "notistack";
import moment from "moment";

const TYPE = "RD_FRONTEND";

export default function RDFrontEnd() {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [setting, setSettings] = useState();

  const [unboxing, setUnboxing] = useState("");
  const [unboxingLink, setUnboxingLink] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [getSetting, { loading, refetch }] = useLazyQuery(GET_SETTINGS, {
    onCompleted: (data) => {
      setUnboxing(data.getSettingsByType.options.unboxing);
      setUnboxingLink(data.getSettingsByType.options.unboxing_link);
      setSettings(data.getSettingsByType);
    },
    fetchPolicy: "no-cache",
  });

  const [updateSettingMutation, { loading: loadingUpdate }] =
    useMutation(SET_SETTINGS);

  useEffect(() => {
    getSetting({
      variables: {
        type: TYPE,
      },
    });
  }, [getSetting, open]);

  const update = () => {
    updateSettingMutation({
      variables: {
        id: setting.id,
        input: {
          updated_at: moment().unix(),
          options: {
            unboxing,
            unboxing_link: unboxingLink,
          },
        },
      },
    })
      .then((res) => {
        handleClose();
        enqueueSnackbar("Saved", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
        });
      });
  };

  const handleLinkChange = (url) => {
    const regExp =
      /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=)([^#\&\?]*).*/;
    let match = url.match(regExp);
    console.log("match", match);
    setUnboxing(match && match[1].length == 11 ? match[1] : "");
    setUnboxingLink(url);
    if (!match || match[1].length !== 11) {
      enqueueSnackbar(
        "Can't scrap the id from this link. Please contact to tech team.",
        {
          variant: "error",
        }
      );
    }
  };

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <Grid item xs>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        style={{
          borderLeft: "5px solid",
          borderLeftColor: "#63b475",
        }}
        className="btn-reward"
      >
        Real Dog Settings
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Real Dog Settings"}</DialogTitle>
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                fullWidth
                type="text"
                id="standard-basic"
                label="Unboxing Video Link"
                variant="standard"
                value={unboxingLink}
                onChange={(e) => {
                  handleLinkChange(e.target.value);
                }}
              />
              <TextField
                fullWidth
                type="text"
                id="standard-basic"
                label="Unboxing Video ID"
                variant="standard"
                value={unboxing}
                disabled
                sx={{ marginTop: "10px" }}
              />
            </DialogContentText>
          </DialogContent>
        )}
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>cancel</Button>
            <Button onClick={update} autoFocus>
              Update
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Grid>
  );
}
