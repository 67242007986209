import React, { Component, useEffect, useState } from "react";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import {
  GET_STOCKS,
  GET_SUPPLIERS,
  GET_INVENTORY_PRODUCTS,
  GET_INV_PRODUCT,
  GET_ACTIVE_ADMIN,
  UPDATE_STOCK_MANUAL,
  GET_STOCK,
} from "../../../queries";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";

function Stock(props) {
  const [open, setOpen] = useState(false);
  const [me, setMe] = useState(props.session.activeAdmin.id);
  const [selectedStock, setSelectedStock] = useState("");
  const [selectedStockLeft, setSelectedStockLeft] = useState("");
  const [selectedStockLeftNew, setSelectedStockLeftNew] = useState("");
  const [selectedStockName, setSelectedStockName] = useState("");
  const [selectedComment, setSelectedComment] = useState("");

  const { subscribeToMore, loading, error, data, refetch } =
    useQuery(GET_STOCKS);

  const { loading: loadingAdmin, data: dataAdmin } = useQuery(GET_ACTIVE_ADMIN);

  const [updateStockManual, { loading: loadingStock }] =
    useMutation(UPDATE_STOCK_MANUAL);

  const [getStock, { loading: loadingGetStockUn, data: dataGetStockUn }] =
    useLazyQuery(GET_STOCK, {
      fetchPolicy: "no-cache",
      variables: {
        id: selectedStock,
      },
      onCompleted: (data) => {
        setSelectedStockLeft(data.stock.left);
        setSelectedStockLeftNew(data.stock.left);
        setSelectedStockName(data.stock.product.name);
        //console.log('datatest', data);
      },
    });

  if (loading)
    return (
      <div>
        <CircularProgress className="loadingbutton" />
      </div>
    );
  if (error) return <div>Error.</div>;

  const handleClickUpdate = () => {
    setOpen(true);
  };

  const updateLeft = (e) => {
    setSelectedStockLeftNew(e.target.value);
  };

  const updateComment = (e) => {
    setSelectedComment(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    resetState();
  };

  const handleUpdateStock = (id) => {
    setSelectedStock(id);
    getStock();
    handleClickUpdate();
    console.log("stock id", id);
  };

  const resetState = () => {
    setSelectedComment();
    setSelectedStock();
    setSelectedStockLeft();
    setSelectedStockLeftNew();
    setSelectedStockName();
  };

  const updateStockFunc = () => {
    const vars = {
      id: selectedStock,
      left: parseInt(selectedStockLeftNew),
      oldleft: selectedStockLeft,
      product_name: selectedStockName,
      rec_date: 0,
      admin_id: me,
      comment: selectedComment,
    };

    console.log(vars);
    updateStockManual({
      variables: {
        id: selectedStock,
        left: parseInt(selectedStockLeftNew),
        oldleft: selectedStockLeft,
        product_name: selectedStockName,
        rec_date: 0,
        admin_id: me,
        comment: selectedComment,
      },
    }).then(async ({ data }) => {
      resetState();
      handleClose();
      refetch();
      console.log(data);
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClickUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        {loadingGetStockUn ? (
          <div>
            <div>
              <LinearProgress color="secondary" />
            </div>
          </div>
        ) : (
          <div>
            <DialogTitle id="alert-dialog-title">
              Update Stock - {selectedStockName}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dataAdmin.activeAdmin.name}!, Are you sure you update this
                stock?
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    name="left"
                    label="Left"
                    type="number"
                    value={selectedStockLeftNew}
                    onChange={updateLeft}
                    width="50%"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    multiline
                    rows={4}
                    margin="normal"
                    value={selectedComment}
                    name="comments"
                    label="Was there anything special or different about this update?"
                    type="text"
                    fullWidth
                    onChange={updateComment}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              <Button onClick={updateStockFunc} color="secondary" autoFocus>
                Update Stock
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
      <StockUI {...data} refetch={refetch} updateStock={handleUpdateStock} />
    </div>
  );
}

export class StockUI extends Component {
  componentDidMount() {
    this.props.refetch();
    // console.log(this.props);
  }
  render() {
    return (
      <div>
        <div className="pagetop">
          <div className="left">
            <Breadcrumbs aria-label="breadcrumb">
              <a color="inherit" href="/">
                Dashboard
              </a>
              <Typography color="textPrimary">Inventory</Typography>
            </Breadcrumbs>
          </div>
          <div className="right"></div>
        </div>
        <ul>
          {
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell align="right">Batch Number</TableCell>
                    <TableCell align="right">Date</TableCell>
                    <TableCell align="right">Stock</TableCell>
                    <TableCell align="right">Left</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.stocks.map((stock) => (
                    <TableRow key={stock.id}>
                      <TableCell component="th" scope="row">
                        {stock.product.name}
                      </TableCell>
                      <TableCell component="th" align="right" scope="row">
                        {stock.process.batch_number}
                      </TableCell>
                      <TableCell component="th" align="right" scope="row">
                        {moment
                          .unix(stock.rec_date)
                          .format("DD/MM/YYYY HH:mm:ss")}
                      </TableCell>
                      <TableCell component="th" align="right" scope="row">
                        {stock.stock}
                      </TableCell>
                      <TableCell component="th" align="right" scope="row">
                        {stock.left}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        <Button
                          onClick={() => this.props.updateStock(stock.id)}
                        >
                          <Edit />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </ul>
      </div>
    );
  }
}

export default Stock;
