import React, { useEffect, useState } from "react";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import HistoryIcon from "@mui/icons-material/History";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import AddBoxIcon from "@mui/icons-material/AddBox";
import RemoveIcon from "@mui/icons-material/Remove";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { GET_CUSTOMER_WALLET_LOGS } from "../../../queries";
import { useQuery } from "@apollo/client";
import { Chip, Link } from "@mui/material";

import { formatFloat } from "../../../globals";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));
const LOGTYPES = {
  add_coin: {
    name: "Add",
    movment: "in",
    icon: <ArrowCircleDownIcon color="primary" />,
    message: (data) => {
      return `${formatFloat(
        data.amount
      )} RDC(s) added to your account. Current Balance: ${Math.abs(
        data.amount + data.meta.current
      )}
      ${data.meta.reason ? `\nReason: ${data.meta.reason}` : ""}`;
    },
  },
  award: {
    name: "Add",
    movment: "in",
    icon: <EmojiEventsIcon color="secondary" />,
    message: (data) => {
      return `Earned a %${data.meta.percentage} cash back of ${formatFloat(
        data.amount
      )} RDC(s) from $${
        data.meta.order_amount
      } Secretshop order. Current Balance: ${Math.abs(
        formatFloat(data.amount + data.meta.current)
      )}`;
    },
  },
  add_balance: {
    name: "Add",
    movment: "in",
    icon: <AddBoxIcon color="secondary" />,
    message: (data) => {
      return `$${formatFloat(data.amount)} was added to your Account Balance`;
    },
  },
  remove_balance: {
    name: "Add",
    movment: "out",
    icon: <RemoveIcon color="error" />,
    message: (data) => {
      return `$${formatFloat(
        data.amount
      )} was removed from your Account Balance `;
    },
  },
  cash_out: {
    name: "Cash Out",
    movment: "out",
    icon: <ArrowCircleUpIcon color="error" />,
    message: (data) => {
      return `${formatFloat(
        data.amount
      )} RDC(s) were cashed-out. Current Balance: ${Math.abs(
        formatFloat(data.meta.current)
      )}`;
    },
  },
  gift_claim: {
    name: "Gift Claim",
    movment: "out",
    icon: <LocalOfferIcon color="secondary" />,
    message: (data) => {
      return `${formatFloat(data.amount)} RDC(s) were added after ${
        data.meta.couponId
      } coupon code was redeemed. Current Balance: ${Math.abs(
        formatFloat(data.meta.current + data.amount)
      )}`;
    },
  },
  on_demand: {
    name: "On Demand",
    movment: "out",
    icon: <ReceiptIcon color="error" />,
    message: (data) => {
      if (data.meta.hasOwnProperty("id")) {
        return `$${formatFloat(
          data.amount
        )} Real Dog Balance was applied to your ${data.meta.website}`;
      }
      return (
        <div>
          {data.meta && data.meta.orderAmount && data.meta.link ? (
            <span>
              <a
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                }}
                href={data.meta.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Paid ${data.meta.orderAmount} on {data.meta.website}
              </a>{" "}
            </span>
          ) : (
            <span>
              {data.meta.hasOwnProperty("orderAmount") ? (
                <p>
                  Paid {data.meta.orderAmount} on {data.meta.website}{" "}
                </p>
              ) : (
                <p>
                  {data.amount} RDC(s) were used on demand on{" "}
                  {data.meta.website}
                </p>
              )}
            </span>
          )}
        </div>
      );
    },
  },
  referral: {
    name: "Referalls",
    movment: "in",
    icon: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <AccessibilityNewIcon color="secondary" />
        <Typography color="secondary">RD</Typography>
      </div>
    ),
    message: (data) => {
      return `${data.amount} RDC(s) added because you referred ${
        data.meta.username
      }. Current Balance: ${Math.abs(
        formatFloat(data.amount + data.meta.current)
      )}`;
    },
  },
  affiliate: {
    name: "Affiliate",
    movment: "in",

    icon: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <AccessibilityNewIcon color="info" />
        <Typography color="info" sx={{ color: "#29b6f6" }}>
          FR
        </Typography>
      </div>
    ),
    message: (data) => {
      return `${formatFloat(
        data.amount
      )} RDC(s) were added to your account because  ${
        data.meta.username
      } joined using your link. Current Balance: ${Math.abs(
        formatFloat(data.amount + data.meta.current)
      )}`;
    },
  },
  error: {
    name: "Error",
    icon: <ErrorOutlineIcon color="error" />,
    message: (data) => {
      return `${data.message}. Please try again`;
    },
  },
  coin_transfer: {
    name: "Coin Transfer",
    movment: "out",
    icon: <CurrencyExchangeIcon color="secondary" />,
    message: (data) => {
      return (
        <span>
          <label>
            {data.amount} RDC(s) were transferred (converted) to your Real Dog
            Balance.
          </label>
          <br />
          {data.admin ? (
            <label> Admin: {data.admin.email}</label>
          ) : (
            <label>Member: {data.customer.email} </label>
          )}
        </span>
      );
    },
  },
};

const WalletLogList = ({ id, open, handleOpen, button = true }) => {
  // const [type, setType] = React.useState("all");
  // const [record, setRecord] = React.useState("all");
  // const classes = useStyles();

  // const handleChange = (event) => {
  //   setType(event.target.value);
  // };
  // const handleRecordCountChange = (event) => {
  //   setRecord(event.target.value);
  // };
  return (
    <div style={{ width: "100%", marginTop: "10px" }}>
      {button && (
        <Button
          style={{ zIndex: 2, marginTop: "0px" }}
          fullWidth
          variant="outlined"
          startIcon={<HistoryIcon />}
          className="lightpinkborder  btn-text-left btn-text-margin-left"
          onClick={handleOpen}
        >
          RDC History{" "}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <WalletLogs id={id} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const WalletLogs = ({ id }) => {
  const [outCoins, setOutCoins] = useState(0);
  const [inCoins, setInCoins] = useState(0);
  const { loading, error, data } = useQuery(GET_CUSTOMER_WALLET_LOGS, {
    variables: {
      id: id,
      limit: 1000,
      offset: 0,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      let tempInCoins = 0;
      let tempOutCoins = 0;
      let inKeys = Object.keys(LOGTYPES).filter(
        (key) => LOGTYPES[key].movment === "in"
      );
      let outkeys = Object.keys(LOGTYPES).filter(
        (key) => LOGTYPES[key].movment === "out"
      );

      data.customerWalletLogs &&
        data.customerWalletLogs.map((log) => {
          if (inKeys.includes(log.type)) {
            tempInCoins += log.amount;
          }
        });
      data.customerWalletLogs &&
        data.customerWalletLogs.map((log) => {
          if (outkeys.includes(log.type)) {
            tempOutCoins += log.amount;
          }
        });
      setInCoins(tempInCoins);
      setOutCoins(tempOutCoins);
    }
  }, [data]);

  if (loading) return <CircularProgress />;
  if (error) return `Error! ${error.message}`;
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        Recent RDC Activities
        {/* <span>
          <ArrowDropDownIcon color="secondary" /> {inCoins}
        </span>
        <span>
          <ArrowDropUpIcon color="error" /> {outCoins}
        </span> */}
      </Grid>
      <div style={{ height: 20 }}></div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        // alignItems="center"
      >
        <List dense={false}>
          {data.customerWalletLogs &&
            data.customerWalletLogs.map((log, i) => {
              return (
                <div key={i}>
                  <ListItem>
                    <ListItemIcon>{LOGTYPES[log.type].icon}</ListItemIcon>
                    <ListItemText primary={LOGTYPES[log.type].message(log)} />
                  </ListItem>
                  <Divider light>
                    <Typography color="text.secondary" variant="caption">
                      {moment(parseInt(log.createdAt)).toLocaleString()}
                    </Typography>
                  </Divider>
                </div>
              );
            })}
        </List>
      </Grid>
    </div>
  );
};

export default WalletLogList;
