import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_FLAG, UPDATE_FLAG } from "../../../../queries";
import { IconButton, LinearProgress, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { UploadFile } from "../../../Tools/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import { createRandKey } from "../../../../globals";

const KEY = "secretshopImageReward";

export default function SecretshopImageReward() {
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState({});
  const [img, setImage] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [getFlag, { loading, refetch }] = useLazyQuery(GET_FLAG, {
    onCompleted: (data) => {
      setFlag(data.getFlag);
      setImage(data.getFlag.data.img);
    },
    fetchPolicy: "no-cache",
  });

  const [updateFlagMutation, { loading: loadingUpdate }] =
    useMutation(UPDATE_FLAG);

  useEffect(() => {
    getFlag({
      variables: {
        key: KEY,
      },
    });
  }, [open]);

  const onChangeStatus = () => {
    const copy = copyFlag();
    copy.status = !copy.status;
    setFlag(copy);
  };

  const copyFlag = () => JSON.parse(JSON.stringify(flag));

  const update = () => {
    updateFlagMutation({
      variables: {
        id: flag.id,
        input: {
          key: KEY,
          status: flag.status,
          data: {
            img,
          },
        },
      },
    })
      .then((res) => {
        handleClose();
      })
      .catch((error) => {});
  };

  const resetFeaturedPhoto = () => {
    setImage("");
  };

  const uploadPhoto = (file) => {
    setImage(file.uri);
  };

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <Grid item xs>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        style={{
          borderLeft: "5px solid",
          borderLeftColor: flag.status ? "#63b475" : "#dc5a68",
        }}
        className="btn-reward"
      >
        Secretshop Product Image Reward
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Secretshop Product Image Reward"}
        </DialogTitle>
        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                control={
                  <Switch
                    checked={flag.status}
                    color="secondary"
                    onClick={() => {
                      onChangeStatus();
                    }}
                  />
                }
                label="Reward"
              />
              <Typography>350 x 530 px is the best</Typography>
              <Typography>
                Give some padding about 20px for the text inside the image to be
                sure to work every device.
              </Typography>
              {!img ? (
                <UploadFile
                  idKey={"add-uplad-image-key-" + createRandKey(6)}
                  uploadPhoto={uploadPhoto}
                  buttonName={"Add Featured Image"}
                />
              ) : (
                <div className="featuredImgBlock">
                  <div className="deletephotobtn">
                    <IconButton
                      onClick={resetFeaturedPhoto}
                      color="secondary"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                  <img width={"100%"} src={img} />
                </div>
              )}
            </DialogContentText>
          </DialogContent>
        )}

        {loading || loadingUpdate ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>cancel</Button>
            <Button onClick={update} autoFocus>
              Update
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Grid>
  );
}
