import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import TextField from "@mui/material/TextField";
import { Button, Grid } from "@mui/material";

import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";
import SelectTimes from "./selectTimes";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { CREATE_SCHEDULE_FROM_PRESET } from "../../../../../queries";
import { makeStyles } from "@mui/styles";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000",
    position: "absolute",
  },
}));

export default function SelectDateForSchedule({ id, dateList, setDateList }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [createMutation, { loading }] = useMutation(
    CREATE_SCHEDULE_FROM_PRESET
  );
  const [expanded, setExpanded] = useState(false);

  const [value, setValue] = useState(new Date());
  let a = [];
  const addOne = () => {
    if (dateList[dateList.length - 1].schedule.length === 0) {
      enqueueSnackbar(`You must select schedule first `, {
        variant: "info",
      });
      return;
    }
    let copy = JSON.parse(JSON.stringify(dateList));
    copy.push({
      date: moment(copy[copy.length - 1].date).add(1, "days"),
      schedule: "",
    });
    setDateList(copy);
  };
  const setDate = (index, value) => {
    let copy = JSON.parse(JSON.stringify(dateList));
    copy[index].date = value;
    setDateList(copy);
  };
  const setScheduleId = (index, value) => {
    let copy = JSON.parse(JSON.stringify(dateList));
    copy[index].schedule = value;
    setDateList(copy);
  };

  const deleteDate = (index) => {
    let copy = JSON.parse(JSON.stringify(dateList));
    copy.splice(index, 1);
    setDateList(copy);
  };

  const onPresConfirm = () => {
    if (dateList[dateList.length - 1].schedule.length === 0) {
      enqueueSnackbar(`You must select schedule first `, {
        variant: "info",
      });
      return;
    }
    let data = dateList.map((item) => {
      return {
        date: item.date,
        preset: item.schedule,
      };
    });
    createMutation({ variables: { input: data } })
      .then((res) => {
        enqueueSnackbar(`Schedule has been successfully confirmed`, {
          variant: "success",
        });
        setExpanded(false);
      })
      .catch((error) => {
        enqueueSnackbar(`Your Booking Could Not created `, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item md={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            {dateList.map((item, index) => {
              return (
                <Grid item md={12} key={item.toString()}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid item xs={5}>
                      <DatePicker
                        disabled={index !== dateList.length - 1}
                        views={["day"]}
                        inputFormat="MM/dd/yyyy"
                        label="Date"
                        value={item.date}
                        minDate={
                          index === 0
                            ? new Date()
                            : new Date(
                                moment(dateList[index - 1].date).add(1, "days")
                              )
                        }
                        onChange={(newValue) => {
                          console.log(newValue);
                          let day = new Date(newValue).getMonth();
                          let date = new Date(newValue).getDate();
                          console.log(day, date);
                          //   setValue(newValue);
                          setDate(index, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} helperText={null} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <SelectTimes
                        id={id}
                        scheduleId={item.schedule}
                        setScheduleId={(data) => {
                          setScheduleId(index, data);
                        }}
                        disabled={index !== dateList.length - 1}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {dateList.length !== 1 && (
                        <IconButton
                          fullWidth
                          aria-label="delete"
                          size="medium"
                          onClick={() => {
                            deleteDate(index);
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item md={12}>
              <Button
                fullWidth
                color="secondary"
                onClick={() => {
                  addOne();
                }}
              >
                <AddCircleIcon /> Add New
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}
