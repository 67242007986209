import React, { Component, useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLazyQuery } from "@apollo/client";

import {  MEMBERSHIP_SEARCH } from "../../../../queries";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
//
function AutoSearch(props) {
  const classes = useStyles();
  const [key, setSearch] = useState("/");
  const [category, setCategory] = useState(props.category);
  const [dataset, setDataset] = useState([]);
  const isInitialMount = useRef(true);
  const [open, setOpen] = useState(false);

  const [getMembershipUser, { loading: loadingSearch, data: searchData }] =
    useLazyQuery(MEMBERSHIP_SEARCH, {
      fetchPolicy: "no-cache",
      variables: {
        key,
        category
      },
      onCompleted: (data) => {
        if(data){
            setDataset(data.findMembership)
        }
      },
    });

  useEffect(() => {
    if (!isInitialMount.current) {
      getMembershipUser();
      isInitialMount.current = true;
    }
  }, [key, category]);

  useEffect(()  =>  {
    setCategory(props.category);
  },  [props.category])

  const searchInput = event =>  {
    const { value } = event.target;
    if(value.length >= 2){
      isInitialMount.current = false;
      setSearch(value);
    }
  }

  const selectCategory = event =>  {
    const { value } = event.target;
    if(value){
      setCategory(value);
      // setOpen(true)
      isInitialMount.current = false;
      props.filterTable(value);
    }
  }

  const onMouseLeave  = event =>  {
    setOpen(false);
  }

  const onMouseOver  = event =>  {
    setOpen(true);
  }

  return <AutoSearcUI
    history={props.history}
    edit={props.edit}
    searchInput={searchInput}
    classes={classes}
    dataset={dataset}
    search={key}
    category={category}
    selectCategory={selectCategory}
    loadingSearch={loadingSearch}
    open={open}
    onMouseLeave={onMouseLeave}
    onMouseOver={onMouseOver}
         />
}
export class AutoSearcUI extends Component {
  render() {
    const { searchInput,  dataset, search, history, loadingSearch, edit,  category,  selectCategory, classes, open, onMouseLeave, onMouseOver  }  =  this.props;
    return (
      <Grid container spacing={3} >
        <Grid item xs={9}  onMouseOver={onMouseOver}  onMouseLeave={onMouseLeave}>
          <Autocomplete
            id="REALDOG - REAL MEMBERSHIP SEARCH"
            noOptionsText={"Autocomplete Search Feature"}
            loading={loadingSearch}
            options={dataset}
            valueInput={search}
            getOptionLabel={(option) => `${option.name} - ${option.email}`}
            open={open}
            renderOption={(props, option ) => (
              <li {...props}>
                <div className="searchresult">
                  <b>{option.name} </b>
                </div>
              </li>
            )}
            onChange={(e, value) =>  {
              let newValue = value || "";
              return history.push(`/dashboard/customer/${newValue.id}`);
            }}
            renderInput={(articles) => (
              <TextField
                {...articles}
                label="Search User"
                margin="normal"
                onChange={searchInput}
                variant="outlined"
                InputProps={{ ...articles.InputProps, value: search, type: "search" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Filter Category
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              onChange={selectCategory}
            >
              <MenuItem value={"/"}>Show All</MenuItem>
              <MenuItem value={"Feedreal.com"}>Feedreal.com</MenuItem>
              <MenuItem value={"Real.Dog"}>Real.dog</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )
  }
}

export default AutoSearch;
