import React, { Component, useState, useEffect, useRef } from "react";
import { SINGLE_UPLOAD, MULTIPE_UPLOAD } from "../../queries";
import {
  useQuery,
  NetworkStatus,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import { CircularProgress } from "@mui/material";
import Resizer from "react-image-file-resizer";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";

const compressImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500, // maxWidth
      1920, // maxHeight
      "JPEG", //compressFormat
      100, //quality
      0, // rotation
      (uri) => {
        // responseUriFunc
        resolve(uri);
      },
      "blob" // outputType
    );
  });
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const SpinnerAdornment = (props) => (
  <CircularProgress size={20} className="loadingbutton" />
);

const CompressedFile = (props) => {
  const classes = useStyles();

  const [singleUpload, { loading, error }] = useMutation(SINGLE_UPLOAD);
  if (loading) return <SpinnerAdornment />;
  if (error) return <div>{console.log(JSON.stringify(error, null, 2))}</div>;
  const { uploadPhoto } = props;
  return (
    <React.Fragment>
      <div className={classes.root}>
        <input
          type="file"
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          onChange={async (e) => {
            let [file] = e.target.files;
            const resizedFile = await compressImage(file);
            singleUpload({
              variables: { file: resizedFile },
            }).then(async ({ data }) => {
              uploadPhoto(data["singleUpload"]);
            });
          }}
        />
        <label htmlFor="contained-button-file" style={{ margin: 0 }}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            {props.buttonName}
          </Button>
        </label>
      </div>
    </React.Fragment>
  );
};

const UploadFile = (props) => {
  const classes = useStyles();
  const idKey = props.idKey ? props.idKey : "contained-button-file";
  const [singleUpload, { loading, error }] = useMutation(SINGLE_UPLOAD);
  if (loading) return <SpinnerAdornment />;
  if (error) return <div>{console.log(JSON.stringify(error, null, 2))}</div>;
  const { uploadPhoto } = props;
  // console.log(props.idKey);
  return (
    <React.Fragment>
      <div className={classes.root}>
        <input
          type="file"
          accept="image/*"
          className={classes.input}
          id={idKey}
          onChange={(e) => {
            const [file] = e.target.files;
            singleUpload({
              variables: { file },
            }).then(async ({ data }) => {
              uploadPhoto(data["singleUpload"]);
            });
          }}
        />
        <label htmlFor={idKey} style={{ margin: 0 }}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            {props.buttonName}
          </Button>
        </label>
      </div>
    </React.Fragment>
  );
};

const MultipleFile = (props) => {
  const [multipleUpload, { loading, error }] = useMutation(MULTIPE_UPLOAD);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{console.log(JSON.stringify(error, null, 2))}</div>;

  const { uploadPhoto } = props;
  return (
    <React.Fragment>
      <input
        type="file"
        multiple
        onChange={(e) => {
          const { files } = e.target;
          multipleUpload({
            variables: { file: files },
          }).then(async ({ data }) => {
            uploadPhoto(data["multipleUpload"]);
          });
        }}
      />
    </React.Fragment>
  );
};
export { UploadFile, MultipleFile, CompressedFile };
