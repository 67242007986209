import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import FirstTabSteps from "./FirstTabSteps";
import ThirdTabSteps from "./ThirdTabSteps";
import SecondTabSteps from "./SecondTabSteps";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
const OneTimeOrderDialog = ({
  customerMongoId,
  stripeCustomer,
  email,
  id,
  refetch,
  mongoCustomer,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        fullWidth
        startIcon={<AddCircleOutlineIcon />}
        color="secondary"
        variant="outlined"
        onClick={handleClickOpen}
        className=" btn-text-left btn-text-margin-left"
      >
        One Time Order
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <OrderTabs
            customerMongoId={customerMongoId}
            stripeCustomer={stripeCustomer}
            email={email}
            id={id}
            close={() => {
              refetch();
              handleClose();
            }}
            mongoCustomer={mongoCustomer}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default OneTimeOrderDialog;

function TabPanel(props) {
  const { children, value, index, mongoCustomer, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const OrderTabs = ({
  customerMongoId,
  stripeCustomer,
  email,
  id,
  close,
  mongoCustomer,
}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Boxes" {...a11yProps(0)} />
          <Tab label="Custom Order" {...a11yProps(1)} />
          <Tab label="Products" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <FirstTabSteps
            customerMongoId={customerMongoId}
            stripeCustomer={stripeCustomer}
            email={email}
            id={id}
            close={close}
            mongoCustomer={mongoCustomer}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <SecondTabSteps
            customerMongoId={customerMongoId}
            stripeCustomer={stripeCustomer}
            email={email}
            id={id}
            close={close}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <ThirdTabSteps
            customerMongoId={customerMongoId}
            stripeCustomer={stripeCustomer}
            email={email}
            id={id}
            close={close}
            mongoCustomer={mongoCustomer}
          />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};
