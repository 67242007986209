import { Grid } from "@mui/material";
import React from "react";
import CheckoutReward from "./components/checkoutReward";
import SecretshopImageReward from "./components/secretshopImageReward";
import SecretshoReward from "./components/secretshoReward";
import SecretshopFreeShipping from "./components/secretshopFreeShipping";
import NewMemberMeatballRewards from "./components/newMemberMeatballRewards";
import FreeMembership from "./components/freeMembership";
import TCBOGOCheckoutReward from "./components/TCBOGOCheckoutReward";
import FreeBoxReward from "./components/FreeBoxReward";
import FeedrealCheckoutReward from "./components/FeedrealCheckoutReward";
import FeedrealDiscountReward from "./components/FeedrealDiscountReward";
import FeedRealCourseMemberDiscount from "./components/FeedRealCourseMemberDiscount";
import GiftCardDiscount from "./components/GiftCardDiscount";
import CourseDiscountBanner from "./components/CourseDiscountBanner";
import DiscountedMembership from "./components/discountedMembership";
import RealDogBanner from "./components/RealDogBanner";
import CourseBanners from "./components/CourseBanners";

const Rewards = () => {
  return (
    <div
      style={{
        paddingTop: 55,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={2} md={2}>
          <CheckoutReward />
        </Grid>
        <Grid item xs={2} md={2}>
          <TCBOGOCheckoutReward />
        </Grid>
        <Grid item xs={2} md={2}>
          <SecretshoReward />
        </Grid>
        <Grid item xs={2} md={2}>
          <SecretshopImageReward />
        </Grid>
        <Grid item xs={2} md={2}>
          <SecretshopFreeShipping />
        </Grid>
        <Grid item xs={2} md={2}>
          <NewMemberMeatballRewards />
        </Grid>
        <Grid item xs={2} md={2}>
          <FreeMembership />
        </Grid>
        <Grid item xs={2} md={2}>
          <DiscountedMembership />
        </Grid>
        <Grid item xs={2} md={2}>
          <FreeBoxReward />
        </Grid>
        <Grid item xs={2} md={2}>
          <FeedrealCheckoutReward />
        </Grid>
        <Grid item xs={2} md={2}>
          <FeedrealDiscountReward />
        </Grid>
        <Grid item xs={2} md={2}>
          <FeedRealCourseMemberDiscount />
        </Grid>
        <Grid item xs={2} md={2}>
          <GiftCardDiscount />
        </Grid>
        <Grid item xs={2} md={2}>
          <CourseBanners />
        </Grid>
      </Grid>
    </div>
  );
};

export default Rewards;
