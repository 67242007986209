import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { DONTCANCELSUBSCRIPTION } from "../../../../../queries";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#00000095",
    position: "absolute",
  },
}));

export default function DoNotCancelMembership({ subId, done }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [dontCancelSubscription, { loading }] = useMutation(
    DONTCANCELSUBSCRIPTION
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDontCancel = () => {
    dontCancelSubscription({
      variables: {
        subId: subId,
        time: moment().unix(),
        membership: true,
      },
    })
      .then((res) => {
        done();
        handleClose();
        enqueueSnackbar(`DON'T Cancel Subscription succeed.`, {
          variant: "success",
        });
      })
      .catch((error) => {
        handleClose();
        enqueueSnackbar(`DON'T Cancel Subscription error `, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        className="redborder  btn-text-left btn-text-margin-left"
        onClick={handleClickOpen}
      >
        Don't Cancel RDB Memberhship
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <Typography align="center">
            Please wait
            <br />
            <CircularProgress color="primary" />
          </Typography>
        </Backdrop>
        <DialogTitle id="alert-dialog-title">
          {"DON'T CANCEL MEMBERSHIP"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button onClick={onDontCancel} autoFocus>
            confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
