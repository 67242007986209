import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { Button, TextField, Typography } from "@mui/material";
import { Label } from "@mui/icons-material";
import { createRandKey } from "../../../../globals";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const QuizCategories = ({ categories = [], setCategories }) => {
  const handleDelete = (index) => () => {};

  const [singleCategory, setSingleCategory] = useState("");

  const addCategory = () => {
    if (singleCategory.length < 4) {
      return;
    }
    const copy = JSON.parse(JSON.stringify(categories));
    copy.push(singleCategory);
    setCategories(copy);
    setSingleCategory("");
  };

  return (
    <div
      style={{
        border: "1px solid rgba(255, 255, 255, 0.23)",
        padding: 20,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Typography>
        <Label
          style={{
            marginBottom: -7,
          }}
        />{" "}
        Categories
      </Typography>
      <div>
        {categories.map((tag, index) => {
          return (
            <Chip
              key={"chip-key-" + createRandKey(6)}
              label={tag}
              onDelete={tag.label === "React" ? undefined : handleDelete(index)}
              style={{
                marginRight: 10,
                marginTop: 10,
              }}
            />
          );
        })}
      </div>
      <br></br>
      <div>
        <TextField
          onKeyDown={(e) => {
            if (e.keyCode == 13) {
              console.log("value", e.target.value);
              // put the login here
              addCategory();
            }
          }}
          size="small"
          value={singleCategory}
          onChange={(e) => setSingleCategory(e.target.value)}
        />
        <Button
          onClick={() => {
            addCategory();
          }}
        >
          Add Category
        </Button>
      </div>
    </div>
  );
};
export default QuizCategories;
