import {
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  MenuItem,
  Breadcrumbs,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_RETAILER_REQ_PENDING,
  GET_AFFILIATE_REQ_PENDING,
  AFFILIATE_STATUS_UPDATE,
  RETAILER_STATUS_UPDATE,
  UPDATE_RETAILER_REQ,
} from "../../../queries";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import { States } from "../Customers/Components/constants";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Alert from "@mui/material/Alert";

const APPROVED = "approved";
const DECLINED = "declined";

const AffiliateFormRequests = () => {
  const { data, loading, error, refetch } = useQuery(GET_AFFILIATE_REQ_PENDING);

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <div>Error</div>;
  }
  return (
    <div>
      <div className="pagetop">
        <div className="left">
          <Breadcrumbs aria-label="breadcrumb">
            <a color="inherit" href="/">
              Dashboard
            </a>
            <Link color="inherit" to={"/dashboard/affiliate"}>
              Affiliate
            </Link>

            <Typography color="textPrimary">Affiliate Requests</Typography>
          </Breadcrumbs>
        </div>
      </div>
      {data.getAffiliateFormReqPending.map((item, index) => {
        return (
          <AffiliateItem
            key={"retailer-key-" + item.id}
            item={item}
            refetch={refetch}
          />
        );
      })}
    </div>
  );
};
export default AffiliateFormRequests;

const AffiliateItem = ({ item, refetch = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateStatusMutation, { loading }] = useMutation(
    AFFILIATE_STATUS_UPDATE
  );

  const firstName = item.first_name;
  const lastName = item.last_name;
  const phone = item.phone_number;
  const email = item.email;

  const status = item.status;
  const website = item.social.website;
  const social_media = item.social.social_media;

  const reqTime = item.createdAt;

  const onConfirm = ( closeFunction ) => {
    updateStatusMutation({ variables: { id: item.id, status: APPROVED } })
      .then((res) => {
        refetch();
        enqueueSnackbar("Updated Successfully", {
          variant: "success",
        });
        closeFunction();
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      });
  };
  const onDecline = ( closeFunction ) => {
    updateStatusMutation({ variables: { id: item.id, status: DECLINED } })
      .then((res) => {
        refetch();
        enqueueSnackbar("Declined Successfully", {
          variant: "success",
        });
        closeFunction();
      })
      .catch((err) => {
        enqueueSnackbar("Error", {
          variant: "error",
        });
      });
    refetch();
  };
  return (
    <div
      style={{
        maxWidth: 600,
        borderRadius: 5,
        padding: 20,
        marginBottom: 10,
        position: "relative",
        backgroundColor: "#222429",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      }}
    >
      <div
        style={{
          position: "absolute",
          right: 10,
          top: 10,
        }}
      >
        {/* <UpdateRetailer item={item} refetch={refetch} /> */}
        <Typography>{moment(reqTime * 1000).format("MM/DD/YYYY")}</Typography>
        <Typography variant="subtitle2" textAlign={"center"}>
          {moment(reqTime * 1000).format("LT")}
        </Typography>
      </div>

      <Typography variant="h5">
        {firstName} {lastName}
      </Typography>
      <Typography>
        <b>Phone Number: </b> {phone}
      </Typography>
      <Typography>
        <b>Email:</b> {email}
      </Typography>

      {website && (
        <Typography>
          <b>Website: </b> {website}
        </Typography>
      )}
      {social_media && (
        <Typography>
          <b>Tiktok: </b> {social_media}
        </Typography>
      )}
      <br></br>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Chip
          color="secondary"
          label={status.toUpperCase()}
          style={{
            backgroundColor: "yellow",
            color: "black",
          }}
        />
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <SeeAnswers
              item={item}
              onDecline={onDecline}
              onConfirm={onConfirm}
              loading={loading}
            />
            <ConfirmAffiliate
              type={DECLINED}
              firstName={firstName}
              lastName={lastName}
              onConfirm={onDecline}
              loading={loading}
            />
            <ConfirmAffiliate
              type={APPROVED}
              firstName={firstName}
              lastName={lastName}
              email={email}
              phone={phone}
              onConfirm={onConfirm}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const ConfirmAffiliate = ({
  type,
  firstName,
  lastName,
  email,
  phone,
  onConfirm,
  loading,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const color = type === APPROVED ? "success" : "error";
  return (
    <div>
      <Button
        variant="outlined"
        color={color}
        onClick={handleClickOpen}
        style={{
          marginLeft: 10,
        }}
      >
        {type === APPROVED && "Confirm"}
        {type === DECLINED && "Decline"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to create Affiliate User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {type === APPROVED && (
              <Alert severity="warning">
                If member already in our system, please find the member from
                Customer panel and click "Make Affiliate" button in the panel
                then confirm in here!
              </Alert>
            )}
            <Typography>
              {firstName} {lastName}
            </Typography>
            <Typography>{email}</Typography>
            <Typography>{phone}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm(() => {
                handleClose();
              });
            }}
            color={color}
            autoFocus
            disabled={loading}
          >
            {type === APPROVED && !loading && "Confirm"}
            {type === DECLINED && !loading && "Decline"}
            {loading && <CircularProgress />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const SeeAnswers = ({ item, onDecline, onConfirm, loading }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const firstName = item.first_name;
  const lastName = item.last_name;
  const phone = item.phone_number;
  const email = item.email;

  const status = item.status;
  const website = item.social.website;
  const social_media = item.social.social_media;
  const questions = [...item.questions];

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{
          marginLeft: 10,
        }}
        startIcon={<RemoveRedEyeIcon />}
      >
        See Answers
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edit"}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            paddingTop: "10px",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            ["& .MuiTextField-root"]: {
              marginTop: "12px",
              width: "48%",
              ["&.question , &.answer"]: {
                width: "100%",
              },
              ["&.question"]: {
                marginTop: "18px",
              },
              ["&.answer"]: {
                paddingBottom: "15px",
                borderBottom: "1px solid #ffffff33",
                ["&:nth-last-child(1)"]: {
                  borderBottom: "none",
                },
              },
            },
          }}
        >
          <TextField
            variant="outlined"
            label="First Name"
            value={firstName}
            disabled
          />
          <TextField
            variant="outlined"
            label="Last Name"
            value={lastName}
            disabled
          />
          <TextField variant="outlined" label="Email" value={email} disabled />
          <TextField
            variant="outlined"
            label="Phone Number"
            value={phone}
            disabled
          />
          {questions.map((data) => (
            <>
              <TextField
                className="question"
                variant="outlined"
                label="Question"
                value={data.q}
                multiline
                maxRows={3}
                disabled
              />
              <TextField
                className="answer"
                variant="outlined"
                label="Answer"
                value={data.a}
                multiline
                maxRows={3}
                disabled
              />
            </>
          ))}
        </DialogContent>
        <DialogActions>
          <ConfirmAffiliate
            type={DECLINED}
            firstName={firstName}
            lastName={lastName}
            onConfirm={onDecline}
            loading={loading}
          />
          <ConfirmAffiliate
            type={APPROVED}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
            onConfirm={onConfirm}
            loading={loading}
          />
          <Button
            onClick={handleClose}
            color="primary"
            sx={{ marginLeft: "20px" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
