import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { List, ListItem, ListItemText } from "@mui/material";
import { useMutation } from "@apollo/client";
import { COMPLETE_DANGER_UNIT } from "../../../../../queries";

const ALLOWED_EMALILS = ["ruby@real.dog", "oguz@real.dog", "kay@real.dog"];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CompleteDangerUnitDialog({
  // props.session.activeAdmin.email
  unit,
  completedTutorials,
  customerId,
  refetch,
  activeAdmin,
}) {
  const [open, setOpen] = React.useState(false);
  const [completeTheUnit, { loading, error }] = useMutation(
    COMPLETE_DANGER_UNIT,
    {
      variables: {
        input: {
          unitId: unit.id,
          courseId: unit.courseId,
          customerId: customerId,
        },
      },
      onCompleted: () => {
        setOpen(false);
        refetch();
      },
    }
  );

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };
  const onMakeUnitCompleted = (e) => {
    e.stopPropagation();
    handleClickOpen(e);
  };
  const unitCompleted = (e) => {
    e.stopPropagation();
    completeTheUnit();
  };
  if (!ALLOWED_EMALILS.includes(activeAdmin.email)) {
    return <div></div>;
  }
  return (
    <div>
      <Button
        variant="outlined"
        // startIcon={<CheckBoxOutlineBlankIcon />}
        style={{
          marginLeft: 10,
        }}
        size="small"
        onClick={onMakeUnitCompleted}
      >
        Complete Tutorials
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>{"Danger"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This is very dangerous operation. if you confirm the every tutorial
            except quiz tutorial will be
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            close
          </Button>
          <Button disabled={loading} onClick={unitCompleted}>
            confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
