import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  ButtonBase,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GET_NUMBER_OF_COURSE_USER } from "../../../queries";
import { useQuery } from "@apollo/client";
import InfoIcon from "@mui/icons-material/Info";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CoursePurchaseDetail() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { loading, data, error } = useQuery(GET_NUMBER_OF_COURSE_USER);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <ButtonBase onClick={handleClickOpen}>
        <Paper className={classes.paper}>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <InfoIcon />
          </div>
          <p>
            Course Purchased (Nutrition){" "}
            <span>{data.getNumberOfCourseUser.nutrition.total}</span>
          </p>
        </Paper>
      </ButtonBase>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle>{"Courses Purchase Detail"}</DialogTitle>
        <DialogContent className={"analyticsSpan"}>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container>
              <Grid item xs={6}>
                <Typography>Nutrition Course</Typography>
                <div
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <ul>
                    <li>
                      {" "}
                      total: {data.getNumberOfCourseUser.nutrition.total}
                    </li>
                    <li>free: {data.getNumberOfCourseUser.nutrition.free}</li>
                    <li> paid: {data.getNumberOfCourseUser.nutrition.paid}</li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography>CPCN Program</Typography>
                <div
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <ul>
                    <li> total: {data.getNumberOfCourseUser.pro.total}</li>
                    <li>free: {data.getNumberOfCourseUser.pro.free}</li>
                    <li> paid: {data.getNumberOfCourseUser.pro.paid}</li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
