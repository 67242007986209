import * as React from "react";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";
import { GET_CUSTOMER } from "../../../../../queries";
import { useQuery } from "@apollo/client";
const PaymentComponent = ({
  sources,
  stripePaymentSource,
  setStripePaymentSource,
  price = 0,
  shippingPrice = 0,
  notes,
  setNotes,
  rdcUse = false,
  setRDCUse = () => {},
}) => {
  let { id } = useParams();
  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: { id },
    fetchPolicy: "no-cache",
  });
  console.log(loading, error, data);
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid sm={12} style={{ marginBottom: 20 }}>
        <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Note"
          multiline
          rows={4}
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
      </Grid>
      <Grid>
        <Typography variant="h5">
          Total: $
          {parseFloat(parseFloat(price) + parseFloat(shippingPrice)).toFixed(2)}
        </Typography>
        <Typography>
          Shipping: $ {parseFloat(shippingPrice).toFixed(2)}{" "}
        </Typography>
      </Grid>
      {data && data.customer && (
        <Grid>
          <Typography>
            RDC:{" "}
            {data.customer.wallet
              ? parseFloat(data.customer.wallet.coins).toFixed(2)
              : 0}{" "}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={rdcUse}
                onClick={() => {
                  setRDCUse(!rdcUse);
                }}
                disabled={data.customer.wallet ? false : true}
              />
            }
            label="Use RDC"
          />
        </Grid>
      )}
      <Grid sm={12} style={{ marginTop: 20 }}>
        <SelectTextFields
          sources={sources}
          stripePaymentSource={stripePaymentSource}
          setStripePaymentSource={setStripePaymentSource}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentComponent;

const SelectTextFields = ({
  sources,
  stripePaymentSource,
  setStripePaymentSource,
}) => {
  const [currency, setCurrency] = React.useState("");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <div>
      {sources.length > 0 ? (
        <TextField
          fullWidth
          id="outlined-select-currency"
          select
          label="Select Payment Method"
          value={stripePaymentSource}
          onChange={(event) => setStripePaymentSource(event.target.value)}
          helperText="Please select your currency"
        >
          {sources.map((option) => (
            <MenuItem key={option.value} value={option.id}>
              Last 4 digit is : {option.last4}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          fullWidth
          id="outlined-select-currency"
          select
          label="No CC on this account!"
          disabled
        ></TextField>
      )}
    </div>
  );
};
