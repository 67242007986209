import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useLazyQuery } from "@apollo/client";
import { GET_CUSTOMER_WIZARD_STEP } from "../../../queries";
import { Chip, Grid, LinearProgress } from "@mui/material";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";

const STEPS_RAW = [
  {
    img: require("../../../assets/assistant/assistantFirstStep.png"),
    tag: "firstStep",
    title: "First Step",
  },
  {
    img: require("../../../assets/assistant/assistantUserName.png"),
    tag: "userName",
    title: "Username",
  },
  {
    img: require("../../../assets/assistant/assistantBirthDates.png"),
    tag: "dogsBirthDate",
    title: "Dogs Birth Date",
  },
  {
    img: require("../../../assets/assistant/assistantPhoto.png"),
    tag: "dogsPhoto",
    title: "Dogs Photo",
  },
  {
    img: require("../../../assets/assistant/assistantBreedsAndWeights.png"),
    tag: "dogsBreedWeight",
    title: "Breeds and Weight",
  },
  {
    img: require("../../../assets/assistant/assistantDogEatPrimarily.png"),
    tag: "questionAnswer1",
    title: "What does your dog eat primarily",
  },
  {
    img: require("../../../assets/assistant/assistantConsernedWith.png"),
    tag: "questionAnswer2",
    title: "What are you most concerned with for your dog",
  },
  {
    img: require("../../../assets/assistant/assistantOralFleaofTickPreventative.png"),
    tag: "questionAnswer3",
    title: "Are you using any oral flea of tick preventative for your dog",
  },
  {
    img: require("../../../assets/assistant/assistantTopicalFlea.png"),
    tag: "questionAnswer4",
    title: "Are you using any topicalFlea or tick prevenrtative on your dog",
  },
  {
    img: require("../../../assets/assistant/assistantConsultation.png"),
    tag: "questionAnswer5",
    title: "Assistant Consultation",
  },
  {
    img: require("../../../assets/assistant/assistantDogsSafety.png"),
    tag: "assistantText6",
    title: "Dogs safety",
  },
  {
    img: require("../../../assets/assistant/assistantSecretShop.png"),
    tag: "assistantText7",
    title: "Secret Shop",
  },
  {
    img: require("../../../assets/assistant/assistantMakeYourDogFoodatHome.png"),
    tag: "assistantText8",
    title: "Do you want to start making your dog's food at",
  },
  {
    img: require("../../../assets/assistant/assistantLearMoreAboutFeeding.png"),
    tag: "assistantText9",
    title: "Learn more about feeding",
  },
  {
    img: require("../../../assets/assistant/assistantFunActivityWithDog.png"),
    tag: "assistantText10",
    title: "Want to do a fun activity with your dog?",
  },
  {
    img: require("../../../assets/assistant/assistantYoutubeVideo1.png"),
    tag: "youtebeVideo1",
    title: "Youtube Video 1",
  },
  {
    img: require("../../../assets/assistant/assistantYoutubeVideo2.png"),
    tag: "youtebeVideo2",
    title: "Youtube Video 2",
  },
  {
    img: require("../../../assets/assistant/assistantYoutubeVideo3.png"),
    tag: "youtebeVideo3",
    title: "Youtube Video 3",
  },
  {
    img: require("../../../assets/assistant/assistantYoutubeVideo4.png"),
    tag: "youtebeVideo4",
    title: "Youtube Video 4",
  },
  {
    img: require("../../../assets/assistant/assistantQuestion1.png"),
    tag: "generalQuestion1",
    title: "What Real Dog Muscle Meat has the highest protein?",
  },
  {
    img: require("../../../assets/assistant/assistantQuestion2.png"),
    tag: "generalQuestion2",
    title: "What Real Dog Organ Meat has the highest fat?",
  },
  {
    img: require("../../../assets/assistant/assistantQuestion3.png"),
    tag: "generalQuestion3",
    title: "What Real Dog Seafood has the highest moisture?",
  },
  {
    img: require("../../../assets/assistant/assistantQuestion4.png"),
    tag: "generalQuestion4",
    title:
      "How many Air-Dried Chicken Feet does it take to equal 1 Raw Chicken Foot?",
  },
  {
    img: require("../../../assets/assistant/assistantQuestion5.png"),
    tag: "generalQuestion5",
    title: "How many calories are in the average bag of Real Dog's Duck Neck?",
  },
  {
    img: require("../../../assets/assistant/assistantQuestion6.png"),
    tag: "generalQuestion6",
    title: "What is Real Dog's super chew that is highest in crude protein?",
  },
  {
    img: require("../../../assets/assistant/assistantQuestion7.png"),
    tag: "generalQuestion7",
    title: "What Real Dog Meatball is highest in crude protein?",
  },
  {
    img: require("../../../assets/assistant/assistantDidYouKnow.png"),
    tag: "assistantText11",
    title: "Did you know ...",
  },
  {
    img: require("../../../assets/assistant/assistantHaveYouHeard.png"),
    tag: "assistantText12",
    title: "Have you heard about our DIY raw workshops and packwalks?",
  },
  {
    img: require("../../../assets/assistant/assistantMembershipOffer.png"),
    tag: "dogMemberOffer",
    title: "Membership Offer",
  },
  {
    img: require("../../../assets/assistant/assistantOrigin.png"),
    tag: "assistantOrigin",
    title: "Why did you choose Real Dog Box?",
  },
  {
    img: require("../../../assets/assistant/assistantQr.png"),
    tag: "dogQRCode",
    title: "Qr Code ",
  },
];

export default function CustomersAssistantSteps({ customer }) {
  const [fetchCustomerWizardStepsMutation, { loading }] = useLazyQuery(
    GET_CUSTOMER_WIZARD_STEP,
    { fetchPolicy: "no-cache" }
  );

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [assistantSteps, setAssistantSteps] = useState([]);

  useEffect(() => {
    if (customer.customer && open) {
      console.log("customer.customer => ", customer.customer);
      fetchCustomerWizardStepsMutation({
        variables: {
          id: customer.customer.id,
        },
      })
        .then((response) => {
          console.log("RESPONSE => ", response);
          setAssistantSteps(response.data.getUsersWizardSteps.wizardSteps);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [customer, open]);

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} fullWidth>
        See Customer's Assistant progress
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          Wizard Steps
          {!loading && (
            <Typography>
              ({STEPS_RAW.length - assistantSteps.length} incomplete)
            </Typography>
          )}
        </DialogTitle>
        {loading ? (
          <LinearProgress />
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                {STEPS_RAW.map((step) => {
                  return (
                    <Grid item sm={4}>
                      <Card>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            image={step.img}
                            alt="green iguana"
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {step.title}
                            </Typography>
                            {assistantSteps
                              .map((item) => item.tag)
                              .includes(step.tag) ? (
                              <Chip label="completed" color="success" />
                            ) : (
                              <Chip label="uncompleted" color="error" />
                            )}
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
